import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import LocationUsage from './locationUsage';

const CompanyUsage = (props) => {
   const [usageData, setUsageData] = useState(false);
   const [usageDataSet, setUsageDataSet] = useState(false);

   useEffect(() => {
      if (props.company && props.weekDays) {
         let usage = {};
         props.weekDays.map((day) => {
            usage[day.ref] = 0;
         });
         props.company.locations.map((location) => {
            usage[location.lid] = {};
            props.weekDays.map((day) => {
               usage[location.lid][day.ref] = 0;
            });
         });
         setUsageData(usage);
         setUsageDataSet(true);
      }
   }, [props.company, props.weekDays]);

   return (
      <React.Fragment>
         <TableRow>
            <TableCell>{props.company.companyName}</TableCell>
            <TableCell></TableCell>
            <TableCell>{props.company.totalPeople}</TableCell>
            {usageData &&
               props.weekDays.map((day) => {
                  return <TableCell key={day.ref}>{usageData[day.ref]}</TableCell>;
               })}
         </TableRow>
         {props.company.locations.map((location) => {
            return (
               <LocationUsage
                  key={location.lid}
                  location={location}
                  usageData={usageData}
                  usageDataSet={usageDataSet}
                  setUsageData={setUsageData}
                  weekDays={props.weekDays}
                  setUsageDataSet={setUsageDataSet}
               />
            );
         })}
      </React.Fragment>
   );
};

export default CompanyUsage;
