import React, { useEffect, useState } from 'react';
import {
   Box,
   Button,
   Chip,
   Collapse,
   Divider,
   FormGroup,
   Paper,
   Tooltip,
   Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CircularProgress from '@mui/material/CircularProgress';
import { withFirebase } from '../../../Firebase';
import ItoFromOffisite from './itoFromOffisite';
import LocationFilter from './locationFilter';
import { DateToday } from '../../../../constants/functions';

const Overview = (props) => {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [people, setPeople] = useState(false);
   const [routineIndex, setRoutineIndex] = useState(false);
   const [schedule, setSchedule] = useState(false);
   const [itoCollapsed, setItoCollapsed] = useState(true);
   const [wfhCollapsed, setWfhCollapsed] = useState(false);
   const [offsiteCollapsed, setOffsiteCollapsed] = useState(false);
   const [awayCollapsed, setAwayCollapsed] = useState(false);
   const [numbers, setNumbers] = useState({
      ito: 0,
      wfh: 0,
      offsite: 0,
      away: 0,
      blank: 0,
   });
   const [companyLocs, setCompanyLocs] = useState(false);
   const [selectedLocs, setSelectedLocs] = useState([]);
   const [companyPeople, setCompanyPeople] = useState(false);
   const [otherLocITONums, setOtherLocITONums] = useState(false);
   const today = DateToday();

   useEffect(() => {
      if (props.tab === 3 && props.location.routine && props.day.ref > today.ref) {
         const unsubscribe = props.firebase
            .routineIndex(props.displayFilter.cid)
            .onSnapshot((result) => {
               if (result.exists) {
                  setRoutineIndex(result.data());
               }
            });
         return () => unsubscribe();
      }
   }, [props.tab, props.displayFilter, props.location, props.day]);

   useEffect(() => {
      if (props.tab === 3 && props.location) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         props.firebase
            .company(props.location.cid)
            .get()
            .then((result) => {
               // setCompanyLocs(result.data().locations)
               let locNums = {};
               let allLocs = [];
               Object.entries(result.data().locations).map(([key, value]) => {
                  locNums[key] = {
                     ito: { allDay: 0, am: 0, pm: 0 },
                     offsite: { allDay: 0, am: 0, pm: 0 },
                  };
                  if (
                     key !== lid &&
                     result.data().publishedLocations &&
                     result.data().publishedLocations[key]
                  ) {
                     allLocs.push({ lid: key, name: value });
                  }
               });
               allLocs.sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
               );
               setCompanyLocs(allLocs);
               setOtherLocITONums(locNums);
            });
      }
   }, [props.tab, props.location]);

   useEffect(() => {
      if (props.tab === 3 && props.displayFilter) {
         let query = props.firebase.companyPeople(props.displayFilter.cid);
         if (props.displayFilter.type === 'group') {
            query = props.firebase.locationGroupDetails(
               props.displayFilter.lid,
               props.displayFilter.id,
            );
         } else if (props.displayFilter.type === 'location') {
            query = props.firebase.locationPeopleIndex(props.displayFilter.id);
         }
         const unsubscribe = query.onSnapshot((result) => {
            let peopleList = [];
            if (result.exists) {
               let data = {};
               if (props.displayFilter.type === 'group') {
                  data = result.data().members;
               } else {
                  data = result.data();
               }
               Object.entries(data).map(([key, value]) => {
                  var person = { uid: key, ...value, cid: authUser.cid };
                  if (routineIndex && routineIndex[key]) {
                     person.routine = routineIndex[key].routine;
                  }
                  peopleList.push({ uid: key, ...person });
               });
            }
            peopleList.sort((a, b) =>
               a.name.firstName.toLowerCase() > b.name.firstName.toLowerCase() ? 1 : -1,
            );
            setPeople(peopleList);
         });
         return () => unsubscribe();
      }
   }, [props.tab, props.displayFilter, routineIndex]);
   useEffect(() => {
      if (people) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .locationDayScheduleDate(lid, props.day.ref)
            .onSnapshot((result) => {
               let data = {
                  ito: {
                     allday: [],
                     am: [],
                     pm: [],
                  },
                  wfh: {
                     allday: [],
                     am: [],
                     pm: [],
                  },
                  offsite: {
                     allday: [],
                     am: [],
                     pm: [],
                  },
                  away: {
                     allday: [],
                     am: [],
                     pm: [],
                  },
                  blank: {
                     allday: [],
                     am: [],
                     pm: [],
                  },
               };
               let nums = {
                  ito: {
                     allday: 0,
                     am: 0,
                     pm: 0,
                  },
                  wfh: {
                     allday: 0,
                     am: 0,
                     pm: 0,
                  },
                  offsite: {
                     allday: 0,
                     am: 0,
                     pm: 0,
                  },
                  away: {
                     allday: 0,
                     am: 0,
                     pm: 0,
                  },
                  blank: {
                     allday: 0,
                     am: 0,
                     pm: 0,
                  },
               };
               people.map((person) => {
                  if (result.exists) {
                     if (
                        result.data()[person.uid]?.status &&
                        result.data()[person.uid].status !== '-' &&
                        result.data()[person.uid].status !== 'split'
                     ) {
                        data[result.data()[person.uid].status].allday.push({
                           uid: person.uid,
                           ...person,
                           ...result.data()[person.uid],
                        });
                        nums[result.data()[person.uid].status].allday =
                           nums[result.data()[person.uid].status].allday + 1;
                     }
                     if (
                        result.data()[person.uid]?.status &&
                        result.data()[person.uid].status == 'split'
                     ) {
                        if (result.data()[person.uid].splitDay.AM !== '-') {
                           data[result.data()[person.uid].splitDay.AM].am.push({
                              uid: person.uid,
                              ...person,
                              ...result.data()[person.uid],
                           });
                           nums[result.data()[person.uid].splitDay.AM].am =
                              nums[result.data()[person.uid].splitDay.AM].am + 1;
                        } else {
                           data.blank.am.push({
                              uid: person.uid,
                              ...person,
                              ...result.data()[person.uid],
                           });
                           nums.blank.am = nums.blank.am + 1;
                        }
                        if (result.data()[person.uid].splitDay.PM !== '-') {
                           data[result.data()[person.uid].splitDay.PM].pm.push({
                              uid: person.uid,
                              ...person,
                              ...result.data()[person.uid],
                           });
                           nums[result.data()[person.uid].splitDay.PM].pm =
                              nums[result.data()[person.uid].splitDay.PM].pm + 1;
                        } else {
                           data.blank.pm.push({
                              uid: person.uid,
                              ...person,
                              ...result.data()[person.uid],
                           });
                           nums.blank.pm = nums.blank.pm + 1;
                        }
                     }
                     if (
                        result.data()[person.uid]?.status &&
                        result.data()[person.uid].status == '-'
                     ) {
                        data.blank.allday.push({
                           uid: person.uid,
                           ...person,
                           ...result.data()[person.uid],
                        });
                        nums.blank.allday = nums.blank.allday + 1;
                     }
                     if (person.routine && !result.data()[person.uid]) {
                        if (person.routine[props.day.weekDay] !== '-') {
                           data[person.routine[props.day.weekDay]].allday.push({
                              uid: person.uid,
                              ...person,
                              status: person.routine[props.day.weekDay],
                           });
                           nums[person.routine[props.day.weekDay]].allday =
                              nums[person.routine[props.day.weekDay]].allday + 1;
                        } else {
                           data.blank.allday.push({
                              uid: person.uid,
                              ...person,
                              status: '-',
                           });
                           nums.blank.allday = nums.blank.allday + 1;
                        }
                     }
                  } else {
                     if (person.routine) {
                        if (person.routine[props.day.weekDay] !== '-') {
                           data[person.routine[props.day.weekDay]].allday.push({
                              uid: person.uid,
                              ...person,
                              status: person.routine[props.day.weekDay],
                           });
                           nums[person.routine[props.day.weekDay]].allday =
                              nums[person.routine[props.day.weekDay]].allday + 1;
                        } else {
                           data.blank.allday.push({
                              uid: person.uid,
                              ...person,
                              status: '-',
                           });
                           nums.blank.allday = nums.blank.allday + 1;
                        }
                     }
                  }
               });
               setNumbers(nums);
               setSchedule(data);
            });
         return () => unsubscribe();
      }
   }, [props.displayFilter, people, props.day]);

   useEffect(() => {
      if (companyLocs.length > 0) {
         const unsubscribe = props.firebase
            .companyPeopleIndex(props.displayFilter.cid)
            .onSnapshot((result) => {
               let peopleList = {};
               if (result.exists) {
                  peopleList = result.data();
               }

               setCompanyPeople(peopleList);
            });
         return () => unsubscribe();
      }
   }, [companyLocs, props.displayFilter]);

   function calcStatNumbers(time, number, others, block) {
      if (Object.keys(others).length !== 0) {
         let no = 0;
         Object.entries(others).map(([key, value]) => {
            no = no + value[block][time];
         });
         no = no + number;
         return no;
      } else {
         return number;
      }
   }

   return (
      <Box
         sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '80vw',
         }}
      >
         {schedule ? (
            <Box sx={{ width: '100%' }}>
               <Box component={Paper} sx={{ p: 2, m: 1, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Box>
                        <Typography>ITO</Typography>
                     </Box>
                     <Box>
                        <Button
                           size="small"
                           onClick={() => setItoCollapsed(!itoCollapsed)}
                        >
                           {itoCollapsed ? 'Hide' : 'Show'}
                        </Button>
                     </Box>
                  </Box>
                  <Collapse in={itoCollapsed} collapsedSize={45}>
                     {schedule.ito.allday.map((ito) => (
                        <Chip
                           size="small"
                           color="primary"
                           variant="outlined"
                           key={ito.uid}
                           icon={<DoneIcon />}
                           label={`${ito.name.firstName} ${ito.name.lastName}`}
                           sx={{ m: 0.5 }}
                        />
                     ))}
                     {schedule.ito.am.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {/* <Divider sx={{mb:1}} /> */}
                           {schedule.ito.am.map((ito) => (
                              <Chip
                                 size="small"
                                 color="primary"
                                 variant="outlined"
                                 key={ito.uid}
                                 icon={<DoneIcon />}
                                 label={`${ito.name.firstName} ${ito.name.lastName} - AM`}
                              />
                           ))}
                        </Box>
                     )}
                     {schedule.ito.pm.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {/* <Divider sx={{mb:1}} /> */}
                           {schedule.ito.pm.map((ito) => (
                              <Chip
                                 size="small"
                                 color="primary"
                                 variant="outlined"
                                 key={ito.uid}
                                 icon={<DoneIcon />}
                                 label={`${ito.name.firstName} ${ito.name.lastName} - PM`}
                              />
                           ))}
                        </Box>
                     )}
                     <Box>
                        {companyLocs &&
                           otherLocITONums &&
                           props.displayFilter.type !== 'group' &&
                           companyLocs.length > 0 &&
                           companyPeople && (
                              <React.Fragment>
                                 <Divider sx={{ mt: 1 }} />
                                 {companyLocs.map((otherLoc) => (
                                    <ItoFromOffisite
                                       key={otherLoc.lid}
                                       block={'ito'}
                                       locName={otherLoc.name}
                                       lid={otherLoc.lid}
                                       companyPeople={companyPeople}
                                       displayFilter={props.displayFilter}
                                       day={props.day}
                                       location={props.location}
                                       tab={props.tab}
                                       setOtherLocITONums={setOtherLocITONums}
                                       otherLocITONums={otherLocITONums}
                                    />
                                 ))}
                              </React.Fragment>
                           )}
                     </Box>
                  </Collapse>
                  <Box
                     component="div"
                     sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  >
                     <Box
                        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                     >
                        <Typography variant="caption">ITO Totals:</Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-evenly',
                           minWidth: '60%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <DoneIcon fontSize="small" color="primary" />
                           <Typography variant="caption">
                              {calcStatNumbers(
                                 'allDay',
                                 numbers.ito.allday,
                                 otherLocITONums,
                                 'ito',
                              )}
                           </Typography>
                        </Box>
                        {calcStatNumbers('am', numbers.ito.am, otherLocITONums, 'ito') !==
                           0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <DoneIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 AM:{' '}
                                 {calcStatNumbers(
                                    'am',
                                    numbers.ito.am,
                                    otherLocITONums,
                                    'ito',
                                 )}
                              </Typography>
                           </Box>
                        )}
                        {calcStatNumbers('pm', numbers.ito.pm, otherLocITONums, 'ito') !==
                           0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <DoneIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 PM:{' '}
                                 {calcStatNumbers(
                                    'pm',
                                    numbers.ito.pm,
                                    otherLocITONums,
                                    'ito',
                                 )}
                              </Typography>
                           </Box>
                        )}
                     </Box>
                     <Box></Box>
                  </Box>
               </Box>
               <Box component={Paper} sx={{ p: 2, m: 1, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Box>
                        <Typography>WFH</Typography>
                     </Box>
                     <Box>
                        <Button
                           size="small"
                           onClick={() => setWfhCollapsed(!wfhCollapsed)}
                        >
                           {wfhCollapsed ? 'Hide' : 'Show'}
                        </Button>
                     </Box>
                  </Box>
                  <Collapse in={wfhCollapsed} collapsedSize={45}>
                     {schedule.wfh.allday.map((wfh) => (
                        <Chip
                           size="small"
                           color="primary"
                           variant="outlined"
                           key={wfh.uid}
                           icon={<HomeOutlinedIcon />}
                           label={`${wfh.name.firstName} ${wfh.name.lastName}`}
                           sx={{ m: 0.5 }}
                        />
                     ))}
                     {/* <Divider sx={{mb:1}} /> */}
                     {schedule.wfh.am.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.wfh.am.map((wfh) => (
                              <Chip
                                 size="small"
                                 color="primary"
                                 variant="outlined"
                                 key={wfh.uid}
                                 icon={<HomeOutlinedIcon />}
                                 label={`${wfh.name.firstName} ${wfh.name.lastName} - AM`}
                              />
                           ))}
                           <Divider sx={{ mt: 1 }} />
                        </Box>
                     )}
                     {schedule.wfh.pm.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.wfh.pm.map((wfh) => (
                              <Chip
                                 size="small"
                                 color="primary"
                                 variant="outlined"
                                 key={wfh.uid}
                                 icon={<HomeOutlinedIcon />}
                                 label={`${wfh.name.firstName} ${wfh.name.lastName} - PM`}
                              />
                           ))}
                        </Box>
                     )}
                  </Collapse>
                  <Box
                     component="div"
                     sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  >
                     <Box
                        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                     >
                        <Typography variant="caption">WFH Totals:</Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-evenly',
                           minWidth: '60%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <HomeOutlinedIcon fontSize="small" color="primary" />
                           <Typography variant="caption">{numbers.wfh.allday}</Typography>
                        </Box>
                        {numbers.wfh.am !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <HomeOutlinedIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 AM: {numbers.wfh.am}
                              </Typography>
                           </Box>
                        )}
                        {numbers.wfh.pm !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <HomeOutlinedIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 PM: {numbers.wfh.pm}
                              </Typography>
                           </Box>
                        )}
                     </Box>
                     <Box></Box>
                  </Box>
               </Box>
               <Box component={Paper} sx={{ p: 2, m: 1, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Box>
                        <Typography>Offsite</Typography>
                     </Box>
                     <Box>
                        <Button
                           size="small"
                           onClick={() => setOffsiteCollapsed(!offsiteCollapsed)}
                        >
                           {offsiteCollapsed ? 'Hide' : 'Show'}
                        </Button>
                     </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                     <LocationFilter
                        companyLocs={companyLocs}
                        selectedLocs={selectedLocs}
                        setSelectedLocs={setSelectedLocs}
                        displayFilter={props.displayFilter}
                     />
                  </Box>
                  <Collapse in={offsiteCollapsed} collapsedSize={55}>
                     {schedule.offsite.allday.map((offsite) => (
                        <Tooltip
                           title={
                              offsite.offsiteLocation?.locationName
                                 ? offsite.offsiteLocation.locationName
                                 : offsite?.offsiteLocation !== ''
                                 ? offsite.offsiteLocation
                                 : '-'
                           }
                           arrow
                           placement="top"
                           key={offsite.uid}
                        >
                           <Chip
                              size="small"
                              color="primary"
                              variant="outlined"
                              key={offsite.uid}
                              icon={<PinDropIcon />}
                              label={`${offsite.name.firstName} ${offsite.name.lastName}`}
                              sx={{ m: 0.5 }}
                           />
                        </Tooltip>
                     ))}
                     {/* <Divider sx={{mb:1}} /> */}
                     {schedule.offsite.am.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.offsite.am.map((offsite) => (
                              <Tooltip
                                 title={
                                    offsite.offsiteLocation?.AM?.locationName
                                       ? offsite.offsiteLocation.AM.locationName
                                       : offsite?.offsiteLocation?.AM !== ''
                                       ? offsite.offsiteLocation.AM
                                       : '-'
                                 }
                                 arrow
                                 placement="top"
                                 key={offsite.uid}
                              >
                                 <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    key={offsite.uid}
                                    icon={<PinDropIcon />}
                                    label={`${offsite.name.firstName} ${offsite.name.lastName} - AM`}
                                 />
                              </Tooltip>
                           ))}
                           {/* <Divider sx={{mt:1}} /> */}
                        </Box>
                     )}

                     {schedule.offsite.pm.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.offsite.pm.map((offsite) => (
                              <Tooltip
                                 title={
                                    offsite.offsiteLocation?.PM?.locationName
                                       ? offsite.offsiteLocation.PM.locationName
                                       : offsite?.offsiteLocation?.PM !== ''
                                       ? offsite.offsiteLocation.PM
                                       : '-'
                                 }
                                 arrow
                                 placement="top"
                                 key={offsite.uid}
                              >
                                 <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    key={offsite.uid}
                                    icon={<PinDropIcon />}
                                    label={`${offsite.name.firstName} ${offsite.name.lastName} - PM`}
                                 />
                              </Tooltip>
                           ))}
                        </Box>
                     )}
                     <Box>
                        {selectedLocs &&
                           otherLocITONums &&
                           props.displayFilter.type !== 'group' &&
                           selectedLocs.length > 0 &&
                           companyPeople && (
                              <React.Fragment>
                                 <Divider sx={{ mt: 1 }} />
                                 {selectedLocs.map((otherLoc) => (
                                    <ItoFromOffisite
                                       key={otherLoc.lid}
                                       block={'offsite'}
                                       routineIndex={routineIndex}
                                       locName={otherLoc.name}
                                       lid={otherLoc.lid}
                                       companyPeople={companyPeople}
                                       displayFilter={props.displayFilter}
                                       day={props.day}
                                       location={props.location}
                                       tab={props.tab}
                                       setOtherLocITONums={setOtherLocITONums}
                                       otherLocITONums={otherLocITONums}
                                    />
                                 ))}
                              </React.Fragment>
                           )}
                     </Box>
                  </Collapse>
                  <Box
                     component="div"
                     sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  >
                     <Box
                        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                     >
                        <Typography variant="caption">Offsite Totals:</Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-evenly',
                           minWidth: '60%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <PinDropIcon fontSize="small" color="primary" />
                           <Typography variant="caption">
                              {calcStatNumbers(
                                 'allDay',
                                 numbers.offsite.allday,
                                 otherLocITONums,
                                 'offsite',
                              )}
                           </Typography>
                        </Box>
                        {calcStatNumbers(
                           'am',
                           numbers.offsite.am,
                           otherLocITONums,
                           'offsite',
                        ) !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <PinDropIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 AM:{' '}
                                 {calcStatNumbers(
                                    'am',
                                    numbers.offsite.am,
                                    otherLocITONums,
                                    'offsite',
                                 )}
                              </Typography>
                           </Box>
                        )}
                        {calcStatNumbers(
                           'pm',
                           numbers.offsite.pm,
                           otherLocITONums,
                           'offsite',
                        ) !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <PinDropIcon fontSize="small" color="primary" />
                              <Typography variant="caption">
                                 PM:{' '}
                                 {calcStatNumbers(
                                    'pm',
                                    numbers.offsite.pm,
                                    otherLocITONums,
                                    'offsite',
                                 )}
                              </Typography>
                           </Box>
                        )}
                     </Box>
                     <Box></Box>
                  </Box>
               </Box>

               <Box component={Paper} sx={{ p: 2, m: 1, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Box>
                        <Typography>Away</Typography>
                     </Box>
                     <Box>
                        <Button
                           size="small"
                           onClick={() => setAwayCollapsed(!awayCollapsed)}
                        >
                           {awayCollapsed ? 'Hide' : 'Show'}
                        </Button>
                     </Box>
                  </Box>
                  <Collapse in={awayCollapsed} collapsedSize={45}>
                     {schedule.away.allday.map((away) => (
                        <Chip
                           size="small"
                           color="secondary"
                           variant="outlined"
                           key={away.uid}
                           icon={<DoNotDisturbOnIcon />}
                           label={`${away.name.firstName} ${away.name.lastName}`}
                           sx={{ m: 0.5 }}
                        />
                     ))}
                     {/* <Divider sx={{mb:1}} /> */}
                     {schedule.away.am.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.away.am.map((away) => (
                              <Chip
                                 size="small"
                                 color="secondary"
                                 variant="outlined"
                                 key={away.uid}
                                 icon={<DoNotDisturbOnIcon />}
                                 label={`${away.name.firstName} ${away.name.lastName} - AM`}
                              />
                           ))}
                           {/* <Divider sx={{mt:1}} /> */}
                        </Box>
                     )}

                     {schedule.away.pm.length !== 0 && (
                        <Box sx={{ mt: 1 }}>
                           {schedule.away.pm.map((away) => (
                              <Chip
                                 size="small"
                                 color="secondary"
                                 variant="outlined"
                                 key={away.uid}
                                 icon={<DoNotDisturbOnIcon />}
                                 label={`${away.name.firstName} ${away.name.lastName} - PM`}
                              />
                           ))}
                        </Box>
                     )}
                  </Collapse>
                  <Box
                     component="div"
                     sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  >
                     <Box
                        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                     >
                        <Typography variant="caption">Away Totals:</Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-evenly',
                           minWidth: '60%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <DoNotDisturbOnIcon fontSize="small" color="secondary" />
                           <Typography variant="caption">
                              {numbers.away.allday}
                           </Typography>
                        </Box>
                        {numbers.away.am !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <DoNotDisturbOnIcon fontSize="small" color="secondary" />
                              <Typography variant="caption">
                                 AM: {numbers.away.am}
                              </Typography>
                           </Box>
                        )}
                        {numbers.away.pm !== 0 && (
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              {' '}
                              <DoNotDisturbOnIcon fontSize="small" color="secondary" />
                              <Typography variant="caption">
                                 PM: {numbers.away.pm}
                              </Typography>
                           </Box>
                        )}
                     </Box>
                     <Box></Box>
                  </Box>
               </Box>
            </Box>
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
               }}
            >
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(Overview);
