import React, { useState } from 'react';
import { withFirebase } from '../Firebase';

import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Tooltip } from '@mui/material';
import { useCompany } from '../../providers';
import { usePage } from '../layout/PageLayout';

const useStyles = makeStyles((theme) => ({
   tableCell: {
      maxWidth: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
   },
}));

async function inviteUser(props) {
   var localUser = JSON.parse(localStorage.getItem('authUser'));
   var inviteNewUser = firebase.functions().httpsCallable('inviteUser');
   inviteNewUser(props.value)
      .then(async function (result) {
         if (result.data.error) {
            props.setChecked(true);
            props.setAlertMessage({
               type: 'error',
               message: result.data.error.errorInfo.message,
            });
         } else {
            var uid = result.data.userRecord.uid;
            let data = props.value;
            data.marketing = { optIn: false };
            data.roles = { ...props.value.roles, USER: 'STAFF' };
            data.status = { statusId: 'invited' };
            var status = { statusId: 'invited' };
            await props.firebase.user(uid).set(data, { merge: true });
            await props.firebase
               .getPerson(localUser.cid, uid)
               .set({ status }, { merge: true });
            props.setChecked(true);
            props.setAlertMessage({
               type: 'success',
               message: 'An invitation has been sent to ' + props.value.email,
            });
            props.setLoading(false);
         }
      })
      .catch(function (error) {
         console.error('There was an error when calling the Cloud Function', error);
         // window.alert('There was an error when calling the Cloud Function:\n\nError Code: '
         //   + code + '\nError Message:' + message + '\nError Details:' + details);
      });
}

function StatusCell(props) {
   switch (props.value.status.statusId) {
      case 'notInvited':
         return (
            <TableCell key="status">
               <div>
                  <Button
                     disabled={props.loading}
                     onClick={(e) => {
                        e.stopPropagation();
                        props.setLoading(true);
                        inviteUser(props);
                     }}
                     size="small"
                     variant="outlined"
                     color="primary"
                  >
                     Invite
                  </Button>
               </div>
            </TableCell>
         );
      case 'invited':
         return <TableCell key="status">Invited</TableCell>;
      default:
         return <TableCell key="blank"></TableCell>;
   }
}

const Rows = (props) => {
   const classes = useStyles();
   const { company } = useCompany();
   const { selectedLocationId, selectedGroupId } = usePage();
   const [loading, setLoading] = useState(false);
   return (
      <React.Fragment>
         {Object.entries(props.companyPeople).map(([key, person]) => {
            return (
               <TableRow
                  id={person.uid}
                  key={key}
                  hover
                  onClick={() => {
                     props.setPeopleDrawer(true);
                     props.setDrawerData({ type: 'edit', value: person });
                  }}
               >
                  <TableCell className={classes.tableCell} key="name">
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedLocationId !== person.lid[0] && (
                           <Tooltip
                              title={company.locations[person.lid[0]]}
                              placement="top"
                           >
                              <BusinessIcon fontSize="small" sx={{ mr: 1 }} />
                           </Tooltip>
                        )}
                        {person.name.firstName + ' ' + person.name.lastName}
                        {person.groupAdmins &&
                           person.groupAdmins.includes(selectedGroupId) && (
                              <Tooltip title="Group Admin">
                                 <AdminPanelSettingsIcon
                                    color="disabled"
                                    fontSize="small"
                                    sx={{ ml: 1 }}
                                 />
                              </Tooltip>
                           )}
                     </div>
                  </TableCell>
                  <TableCell className={classes.tableCell} key="email">
                     {person.email}
                  </TableCell>
                  <TableCell className={classes.tableCell} key="group">
                     {person.groups &&
                        Object.entries(person.groups).map(([groupKey, groupValue]) => {
                           // console.log(groupValue, 'GROUP VALUE');
                           if (groupValue) {
                              return (
                                 <Chip
                                    key={groupKey}
                                    size="small"
                                    label={
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                          }}
                                       >
                                          {groupValue.global && (
                                             <PublicIcon
                                                fontSize="small"
                                                color="disabled"
                                                sx={{ ml: -0.5, mr: 0.5 }}
                                             />
                                          )}{' '}
                                          {groupValue.name}
                                       </Box>
                                    }
                                 />
                              );
                           }
                        })}
                  </TableCell>
                  <StatusCell
                     setChecked={props.setChecked}
                     setAlertMessage={props.setAlertMessage}
                     firebase={props.firebase}
                     value={person}
                     loading={loading}
                     setLoading={setLoading}
                  />
               </TableRow>
            );
         })}
      </React.Fragment>
   );
};

export default withFirebase(Rows);
