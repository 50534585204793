import React from 'react';
import { CardSection } from '../../layout';
import { Box, Typography } from '@mui/material';
import { withFirebase } from '../../Firebase';
import { DateToday } from '../../../constants/functions';
import BookingList from './bookingList';

const MyBookings = () => {
   return (
      <CardSection>
         <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Bookings
         </Typography>
         <Box>
            <Box sx={{ overflow: 'scroll', minWidth: '85px', maxHeight: '170px' }}>
               {[0, 1, 2].map((i) => {
                  return <BookingList key={i} date={DateToday(i)} />;
               })}
            </Box>
         </Box>
      </CardSection>
   );
};

export default withFirebase(MyBookings);
