import React from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getDay } from '../../../../constants/functions';

const TableHeadCells = (props) => {
   const history = useHistory();
   function clickDay(day) {
      props.setDay(getDay(day.fullDate));
      props.setView('day');
      history.push({
         pathname: '/schedule',
         search: `?view=day&date=${day.year}-${String(day.month + 1).padStart(
            2,
            '0',
         )}-${String(day.day).padStart(2, '0')}`,
      });
   }
   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            '&:hover': {
               cursor: 'pointer',
               textDecoration: 'underline',
            },
         }}
         onClick={() => clickDay(props.weekDay)}
      >
         {/* <div > */}
         <Typography
            color="primary"
            //  className={classes.dates}
            variant={props.view === 'multiweek' ? 'caption' : 'subtitle1'}
         >
            {props.weekDay.day}
         </Typography>
         <Typography
            color="primary"
            variant={props.view === 'multiweek' ? 'caption' : 'subtitle2'}
         >
            {props.view === 'multiweek'
               ? props.weekDay.shortDayName.substring(0, 2)
               : props.weekDay.shortDayName}
         </Typography>
         {/* </div> */}
      </Box>
   );
};

export default TableHeadCells;
