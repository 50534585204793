import React from 'react';

import { compose } from 'recompose';
import { AuthProvider, CompanyProvider } from '../../providers';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import Main from './main';

const Dashboard = () => (
   <AuthProvider>
      <CompanyProvider>
         <Main />
      </CompanyProvider>
   </AuthProvider>
);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withEmailVerification, withAuthorization(condition))(Dashboard);
