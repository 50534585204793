import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import SpaceDetails from '../Spaces/spaceDetails';
import Bookings from '../Spaces/bookings';
import SpaceForm from '../Spaces/form';
import { DateToday } from '../../constants/functions';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(0),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      width: '60%',
   },
   drawer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
}));

const LightTooltip = withStyles((theme) => ({
   tooltip: {
      // backgroundColor: theme.palette.common.grey,
      // color:  theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 'medium',
   },
}))(Tooltip);

const SpacesDrawer = (props) => {
   const classes = useStyles();
   const [editSpace, setEditSpace] = useState(false);
   const [space, setSpace] = useState(false);
   const [parents, setParents] = useState({});
   const [childSpaces, setChildSpaces] = useState(false);
   const [buttonDisabled, setButtonDisabled] = useState(false);
   const [futureBookings, setFutureBookings] = useState(false);

   useEffect(() => {
      if (props.spacesDrawerOpen === true) {
         getSpaceAreas(props);
         if (props.spaceKey) {
            getSpace(props);
         }
      }
   }, [props.spacesDrawerOpen, props]);

   useEffect(() => {
      const date = DateToday();
      if (space) {
         const getFutureBookings = props.firebase
            .futureSpaceBookings(props.locationKey, date.ref, space.id)
            .limit(10)
            .onSnapshot((result) => {
               if (!result.empty) {
                  let reservations = {};
                  let ignore = true;
                  result.forEach((doc) => {
                     if (doc.data().bookingNo >= 1) {
                        reservations[doc.id] = { ...doc.data() };
                        ignore = false;
                     }
                  });
                  if (!ignore) {
                     setFutureBookings(reservations);
                     setButtonDisabled(true);
                  } else {
                     setFutureBookings(false);
                     setButtonDisabled(false);
                  }
               }
            });
         return () => {
            getFutureBookings();
            setFutureBookings(false);
            setButtonDisabled(false);
         };
      }
   }, [space]);

   useEffect(() => {
      const loadChildSpaces = async () => {
         if (space.type === 'area') {
            await props.firebase
               .getChildSpaces(props.locationKey, space.id)
               .get()
               .then((results) => {
                  if (!results.empty) {
                     let children = {};
                     results.forEach((doc) => {
                        children[doc.id] = { ...doc.data() };
                     });
                     setChildSpaces(children);
                     setButtonDisabled(true);
                  }
               });
         }
      };
      loadChildSpaces();
   }, [space]);

   function clearDrawerFields() {
      props.setSpacesDrawerOpen(false);
      setEditSpace(false);
      props.setSpaceKey(null);
      setSpace({});
      setChildSpaces(false);
      setButtonDisabled(false);
   }

   async function getSpaceAreas(props) {
      props.firebase.getLocationAreas(props.locationKey).onSnapshot((result) => {
         let areas = {};
         if (result.empty == false) {
            result.forEach((doc) => {
               areas[doc.id] = { ...doc.data() };
            });
            setParents(areas);
         }
      });
   }

   async function getSpace(props) {
      let spaceDetails = {};
      const spaceData = await props.firebase
         .getSpace(props.locationKey, props.spaceKey)
         .get();
      spaceDetails = spaceData.data();
      spaceDetails.id = props.spaceKey;
      setSpace(spaceDetails);
   }

   const switchToEdit = (e) => {
      setEditSpace(true);
   };
   const archiveSpace = (e) => {
      props.firebase.archiveSpace(props.locationKey, props.spaceKey);
   };
   const unarchiveSpace = (e) => {
      props.firebase.unarchiveSpace(props.locationKey, props.spaceKey);
   };
   const deleteSpace = async () => {
      console.log(space, childSpaces);
      props.setSpacesDrawerOpen(false);
      if (!childSpaces) {
         const schedules = await props.firebase
            .getSpaceSchedules(props.locationKey, space.id)
            .get();
         console.log(schedules);
         if (schedules.empty) {
            try {
               await completeDeleteSpace(space);
            } catch (error) {
               console.log(error, 'error completing the delete space');
            }
         } else {
            schedules.forEach((schedule) => {
               let day = { id: schedule.id, ...schedule.data() };
               console.log(day);
               props.firebase
                  .deleteSpaceSchedule(props.locationKey, day, space)
                  .then(() => {
                     completeDeleteSpace(space);
                  });
            });
            console.log('schedule not empty');
         }
      } else {
         //set an alert saying you need to remove the child spaces
      }

      // props.firebase.getSpace(props.locationKey, props.spaceKey).delete();
      // props.setSpacesDrawerOpen(false);
   };
   async function completeDeleteSpace(space) {
      if (space.imagePath) {
         await deleteImage(space.imagePath);
      }
      if (space.mapPath) {
         await deleteMap(space.mapPath);
      }
      await props.firebase.getSpace(props.locationKey, props.spaceKey).delete();
   }
   async function deleteImage(path) {
      var desertRef = props.firebase.storedImageRef(path);
      await desertRef
         .delete()
         .then(() => {
            return props.firebase.deleteSpaceImage(props.locationKey, space.id);
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }
   async function deleteMap(path) {
      var desertRef = props.firebase.storedImageRef(path);
      await desertRef
         .delete()
         .then(() => {
            props.firebase.deleteSpaceMap(props.locationKey, space.id);
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }

   return (
      <Drawer
         classes={{ paper: classes.drawerPaper }}
         anchor="right"
         open={props.spacesDrawerOpen}
         onClose={() => {
            clearDrawerFields();
         }}
      >
         <div className={classes.drawer}>
            {props.spaceKey && !editSpace ? (
               <React.Fragment>
                  <Container>
                     <SpaceDetails
                        space={space}
                        childSpaces={childSpaces}
                        spaceKey={props.spaceKey}
                        locationKey={props.locationKey}
                     />
                     <Grid style={{ marginTop: 24, marginBottom: 24 }} container>
                        <Grid item xs={6}>
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={switchToEdit}
                              startIcon={<CreateIcon />}
                           >
                              Edit space
                           </Button>
                        </Grid>
                        <Grid item xs={6}>
                           {space.archive ? (
                              <React.Fragment>
                                 <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={unarchiveSpace}
                                 >
                                    Unarchive space
                                 </Button>
                                 {/* <Button variant="outlined" color="secondary" onClick={deleteSpace}>Delete space</Button>   */}
                              </React.Fragment>
                           ) : (
                              <div
                                 style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                 }}
                              >
                                 <Button
                                    variant="outlined"
                                    disabled={buttonDisabled}
                                    color="secondary"
                                    onClick={archiveSpace}
                                 >
                                    Archive space
                                 </Button>
                                 <LightTooltip
                                    title="You can only Archive spaces that don't have upcoming bookings or child spaces"
                                    placement="top"
                                    arrow
                                 >
                                    <HelpOutlineIcon style={{ marginLeft: '16px' }} />
                                 </LightTooltip>
                              </div>
                           )}
                        </Grid>
                     </Grid>

                     <Bookings
                        futureBookings={futureBookings}
                        spaceKey={props.spaceKey}
                        cid={props.cid}
                        locationKey={props.locationKey}
                     />
                  </Container>
               </React.Fragment>
            ) : (
               <SpaceForm
                  parents={parents}
                  editSpace={editSpace}
                  setSpacesDrawerOpen={props.setSpacesDrawerOpen}
                  spaceKey={props.spaceKey}
                  locationKey={props.locationKey}
               />
            )}
         </div>
      </Drawer>
   );
};

export default withFirebase(SpacesDrawer);
