import * as React from 'react';
import Box from '@mui/material/Box';
import ScheduleExport from './ScheduleExport';
import RoutineExport from './routineExport';
import { Divider } from '@mui/material';
import { TabSet, TabSetContent, useTabSet } from '../layout';

const Export = (props) => {
   const [tabValue, _, handleTabChange] = useTabSet({
      urlParamAlias: 'exportTab',
   });

   const tabNames = props.location.routine
      ? ['Schedules', 'Future Routines']
      : ['Schedules'];

   return (
      <Box sx={{ width: '100%' }}>
         <TabSet value={tabValue} tabNames={tabNames} onChange={handleTabChange} />
         <Divider />
         <TabSetContent
            tabNames={tabNames}
            tabValue={tabValue}
            contentArray={[
               <ScheduleExport tab={props.tab} location={props.location} />,
               <RoutineExport tab={props.tab} location={props.location} />,
            ]}
         />
      </Box>
   );
};
export default Export;
