import React from 'react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PinDropIcon from '@material-ui/icons/PinDrop';
import SplitIcons from '../Schedule/Chips/splitIcons';
import SplitIconsDark from '../Schedule/Chips/splitIconsDark';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const LoadChip = (props) => {
   async function handleDelete() {
      var participants = props.form.internalParticipants;
      delete participants[props.id];
      props.setPeopleVal(props.peopleVal.filter((entry) => entry.id !== props.id));
   }
   let chipDets = {
      status: props.value.status,
      id: props.id,
      name: props.value.name,
   };
   if (chipDets.status === 'none' && props.value.routine) {
      chipDets.status = props.value.routine;
   }
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   let splitValues = '';
   if (props.value.status == 'split') {
      let am = `${props.value.splitValues.AM}am`;
      let pm = `${props.value.splitValues.PM}pm`;
      splitValues = am + pm;
   }

   switch (chipDets.status) {
      case 'ito':
         return (
            <Chip
               key={chipDets.id}
               variant="outlined"
               icon={<DoneIcon />}
               label={chipDets.name}
               onDelete={() => {
                  handleDelete();
               }}
            />
         );

      case 'wfh':
         return (
            <Chip
               key={chipDets.id}
               icon={<HomeIcon />}
               label={chipDets.name}
               onDelete={() => {
                  handleDelete();
               }}
            />
         );
      case 'away':
         return (
            <Chip
               key={chipDets.id}
               icon={<NotInterestedIcon />}
               label={chipDets.name}
               color="secondary"
               onDelete={() => {
                  handleDelete();
               }}
            />
         );
      case 'offsite':
         return (
            <Chip
               key={chipDets.id}
               icon={<PinDropIcon />}
               label={chipDets.name}
               onDelete={() => {
                  handleDelete();
               }}
            />
         );
      case 'split':
         return (
            <Chip
               key={chipDets.id}
               variant="outlined"
               icon={
                  prefersDarkMode ? (
                     <SplitIconsDark splitValues={splitValues} />
                  ) : (
                     <SplitIcons splitValues={splitValues} />
                  )
               }
               label={chipDets.name}
               onDelete={() => {
                  handleDelete();
               }}
            />
         );
      default:
         return (
            <Chip
               key={chipDets.id}
               variant="outlined"
               color="primary"
               label={chipDets.name}
               onDelete={() => {
                  handleDelete();
               }}
            />
         );
   }
};

export default LoadChip;
