import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../assets/logo.png';

// Create styles
const styles = StyleSheet.create({
   page: {
      backgroundColor: '#fff',
   },
   header: {
      backgroundColor: '#115293',
      color: '#fff',
      height: '90px',
      marginBottom: 120,
      flexDirection: 'row',
   },
   title: {
      fontSize: 30,
      marginTop: 20,
      textAlign: 'center',
   },
   logo: {
      // width: '50%',
      position: 'absolute',
      bottom: 30,
      right: 30,
   },
   heading: {
      // width: '50%',
      flexGrow: 1,
      marginTop: 10,
   },
   // section: {
   //    // width: '50%',
   //    flexDirection: 'row',
   // },
   contents: {
      flexGrow: 1,
      marginBottom: 50,
      alignItems: 'center',
   },
   section: {
      margin: 10,
      padding: 10,
   },
   h2: {
      fontSize: 24,
   },
});

// Create Document Component
const QRPDF = (props) => (
   <Document>
      <Page wrap size="A4" style={styles.page}>
         <View style={styles.header}>
            <View style={styles.heading}>
               <Text style={styles.title}>SCAN TO CHECK IN</Text>
            </View>
         </View>
         <View style={styles.section}>
            <View style={styles.contents}>
               <Image
                  style={{ width: '256px', height: '256px' }}
                  source={{ uri: props.image }}
               />
            </View>
         </View>
         <View style={styles.section}>
            <View style={styles.contents}>
               <Text style={styles.h2}>Instructions</Text>
               <Text>1. Scan QR code with your smartphone</Text>
               <Text>2. Log into intheOffice</Text>
               <Text>3. Tap "Submit"</Text>
            </View>
         </View>
         <View style={styles.logo}>
            <Image style={{ width: '128px', height: '90px' }} src={logo} />
         </View>
      </Page>
   </Document>
);

export default QRPDF;
