import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => {
   return (
      <Box
         display="flex"
         justifyContent="center"
         alignItems="center"
         height="100vh"
         flexDirection="column"
      >
         <CircularProgress />
      </Box>
   );
};

export default Loading;
