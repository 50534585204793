import React, { useState, useEffect } from 'react';
import {
   Avatar,
   Box,
   Divider,
   List,
   ListItem,
   Stack,
   Tooltip,
   Typography,
   useTheme,
} from '@mui/material';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import { spaceTypeImage } from '../../Spaces/functions';
import { withFirebase } from '../../Firebase';
import { useAuth, useCompany } from '../../../providers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const BookingList = ({ date, ...props }) => {
   const { authUser } = useAuth();
   const history = useHistory();
   const theme = useTheme();
   const { company } = useCompany();
   const [bookings, setBookings] = useState(null);

   useEffect(() => {
      const subscribe = props.firebase
         .getUserSpaceBookingsOnDate(authUser.cid, authUser.uid, date.ref)
         .onSnapshot((doc) => {
            let books = [];
            let allDay = [];
            let am = [];
            let pm = [];
            let hourly = [];
            if (doc.exists && doc.data().bookingNo >= 1) {
               const promises = Object.entries(doc.data().bookings).map(
                  async ([key, value]) => {
                     const space = await props.firebase
                        .getSpace(value.lid, value.sid)
                        .get();
                     let loadedSpace = {
                        ...space.data(),
                        typeName: space.data().type,
                     };
                     //  if (loadedSpace.parent) {
                     //     const parent = await props.firebase
                     //        .getSpace(value.lid, loadedSpace.parent)
                     //        .get();
                     //     loadedSpace.parentDetail = {
                     //        typeName: parent.data().type,
                     //        ...parent.data(),
                     //     };
                     //  }
                     if (value?.type === 'All day') {
                        allDay.push({ bid: key, ...loadedSpace, ...value });
                     } else if (value?.type === 'AM') {
                        am.push({ bid: key, ...loadedSpace, ...value });
                     } else if (value?.type === 'PM') {
                        pm.push({ bid: key, ...loadedSpace, ...value });
                     } else {
                        // if (props.location && props.location.id === value.lid) {
                        hourly.push({
                           bid: key,
                           ...loadedSpace,
                           ...value,
                           type: false,
                        });
                        // }
                     }
                  },
               );

               Promise.all(promises).then(() => {
                  if (props.dayTime === 'AM') {
                     books.push(...am);
                  } else if (props.dayTime === 'PM') {
                     books.push(...pm);
                  } else {
                     books.push(...allDay);
                     books.push(...pm);
                     books.push(...am);
                  }
                  books.push(
                     ...hourly.sort((a, b) => a.start.toDate() - b.start.toDate()),
                  );
                  setBookings(books);
               });
            } else {
               setBookings(false);
            }
         });
      return () => subscribe();
   }, []);

   function loadTime(first, second) {
      var start = new Date(first.seconds * 1000);
      var end = new Date(second.seconds * 1000);
      var starthours = start.getHours();
      var startmins = start.getMinutes();
      var endhours = end.getHours();
      var endmins = end.getMinutes();
      var time =
         ('0' + starthours).slice(-2) +
         ':' +
         ('0' + startmins).slice(-2) +
         ' - ' +
         ('0' + endhours).slice(-2) +
         ':' +
         ('0' + endmins).slice(-2);
      return time;
   }
   return (
      <Box>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: '#f5f5f5',
                  textDecoration: 'underline',
               },
               height: '35px',
            }}
            onClick={() =>
               history.push(
                  `/schedule?view=day&date=${date.year}-${date.month + 1}-${date.day}`,
               )
            }
         >
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center', // Vertically center the icon and text
                  flexWrap: 'wrap',
                  mt: 1,
               }}
            >
               <Typography variant="caption" color="primary" sx={{ fontSize: 'small' }}>
                  {date.shortDayName}, {date.day} {date.shortMonthName}
               </Typography>
               <SchemaOutlinedIcon
                  fontSize="small"
                  color="primary"
                  sx={{ transform: 'rotate(-90deg)' }}
               />
            </Box>
         </Box>

         <Divider />
         {bookings !== null && bookings.length > 0 ? (
            <List dense={true}>
               {bookings.map((booking) => (
                  <ListItem
                     key={booking.bid}
                     alignItems="flex-start"
                     sx={{
                        pt: 0,
                        pb: 0,
                        pl: 1,
                        borderLeft: `4px solid`,
                        borderLeftColor: booking.backgroundColor
                           ? booking.backgroundColor
                           : '#C8C8C8',
                     }}
                  >
                     <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                           display: 'flex',
                           alignContent: 'center',
                           alignItems: 'center',
                        }}
                     >
                        <Avatar
                           alt={booking.typeName}
                           src={spaceTypeImage(booking.typeName)}
                           sx={{ width: 24, height: 24 }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                           <Typography variant="caption">{booking.name}</Typography>
                           {booking.timePeriod.hourly && (
                              <Typography variant="caption" color="textSecondary">
                                 {loadTime(booking.start, booking.end)}
                              </Typography>
                           )}
                        </Box>
                     </Stack>
                     {booking.lid !== authUser.lid[0] && (
                        <Tooltip
                           title={company.locations[booking.lid]}
                           arrow
                           placement="left"
                        >
                           <BusinessIcon
                              fontSize="small"
                              sx={{
                                 position: 'absolute',
                                 right: 1,
                                 color: theme.palette.text.secondary,
                              }}
                           />
                        </Tooltip>
                     )}
                  </ListItem>
               ))}
            </List>
         ) : (
            <Typography variant="caption" gutterBottom>
               No bookings.
            </Typography>
         )}
      </Box>
   );
};

export default withFirebase(BookingList);
