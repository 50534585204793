import { useEffect, useState } from 'react';
import { roleList, roleByAliasMap } from './additionalRolesListData';
import { useAuth } from '../../providers';
import { Typography, Box } from '@mui/material';
import AdditionalRolesForm from './AdditionalRolesForm';
import { withFirebase } from '../Firebase';
import ChipSet from '../ChipSet';

const initialState = null;

const AdditionalRoles = (props) => {
   const { authUser } = useAuth();
   const [selection, setSelection] = useState(initialState);
   const [profileRoles, setProfileRoles] = useState(authUser.profileRoles);

   const batch = props.firebase.db.batch();
   const lidIndexRef = props.firebase.db.doc(
      `location/${authUser.lid[0]}/indexes/profileRoles`,
   );
   const userRef = props.firebase.db.doc(`users/${authUser.uid}`);
   const coUserRef = props.firebase.db.doc(
      `company/${authUser.cid}/people/${authUser.uid}`,
   );

   useEffect(() => {
      setProfileRoles(authUser.profileRoles);
   }, [authUser.profileRoles]);

   function handleOnClear() {
      setSelection(initialState);
   }

   function handleOnChange(e, newValue) {
      if (newValue === null) {
         handleOnClear();
         return;
      }
      setSelection(newValue);
   }

   async function handleOnSubmit(e) {
      e.preventDefault();
      if (profileRoles.includes(selection)) {
         handleOnClear();
         return;
      }

      const allRoles = [...profileRoles, selection.alias];
      batch.set(lidIndexRef, { [authUser.uid]: allRoles }, { merge: true });
      batch.set(userRef, { profileRoles: allRoles }, { merge: true });
      batch.set(coUserRef, { profileRoles: allRoles }, { merge: true });

      try {
         await batch.commit();
      } catch (error) {
         console.error(error, 'error saving profile roles');
      }

      handleOnClear();
   }

   async function handleRemoveRole(value) {
      const newRoles = profileRoles.filter((role) => role !== value.alias);

      batch.set(lidIndexRef, { [authUser.uid]: newRoles }, { merge: true });
      batch.set(userRef, { profileRoles: newRoles }, { merge: true });
      batch.set(coUserRef, { profileRoles: newRoles }, { merge: true });

      try {
         await batch.commit();
      } catch (error) {
         console.error(error, 'error saving profile roles');
      }
   }

   return (
      <>
         <Typography variant="h5" gutterBottom>
            Additional Workplace Roles
         </Typography>
         <Typography>
            Add any applicable roles to your profile from the list below
         </Typography>
         <AdditionalRolesForm
            roleList={roleList}
            value={selection}
            onSubmit={handleOnSubmit}
            onChange={handleOnChange}
         />
         <Box my={1}>
            <ChipSet
               listItems={profileRoles.map((role) => roleByAliasMap()[role])}
               onDelete={handleRemoveRole}
            />
         </Box>
      </>
   );
};

export default withFirebase(AdditionalRoles);
