import React from 'react';
import { Box, Typography } from '@mui/material';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';

const OffsiteDetails = (props) => {
   return (
      <Box
         sx={{
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
         }}
      >
         {props.dayStatus.set === 'split' ? (
            <Box>
               {props.dayStatus.offsiteName[props.time] && (
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                     {props.dayStatus.offsiteLocation[props.time] !== null && (
                        <LocationCityOutlinedIcon />
                     )}

                     <Typography>{props.dayStatus.offsiteName[props.time]}</Typography>
                  </Box>
               )}{' '}
            </Box>
         ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               {props.dayStatus.offsiteLocation && <LocationCityOutlinedIcon />}

               <Typography>{props.dayStatus.offsiteName}</Typography>
            </Box>
         )}
      </Box>
   );
};
export default OffsiteDetails;
