import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import { DateTodayDBReady } from '../../../constants/functions';
import * as ROUTES from '../../../constants/routes';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@material-ui/core/colors';
import * as EmailValidator from 'email-validator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
   green: {
      color: green[700],
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: green[700],
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}));
const VisitorMeetingConfirm = ({ clearForm, ...props }) => {
   const classes = useStyles();
   const history = useHistory();
   function handleSubmit() {
      props.setForm(false);
      if (props.llid) {
         history.push({
            pathname: `/visitors?llid=${props.llid}&llocation=${props.landlocation}`,
         });
         history.go(0);
      } else {
         props.setStatus('step1');
      }
   }

   return (
      <React.Fragment>
         <Avatar className={classes.avatar}>
            <CheckCircleIcon />
         </Avatar>
         <Typography variant="h5" component="h2">
            All Done!
         </Typography>
         <React.Fragment>
            <Typography variant="body1" component="p" align="center">
               Thank you for using our Visitor Registration Form.
            </Typography>
            <Button
               onClick={() => {
                  handleSubmit();
               }}
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
            >
               Done
            </Button>
            <Button
               onClick={() => {
                  clearForm();
               }}
               fullWidth
               variant="outlined"
               color="primary"
            >
               start again
            </Button>
         </React.Fragment>
      </React.Fragment>
   );
};
export default VisitorMeetingConfirm;
