import React, { useState, useEffect } from 'react';
import ItoChip from './itoChip';
import BlankChip from './blankChip';
import AwayChip from './awayChip';
import WfhChip from './wfhChip';
import OffsiteChip from './offsiteChip';
import SplitChip from './splitChip';

const Chips = (props) => {
   const [timeBox, setTimeBox] = useState(false);
   useEffect(() => {
      if (props.timeBox) {
         setTimeBox(true);
      }
   }, [props.timeBox]);
   if (props?.value?.set) {
      switch (props.value.set) {
         case 'ito':
            return (
               <ItoChip
                  availability={props.availability}
                  bookings={props.bookings}
                  clickable={props.clickable}
                  data={props.data}
                  offsiteName={props.offsiteName}
                  routineEnabled={props.routineEnabled}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
                  spaces={props.spaces}
                  spacesEnabled={props.spacesEnabled}
                  value={props.value}
                  timeBox={props.timeBox}
                  dayTime={props.dayTime}
               />
            );
         case 'wfh':
            return (
               <WfhChip
                  availability={props.availability}
                  bookings={props.bookings}
                  clickable={props.clickable}
                  data={props.data}
                  offsiteName={props.offsiteName}
                  routineEnabled={props.routineEnabled}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
                  spaces={props.spaces}
                  spacesEnabled={props.spacesEnabled}
                  value={props.value}
                  timeBox={props.timeBox}
                  dayTime={props.dayTime}
                  miniChips={props.miniChips}
               />
            );
         case 'offsite':
            return (
               <OffsiteChip
                  availability={props.availability}
                  bookings={props.bookings}
                  clickable={props.clickable}
                  data={props.data}
                  offsiteName={props.offsiteName}
                  routineEnabled={props.routineEnabled}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
                  spaces={props.spaces}
                  spacesEnabled={props.spacesEnabled}
                  value={props.value}
                  timeBox={props.timeBox}
                  dayTime={props.dayTime}
                  miniChips={props.miniChips}
               />
            );
         case 'away':
            return (
               <AwayChip
                  clickable={props.clickable}
                  value={props.value}
                  timeBox={props.timeBox}
                  dayTime={props.dayTime}
                  miniChips={props.miniChips}
               />
            );
         case 'split':
            return (
               <SplitChip
                  availability={props.availability}
                  bookings={props.bookings}
                  clickable={props.clickable}
                  data={props.data}
                  offsiteName={props.offsiteName}
                  routineEnabled={props.routineEnabled}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
                  spaces={props.spaces}
                  spacesEnabled={props.spacesEnabled}
                  value={props.value}
                  timeBox={props.timeBox}
                  miniChips={props.miniChips}
               />
            );
         case '-':
            return (
               <BlankChip
                  clickable={props.clickable}
                  value={props.value}
                  timeBox={props.timeBox}
                  dayTime={props.dayTime}
                  miniChips={props.miniChips}
               />
            );
         default:
            return (
               <BlankChip
                  clickable={props.clickable}
                  value={props.value}
                  miniChips={props.miniChips}
               />
            );
      }
   } else {
      return (
         <BlankChip clickable={props.clickable} value={'-'} miniChips={props.miniChips} />
      );
   }
};

export default Chips;
