import React, { useEffect, useState } from 'react';
import {
   Alert,
   Grid,
   Typography,
   Button,
   Box,
   List,
   ListItem,
   IconButton,
   Tooltip,
   Popover,
   Divider,
} from '@mui/material';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { withFirebase } from '../../Firebase';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AvailabilityList from '../availabilityList';
import SplitDays from './splitDays';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const resetForm = { from: null, to: null, type: 'available' };

const TimingBox = (props) => {
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

   const theme = createTheme({
      palette: {
         primary: {
            main: prefersDarkMode ? '#32BFC7' : '#342E76',
         },
         secondary: {
            main: prefersDarkMode ? '#DB3D28' : '#DB3D28',
         },
         text: {
            primary: prefersDarkMode ? '#fff' : '#000',
         },
      },
   });

   const [availabilities, setAvailabilities] = useState(false);
   const [form, setForm] = useState(resetForm);
   const [formError, setFormError] = useState(false);

   const [snackBarOpen, setSnackBarOpen] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);

   function handleChange(type) {
      setForm({ ...form, type: type });
   }
   async function saveCustomTiming() {
      setFormError(false);
      if (form.from === null && form.to === null) {
         setFormError(true);
      } else {
         try {
            let times = [];
            if (props.savedTimes) {
               times = times.concat(props.savedTimes);
            }
            times.push(form);
            let routine = false;
            if (props.status.routine && props.status.routine === props.status.set) {
               routine = true;
            }
            await props.firebase.customTimingsSave(
               times,
               props.data.user,
               props.status,
               props.data.date.ref,
               routine,
            );
            setForm(resetForm);
            setFormError(false);
            setAvailabilities(false);
            setSnackBarOpen(true);
            setAnchorEl(null);
         } catch (error) {
            console.log(error, 'error');
         }
      }
   }
   async function deleteTimings() {
      try {
         await props.firebase.customTimingsDelete(
            props.data.user,
            props.status,
            props.data.date.ref,
         );
         setDeleteModal(false);
      } catch (error) {
         console.log(error, 'error');
      }
   }

   const handleClick = (event) => {
      if (anchorEl === null) {
         setAnchorEl(event.currentTarget);
      } else {
         setAnchorEl(null);
      }
      setAvailabilities(!availabilities);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   const handleClose = () => {
      setAnchorEl(null);
      setAvailabilities(!availabilities);
   };

   const handleSnackClose = () => {
      setSnackBarOpen(false);
   };

   var i = 0;

   const handleDeleteModalOpen = () => {
      setDeleteModal(true);
   };
   const style = {
      // position: 'absolute',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      // width: 400,
      // bgcolor: 'paper',
      // boxShadow: 24,
      // p: 4,
   };

   return (
      <ThemeProvider theme={theme}>
         <Dialog
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <DialogContent>
               <Typography paragraph>
                  Are you sure you want to delete all your availability time periods?
               </Typography>
               <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                     color="error"
                     variant="contained"
                     onClick={() => deleteTimings()}
                  >
                     Delete
                  </Button>
                  <Button onClick={() => setDeleteModal(false)}>Close</Button>
               </DialogActions>
            </DialogContent>
         </Dialog>

         <Grid container item xs={12} sx={{ mt: 3 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
               <Typography align="center" component="h1" variant="h6">
                  {props.title}
               </Typography>
            </Grid>
            {props.location?.routine && (
               <React.Fragment>
                  <Grid container item xs={12} sm={3}>
                     <Grid item xs={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: {
                                 xs: 'space-between',
                                 sm: 'space-evenly',
                              },
                              width: '100%',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           {/* <Box> */}
                           <Tooltip
                              sx={{ display: { xs: 'none', lg: 'flex' } }}
                              title="Add availability time period"
                              placement="top"
                           >
                              <Button
                                 onClick={handleClick}
                                 startIcon={<AddIcon color="primary" />}
                              >
                                 Availability
                              </Button>
                           </Tooltip>
                           <Tooltip
                              sx={{ display: { xs: 'none', sm: 'flex', lg: 'none' } }}
                              title="Add availability time period"
                              placement="top"
                           >
                              <Button
                                 onClick={handleClick}
                                 startIcon={<AddIcon color="primary" />}
                              >
                                 Avail..
                              </Button>
                           </Tooltip>
                           <Button
                              onClick={handleClick}
                              sx={{ display: { xs: 'flex', sm: 'none' } }}
                              startIcon={<AddIcon color="primary" />}
                           >
                              Availability
                           </Button>
                           {/* <IconButton onClick={handleClick} sx={{display:{ xs:'flex', sm: 'none'}}} color="primary" aria-label="Add availability">
                <AddIcon color="primary" />
              </IconButton> */}
                           {/* </Box> */}
                           <Box>
                              <Tooltip title="Delete all" placement="top">
                                 <IconButton onClick={() => handleDeleteModalOpen()}>
                                    <DeleteSweepIcon />
                                 </IconButton>
                              </Tooltip>
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={12}>
                        <List dense={true}>
                           {props.savedTimes &&
                              props.savedTimes.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           {!props.savedTimes && (
                              <ListItem>
                                 <Typography variant="caption">
                                    No custom availability hours
                                 </Typography>
                              </ListItem>
                           )}
                        </List>
                     </Grid>
                     <Divider
                        variant="middle"
                        sx={{ width: '90%', mb: 1, display: { xs: 'block', sm: 'none' } }}
                     />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                     <SplitDays
                        data={props.data}
                        setDayTime={props.setDayTime}
                        dayTime={props.dayTime}
                        status={props.status}
                        displayFilter={props.displayFilter}
                        location={props.location}
                     />
                  </Grid>
               </React.Fragment>
            )}
         </Grid>

         <Snackbar
            open={snackBarOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleSnackClose}
         >
            <Alert
               onClose={handleSnackClose}
               severity="success"
               sx={{ width: '100%', backgroundColor: 'success.main', color: 'white' }}
            >
               Availability added successfully
            </Alert>
         </Snackbar>
         <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableEnforceFocus
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
            }}
         >
            <Box sx={{ p: 2, backgroundColor: '#32BFC7' }}>
               <Typography align="center" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Set your availablility
               </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
               <div
                  style={{
                     display: 'flex',
                     alignItems: 'center',
                     flexWrap: 'wrap',
                     marginBottom: 8,
                  }}
               >
                  {form.type === 'available' ? (
                     <React.Fragment>
                        <IconButton aria-label="available" color="primary">
                           <EventAvailableIcon />
                        </IconButton>
                        <IconButton
                           aria-label="unavailable"
                           color="default"
                           onClick={() => handleChange('unavailable')}
                        >
                           <EventBusyIcon />
                        </IconButton>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <IconButton
                           aria-label="available"
                           color="default"
                           onClick={() => handleChange('available')}
                        >
                           <EventAvailableIcon />
                        </IconButton>
                        <IconButton aria-label="unavailable" color="primary">
                           <EventBusyIcon />
                        </IconButton>
                     </React.Fragment>
                  )}
                  {form.type === 'available' ? (
                     <Typography align="center">Available</Typography>
                  ) : (
                     <Typography align="center">Unavailable</Typography>
                  )}
               </div>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                     label="From"
                     value={form.from}
                     ampm={false}
                     onChange={(newValue) => {
                        setForm({ ...form, from: newValue });
                     }}
                     renderInput={(params) => (
                        <TextField fullWidth sx={{ mb: 2 }} {...params} />
                     )}
                  />
               </LocalizationProvider>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                     label="To"
                     value={form.to}
                     ampm={false}
                     onChange={(newValue) => {
                        setForm({ ...form, to: newValue });
                     }}
                     renderInput={(params) => (
                        <TextField fullWidth sx={{ mb: 2 }} {...params} />
                     )}
                  />
               </LocalizationProvider>
               {formError && (
                  <Typography variant="caption" color="error">
                     You must select at least a "From" or "To" time.
                  </Typography>
               )}
            </Box>
            <Box
               sx={{ p: 2, backgroundColor: '#fafafa' }}
               display="flex"
               justifyContent="space-evenly"
            >
               <Button
                  variant="contained"
                  color="primary"
                  onClick={() => saveCustomTiming()}
               >
                  Save
               </Button>
               <Button variant="outlined" onClick={handleClose}>
                  Close
               </Button>
            </Box>
         </Popover>
      </ThemeProvider>
   );
};
export default withFirebase(TimingBox);
