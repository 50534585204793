import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { withFirebase } from '../../Firebase';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(4),
      flexGrow: 1,
   },
   title: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
   },
   subhead: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   formControl: {
      margin: theme.spacing(3),
   },
   button: {
      margin: theme.spacing(2, 0, 0, 0),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   error: {
      color: 'red',
   },
   wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   peopleButtonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   peopleButtonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}));

function Capacity(props) {
   const classes = useStyles();
   const [unitsValue, setUnitsValue] = useState('');
   const [unitsHelperText, setUnitsHelperText] = useState('');
   const [peopleHelperText, setPeopleHelperText] = useState('');
   const [deskHelperText, setDeskHelperText] = useState('');
   const [desksDisabled, setDeskDisabled] = useState(false);
   const [deskNumber, setDeskNumber] = useState('');
   const [deskCapacity, setDeskCapacity] = useState('');
   const [deskCapacityError, setDeskCapacityError] = useState(false);
   const [deskNumberError, setDeskNumberError] = useState(false);
   const [peopleDisabled, setPeopleDisabled] = useState(true);
   const [peopleCapacity, setPeopleCapacity] = useState('');
   const [peopleCapacityError, setPeopleCapacityError] = useState(false);
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [loading, setLoading] = React.useState(false);
   const [success, setSuccess] = React.useState(false);
   const [peopleLoading, setPeopleLoading] = React.useState(false);
   const [peopleSuccess, setPeopleSuccess] = React.useState(false);
   const [changed, setChanged] = useState(false);

   const timer = React.useRef();
   const buttonClassname = clsx({
      [classes.buttonSuccess]: success,
   });
   const PeopleButtonClassname = clsx({
      [classes.peopleButtonSuccess]: peopleSuccess,
   });

   useEffect(() => {
      return () => {
         clearTimeout(timer.current);
      };
   }, []);
   function saveUnits(event) {
      setSuccess(false);
      setPeopleSuccess(false);
      props.firebase.locationCapacitySet(props.locationKey, {
         capacity: { unit: event.target.value },
      });
      setUnitsValue(event.target.value);
   }
   function saveDesk(event) {
      event.preventDefault();
      var valid = true;
      setDeskCapacityError(false);
      setDeskNumberError(false);
      setDeskHelperText('');
      if (isNaN(deskNumber) || deskNumber < 1) {
         valid = false;
         setDeskNumberError(true);
         setDeskHelperText(
            'Values must only contain numbers and percentages must be less than 100',
         );
      }
      if (isNaN(deskCapacity) || deskCapacity < 1 || deskCapacity > 100) {
         valid = false;
         setDeskCapacityError(true);
         setDeskHelperText(
            'Values must only contain numbers and percentages must be less than 100',
         );
      }
      if (valid === true) {
         setSuccess(false);
         setLoading(true);
         const setUpField = { 'setup.capacity': true };
         return (
            props.firebase.locationCapacitySet(props.locationKey, {
               capacity: { desks: { number: deskNumber, capacity: deskCapacity } },
            }),
            props.firebase.locationSetUp(props.locationKey, setUpField).then(function () {
               timer.current = setTimeout(() => {
                  setSuccess(true);
                  setLoading(false);
                  setChanged(false);
               }, 2000);
            })
         );
      }
   }
   function savePeople(event) {
      event.preventDefault();
      if (isNaN(peopleCapacity) || peopleCapacity < 1 || peopleCapacity > 100) {
         setPeopleCapacityError(true);
         setPeopleHelperText('Values must be numbers less than 100');
      } else {
         setPeopleSuccess(false);
         setPeopleLoading(true);
         const setUpField = { 'setup.capacity': true };
         return (
            props.firebase.locationCapacitySet(props.locationKey, {
               capacity: { people: { capacity: peopleCapacity } },
            }),
            props.firebase.locationSetUp(props.locationKey, setUpField).then(function () {
               setPeopleCapacityError(false);
               setPeopleHelperText('');
               timer.current = setTimeout(() => {
                  setPeopleSuccess(true);
                  setPeopleLoading(false);
                  setChanged(false);
               }, 2000);
            })
         );
      }
   }
   useEffect(() => {
      const loadCapacity = async () => {
         if (props.expanded === 'capacity' && !changed) {
            await props.firebase.location(props.locationKey).onSnapshot((result) => {
               const capacity = result.data().capacity;
               if (capacity.unit) {
                  setUnitsValue(capacity.unit);
               }
               if (capacity.people) {
                  if (capacity.people.capacity) {
                     setPeopleCapacity(capacity.people.capacity);
                  }
               }
               if (capacity.desks) {
                  if (capacity.desks.capacity) {
                     setDeskCapacity(capacity.desks.capacity);
                  }
                  if (capacity.desks.number) {
                     setDeskNumber(capacity.desks.number);
                  }
               }
            });
            if (unitsValue === 'people') {
               setUnitsHelperText(
                  'Limiting by people assumes that you want to keep a certain percentage of people intheOffice and are not limited by space',
               );
               setPeopleDisabled(false);
               setDeskDisabled(true);
            } else if (unitsValue === 'desks') {
               setUnitsHelperText(
                  'Limiting by desks is the best option if you have a fixed amount of space',
               );
               setPeopleDisabled(true);
               setDeskDisabled(false);
            }
         }
      };
      loadCapacity();
   }, [props.expanded, user]);
   return (
      <React.Fragment>
         <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
               {/* <Paper> */}
               <Typography className={classes.subhead} component="h5" variant="h6">
                  Units
               </Typography>
               <form>
                  <FormControl component="fieldset" className={classes.formControl}>
                     <FormLabel component="label">
                        Base the maximum capacity on the number of:{' '}
                     </FormLabel>
                     <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={unitsValue}
                        onChange={saveUnits}
                     >
                        <FormControlLabel
                           value="desks"
                           control={<Radio />}
                           label="Desks"
                        />
                        <FormControlLabel
                           value="people"
                           control={<Radio />}
                           label="People"
                        />
                     </RadioGroup>
                     <FormHelperText>{unitsHelperText}</FormHelperText>
                  </FormControl>
               </form>
               {/* </Paper> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               {/* <Paper> */}
               <Typography className={classes.subhead} component="h5" variant="h6">
                  Desks
               </Typography>
               <form onSubmit={saveDesk}>
                  <FormControl
                     component="fieldset"
                     className={classes.formControl}
                     disabled={desksDisabled}
                  >
                     {/* <FormLabel component="label">Set the number of desks and the maximum capacity</FormLabel> */}
                     <TextField
                        name="Number of Desks"
                        id="deskNumber"
                        label="Number of desks"
                        value={deskNumber}
                        onInput={(e) => {
                           setDeskNumber(e.target.value);
                           setChanged(true);
                           setSuccess(false);
                        }}
                        disabled={desksDisabled}
                        error={deskNumberError}
                     />
                     <TextField
                        name="Max Desk Capacity"
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                           ),
                        }}
                        id="deskCapacity"
                        label="Max Desk Capacity"
                        value={deskCapacity}
                        onInput={(e) => {
                           setDeskCapacity(e.target.value);
                           setChanged(true);
                           setSuccess(false);
                        }}
                        disabled={desksDisabled}
                        error={deskCapacityError}
                     />
                     <FormHelperText className={classes.error}>
                        {deskHelperText}
                     </FormHelperText>
                     <div className={classes.wrapper}>
                        <Button
                           className={buttonClassname}
                           variant="contained"
                           color="primary"
                           align="right"
                           disabled={desksDisabled}
                           type="submit"
                        >
                           Save
                        </Button>
                        {loading && (
                           <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                           />
                        )}
                     </div>
                  </FormControl>
               </form>
               {/* </Paper> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               {/* <Paper> */}
               <Typography className={classes.subhead} component="h5" variant="h6">
                  People
               </Typography>
               <form onSubmit={savePeople}>
                  <FormControl
                     component="fieldset"
                     className={classes.formControl}
                     disabled={peopleDisabled}
                  >
                     {/* <FormLabel component="label">Base the maximum capacity on the number of: </FormLabel> */}
                     <TextField
                        name="Max People Capacity"
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                           ),
                        }}
                        id="peopleCapacity"
                        label="Max People Capacity"
                        value={peopleCapacity}
                        onInput={(e) => {
                           setPeopleCapacity(e.target.value);
                           setChanged(true);
                           setPeopleSuccess(false);
                        }}
                        disabled={peopleDisabled}
                        error={peopleCapacityError}
                     />
                     <FormHelperText className={classes.error}>
                        {peopleHelperText}
                     </FormHelperText>
                     <div className={classes.wrapper}>
                        <Button
                           className={PeopleButtonClassname}
                           variant="contained"
                           color="primary"
                           align="right"
                           disabled={peopleDisabled}
                           type="submit"
                        >
                           Save
                        </Button>
                        {peopleLoading && (
                           <CircularProgress
                              size={24}
                              className={classes.peopleButtonProgress}
                           />
                        )}
                     </div>
                  </FormControl>
               </form>
               {/* </Paper> */}
            </Grid>
         </Grid>
      </React.Fragment>
   );
}

export default withFirebase(Capacity);
