import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withFirebase } from '../Firebase';
import {
   Radar,
   RadarChart,
   PolarGrid,
   PolarAngleAxis,
   PolarRadiusAxis,
   ResponsiveContainer,
   Tooltip,
} from 'recharts';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

const useStyles = makeStyles((theme) => ({
   paper: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      height: '500px',
   },
   title: {
      width: '100%',
   },
}));

const FavouriteDays = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const [display, setDisplay] = useState({
      ito: true,
      offsite: false,
      wfh: false,
      away: false,
   });
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   function IconCheckboxes() {
      function toggle(e) {
         setDisplay({ ...display, [e.target.name]: e.target.checked });
      }
      return (
         <Box sx={{ border: 1, pl: 2, pr: 2 }}>
            <Typography variant="caption">Toggles:</Typography>
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.ito}
               name="ito"
               icon={<CheckCircleOutlineIcon />}
               checkedIcon={<CheckCircleIcon style={{ fill: theme.palette.ito.main }} />}
            />
            {props.location.offsiteEnabled && (
               <Checkbox
                  {...label}
                  onClick={(e) => toggle(e)}
                  checked={display.offsite}
                  name="offsite"
                  icon={<PinDropIcon />}
                  checkedIcon={
                     <PinDropIcon style={{ fill: theme.palette.offsite.main }} />
                  }
               />
            )}
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.wfh}
               name="wfh"
               icon={<HomeIcon />}
               checkedIcon={<HomeIcon style={{ fill: theme.palette.wfh.main }} />}
            />
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.away}
               name="away"
               icon={<DoDisturbOffIcon />}
               checkedIcon={<DoDisturbOnIcon style={{ fill: theme.palette.away.main }} />}
            />
         </Box>
      );
   }

   return (
      <React.Fragment>
         <Paper>
            <Grid container spacing={0}>
               <Grid item xs={12}>
                  <Typography sx={{ pt: 3, pl: 3 }} variant="h5" component="h2">
                     Favourite Days
                  </Typography>
               </Grid>
               <Grid item xs={12} sx={{ p: 2 }}>
                  <IconCheckboxes display={display} />
               </Grid>

               {!props.favDays ? (
                  <Typography>Loading ... </Typography>
               ) : (
                  <Grid item xs={12} className={classes.paper}>
                     <ResponsiveContainer width="100%" height="100%">
                        <RadarChart
                           cx="50%"
                           cy="50%"
                           outerRadius="80%"
                           data={props.favDays}
                        >
                           <PolarGrid />
                           <PolarAngleAxis dataKey="name" />
                           <PolarRadiusAxis domain={[0, props.max]} />
                           <Tooltip />
                           {display.ito && (
                              <Radar
                                 name="ito"
                                 dataKey="ito"
                                 stroke="#8884d8"
                                 fill={theme.palette.ito.main}
                                 fillOpacity={0.6}
                              />
                           )}
                           {display.offsite && (
                              <Radar
                                 name="offsite"
                                 dataKey="offsite"
                                 stroke="#8884d8"
                                 fill={theme.palette.offsite.main}
                                 fillOpacity={0.6}
                              />
                           )}
                           {display.wfh && (
                              <Radar
                                 name="wfh"
                                 dataKey="wfh"
                                 stroke="#8884d8"
                                 fill={theme.palette.wfh.main}
                                 fillOpacity={0.6}
                              />
                           )}
                           {display.away && (
                              <Radar
                                 name="away"
                                 dataKey="away"
                                 stroke="#8884d8"
                                 fill={theme.palette.away.main}
                                 fillOpacity={0.6}
                              />
                           )}
                        </RadarChart>
                     </ResponsiveContainer>
                  </Grid>
               )}
            </Grid>
         </Paper>
      </React.Fragment>
   );
};

export default withFirebase(FavouriteDays);
