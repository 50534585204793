import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useFirebase } from '../../Firebase';
import useFirebaseRequest from '../../../hooks/useFirebaseRequest';
import { allCompanies } from '../firebaseRefs';
import { CardSection } from '../../layout';
import CoCard from './coCard';

const NewSignUps = () => {
   const firebase = useFirebase();
   const [companies, setCompanies] = useState([]);

   useEffect(() => {
      const getData = async () => {
         const fourteenDaysAgo = new Date();
         fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

         try {
            const results = await allCompanies()
               .where('profile.creationDate', '>=', fourteenDaysAgo)
               .get();
            setCompanies(results.docs.map((doc) => ({ cid: doc.id, ...doc.data() })));
         } catch (error) {
            console.error('Error fetching companies: ', error);
         }
      };
      getData();
   }, []);

   return (
      <CardSection sx={{ m: 2 }}>
         <Typography variant="h4" component="h3">
            New (last 14 days)
         </Typography>
         <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {companies.map((company) => (
               <CoCard key={company.cid} company={company} firebase={firebase} />
            ))}
         </Box>
      </CardSection>
   );
};

export default NewSignUps;
