import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import TenantCards from './tenantCards';
import CalloutBox from '../../layout/CalloutBox';
import Customisations from './customisations';

const LandlordVisitor = ({ location, lid, cid }) => {
   const [tenants, setTenants] = useState([]);
   const kioskLink = `https://app.intheOffice.io/visitors?llid=${lid}&llocation=${location.name}`;

   useEffect(() => {
      if (location.visitorHostType === 'Landlord' && location.tenantNumber > 0) {
         let data = [];
         Object.entries(location.tenantDetails).forEach(([key, value]) => {
            data.push({ lid: key, ...value });
         });
         setTenants(data);
      }
   }, [location.visitorHostType, location.tenantNumber]);
   return (
      <Box>
         <Box>
            <Typography variant="h6">Kiosk Link</Typography>
            <CalloutBox contents={kioskLink} sx={{ width: '100%' }} />
         </Box>
         <Divider sx={{ mt: 1, mb: 1 }} />
         <Typography variant="h6">Linked Tenants</Typography>

         <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {tenants.length > 0 ? (
               tenants.map((tenant, index) => <TenantCards tenant={tenant} key={index} />)
            ) : (
               <Typography>No linked tenants</Typography>
            )}
         </Box>
         <Divider sx={{ mt: 1, mb: 1 }} />
         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Landlord Key</Typography>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            >
               <CalloutBox contents={lid + '*' + cid} sx={{ width: '100%' }} />
            </Box>
            <Typography variant="caption">
               Give your tenants this key to enter into their visitor settings panel to
               add the link to your intheOffice profile.
            </Typography>
         </Box>
         <Divider sx={{ mt: 1, mb: 1 }} />
         <Box>
            <Customisations lid={lid} />
         </Box>
      </Box>
   );
};

export default LandlordVisitor;
