import React, { useEffect, useState } from 'react';

import { withFirebase } from '../../Firebase';
import {
   Grid,
   Typography,
   FormControl,
   FormGroup,
   FormControlLabel,
   Switch,
   Box,
} from '@mui/material';
import EditPreviousDaysPermissions from './editPreviousDaysPermissions';
import CompanySelect from './companySelect';

const SchedulePanel = (props) => {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [status, setStatus] = useState(false);

   useEffect(() => {
      if (props.expanded === 'scheduling') {
         const unsubscribe = props.firebase.company(authUser.cid).onSnapshot((result) => {
            if (
               result.data().publishedLocations &&
               result.data().publishedLocations[props.lid]
            ) {
               setStatus(true);
            } else {
               setStatus(false);
            }
         });
         return () => unsubscribe();
      }
   }, [props.expanded]);

   async function handleChange(event) {
      await props.firebase
         .company(authUser.cid)
         .set({ publishedLocations: { [props.lid]: !status } }, { merge: true });
   }

   return (
      <React.Fragment>
         <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                  <Typography variant="h6" component="h2">
                     Publish location schedule
                  </Typography>
                  <Typography variant="body1">
                     Publish the schedule for {props.locationName} so that it is visible
                     to all people within your company. If enabled, people can select this
                     location from the filter at the top of the Dashboard and Schedule
                     pages to view where people belonging to this location are working.
                  </Typography>
               </Box>
               <Box
                  sx={{
                     width: {
                        xs: '100%',
                        sm: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                     },
                  }}
               >
                  <FormControl component="fieldset">
                     <FormGroup aria-label="position" row>
                        <FormControlLabel
                           value="top"
                           control={
                              <Switch
                                 color="primary"
                                 checked={status}
                                 onChange={(event) => {
                                    handleChange(event);
                                 }}
                              />
                           }
                           label="Enable"
                           labelPlacement="top"
                        />
                     </FormGroup>
                  </FormControl>
               </Box>
               {status &&
                  (authUser.cid === 'qAWtyssD4ZCqGXErCyZZ' ||
                     authUser.cid === 'OqrXXfBEATBoT6EQOj3K' ||
                     authUser.cid === '2fp8D8shclw1dEUAxYjt') && (
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           mt: 2,
                        }}
                     >
                        <Box
                           sx={{
                              width: {
                                 xs: '100%',
                                 sm: '50%',
                                 display: 'flex',
                                 justifyContent: 'center',
                                 flexDirection: 'column',
                              },
                           }}
                        >
                           <Typography variant="h6" component="h2" paragraph>
                              Exceptions
                           </Typography>
                           <Typography variant="body1">
                              You can exclude (hide) this location from being visible by
                              people in other specific locations. Please these locations
                              from the list:
                           </Typography>
                        </Box>
                        <Box
                           sx={{
                              width: {
                                 xs: '100%',
                                 sm: '50%',
                                 display: 'flex',
                                 justifyContent: 'center',
                              },
                           }}
                        >
                           <CompanySelect lid={props.lid} />
                        </Box>
                     </Box>
                  )}
            </Box>
            <Box sx={{ mt: 2 }}>
               <EditPreviousDaysPermissions lid={props.lid} expanded={props.expanded} />
            </Box>
         </Box>
      </React.Fragment>
   );
};

export default withFirebase(SchedulePanel);
