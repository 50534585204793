import React from 'react';
import { Typography } from '@mui/material';
import { PageLayout } from '../layout';

const PermissionDenied = (props) => {
   return (
      <PageLayout>
         <Typography component="h1" variant="h2">
            Permission Denied
         </Typography>
         <Typography variant="body1">
            Oops, it looks like you don't have permission to view this page.
         </Typography>
      </PageLayout>
   );
};

export default PermissionDenied;
