import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { MenuItem } from '@material-ui/core';
import { spaceTypes } from '../../constants/data';
import ColorPicker from './colourPicker';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(0),
   },
   steps: {
      width: '100%',
   },
   select: {
      minWidth: 250,
   },
   backButton: {
      marginRight: theme.spacing(1),
   },
   instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   buttons: {
      margin: theme.spacing(3, 0, 2),
   },
}));

function getSteps() {
   return ['Details', 'Bookings', 'Features'];
}

const SpaceForm = (props) => {
   const classes = useStyles();
   const parents = props.parents;
   const [formValues, setFormValues] = useState({
      name: '',
      description: '',
      seats: '',
      type: '',
      parent: '',
      bookable: true,
      allDay: true,
      ampm: false,
      hourly: false,
      approvalRequired: false,
      amenities: [],
      archive: false,
      backgroundColor: false,
   });
   const [spaceNameError, setSpaceNameError] = useState(false);
   const [spaceTypeError, setSpaceTypeError] = useState(false);
   const [spaceFeatures, setSpaceFeatures] = useState([]);
   const [activeStep, setActiveStep] = React.useState(0);
   const [timeValue, setTimeValue] = useState('allDay');
   const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

   useEffect(() => {
      if (props.editSpace) {
         getSpace();
      }
   }, []);

   async function getSpace() {
      const result = await props.firebase
         .spaces(props.locationKey)
         .doc(props.spaceKey)
         .get();
      let details = result.data();
      details.allDay = details.timePeriod.allDay;
      details.ampm = details.timePeriod.ampm;
      details.hourly = details.timePeriod.hourly;
      details.edit = true;
      if (!details.backgroundColor || details.backgroundColor === undefined) {
         setBackgroundColor('#FFFFFF');
         details.backgroundColor = '#FFFFFF';
      } else {
         setBackgroundColor(details.backgroundColor);
      }
      setFormValues(details);
      let amenities = [];
      details.amenities.map((item) => {
         amenities.push({ name: item });
      });
      setSpaceFeatures(amenities);
   }

   const steps = getSteps();
   const handleNext = () => {
      var valid = true;
      if (activeStep === 0) {
         if (formValues.name === '') {
            setSpaceNameError(true);
            valid = false;
         }
         if (formValues.type === '') {
            setSpaceTypeError(true);
            valid = false;
         }
      }
      if (valid === true) {
         setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };

   const handleReset = () => {
      setActiveStep(0);
   };

   const handleSpaceFeatures = (event, values) => {
      setSpaceFeatures(values);
      setFormValues({ ...formValues, spaceFeatures: values });
   };
   async function saveSpace(event) {
      event.preventDefault();

      var amenities = [];
      if (spaceFeatures.length !== 0) {
         Object.values(spaceFeatures).map((feature) => {
            amenities.push(feature.name);
         });
      } else {
         amenities = [];
      }
      var data = {
         name: formValues.name,
         description: formValues.description,
         seats: Number(formValues.seats),
         type: formValues.type,
         parent: formValues.parent,
         bookable: formValues.bookable,
         timePeriod: {
            allDay: formValues.allDay,
            ampm: formValues.ampm,
            hourly: formValues.hourly,
         },
         approvalRequired: formValues.approvalRequired,
         amenities,
         archive: formValues.archive,
         backgroundColor: formValues.backgroundColor,
      };

      // data.timePeriod[timeValue] = true;
      if (props.spaceKey === null) {
         await props.firebase.spaces(props.locationKey).doc().set(data, { merge: true });
      } else {
         await props.firebase
            .spaces(props.locationKey)
            .doc(props.spaceKey)
            .set(data, { merge: true });
      }
      props.setSpacesDrawerOpen(false);
   }
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };
   const handleInputChangeCheckbox = (e) => {
      const { name, checked } = e.target;
      setFormValues({ ...formValues, [name]: checked });
   };
   function handleChange(e) {
      setTimeValue(e.target.value);
      let timeVals = {
         allDay: false,
         ampm: false,
         hourly: false,
      };
      timeVals[e.target.value] = true;
      setFormValues({ ...formValues, ...timeVals });
   }
   function getStepContent(stepIndex) {
      switch (stepIndex) {
         case 0:
            return (
               <React.Fragment>
                  <Grid item xs={12} sm={12}>
                     <TextField
                        name="name"
                        variant="outlined"
                        fullWidth
                        id="name"
                        label="Name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        required
                        error={spaceNameError}
                        autoFocus
                     />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={4}
                        id="description"
                        label="Description"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <ColorPicker
                        setFormValues={setFormValues}
                        backgroundColor={backgroundColor}
                        formValues={formValues}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl className={classes.select} variant="outlined">
                        <InputLabel id="outlined-parent-native-simple">
                           Parent Space
                        </InputLabel>
                        <Select
                           labelId="outlined-parent-native-simple"
                           value={formValues.parent}
                           onChange={handleInputChange}
                           label="Parent Space"
                           name="parent"
                        >
                           <MenuItem aria-label="None" value="">
                              <em>None</em>
                           </MenuItem>
                           {Object.entries(parents).map(([key, value], i) => {
                              return (
                                 <MenuItem key={key} value={key}>
                                    {value.name}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl
                        className={classes.select}
                        required
                        error={spaceTypeError}
                        variant="outlined"
                     >
                        <InputLabel htmlFor="outlined-type-native-simple">
                           Type
                        </InputLabel>
                        <Select
                           native
                           name="type"
                           value={formValues.type}
                           onChange={handleInputChange}
                           label="Type"
                           inputProps={{
                              name: 'type',
                              id: 'outlined-type-native-simple',
                           }}
                        >
                           <option aria-label="None" value="" />
                           {Object.entries(spaceTypes).map(([key, type]) => (
                              <option key={key} value={key}>
                                 {type}
                              </option>
                           ))}
                        </Select>
                     </FormControl>
                  </Grid>
               </React.Fragment>
            );
         case 1:
            return (
               <React.Fragment>
                  <Grid item xs={12}>
                     <FormLabel component="legend">
                        How many seats can be reserved in this space?
                     </FormLabel>
                     <TextField
                        name="seats"
                        variant="outlined"
                        id="seats"
                        label="Number of Seats"
                        value={formValues.seats}
                        onChange={handleInputChange}
                        type="number"
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormLabel component="legend">Space reservations:</FormLabel>
                     <FormControlLabel
                        control={
                           <Switch
                              checked={formValues.bookable}
                              onChange={handleInputChangeCheckbox}
                              name="bookable"
                              color="primary"
                           />
                        }
                        label="Alow users to book this space"
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">
                           Allow people to reserve this space:
                        </FormLabel>
                        <FormGroup>
                           <RadioGroup
                              aria-labelledby="booking-time-period"
                              // defaultValue="allDay"
                              name="booking-time-period"
                              value={timeValue}
                              onChange={handleChange}
                           >
                              <FormControlLabel
                                 value="allDay"
                                 control={
                                    <Radio
                                       disabled={!formValues.bookable || formValues.edit}
                                    />
                                 }
                                 label="All Day"
                              />
                              {/* <FormControlLabel value="ampm" control={<Radio disabled={!formValues.bookable || formValues.edit} />} label="Am/PM" /> */}
                              <FormControlLabel
                                 value="hourly"
                                 control={
                                    <Radio
                                       disabled={!formValues.bookable || formValues.edit}
                                    />
                                 }
                                 label="Hourly"
                              />
                           </RadioGroup>
                        </FormGroup>
                     </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
            <FormLabel component="legend">Do bookings for this space require approval?</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.approvalRequired}
                    onChange={handleInputChangeCheckbox}
                    name="approvalRequired"
                    color="primary"
                    disabled={!formValues.bookable}
                  />
                }
                label="Require approval"
              />
            </Grid> */}
               </React.Fragment>
            );
         case 2:
            return (
               <React.Fragment>
                  <Grid item xs={12}>
                     <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={spaceAmenities}
                        disableCloseOnSelect
                        value={spaceFeatures}
                        getOptionSelected={(option, spaceFeatures) =>
                           option.name === spaceFeatures.name
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={handleSpaceFeatures}
                        renderOption={(option, { selected }) => (
                           <React.Fragment>
                              <Checkbox
                                 icon={icon}
                                 checkedIcon={checkedIcon}
                                 style={{ marginRight: 8 }}
                                 checked={selected}
                              />
                              {option.name}
                           </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              variant="outlined"
                              label="Space features"
                              placeholder="e.g. Height adjustable desk"
                           />
                        )}
                     />
                  </Grid>
               </React.Fragment>
            );
         default:
            return 'Unknown stepIndex';
      }
   }

   return (
      <React.Fragment>
         <Typography component="h1" variant="h5">
            {props.editSpace ? 'Edit Space' : 'Create a new Space'}
         </Typography>

         <div className={classes.steps}>
            <Stepper activeStep={activeStep} alternativeLabel>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel>{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>
            <div>
               {activeStep === steps.length ? (
                  <div>
                     <Typography className={classes.instructions}>
                        All steps completed
                     </Typography>
                     <Button onClick={handleReset}>Reset</Button>
                  </div>
               ) : (
                  <Container maxWidth="xs">
                     <div>
                        <form className={classes.form}>
                           <Grid container spacing={2}>
                              {getStepContent(activeStep)}
                           </Grid>
                           <div className={classes.buttons}>
                              <Button
                                 disabled={activeStep === 0}
                                 onClick={handleBack}
                                 className={classes.backButton}
                              >
                                 Back
                              </Button>
                              {activeStep === steps.length - 1 ? (
                                 <Button
                                    onClick={saveSpace}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitButton}
                                    align="right"
                                 >
                                    Save Space
                                 </Button>
                              ) : (
                                 <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleNext}
                                 >
                                    Next
                                 </Button>
                              )}
                           </div>
                        </form>
                     </div>
                  </Container>
               )}
            </div>
         </div>
      </React.Fragment>
   );
};

const spaceAmenities = [
   { name: 'Desk storage' },
   { name: 'Ergonomic chair' },
   { name: 'External monitor' },
   { name: 'Height adjustable desk' },
   { name: 'Keyboard + mouse' },
   { name: 'Lockable door' },
   { name: 'Multi screen' },
   { name: 'Multiple seat' },
   { name: 'Printer' },
   { name: 'Projector' },
   { name: 'Window view' },
   { name: 'Tv monitor' },
   { name: 'White boards' },
];

export default withFirebase(SpaceForm);
