import React, { useEffect, useState } from 'react';
import {
   Button,
   Box,
   FormGroup,
   FormControlLabel,
   FormHelperText,
   Switch,
   Grid,
   Typography,
   FormControl,
   CircularProgress,
   Alert,
} from '@mui/material';
import { TextField } from '@material-ui/core';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmptyObject } from '../../utils/objectUtils';
import Dialog from '../../components/Dialog';
import GroupAddVideoHelper from './GroupAddVideoHelper';
import GroupMembersList from './GroupMembersList';
import { useFirebase } from '../../components/Firebase';
import useFirebaseRequest from '../../hooks/useFirebaseRequest';
import useGetAllCompanyPeople from './hooks/useGetAllCompanyPeople';
import useSaveGroup from './hooks/useSaveGroup';
import useDeleteGroup from './hooks/useDeleteGroup';
import { usePage } from '../../components/layout/PageLayout';
import ConfirmDeleteButton from '../../components/ConfirmDeleteButton/ConfirmDeleteButton';
import { useLocations } from '../../providers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { addGroupFormSchema, GroupTemplate } from './utils/models';

const GroupDetailsModal = ({ title, gid, lid, onClose, newGroup }) => {
   const firebase = useFirebase();
   const newGroupDetails = new GroupTemplate(lid);

   const { handleSelectGroup, isMobile } = usePage();
   const { allLocations } = useLocations();
   const [showGlobalSwitchInfo, setShowGlobalSwitchInfo] = useState(false);

   const {
      loading: allPeopleLoading,
      data: allPeopleData,
      error: allPeopleError,
   } = useGetAllCompanyPeople(lid);

   const { onDelete } = useDeleteGroup(lid, gid, {
      successMessage: `Group was deleted`,
      errorMessage: `Group was not deleted. Please try again later`,
      callback: handleSelection,
   });
   const { loading: isSaving, onSave } = useSaveGroup(lid, gid, {
      successMessage: `Group was updated`,
      errorMessage: `Group was not updated. Please try again later`,
      callback: onClose,
   });

   const { loading, data: groupDetails } = useFirebaseRequest(
      firebase.locationGroupDetails,
      {
         args: [lid, gid],
         sortFunction: (response) =>
            response ? { ...newGroupDetails, ...response } : { ...newGroupDetails },
      },
   );

   const methods = useForm({
      defaultValues: newGroupDetails,
      mode: 'all',
      resolver: yupResolver(addGroupFormSchema),
   });

   const global = methods.watch('global');
   const externalMemberCount = methods.watch('externalMembers');

   function getRenderedPeople() {
      return global
         ? allPeopleData.sorted
         : allPeopleData.sorted.filter((person) => !person.externalLocation);
   }

   function handleSelection() {
      handleSelectGroup(undefined, 'all');
      onClose();
   }

   useEffect(() => {}, [global]);
   useEffect(() => {
      if (groupDetails) {
         methods.reset(groupDetails);
      }
   }, [groupDetails]);

   return (
      <Dialog
         open={!!gid}
         onClose={onClose}
         disableEscapeKeyDown
         disableBackdropClick
         fullWidth
         maxWidth="md"
         title={title}
         loading={loading || allPeopleLoading}
         contextData={{
            groupDetails,
            global,
            getRenderedPeople,
            allPeopleData,
            allPeopleError,
         }}
         actions={
            <Grid container spacing={1}>
               <Grid item xs={6} sm={6} md>
                  {!newGroup && (
                     <ConfirmDeleteButton
                        onClick={methods.handleSubmit(onDelete)}
                        color="error"
                        variant="outlined"
                        fullWidth={isMobile}
                     >
                        Delete
                     </ConfirmDeleteButton>
                  )}
               </Grid>
               <Grid item xs={6} sm={6} md="auto">
                  <Button variant="outlined" onClick={onClose} fullWidth>
                     Close
                  </Button>
               </Grid>
               <Grid item xs={12} sm={12} md="auto">
                  <Button
                     fullWidth
                     variant="contained"
                     onClick={methods.handleSubmit(onSave)}
                     disabled={
                        !methods.formState.isValid ||
                        methods.formState.isSubmitting ||
                        getRenderedPeople().length === 0 ||
                        isSaving
                     }
                  >
                     {isSaving ? <CircularProgress /> : 'Submit'}
                  </Button>
               </Grid>
            </Grid>
         }
      >
         <FormProvider {...methods}>
            {!isEmptyObject(methods.getValues()) && (
               <Box>
                  {/* {newGroup && <GroupAddVideoHelper />} */}
                  <Box my={3}>
                     <Typography variant="h6">Group details</Typography>
                  </Box>

                  <Grid container spacing={3}>
                     <Grid item xs={12} sm={12} md={6}>
                        <Controller
                           name="name"
                           control={methods.control}
                           render={({ field, fieldState }) => {
                              return (
                                 <FormControl fullWidth autoFocus>
                                    <TextField
                                       {...field}
                                       variant="outlined"
                                       autoFocus
                                       label="Name"
                                       id="name"
                                       error={fieldState.invalid}
                                       placeholder="A group name..."
                                       helperText={
                                          fieldState.invalid
                                             ? fieldState.error.message
                                             : 'Name of the group'
                                       }
                                    />
                                 </FormControl>
                              );
                           }}
                        />
                     </Grid>

                     <Grid item xs={12} sm={12} md={6}>
                        <Box display="flex" gap={1} mb={2}>
                           <LocationOnIcon />
                           <Typography>
                              Group location base:{' '}
                              <strong>{global ? 'Global' : allLocations[lid]}</strong>
                           </Typography>
                        </Box>

                        <FormGroup sx={{ gap: 2, px: 2 }}>
                           <Box>
                              <Controller
                                 name="locationPublic"
                                 control={methods.control}
                                 render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                       required
                                       control={
                                          <Switch checked={value} onChange={onChange} />
                                       }
                                       label="Public"
                                    />
                                 )}
                              />
                              <FormHelperText>
                                 Control whether the group is only visible to members of
                                 the group or everyone across the location.
                              </FormHelperText>
                           </Box>

                           <Box>
                              <Controller
                                 name="global"
                                 control={methods.control}
                                 render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                       required
                                       control={
                                          <Switch
                                             checked={value}
                                             onChange={() => {
                                                if (global) {
                                                   if (!!externalMemberCount.length) {
                                                      setShowGlobalSwitchInfo(true);
                                                   } else {
                                                      setShowGlobalSwitchInfo(false);
                                                      onChange(!value);
                                                   }
                                                } else {
                                                   setShowGlobalSwitchInfo(false);
                                                   onChange(!value);
                                                }
                                             }}
                                          />
                                       }
                                       label="Global"
                                    />
                                 )}
                              />
                              <FormHelperText>
                                 Control whether the group is visible across the entire
                                 company (all locations).
                              </FormHelperText>
                              {showGlobalSwitchInfo && (
                                 <Alert
                                    severity="error"
                                    sx={{ mt: 1 }}
                                    onClose={() => setShowGlobalSwitchInfo(false)}
                                 >
                                    There are external members still part of the group.
                                    Please remove them first
                                 </Alert>
                              )}
                           </Box>
                        </FormGroup>
                     </Grid>
                  </Grid>

                  {allPeopleData && <GroupMembersList />}
                  {allPeopleError && <p>error</p>}
               </Box>
            )}
         </FormProvider>
      </Dialog>
   );
};

export default GroupDetailsModal;
