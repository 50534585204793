import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const AwayPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 35 34">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="status/ITOPM-Copy" transform="translate(1.1961, 1.1961)">
               <g id="do_not_disturb_on_black_24dp" transform="translate(-1, -1)">
                  <path
                     d="M28,7 C22.48,7 18,11.48 18,17 C18,22.52 22.48,27 28,27 C33.52,27 38,22.52 38,17 C38,11.48 33.52,7 28,7 Z M33,18 L23,18 L23,16 L33,16 L33,18 Z"
                     id="Shape"
                     fill="#DB3D28"
                     fillRule="nonzero"
                  ></path>
                  <line
                     x1="23.5"
                     y1="0.5"
                     x2="0.5"
                     y2="33.5"
                     id="Line-6"
                     stroke={theme.palette.primary.main}
                     strokeLinecap="square"
                  ></line>
               </g>
            </g>
         </g>
      </SvgIcon>
   );
};

export default AwayPm;
