import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { spaceTypeImage } from '../functions';

import { Box, IconButton, Typography } from '@mui/material';
import { spaceTypes } from '../../../constants/data';
import { withFirebase } from '../../Firebase';

const SpaceCardHeader = (props) => {
   const user = props.user;
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [image, setImage] = useState('');
   const [imageExists, setImageExists] = useState(false);

   useEffect(() => {
      let isMounted = true;

      if (props.space.imagePath) {
         var imageRef = props.firebase.showImage(props.space.imagePath);
         imageRef
            .getDownloadURL()
            .then(function (url) {
               if (isMounted) {
                  setImage(url);
                  setImageExists(true);
               }
            })
            .catch(function (error) {
               if (isMounted) {
                  console.warn(error, 'error');
               }
            });
      }

      return () => {
         isMounted = false;
      };
   }, []);

   const toggleFavourite = async (space) => {
      let favourites = user.favouriteSpaces;
      if (space.favourite) {
         const index = favourites.indexOf(space.sid);
         if (index > -1) {
            favourites.splice(index, 1);
         }
         await props.firebase
            .companyPerson(user.cid, user.uid)
            .set({ favouriteSpaces: favourites }, { merge: true });
      } else {
         if (favourites.length <= 9) {
            favourites.push(space.sid);
            await props.firebase
               .companyPerson(user.cid, user.uid)
               .set({ favouriteSpaces: favourites }, { merge: true });
         } else {
            alert(
               'You can only add up to 10 favourites. Please remove an existing one and try again.',
            );
         }
      }
   };
   return (
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
         <Box sx={{ ml: '-8px', mt: '-8px' }}>
            {user !== null && user.uid === authUser.uid ? (
               <IconButton
                  onClick={() => {
                     toggleFavourite(props.space);
                  }}
                  aria-label="favourite"
               >
                  {props.space.favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
               </IconButton>
            ) : props.space.favourite ? (
               <IconButton disabled>
                  <FavoriteIcon />
               </IconButton>
            ) : (
               <IconButton disabled>
                  <FavoriteBorderIcon />
               </IconButton>
            )}
         </Box>
         <Box>
            <Avatar alt={props.space.type} src={spaceTypeImage(props.space.type)} />
         </Box>
         <Box sx={{ ml: 1 }}>
            <Typography variant="body2">{props.space.name}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'start', color: 'gray' }}>
               <Typography variant="caption">{spaceTypes[props.space.type]}</Typography>
            </Box>
         </Box>
      </Box>
   );
};
export default withFirebase(SpaceCardHeader);
