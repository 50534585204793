import { DateToday } from '../../../constants/functions';

export function roundToNearestHour(date) {
   // date.setMinutes(date.getMinutes() + 30);
   // date.setMinutes(0, 0, 0);
   date.setMinutes(Math.ceil(date.getMinutes() / 30) * 30);

   return date;
}

export const myEventsList = [
   {
      id: 'randomstringg1',
      title: 'Event 1',
      host: {
         name: 'Tim Smith',
         uid: 'props.bookingForm.user.uid',
      },
      start: new Date(2022, 4, 5, 9, 30, 0),
      end: new Date(2022, 4, 5, 10, 30, 0),
      seats: 3,
      internalParticipants: null,
      externalGuests: null,
      catering: '',
      it: '',
   },
   {
      id: 'randomstringg2',
      title: 'Event 3',
      host: {
         name: 'props.bookingForm.user.name.firstName + props.bookingForm.user.name.lastName',
         uid: 'props.bookingForm.user.uid',
      },
      start: new Date(2022, 4, 5, 9, 30, 0),
      end: new Date(2022, 4, 5, 10, 30, 0),
      seats: 3,
      internalParticipants: null,
      externalGuests: null,
      catering: '',
      it: '',
   },
];

function diff_minutes(dt2, dt1) {
   var diff = (dt2.getTime() - dt1.getTime()) / 1000;
   diff /= 60;
   return Math.abs(Math.round(diff));
}

export function calculateAvailability(bookings, date) {
   console.log(bookings, 'result data');
   console.log(date, 'date');
   console.log(DateToday());
   const today = DateToday();
   console.log(today);
   if (bookings.bookingNo === 0) {
      return 0;
   } else {
      let periodStart = new Date(today.year, today.month, today.day, 9, 0);
      let periodEnd = new Date(today.year, today.month, today.day, 18, 0);
      let timeUsed = 0;
      if (Number(date.ref) == Number(today.ref)) {
         periodStart = new Date();
      }
      Object.entries(bookings.bookings).map(([key, value]) => {
         if (Number(date.ref) == Number(today.ref)) {
            if (value.start.toDate() > new Date()) {
               timeUsed =
                  timeUsed + diff_minutes(value.end.toDate(), value.start.toDate());
            }
            if (value.start.toDate() < new Date() && value.end.toDate() > new Date()) {
               timeUsed = timeUsed + diff_minutes(new Date(), value.end.toDate());
            }
         } else {
            timeUsed = timeUsed + diff_minutes(value.end.toDate(), value.start.toDate());
         }
      });
      console.log(timeUsed, 'used');
      let availability = diff_minutes(periodEnd, periodStart);
      let cap = (timeUsed / availability) * 100;
      console.log(cap, 'cap');

      if (cap < 30) {
         return 1;
      } else if (cap >= 30 && cap < 50) {
         return 2;
      } else if (cap >= 50 && cap < 75) {
         return 3;
      } else if (cap >= 75 && cap < 80) {
         return 4;
      } else {
         return 5;
      }
   }
}

export const naturalCompare = (a, b) => {
   const chunkRegex = /(\d+)|(\D+)/g;
   const ax = a.match(chunkRegex);
   const bx = b.match(chunkRegex);

   while (ax && bx && ax.length > 0 && bx.length > 0) {
      const chunkA = ax.shift();
      const chunkB = bx.shift();

      if (chunkA !== chunkB) {
         const numA = parseInt(chunkA, 10);
         const numB = parseInt(chunkB, 10);

         if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
         }
         return chunkA.localeCompare(chunkB);
      }
   }

   return a.localeCompare(b);
};
