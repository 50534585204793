import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { withFirebase } from '../Firebase';
import {
   BarChart,
   Bar,
   Cell,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer,
} from 'recharts';

import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { IconButton } from '@mui/material';
import ExportButton from '../Reporting/exportButton';

const useStyles = makeStyles((theme) => ({
   paper: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
   },
   title: {
      width: '100%',
   },
}));

const OverallNumbers = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const [display, setDisplay] = useState({
      ito: true,
      offsite: false,
      wfh: false,
      away: false,
      ['-']: false,
   });
   const headers = () => {
      let labels = ['Name'];
      Object.entries(display).map(([key, value]) => {
         if (value) {
            labels.push(key);
         }
      });
      return labels;
   };
   const exportData = () => {
      let data = [];
      props.loadedData.map((row) => {
         let dataLoad = {
            name: row.name,
         };
         Object.entries(display).map(([key, value]) => {
            if (value) {
               dataLoad[key] = row[key];
            }
         });
         data.push(dataLoad);
      });
      return data;
   };
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const calculateHeight = () => {
      let heightValue = 500;
      let multiply = 32;
      if (props.loadedData.length < 40) {
         multiply = 60;
      }
      if (props.fullDisplay === 'overall') {
         heightValue = props.loadedData.length * multiply;
      }
      return heightValue + 'px';
   };
   const calculateWidth = () => {
      let widthValue = 60;
      if (props.fullDisplay === 'overall') {
         widthValue = 120;
      }
      return widthValue;
   };
   function IconCheckboxes() {
      function toggle(e) {
         setDisplay({ ...display, [e.target.name]: e.target.checked });
      }
      return (
         <Box sx={{ border: 1, pl: 2, pr: 2 }}>
            <Typography variant="caption">Toggles:</Typography>
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.ito}
               name="ito"
               icon={<CheckCircleOutlineIcon />}
               checkedIcon={<CheckCircleIcon style={{ fill: theme.palette.ito.main }} />}
            />
            {props.location.offsiteEnabled && (
               <Checkbox
                  {...label}
                  onClick={(e) => toggle(e)}
                  checked={display.offsite}
                  name="offsite"
                  icon={<PinDropIcon />}
                  checkedIcon={
                     <PinDropIcon style={{ fill: theme.palette.offsite.main }} />
                  }
               />
            )}
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.wfh}
               name="wfh"
               icon={<HomeIcon />}
               checkedIcon={<HomeIcon style={{ fill: theme.palette.wfh.main }} />}
            />
            <Checkbox
               {...label}
               onClick={(e) => toggle(e)}
               checked={display.away}
               name="away"
               icon={<DoDisturbOffIcon />}
               checkedIcon={<DoDisturbOnIcon style={{ fill: theme.palette.away.main }} />}
            />
         </Box>
      );
   }

   function toggleFullDisplay() {
      if (props.fullDisplay === 'overall') {
         props.setFullDisplay('');
      } else {
         props.setFullDisplay('overall');
      }
   }

   return (
      <React.Fragment>
         <Paper>
            <Grid container spacing={0}>
               <Grid item xs={12}>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                     }}
                  >
                     <Typography sx={{ pt: 3, pl: 3 }} variant="h5" component="h2">
                        Overall Stats
                     </Typography>

                     <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ pt: 1, pr: 1 }}
                     >
                        <ExportButton
                           data={exportData()}
                           headers={headers()}
                           time={true}
                           icon={true}
                        />
                        <IconButton
                           onClick={() => toggleFullDisplay()}
                           size="large"
                           aria-label="expand"
                        >
                           {props.fullDisplay === 'overall' ? (
                              <CloseFullscreenIcon color="primary" />
                           ) : (
                              <OpenInFullIcon color="primary" />
                           )}
                        </IconButton>
                     </Stack>
                  </Box>
               </Grid>
               <Grid item xs={12} sx={{ p: 2 }}>
                  <IconCheckboxes display={display} />
               </Grid>

               {!props.loadedData ? (
                  <Typography>Loading ... </Typography>
               ) : (
                  <Grid
                     item
                     xs={12}
                     className={classes.paper}
                     sx={{ height: calculateHeight }}
                  >
                     <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                           layout="vertical"
                           width={500}
                           height={600}
                           data={props.loadedData}
                           margin={{
                              top: 0,
                              right: 30,
                              left: 20,
                              bottom: 5,
                           }}
                        >
                           <CartesianGrid strokeDasharray="3 3" />
                           <XAxis
                              type="number"
                              allowDecimals={false}
                              domain={[0, props.max]}
                           />
                           <YAxis
                              dataKey="name"
                              type="category"
                              width={calculateWidth()}
                           />
                           <Tooltip />
                           <Legend />
                           {display.ito && (
                              <Bar
                                 dataKey="ito"
                                 stackId="a"
                                 fill={theme.palette.primary.main}
                              />
                           )}
                           {display.offsite && (
                              <Bar
                                 dataKey="offsite"
                                 stackId="a"
                                 fill={theme.palette.offsite.main}
                              />
                           )}
                           {display.wfh && (
                              <Bar
                                 dataKey="wfh"
                                 stackId="a"
                                 fill={theme.palette.wfh.main}
                              />
                           )}
                           {display.away && (
                              <Bar
                                 dataKey="away"
                                 stackId="a"
                                 fill={theme.palette.away.main}
                              />
                           )}
                        </BarChart>
                     </ResponsiveContainer>
                  </Grid>
               )}
            </Grid>
         </Paper>
      </React.Fragment>
   );
};

export default withFirebase(OverallNumbers);
