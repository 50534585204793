import React from 'react';
import { Box, Typography } from '@mui/material';

const Bookings = (props) => {
   return (
      <Box sx={{ fontSize: '0.8rem' }}>
         {props.dayStatus.bookings.map((value) => {
            if (props.dayStatus.set === 'split' && value.type === props.time) {
               return (
                  <Box key={value.bid} sx={{ pl: 2 }}>
                     {value.parentName !== '' && (
                        <Typography display="inline" variant="caption">
                           {value.parentName}
                        </Typography>
                     )}
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'flex-start',
                           justifyItems: 'center',
                        }}
                     >
                        {value.backgroundColor && (
                           <Box
                              sx={{
                                 width: '15px',
                                 height: '15px',
                                 backgroundColor: value.backgroundColor,
                                 mr: 1,
                              }}
                           ></Box>
                        )}
                        <Typography>{value.name}</Typography>
                     </Box>
                  </Box>
               );
            } else if (props.dayStatus.set !== 'split') {
               return (
                  <Box key={value.bid}>
                     {value.parentName !== '' && (
                        <Typography display="inline" variant="caption">
                           {value.parentName}
                        </Typography>
                     )}
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'flex-start',
                           justifyItems: 'center',
                        }}
                     >
                        {value.backgroundColor && (
                           <Box
                              sx={{
                                 width: '15px',
                                 height: '15px',
                                 backgroundColor: value.backgroundColor,
                                 mr: 1,
                              }}
                           ></Box>
                        )}
                        <Typography>{value.name}</Typography>
                     </Box>
                  </Box>
               );
            }
         })}
      </Box>
   );
};
export default Bookings;
