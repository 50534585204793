import React, { createContext, useContext } from 'react';
import { useToggle } from '../../../hooks';
import { Box } from '@mui/material';
import AppBar from '../AppBar';
import AppSideBar from '../AppSideBar';
import Logo from '../AppBar/Logo';
import MenuButton from '../AppBar/MenuButton';
import UserManagementControl from '../AppBar/UserManagementControl';
import Footer from '../Footer';
import { Toolbar } from '@mui/material';
import AuthUserContext from '../../Session/authUserContext';

const AppBarContext = createContext(null);
const AppLayout = ({ children }) => {
   const [toggle, handleToggle] = useToggle();
   return (
      <AppBarContext.Provider
         value={{
            menuOpen: toggle,
         }}
      >
         <AppBar>
            <MenuButton onClick={handleToggle} mr={1} />
            <Logo width={150} height={45} />
            <Box flex={1} />
            <UserManagementControl />
         </AppBar>
         <AuthUserContext.Consumer>
            {(authUser) => <AppSideBar open={toggle} authUser={authUser} />}
         </AuthUserContext.Consumer>
         <Toolbar />
         <Box
            component={'main'}
            sx={(theme) => ({
               ml: [0, 8],
               height: `calc(100vh - ${theme.spacing(14)})`,
            })}
         >
            {children}
            <Footer />
         </Box>
      </AppBarContext.Provider>
   );
};

export const useAppBar = () => useContext(AppBarContext);
export default AppLayout;
