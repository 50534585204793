import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { withFirebase } from '../../Firebase';
import { DropzoneDialog } from 'material-ui-dropzone';
import { deleteLogo } from './functions';

const TenantImageUpload = ({ lid, location, ...props }) => {
   const [image, setImage] = useState(null);
   const [dialogOpen, setDialogOpen] = useState(false);
   const [uploading, setUploading] = useState(false);
   const [imageUrl, setImageUrl] = useState(null);
   const [landlordDetails, setLandlordDetails] = useState(null);

   useEffect(() => {
      if (location.logoPath) {
         const fullPath = props.firebase.showImage(location.logoPath);
         fullPath.getDownloadURL().then((url) => {
            setImageUrl(url);
         });
      } else {
         setImageUrl(null);
      }
   }, [location.logoPath]);

   useEffect(() => {
      if (location.landlordDetails) {
         setLandlordDetails(location.landlordDetails);
      } else {
         setLandlordDetails(null);
      }
   }, [location.landlordDetails]);

   const saveUpload = async (files) => {
      setUploading(true);
      let path = `logos/${lid}`;
      const batch = props.firebase.db.batch();
      const tlocRef = props.firebase.db.doc(`location/${lid}`);
      batch.set(tlocRef, { logoPath: `${path}/${files[0].name}` }, { merge: true });
      const visColRef = props.firebase.db.collection(`visitors`);
      batch.set(
         visColRef.doc(lid),
         { logoPath: `${path}/${files[0].name}` },
         { merge: true },
      );
      if (landlordDetails !== null) {
         batch.set(
            visColRef.doc(location.landlordDetails.lid),
            { tenantDetails: { [lid]: { logoPath: `${path}/${files[0].name}` } } },
            { merge: true },
         );
         const llocRef = props.firebase.db.doc(
            `location/${location.landlordDetails.lid}`,
         );
         batch.set(
            llocRef,
            { tenantDetails: { [lid]: { logoPath: `${path}/${files[0].name}` } } },
            { merge: true },
         );
      }
      try {
         await props.firebase.uploadImage(files[0], path);
         batch.commit();
      } catch (error) {
         console.warn(error, 'ERROR uploading logo');
      }
      setDialogOpen(false);
   };

   return (
      <Box
         sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
      >
         {imageUrl == null ? (
            <Box>
               <DropzoneDialog
                  open={dialogOpen}
                  onSave={(files) => {
                     saveUpload(files);
                  }}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  showPreviews={true}
                  maxFileSize={5000000}
                  filesLimit={1}
                  onClose={() => {
                     setDialogOpen(false);
                  }}
               />
               <Button onClick={() => setDialogOpen(true)}>Upload Logo</Button>
            </Box>
         ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               <img
                  src={imageUrl}
                  style={{
                     height: 'auto',
                     width: '250px',
                     maxHeight: '100%',
                     maxWidth: '100%',
                  }}
               />
               <Button
                  onClick={() => deleteLogo(lid, location.logoPath, landlordDetails)}
                  color="secondary"
               >
                  Remove Logo
               </Button>
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(TenantImageUpload);
