import React from 'react';
import { Box, Divider, List } from '@mui/material';
import { Typography } from '@material-ui/core';
import AvailableTimes from './ToolTipContents/availableTimes';
import Bookings from './ToolTipContents/bookings';
import OffsiteDetails from './ToolTipContents/offsiteDetails';

const ToolTipContent = (props) => {
   function formatValue(value) {
      switch (value) {
         case 'ito':
            return 'ITO';
         case 'wfh':
            return 'WFH';
         case 'offsite':
            return 'Offsite';
         case 'away':
            return 'Away';
         default:
            return '-';
      }
   }
   return (
      <Box sx={{ minWidth: 250, p: 1 }}>
         {props.dayStatus && (
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  mb: 2,
               }}
            >
               {props.dayStatus.customTimes && (
                  <AvailableTimes dayStatus={props.dayStatus} />
               )}
               {props.dayStatus.set === 'split' ? (
                  <Box
                     sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        width: '100%',
                     }}
                  >
                     <Box sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'white' }} variant="body1">
                           AM: {formatValue(props.dayStatus.splitValues.AM)}
                        </Typography>
                        {props.dayStatus.offsiteName &&
                           props.dayStatus.offsiteName.AM !== null && (
                              <OffsiteDetails dayStatus={props.dayStatus} time={'AM'} />
                           )}
                        {props.dayStatus.bookings !== null && (
                           <Bookings dayStatus={props.dayStatus} time={'AM'} />
                        )}
                     </Box>
                     <Divider sx={{ bgcolor: '#fff' }} />
                     <Box sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'white' }} variant="body1">
                           PM: {formatValue(props.dayStatus.splitValues.PM)}
                        </Typography>
                        {props.dayStatus.offsiteName &&
                           props.dayStatus.offsiteName.PM !== null && (
                              <OffsiteDetails dayStatus={props.dayStatus} time={'PM'} />
                           )}
                        {props.dayStatus.bookings !== null && (
                           <Bookings dayStatus={props.dayStatus} time={'PM'} />
                        )}
                     </Box>
                  </Box>
               ) : (
                  <React.Fragment>
                     {props.dayStatus.bookings !== null && (
                        <Bookings dayStatus={props.dayStatus} />
                     )}
                     {props.dayStatus.offsiteName !== null && (
                        <OffsiteDetails dayStatus={props.dayStatus} />
                     )}
                  </React.Fragment>
               )}
            </Box>
         )}
      </Box>
   );
};

export default ToolTipContent;
