import React from 'react';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import {
   CircularProgressbarWithChildren,
   CircularProgressbar,
   buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Tooltip, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
   normal: {
      maxWidth: 150,
      margin: 'auto',
      textSize: 'xx-large',
   },
   mini: {
      maxWidth: 55,
      minWidth: 55,
      margin: 'auto',
   },
   micro: {
      maxWidth: 30,
      minWidth: 30,
      margin: 'auto',
   },
   multi: {
      maxWidth: 64,
      margin: 'auto',
   },
}));

const SplitDayCap = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   function setStyle(value, time) {
      if (props.micro) {
         if (value >= 100) {
            const styles = {
               color: '#DB3D28',
               fontWeight: 'bold',
            };
            return styles;
         } else if (value > 90 && value < 100) {
            const styles = {
               color: '#F7B843',
            };
            return styles;
         } else {
            const styles = {
               color: theme.palette.text.primary,
            };
            return styles;
         }
      } else {
         var capStyles = {};
         if (time == 'AM') {
            capStyles = {
               pathColor: '#9ECF99',
               trailColor: '#e6e6e6',
               // rotation: 1 / 2 + 1 / 8,
               // trailColor: 'transparent',
               // strokeWidth:25,
            };
         }
         if (time == 'PM') {
            capStyles = {
               pathColor: '#9E9ACF',
               trailColor: '#e6e6e6',
               // rotation: 1 / 2 + 1 / 8,
               // trailColor: 'transparent',
               // strokeWidth:25,
            };
         }
         if (value > 100) {
            capStyles = {
               ...capStyles,
               pathTransition: 'none',
               pathColor: '#DB3D28',
               textColor: '#fff',
               fontWeight: 'bold',
               textSize: 'xx-large',
               // strokeLinecap: 'butt',
            };
         } else if (value > 90 && value < 100) {
            capStyles = {
               ...capStyles,
               pathTransition: 'none',
               pathColor: '#F7E943',
               textSize: 'xx-large',
               textColor: '#646464',
               // strokeLinecap: 'butt',
            };
         } else {
            capStyles = {
               ...capStyles,
               pathTransition: 'none',
               // textColor: '#646464',
               textSize: 'xx-large',
               // strokeLinecap: 'butt',
               // trailColor: '#fff',
            };
         }
         if (props.mini || props.micro) {
            capStyles = {
               ...capStyles,
               trailColor: '#fff',
            };
         }
         return buildStyles(capStyles);
      }
   }
   if (props.micro) {
      return (
         <div style={{ display: 'flex', flexDirection: 'column' }}>
            {' '}
            <Tooltip title="AM" placement="left">
               <Typography
                  style={setStyle(props.capacity.percent, 'AM')}
                  variant="caption"
               >
                  {props.capToggle
                     ? `${props.capacity.number}`
                     : `${Math.round(props.capacity.percent)}%`}
               </Typography>
            </Tooltip>
            <Tooltip title="PM" placement="left">
               <Typography
                  style={setStyle(props.capacity.pmPercent, 'PM')}
                  variant="caption"
               >
                  {props.capToggle
                     ? `${props.capacity.pm}`
                     : `${Math.round(props.capacity.pmPercent)}%`}
               </Typography>
            </Tooltip>
         </div>
      );
   } else {
      return (
         <div
            className={clsx({
               [classes.mini]: props.mini,
               [classes.normal]: !props.mini && !props.micro,
               [classes.micro]: props.micro,
            })}
         >
            <AnimatedProgressProvider
               valueStart={0}
               valueEnd={props.capacity.percent}
               duration={0.7}
               easingFunction={easeQuadInOut}
            >
               {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                     <CircularProgressbarWithChildren
                        value={value}
                        strokeWidth={props.mini || props.micro ? 11 : 7}
                        styles={setStyle(value, 'AM')}
                     >
                        {/*
          Width here needs to be ((100 - 2 * strokeWidth)+1)% 
          in order to fit exactly inside the outer progressbar.
        */}
                        <div
                           style={
                              props.mini || props.micro
                                 ? { width: '79%' }
                                 : { width: '87%' }
                           }
                        >
                           <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={props.capacity.pmPercent}
                              duration={0.7}
                              easingFunction={easeQuadInOut}
                           >
                              {(value) => {
                                 const roundedValue2 = Math.round(value);

                                 return (
                                    <CircularProgressbarWithChildren
                                       value={value}
                                       strokeWidth={props.mini || props.micro ? 11 : 7}
                                       styles={setStyle(value, 'PM')}
                                    >
                                       <Tooltip title="AM" placement="left">
                                          <Typography
                                             style={
                                                props.mini || props.micro
                                                   ? { lineHeight: 1 }
                                                   : {
                                                        fontSize: 'large',
                                                        color: '#3e98c7',
                                                     }
                                             }
                                             variant="caption"
                                          >
                                             {props.capToggle
                                                ? `${props.capacity.number}`
                                                : `${roundedValue}%`}
                                          </Typography>
                                       </Tooltip>
                                       <Tooltip title="PM" placement="left">
                                          <Typography
                                             style={
                                                props.mini || props.micro
                                                   ? { lineHeight: 1 }
                                                   : {
                                                        fontSize: 'large',
                                                        color: '#3e98c7',
                                                     }
                                             }
                                             variant="caption"
                                          >
                                             {props.capToggle
                                                ? `${props.capacity.pm}`
                                                : `${roundedValue2}%`}
                                          </Typography>
                                       </Tooltip>
                                    </CircularProgressbarWithChildren>
                                 );
                              }}
                           </AnimatedProgressProvider>
                        </div>
                     </CircularProgressbarWithChildren>
                  );
               }}
            </AnimatedProgressProvider>
         </div>
      );
   }
};
export default SplitDayCap;
