import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { withFirebase } from '../../Firebase';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(4),
      flexGrow: 1,
   },
   title: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
   },
   subhead: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
}));

function Details(props) {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [location, setLocation] = useState(false);
   const [admins, setAdmins] = useState({
      uid: { name: { firstName: 'Loading...', lastName: '' } },
   });

   useEffect(() => {
      const loadDetails = async () => {
         if (props.expanded === 'details') {
            props.firebase.location(props.locationKey).onSnapshot((result) => {
               if (result.exists) {
                  setLocation(result.data());
               }
            });
         }
      };
      loadDetails();
   }, [props.expanded]);

   async function loadPerson(cid, uid) {
      let result = await props.firebase.getPerson(cid, uid).get();
      if (result.exists) {
         setAdmins({ ...admins, [uid]: result.data() });
      }
   }

   useEffect(() => {
      const loadAdmins = async () => {
         if (props.expanded === 'details' && location) {
            location.locationAdmins.map((item) => {
               loadPerson(user.cid, item);
            });
         }
      };
      loadAdmins();
   }, [props.expanded, location]);

   return (
      <React.Fragment>
         <Grid container direction="row" spacing={3}>
            {location && (
               <React.Fragment>
                  <Grid item xs={12} sm={6}>
                     <Typography variant="body1">
                        Created on:{' '}
                        {location.profile.creationDate.toDate().toDateString()}
                     </Typography>
                     <Typography variant="body1">People: {location.people}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <Typography variant="body1">Administrators: </Typography>
                     {Object.entries(admins).map(
                        ([key, value]) =>
                           key !== 'uid' && (
                              <Typography variant="body1" key={key}>
                                 {value.name.firstName} {value.name.lastName}{' '}
                              </Typography>
                           ),
                     )}
                  </Grid>
               </React.Fragment>
            )}
         </Grid>
      </React.Fragment>
   );
}

export default withFirebase(Details);
