import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChairPMBooked = (props) => (
   <SvgIcon {...props} viewBox="0 0 24 24">
      <g
         id="ITO-Design-System"
         stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd"
      >
         <g id="Chair-pm-booked">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <path
               d="M6.45958907,17 L8.66920471,15 L20,15 L20,21 L18,21 L18,17 L6.45958907,17 Z M4,19.2099279 L6,17.4087149 L6,21 L4,21 L4,19.2099279 Z M19,10 L22,10 L22,13 L19,13 Z M15,9.29460144 L17,7.50917053 L17,13 L10.8861983,13 L13.1152878,11.000658 L15,11.000658 L15,9.29460144 Z"
               id="Combined-Shape"
               fill="#C8C8C8"
            ></path>
            <path
               d="M7.37171904,15.5 L6.12541612,16.6280747 L4.5,18.0885026 L4.5,15.5 L7.37171904,15.5 Z M4.5,10.5 L4.5,12.5 L2.5,12.5 L2.5,10.5 L4.5,10.5 Z M8.9999456,3.5 L15.0187369,3.50065485 C15.1460307,3.50671682 15.6793695,3.55261778 16.0724797,3.95150363 C16.295821,4.17812628 16.4622439,4.52020049 16.5,5.01812019 L16.5,7.2855981 L15.5042877,8.17639559 L15.5042877,4.5 L8.5,4.5 L8.5,11.500658 L11.808995,11.500658 L10.694817,12.5 L7.5,12.5 L7.5,5.01787626 C7.53754112,4.50881141 7.66387059,4.11281757 7.92313477,3.85355339 C8.17407884,3.60260932 8.54038013,3.5 8.9999456,3.5 Z"
               id="Combined-Shape"
               stroke="#476B43"
               fill="#476B43"
            ></path>
         </g>
      </g>
   </SvgIcon>
);

export default ChairPMBooked;
