import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import { withFirebase } from '../Firebase';
import { Box, ListItemButton, Modal } from '@mui/material';
import VisitorListItem from './visitorListItem';
import { set } from 'date-fns';
import { SettingsVoice } from '@mui/icons-material';
import EditVisitor from '../Visitors/VisitorDash/editVisitor';

function VisitorList({ handleEditModal, ...props }) {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [disableDelete, setDisableDelete] = useState(false);

   async function handleDelete(email, details) {
      try {
         await props.firebase.deleteVisitor(
            props.cid,
            props.lid,
            details.host.uid,
            props.date,
            email,
         );
      } catch (error) {
         console.log(error, 'error');
      }
   }

   useEffect(() => {
      if (props.dayVisitors && props.dayVisitors.length > 0) {
         let disDel = {};
         props.dayVisitors.map((value) => {
            if (value.checkin) {
               disDel[value.uid] = true;
            } else {
               disDel[value.uid] = false;
            }
         });
         setDisableDelete(disDel);
      }
   }, [props.dayVisitors]);

   return (
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
         {props.dayVisitors.map((value, key) => (
            <React.Fragment key={value.uid}>
               <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                     <React.Fragment>
                        {value.owner && value.owner.uid === authUser.uid && (
                           <IconButton
                              edge="end"
                              aria-label="delete"
                              disabled={disableDelete[value.uid]}
                              onClick={() => handleDelete(value.uid, value)}
                           >
                              <DeleteIcon />
                           </IconButton>
                        )}
                     </React.Fragment>
                  }
               >
                  {!disableDelete[value.uid] &&
                  ((value.owner && value.owner.uid === authUser.uid) ||
                     authUser?.roles?.ADMIN) ? (
                     <ListItemButton onClick={() => handleEditModal(value)}>
                        <VisitorListItem
                           date={props.date}
                           value={value}
                           type={props.type}
                        />
                     </ListItemButton>
                  ) : (
                     <VisitorListItem value={value} type={props.type} />
                  )}

                  {props.dayVisitors.length > key + 1 && (
                     <Divider variant="inset" component="li" />
                  )}
               </ListItem>
            </React.Fragment>
         ))}
      </List>
   );
}

export default withFirebase(VisitorList);
