import React, { useState, useEffect } from 'react';
import {
   Box,
   Divider,
   FormControl,
   FormControlLabel,
   Button,
   Modal,
   Switch,
   Typography,
} from '@mui/material';
import { withFirebase } from '../../Firebase';
import firebase from 'firebase/app';
import { useCompany } from '../../../providers';

const GlobalGroupSwitch = (props) => {
   const { company } = useCompany();
   const [checked, setChecked] = useState(true);
   const [open, setOpen] = useState(false);

   const handleClose = () => setOpen(false);
   const submitToggle = async () => {
      setOpen(false);
      try {
         await removeOtherLocationPeople(group);
         // await toggleGlobalGroup(false);
      } catch (error) {
         console.warn('ERROR - problem removing other location group members', error);
      }
   };
   async function removeOtherLocationPeople(group) {
      console.log(group, 'Deleting other group members');
   }
   console.log(company);
   console.log(props.group);
   useEffect(() => {
      if (props.group && props.group.global) {
         setChecked(true);
      } else {
         setChecked(false);
      }
   }, [props.group]);

   const handleChange = () => {
      if (!checked) {
         toggleGlobalGroup(true);
      } else {
         let hasGlobalMembers = false;
         Object.entries(props.group.members).map(([key, value]) => {
            if (value.lid !== props.group.lid) {
               hasGlobalMembers = true;
               return;
            }
         });
         if (hasGlobalMembers) {
            setOpen(true);
         } else {
            toggleGlobalGroup(false);
         }
      }
   };

   async function toggleGlobalGroup(status) {
      const batch = props.firebase.db.batch();
      let group = { ...props.group, global: status };
      delete group.gid;
      Object.entries(group.members).map(([key, value]) => {
         if (status) {
            group.members[key].lid = props.group.lid;
         } else {
            group.members[key].lid = firebase.firestore.FieldValue.delete();
         }
      });
      let groupIndex = { ...props.group, global: status };
      delete groupIndex.gid;
      delete groupIndex.members;
      delete groupIndex.admins;

      Object.keys(company.locations).map((lid) => {
         console.log(company.locations[lid], 'LOCATIONS');
         const groupRef = props.firebase.db.doc(
            `location/${lid}/groups/${props.group.gid}`,
         );
         const groupIndexRef = props.firebase.db.doc(`location/${lid}/indexes/groups`);
         if (lid === props.group.lid) {
            batch.set(groupRef, group, { merge: true });
            batch.set(groupIndexRef, { [props.group.gid]: groupIndex }, { merge: true });
         } else if (status) {
            batch.set(groupRef, group, { merge: true });
            batch.set(groupIndexRef, { [props.group.gid]: groupIndex }, { merge: true });
         } else if (!status && lid !== props.group.lid) {
            batch.delete(groupRef);
            batch.set(
               groupIndexRef,
               { [props.group.gid]: firebase.firestore.FieldValue.delete() },
               { merge: true },
            );
         }
      });
      console.log(props.group.gid, props.group.lid);
      try {
         await batch.commit();
      } catch (error) {
         console.warn('ERROR - problem making group global', error);
      }
   }

   return (
      <Box sx={{ width: '100%' }}>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box
               sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
               }}
            >
               {' '}
               <Typography id="modal-modal-title" variant="h6" component="h2">
                  Revert group to local confirmation
               </Typography>
               <Typography paragraph sx={{ mt: 2 }}>
                  WARNING - There are people from other locations in this group.
               </Typography>
               <Typography paragraph>
                  {`By clicking on Remove, this will revert to being a group for ${
                     company.locations[props.group.lid]
                  } only. People who are in other locations will be removed.`}
               </Typography>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={submitToggle} variant="contained" color="secondary">
                     Remove
                  </Button>
               </Box>
            </Box>
         </Modal>
         <Divider sx={{ my: 2 }} />
         <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Include people from other locations?</Typography>
            <FormControl>
               <FormControlLabel
                  value="top"
                  control={
                     <Switch color="primary" checked={checked} onChange={handleChange} />
                  }
                  label="Global"
                  labelPlacement="end"
               />
            </FormControl>
         </Box>
         <Divider sx={{ my: 2 }} />
      </Box>
   );
};

export default withFirebase(GlobalGroupSwitch);
