import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const BlankAmpmBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/AwayAMpmBooked-Copy"
               fill={theme.palette.tableBody.main}
               fillRule="nonzero"
            >
               <path
                  d="M41,0 C41,0 19.4938713,31 19.4938713,31 L15.5,31 C6.48554758,31 0,24.0604136 0,15.5 C0,6.93958638 6.93958638,0 15.5,0 Z M17,12.5 L9,12.5 L9,14.5 L17,14.5 L17,12.5 Z"
                  id="Oval-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default BlankAmpmBooked;
