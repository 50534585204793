import React from 'react';
import { Box, Icon } from '@mui/material';
import ITOAM from '../../../assets/status/ITOAM-dark.svg';
import ITOAMBooked from '../../../assets/status/ITOAM-booked-dark.svg';
import ITOPMBooked from '../../../assets/status/ITOPM-booked-dark.svg';
import ITOAMBoth from '../../../assets/status/ITOAM-bothbooked-dark.svg';
import ITOPM from '../../../assets/status/ITOPM-dark.svg';
import WFHAM from '../../../assets/status/WFHAM-dark.svg';
import WFHPM from '../../../assets/status/WFHPM-dark.svg';
import OFFSITEAM from '../../../assets/status/OFFSITEAM-dark.svg';
import OFFSITEPM from '../../../assets/status/OFFSITEPM-dark.svg';
import AWAYAM from '../../../assets/status/AWAYAM-dark.svg';
import AWAYPM from '../../../assets/status/AWAYPM-dark.svg';
import DASH from '../../../assets/status/DASH-dark.svg';
import DASHAM from '../../../assets/status/DASHAM-dark.svg';

const SplitIconsDark = (props) => {
   const commonStyles = {
      width: '40px',
      height: '31px',
   };
   if (props.splitValues) {
      switch (props.splitValues) {
         case 'itoam-pm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={DASH} />
                  </Icon>
               </Box>
            );
         case 'itoamwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case 'itoamoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case 'itoamawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         case 'itoamitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case 'itoambooking-pm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBooked} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={DASH} />
                  </Icon>
               </Box>
            );
         case 'itoambookingwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBooked} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case 'itoambookingawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBooked} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         case 'itoambookingoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBooked} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case 'itoambookingitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBooked} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case 'itoamitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case 'itoambookingitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={ITOAMBoth} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case 'wfham-pm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={DASH} />
                  </Icon>
               </Box>
            );
         case 'wfhamoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case 'wfhamitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case 'wfhamitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case 'wfhamawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         case 'wfhamwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={WFHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case 'offsiteam-pm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={DASH} />
                  </Icon>
               </Box>
            );
         case 'offsiteamwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case 'offsiteamitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case 'offsiteamitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case 'offsiteamawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         case 'offsiteamoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={OFFSITEAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case 'awayam-pm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={DASH} />
                  </Icon>
               </Box>
            );
         case 'awayamwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case 'awayamitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case 'awayamitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case 'awayamoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case 'awayamawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles }}>
                     <img src={AWAYAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         case '-amwfhpm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-15px',
                        mr: '-21px',
                        top: 2,
                     }}
                  >
                     <img src={WFHPM} />
                  </Icon>
               </Box>
            );
         case '-amitopm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-15px',
                        mr: '-21px',
                        top: 2,
                     }}
                  >
                     <img src={ITOPM} />
                  </Icon>
               </Box>
            );
         case '-amitopmbooking':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles, ml: '1px' }}>
                     <img src={DASHAM} />
                  </Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-21px',
                        mr: '-21px',
                     }}
                  >
                     <img src={ITOPMBooked} />
                  </Icon>
               </Box>
            );
         case '-amoffsitepm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-15px',
                        mr: '-21px',
                        top: 2,
                     }}
                  >
                     <img src={OFFSITEPM} />
                  </Icon>
               </Box>
            );
         case '-amawaypm':
            return (
               <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
                  <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
                  <Icon
                     sx={{
                        ...commonStyles,
                        position: 'relative',
                        left: '-15px',
                        mr: '-21px',
                        top: 2,
                     }}
                  >
                     <img src={AWAYPM} />
                  </Icon>
               </Box>
            );
         default:
            return (
               <Box sx={{ minHeight: '31px', minWidth: '25px', display: 'flex' }}>
                  <Icon sx={{ ...commonStyles, position: 'relative' }}>-</Icon>
               </Box>
            );
      }
   }
};
export default SplitIconsDark;
