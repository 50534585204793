import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../providers';
import { withFirebase } from '../../Firebase';

const ActionButtons = ({
   error,
   submitBooking,
   cancelSpace,
   form,
   setDetailsData,
   detailsData,
   existing,
   firebase,
}) => {
   const theme = useTheme();
   const { authUser } = useAuth();
   const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
   const [adminUsers, setAdminUsers] = useState([]);

   useEffect(() => {
      const getAdminUsers = async () => {
         const response = await firebase.db
            .doc(`company/${authUser.cid}/people/${authUser.uid}/index/groupAdmin`)
            .get()
            .then((result) => {
               if (result.exists) {
                  let data = [];
                  Object.keys(result.data()).forEach((key) => {
                     data.push(key);
                  });
                  return data;
               }
            });
         setAdminUsers(response);
      };
      getAdminUsers();
   }, []);

   return (
      <>
         {detailsData.edit ? (
            <Box
               sx={{
                  width: '100%',
                  justifyContent: isXsDown ? 'flex-start' : 'flex-end',
                  display: 'flex',
               }}
            >
               <Box
                  sx={{
                     width: isXsDown ? '50%' : '100%',
                     justifyContent: isXsDown ? 'flex-start' : 'flex-end',
                     display: 'flex',
                  }}
               >
                  {error.error && (
                     <Typography variant="caption" paragraph color="error">
                        {error.message}
                     </Typography>
                  )}
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-evenly',
                     width: isXsDown ? '50%' : '100%',
                  }}
               >
                  {existing ? (
                     <>
                        <Button
                           variant="contained"
                           disabled={error.error}
                           onClick={submitBooking}
                           sx={{ mr: isXsDown ? 2 : 8, width: '200px' }}
                        >
                           Save
                        </Button>
                        <Button
                           variant="outlined"
                           color="secondary"
                           onClick={cancelSpace}
                        >
                           Delete
                        </Button>
                     </>
                  ) : (
                     <Button
                        variant="contained"
                        disabled={error.error}
                        onClick={submitBooking}
                        sx={{ mr: isXsDown ? 0 : 8, width: '200px' }}
                     >
                        Book
                     </Button>
                  )}
               </Box>
            </Box>
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
               }}
            >
               <Box sx={{ width: isXsDown ? '100%' : '50%' }}></Box>
               <Box
                  sx={{
                     width: isXsDown ? '100%' : '50%',
                     display: 'flex',
                     justifyContent: 'space-evenly',
                  }}
               >
                  {form &&
                     (form.owner.uid === authUser.uid ||
                        authUser.roles?.ADMIN == 'ADMIN' ||
                        (adminUsers &&
                           adminUsers.length > 0 &&
                           adminUsers.indexOf(form.host.uid) >= 0)) && (
                        <>
                           <Button
                              variant="contained"
                              sx={{ mr: isXsDown ? 2 : 8, width: '200px' }}
                              onClick={() =>
                                 setDetailsData({
                                    ...detailsData,
                                    edit: true,
                                 })
                              }
                           >
                              Edit
                           </Button>
                           <Button
                              variant="outlined"
                              color="secondary"
                              onClick={cancelSpace}
                           >
                              Delete
                           </Button>
                        </>
                     )}
               </Box>
            </Box>
         )}
      </>
   );
};

export default withFirebase(ActionButtons);
