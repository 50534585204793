import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
   DatePicker,
} from '@material-ui/pickers';
import EastIcon from '@mui/icons-material/East';
import { FullWeekDateRange } from '../../constants/functions';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
   root: {
      '& .MuiTextField-root': {
         margin: theme.spacing(1),
         width: '25ch',
      },
   },
}));

export default function DateRangePicker(props) {
   const classes = useStyles();
   const [startDate, setStartDate] = useState(() => {
      const dateString = props.dates[0];
      const year = dateString.substr(0, 4);
      const month = dateString.substr(4, 2); // month is zero-indexed
      const day = dateString.substr(6, 2);

      const date = new Date(year, month, day);
      return date;
   });
   const [endDate, setEndDate] = useState(() => {
      const dateString = props.dates[props.dates.length - 1];
      const year = dateString.substr(0, 4);
      const month = dateString.substr(4, 2); // month is zero-indexed
      const day = dateString.substr(6, 2);

      const date = new Date(year, month, day);
      return date;
   });

   const handleStartDateChange = (date) => {
      setStartDate(date);
      if (endDate !== null) {
         let newDayRange = FullWeekDateRange(
            formatDate(date),
            formatDate(endDate),
            props.hideWeekends,
         );
         props.setDates(newDayRange);
         props.setTotalDays(newDayRange.length);
      }
   };

   const handleEndDateChange = (date) => {
      setEndDate(date);
      if (startDate !== null) {
         let newDayRange = FullWeekDateRange(
            formatDate(startDate),
            formatDate(date),
            props.hideWeekends,
         );
         props.setDates(newDayRange);
         props.setTotalDays(newDayRange.length);
      }
   };

   const formatDate = (date) => {
      let dateObject = {
         year: date.getFullYear(),
         month: date.getMonth(),
         day: date.getDate(),
      };
      return dateObject;
   };

   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <DatePicker
               disableToolbar
               style={{ width: '100px' }}
               variant="inline"
               format="dd/MM/yyyy"
               margin="normal"
               id="start-date-picker"
               label="Start Date"
               value={startDate}
               onChange={handleStartDateChange}
               // KeyboardButtonProps={{
               //   'aria-label': 'change start date',
               // }}
               maxDate={endDate !== null ? endDate : new Date()}
               // renderInput={(props) => <TextField {...props} />}
            />
            <EastIcon sx={{ mt: '22px', ml: '8px', mr: '8px' }} />
            <DatePicker
               disableToolbar
               style={{ width: '100px' }}
               variant="inline"
               format="dd/MM/yyyy"
               margin="normal"
               id="end-date-picker"
               label="End Date"
               value={endDate}
               onChange={handleEndDateChange}
               // KeyboardButtonProps={{
               //   'aria-label': 'change end date',
               // }}
               minDate={startDate}
               maxDate={new Date()}
               // renderInput={(props) => <TextField {...props} />}
            />
         </Box>
      </MuiPickersUtilsProvider>
   );
}
