import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { PageLayout } from '../layout';
import { Alert } from '@material-ui/lab';
import {
   useTheme,
   Box,
   Collapse,
   Divider,
   Paper,
   Typography,
   Grid,
   Link,
   Checkbox,
   FormControlLabel,
   TextField,
   Button,
   Avatar,
   Icon,
} from '@mui/material';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withFirebase } from '../Firebase';
import MsSymbol from '../../assets/mssymbol.svg';
import * as ROUTES from '../../constants/routes';
import MSTeamsSignIn from './msTeamsSignIn';

const SignIn = (props) => {
   const theme = useTheme();
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [checked, setChecked] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   var msTeams = false;
   if (
      window.location.hostname.includes('ngrok.io') ||
      window.location.hostname.includes('msteams')
      // ||
      // window.location.hostname.includes('staging-intheoffice')
   ) {
      msTeams = true;
   }

   const Toast = () => {
      return (
         <div>
            <Collapse in={checked} collapsedHeight={0}>
               <Alert severity="error">
                  <strong>Error - </strong>
                  {errorMessage}
               </Alert>
            </Collapse>
         </div>
      );
   };

   function handleSubmit(event) {
      event.preventDefault();
      props.firebase
         .doSignInWithEmailAndPassword(email, password)
         .then(() => {
            props.history.push(ROUTES.DASHBOARD);
         })
         .catch((error) => {
            setErrorMessage(error.message);
            setChecked(true);
         });
   }
   useEffect(() => {
      const getOffice = () => {};
      getOffice();
   }, []);

   return (
      <PageLayout sx={{ display: 'flex', justifyContent: 'center' }}>
         <Paper
            sx={{
               mt: theme.spacing(8),
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               p: theme.spacing(4),
               minWidth: 300,
               maxWidth: 450,
            }}
         >
            <Toast />
            {!msTeams ? (
               <React.Fragment>
                  <Avatar
                     sx={{
                        margin: theme.spacing(1),
                        backgroundColor: theme.palette.secondary.main,
                     }}
                  >
                     <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                     Sign in
                  </Typography>
                  <Button
                     fullWidth
                     href={ROUTES.AUTH}
                     variant="outlined"
                     sx={{
                        height: '41px',
                        my: 1.5,
                     }}
                  >
                     <div
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           flexWrap: 'noWrap',
                        }}
                     >
                        <Icon sx={{ height: 35, mr: 1.5 }}>
                           <img src={MsSymbol} />
                        </Icon>
                        <Typography noWrap>Sign in with Microsoft</Typography>
                     </div>
                  </Button>
                  <Typography component="p" variant="h6">
                     Or
                  </Typography>
                  <Divider sx={{ width: '100%', my: 2 }} />
                  <Typography variant="body1">Sign in with your email address</Typography>

                  <Box component="form" onSubmit={handleSubmit} noValidate>
                     <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onInput={(e) => setEmail(e.target.value)}
                        autoFocus
                     />
                     <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onInput={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                     />
                     <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                     />
                     <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ my: 1.5 }}
                     >
                        Sign In
                     </Button>

                     <Grid container>
                        <Grid item xs>
                           <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                              Forgot password?
                           </Link>
                        </Grid>
                        <Grid item>
                           <Link href={ROUTES.SIGN_UP} variant="body2">
                              {"Don't have an account? Sign Up"}
                           </Link>
                        </Grid>
                     </Grid>
                  </Box>
               </React.Fragment>
            ) : (
               <MSTeamsSignIn
                  MSTeams
                  setErrorMessage={setErrorMessage}
                  setChecked={setChecked}
               />
            )}
         </Paper>
      </PageLayout>
   );
};

const SignInForm = compose(withRouter, withFirebase)(SignIn);

export default SignInForm;
