import React from 'react';
import { Paper } from '@mui/material';

const CardSection = ({ sx, ...rest }) => {
   return (
      <Paper sx={{ p: 3, boxShadow: `0px 1px 2px rgba(0,0,0,0.25)`, ...sx }} {...rest} />
   );
};

export default CardSection;
