import React from 'react';

import { compose } from 'recompose';
import { AuthProvider, CompanyProvider } from '../../providers';
import { withAuthorization, withEmailVerification } from '../Session';
import { FirebaseContext } from '../Firebase';
import Main from './main';

const Dashboard = () => (
   <FirebaseContext.Consumer>
      {(firebase) => {
         return (
            <AuthProvider>
               <CompanyProvider>
                  <Main />
               </CompanyProvider>
            </AuthProvider>
         );
      }}
   </FirebaseContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition))(Dashboard);
