import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import uploadImage from '../../../assets/uploadLogo.png';

import { withFirebase } from '../../Firebase';

const TenantCards = ({ tenant, ...props }) => {
   const [imageUrl, setImageUrl] = useState(uploadImage);
   useEffect(() => {
      if (tenant.logoPath) {
         const fullPath = props.firebase.showImage(tenant.logoPath);
         fullPath.getDownloadURL().then((url) => {
            setImageUrl(url);
         });
      } else {
         setImageUrl(uploadImage);
      }
   }, [tenant.logoPath]);

   return (
      <Card sx={{ minWidth: 250, maxWidth: 250, m: 2 }}>
         <CardMedia
            sx={{
               height: 175,
               backgroundSize: 'contain',
               backgroundPosition: 'center',
               backgroundRepeat: 'no-repeat',
            }}
            image={imageUrl}
            title="green iguana"
         />
         <CardContent align="center">
            <Typography>{tenant.displayName}</Typography>
         </CardContent>
      </Card>
   );
};

export default withFirebase(TenantCards);
