import React from 'react';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AvailabilityList from '../availabilityList';

const HtmlTooltip = styled(({ className, ...props }) => (
   <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
      width: 220,
      boxShadow: theme.shadows[1],
      fontSize: 'large',
   },
}));

var i = 0;

const OffsiteChip = (props) => {
   function handleMore(value) {
      props.setSpaceData(props.data);
      props.setStatusDrawerOpen(true);
   }
   if (props.timeBox) {
      return (
         <Chip
            clickable={true}
            size="medium"
            variant="outlined"
            label={<PinDropIcon style={{ marginTop: 5 }} />}
            color="primary"
            value="offsite"
         />
      );
   } else if (props.clickable) {
      if (props.routine) {
         return (
            <Chip
               clickable={props.clickable}
               size="medium"
               variant="outlined"
               label={<PinDropIcon style={{ marginTop: 5 }} />}
               color="primary"
               value="offsite"
            />
         );
      } else if (props.offsiteName !== null || props.availability) {
         // if(props.offsiteName.locationId){
         return (
            <HtmlTooltip
               title={
                  <React.Fragment>
                     <Grid container>
                        {props.availability && (
                           <Grid item xs={12}>
                              <List dense={true}>
                                 {props.availability.map((time) => {
                                    i++;
                                    return <AvailabilityList key={i} time={time} />;
                                 })}
                              </List>
                           </Grid>
                        )}
                        {props.offsiteName && (
                           <Grid item xs={12}>
                              {props.offsiteName.locationId ? (
                                 <div
                                    style={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       flexWrap: 'wrap',
                                    }}
                                 >
                                    <LocationCityOutlinedIcon />
                                    {props.offsiteName.locationName}
                                 </div>
                              ) : (
                                 props.offsiteName
                              )}
                           </Grid>
                        )}
                     </Grid>
                  </React.Fragment>
               }
            >
               <Chip
                  size="medium"
                  clickable={props.clickable}
                  label={<PinDropIcon style={{ marginTop: 5 }} />}
                  color="primary"
                  variant="outlined"
                  value="offsite"
                  onDelete={() => handleMore(props.value.set)}
                  deleteIcon={<MoreVertIcon />}
               />
            </HtmlTooltip>
         );
      } else {
         return (
            <Chip
               clickable={props.clickable}
               size="medium"
               variant="outlined"
               label={<PinDropIcon style={{ marginTop: 5 }} />}
               value="offsite"
               onDelete={() => handleMore(props.value.set)}
               deleteIcon={<MoreVertIcon />}
            />
         );
      }
   } else {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                     <Grid item xs={12}>
                        {props.offsiteName?.locationId ? (
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              <LocationCityOutlinedIcon />
                              {props.offsiteName.locationName}
                           </div>
                        ) : props.offsiteName !== null ? (
                           props.offsiteName
                        ) : (
                           '-'
                        )}
                     </Grid>
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               clickable={props.clickable}
               size="medium"
               variant="outlined"
               label={<PinDropIcon style={{ marginTop: 5 }} />}
               color="primary"
               value="offsite"
            />
         </HtmlTooltip>
      );
   }
};

export default OffsiteChip;
