import React from 'react';
import { Avatar } from '@mui/material';

import EventSeatIcon from '@mui/icons-material/EventSeat';
import EventSeatIconOutlined from '@mui/icons-material/EventSeatOutlined';

import { stringAvatar } from '../../../../constants/functions';

import ChairAMBooked from '../../../../assets/icons/ChairAMBooked';
import ChairAMBookedHovered from '../../../../assets/icons/ChairAMBookedHovered';
import ChairPMBooked from '../../../../assets/icons/ChairPMBooked';
import ChairPMBookedHovered from '../../../../assets/icons/ChairPMBookedHovered';

const SeatIcon = (props) => {
   function processColor() {
      if (props.booked) {
         return '#C8C8C8';
      } else {
         return '#476B43';
      }
   }

   if (props.multi) {
      if (props.booking.AM && props.booking.PM == null) {
         return (
            <React.Fragment>
               {props.isHovered ? (
                  <ChairAMBookedHovered
                     sx={{
                        m: 1,
                     }}
                  />
               ) : (
                  <ChairAMBooked
                     sx={{
                        m: 1,
                     }}
                  />
               )}
               {props.booking.AM.owner && (
                  <div
                     style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '10px',
                        left: '10px',
                     }}
                  >
                     <Avatar
                        {...stringAvatar(
                           `${props.booking.AM.owner.name.firstName} ${props.booking.AM.owner.name.lastName}`,
                        )}
                     />
                  </div>
               )}
            </React.Fragment>
         );
      } else if (props.booking.PM && props.booking.AM == null) {
         return (
            <React.Fragment>
               {props.isHovered ? (
                  <ChairPMBookedHovered
                     sx={{
                        m: 1,
                     }}
                  />
               ) : (
                  <ChairPMBooked
                     sx={{
                        m: 1,
                     }}
                  />
               )}
               {props.booking.PM.owner && (
                  <div
                     style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '24px',
                        left: '24px',
                     }}
                  >
                     <Avatar
                        {...stringAvatar(
                           `${props.booking.PM.owner.name.firstName} ${props.booking.PM.owner.name.lastName}`,
                        )}
                     />
                  </div>
               )}
            </React.Fragment>
         );
      } else {
         return (
            <React.Fragment>
               {props.isHovered ? (
                  <EventSeatIcon
                     sx={{
                        color: '#C8C8C8',
                        m: 1,
                     }}
                  />
               ) : (
                  <EventSeatIconOutlined
                     sx={{
                        color: '#C8C8C8',
                        m: 1,
                     }}
                  />
               )}
               {props.booking.AM.owner && (
                  <div
                     style={{
                        position: 'absolute',
                        // zIndex: 1,
                        top: '10px',
                        left: '10px',
                        zIndex: 2,
                     }}
                  >
                     <Avatar
                        {...stringAvatar(
                           `${props.booking.AM.owner.name.firstName} ${props.booking.AM.owner.name.lastName}`,
                        )}
                     />
                  </div>
               )}
               {props.booking.PM.owner && (
                  <div
                     style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '24px',
                        left: '24px',
                     }}
                  >
                     <Avatar
                        {...stringAvatar(
                           `${props.booking.PM.owner.name.firstName} ${props.booking.PM.owner.name.lastName}`,
                        )}
                     />
                  </div>
               )}
            </React.Fragment>
         );
      }
   } else {
      return (
         <React.Fragment>
            {props.isHovered ? (
               <EventSeatIcon
                  sx={{
                     color: processColor(),
                     m: 1,
                  }}
               />
            ) : (
               <EventSeatIconOutlined
                  sx={{
                     color: processColor(),
                     m: 1,
                  }}
               />
            )}
            {props.booking && props.booking.owner && (
               <div
                  style={{
                     position: 'absolute',
                     zIndex: 1,
                     top: '15px',
                     left: '18px',
                  }}
               >
                  <Avatar
                     {...stringAvatar(
                        `${props.booking.owner.name.firstName} ${props.booking.owner.name.lastName}`,
                     )}
                  />
               </div>
            )}
         </React.Fragment>
      );
   }
};
export default SeatIcon;
