import { ExportToCsv } from 'export-to-csv';

export const exportToCsv = (filename, data) => {
   const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
   };

   const csvExporter = new ExportToCsv(options);

   csvExporter.generateCsv(data);
};
