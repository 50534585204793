import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import QRPDF from '../../Settings/location/qrPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core/styles';
import CalloutBox from '../../layout/CalloutBox';

const useStyles = makeStyles((theme) => ({
   formControl: {
      width: 200,
   },
   blueTable: {
      backgroundColor: '#342E76',
   },
   paper: {
      width: '100%',
      padding: theme.spacing(2),
   },
   input: {
      marginLeft: theme.spacing(1),
      flex: 1,
   },
   iconButton: {
      padding: 10,
   },
}));

const CheckingInPanel = ({ expanded, visitorsEnabled, lid, locationName }) => {
   const classes = useStyles();
   const QRvalue =
      'https://app.intheoffice.io/visitors?loc=' +
      lid +
      '&locName=' +
      encodeURIComponent(locationName.trim());
   const [qrSvgCode, setQrSvgCode] = useState('');
   const [isReady, setIsReady] = useState(false);

   useEffect(() => {
      const loadCanvas = async () => {
         console.log(visitorsEnabled, expanded, 'VISITORS');
         if (visitorsEnabled && expanded === 'visitors') {
            const qrCodeCanvas = document.querySelector(`#canvasVisitors-${lid}`);
            console.log(qrCodeCanvas, 'CANVASS');

            const qrCodeDataUri = qrCodeCanvas.toDataURL();
            setQrSvgCode(qrCodeDataUri);
            qrCodeCanvas.style.display = 'none';
         }
      };
      loadCanvas();
   }, [visitorsEnabled, expanded]);
   function handleCopy() {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(QRvalue);
   }
   return (
      <Box>
         <Box>
            <Typography variant="h6" component="h2">
               QR code check in
            </Typography>
         </Box>
         <Box>
            <QRCode level="Q" size={256} value={QRvalue} id={`canvasVisitors-${lid}`} />
            <Box classes={classes.pdf}>
               {isReady ? (
                  <PDFDownloadLink
                     document={<QRPDF data={'Title'} image={qrSvgCode} />}
                     fileName={locationName + '.pdf'}
                  >
                     {({ blob, url, loading, error }) =>
                        loading ? (
                           <Button variant="contained" disabled={true}>
                              Loading...
                           </Button>
                        ) : (
                           <Button variant="contained" color="primary">
                              Download
                           </Button>
                        )
                     }
                  </PDFDownloadLink>
               ) : (
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => setIsReady(true)}
                  >
                     Generate PDF
                  </Button>
               )}
            </Box>
         </Box>
         <Box>
            <Typography variant="body1">
               Generate a QR code PDF, and ask your visitors to scan it with their phones
               when they arrive. Once they have checked in, the person they are meeting
               will recieve an email informing them their visitor has arrived.
            </Typography>
         </Box>
         <Divider sx={{ mt: 2 }} />
         <Box>
            <Box>
               <Typography variant="h6" component="h2" sx={{ pt: 1, pb: 1 }}>
                  Kiosk check in
               </Typography>
            </Box>
            <Box>
               <CalloutBox contents={QRvalue} sx={{ width: '100%' }} />
            </Box>
            <Box>
               <Typography variant="caption">
                  Set up a visitor check in kiosk by copying this URL onto a device you
                  want yout visitors to use when they arrive.
               </Typography>
            </Box>
         </Box>
      </Box>
   );
};

export default CheckingInPanel;
