import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '70vh',
      maxWidth: '100%',
      marginTop: theme.spacing(5),
   },
}));

const EmailExistsForm = (props) => {
   const classes = useStyles();
   const [form, setForm] = useState({});
   const [signInType, setSignInType] = useState('');
   const [password, setPassword] = useState('');
   const [checked, setChecked] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      const listProviders = async () => {
         var email = props.mSCreds.email;
         await props.firebase
            .accountExistsDifferentCreds(email)
            .then(async function (methods) {
               if (methods.length === 0) {
                  setSignInType('noProviders');
               }
               if (methods[0] === 'password') {
                  setSignInType('emailProvider');
               }
            });
      };
      listProviders();
   }, []);

   useEffect(() => {
      const linkAccountWithoutProviders = async () => {
         if (signInType === 'noProviders') {
            var newPassword =
               Math.random().toString(36).substring(2, 15) +
               Math.random().toString(36).substring(2, 15);
            var convertAccount = firebase
               .functions()
               .httpsCallable('linkNonVerifiedUser');
            var data = props.mSCreds;
            data.newPassword = newPassword;
            await convertAccount(data).then(async () => {
               await props.firebase
                  .doSignInWithEmailAndPassword(props.mSCreds.email, newPassword)
                  .then(async function (result) {
                     return result.user.linkWithCredential(props.mSCreds.credential);
                  })
                  .then(async function () {
                     var user = JSON.parse(localStorage.getItem('authUser'));
                     var status = { statusId: 'active' };
                     await props.firebase.user(user.uid).set({ status }, { merge: true });
                     await props.firebase
                        .getPerson(user.cid, user.uid)
                        .set({ status }, { merge: true });
                     if (props.msTeams) {
                        props.history.push(ROUTES.MSTEAMSAUTH);
                     } else {
                        props.history.push(ROUTES.DASHBOARD);
                     }
                  });
            });
         }
      };
      linkAccountWithoutProviders();
   }, [signInType]);

   const Toast = () => {
      return (
         <div>
            <Collapse in={checked} collapsedHeight={0}>
               <Alert severity="error">
                  <strong>Error - </strong>
                  {errorMessage}
               </Alert>
            </Collapse>
         </div>
      );
   };

   const submitPassword = async (event) => {
      var data = props.mSCreds;
      var email = props.mSCreds.email;
      await props.firebase
         .doSignInWithEmailAndPassword(props.mSCreds.email, password)
         .then(async function (result) {
            return result.user.linkWithCredential(props.mSCreds.credential);
         })
         .then(async function () {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const page = urlParams.get('redirect');
            if (page === 'settings') {
               setSignInType('connected');
            } else {
               if (props.msTeams) {
                  props.history.push(ROUTES.MSTEAMSAUTH);
               } else {
                  props.history.push(ROUTES.DASHBOARD);
               }
            }
         })
         .catch((error) => {
            setErrorMessage(error.message);
            setChecked(true);
         });
   };

   return (
      <Container component="main" maxWidth="xs">
         <Toast />
         {signInType === 'noProviders' && (
            <React.Fragment>
               <Typography variant="h5" component="h2" paragraph>
                  Almost there...
               </Typography>
               <CircularProgress />
               <Typography variant="body1" paragraph>
                  We've found your details and just finishing configuring a few bits.
               </Typography>
            </React.Fragment>
         )}
         {signInType === 'emailProvider' && (
            <React.Fragment>
               <Typography variant="h5" component="h2" paragraph>
                  Almost there...
               </Typography>
               <Typography variant="body1" paragraph>
                  It looks like you previously signed in with your email. Enter your
                  intheOffice password below to link your account with Microsoft single
                  sign-on.
               </Typography>
               <Grid item xs={12}>
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Password"
                     type="password"
                     id="password"
                     value={password}
                     onInput={(e) => setPassword(e.target.value)}
                  />
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={(e) => {
                        submitPassword(e);
                     }}
                  >
                     {' '}
                     Submit{' '}
                  </Button>
               </Grid>
            </React.Fragment>
         )}
         {signInType === 'connected' && (
            <React.Fragment>
               <Typography variant="h5" component="h2" paragraph>
                  Connection complete.
               </Typography>

               <Button variant="contained" color="primary" href={ROUTES.SETTINGSUSERS}>
                  Go back to settings
               </Button>
            </React.Fragment>
         )}
      </Container>
   );
};

const EmailExists = compose(withRouter, withFirebase)(EmailExistsForm);
export default withFirebase(EmailExists);
