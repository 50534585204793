import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { green } from '@material-ui/core/colors';
import * as EmailValidator from 'email-validator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
   container: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(8),
   },
   paper: {
      marginTop: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
   },
   green: {
      color: green[700],
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}));
const VisitorDetails = ({ customisations, clearForm, ...props }) => {
   const classes = useStyles();
   const history = useHistory();
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [nameError, setNameError] = useState({ status: false, text: '' });
   const [emailError, setEmailError] = useState({ status: false, text: '' });

   function handleSubmit() {
      var valid = true;
      setNameError({ state: false, text: '' });
      setEmailError({ state: false, text: '' });
      var emailValid = EmailValidator.validate(email); // true
      if (emailValid === false) {
         setEmailError({ status: true, text: 'You must enter a vaild email address' });
         valid = false;
      }
      if (name === '') {
         setNameError({ status: true, text: 'You must enter a vaild email address' });
         valid = false;
      }
      if (valid) {
         props.setForm({ name, email: email.toLowerCase() });
         props.setStatus('step2');
      }
   }

   return (
      <React.Fragment>
         <Avatar className={classes.avatar}>
            <AssignmentIndIcon />
         </Avatar>
         <Typography variant="h5" component="h2">
            Your details
         </Typography>
         <Typography variant="body1" component="p" align="center">
            If you are arriving or leaving, please enter your name and email address.
         </Typography>
         <form className={classes.form}>
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               id="name"
               label="Name"
               name="name"
               value={name}
               error={nameError.status}
               helperText={nameError.text}
               onInput={(e) => setName(e.target.value)}
            />
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               id="email"
               label="Email Address"
               name="email"
               value={email}
               onInput={(e) => {
                  setEmail(e.target.value);
               }}
               error={emailError.status}
               helperText={emailError.text}
            />
            <Button
               onClick={() => {
                  handleSubmit();
               }}
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
            >
               Next
            </Button>
            <Button
               onClick={() => {
                  clearForm();
               }}
               fullWidth
               variant="outlined"
               color="primary"
            >
               start again
            </Button>
         </form>
      </React.Fragment>
   );
};
export default VisitorDetails;
