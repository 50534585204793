import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import Main from './main';
import { AuthProvider } from '../../providers';

const Schedule = (props) => {
   return (
      <AuthProvider>
         <Main />
      </AuthProvider>
   );
};

const HomePage = compose(withRouter, withFirebase)(Schedule);

const condition = (authUser) => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition))(HomePage);
