import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import VisitorList from './visitorList';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Accordion = styled((props) => (
   <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
   border: `1px solid ${theme.palette.divider}`,
   '&:not(:last-child)': {
      borderBottom: 0,
   },
   '&:before': {
      display: 'none',
   },
}));

const AccordionSummary = styled((props) => (
   <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
   />
))(({ theme }) => ({
   minHeight: '24px',
   height: '26px',
   backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
   flexDirection: 'row-reverse',
   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
   },
   '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
   },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   padding: theme.spacing(2),
   borderTop: '1px solid rgba(0, 0, 0, .125)',
   maxHeight: '500px',
   overflow: 'scroll',
}));
const VisitorListAccordion = ({ handleEditModal, ...props }) => {
   const [expanded, setExpanded] = useState({
      panel1: true,
      panel2: true,
      panel3: false,
   });

   useEffect(() => {
      if (props.dayVisitors) {
         if (props.dayVisitors.expected.length === 0) {
            setExpanded({ ...expanded, panel1: false });
         }
         if (props.dayVisitors.checkedIn.length === 0) {
            setExpanded({ ...expanded, panel2: false });
         }
         if (props.dayVisitors.checkedOut.length === 0) {
            setExpanded({ ...expanded, panel3: false });
         }
      }
   }, [props.dayVisitors]);

   return (
      <Box sx={{ mt: 2, width: '100%', typography: 'body1' }}>
         <Accordion
            expanded={expanded.panel1}
            onChange={() => setExpanded({ ...expanded, panel1: !expanded.panel1 })}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="expected">
               <Box
                  sx={{
                     display: 'flex',
                     width: '100%',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}
               >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>Expected</Typography>
                  <Typography
                     variant="caption"
                     sx={{ textAlign: 'right', color: 'text.secondary' }}
                  >
                     count: {props.dayVisitors.expected.length}
                  </Typography>
               </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
               {props.dayVisitors.expected.length > 0 ? (
                  <VisitorList
                     cid={props.cid}
                     lid={props.lid}
                     date={props.date}
                     dayVisitors={props.dayVisitors.expected}
                     type={'expected'}
                     // handleChange={handleChange}
                     handleEditModal={handleEditModal}
                  />
               ) : (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                     No visitors expected
                  </Typography>
               )}
            </AccordionDetails>
         </Accordion>
         <Accordion
            expanded={expanded.panel2}
            onChange={() => setExpanded({ ...expanded, panel2: !expanded.panel2 })}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="expected">
               <Box
                  sx={{
                     display: 'flex',
                     width: '100%',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                     }}
                  >
                     <AssignmentTurnedInIcon />
                     <Typography sx={{ ml: 1 }}>Checked In</Typography>
                  </Box>

                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                     count: {props.dayVisitors.checkedIn.length}
                  </Typography>
               </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
               {props.dayVisitors.checkedIn.length > 0 ? (
                  <VisitorList
                     cid={props.cid}
                     lid={props.id}
                     date={props.date.ref}
                     dayVisitors={props.dayVisitors.checkedIn}
                     type={'checkedIn'}
                     handleEditModal={handleEditModal}
                  />
               ) : (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                     No visitors checked in
                  </Typography>
               )}
            </AccordionDetails>
         </Accordion>
         <Accordion
            expanded={expanded.panel3}
            onChange={() => setExpanded({ ...expanded, panel3: !expanded.panel3 })}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="expected">
               <Box
                  sx={{
                     display: 'flex',
                     width: '100%',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                     }}
                  >
                     <ExitToAppIcon />
                     <Typography sx={{ ml: 1 }}>Checked Out</Typography>
                  </Box>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                     count: {props.dayVisitors.checkedOut.length}
                  </Typography>
               </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
               {props.dayVisitors.checkedOut.length > 0 ? (
                  <VisitorList
                     cid={props.cid}
                     lid={props.id}
                     date={props.date.ref}
                     dayVisitors={props.dayVisitors.checkedOut}
                     type={'checkedOut'}
                  />
               ) : (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                     No visitors checked out
                  </Typography>
               )}
            </AccordionDetails>
         </Accordion>
      </Box>
   );
};

export default VisitorListAccordion;
