import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { Chip, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      [theme.breakpoints.down('md')]: {
         width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
         width: '90%',
      },
      [theme.breakpoints.down('xs')]: {
         width: '90%',
      },
      [theme.breakpoints.up('md')]: {
         width: '65%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   title: {
      marginTop: theme.spacing(4),
   },
}));

const MeetingBookingDrawer = (props) => {
   const classes = useStyles();
   const [form, setForm] = useState({});
   const [type, setType] = useState([
      { name: 'Meeting', selected: false },
      { name: 'In the Office request', selected: false },
   ]);
   function handleClose() {
      props.setMeetingDrawer(false);
      setForm({});
   }

   function handleTypeClick(row) {
      alert(row.name);
   }
   return (
      <Drawer
         classes={{ paper: classes.drawerPaper }}
         anchor="right"
         open={props.meetingDrawer}
         onClose={() => handleClose()}
      >
         <Container maxWidth="md">
            <div className={classes.root}>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Typography
                        className={classes.title}
                        align="center"
                        component="h1"
                        variant="h5"
                     >
                        Meeting drawer
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     container
                     direction="row"
                     justify="center"
                     alignItems="center"
                     xs={12}
                     spacing={2}
                  >
                     <Grid item xs={12}>
                        {type.map((row) => (
                           <Chip
                              key={row.name}
                              label={row.name}
                              clickable
                              onClick={() => handleTypeClick(row)}
                           />
                        ))}
                     </Grid>
                     <Grid item xs={12}></Grid>
                     <Grid item>
                        <Button variant="contained" color="primary">
                           Save
                        </Button>
                     </Grid>
                     <Grid item>
                        <Button
                           variant="outlined"
                           color="primary"
                           onClick={() => handleClose()}
                        >
                           Discard
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </div>
         </Container>
      </Drawer>
   );
};

export default withFirebase(MeetingBookingDrawer);
