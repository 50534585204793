import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import enLocale from 'date-fns/locale/en-GB';
import {
   DatePicker,
   // MobileDatePicker,
   TimePicker,
   DateTimePicker,
   MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Typography } from '@material-ui/core';

const DatePick = (props) => {
   const [selectedDate, handleDateChange] = useState(null);
   useEffect(() => {
      if (props.value.date) {
         handleDateChange(props.value.date);
      }
   }, [props.value]);
   if (props.pickerType === 'date') {
      return (
         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <Typography style={{ marginRight: '8px' }} variant="caption">
                  Date:
               </Typography>
               {selectedDate !== null && (
                  <DatePicker
                     format="dd/MM/Y"
                     value={selectedDate}
                     minDate={new Date()}
                     onChange={(e) => {
                        handleDateChange(e);
                        props.setDate({ ...props.value, date: e });
                     }}
                     renderInput={(props) => <TextField {...props} />}
                  />
               )}
            </div>
         </MuiPickersUtilsProvider>
      );
   } else if (props.pickerType === 'range') {
      return (
         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <Typography style={{ marginRight: '8px' }} variant="caption">
                  Date:
               </Typography>
               {selectedDate !== null && (
                  <DatePicker
                     format="dd/MM/Y"
                     value={selectedDate}
                     minDate={props.min.date}
                     maxDate={props.max.date}
                     onChange={(e) => {
                        handleDateChange(e);
                        props.setDate({ ...props.value, date: e });
                     }}
                     renderInput={(props) => <TextField {...props} />}
                  />
               )}
            </div>
         </MuiPickersUtilsProvider>
      );
   } else if (props.pickerType === 'time') {
      return (
         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <Typography style={{ marginRight: '8px' }} variant="caption">
                  Time:
               </Typography>
               <TimePicker value={selectedDate} onChange={handleDateChange} />
            </div>
         </MuiPickersUtilsProvider>
      );
   } else {
      return (
         <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <Typography style={{ marginRight: '8px' }} variant="caption">
                  Date and Time:
               </Typography>
               <DateTimePicker
                  format="dd/MM/Y - HH:mm"
                  value={selectedDate}
                  onChange={handleDateChange}
               />
            </div>
         </MuiPickersUtilsProvider>
      );
   }
};

export default DatePick;
