import React, { useEffect, useState } from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { withFirebase } from '../../../Firebase';
import DoneIcon from '@mui/icons-material/Done';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

const ITOFromOffsite = (props) => {
   const [schedule, setSchedule] = useState(false);
   let lid = props.displayFilter.id;
   if (props.displayFilter.type === 'group') {
      lid = props.displayFilter.lid;
   }
   useEffect(() => {
      if (props.lid && props.day && props.companyPeople) {
         const unsubscribe = props.firebase
            .locationDayScheduleDate(props.lid, props.day.ref)
            .onSnapshot((result) => {
               let sched = [];
               let nums = {
                  ito: {
                     allDay: 0,
                     am: 0,
                     pm: 0,
                  },
                  offsite: {
                     allDay: 0,
                     am: 0,
                     pm: 0,
                  },
               };
               if (result.exists) {
                  Object.entries(result.data()).map(([key, value]) => {
                     if (props.block === 'ito') {
                        if (
                           value.status === 'offsite' &&
                           value.offsiteLocation &&
                           value.offsiteLocation.locationId &&
                           value.offsiteLocation.locationId === lid
                        ) {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'allDay',
                              status: 'offsite',
                           });
                           nums.ito.allDay = nums.ito.allDay + 1;
                        }
                        if (value.status === 'split' && value.offsiteLocation) {
                           if (
                              value.offsiteLocation?.AM?.locationId &&
                              value.offsiteLocation?.AM?.locationId === lid
                           ) {
                              sched.push({
                                 uid: key,
                                 name: {
                                    firstName: props.companyPeople[key].name.firstName,
                                    lastName: props.companyPeople[key].name.lastName,
                                 },
                                 time: 'AM',
                                 status: 'offsite',
                              });
                              nums.ito.am = nums.ito.am + 1;
                           }
                           if (
                              value.offsiteLocation?.PM?.locationId &&
                              value.offsiteLocation?.PM?.locationId === lid
                           ) {
                              sched.push({
                                 uid: key,
                                 name: {
                                    firstName: props.companyPeople[key].name.firstName,
                                    lastName: props.companyPeople[key].name.lastName,
                                 },
                                 time: 'PM',
                                 status: 'offsite',
                              });
                              nums.ito.pm = nums.ito.pm + 1;
                           }
                        }
                     }
                     if (props.block === 'offsite') {
                        if (value.status === 'ito') {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'allDay',
                              status: 'ito',
                           });
                           nums.offsite.allDay = nums.offsite.allDay + 1;
                        }
                        if (value.status === 'split' && value.splitDay.AM === 'ito') {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'AM',
                              status: 'ito',
                           });
                           nums.offsite.am = nums.offsite.am + 1;
                        }
                        if (value.status === 'split' && value.splitDay.PM === 'ito') {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'PM',
                              status: 'ito',
                           });
                           nums.offsite.pm = nums.offsite.pm + 1;
                        }
                     }
                  });
               }
               if (props.block == 'offsite' && props.routineIndex) {
                  Object.entries(props.routineIndex).map(([key, value]) => {
                     if (
                        value.routine &&
                        value.routine[props.day.weekDay] === 'ito' &&
                        value.lid === props.lid
                     ) {
                        if (result.exists && isUidNotKey(result.data(), key)) {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'allDay',
                              status: 'ito',
                           });
                           nums.offsite.allDay = nums.offsite.allDay + 1;
                        } else if (!result.exists) {
                           sched.push({
                              uid: key,
                              name: {
                                 firstName: props.companyPeople[key].name.firstName,
                                 lastName: props.companyPeople[key].name.lastName,
                              },
                              time: 'allDay',
                              status: 'ito',
                           });
                           nums.offsite.allDay = nums.offsite.allDay + 1;
                        }
                     }
                  });
               }
               setSchedule(sched);
               props.setOtherLocITONums((prevState) => ({
                  ...prevState,
                  [props.lid]: { ...nums },
               }));
               sched.sort((a, b) =>
                  a.name.firstName.toLowerCase() > b.name.firstName.toLowerCase()
                     ? 1
                     : -1,
               );
            });
         return () => {
            let nums = {
               ito: props.otherLocITONums[props.lid].ito,
               offsite: {
                  allDay: 0,
                  am: 0,
                  pm: 0,
               },
            };
            props.setOtherLocITONums((prevState) => ({
               ...prevState,
               [props.lid]: { ...nums },
            }));

            unsubscribe();
         };
      }
   }, [props.lid, props.day, props.companyPeople, props.routineIndex, props.block]);
   function isUidNotKey(obj, value) {
      return !Object.keys(obj).includes(value);
   }

   return (
      <React.Fragment>
         {schedule && schedule.length > 0 && (
            <React.Fragment>
               {schedule.map((entry) => {
                  if (props.block === 'ito' && entry.status === 'offsite') {
                     return (
                        <Tooltip
                           title={props.locName}
                           arrow
                           placement="top"
                           variant="outlined"
                           key={`${entry.uid}${entry.time}`}
                        >
                           <Chip
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              color="primary"
                              variant="outlined"
                              key={entry.uid}
                              icon={<DoneIcon />}
                              label={
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       flexWrap: 'wrap',
                                    }}
                                 >
                                    {entry.name.firstName} {entry.name.lastName}
                                    {entry.time !== 'allDay' && ` - ${entry.time}`}
                                    <BusinessOutlinedIcon
                                       fontSize="small"
                                       sx={{ ml: 1 }}
                                    />
                                 </Box>
                              }
                           />
                        </Tooltip>
                     );
                  }
                  if (props.block === 'offsite' && entry.status === 'ito') {
                     return (
                        <Tooltip
                           title={props.locName}
                           arrow
                           placement="top"
                           variant="outlined"
                           key={`${entry.uid}${entry.time}`}
                        >
                           <Chip
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              color="primary"
                              variant="outlined"
                              key={entry.uid}
                              icon={<PinDropIcon />}
                              label={
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       flexWrap: 'wrap',
                                    }}
                                 >
                                    {entry.name.firstName} {entry.name.lastName}
                                    {entry.time !== 'allDay' && ` - ${entry.time}`}
                                    <BusinessOutlinedIcon
                                       fontSize="small"
                                       sx={{ ml: 1 }}
                                    />
                                 </Box>
                              }
                           />
                        </Tooltip>
                     );
                  }
               })}
            </React.Fragment>
         )}
      </React.Fragment>
   );
};
export default withFirebase(ITOFromOffsite);
