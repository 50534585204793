export const featureSet = [
   'advancedScheduling',
   'groups',
   'multisite',
   'reporting',
   'spaceBookings',
   'visitors',
   'timesheets',
   'leave',
];
