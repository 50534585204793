import React from 'react';
import {
   Box,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Tooltip,
   Typography,
} from '@mui/material';
import CardSection from '../../layout/CardSection/CardSection';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';

const ReadOnlyMeetingForm = ({ form }) => {
   function makeMins(time) {
      time = ('0' + time).slice(-2);
      return time;
   }
   return (
      <Box sx={{}}>
         <CardSection>
            <Typography variant="h6" paragraph>
               Details
            </Typography>
            <List>
               <ListItem>
                  <ListItemIcon>
                     <Tooltip placement="top" title="Title">
                        <FormatQuoteOutlinedIcon />
                     </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={form.title} />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Tooltip placement="top" title="Start/End time">
                        <AccessTimeOutlinedIcon />
                     </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                     primary={`${form.start.getHours()}:${makeMins(
                        form.start.getMinutes(),
                     )} - ${form.end.getHours()}:${makeMins(form.end.getMinutes())}`}
                  />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Tooltip placement="top" title="Host">
                        <PortraitOutlinedIcon />
                     </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={form.host.name} />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Tooltip placement="top" title="Seats">
                        <EventSeatOutlinedIcon />
                     </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={form.seats} />
               </ListItem>
            </List>
         </CardSection>
      </Box>
   );
};

export default ReadOnlyMeetingForm;
