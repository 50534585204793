import React from 'react';
import { Typography } from '@mui/material';

const Seats = (props) => {
   switch (props.time) {
      case 2:
         return (
            <Typography variant="caption" color="textSecondary">
               AM -{' '}
               {props.space.seatsAvailableAM
                  ? props.space.seatsAvailableAM
                  : props.space.seatsAvailable}
               /{props.space.seats} available
            </Typography>
         );
      case 3:
         return (
            <Typography variant="caption" color="textSecondary">
               PM -{' '}
               {props.space.seatsAvailablePM
                  ? props.space.seatsAvailablePM
                  : props.space.seatsAvailable}
               /{props.space.seats} available
            </Typography>
         );

      default:
         return (
            <Typography variant="caption" color="textSecondary">
               {props.space.seatsAvailable}/{props.space.seats} available
            </Typography>
         );
   }
};

export default Seats;
