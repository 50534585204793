export const Rows = {
   id: 0,
   name: 'Loading...',
   date: {
      0: {
         day: '1',
      },
      1: {
         day: '2',
      },
      2: {
         day: '3',
      },
      3: {
         day: '4',
      },
      4: {
         day: '5',
      },
      5: {
         day: '6',
      },
      6: {
         day: '7',
      },
   },
   status: {
      0: {
         set: '-',
      },
      1: {
         set: '-',
      },
      2: {
         set: '-',
      },
      3: {
         set: '-',
      },
      4: {
         set: '-',
      },
      5: {
         set: '-',
      },
      6: {
         set: '-',
      },
   },
};

export const spaceTypes = {
   desk: 'Desk',
   office: 'Office',
   cowork: 'Co-working space',
   room: 'Meeting room',
   area: 'Area',
   parking: 'Parking space',
   locker: 'Locker',
   pets: 'Pets',
};
