import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';

import { styled } from '@mui/system';

const IconButton = styled(Button)({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'center',
   padding: '10px',
   minWidth: '90px',
   minHeight: '90px',
   transition: 'box-shadow 0.1s ease-in-out',
   '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
   },
});

const SideBarMoreMenuItems = ({ navItems }) => {
   const { theme } = useTheme();
   const history = useHistory();
   const location = useLocation();
   const internalRoute = (path) => history.push(path);
   const externalRoute = (path) => window.open(path, '_blank');
   return (
      <Box display="flex" flexDirection="column">
         <Box display="flex" flexDirection="row" sx={{ padding: 0.5, flexWrap: 'wrap' }}>
            {navItems.map((navItem, index) => (
               <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                     minWidth: '100px',
                     maxWidth: '100px',
                     minHeight: '120px',
                     maxHeight: '120px',
                     borderRadius: 1,
                     padding: 1,
                     justifyContent: 'space-between',
                     alignContent: 'stretch',
                     flexWrap: 'wrap',
                  }}
               >
                  <IconButton
                     key={index}
                     onClick={() =>
                        navItem.externalPath !== null
                           ? externalRoute(navItem.externalPath)
                           : internalRoute(navItem.path)
                     }
                     sx={{ padding: 2 }}
                  >
                     <navItem.icon
                        sx={{
                           fontSize: 30,
                           border: '0.5px solid',
                           borderRadius: 1,
                           borderColor: 'text',
                           m: 1,
                        }}
                     />
                     <Typography
                        variant="caption"
                        sx={{
                           mt: 1,
                           fontSize: 'x-small',
                           textTransform: 'none',
                           '& .MuiButton-label': {
                              textTransform: 'capitalize',
                           },
                        }}
                     >
                        {navItem.name}
                     </Typography>
                  </IconButton>
               </Box>
            ))}
         </Box>
         <Divider sx={{ width: '100%', mt: 1, mb: 1 }} />
         <Typography variant="caption" align="center">
            Enable more features in Settings
         </Typography>
      </Box>
   );
};

export default SideBarMoreMenuItems;
