import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import { Firebase, FirebaseContext } from './components/Firebase';
import './index.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarProvider } from 'notistack';
// MSAL configuration
const configuration = {
   auth: {
      clientId: '0a89f7d8-b648-4a31-b009-df4f252fb650',
   },
};

const pca = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
   <FirebaseContext.Provider value={new Firebase()}>
      <MsalProvider instance={pca}>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ErrorBoundary>
               <SnackbarProvider>
                  <App />
               </SnackbarProvider>
            </ErrorBoundary>
         </MuiPickersUtilsProvider>
      </MsalProvider>
   </FirebaseContext.Provider>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'));
