import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const ItoMiniBothBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} color="primary" viewBox="0 0 26 40">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="ito-mini-bothBooked"
               transform="translate(0.0004, 0)"
               fill={theme.palette.primary.main}
            >
               <path
                  d="M25.9995623,12.41 L26,36 C26,38.209139 24.209139,40 22,40 L4,40 C1.790861,40 -2.84215468e-14,38.209139 -2.84215468e-14,36 L-0.000437746097,28.956 L25.9995623,12.41 Z M21.7272727,22.4 L12.0909091,32.6835821 L8.27272727,28.6089552 L7,29.9671642 L12.0909091,35.4 L23,23.758209 L21.7272727,22.4 Z M22,0 C24.209139,0 26,1.790861 26,4 L25.9995623,11.225 L-0.000437746097,27.771 L-2.84215468e-14,4 C-2.84215468e-14,1.790861 1.790861,0 4,0 L22,0 Z M16.7272727,3.4 L7.09090909,13.6835821 L3.27272727,9.60895522 L2,10.9671642 L7.09090909,16.4 L18,4.75820896 L16.7272727,3.4 Z"
                  id="Combined-Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoMiniBothBooked;
