import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../components/Dialog';

function getSinglePlural(count) {
   return count > 1 || count === 0 ? 'members' : 'member';
}

const GroupMembersListHeader = ({
   handleClearAll,
   renderedMembers,
   editedMembers,
   editedAdminMembers,
}) => {
   const { groupDetails, allPeopleData } = useDialogContext();

   // console.warn('groupDetails from header', groupDetails);
   const { setValue } = useFormContext();

   function getMemberCount() {
      const memberCount = Object.keys(editedMembers).length;
      setValue('count', memberCount);
      return memberCount;
   }

   // function getExternalMemberCount() {
   //    const removedMembers = Object.keys(editedMembers);

   //    const allAddedMembers = Object.keys({
   //       ...originalMembers,
   //       ...editedMembers,
   //       ...editedAdminMembers,
   //    }).reduce((acc, id) => (removedMembers.includes(id) ? acc : [...acc, id]), []);

   //    if (allPeopleData.raw) {
   //       const result = allAddedMembers.filter(
   //          (id) => allPeopleData.raw[id].externalLocation,
   //       );
   //       setValue('externalMembers', result.length);
   //       return;
   //    }
   //    setValue('externalMembers', 0);
   // }

   const count = getMemberCount();
   useEffect(() => {
      getMemberCount();
      // getExternalMemberCount();
   }, [editedMembers]);

   return (
      <Grid container spacing={2} sx={{ my: 2 }} alignItems={'baseline'}>
         <Grid item xs={12} sm={12} md>
            <Typography variant="h6">Group members </Typography>
         </Grid>

         <Grid item>
            <Box display={'flex'} alignItems={'center'} gap={2}>
               <Typography color={'GrayText'}>
                  {`${count} ${getSinglePlural(count)} added `}
                  {renderedMembers ? `of ${renderedMembers.length}` : null}
               </Typography>
               <Button variant="outlined" size="small" onClick={handleClearAll}>
                  Clear all
               </Button>
            </Box>
         </Grid>
      </Grid>
   );
};

export default GroupMembersListHeader;
