import React, { useEffect, useState } from 'react';
import {
   Box,
   Typography,
   Button,
   FormControlLabel,
   IconButton,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   CircularProgress,
   FormLabel,
   FormControl,
   RadioGroup,
   Radio,
   Stack,
   FormHelperText,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicIcon from '@mui/icons-material/Public';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@material-ui/core/TextField';
import { withFirebase } from '../Firebase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Members from './Groups/members';
import Admins from './Groups/admins';
import FeatureSwitch from '../Billing/featureSwitch';
import { chunkObject } from '../../constants/functions';
import AuthProvider from '../../providers/AuthProvider';
import { manageAdminAllocation } from './Groups/functions';

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: '60%',
   bgcolor: 'background.paper',
   boxShadow: 24,
   p: 4,
};

const EditGroupModal = (props) => {
   const groupReset = {
      admins: {},
      companyPublic: false,
      count: 0,
      global: false,
      hidden: false,
      lid: '',
      locationPublic: false,
      name: '',
      type: 'group',
      members: {},
   };
   const [group, setGroup] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const [checked, setChecked] = useState(false);
   const [deleteConfirm, setDeleteConfirm] = useState(false);
   const [expanded, setExpanded] = useState(false);
   const [groupCount, setGroupCount] = useState(0);
   const [editedMembers, setEditedMembers] = useState({ new: {}, removed: {} });
   const [saving, setSaving] = useState(false);
   const [deleteWarning, setDeleteWarning] = useState(false);

   const handleChange = () => {
      setChecked(!checked);
   };

   const handleClose = () => {
      props.setEditGroup(false);
      props.setSelectedGroup(null);
      setGroup(false);
      setDisabled(false);
      props.setNewGroup(false);
      setSaving(false);
      setEditedMembers({ new: {}, removed: {} });
      setDeleteWarning(false);
   };
   useEffect(() => {
      const loadGroups = () => {
         if (props.editGroup) {
            if (props.newGroup) {
               setGroup({ ...groupReset, lid: props.lid });
            } else if (props.selectedGroup !== null) {
               console.log(props.selectedGroup);
               setGroup(props.selectedGroup);
               setGroupCount(props.selectedGroup.count);
            }
         }
      };
      loadGroups();
      return () => {
         setGroup(false);
         setGroupCount(0);
      };
   }, [props.selectedGroup, props.editGroup, props.newGroup]);

   async function handleSave() {
      setSaving(true);
      var gid = '';
      let admins = {};
      if (props.newGroup) {
         gid = await props.firebase.locationGroups().doc();
         gid = gid.id;
      } else {
         gid = props.selectedGroup.gid;
         admins = await props.firebase.db
            .doc(`location/${props.lid}/groups/${gid}`)
            .get()
            .then((result) => {
               return result.data().admins;
            });
      }
      let groupToSave = { ...group };
      delete groupToSave.members;
      try {
         await props.firebase.saveGroup(props.lid, gid, groupToSave);
         let uidsList = await convertMembers(editedMembers, admins);
         if (uidsList) {
            if (Object.keys(admins).length !== 0) {
               try {
                  await manageAdminAllocation(
                     props.firebase,
                     props.cid,
                     props.lid,
                     gid,
                     uidsList,
                     admins,
                  );
               } catch (error) {
                  console.warn(error, 'error - managing admin allocation');
               }
            }
            let chunks = await chunkObject(uidsList, 10);
            chunks.map(async (chunk) => {
               try {
                  let uidsAuthorised = await getAuthedUsers(chunk);
                  await props.firebase.updateGroupMembers(
                     props.cid,
                     props.lid,
                     gid,
                     uidsAuthorised,
                     admins,
                  );
               } catch (error) {
                  console.warn(error, 'error - updating group');
               }
            });
         }
      } catch (error) {
         console.warn(error, 'error - saving group');
      }
      handleClose();
   }

   async function convertMembers(editedMembers) {
      if (
         Object.keys(editedMembers.new).length === 0 &&
         Object.keys(editedMembers.removed).length === 0
      ) {
         return false;
      } else {
         let uids = {};
         Object.entries(editedMembers.new).map(([keys, value]) => {
            uids[keys] = { ...value, type: 'new' };
         });
         Object.entries(editedMembers.removed).map(async ([keys, value]) => {
            uids[keys] = { ...value, type: 'removed' };
         });
         return uids;
      }
   }
   async function getAuthedUsers(uidsList) {
      let uids = [];
      Object.keys(uidsList).map((key) => {
         uids.push(key);
      });
      let people = await props.firebase
         .companyPeopleArray(props.cid, uids)
         .get()
         .then((results) => {
            let data = {};
            results.forEach((result) => {
               if (result.data().status.statusId !== 'notInvited') {
                  data[result.id] = {
                     ...uidsList[result.id],
                     auth: true,
                  };
               } else {
                  data[result.id] = {
                     ...uidsList[result.id],
                     auth: false,
                  };
               }
            });
            return data;
         })
         .catch((error) => {
            console.warn(error, 'Error - couldnt retrieve company users');
         });
      return people;
   }
   async function handleDelete() {
      setDisabled(true);
      if (props.selectedGroup.count > 50) {
         setDeleteWarning(
            'Please remove the group members before you try to delete the group.',
         );
         setDisabled(false);
         setDeleteConfirm(false);
      } else {
         try {
            const people = await props.firebase
               .groupPeople(props.cid, props.selectedGroup.gid)
               .get()
               .then((results) => {
                  let ppl = {};
                  if (!results.empty) {
                     results.forEach((result) => {
                        if (result.data().status.statusId !== 'notInvited') {
                           ppl[result.id] = { auth: true };
                        } else {
                           ppl[result.id] = { auth: false };
                        }
                     });
                  }
                  return ppl;
               });
            if (props.selectedGroup.gid === props.groupFilter) {
               props.setGroupFilter('');
            }
            await props.firebase.deleteGroup(
               props.cid,
               props.lid,
               props.selectedGroup.gid,
               people,
            );
            handleClose();
         } catch (error) {
            console.warn(error, 'error - deleting group');
         }
      }
   }

   const expandPanel = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   return (
      <React.Fragment>
         {props.groupsEnabled ? (
            <React.Fragment>
               {saving ? (
                  <Box sx={{ style }}>
                     <CircularProgress />
                  </Box>
               ) : (
                  <Box sx={style}>
                     {props.newGroup ? (
                        <Typography variant="h5" component="h2" paragraph>
                           Add a group
                        </Typography>
                     ) : (
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                           }}
                        >
                           <Typography variant="h5" component="h2" paragraph>
                              Edit group
                           </Typography>
                           {deleteConfirm ? (
                              <Button
                                 startIcon={<DeleteIcon />}
                                 aria-label="Confirm Delete"
                                 disabled={disabled}
                                 color="error"
                                 onClick={() => {
                                    handleDelete();
                                 }}
                              >
                                 Confirm Delete
                              </Button>
                           ) : (
                              <IconButton
                                 aria-label="delete"
                                 disabled={disabled}
                                 onClick={() => {
                                    setDeleteConfirm(true);
                                 }}
                              >
                                 <DeleteIcon />
                              </IconButton>
                           )}
                        </Box>
                     )}
                     {group && (
                        <React.Fragment>
                           <TextField
                              id="group"
                              label="Group Name"
                              variant="outlined"
                              value={group.name}
                              fullWidth
                              onChange={(e) =>
                                 setGroup({ ...group, name: e.target.value })
                              }
                           />
                           <Box sx={{ p: 1, mt: 1 }}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 onChange={expandPanel('panel1')}
                              >
                                 <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '100%',
                                       }}
                                    >
                                       <Box sx={{ width: '250px' }}>
                                          <Typography>Members</Typography>
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          {group.global && (
                                             <PublicIcon fontSize="small" />
                                          )}
                                          <PeopleAltIcon fontSize="small" />
                                          <Typography variant="caption">
                                             {groupCount} ppl
                                          </Typography>
                                          {console.log(group)}
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                             minWidth: '50px',
                                          }}
                                       ></Box>
                                    </Box>
                                 </AccordionSummary>
                                 <AccordionDetails
                                    sx={{
                                       minHeight: '100px',
                                       maxHeight: '20vh',
                                       overflow: 'scroll',
                                    }}
                                 >
                                    {expanded === 'panel1' && (
                                       <Members
                                          lid={group.lid}
                                          gid={group.gid}
                                          cid={props.cid}
                                          setEditedMembers={setEditedMembers}
                                          editedMembers={editedMembers}
                                          setGroupCount={setGroupCount}
                                       />
                                    )}
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'admins'}
                                 onChange={expandPanel('admins')}
                              >
                                 <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '100%',
                                       }}
                                    >
                                       <Box sx={{ width: '250px' }}>
                                          <Typography>Group Admins</Typography>
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          <AdminPanelSettingsIcon color="disabled" />
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                             minWidth: '50px',
                                          }}
                                       ></Box>
                                    </Box>
                                 </AccordionSummary>
                                 <AccordionDetails
                                    sx={{
                                       minHeight: '100px',
                                       maxHeight: '20vh',
                                       overflow: 'scroll',
                                    }}
                                 >
                                    {expanded === 'admins' && (
                                       <AuthProvider>
                                          <Admins
                                             lid={group.lid}
                                             gid={group.gid}
                                             cid={props.cid}
                                          />
                                       </AuthProvider>
                                    )}
                                 </AccordionDetails>
                              </Accordion>
                              <Accordion
                                 expanded={expanded === 'panel2'}
                                 onChange={expandPanel('panel2')}
                              >
                                 <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '100%',
                                       }}
                                    >
                                       <Box sx={{ width: '250px' }}>
                                          <Typography>Visibility settings</Typography>
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          <Stack direction="row" spacing={2}>
                                             {group.locationPublic ? (
                                                <VisibilityIcon color="disabled" />
                                             ) : (
                                                <VisibilityOffIcon color="disabled" />
                                             )}
                                          </Stack>
                                       </Box>
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                             minWidth: '50px',
                                          }}
                                       ></Box>
                                    </Box>
                                 </AccordionSummary>
                                 <AccordionDetails
                                    sx={{
                                       minHeight: '100px',
                                       maxHeight: '20vh',
                                       overflow: 'scroll',
                                    }}
                                 >
                                    <FormControl>
                                       <FormLabel id="publish-group-radios">
                                          <div
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                             }}
                                          >
                                             {group.locationPublic ? (
                                                <VisibilityIcon color="primary" />
                                             ) : (
                                                <VisibilityOffIcon color="primary" />
                                             )}
                                             Visibility
                                          </div>
                                       </FormLabel>
                                       <RadioGroup
                                          row
                                          aria-labelledby="publish-group-radios"
                                          name="publish-group-radios"
                                          value={group.locationPublic}
                                          onChange={() =>
                                             setGroup({
                                                ...group,
                                                locationPublic: !group.locationPublic,
                                             })
                                          }
                                       >
                                          <FormControlLabel
                                             value={false}
                                             control={<Radio />}
                                             label="Private"
                                          />
                                          <FormControlLabel
                                             value={true}
                                             control={<Radio />}
                                             label="Public"
                                          />
                                       </RadioGroup>
                                       <FormHelperText>
                                          Control whether the group is only visible to
                                          members of the group, or everyone across the
                                          location.
                                       </FormHelperText>
                                    </FormControl>
                                 </AccordionDetails>
                              </Accordion>
                           </Box>
                        </React.Fragment>
                     )}
                     <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                        <Button
                           variant="contained"
                           disabled={disabled}
                           color="primary"
                           onClick={() => {
                              handleSave();
                           }}
                        >
                           Save
                        </Button>
                        <Button
                           disabled={disabled}
                           onClick={() => {
                              handleClose();
                           }}
                        >
                           Close
                        </Button>
                     </Box>
                     {deleteWarning && (
                        <Typography variant="caption" color="error">
                           {deleteWarning}
                        </Typography>
                     )}
                  </Box>
               )}
            </React.Fragment>
         ) : (
            <FeatureSwitch feature="groups" />
         )}
      </React.Fragment>
   );
};

export default withFirebase(EditGroupModal);
