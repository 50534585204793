import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@mui/material/TableCell';

import CapacityGauge from '../../../Capacity';
import { DateToday } from '../../../../constants/functions';
import { CalculateCapacity } from '../../..//Capacity/functions';
import { withFirebase } from '../../../Firebase';

const useStyles = makeStyles((theme) => ({
   exceeded: {
      color: 'rgb(219, 61, 40)',
      borderBottom: '2px solid rgba(224, 224, 224, 1)',
      fontWeight: '900',
      position: 'sticky',
      top: 65,
   },
   capacityRowCell: {
      borderBottom: '2px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
      position: 'sticky',
      top: 65,
   },
}));

const CapCells = (props) => {
   const classes = useStyles();
   const today = DateToday();
   const initialCapacity = {
      0: { percent: 0, number: 0, pm: null, exceed: false },
      1: { percent: 0, number: 0, pm: null, exceed: false },
      2: { percent: 0, number: 0, pm: null, exceed: false },
      3: { percent: 0, number: 0, pm: null, exceed: false },
      4: { percent: 0, number: 0, pm: null, exceed: false },
      5: { percent: 0, number: 0, pm: null, exceed: false },
      6: { percent: 0, number: 0, pm: null, exceed: false },
   };
   const [capacity, setCapacity] = useState(false);
   const [routine, setRoutine] = useState(false);

   useEffect(() => {
      if (props.location.routine) {
         const loadRoutine = props.firebase
            .getRoutineStreak(props.location.id)
            .onSnapshot((result) => {
               if (result.exists) {
                  setRoutine(result.data());
               } else {
                  setRoutine(false);
               }
            });
         return () => loadRoutine();
      }
   }, [props.location]);

   useEffect(() => {
      if (props.location && props.weekDates && Object.keys(props.location).length !== 0) {
         let locationData = {};
         var capNumber = null;
         var capCapacity = 100;
         locationData = props.location;
         const capSettings = locationData.capacity;
         const capUnit = locationData.capacity.unit;
         if (capSettings[capUnit]) {
            if (capSettings[capUnit].number) {
               capNumber = capSettings[capUnit].number;
            } else {
               capNumber = props.location.people;
            }
            if (capSettings[capUnit].capacity) {
               capCapacity = capSettings[capUnit].capacity;
            }
         } else {
            capNumber = props.location.people;
         }
         const loadCapacityNumbers = props.firebase
            .getStatusLevelsStreaks(
               props.location.id,
               props.weekDates[0].ref,
               props.weekDates[props.weekDates.length - 1].ref,
            )
            .onSnapshot((result) => {
               let resultData = {};
               if (!result.empty) {
                  result.forEach((doc) => {
                     resultData[doc.id] = doc.data();
                  });
               } else {
                  resultData = initialCapacity;
               }

               let capData = [];
               props.weekDates.map((weekDate) => {
                  var day = weekDate.ref;
                  let number = 0;
                  let percent = 0;
                  let pm = null;
                  let split = false;
                  if (
                     !resultData[day] ||
                     (resultData[day] && !resultData[day].routineSaved)
                  ) {
                     if (
                        routine &&
                        routine[weekDate.weekDay] &&
                        routine[weekDate.weekDay].ito &&
                        today.ref < weekDate.ref
                     ) {
                        number = number + routine[weekDate.weekDay].ito;
                     }
                     if (
                        resultData[day] &&
                        resultData[day].overridden &&
                        resultData[day].overridden.ito
                     ) {
                        number = number - resultData[day].overridden.ito;
                     }
                  }
                  if (resultData[day]) {
                     if (resultData[day].ito !== undefined && resultData[day].ito !== 0) {
                        number = number + resultData[day].ito;
                     }
                     if (
                        (resultData[day]?.AM?.ito !== undefined &&
                           resultData[day]?.AM?.ito !== 0) ||
                        (resultData[day]?.PM?.ito !== undefined &&
                           resultData[day]?.PM?.ito !== 0)
                     ) {
                        split = true;
                        pm = number;
                        if (
                           resultData[day].AM.ito !== undefined &&
                           resultData[day].AM.ito !== 0
                        ) {
                           number = number + resultData[day].AM.ito;
                        }
                        if (
                           resultData[day].PM.ito !== undefined &&
                           resultData[day].PM.ito !== 0
                        ) {
                           pm = pm + resultData[day].PM.ito;
                        }
                     }
                  }
                  if (number !== 0 || split) {
                     if (props.location.people === 1 && number === 1) {
                        capData.push({
                           number,
                           percent: 100,
                           exceed: false,
                           weekDay: weekDate.weekDay,
                        });
                     } else {
                        var maxPercentage = (capCapacity / 100) * capNumber;
                        percent = (number / maxPercentage) * 100;
                        var pmPercent = null;
                        percent = +percent.toFixed(0);
                        if (pm !== null) {
                           pmPercent = (pm / maxPercentage) * 100;
                           pmPercent = +pmPercent.toFixed(0);
                        }
                        var exceeded = false;
                        if (percent > capCapacity) {
                           exceeded = true;
                        } else if (pmPercent !== null && pmPercent > capCapacity) {
                           exceeded = true;
                        }
                        capData.push({
                           number,
                           percent,
                           exceed: exceeded,
                           pm,
                           pmPercent,
                           split,
                           weekDay: weekDate.weekDay,
                        });
                     }
                  } else {
                     capData.push({
                        number,
                        percent: 0,
                        exceed: false,
                        weekDay: weekDate.weekDay,
                     });
                  }
               });
               setCapacity(capData);
               // }
            });
         return () => loadCapacityNumbers();
      }
   }, [props.location, props.weekDates, routine]);

   return (
      <React.Fragment>
         {capacity &&
            capacity.map((value, key) => {
               if (!props.hideWeekends) {
                  return (
                     // <TableCell key={key} className={value.exceed===true ? classes.exceeded : classes.capacityRowCell} align="center">{value.percent === null ? <Link underline="always" color="primary" href="/settings" >edit</Link> : value.percent}%</TableCell>
                     <TableCell
                        key={key}
                        sx={{
                           pl: 1,
                           pr: 1,
                           borderLeft:
                              value.weekDay === 1 && key !== 0
                                 ? `1px solid rgba(224, 224, 224, 1)`
                                 : '0px',
                        }}
                     >
                        {props.capToggle && (
                           <React.Fragment>
                              {value.split ? (
                                 <CapacityGauge
                                    capacity={value}
                                    splitDay
                                    mini={props.mini}
                                    micro={props.micro}
                                    capToggle
                                 />
                              ) : (
                                 <CapacityGauge
                                    capacity={value}
                                    mini={props.mini}
                                    micro={props.micro}
                                    capToggle
                                 />
                              )}
                           </React.Fragment>
                        )}
                        {!props.capToggle && (
                           <React.Fragment>
                              {value.split ? (
                                 <CapacityGauge
                                    capacity={value}
                                    splitDay
                                    micro={props.micro}
                                    mini={props.mini}
                                 />
                              ) : (
                                 <React.Fragment>
                                    <CapacityGauge
                                       capacity={value}
                                       micro={props.micro}
                                       mini={props.mini}
                                    />
                                 </React.Fragment>
                              )}
                           </React.Fragment>
                        )}
                     </TableCell>
                  );
               } else if (value.weekDay !== 6 && value.weekDay !== 0) {
                  return (
                     <TableCell
                        key={key}
                        sx={{
                           pl: 1,
                           pr: 1,
                           borderLeft:
                              value.weekDay === 1 && key !== 0
                                 ? `1px solid rgba(224, 224, 224, 1)`
                                 : '0px',
                        }}
                     >
                        {props.capToggle && (
                           <React.Fragment>
                              {value.split ? (
                                 <CapacityGauge
                                    capacity={value}
                                    splitDay
                                    mini={props.mini}
                                    micro={props.micro}
                                    capToggle
                                 />
                              ) : (
                                 <CapacityGauge
                                    capacity={value}
                                    mini={props.mini}
                                    micro={props.micro}
                                    capToggle
                                 />
                              )}
                           </React.Fragment>
                        )}
                        {!props.capToggle && (
                           <React.Fragment>
                              {value.split ? (
                                 <CapacityGauge
                                    capacity={value}
                                    splitDay
                                    micro={props.micro}
                                    mini={props.mini}
                                 />
                              ) : (
                                 <React.Fragment>
                                    <CapacityGauge
                                       capacity={value}
                                       micro={props.micro}
                                       mini={props.mini}
                                    />
                                 </React.Fragment>
                              )}
                           </React.Fragment>
                        )}
                     </TableCell>
                  );
               }
            })}
      </React.Fragment>
   );
};

export default withFirebase(CapCells);
