import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { loadImage } from './functions';

const ConfirmationImage = ({ path }) => {
   const [imageUrl, setImageUrl] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const image = () =>
         loadImage(path).then((url) => {
            setImageUrl(url);
            setLoading(false);
         });

      image();
   }, [path]);

   return (
      <Box>
         {loading ? (
            <CircularProgress />
         ) : (
            <img
               src={imageUrl}
               style={{
                  height: 'auto',
                  width: '100%',
                  maxHeight: '100%',
                  maxWidth: '100%',
               }}
            />
         )}
      </Box>
   );
};

export default ConfirmationImage;
