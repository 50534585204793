import { useState, useEffect } from 'react';
import {
   Typography,
   Box,
   Checkbox,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   IconButton,
   Tooltip,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ExternalIcon from '@mui/icons-material/CorporateFare';
import GroupMembersListHeader from './GroupMembersListHeader';
import { useDialogContext } from '../../components/Dialog';
import { enqueueSnackbar } from 'notistack';

const GroupMembersList = () => {
   const { groupDetails, getRenderedPeople, allPeopleData } = useDialogContext();
   const { setValue } = useFormContext();

   const renderedPeople = getRenderedPeople();
   const [editedAdminMembers, setEditedAdminMembers] = useState(groupDetails.admins);
   const [editedMembers, setEditedMembers] = useState(groupDetails.members);
   const [externalMembers, setExternalMember] = useState(() => {
      return Object.keys(editedMembers).filter((memberId) => {
         if (
            allPeopleData.raw[memberId] &&
            allPeopleData.raw[memberId].externalLocation
         ) {
            return allPeopleData.raw[memberId].externalLocation;
         }
      });
   });

   useEffect(() => {
      setValue('members', editedMembers);
      setValue('admins', editedAdminMembers);
      setValue('externalMembers', externalMembers);
   }, [editedMembers, editedAdminMembers, externalMembers]);

   async function handleToggleMember(id, name) {
      if (editedMembers[id]) {
         const newMembers = { ...editedMembers };
         const newAdminMembers = { ...editedAdminMembers };

         delete newMembers[id];
         delete newAdminMembers[id];

         setEditedMembers({ ...newMembers });
         setEditedAdminMembers({ ...newAdminMembers });
      } else {
         setEditedMembers({ ...editedMembers, [id]: { name } });
      }
   }

   async function handleToggleAdmin(id, name) {
      if (editedAdminMembers[id]) {
         // remove admin
         const newAdminMembers = { ...editedAdminMembers };

         delete newAdminMembers[id];
         setEditedAdminMembers({ ...newAdminMembers });
      } else {
         if (Object.keys(editedAdminMembers).length >= 5) {
            enqueueSnackbar(`Cannot add more than 5 admins to group`, {
               variant: 'info',
               autoHideDuration: 4000,
            });
            return;
         }
         // add admin
         const newMembers = { ...editedMembers, [id]: { name } };
         const newAdminMembers = { ...editedAdminMembers, [id]: { name } };

         setEditedMembers(newMembers);
         setEditedAdminMembers(newAdminMembers);
      }
   }

   async function handleClearAll() {
      setEditedMembers({});
      setEditedAdminMembers({});
      setExternalMember([]);
   }

   function toggleExternalMember(id) {
      if (externalMembers.includes(id)) {
         setExternalMember(externalMembers.filter((uid) => uid !== id));
         return;
      }
      setExternalMember([...externalMembers, id]);
   }

   if (renderedPeople.length === 0) {
      return (
         <Box
            mt={8}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
         >
            <Typography>There are no people to create a group from</Typography>
         </Box>
      );
   }

   return (
      <>
         <GroupMembersListHeader
            handleClearAll={handleClearAll}
            renderedMembers={renderedPeople}
            editedMembers={editedMembers}
            editedAdminMembers={editedAdminMembers}
         />
         <Box
            sx={{
               height: 300,
               overflowY: 'scroll',
               border: '1px solid #ddd',
               position: 'relative',
            }}
         >
            <List>
               {
                  <ListItem
                     divider
                     sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'background.paper',
                     }}
                  >
                     <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box display={'flex'} gap={2}>
                           <Typography variant="caption">Added</Typography>
                           <Typography variant="caption">Name</Typography>
                        </Box>
                     </Box>
                     <Box>
                        <Typography variant="caption">Group Admin</Typography>
                     </Box>
                  </ListItem>
               }

               {renderedPeople.map((person) => {
                  const { id, name } = person;
                  const labelId = `user-${id}`;

                  const isAdmin = Boolean(editedAdminMembers[id]);
                  const isChecked = Boolean(editedMembers[id]);
                  const isExternal = person.externalLocation;

                  return (
                     <ListItem
                        divider
                        id={labelId}
                        disablePadding
                        disableGutters
                        sx={{ p: 0 }}
                        key={id}
                        secondaryAction={
                           <>
                              <Tooltip
                                 title={
                                    isAdmin ? 'Remove group admin' : 'Add group admin'
                                 }
                                 placement="left"
                              >
                                 <IconButton
                                    size="small"
                                    sx={{
                                       mr: 2,
                                       ml: 1,
                                       border: '1px solid rgba(0,0,0,0.2)',
                                       boxSizing: 'border-box',
                                    }}
                                    onClick={() => {
                                       handleToggleAdmin(id, name);
                                       if (isExternal) {
                                          toggleExternalMember(id);
                                       }
                                    }}
                                 >
                                    {isAdmin ? (
                                       <AdminPanelSettingsIcon color="primary" />
                                    ) : (
                                       <AddModeratorIcon sx={{ color: '#ccc' }} />
                                    )}
                                 </IconButton>
                              </Tooltip>
                           </>
                        }
                     >
                        <ListItemButton
                           dense
                           selected={isAdmin}
                           id={id}
                           onClick={() => {
                              handleToggleMember(id, name);
                              if (isExternal) {
                                 toggleExternalMember(id);
                              }
                           }}
                        >
                           <ListItemIcon>
                              <Checkbox
                                 edge="start"
                                 checked={isChecked}
                                 disableRipple
                                 inputProps={{ 'aria-labelledby': labelId }}
                              />
                           </ListItemIcon>

                           <ListItemText
                              id={labelId}
                              primary={
                                 <>
                                    <Box display="flex" gap={1} alignItems={'center'}>
                                       {name.firstName} {name.lastName}
                                       {isExternal && (
                                          <Tooltip
                                             title="External location"
                                             placement="right"
                                          >
                                             <ExternalIcon />
                                          </Tooltip>
                                       )}
                                    </Box>
                                 </>
                              }
                           />
                        </ListItemButton>
                     </ListItem>
                  );
               })}
            </List>
         </Box>
      </>
   );
};

export default GroupMembersList;
