import React from 'react';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import {
   CircularProgressbarWithChildren,
   CircularProgressbar,
   buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import SplitDayCap from './splitDayCap';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles((theme) => ({
   normal: {
      maxWidth: 150,
      margin: 'auto',
   },
   mini: {
      maxWidth: 55,
      minWidth: 55,
      margin: 'auto',
   },
   multi: {
      maxWidth: 64,
      margin: 'auto',
   },
   micro: {
      maxWidth: 30,
      minWidth: 30,
      margin: 'auto',
   },
}));

const CapacityGuage = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   function setStyle(value) {
      if (props.micro) {
         if (value >= 100) {
            const styles = {
               color: '#DB3D28',
               fontWeight: 'bold',
            };
            return styles;
         } else if (value > 90 && value < 100) {
            const styles = {
               color: '#F7B843',
            };
            return styles;
         } else {
            const styles = {
               color: theme.palette.text.primary,
            };
            return styles;
         }
      } else if (props.mini) {
         if (value > 100) {
            return buildStyles({
               pathTransition: 'none',
               pathColor: '#DB3D28',
               textColor: '#fff',
               fontWeight: 'bold',
               textSize: 'x-large',
               // strokeLinecap: 'butt',
               trailColor: '#fff',
            });
         } else if (value > 90 && value < 100) {
            return buildStyles({
               backgroundColor: '#F7E943',
               pathTransition: 'none',
               pathColor: '#F7E943',
               textSize: 'xx-large',
               textColor: '#646464',
               // strokeLinecap: 'butt',
               trailColor: '#fff',
            });
         } else {
            return buildStyles({
               backgroundColor: 'transparent',
               pathTransition: 'none',
               pathColor: '#9ECF99',
               textColor: '#646464',
               textSize: 'x-large',
               // strokeLinecap: 'butt',
               trailColor: '#fff',
            });
         }
      } else {
         if (value > 100) {
            return buildStyles({
               pathTransition: 'none',
               pathColor: '#DB3D28',
               textColor: '#DB3D28',
            });
         } else if (value > 90 && value < 100) {
            return buildStyles({
               pathTransition: 'none',
               pathColor: '#F7E943',
               trailColor: '#e6e6e6',
            });
         } else {
            return buildStyles({
               pathTransition: 'none',
               pathColor: '#9ECF99',
               trailColor: '#e6e6e6',
            });
         }
      }
   }
   if (props.multi) {
      return (
         <div
            className={clsx({
               [classes.mini]: props.mini,
               [classes.normal]: !props.mini && !props.micro,
               [classes.multi]: props.multi,
               [classes.micro]: props.micro,
            })}
         >
            <AnimatedProgressProvider
               valueStart={0}
               valueEnd={props.capacity.ito}
               duration={1.4}
               easingFunction={easeQuadInOut}
            >
               {(value) => {
                  // const roundedValue = Math.round(value);
                  return (
                     <CircularProgressbarWithChildren
                        value={value}
                        strokeWidth={12}
                        background
                        styles={buildStyles({
                           pathColor: theme.palette.ito.main,
                           trailColor: 'transparent',
                           backgroundColor: theme.palette.capacityBackground.main,
                        })}
                     >
                        {/*
          Width here needs to be (100 - 2 * strokeWidth)% 
          in order to fit exactly inside the outer progressbar.
        */}
                        <div style={{ width: '78%' }}>
                           <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={props.capacity.wfh}
                              duration={1.4}
                              easingFunction={easeQuadInOut}
                           >
                              {(value) => {
                                 // const roundedValue = Math.round(value);

                                 return (
                                    <CircularProgressbarWithChildren
                                       value={value}
                                       strokeWidth={12}
                                       styles={buildStyles({
                                          trailColor: 'transparent',
                                          pathColor: theme.palette.wfh.main,
                                       })}
                                    >
                                       <Typography
                                          style={{ color: theme.palette.ito.main }}
                                          variant="caption"
                                       >
                                          {props.capacity.ito}%
                                       </Typography>
                                       <Typography
                                          style={{
                                             color: theme.palette.wfh.main,
                                             fontWeight: 'bold',
                                          }}
                                          variant="caption"
                                       >
                                          {props.capacity.wfh}%
                                       </Typography>
                                    </CircularProgressbarWithChildren>
                                 );
                              }}
                           </AnimatedProgressProvider>
                        </div>
                     </CircularProgressbarWithChildren>
                  );
               }}
            </AnimatedProgressProvider>
         </div>
      );
   }
   if (props.splitDay) {
      return (
         <React.Fragment>
            {props.capToggle ? (
               <SplitDayCap
                  capacity={props.capacity}
                  mini={props.mini || false}
                  micro={props.micro || false}
                  capToggle
               />
            ) : (
               <SplitDayCap
                  capacity={props.capacity}
                  mini={props.mini || false}
                  micro={props.micro || false}
               />
            )}
         </React.Fragment>
      );
   } else if (props.micro) {
      return (
         <>
            <Typography variant="caption" sx={setStyle(props.capacity.percent)}>
               {props.capToggle ? (
                  <React.Fragment>{props.capacity.number}</React.Fragment>
               ) : (
                  `${Math.round(props.capacity.percent)}%`
               )}
            </Typography>
         </>
      );
   } else {
      return (
         <div
            className={clsx({
               [classes.mini]: props.mini,
               [classes.normal]: !props.mini,
            })}
         >
            <AnimatedProgressProvider
               valueStart={0}
               valueEnd={props.capacity.percent}
               duration={1.4}
               easingFunction={easeQuadInOut}
            >
               {(value) => {
                  return (
                     <CircularProgressbarWithChildren
                        value={value}
                        strokeWidth={props.mini ? 22 : props.micro ? 50 : 11}
                        /* This is important to include, because if you're fully managing the
                        animation yourself, you'll want to disable the CSS animation. */
                        styles={setStyle(value)}
                     >
                        <Typography
                           variant="caption"
                           style={
                              props.mini
                                 ? { lineHeight: 1 }
                                 : {
                                      fontSize: 'large',
                                      color: '#3e98c7',
                                   }
                           }
                        >
                           {props.capToggle ? (
                              <React.Fragment>{props.capacity.number}</React.Fragment>
                           ) : (
                              `${Math.round(value)}%`
                           )}
                        </Typography>
                     </CircularProgressbarWithChildren>
                  );
               }}
            </AnimatedProgressProvider>
         </div>
      );
   }
};
export default CapacityGuage;
