import React from 'react';
import { ThemeProvider, useMediaQuery, CssBaseline } from '@material-ui/core';
import legacyThemeLight from '../../themes/legacyThemeLight';
import legacyThemeDark from '../../themes/legacyThemeDark';

const LegacyThemeProvider = ({ children }) => {
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

   const legacyTheme = React.useMemo(
      () => (prefersDarkMode ? legacyThemeDark : legacyThemeLight),
      [prefersDarkMode],
   );

   return (
      <ThemeProvider theme={legacyTheme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};

export default LegacyThemeProvider;
