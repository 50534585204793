import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { withFirebase } from '../../Firebase';
import PropTypes from 'prop-types';
import DayUserBookings from '../../Spaces/dayUserBookings';
import FavouriteSpaceCards from '../../Spaces/favouriteCards';
import AvailableSpaceCards from '../../Spaces/availableCards';
import SpacesDay from '../Views/dayView/spacesDay';
import DayBookings from '../Views/dayView/dayBookings';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import SchedulerIcon from '../../../assets/icons/Scheduler';

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const ITOBlocks = (props) => {
   const [user, setUser] = useState(null);
   const [location, setLocation] = useState(false);
   const [value, setValue] = useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   useEffect(() => {
      if (props.user) {
         const unsubscribe = props.firebase
            .companyPerson(props.user.cid, props.user.uid)
            .onSnapshot(
               (result) => {
                  let person = {};
                  if (result.exists) {
                     person = result.data();
                     person.lid = result.data().lid[0];
                     person.uid = props.user.uid;
                     if (!result.data().favouriteSpaces) {
                        person.favouriteSpaces = [];
                     }
                     setUser(person);
                  }
               },
               (error) => {
                  console.warn(error, 'error - loading companyPerson in ITO Block');
               },
            );
         return () => unsubscribe();
      }
   }, [props.user]);
   useEffect(() => {
      if (props.displayFilter) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         setLocation({ id: lid, lid });
      }
   }, [props.displayFilter]);
   return (
      <React.Fragment>
         {props.date && (
            <React.Fragment>
               <Box sx={{ maxWidth: '100%', width: '100%' }}>
                  <DayUserBookings
                     date={props.date}
                     user={props.user}
                     dayTime={props.dayTime}
                     location={location}
                  />

                  <Divider sx={{ mt: 2 }} />
                  <Box
                     sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                     }}
                  >
                     <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        Spaces
                     </Typography>
                     <Tabs value={value} onChange={handleChange} aria-label="Space Views">
                        <Tab
                           sx={{ minHeight: 50, p: 0, textTransform: 'none' }}
                           icon={<ViewCarouselOutlinedIcon fontSize="small" />}
                           iconposition="start"
                           label={<Typography variant="caption">Cards</Typography>}
                           {...a11yProps(0)}
                        />
                        <Tab
                           sx={{ minHeight: 50, p: 0, textTransform: 'none' }}
                           icon={
                              <SchemaOutlinedIcon
                                 fontSize="small"
                                 sx={{ transform: 'rotate(-90deg)' }}
                              />
                           }
                           iconposition="start"
                           label={<Typography variant="caption">Plan</Typography>}
                           {...a11yProps(1)}
                        />
                        <Tab
                           sx={{ minHeight: 50, p: 0, textTransform: 'none' }}
                           icon={<SchedulerIcon color="inherit" fontSize="small" />}
                           iconposition="start"
                           label={<Typography variant="caption">Scheduler</Typography>}
                           {...a11yProps(2)}
                        />
                     </Tabs>
                  </Box>
                  <Box>
                     <TabPanel value={value} index={0}>
                        <React.Fragment>
                           <FavouriteSpaceCards
                              date={props.date}
                              displayFilter={props.displayFilter}
                              user={user}
                              status={props.status}
                              dayTime={props.dayTime}
                           />
                           <AvailableSpaceCards
                              date={props.date}
                              location={location}
                              user={user}
                              status={props.status}
                              dayTime={props.dayTime}
                           />
                        </React.Fragment>
                     </TabPanel>
                     <TabPanel value={value} index={1}>
                        <DayBookings
                           displayFilter={props.displayFilter}
                           day={props.date}
                           tab={value}
                           compressed={true}
                           user={user}
                        />
                     </TabPanel>
                     <TabPanel value={value} index={2}>
                        <SpacesDay
                           displayFilter={props.displayFilter}
                           day={props.date}
                           location={props.location}
                           tab={value}
                           kiosk={false}
                           user={user}
                        />
                     </TabPanel>
                  </Box>
               </Box>
            </React.Fragment>
         )}
      </React.Fragment>
   );
};

export default withFirebase(ITOBlocks);
