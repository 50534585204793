import { exportToCsv } from './exportToCsv';
import { processScheduleStatuses } from './processScheduleStatuses';
import {
   coRef,
   coUsersIndexRef,
   locationPeopleIndexRef,
   locScheduleRef,
} from '../firebaseRefs';

export async function scheduleStatus(query) {
   const filename = `ITO-export-${query.type}-${query.date}`;
   try {
      const company = await coRef(query.cid).get();
      const users = await coUsersIndexRef(query.cid).get();
      const locUsers = await locationPeopleIndexRef(query.lid).get();
      const res = await locScheduleRef(query.lid, query.date).get();
      if (res.exists) {
         const data = processScheduleStatuses(
            res.data(),
            users.data(),
            locUsers.data(),
            query.lid,
         );
         exportToCsv(filename, data);
         return { code: 200, status: 'success', data, error: null };
      } else {
         return { code: 204, status: 'empty', data: null, error: null };
      }
   } catch (error) {
      console.warn('Error -', error);
      return { code: 400, status: 'error', error };
   }
}
