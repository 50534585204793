import React, { useEffect, useState } from 'react';
import {
   Box,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
   Checkbox,
} from '@mui/material';
import { withFirebase } from '../../Firebase';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useCompany } from '../../../providers';
import GlobalGroupSwitch from './globalGroupSwitch';

const Members = (props) => {
   const { company } = useCompany();
   const [locPeople, setLocPeople] = useState(false);
   const [group, setGroup] = useState(false);
   const [groupMembers, setGroupMembers] = useState(false);

   useEffect(() => {
      if (props.lid) {
         const unsubscribe = props.firebase
            .locationPeopleIndex(props.lid)
            .onSnapshot((result) => {
               let people = [];
               if (result.exists) {
                  Object.entries(result.data()).map(([key, value]) => {
                     people.push({
                        uid: key,
                        name: value.name,
                        displayName: `${value.name.firstName} ${value.name.lastName}`,
                     });
                  });
               }
               people.sort((a, b) =>
                  a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1,
               );
               setLocPeople(people);
            });
         return () => unsubscribe();
      }
   }, [props.lid]);
   useEffect(() => {
      if (locPeople) {
         const unsubscribe = props.firebase
            .locationGroupDetails(props.lid, props.gid)
            .onSnapshot((result) => {
               let members = [];
               if (result.exists) {
                  setGroup({ gid: result.id, ...result.data() });
                  props.setGroupCount(result.data().count);
                  if (result.data().members) {
                     Object.keys(result.data().members).map((uid) => {
                        members.push(uid);
                     });
                  }
               }
               setGroupMembers(members);
            });
         return () => {
            unsubscribe();
            setGroupMembers(false);
         };
      }
   }, [locPeople, props.gid, props.lid]);

   async function toggleMember(person) {
      if (groupMembers.includes(person.uid)) {
         if (props.editedMembers.removed[person.uid] === undefined) {
            props.setEditedMembers({
               ...props.editedMembers,
               removed: {
                  ...props.editedMembers.removed,
                  [person.uid]: { name: person.name },
               },
            });
         } else {
            let removed = props.editedMembers.removed;
            delete removed[person.uid];
            props.setEditedMembers({ ...props.editedMembers, removed });
         }
      } else {
         let newMems = props.editedMembers.new;
         if (props.editedMembers.new[person.uid] === undefined) {
            props.setEditedMembers({
               ...props.editedMembers,
               new: { ...props.editedMembers.new, [person.uid]: { name: person.name } },
            });
         } else {
            delete newMems[person.uid];
            props.setEditedMembers({ ...props.editedMembers, new: newMems });
         }
      }
   }

   return (
      <Box>
         {company?.features.multisite && <GlobalGroupSwitch group={group} />}
         <List dense={true}>
            {locPeople && locPeople.length > 0 && groupMembers ? (
               locPeople.map((person) => (
                  <ListItem
                     key={person.uid}
                     // secondaryAction = {
                     //   <IconButton edge="end" aria-label="Toggle Group Admin" size="small">
                     //     <ManageAccountsIcon fontSize="small"/>
                     //   </IconButton>
                     // }
                     disablePadding
                  >
                     {/* <ListItemButton role={undefined}  dense> */}
                     <ListItemIcon>
                        <Checkbox
                           edge="start"
                           onChange={() => toggleMember(person)}
                           checked={
                              (groupMembers.includes(person.uid) ||
                                 props.editedMembers.new[person.uid] !== undefined) &&
                              props.editedMembers.removed[person.uid] === undefined
                           }
                           tabIndex={-1}
                           disableRipple
                           inputProps={{ 'aria-labelledby': person.displayName }}
                           size="small"
                           sx={{ p: 0 }}
                        />
                     </ListItemIcon>
                     <ListItemText id={person.uid} primary={person.displayName} />
                     {/* </ListItemButton> */}
                  </ListItem>
               ))
            ) : (
               <ListItem>
                  <ListItemText>No one in this location</ListItemText>
               </ListItem>
            )}
         </List>
      </Box>
   );
};

export default withFirebase(Members);
