import * as yup from 'yup';

export const defaultOption = {
   id: 'all',
   gid: 'all',
   name: 'All',
   type: 'group',
   default: true,
};

export class GroupTemplate {
   constructor(locationId) {
      if (!locationId) {
         throw new Error(
            "the GroupTemplate class needs 'locationId' passed into it as an arg",
         );
      }

      return {
         name: '',
         companyPublic: false,
         locationPublic: false,
         global: false,
         hidden: false,
         lid: locationId,
         type: 'group',
         admins: {},
         members: {},
         externalMembers: 0,
         count: 0,
      };
   }
}

export const addGroupFormSchema = yup.object().shape({
   name: yup.string().min(3).required(),
   companyPublic: yup.boolean().required(),
   locationPublic: yup.boolean().required(),
   global: yup.boolean().required(),
   hidden: yup.boolean().required(),
   lid: yup.string().optional(),
   type: yup.string(),
   admins: yup.object(),
   members: yup.object().required(),
   count: yup.number().required(),
});

export const dbGroupObject = (group) => {
   const newGroupObject = group;
   delete newGroupObject.members;
   delete newGroupObject.admins;
   delete newGroupObject.gid;

   return newGroupObject;
};

export const dbGroupIndexesObject = (group) => {
   const newGroupIndexObject = group;
   delete newGroupIndexObject.members;
   delete newGroupIndexObject.admins;
   delete newGroupIndexObject.gid;

   return newGroupIndexObject;
};
