import React, { useEffect, useState } from 'react';
import {
   Box,
   List,
   ListItem,
   ListItemText,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material';
import { withFirebase } from '../../../Firebase';

const CompanyLogs = (props) => {
   const [logs, setLogs] = useState(false);
   useEffect(() => {
      if (props.company) {
         const fetchLogs = async () => {
            const logData = await props.firebase.db
               .doc(`company/${props.company.cid}/logs/features`)
               .get();
            if (logData.exists) {
               setLogs(logData.data());
            }
         };

         fetchLogs();
      }
   }, [props.company]);

   console.log(props);
   return (
      <Box>
         {logs ? (
            <Box>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Feature</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Action</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Object.entries(logs).map(([key, value]) => (
                        <TableRow key={key}>
                           <TableCell>{value.date.toDate().toLocaleString()}</TableCell>
                           <TableCell>{value.feature}</TableCell>
                           <TableCell>{value.email}</TableCell>
                           <TableCell>
                              {value.enabled ? (
                                 <Typography>ON</Typography>
                              ) : (
                                 <Typography>OFF</Typography>
                              )}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Box>
         ) : (
            <Box>
               <Typography>No logs</Typography>
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(CompanyLogs);
