import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const ItoMiniPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="ito-mini-pm" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M26,0 L26,24.862325 C26,27.1474998 24.209139,29 22,29 L4,29 C1.790861,29 0,27.1474998 0,24.862325 L0,17.1041844 L26,0 Z M21.7272727,11.4 L12.0909091,21.6835821 L8.27272727,17.6089552 L7,18.9671642 L12.0909091,24.4 L23,12.758209 L21.7272727,11.4 Z"
                  id="Path-3"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoMiniPm;
