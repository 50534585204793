import React from 'react';
import Typography from '@material-ui/core/Typography';

const Personal = (props) => {
   return (
      <>
         <Typography variant="h5" component="h1" paragraph>
            {props.user.name.firstName} {props.user.name.lastName}
         </Typography>
         <Typography variant="body1" paragraph>
            {props.user.email}
         </Typography>
      </>
   );
};

export default Personal;
