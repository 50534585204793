import React, { useState } from 'react';
import { CardSection } from '../../layout';
import { Button, Box, Typography, Link } from '@mui/material';
import CoCardLocations from './coCardLocations';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const coCard = ({ company }) => {
   const [signedUpDetails, setSignedUpDetails] = useState({
      adminName: '',
      adminEmail: '',
   });
   const history = useHistory();
   function goToUsage() {
      const params = new URLSearchParams({ cid: company.cid });
      history.push(`/globalusage?${params.toString()}`);
   }
   function goToSite(domain) {
      window.open(`https://${domain}`, '_blank');
   }
   return (
      <CardSection sx={{ m: 1 }} key={company.cid}>
         <Typography variant="h5" component="h4">
            {company.companyName}
         </Typography>
         <Box>
            <Typography>{signedUpDetails.adminName}</Typography>
            <Typography
               sx={{ textDecoration: 'underline', cursor: 'pointer' }}
               onClick={() => navigator.clipboard.writeText(signedUpDetails.email)}
            >
               {signedUpDetails.adminEmail}
            </Typography>
            <Typography variant="caption">click to copy</Typography>
         </Box>
         <Typography
            sx={{ cursor: 'pointer' }}
            component={Link}
            onClick={() => goToSite(company.domains.used[0])}
         >
            {company.domains.used[0]}
         </Typography>
         <Typography>{company.profile.creationDate.toDate().toDateString()}</Typography>
         <Typography>people: {company.totalPeople}</Typography>

         {Object.keys(company.locations).map((lid) => (
            <CoCardLocations
               key={lid}
               lid={lid}
               cid={company.cid}
               setSignedUpDetails={setSignedUpDetails}
            />
         ))}
         <Button onClick={goToUsage}>Go to usage</Button>
      </CardSection>
   );
};

export default coCard;
