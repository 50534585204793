import React, { useState, useEffect, useReducer } from 'react';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import reportingImage from '../../assets/reporting.svg';
import requestImage from '../../assets/requestAttendance.svg';
import meetingsImage from '../../assets/meetings.svg';
import groupsImage from '../../assets/groups.svg';
import routineImage from '../../assets/routine.svg';
import visitorsImage from '../../assets/visitorsSquare.svg';
import * as ROLES from '../../constants/roles';
import { CircularProgress, Icon } from '@material-ui/core';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
   },
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
   },
   paperWide: {
      marginTop: 0,
      padding: theme.spacing(2),
   },
   button: {
      margin: theme.spacing(2),
   },
}));

export const features = {
   reporting: {
      title: 'Reporting',
      icon: reportingImage,
      iconAlt: 'Reporting icon',
      intro: 'Reporting lets you see how your employees and teams are using intheOffice.',
      button: 'Enable Reporting',
      terms: `Reporting is a premium feature. If you are not already on our premium subscription, enabling reporting will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
   requests: {
      title: 'Request Attendance',
      icon: requestImage,
      iconAlt: 'Request attendance icon',
      intro: 'You can find a day when your colleagues are available and there is enough office capacity for you all to work in the office together.',
      button: 'Enable Requests',
      terms: `Requesting attendance is part of our premium Advanced Scheduling feature. Advanced Scheduling also includes the Offsite status and Routines features.If you are not already on our premium subscription, enabling the Advanced Scheduling feature will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
   meetings: {
      title: 'Create a meeting',
      icon: meetingsImage,
      iconAlt: 'Request attendance icon',
      intro: `Reserve a Space for a specific time period.`,
      // intro: `Invite people within and outside your organisation to work with you. Adding external people will add them to your visitors list. While you create the meeting, you can book any resources that are required such as meeting rooms or parking spaces for your guests.`,
      // button: 'Enable Meetings',
      terms: `Creating a meeting requires Space Booking to be enabled. If you are not already on our premium subscription, enabling the Space Booking feature will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
   groups: {
      title: 'Create Groups and Teams',
      icon: groupsImage,
      iconAlt: 'Groups icon',
      intro: `Divide your people lists into groups or teams to help them see when their colleagues are planning to work in the office.`,
      button: 'Enable Groups',
      terms: `Groups is a premium feature. If you are not already on our premium subscription, enabling the groups feature will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
   routine: {
      title: 'My routine',
      icon: routineImage,
      iconAlt: 'My routine icon',
      intro: `Create a recurring starting point for where you are planning to work each week. Routines can be overriden by changing your status on your Schedule`,
      button: 'Enable Advanced Scheduling',
      terms: `Routines is part of our premium Advanced Scheduling feature. Advanced Scheduling also includes the Offsite status and Request Attendance features. If you are not already on our premium subscription, enabling the Advanced Scheduling feature will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
   visitors: {
      title: 'Visitors',
      icon: visitorsImage,
      iconAlt: 'Visitors Icon',
      intro: `Manage your visitors and know exactly who is in your office and when. Visitors can check-in using our kiosk screen or touchless check-in to automatically notify the visitor's host that they have arrived.`,
      button: 'Enable Visitors',
      terms: `Visitors is a premium feature. If you are not already on our premium subscription, enabling the visitors feature will start your 7 day premium free trial. After your free trial period, your premium feature usage will be calculated daily, based on the cost of the features you have enabled x by the total number of users.`,
      released: true,
   },
};

const FeatureSwitch = (props) => {
   const classes = useStyles();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [throbber, setThrobber] = useState(false);
   const [company, setCompany] = useState(false);
   const [disabled, setDisabled] = useState(true);
   const [saved, setSaved] = useState(false);
   useEffect(() => {
      const loadLocations = async () => {
         const co = await props.firebase.company(authUser.cid).get();
         setCompany({ cid: co.id, ...co.data() });
         setDisabled(false);
      };
      loadLocations();
   }, []);
   async function handleSubmit(feature) {
      setThrobber(true);
      setTimeout(async () => {
         try {
            let visitorInternalDetails = {};
            Object.keys(company.locations).map((lid) => {
               visitorInternalDetails[lid] = {};
            });
            if (feature === 'visitors') {
               let results = await props.firebase.companyPeople(authUser.cid).get();
               if (!results.empty) {
                  results.forEach((result) => {
                     visitorInternalDetails[result.data().lid[0]][
                        result.data().email.toLowerCase()
                     ] = `${result.data().name.firstName} ${result.data().name.lastName}`;
                  });
               }
            }
            await props.firebase.featureSwitch(company, feature, visitorInternalDetails);
            setSaved(true);
         } catch (error) {
            console.log(error, 'error');
         }
      }, 3000);
   }

   const Card = () => {
      return (
         <Paper className={clsx(classes.paper, { [classes.paperWide]: props.wide })}>
            {!throbber ? (
               <React.Fragment>
                  <img
                     src={features[props.feature].icon}
                     alt="reporting icon"
                     height={75}
                     width={75}
                  />
                  <Typography component="h1" variant="h5" paragraph align="center">
                     {features[props.feature].title}
                  </Typography>
                  <Typography variant="body1">{features[props.feature].intro}</Typography>
                  {features[props.feature].released &&
                  features[props.feature].button &&
                  !!authUser.roles[ROLES.ADMIN] ? (
                     <React.Fragment>
                        <Button
                           className={classes.button}
                           disabled={disabled}
                           variant="contained"
                           color="primary"
                           onClick={() => handleSubmit(props.feature)}
                        >
                           {features[props.feature].button}
                        </Button>
                        <Typography variant="caption">
                           {features[props.feature].terms}
                        </Typography>
                        <Link to={ROUTES.SETTINGS}>
                           See more information and potential costs in Settings
                        </Link>
                     </React.Fragment>
                  ) : !features[props.feature].released ? (
                     <Typography variant="body1" style={{ marginTop: 16 }}>
                        Coming soon.
                     </Typography>
                  ) : (
                     <Typography variant="body1" style={{ marginTop: 16 }}>
                        Contact your administrator to enable this feature.
                     </Typography>
                  )}
               </React.Fragment>
            ) : (
               <React.Fragment>
                  {saved ? (
                     <React.Fragment>
                        <Typography component="h1" variant="h5" paragraph align="center">
                           Done, please refresh this page.
                        </Typography>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <Typography component="h1" variant="h5" paragraph align="center">
                           Just setting this up for you ...
                        </Typography>
                        <CircularProgress />
                     </React.Fragment>
                  )}
               </React.Fragment>
            )}
         </Paper>
      );
   };

   if (props.wide) {
      return <Card />;
   } else {
      return (
         <Container component="main" maxWidth="xs">
            <Card />
         </Container>
      );
   }
};

export default withFirebase(FeatureSwitch);
