import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import {
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
   useMsal,
} from '@azure/msal-react';
import GroupList from './groupList';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles((theme) => ({
   leftBar: {
      paddingTop: theme.spacing(1),
   },
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
   },
}));

const LeftPanel = (props) => {
   const classes = useStyles();
   const [anchorEl, setAnchorEl] = useState(null);

   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   async function syncUsers() {
      alert('sync');
   }

   return (
      <>
         <Hidden xsDown>
            <Grid
               className={classes.leftBar}
               style={{ paddingLeft: '16px' }}
               container
               spacing={1}
               direction="row"
               justifycontent="flex-start"
            >
               <Grid item sm={8}>
                  <Button
                     onClick={() => {
                        props.setPeopleDrawer(true);
                        props.setDrawerData({ type: 'blank' });
                     }}
                     className={classes.button}
                     size="large"
                     fullWidth
                     variant="contained"
                     startIcon={<AddIcon />}
                  >
                     Add
                  </Button>
               </Grid>
               <Grid item sm={4}>
                  <div>
                     <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                     >
                        <MoreVertIcon />
                     </IconButton>
                     <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                     >
                        <AuthenticatedTemplate>
                           <MenuItem
                              key="userSync"
                              onClick={() => {
                                 props.setSyncMicrosoftDrawer(true);
                                 props.setImportType('users');
                                 handleClose();
                              }}
                           >
                              <ListItemIcon>
                                 <PersonOutlineIcon fontSize="small" />
                              </ListItemIcon>
                              Import Office 365 Users
                           </MenuItem>
                        </AuthenticatedTemplate>
                        <MenuItem
                           key="import"
                           onClick={() => {
                              props.setBulkDrawer(true);
                              handleClose();
                           }}
                        >
                           Import People from CSV
                        </MenuItem>
                        <MenuItem
                           key="bulk"
                           onClick={() => {
                              props.setBulkDeleteDrawer(true);
                              handleClose();
                           }}
                        >
                           Bulk Actions
                        </MenuItem>
                     </Menu>
                  </div>
               </Grid>
            </Grid>
            {/* <List>
               {props.displayFilter.id && (
                  <GroupList
                     companyPlan={props.companyPlan}
                     groups={props.groups}
                     groupFilter={props.groupFilter}
                     setGroupFilter={props.setGroupFilter}
                     setAlertMessage={props.setAlertMessage}
                     setChecked={props.setChecked}
                     displayFilter={props.displayFilter}
                  />
               )}
            </List> */}
         </Hidden>
         <Hidden smUp>
            <Fab
               onClick={() => {
                  props.setPeopleDrawer(true);
                  props.setDrawerData({ type: 'blank' });
               }}
               className={classes.fab}
               color="primary"
               aria-label="add"
            >
               <AddIcon />
            </Fab>
         </Hidden>
      </>
   );
};

export default withFirebase(LeftPanel);
