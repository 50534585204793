import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { parse } from 'query-string';
import { useFirebase } from '../Firebase';
import Builder from './builder';
import { PageLayout } from '../layout';
import Loading from '../Loading';
import { Box, Button, Typography } from '@mui/material';
import { fetchData } from './functions/fetchData';
import { useCompany } from '../../providers';
import * as ROUTES from '../../constants/routes';

const Main = () => {
   const firebase = useFirebase();
   const location = useLocation();
   const { company } = useCompany();
   const query = useMemo(() => parse(location.search), [location.search]);
   const [state, setState] = useState('loading');

   useEffect(() => {
      if (!query.type || !query.cid) {
         setState('builder');
      } else {
         const fetchDataAsync = async () => {
            try {
               const res = await fetchData(query);
               setState(res.status);
            } catch (error) {
               setState(error.status);
            }
         };
         fetchDataAsync();
      }
   }, [query]);

   const BackButton = () => {
      return (
         <Box display="flex" justifyContent="center" alignItems="center">
            <Button href={ROUTES.API} variant="contained">
               Back to builder
            </Button>
         </Box>
      );
   };

   switch (state) {
      case 'loading':
         return (
            <PageLayout>
               <Box display="flex" justifyContent="center" alignItems="center">
                  <Loading />
               </Box>
            </PageLayout>
         );
      case 'error':
         return (
            <PageLayout>
               <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
               >
                  <Typography variant="h4">Error</Typography>
                  <Typography variant="body1">
                     There is something wrong with your query. Please go back to the
                     builder and try again.
                  </Typography>
               </Box>
               <BackButton />
            </PageLayout>
         );
      case 'success':
         return (
            <PageLayout>
               <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h4">Success</Typography>
               </Box>
               <BackButton />
            </PageLayout>
         );
      case 'empty':
         return (
            <PageLayout>
               <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h4">No data found</Typography>
               </Box>
               <BackButton />
            </PageLayout>
         );
      case 'builder':
         return <Builder />;
      default:
         return null;
   }
};

export default Main;
