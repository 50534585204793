import { useEffect, useState } from 'react';

const useFirebaseSnapshot = (
   func = undefined,
   options = {
      args: [],
      dependencies: [],
      query: undefined,
      sortFunction: undefined,
   },
) => {
   const { args, dependencies, query, sortFunction } = options;
   const [data, setData] = useState(undefined);
   const [error, setError] = useState(undefined);

   if (typeof sortFunction !== 'undefined' && typeof sortFunction !== 'function') {
      throw new Error(`sortFunction is ${typeof sortFunction}, expected a function`);
   }

   function dataHandler(response) {
      if (sortFunction) {
         const sortedResponse = sortFunction(response);
         setData(sortedResponse);
      } else {
         setData(response);
      }
   }

   function functionRequest() {
      return func(...args).onSnapshot((result) => {
         if (result.exists) {
            return dataHandler(result.data());
         } else {
            return dataHandler({});
         }
      });
   }

   function queryRequest() {
      return query.onSnapshot((result) => {
         if (!result.empty) {
            return dataHandler(result);
         }
         return dataHandler({});
      });
   }

   useEffect(() => {
      const unsubscribe = func ? functionRequest() : queryRequest();
      return () => unsubscribe();
   }, dependencies || []);

   return { loading: data === undefined, data, error };
};

export default useFirebaseSnapshot;
