import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material';

const BlankPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 34 35">
         <g id="ITO-Design-System" fill="none">
            <g id="status/DASH" transform="translate(1.1961, 1.1961)">
               <line
                  x1="22.5"
                  y1="-0.5"
                  x2="-0.5"
                  y2="32.5"
                  id="Line-6"
                  stroke={theme.palette.primary.main}
                  strokeLinecap="square"
                  strokeWidth="1"
               ></line>
               <line
                  x1="31"
                  y1="15"
                  x2="23"
                  y2="15"
                  id="Line"
                  stroke={theme.palette.primary.main}
                  strokeWidth="2"
               ></line>
            </g>
         </g>
      </SvgIcon>
   );
};

export default BlankPm;
