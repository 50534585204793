export const isEmptyObject = (obj) => {
   return !Boolean(Object.keys(obj).length);
};

export function getName(person) {
   if (person.name) {
      return person.name.firstName + ' ' + person.name.lastName;
   } else {
      return person.firstName + ' ' + person.lastName;
   }
}
