import React from 'react';
import { ListItem, ListItemText, IconButton, ListItemIcon } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#342E76',
//     },
//     secondary:{
//       main: '#DB3D28',
//     },
//   },
// });

const AvailabilityList = (props) => {
   const type = props.time.type.replace(/^\w/, (c) => c.toUpperCase());
   let fromTime = null;
   let toTime = null;
   if (props.time.from !== null) {
      fromTime = props.time.from
         .toDate()
         .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
   }
   if (props.time.to !== null) {
      toTime = props.time.to
         .toDate()
         .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
   }
   return (
      <ListItem>
         <ListItemIcon>
            {props.time.type === 'available' ? (
               <EventAvailableIcon color="teal" />
            ) : (
               <EventBusyIcon color="warning" />
            )}
         </ListItemIcon>
         {fromTime !== null && toTime !== null ? (
            <ListItemText primary={`${type} between ${fromTime} & ${toTime}`} />
         ) : props.time.from === null ? (
            <ListItemText primary={`${type} until ${toTime}`} />
         ) : (
            <ListItemText primary={`${type} from ${fromTime}`} />
         )}
      </ListItem>
   );
};

export default AvailabilityList;
