import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Button, Paper, ButtonBase } from '@mui/material';
import Image from 'material-ui-image';
import RoomIcon from '@mui/icons-material/Room';
import { spaceTypeImage } from '../../../Spaces/functions';
import BookingChips from './bookingChips';

const ChildSpace = (props) => {
   // const [totalSeatsAvailable, setTotalSeatsAvailable] = useState(space.seats);

   // useEffect(())
   const style = {
      minWidth: '35px',
      maxWidth: '35px',
      maxHeight: '35px',
      mr: 1,
   };
   const space = props.space;
   const availableSeats = props.availableSeats;
   const bookings = props.bookings;
   async function showMap(sid, lid) {
      props.setSpaceMap({ sid, lid });
      props.setMap(true);
   }
   const statusStyles = (status) => {
      switch (status) {
         case 'disabled':
            return 'text.disabled';
         case 'warning':
            return 'text.warning';
         default:
            return 'text.success';
      }
   };
   function calcPercentage(num, total, status = false) {
      let percentage = (num / total) * 100;
      if (
         percentage <= 25 ||
         (total === 2 && percentage <= 50) ||
         (total === 3 && percentage <= 34)
      ) {
         status = 'warning';
      }
      if (num == 0) {
         status = 'disabled';
      }
      return status;
   }
   const CalculateAvailablility = (props) => {
      if (props.available.amBooked == 0 && props.available.pmBooked == 0) {
         let status = calcPercentage(props.available.availableSeats, props.seats);
         let seat = 'seat';
         if (props.available.availableSeats > 1) {
            seat = 'seats';
         }
         return (
            <Typography variant="caption" sx={{ color: statusStyles(status) }}>
               {`${props.available.availableSeats}/${props.seats} ${seat} available`}
            </Typography>
         );
      } else {
         let amSeats = props.available.availableSeats - props.available.amBooked;
         let pmSeats = props.available.availableSeats - props.available.pmBooked;
         let amStatus = calcPercentage(
            props.available.availableSeats - props.available.amBooked,
            props.seats,
         );
         let pmStatus = calcPercentage(
            props.available.availableSeats - props.available.pmBooked,
            props.seats,
         );
         let overallStatus = false;
         if (amStatus == pmStatus) {
            overallStatus = pmStatus;
         } else if (
            (amStatus == 'warning' && pmStatus == 'disabled') ||
            (amStatus == 'disabled' && pmStatus == 'warning')
         ) {
            overallStatus = 'warning';
         }

         return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption" sx={{ color: statusStyles(amStatus) }}>
                     AM - {`${amSeats}/${props.seats}`}
                  </Typography>
                  <Typography variant="caption" sx={{ color: statusStyles(pmStatus) }}>
                     PM - {`${pmSeats}/${props.seats}`}
                  </Typography>
               </Box>
               <Box sx={{ ml: '4px' }}>
                  <Typography
                     variant="caption"
                     sx={{ color: statusStyles(overallStatus) }}
                  >
                     seat{(pmSeats > 1 || amSeats > 1) && 's'} available
                  </Typography>
               </Box>
            </Box>
         );
      }
   };

   const SpaceButton = styled(ButtonBase)(({ theme }) => ({
      // position: 'relative',
      // height: 200,
      // [theme.breakpoints.down('sm')]: {
      //   width: '100% !important', // Overrides inline-style
      //   height: 100,
      // },
      // '&:hover, &.Mui-focusVisible': {
      //   backgroundColor: theme.palette.common.black,
      //   opacity: 0.05,
      // },
   }));
   function buttonDisabled() {
      let buttonType = false;
      if (status === 'disabled') {
         buttonType = true;
      }
      return buttonType;
   }

   return (
      <Box
         component={Paper}
         sx={{
            m: 1,
            minWidth: '250px',
            maxWidth: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            border: 0,
            borderLeft: 4,
            borderColor: statusStyles,
         }}
      >
         <SpaceButton
            onClick={() => {
               if (space.mapPath) {
                  showMap(space.sid, space.lid);
               }
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                  p: 1,
               }}
            >
               <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={style}>
                     <Image src={spaceTypeImage(space.type)} />
                  </Box>
                  <Box>
                     <Typography variant="body1" paragraph sx={{ mb: 0 }}>
                        {space.name}
                     </Typography>
                     <CalculateAvailablility
                        available={availableSeats[space.sid]}
                        seats={space.seats}
                     />
                  </Box>
               </Box>
               <Box>
                  {space.mapPath && (
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                           justifyContent: 'flex-end',
                        }}
                     >
                        <RoomIcon color="primary" fontSize="small" />
                        <Typography variant="caption" color="primary">
                           Map
                        </Typography>
                     </Box>
                  )}
               </Box>
            </Box>
         </SpaceButton>
         <Box>
            <Box>
               {bookings &&
                  bookings[space.sid] &&
                  Object.keys(bookings[space.sid]).length !== 0 && (
                     <BookingChips bookingItems={bookings[space.sid]} />
                  )}
            </Box>
            {props.authStatus && props.authStatus.status === 'ito' && (
               <Box
                  sx={{
                     display: 'flex',
                     alignContent: 'center',
                     flexWrap: 'wrap',
                     justifyContent: 'space-between',
                  }}
               >
                  <Button disabled={buttonDisabled()}>Book</Button>
               </Box>
            )}
         </Box>
      </Box>
   );
};

export default ChildSpace;
