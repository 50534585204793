import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const BlankAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="status/DASHAM" fill="none">
               <path
                  d="M15.5,31 C20.071838,31 19.3384387,31 19.3384387,31 C19.3384387,31 19.4938713,31 19.4938713,31 C19.4938713,31 41,0 41,0 C41,0 19.7413077,0 15.5,0 C6.93958638,0 0,6.93958638 0,15.5 C0,24.0604136 6.48554758,31 15.5,31 Z"
                  id="Oval"
               ></path>
               <line
                  x1="17"
                  y1="14.5"
                  x2="9"
                  y2="14.5"
                  id="Line"
                  stroke={theme.palette.primary.main}
                  strokeWidth="2"
               ></line>
            </g>
         </g>
      </SvgIcon>
   );
};

export default BlankAm;
