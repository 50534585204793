import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EachLocation from './eachLocation';
import LocationDrawer from './locationDrawer';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: theme.spacing(2),
   },
}));

function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Locations = (props) => {
   const classes = useStyles();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [locationDrawer, setLocationDrawer] = useState(false);
   const [snackBar, setSnackBar] = useState({
      display: false,
      type: 'success',
      message: '',
   });

   const snackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setSnackBar({ ...snackBar, display: false });
   };

   async function deleteDoc(path) {
      let schedule = await props.firebase.wholeCollection(path + '/schedule').get();
      if (!schedule.empty) {
         // schedule.forEach(doc => {
         //   let docPath = path+"/schedule/"+doc.id;
         //   deleteDoc(docPath);
         // })
      } else {
         await props.firebase.doc(path).delete();
      }
   }
   async function deleteCollection(path) {
      let docs = await props.firebase.wholeCollection(path).get();
      if (!docs.empty) {
         docs.forEach((doc) => {
            let docPath = path + doc.id;
            deleteDoc(docPath);
         });
      }
   }

   async function resetAllData() {
      const authUser = JSON.parse(localStorage.getItem('authUser'));

      let path = `company/${authUser.cid}/people/`;
      deleteCollection(path);
   }

   return (
      <React.Fragment>
         <LocationDrawer
            locationDrawer={locationDrawer}
            company={props.company}
            setLocationDrawer={setLocationDrawer}
            setSnackBar={setSnackBar}
            locations={props.locations}
         />
         <Snackbar
            open={snackBar.display}
            autoHideDuration={6000}
            onClose={snackbarClose}
         >
            <Alert onClose={snackbarClose} severity={snackBar.type}>
               {snackBar.message}
            </Alert>
         </Snackbar>
         <Grid container direction="row" spacing={3} className={classes.root}>
            <Grid item xs={12}>
               <Typography variant="caption">
                  Locations are ways to group your people together. People in a location
                  or area are able to see other people within the area or location, but
                  are not able to see people outside their own area. If you are a company
                  that is fully remote, you will still need to have one area in order to
                  have all the people showing up.
               </Typography>
            </Grid>
            {props.company?.features?.multisite && (
               <Grid container item direction="column" spacing={3}>
                  <Grid item xs={12}>
                     <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setLocationDrawer(true)}
                     >
                        Add a Location
                     </Button>
                  </Grid>
               </Grid>
            )}
            <Grid container item xs={12}>
               {props.locations.map((location) => (
                  <EachLocation
                     key={location.id}
                     cid={authUser.cid}
                     company={props.company}
                     locations={props.locations}
                     locationKey={location.id}
                     locationName={location.name}
                     setSnackBar={setSnackBar}
                  />
               ))}
            </Grid>
            {props.company?.features?.multisite && props.archivedLocations && (
               <Grid container item xs={12}>
                  {props.archivedLocations.map((archivedLocation) => (
                     <EachLocation
                        key={archivedLocation.id}
                        archived={true}
                        cid={authUser.cid}
                        company={props.company}
                        locations={props.archivedLocations}
                        locationKey={archivedLocation.id}
                        locationName={archivedLocation.name}
                        setSnackBar={setSnackBar}
                     />
                  ))}
               </Grid>
            )}
            {/* <Grid item xs={12}>
            <Button onClick={()=>resetAllData()}>Reset</Button>
          </Grid> */}
         </Grid>
      </React.Fragment>
   );
};
export default withFirebase(Locations);
