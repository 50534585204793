import React, { useState, useEffect } from 'react';
import Drawer from '../Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PublicIcon from '@mui/icons-material/Public';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import * as EmailValidator from 'email-validator';
import RoutineModal from './routineModal';
import { DateToday } from '../../constants/functions';
import { Box, Chip } from '@mui/material';
import { manageMultipleGroupAllocation } from '../People/Groups/functions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
   },
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(0),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   leftBar: {
      paddingTop: theme.spacing(4),
   },
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   drawerPaper: {
      width: '60%',
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   deleteButton: {
      margin: theme.spacing(3, 0, 2),
      float: 'right',
   },
   checkbox: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         marginLeft: theme.spacing(0),
      },
   },
}));

const PeoplePageDrawer = (props) => {
   const classes = useStyles();
   const [form, setForm] = useState('blank');
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [email, setEmail] = useState('');
   const [emailError, setEmailError] = useState({
      error: false,
      helperText: '',
   });
   const [emailDisabled, setEmailDisabled] = useState(false);
   const [personGroups, setPersonGroups] = useState(false);
   const [userLoc, setUserLoc] = useState(false);
   const [userData, setUserData] = useState(false);
   const [lastView, setLastView] = useState(false);
   const [addAnother, setAddAnother] = useState(false);
   const user = JSON.parse(localStorage.getItem('authUser'));
   const toggleAddAnother = () => setAddAnother(!addAnother);
   const [deleteDisabled, setDeleteDisabled] = useState(false);
   const [locChange, setLocChange] = useState(false);
   const [groupOptions, setGroupOptions] = useState(false);
   const [saveError, setSaveError] = useState(false);
   const today = DateToday();

   useEffect(() => {
      if (props.peopleDrawer) {
         setForm(props.drawerData.type);

         if (props.drawerData.type === 'edit' || props.drawerData.type === 'view') {
            const unsubscribe = props.firebase
               .getPerson(props.drawerData.value.cid, props.drawerData.value.uid)
               .onSnapshot((result) => {
                  if (result.exists) {
                     let person = result.data();

                     person.id = result.id;
                     setFirstName(person.name.firstName);
                     setLastName(person.name.lastName);
                     setEmail(person.email);
                     setEmailDisabled(true);
                     setUserData({ uid: person.id, ...person });
                     setUserLoc(person.lid[0]);
                  }
               });
            return () => {
               unsubscribe();
               setPersonGroups(false);
               setLocChange(false);
            };
         } else if (props.drawerData.type === 'blank') {
            setPersonGroups([]);
            setLocChange(false);
         }
      }
   }, [props.peopleDrawer]);

   useEffect(() => {
      if (props.peopleDrawer && userLoc && userData) {
         //setGroupOptions(Object.values(props.groups));
         const unsubscribe = props.firebase
            .locationGroupsIndex(userLoc)
            .onSnapshot((result) => {
               let grps = {};
               if (result.exists) {
                  Object.entries(result.data()).map(([key, value]) => {
                     //check if key exists in props.groups
                     if (props.groups[key]) {
                        grps[key] = {
                           id: key,
                           name: value.name,
                           admins: value.admins,
                           global: value.global,
                        };
                     }
                  });
               }
               setGroupOptions(Object.values(grps));
               let selected = [];
               userData.gid.map((id) => {
                  if (grps[id]) {
                     selected.push(grps[id]);
                  }
               });
               setPersonGroups(selected);
            });
         return () => {
            unsubscribe();
         };
      }
   }, [props.drawerData, userLoc, userData]);

   useEffect(() => {
      setSaveError(false);
      if (userLoc && userLoc !== props.displayFilter.id) {
         if (userData.routine) {
            Object.values(userData.routine).map((day) => {
               if (day !== '-') {
                  setSaveError(true);
               }
            });
         }
      }
   }, [userLoc, userData.routine]);

   function clearDrawerFields() {
      setFirstName('');
      setLastName('');
      setEmail('');
      if (addAnother) {
         setPersonGroups([]);
      } else {
         setPersonGroups(false);
      }
      setUserData(false);
      setUserLoc(false);
      setSaveError(false);
      setEmailDisabled(false);
   }
   function closeDrawer() {
      props.setPeopleDrawer(false);
      props.setDrawerData(false);
      clearDrawerFields();
   }
   async function getFullGroupDetails(gid) {
      const group = await props.firebase
         .locationGroupDetails(props.displayFilter.id, gid)
         .get();
      return group.data();
   }

   async function saveUser(event) {
      setSaveError(true);
      event.preventDefault();
      if (EmailValidator.validate(email) === false) {
         setEmailError({
            error: true,
            helperText: 'You must add a valid email address',
         });
         setSaveError(false);
      } else if (EmailValidator.validate(email) === true) {
         let lid = [userLoc];
         let gid = [];
         let grps = {
            new: [],
            removed: [],
            locGroups: props.groups,
         };
         let person = {
            name: {
               firstName,
               lastName,
            },
            email: email.toLowerCase(),
            lid,
            gid,
            cid: user.cid,
            status: {
               statusId: 'notInvited',
            },
         };
         let visitorsEnabled = false;
         if (props.company?.features?.visitors) {
            visitorsEnabled = true;
         }
         let routineEnabled = false;
         if (props.company?.features?.advancedScheduling) {
            routineEnabled = true;
         }
         //load the group details into grps
         if (!locChange) {
            await Promise.all(
               personGroups.map(async (groupdetails) => {
                  if (userData && !userData.gid.includes(groupdetails.id)) {
                     grps.locGroups[groupdetails.id] = await getFullGroupDetails(
                        groupdetails.id,
                     );
                     grps.new.push(groupdetails.id);
                  } else if (props.drawerData.type == 'blank') {
                     grps.new.push(groupdetails.id);
                  }
                  gid.push(groupdetails.id);
               }),
            );
            if (userData) {
               await Promise.all(
                  userData.gid.map(async (gidKey) => {
                     if (!gid.includes(gidKey)) {
                        let fullGroupDets = await getFullGroupDetails(gidKey);
                        grps.locGroups[gidKey] = fullGroupDets;
                        grps.removed.push(gidKey);
                     }
                  }),
               );
            }
         } else {
            grps.removed = userData.gid;
         }
         if (form === 'blank') {
            let uid = await props.firebase.db
               .collection(`company/${user.cid}/people`)
               .doc()
               .get();
            person.lid = [props.displayFilter.id];
            const existing = await props.firebase.emailExists(user.cid, email).get();
            if (!existing.empty) {
               props.setChecked(true);
               props.setAlertMessage({
                  type: 'warning',
                  message: email + ' already exists.',
               });
            } else {
               await props.firebase
                  .newPerson(
                     props.displayFilter.id,
                     user.cid,
                     person,
                     uid.id,
                     visitorsEnabled,
                  )
                  .then(async () => {
                     if (personGroups.length > 0) {
                        try {
                           await manageMultipleGroupAllocation(
                              props.firebase,
                              grps.new,
                              person,
                              uid.id,
                              person.lid[0],
                              person.cid,
                              'new',
                           );
                        } catch (error) {
                           console.warn(error, 'Error - setting groups for new person');
                        }
                     }
                     props.setChecked(true);
                     props.setAlertMessage({
                        type: 'success',
                        message: firstName + ' ' + lastName + ' has been Added.',
                     });
                     clearDrawerFields();
                     if (!addAnother) {
                        closeDrawer();
                     }
                  })
                  .catch((error) => {
                     props.setChecked(true);
                     props.setAlertMessage({
                        type: 'error',
                        message: 'Oops, something went wrong.',
                     });
                     console.warn(error, 'error');
                  });
            }
         } else if (form === 'edit') {
            var uid = userData.uid;
            if (locChange) {
               try {
                  let dateRef = today.ref - 1;
                  const futureSchedule = await props.firebase
                     .personFutureScheduleExists(user.cid, uid, dateRef.toString())
                     .get();
                  if (!futureSchedule.empty) {
                     try {
                        await props.firebase.migrateFutureSchedule(
                           user,
                           uid,
                           futureSchedule,
                           props.displayFilter,
                           userLoc,
                        );
                     } catch (error) {
                        console.warn(error, 'error - migrating future schedule');
                     }
                  }
               } catch (e) {
                  console.warn(e, 'error - editing persons future shedule');
               }
            }
            const personStatus = userData.status.statusId;
            person.status = { statusId: personStatus };
            setAddAnother(false);

            try {
               await props.firebase.editPerson(
                  user.cid,
                  uid,
                  person,
                  props.displayFilter.id,
                  visitorsEnabled,
                  routineEnabled,
                  // grps,
               );
               if (grps.new.length > 0) {
                  try {
                     await manageMultipleGroupAllocation(
                        props.firebase,
                        grps.new,
                        person,
                        uid,
                        props.displayFilter.id,
                        person.cid,
                        'new',
                     );
                  } catch (error) {
                     console.warn(error, 'Error - setting groups for new person');
                  }
               }
               if (grps.removed.length > 0) {
                  try {
                     await manageMultipleGroupAllocation(
                        props.firebase,
                        grps.removed,
                        person,
                        uid,
                        props.displayFilter.id,
                        person.cid,
                        'removed',
                     );
                  } catch (error) {
                     console.warn(error, 'Error - setting groups for new person');
                  }
               }

               props.setChecked(true);
               props.setAlertMessage({
                  type: 'success',
                  message: firstName + ' ' + lastName + ' has been updated.',
               });
               closeDrawer();
            } catch (error) {
               props.setChecked(true);
               props.setAlertMessage({
                  type: 'error',
                  message: 'Oops, something went wrong.',
               });
               console.warn(error, 'error - editing person');
            }
         }
      }
   }
   async function deleteUser(props) {
      setDeleteDisabled(true);
      let routine = false;
      if (userData.routine) {
         Object.values(userData.routine).map((day) => {
            if (day !== '-') {
               routine = true;
            }
         });
      }
      const uid = props.drawerData.value.uid;
      const lid = props.displayFilter.id;
      const cid = user.cid;
      if (user.uid === uid) {
         props.setChecked(true);
         props.setAlertMessage({
            type: 'warning',
            message: 'You cannot delete yourself',
         });
         setDeleteDisabled(false);
      } else if (routine) {
         props.setChecked(true);
         props.setAlertMessage({
            type: 'warning',
            message: 'You must remove the routine before you can delete this user',
         });
         setDeleteDisabled(false);
      } else {
         const attendance = await props.firebase.personScheduleExists(cid, uid).get();
         if (attendance.empty) {
            let grps = {
               removed: [],
               locGroups: props.groups,
            };
            props.drawerData.value.gid.map((gidKey) => {
               grps.removed.push(gidKey);
            });
            // await Promise.all(
            //    userData.gid.map(async (gidKey) => {
            //       if (!gid.includes(gidKey)) {
            //          let fullGroupDets = await getFullGroupDetails(gidKey);
            //          grps.locGroups[gidKey] = fullGroupDets;
            //          grps.removed.push(gidKey);
            //       }
            //    }),
            // );
            const person = await props.firebase.getPerson(cid, uid).get();
            const status = person.data().status;
            let visitorsEnabled = false;
            if (props.company?.features?.visitors) {
               visitorsEnabled = true;
            }
            let routineEnabled = false;
            if (props.company?.features?.advancedScheduling) {
               routineEnabled = true;
            }
            try {
               await manageMultipleGroupAllocation(
                  props.firebase,
                  grps.removed,
                  person.data(),
                  uid,
                  props.displayFilter.id,
                  cid,
                  'removed',
               );
            } catch (error) {
               console.warn(error, 'Error - setting groups for new person');
            }
            if (status.statusId === 'notInvited') {
               try {
                  await props.firebase.deletePerson(
                     lid,
                     cid,
                     uid,
                     props.drawerData.value.email,
                     visitorsEnabled,
                     // grps,
                  );
                  props.setChecked(true);
                  props.setAlertMessage({
                     type: 'warning',
                     message: firstName + ' ' + lastName + ' has been deleted.',
                  });
                  closeDrawer();
                  setDeleteDisabled(false);
               } catch (error) {
                  props.setChecked(true);
                  props.setAlertMessage({
                     type: 'error',
                     message:
                        'Oops, something went wrong.' +
                        firstName +
                        ' ' +
                        lastName +
                        ' has not been deleted.',
                  });
                  console.warn(error, 'Error deleting not invited person');
               }
            } else if (status.statusId === 'invited' || status.statusId === 'active') {
               var deleteUser = firebase.functions().httpsCallable('deleteUser');
               return deleteUser(uid)
                  .then(async () => {
                     try {
                        await props.firebase.deleteInvitedPerson(
                           lid,
                           cid,
                           uid,
                           props.drawerData.value.email,
                           visitorsEnabled,
                           routineEnabled,
                           grps,
                        );
                        props.setChecked(true);
                        props.setAlertMessage({
                           type: 'warning',
                           message: firstName + ' ' + lastName + ' has been deleted.',
                        });
                        closeDrawer();
                        setDeleteDisabled(false);
                     } catch {
                        props.setChecked(true);
                        props.setAlertMessage({
                           type: 'error',
                           message:
                              'Oops, something went wrong.' +
                              firstName +
                              ' ' +
                              lastName +
                              ' has not been deleted.',
                        });
                     }
                  })
                  .catch((error) => {
                     console.warn(error, 'error');
                  });
            }
         } else {
            props.setChecked(true);
            props.setAlertMessage({
               type: 'warning',
               message:
                  firstName +
                  ' ' +
                  lastName +
                  ' has changed their intheOffice status on one or more dates. You must reset their schedule before you can delete them.',
            });
            setDeleteDisabled(false);
         }
      }
   }

   const handleGroupOptions = (event, values) => {
      setPersonGroups(values);
   };
   const switchLocation = (event) => {
      setUserLoc(event.target.value);
      if (event.target.value !== props.displayFilter.id) {
         let name = props.locations.find((x) => x.id === event.target.value).name;
         setLastView({
            cid: user.cid,
            id: event.target.value,
            type: 'location',
            name,
         });
         setPersonGroups([]);
         setLocChange(true);
      } else {
         setLastView(false);
         setLocChange(false);
      }
   };

   return (
      <Drawer
         anchor="right"
         open={props.peopleDrawer}
         onClose={() => {
            closeDrawer();
         }}
      >
         {props.peopleDrawer && (
            <Container maxWidth="xs">
               <div className={classes.drawer}>
                  {form === 'view' ? (
                     ''
                  ) : (
                     <React.Fragment>
                        <Typography component="h1" variant="h5">
                           {form === 'blank' ? 'Add a person' : 'Edit ' + firstName}
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={saveUser}>
                           <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                 <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onInput={(e) => setFirstName(e.target.value)}
                                    autoFocus
                                    disabled={props.timeToUpgrade}
                                 />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                 <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={lastName}
                                    onInput={(e) => setLastName(e.target.value)}
                                    disabled={props.timeToUpgrade}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    value={email}
                                    onInput={(e) => {
                                       setEmail(e.target.value);
                                       setEmailError({ error: false, helperText: '' });
                                    }}
                                    autoComplete="email"
                                    error={emailError.error}
                                    helperText={emailError.helperText}
                                    disabled={emailDisabled}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 {groupOptions && personGroups && (
                                    <Autocomplete
                                       multiple
                                       disabled
                                       id="location-groups"
                                       options={groupOptions}
                                       value={personGroups}
                                       disableCloseOnSelect
                                       getOptionLabel={(option) => option.name}
                                       renderTags={(value, getTagProps) =>
                                          value.map((option, index) => (
                                             <Chip
                                                label={option.name}
                                                icon={
                                                   option.global ? (
                                                      <PublicIcon
                                                         sx={{ color: 'grey' }}
                                                      />
                                                   ) : null
                                                }
                                                {...getTagProps({ index })}
                                             />
                                          ))
                                       }
                                       onChange={handleGroupOptions}
                                       renderOption={(option, { selected }) => (
                                          <React.Fragment>
                                             <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                             />
                                             {option.global && (
                                                <PublicIcon sx={{ mr: 1 }} />
                                             )}{' '}
                                             {option.name}
                                          </React.Fragment>
                                       )}
                                       fullWidth
                                       renderInput={(params) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             label="Groups"
                                             placeholder="Add group names"
                                             helperText="Please manage groups from the groups list"
                                          />
                                       )}
                                    />
                                 )}
                              </Grid>
                              {form === 'edit' && (
                                 <Grid item xs={12}>
                                    <FormControl style={{ marginTop: 8 }} fullWidth>
                                       <InputLabel id="switch-location">
                                          Location
                                       </InputLabel>
                                       {userLoc && (
                                          <Select
                                             labelId="switch-location"
                                             id="switch-location-select"
                                             value={userLoc}
                                             label="Location"
                                             onChange={(e) => switchLocation(e)}
                                          >
                                             {props.locations.map((location) => (
                                                <MenuItem
                                                   key={location.id}
                                                   id={location.name}
                                                   value={location.id}
                                                >
                                                   {location.name}
                                                </MenuItem>
                                             ))}
                                          </Select>
                                       )}
                                    </FormControl>
                                 </Grid>
                              )}
                              <Grid item xs={12}>
                                 <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={saveError}
                                 >
                                    Save
                                 </Button>

                                 {form === 'edit' ? (
                                    <Button
                                       className={classes.deleteButton}
                                       variant="outlined"
                                       color="secondary"
                                       disabled={deleteDisabled}
                                       onClick={() => deleteUser(props)}
                                    >
                                       Delete
                                    </Button>
                                 ) : (
                                    <FormControlLabel
                                       className={classes.checkbox}
                                       value="addAnother"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             checked={addAnother}
                                             onChange={(e) => {
                                                toggleAddAnother(e.target.value);
                                             }}
                                             name="checkedF"
                                             disabled={props.timeToUpgrade}
                                          />
                                       }
                                       label="tick to add another"
                                       labelPlacement="end"
                                    />
                                 )}
                              </Grid>
                              {deleteDisabled && (
                                 <Grid item xs={12}>
                                    <Box
                                       sx={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                       <CircularProgress />
                                    </Box>
                                 </Grid>
                              )}
                           </Grid>
                        </form>
                     </React.Fragment>
                  )}
               </div>
               {userData?.routine && (
                  <RoutineModal userData={userData} saveError={saveError} />
               )}
            </Container>
         )}
      </Drawer>
   );
};

export default withFirebase(PeoplePageDrawer);
