import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withFirebase } from '../../Firebase';
import { Box, FormHelperText } from '@mui/material';

const CompanyAdmins = (props) => {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [companyPeople, setCompanyPeople] = useState(false);
   const [admins, setAdmins] = useState(false);
   const [error, setError] = useState({ error: false, text: '' });
   const [edit, setEdit] = useState(false);
   const [deleteError, setDeleteError] = useState(false);

   useEffect(() => {
      if (props.company) {
         const unsubscribe = props.firebase
            .companyPeople(props.company.cid)
            .onSnapshot((results) => {
               let ppl = [];
               let add = [];
               results.forEach((result) => {
                  ppl.push({ uid: result.id, ...result.data() });
                  if (
                     result.data().locationAdmin &&
                     result.data().locationAdmin.length > 0
                  ) {
                     add.push({ uid: result.id, ...result.data() });
                  }
               });
               ppl.sort((a, b) =>
                  a.name.firstName
                     .toLowerCase()
                     .localeCompare(b.name.firstName.toLowerCase()),
               );
               setCompanyPeople(ppl);
               setAdmins(add);
            });
         return () => unsubscribe();
      }
   }, [props.company]);

   async function handleChange(event) {
      setError({ error: false, text: '' });
      setDeleteError(false);
      const userCheck = await props.firebase.user(event.target.value).get();
      if (userCheck.exists) {
         let newAdminArray = [];
         admins.map((admin) => {
            newAdminArray.push(admin.uid);
         });
         if (!newAdminArray.includes(event.target.value)) {
            newAdminArray.push(event.target.value);
         }
         let locations = [];
         Object.keys(props.company.locations).map((location) => {
            locations.push(location);
         });
         try {
            await props.firebase.saveLocationAdmin(
               event.target.value,
               props.company.cid,
               locations,
               newAdminArray,
            );
         } catch (error) {
            console.log(error, 'error');
         }
      } else {
         setError({
            error: true,
            text: 'You must invite this user from the People page before they can be made an Admin',
         });
      }
   }
   async function deleteAdmin(user) {
      setDeleteError(false);
      setError({ error: false, text: '' });
      if (user.uid === authUser.uid) {
         setDeleteError(true);
      } else {
         let newAdminArray = [];
         admins.map((admin) => {
            if (admin.uid !== user.uid) {
               newAdminArray.push(admin.uid);
            }
         });
         let locations = [];
         Object.keys(props.company.locations).map((location) => {
            locations.push(location);
         });
         try {
            await props.firebase.deleteLocationAdmin(
               user.uid,
               props.company.cid,
               locations,
               newAdminArray,
            );
         } catch (error) {
            console.log(error, 'error');
         }
      }
   }

   return (
      <Paper sx={{ p: 3 }}>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
            }}
         >
            <Typography variant="h6" component="h3">
               Admins
            </Typography>
            {edit ? (
               <Button
                  onClick={() => {
                     setEdit(false);
                  }}
               >
                  Done
               </Button>
            ) : (
               <Button
                  onClick={() => {
                     setEdit(true);
                  }}
               >
                  Edit
               </Button>
            )}
         </Box>
         {admins && !edit ? (
            <Box sx={{ mb: 2 }}>
               {admins.map((admin) => (
                  <Chip
                     key={admin.uid}
                     label={`${admin.name.firstName} ${admin.name.lastName}`}
                  />
               ))}
            </Box>
         ) : admins && edit ? (
            <Box sx={{ mb: 2 }}>
               {admins.map((admin) => (
                  <Chip
                     key={admin.uid}
                     label={`${admin.name.firstName} ${admin.name.lastName}`}
                     onDelete={() => deleteAdmin(admin)}
                  />
               ))}
            </Box>
         ) : (
            <Box sx={{ mb: 2 }}>
               <Typography>Loading...</Typography>
            </Box>
         )}
         {deleteError && (
            <Typography color="error">You can't remove yourself as an admin</Typography>
         )}
         {
            companyPeople && edit && (
               <FormControl fullWidth error={error.error}>
                  <InputLabel id="demo-simple-select-label">Admin Select</InputLabel>
                  <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value=""
                     label="Admin Select"
                     onChange={handleChange}
                     fullWidth
                  >
                     {companyPeople.map((person) => (
                        <MenuItem key={person.uid} value={person.uid}>
                           {person.name.firstName} {person.name.lastName} - {person.email}
                        </MenuItem>
                     ))}
                  </Select>
                  <FormHelperText>{error.text}</FormHelperText>
               </FormControl>
            )
            // <Typography variant="body" paragraph>
            //   {Object.entries(companyPeople).map(([key, value])=>(value.name.firstName))}
            // </Typography>
         }
      </Paper>
   );
};

export default withFirebase(CompanyAdmins);
