import React from 'react';
import { useAuth, useCompany, useLocations } from '../../providers';
import IsEnabled from '../IsEnabled';
import { CardSection, PageLayout } from '../layout';
import { Grid } from '@mui/material';
import Personal from './personal';
import Locations from './locations';
import Preferences from './preferences';
import AdditionalRoles from '../AdditionalRoles';
import VisitorNotifications from './visitorNotifications';

const Main = () => {
   const { authUser } = useAuth();
   const { company, companyLoading } = useCompany();
   const { allLocations } = useLocations();

   if (companyLoading || !allLocations) {
      // TODO: put loading spinner here
      return false;
   }

   return (
      <PageLayout>
         <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
               <CardSection>
                  <Personal user={authUser} />
               </CardSection>

               <IsEnabled company={company} hasFeature={'groups'}>
                  <CardSection sx={{ mt: 3 }}>
                     <AdditionalRoles company={company} />
                  </CardSection>
               </IsEnabled>
            </Grid>
            <Grid item xs={12} md={6}>
               <CardSection>
                  <Locations companyName={company.companyName} locations={allLocations} />
               </CardSection>
               <IsEnabled company={company} hasFeature={'visitors'}>
                  <CardSection sx={{ mt: 3 }}>
                     <VisitorNotifications />
                  </CardSection>
               </IsEnabled>
            </Grid>
            <Grid item xs={12}>
               <Preferences user={authUser} />
            </Grid>
         </Grid>
      </PageLayout>
   );
};

export default Main;
