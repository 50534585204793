import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const ItoAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/ITOAM"
               transform="translate(-0.2369, 0)"
               fill={theme.palette.primary.main}
            >
               <path
                  d="M21.6072122,8.6 L22.9140083,10.0271739 L11.7128988,22.2600932 L6.48571429,16.5513975 L7.7925104,15.1242236 L11.7128988,19.4057453 L21.6072122,8.6 Z"
                  id="Combined-Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoAm;
