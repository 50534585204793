import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { DateToday } from '../../constants/functions';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import enLocale from 'date-fns/locale/en-GB';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const DateScroller = ({ date, setDate }) => {
   const [disabled, setDisabled] = useState(true);

   function handleDateChange(newDate) {
      let tod = new Date();
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      setDate(DateToday(differenceInDays));
   }
   function scroll(day) {
      let tod = new Date();
      let newDate = date.fullDate;
      newDate.setDate(newDate.getDate() + day);
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      if (differenceInDays > 0) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
      setDate(DateToday(differenceInDays));
   }

   return (
      <Box
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <IconButton
            disabled={disabled}
            onClick={() => {
               scroll(-1);
            }}
         >
            <ArrowBackIosOutlinedIcon />
         </IconButton>
         <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
               disablePast
               minDate={new Date()}
               value={date.fullDate}
               onChange={(e) => {
                  handleDateChange(e);
               }}
               renderInput={(params) => <TextField {...params} />}
            />
         </LocalizationProvider>
         <IconButton
            onClick={() => {
               scroll(1);
            }}
         >
            <ArrowForwardIosOutlinedIcon />
         </IconButton>
      </Box>
   );
};

export default DateScroller;
