import React from 'react';
import { Box } from '@mui/material';
import ChildSpace from './childSpace';
import ParentSpace from './parentSpace';
import CompressedChildSpace from './compressedChildSpace';
import CompressedParentSpace from './compressedParentSpace';

const DisplaySpaces = (props) => {
   let level = 0;
   if (props.level) {
      level = props.level;
   }
   return (
      <React.Fragment>
         {props.spaces.map((space) => {
            if (space.hasChildren && !space.lastChild) {
               return (
                  <ParentSpace
                     key={space.sid}
                     space={space}
                     setSpaceMap={props.setSpaceMap}
                     setMap={props.setMap}
                     userData={props.userData}
                     compressed={props.compressed}
                     day={props.day}
                  />
               );
            }
         })}
         <Box
            sx={{
               pt: 2,
               pb: 2,
               display: 'flex',
               flexDirection: 'row',
               flexWrap: 'wrap',
               justifyContent: 'start',
            }}
         >
            {props.spaces.map((space) => {
               if (space.lastChild && space.bookable && !space.timePeriod.hourly) {
                  return (
                     <CompressedChildSpace
                        key={space.sid}
                        space={space}
                        setSpaceMap={props.setSpaceMap}
                        setMap={props.setMap}
                        userData={props.userData}
                        compressed={props.compressed}
                        day={props.day}
                     />
                  );
               }
            })}
         </Box>
      </React.Fragment>
   );
};

export default DisplaySpaces;
