import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@mui/material/Button';
import { withFirebase } from '../../Firebase';
import { featureNames } from '../../../constants/functions';

const globalFeatures = {
   advancedScheduling: ['offsiteEnabled', 'routine', 'requests'],
   groups: ['groups'],
   spaceBookings: ['spacesEnabled'],
   reporting: ['reporting'],
   multisite: ['multisite'],
   visitors: ['visitorsEnabled'],
   leave: ['leave'],
};

const FeatureAccordionBody = (props) => {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [errorText, setErrorText] = useState(false);
   const [disabled, setDisabled] = useState(props.switchDisabled);
   const [visitorsConfirmation, setVisitorsConfirmation] = useState(false);

   const handleChange = async (event, panel) => {
      if (panel === 'multisite' && Object.keys(props.company.locations).length > 1) {
         setErrorText(
            'You must archive unused locations before you can switch off Multiple Locations',
         );
      } else if (panel === 'visitors' && !event.target.checked && !visitorsConfirmation) {
         setVisitorsConfirmation(true);
      } else {
         setDisabled(true);
         let data = {
            status: event.target.checked,
            feature: panel,
            subscription: props.company.subscription.plan,
            cid: props.company.cid,
            lids: props.company.locations,
            subFeatures: globalFeatures[panel],
            companyEnabledFeatures: props.company.features,
         };
         if (panel === 'visitors' || panel === 'spaceBookings') {
            if (data.status) {
               let visitorInternalDetails = {};
               Object.keys(data.lids).map((lid) => {
                  visitorInternalDetails[lid] = {};
               });
               let results = await props.firebase.companyPeople(data.cid).get();
               if (!results.empty) {
                  results.forEach((result) => {
                     visitorInternalDetails[result.data().lid[0]][
                        result.data().email.toLowerCase()
                     ] = `${result.data().name.firstName} ${result.data().name.lastName}`;
                  });
               }

               data = { ...data, visitorInternalDetails };
            }
         }
         try {
            await props.firebase.companyFeatures(data);
            setVisitorsConfirmation(false);
            setDisabled(false);
         } catch (error) {
            console.log(error);
            setDisabled(false);
         }

         try {
            const log = {
               uid: authUser.uid,
               email: authUser.email,
               feature: panel,
               enabled: !event.target.checked,
            };
            await props.firebase.logToDb(
               `company/${props.company.cid}/logs/features`,
               log,
            );
         } catch (error) {
            console.error(error);
         }
      }
   };
   function handleClose() {
      setVisitorsConfirmation(false);
   }
   function handleVisitorDelete() {
      let status = { target: { checked: false } };
      handleChange(status, props.panel);
   }

   return (
      <React.Fragment>
         <Dialog
            open={visitorsConfirmation}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               Turn off Visitor Management?
            </DialogTitle>
            <DialogContent>
               <DialogContentText>
                  Turning off Visitor Management will delete all visitor records. This
                  action is not recoverable. Do you want to continue?
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary">
                  Cancel
               </Button>
               <Button onClick={handleVisitorDelete} color="error" variant="contained">
                  Yes, Turn off
               </Button>
            </DialogActions>
         </Dialog>
         <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={8}>
               <Typography>{props.data.header}</Typography>
            </Grid>
            {featureNames[props.panel] && (
               <Grid item xs={12} sm={2}>
                  {props.panel === 'basic' ? (
                     <Typography>£FREE</Typography>
                  ) : props.panel === 'visitors' ? (
                     <>
                        <Typography>
                           £{featureNames[props.panel].cost.toFixed(2)}
                        </Typography>
                        <Typography variant="caption">
                           per user per month / Contact us for Landlord & Tenant pricing
                        </Typography>
                     </>
                  ) : (
                     <>
                        <Typography>
                           £{featureNames[props.panel].cost.toFixed(2)}
                        </Typography>
                        <Typography variant="caption">per user per month</Typography>
                     </>
                  )}
               </Grid>
            )}
            {props.panel !== 'basic' && (
               <Grid item xs={12} sm={2}>
                  <FormControlLabel
                     control={
                        <Switch
                           disabled={disabled}
                           color="primary"
                           checked={props.enabled}
                           onChange={(e) => handleChange(e, props.panel)}
                        />
                     }
                     label="Enable"
                  />
                  {errorText && (
                     <Typography variant="caption" color="error">
                        {errorText}
                     </Typography>
                  )}
               </Grid>
            )}
         </Grid>
         {props.data.features && (
            <List>
               {props.data.features.map((list) => (
                  <ListItem key={list.title} alignItems="flex-start">
                     {list.image && (
                        <ListItemAvatar sx={{ width: 56, height: 56 }}>
                           {list.image}
                        </ListItemAvatar>
                     )}
                     <ListItemText
                        primary={list.title}
                        secondary={<React.Fragment>{list.description}</React.Fragment>}
                     />
                  </ListItem>
               ))}
            </List>
         )}
      </React.Fragment>
   );
};

export default withFirebase(FeatureAccordionBody);
