import React from 'react';
import logo from '../../../assets/logo.svg';
import { Box } from '@mui/material';

const Logo = ({ width, height }) => {
   return (
      <Box sx={{ width, height }}>
         <img
            src={logo}
            alt="InTheOffice Logo"
            width={`${width}px`}
            height={`${height}px`}
         />
      </Box>
   );
};

export default Logo;
