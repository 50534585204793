import React, { useEffect, useState } from 'react';
import CapacityGauge from './index';
import { DateToday } from '../../constants/functions';
import { withFirebase } from '../Firebase';

const DayCapacity = (props) => {
   const [capacity, setCapacity] = useState({ percent: 0, number: 0 });
   const today = DateToday();
   useEffect(() => {
      if (Object.keys(props.location).length !== 0) {
         const loadCapacityNumbers = props.firebase
            .getStatusLevelsStreak(props.location.id, [props.date.ref])
            .onSnapshot(
               (result) => {
                  let locationData = {};
                  var capNumber = null;
                  var capCapacity = 100;
                  locationData = props.location;
                  const capSettings = locationData.capacity;
                  const capUnit = locationData.capacity.unit;
                  if (capSettings[capUnit]) {
                     if (capSettings[capUnit].number) {
                        capNumber = capSettings[capUnit].number;
                     } else {
                        capNumber = props.location.people;
                     }
                     if (capSettings[capUnit].capacity) {
                        capCapacity = capSettings[capUnit].capacity;
                     }
                  } else {
                     capNumber = props.location.people;
                  }
                  if (result.empty) {
                     setCapacity({ percent: 0, number: 0 });
                  } else {
                     let resultData = {};

                     result.forEach((doc) => {
                        resultData[doc.id] = doc.data();
                     });
                     let capData = {};
                     let dates = props.date;
                     var day = dates.ref;
                     let number = 0;
                     let pm = null;
                     let split = false;
                     if (
                        !resultData[day] ||
                        (resultData[day] && !resultData[day].routineSaved)
                     ) {
                        if (
                           props.routine &&
                           props.routine[dates.weekDay].ito &&
                           today.ref < dates.ref
                        ) {
                           number = number + props.routine[dates.weekDay].ito;
                        }
                        if (
                           resultData[day] &&
                           resultData[day].overridden &&
                           resultData[day].overridden.ito
                        ) {
                           number = number - resultData[day].overridden.ito;
                        }
                     }
                     if (resultData[day]) {
                        if (
                           resultData[day].ito !== undefined &&
                           resultData[day].ito !== 0
                        ) {
                           number = number + resultData[day].ito;
                        }
                        if (
                           (resultData[day]?.AM?.ito !== undefined &&
                              resultData[day]?.AM?.ito !== 0) ||
                           (resultData[day]?.PM?.ito !== undefined &&
                              resultData[day]?.PM?.ito !== 0)
                        ) {
                           split = true;
                           pm = number;
                           if (
                              resultData[day].AM.ito !== undefined &&
                              resultData[day].AM.ito !== 0
                           ) {
                              number = number + resultData[day].AM.ito;
                           }
                           if (
                              resultData[day].PM.ito !== undefined &&
                              resultData[day].PM.ito !== 0
                           ) {
                              pm = pm + resultData[day].PM.ito;
                           }
                        }
                     }
                     if (props.adjustments) {
                        if (split) {
                           number = number + props.adjustments.AM;
                           pm = pm + props.adjustments.PM;
                        } else {
                           number = number + props.adjustments.AM;
                        }
                     }
                     if (number !== 0 || split) {
                        if (props.location.people === 1 && number === 1) {
                           capData = { number, percent: 100, exceed: false };
                        } else {
                           var maxPercentage = (capCapacity / 100) * capNumber;
                           var percent = (number / maxPercentage) * 100;
                           var pmPercent = null;
                           percent = +percent.toFixed(0);
                           if (pm !== null) {
                              pmPercent = (pm / maxPercentage) * 100;
                              pmPercent = +pmPercent.toFixed(0);
                           }
                           var exceeded = false;
                           if (percent > capCapacity) {
                              exceeded = true;
                           } else if (pmPercent !== null && pmPercent > capCapacity) {
                              exceeded = true;
                           }
                           capData = {
                              number,
                              percent,
                              exceed: exceeded,
                              pm,
                              pmPercent,
                              split,
                           };
                        }
                     } else {
                        capData = { number, percent: 0, exceed: false };
                     }
                     setCapacity(capData);
                  }
               },
               (error) => {
                  console.log(error, 'Error getting capacity');
               },
            );
         return () => loadCapacityNumbers();
      }
   }, [props.location, props.routine, props.date, props.adjustments]);

   return (
      <React.Fragment>
         {capacity.split ? (
            <CapacityGauge capacity={capacity} splitDay />
         ) : (
            <CapacityGauge capacity={capacity} />
         )}
      </React.Fragment>
   );
};

export default withFirebase(DayCapacity);
