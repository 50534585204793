import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import { DateToday, getWeekDates } from '../../../constants/functions';
import { Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material';
import AdminSpaces from './adminSpaces';
const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
   },
}));

const CapBox = (props) => {
   return (
      <Box>
         <Typography paragraph>{props.date}:</Typography>
         <Table>
            <TableHead>
               <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Schedule</TableCell>
                  <TableCell>StatusLevels</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               <TableRow>
                  <TableCell>Ito</TableCell>
                  <TableCell>{props.sched.ito}</TableCell>
                  <TableCell>{props.statusLev.ito}</TableCell>
               </TableRow>
               <TableRow>
                  <TableCell>wfh</TableCell>
                  <TableCell>{props.sched.wfh}</TableCell>
                  <TableCell>{props.statusLev.wfh}</TableCell>
               </TableRow>
               <TableRow>
                  <TableCell>offsite</TableCell>
                  <TableCell>{props.sched.offsite}</TableCell>
                  <TableCell>{props.statusLev.offsite}</TableCell>
               </TableRow>
               <TableRow>
                  <TableCell>away</TableCell>
                  <TableCell>{props.sched.away}</TableCell>
                  <TableCell>{props.statusLev.away}</TableCell>
               </TableRow>
            </TableBody>
         </Table>
         {props.dayScheds &&
            Object.entries(props.dayScheds).map(([key, value]) => {
               return <Typography key={key}>{key}</Typography>;
            })}
         {console.log(props.dayScheds, 'DAYS')}
      </Box>
   );
};

const LocationOverview = (props) => {
   const classes = useStyles();
   const [admins, setAdmins] = useState(false);
   const today = DateToday();
   const [scroll, setScroll] = useState(0);
   const [weekDates, setWeekDates] = useState(getWeekDates(today));
   const [capComp, setCapComp] = useState(false);
   const [dayScheds, setDayScheds] = useState(false);

   useEffect(() => {
      const loadAdmins = async () => {
         if (props.selectedLocation) {
            await props.firebase
               .getUsersInArray(
                  props.selectedLocation.cid,
                  props.selectedLocation.locationAdmins,
               )
               .get()
               .then((result) => {
                  let people = [];
                  result.forEach((doc) => {
                     people.push({ uid: doc.id, ...doc.data() });
                  });
                  setAdmins(people);
               });
         } else {
            setAdmins(false);
         }
      };
      loadAdmins();
   }, [props.selectedLocation]);

   async function checkCapNos() {
      console.log(weekDates, 'days');
      let caps = {};
      let days = {};
      Object.entries(weekDates).map(([key, value]) => {
         caps[value.ref] = {
            sched: {
               ito: 0,
               wfh: 0,
               away: 0,
               offsite: 0,
            },
            statusLevels: {},
         };
      });
      try {
         await props.firebase
            .locationScheduleMultiDay(
               props.selectedLocation.lid,
               weekDates[1].ref,
               weekDates[0].ref,
            )
            .get()
            .then((results) => {
               results.forEach((result) => {
                  if (result.exists) {
                     days[result.id] = result.data();
                     Object.entries(result.data()).map(([key, value]) => {
                        caps[result.id].sched[value.status] =
                           caps[result.id].sched[value.status] + 1;
                     });
                  }
               });
            });
         await props.firebase
            .locationStatusLevels(
               props.selectedLocation.lid,
               weekDates[1].ref,
               weekDates[0].ref,
            )
            .get()
            .then((results) => {
               results.forEach((result) => {
                  if (result.exists) {
                     console.log(result.data());
                     Object.entries(result.data()).map(([key, value]) => {
                        caps[result.id].statusLevels[key] = value;
                     });
                  }
               });
            });
         setDayScheds(days);
         setCapComp(caps);
         console.log(props.selectedLocation, 'selected location');
      } catch (error) {
         console.log(error, 'error getting locSched');
      }
      // console.log(locSched)
   }

   async function changeWeek(direction) {
      let weekDates = [];
      if (direction === -1) {
         let decrease = scroll - 7;
         weekDates = getWeekDates(DateToday(decrease));
         setScroll(decrease);
      }
      if (direction === 1) {
         let increase = scroll + 7;
         weekDates = getWeekDates(DateToday(increase));
         setScroll(increase);
      }
      setWeekDates(weekDates);
   }

   async function fixCapCalcs() {
      await props.firebase
         .locationScheduleMultiDay(
            props.selectedLocation.lid,
            weekDates[1].ref,
            weekDates[0].ref,
         )
         .get()
         .then((results) => {
            results.forEach((result) => {
               if (result.exists) {
                  let cap = {
                     '-': 0,
                     ito: 0,
                     wfh: 0,
                     away: 0,
                     offsite: 0,
                  };
                  if (result.id > today.ref) {
                     cap.overridden = {
                        ito: 0,
                        wfh: 0,
                        away: 0,
                        offsite: 0,
                     };
                  }
                  Object.entries(result.data()).map(([uid, value]) => {
                     if (!value.routine || result.id <= today.ref) {
                        cap[value.status] = cap[value.status] + 1;
                     } else {
                        if (value.overridden) {
                           cap.overridden[value.routine] =
                              cap.overridden[value.routine] + 1;
                           cap[value.status] = cap[value.status] + 1;
                        }
                     }
                  });
                  props.firebase
                     .getStatusLevelsDay(props.selectedLocation.lid, result.id)
                     .update(cap);
               }
            });
         });
   }

   return (
      <Grid container className={classes.container} spacing={3}>
         <Grid item xs={12}>
            <Button onClick={() => props.setSelectedLocation(false)}>Back</Button>
         </Grid>
         <AdminSpaces selectedLocation={props.selectedLocation} />
         <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
               <Button onClick={() => checkCapNos()}>CheckCapNos</Button>
               <Button onClick={() => changeWeek(-1)}>Back</Button>
               <Button onClick={() => changeWeek(1)}>Forward</Button>
               <Button onClick={() => fixCapCalcs()}>FixCapCalcs</Button>
               {capComp && dayScheds && (
                  <React.Fragment>
                     {Object.entries(capComp).map(([date, value]) => (
                        <CapBox
                           key={date}
                           date={date}
                           sched={value.sched}
                           statusLev={value.statusLevels}
                           dayScheds={dayScheds}
                        />
                     ))}
                  </React.Fragment>
               )}
            </Paper>
            <Paper sx={{ p: 3 }}>
               <Typography variant="h5">{props.selectedLocation.name}</Typography>
               <Typography variant="body" paragraph>
                  People: {props.selectedLocation.people}
               </Typography>
            </Paper>
            <Paper sx={{ p: 3 }}>
               <Typography variant="h6">Admins</Typography>
               {admins && (
                  <React.Fragment>
                     {admins.map((admin) => (
                        <React.Fragment key={admin.uid}>
                           <Typography variant="body" paragraph>
                              {admin.name.firstName} {admin.name.lastName}
                           </Typography>
                           <Typography variant="body" paragraph>
                              {admin.email}
                           </Typography>
                        </React.Fragment>
                     ))}
                  </React.Fragment>
               )}
            </Paper>
         </Grid>
      </Grid>
   );
};

export default withFirebase(LocationOverview);
