import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MenuButton = ({ onClick, sx }) => {
   return (
      <IconButton
         edge="start"
         onClick={onClick}
         color="inherit"
         aria-label="menu"
         sx={sx}
      >
         <MenuIcon />
      </IconButton>
   );
};

export default MenuButton;
