import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { withFirebase } from '../Firebase';
import 'firebase/firestore';
import 'date-fns';

import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LogoutIcon from '@mui/icons-material/Logout';
import { DateToday } from '../../constants/functions';
import DetailsDialog from '../Spaces/detailsDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { element } from 'prop-types';

const locales = {
   'en-GB': require('date-fns/locale/en-GB'),
};
const localizer = dateFnsLocalizer({
   format,
   parse,
   startOfWeek,
   getDay,
   locales,
});

function Item(props) {
   const { sx, ...other } = props;
   return (
      <Box
         sx={{
            p: 1,
            m: 1,
            width: '50%',
            bgcolor: '#fff',
            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
            border: '1px solid #342E76',
            borderRadius: 2,
            fontSize: '0.875rem',
            fontWeight: '700',
            textAlign: 'center',
            ...sx,
         }}
         {...other}
      />
   );
}

const date = DateToday();

const SpaceKiosk = (props) => {
   const [events, setEvents] = useState(false);
   const [space, setSpace] = useState(false);
   const [nextEvent, setNextEvent] = useState(null);
   const [currentEvent, setCurrentEvent] = useState(null);
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [spaceSchedule, setSpaceSchedule] = useState(false);
   const [loading, setLoading] = useState(false);
   useEffect(() => {
      if (props.sid && props.lid) {
         const unsubscribe = props.firebase
            .getSpace(props.lid, props.sid)
            .onSnapshot((result) => {
               if (result.exists) {
                  setSpace({ sid: result.id, ...result.data() });
               }
            });
         return () => unsubscribe();
      }
   }, [props.sid, props.lid]);

   useEffect(() => {
      if (space) {
         const unsubscribe = props.firebase
            .getSpaceDaySchedule(props.lid, space.sid, date.ref)
            .onSnapshot((result) => {
               let bookings = [];
               let sched = {};
               let bookingNo = 0;
               if (result.exists && result.data().bookingNo > 0) {
                  bookingNo = result.data().bookingNo;
                  Object.entries(result.data().bookings).map(([key, value]) => {
                     let start = new Date(value.start.seconds * 1000);
                     let end = new Date(value.end.seconds * 1000);
                     let schedStart = value.start;
                     let schedEnd = value.end;
                     if (value.checkInTime && value.start > value.checkInTime) {
                        start = new Date(value.checkInTime.seconds * 1000);
                        schedStart = value.checkInTime;
                     }
                     if (value.checkOutTime && value.end > value.checkOutTime) {
                        end = new Date(value.checkOutTime.seconds * 1000);
                        schedEnd = value.checkOutTime;
                     }
                     sched[key] = value;
                     sched[key].start = schedStart;
                     sched[key].end = schedEnd;
                     bookings.push({
                        ...value,
                        id: key,
                        start,
                        end,
                        saved: true,
                     });
                  });
               }
               setSpaceSchedule({ bookingNo, bookings: sched });
               setEvents({ bookings });
            });
         return () => unsubscribe();
      }
   }, [space]);

   useEffect(() => {
      if (events) {
         const interval = setInterval(() => {
            setCurrentEvent(null);
            events.bookings.map((event) => {
               if (
                  event.start < new Date() &&
                  event.end > new Date() &&
                  !event.checkedOut
               ) {
                  setCurrentEvent(event);
               } else if (
                  event.checkedIn &&
                  event.end > new Date() &&
                  !event.checkedOut
               ) {
                  setCurrentEvent(event);
               }
            });
            let upcoming = events.bookings.sort((a, b) => a.start - b.start);
            var found = upcoming.find(function (element) {
               return (
                  (element.start > new Date() &&
                     element.end > new Date() &&
                     !element.checkedIn) ||
                  null
               );
            });
            if (found) {
               setNextEvent(found);
            } else {
               setNextEvent(null);
            }
            setLoading(false);
         }, 1000);
         return () => clearInterval(interval);
      }
   }, [events]);

   function EventAgenda({ event }) {
      return (
         <span>
            <span style={{ color: 'white' }}>
               {event.host.name} - <em> {event.seats} seats</em> - {event.title}
            </span>
         </span>
      );
   }

   function makeMins(time) {
      time = ('0' + time).slice(-2);
      return time;
   }

   let components = {
      day: {
         event: EventAgenda, // with the agenda view use a different component to render events
      },
      month: {
         event: EventAgenda,
      },
   };

   const handleCheck = async (bookingId, event) => {
      setLoading(true);
      if (event === 'in') {
         try {
            await props.firebase.spaceCheckIn(props.lid, props.sid, date.ref, bookingId);
         } catch (error) {
            console.log(error, 'error');
         }
      } else {
         try {
            await props.firebase.spaceCheckOut(props.lid, props.sid, date.ref, bookingId);
         } catch (error) {
            console.log(error, 'error');
         }
      }
   };

   return (
      <React.Fragment>
         <DetailsDialog
            setDetailsOpen={setDetailsOpen}
            detailsOpen={detailsOpen}
            date={date}
            space={space}
            location={props.location}
            cid={props.cid}
            kiosk={true}
            spaceSchedule={spaceSchedule}
            detailsData={{ edit: true }}
         />
         <Grid item sm={2}></Grid>
         <Grid item xs={12} sm={8}>
            <Typography variant="h2" component="h1" align="center">
               {space.name}
            </Typography>
            <Typography variant="h4" component="h2" align="center">
               {date.dayName + ', ' + date.day + ' ' + date.monthName + ' ' + date.year}
            </Typography>
         </Grid>
         <Grid item xs={12} sm={2}>
            <Button onClick={() => setDetailsOpen(true)}>Create a booking</Button>
         </Grid>
         <Box
            noValidate
            component="form"
            sx={{
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'flex-start',
               m: 'auto',
               width: '100%',
            }}
         >
            {events && (
               <React.Fragment>
                  <Item>
                     <Calendar
                        // selectable
                        views={{ month: true, work_week: true, day: true }}
                        defaultDate={new Date()}
                        defaultView={Views.DAY}
                        localizer={localizer}
                        events={events.bookings}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        // onSelecting={onSelecting}
                        components={components}
                        toolbar={false}
                        dayLayoutAlgorithm="no-overlap"
                        scrollToTime={new Date()}
                        step={15}
                        eventPropGetter={(event) => {
                           const backgroundColor = event.saved ? '#342E76' : '#32BFC7';
                           // const color = event.saved ? 'white': '#342E76';
                           return { style: { backgroundColor, cursor: 'default' } };
                        }}
                        // min={minTime}
                        // max={maxTime}
                     />
                  </Item>
                  <Item sx={{ border: 'none' }}>
                     {currentEvent !== null && (
                        <Paper
                           sx={{
                              p: 2,
                              mb: 2,
                              borderLeft: 8,
                              borderColor: '#32BFC7',
                              backgroundColor: '#D6F2F4',
                           }}
                        >
                           <Typography align="left" variant="subtitle1">
                              Current booking:
                           </Typography>
                           {currentEvent.title !== '' && (
                              <Typography
                                 sx={{ pb: 2, color: '#342E76' }}
                                 variant="h5"
                                 component="h3"
                              >
                                 {currentEvent.title}
                              </Typography>
                           )}
                           <Divider sx={{ mb: 2 }} />
                           <Grid container item xs={12}>
                              <Grid item xs={6}>
                                 <Typography variant="subtitle2">
                                    {`${currentEvent.start.getHours()}:${makeMins(
                                       currentEvent.start.getMinutes(),
                                    )} - ${currentEvent.end.getHours()}:${makeMins(
                                       currentEvent.end.getMinutes(),
                                    )} | Host: ${currentEvent.host.name}`}
                                 </Typography>
                                 <Typography paragraph variant="subtitle2">
                                    People: {currentEvent.seats}
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 {!currentEvent.checkedIn ? (
                                    <Button
                                       align="center"
                                       variant="contained"
                                       size="large"
                                       startIcon={<CheckIcon />}
                                       onClick={() => handleCheck(currentEvent.id, 'in')}
                                       color="primary"
                                       disabled={loading}
                                    >
                                       Check in
                                    </Button>
                                 ) : (
                                    <Button
                                       align="center"
                                       variant="contained"
                                       size="large"
                                       endIcon={<LogoutIcon />}
                                       onClick={() => handleCheck(currentEvent.id, 'out')}
                                       color="primary"
                                       disabled={loading}
                                    >
                                       Check out
                                    </Button>
                                 )}
                                 {loading && (
                                    <CircularProgress
                                       size={24}
                                       sx={{
                                          color: green[500],
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          marginTop: '-12px',
                                          marginLeft: '-12px',
                                       }}
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        </Paper>
                     )}
                     <Paper
                        sx={{
                           p: 2,
                           borderLeft: 8,
                           borderColor: '#342E76',
                           backgroundColor: '#D6D5E4',
                        }}
                     >
                        <Typography align="left" variant="subtitle1">
                           Upcoming booking:
                        </Typography>
                        {nextEvent && nextEvent.title !== '' && (
                           <Typography
                              sx={{ pb: 2, color: '#342E76' }}
                              variant="h5"
                              component="h3"
                           >
                              {nextEvent.title}
                           </Typography>
                        )}
                        <Divider sx={{ mb: 2 }} />
                        <Grid container item xs={12}>
                           {nextEvent !== null ? (
                              <React.Fragment>
                                 <Grid item xs={6}>
                                    <Typography variant="subtitle2">
                                       {`${nextEvent.start.getHours()}:${makeMins(
                                          nextEvent.start.getMinutes(),
                                       )} - ${nextEvent.end.getHours()}:${makeMins(
                                          nextEvent.end.getMinutes(),
                                       )} | Host: ${nextEvent.host.name}`}
                                    </Typography>
                                    <Typography paragraph variant="subtitle2">
                                       People: {nextEvent.seats}
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6}>
                                    {!nextEvent.checkedIn && (
                                       <Button
                                          align="center"
                                          variant="outlined"
                                          size="large"
                                          startIcon={<CheckIcon />}
                                          onClick={() => handleCheck(nextEvent.id, 'in')}
                                       >
                                          Check in
                                       </Button>
                                    )}
                                 </Grid>
                              </React.Fragment>
                           ) : (
                              <Grid item xs={12}>
                                 <Typography align="center">
                                    There are no more bookings today
                                 </Typography>
                              </Grid>
                           )}
                        </Grid>
                     </Paper>
                  </Item>
               </React.Fragment>
            )}
         </Box>
      </React.Fragment>
   );
};

export default withFirebase(SpaceKiosk);
