import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, Dialog } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import 'date-fns';
import { withFirebase } from '../../../Firebase';
import DetailsDialog from '../../../Spaces/detailsDialog';
import HourlyCards from './hourlyCards';
import { AuthProvider } from '../../../../providers';

const SpacesDay = (props) => {
   const [loading, setLoading] = useState(true);
   const [spaces, setSpaces] = useState(false);
   const [events, setEvents] = useState(false);
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [detailsData, setDetailsData] = useState(false);
   const [spaceSchedule, setSpaceSchedule] = useState(false);
   const [kiosk, setKiosk] = useState(false);
   const [bookingForm, setBookingForm] = useState(false);
   useEffect(() => {
      if (props.tab === 2 && props.displayFilter) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .spaces(lid)
            .where('bookable', '==', true)
            .where('timePeriod.hourly', '==', true)
            .where('archive', '==', false)
            .onSnapshot((results) => {
               let spacesData = [];
               if (!results.empty) {
                  results.forEach((result) => {
                     spacesData.push({ sid: result.id, ...result.data() });
                  });
               }
               spacesData.sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
               );
               setSpaces(spacesData);
               setLoading(false);
            });
         return () => unsubscribe();
      }
   }, [props.tab, props.displayFilter]);
   useEffect(() => {
      const authUser = JSON.parse(localStorage.getItem('authUser'));
      if (authUser && authUser?.roles?.ADMIN === 'ADMIN') {
         setKiosk(true);
      } else {
         setBookingForm((prevBookingForm) => ({
            ...prevBookingForm,
            user: { ...authUser },
         }));
      }
   }, []);

   useEffect(() => {
      if (spaces && props.day) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .spaceBookings(lid, props.day.ref)
            .onSnapshot((result) => {
               let entries = {};
               let sched = {};
               spaces.map((space) => {
                  entries[space.sid] = [];
                  sched[space.sid] = {};
                  if (
                     result.exists &&
                     result.data()[space.sid] &&
                     result.data()[space.sid].bookingNo > 0
                  ) {
                     Object.entries(result.data()[space.sid].bookings).map(
                        ([bid, book]) => {
                           let start = new Date(book.start.seconds * 1000);
                           let end = new Date(book.end.seconds * 1000);
                           entries[space.sid].push({
                              ...book,
                              id: bid,
                              start,
                              end,
                              saved: true,
                           });
                        },
                     );
                     sched[space.sid] = result.data()[space.sid];
                  }
               });
               setSpaceSchedule(sched);
               setEvents(entries);
            });
         return () => {
            unsubscribe();
            setSpaceSchedule(false);
            setEvents(false);
         };
      }
   }, [spaces, props.displayFilter, props.tab, props.day]);

   const handleClose = () => {
      setDetailsOpen(false);
      // setDefaultDate(false);
      // setEvents(false);
      // setSpaceBookings(false);
      // setForm(false);
      // setError({ error: false, message: '' });
   };

   return (
      <AuthProvider>
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               justifyContent: 'flex-start',
               alignItems: 'center',
               overflow: 'auto',
            }}
         >
            {loading ? (
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: '50vh',
                  }}
               >
                  <CircularProgress />
               </Box>
            ) : (
               <Box>
                  {spaces.length === 0 ? (
                     <Typography>There are no Spaces</Typography>
                  ) : (
                     <Box sx={{ display: 'flex' }}>
                        <DetailsDialog
                           setDetailsOpen={setDetailsOpen}
                           detailsOpen={detailsOpen}
                           date={detailsData.date}
                           space={detailsData.space}
                           location={detailsData.location}
                           cid={detailsData.cid}
                           kiosk={kiosk}
                           bookingForm={bookingForm}
                           spaceSchedule={detailsData.spaceSchedule}
                           existing={detailsData.existing}
                           booking={detailsData.booking}
                           detailsData={detailsData}
                           setDetailsData={setDetailsData}
                        />
                        {props.day &&
                           spaces.map((space) => (
                              <HourlyCards
                                 key={space.sid}
                                 space={space}
                                 setDetailsData={setDetailsData}
                                 setDetailsOpen={setDetailsOpen}
                                 displayFilter={props.displayFilter}
                                 day={props.day}
                                 spaceSchedule={spaceSchedule[space.sid]}
                                 events={events[space.sid]}
                                 setBookingForm={setBookingForm}
                              />
                           ))}
                     </Box>
                  )}
               </Box>
            )}
         </Box>
      </AuthProvider>
   );
};

export default withFirebase(SpacesDay);
