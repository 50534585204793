import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { PageLayout } from '../layout';
import {
   useMsal,
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
} from '@azure/msal-react';

const LoggingOut = (props) => {
   useEffect(() => {
      setTimeout(function () {
         props.firebase.doSignOut();
      }, 4000);
   }, [props.firebase]);
   return (
      <Grid item xs={12}>
         <CircularProgress align="center" />
         <Typography variant="body1" align="center">
            ... logging you out
         </Typography>
      </Grid>
   );
};

function signOutClickHandler(instance, accounts) {
   const logoutRequest = {
      account: instance.getAccountByHomeId(
         'edc1ec85-2b33-4236-8483-ae9a60e22f1a.711d3d07-f956-4c8d-929a-2778077faa26',
      ),
      postLogoutRedirectUri: window.location.href,
   };
   instance.logoutRedirect(logoutRequest);
}

const LogOut = (props) => {
   const { instance, accounts } = useMsal();
   var msTeams = false;
   if (
      window.location.hostname.includes('ngrok.io') ||
      window.location.hostname.includes('msteams')
   ) {
      msTeams = true;
   }
   if (msTeams) {
      return (
         <PageLayout>
            <Grid
               style={{ height: '80vh', marginLeft: '-40px' }}
               alignItems="center"
               justify="center"
               align="center"
               direction="row"
               container
            >
               <LoggingOut firebase={props.firebase} />
            </Grid>
         </PageLayout>
      );
   } else {
      return (
         <PageLayout>
            <Grid
               style={{ height: '80vh', marginLeft: '-40px' }}
               alignItems="center"
               justify="center"
               align="center"
               direction="row"
               container
            >
               <AuthenticatedTemplate>
                  <Grid item xs={12}>
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                           props.firebase.doSignOut();
                        }}
                     >
                        Logout of intheOffice
                     </Button>
                     <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                           signOutClickHandler(instance, accounts);
                        }}
                     >
                        Logout of intheOffice and Microsoft
                     </Button>
                  </Grid>
               </AuthenticatedTemplate>
               <UnauthenticatedTemplate>
                  <LoggingOut firebase={props.firebase} />
               </UnauthenticatedTemplate>
            </Grid>
         </PageLayout>
      );
   }
};

const LogOutPage = compose(withRouter, withFirebase)(LogOut);

const condition = (authUser) => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition))(LogOutPage);
