import firebaseField from 'firebase/app';

const arrayUnion = (value) => firebaseField.firestore.FieldValue.arrayUnion(value);
const arrayRemove = (value) => firebaseField.firestore.FieldValue.arrayRemove(value);
const increment = (value) => firebaseField.firestore.FieldValue.increment(value);
const deleteField = firebaseField.firestore.FieldValue.delete();

export async function returnNewGroupIndex(newGroupIndex, groupMembers, added = false) {
   Object.keys(groupMembers).map(async (memberUid) => {
      if (added) {
         if (newGroupIndex[memberUid]) {
            newGroupIndex[memberUid] += 1;
         } else {
            newGroupIndex[memberUid] = 1;
         }
      } else {
         if (newGroupIndex[memberUid] && newGroupIndex[memberUid] > 1) {
            newGroupIndex[memberUid] -= 1;
         } else {
            delete newGroupIndex[memberUid];
         }
      }
   });
   return newGroupIndex;
}
export async function processAdminChange(
   firebase,
   uid,
   gid,
   lid,
   cid,
   newAdmins,
   added,
   membersToExecute = false,
) {
   const groupRef = firebase.locationGroupDetails(lid, gid);
   const userCoRef = firebase.getPerson(cid, uid);
   const userRef = firebase.user(uid);
   const userGroupIndexRef = firebase.db.doc(
      `company/${cid}/people/${uid}/index/groupAdmin`,
   );

   try {
      await firebase.db.runTransaction(async (t) => {
         const userResult = await t.get(userCoRef);
         let userUpdate = { groupAdmins: [], roles: { groupAdmin: true } };
         if (userResult.exists) {
            if (userResult.data().groupAdmins) {
               userUpdate.groupAdmins = userResult.data().groupAdmins;
            }
         }
         let groupMembers = {};
         if (!membersToExecute) {
            const groupDets = await t.get(groupRef);
            groupMembers = groupDets.data().members;
         } else {
            groupMembers = membersToExecute;
         }
         const index = await t.get(userGroupIndexRef);
         let newGroupIndex = {};
         if (index.exists) {
            newGroupIndex = index.data();
         }
         newGroupIndex = await returnNewGroupIndex(newGroupIndex, groupMembers, added);
         if (added) {
            userUpdate.groupAdmins.push(gid);
         } else {
            userUpdate.groupAdmins = userUpdate.groupAdmins.filter((g) => g !== gid);
         }
         if (!added && Object.keys(newGroupIndex).length === 0) {
            t.delete(userGroupIndexRef);
            userUpdate.roles.groupAdmin = false;
         } else {
            t.set(userGroupIndexRef, newGroupIndex);
         }
         t.update(groupRef, { admins: newAdmins });
         t.set(userCoRef, userUpdate, { merge: true });
         if (userResult.data().status.statusId !== 'notInvited') {
            t.set(userRef, userUpdate, { merge: true });
         }
      });
   } catch (error) {
      console.warn(error, 'Error making admin change');
   }
}

export async function manageAdminAllocation(
   firebase,
   cid,
   lid,
   gid,
   uidsList,
   admins,
   currentAdmins,
) {
   console.log(firebase, cid, lid, gid, uidsList, admins, '<<< FUNCTION');
   let newAdmins = admins;

   let removed = {};
   let added = {};

   Object.entries(uidsList).map(async ([uid, member]) => {
      if (member.type === 'new') {
         added[uid] = member;
      }
      if (member.type === 'removed') {
         removed[uid] = member;
      }
   });
   await Promise.all(
      Object.keys(admins).map((uid) => {
         const added = Object.keys(currentAdmins).includes(uid);
         return processAdminChange(firebase, uid, gid, lid, cid, newAdmins, added);
      }),
   )
      .then((arr) => console.warn(arr, 'done'))
      .catch((err) => console.warn(err, 'not done'));

   Object.keys(newAdmins).map(async (adminUid) => {
      const userResult = firebase.getPerson(cid, adminUid);
      const userRef = firebase.user(adminUid);
      const userGroupIndexRef = firebase.db.doc(
         `company/${cid}/people/${adminUid}/index/groupAdmin`,
      );
      console.log(added, 'LOADING ADMIN UID');
      try {
         await firebase.db.runTransaction(async (t) => {
            const index = await t.get(userGroupIndexRef);
            const adminUser = await t.get(userResult);
            let newGroupIndex = {};
            if (index.exists) {
               newGroupIndex = index.data();
            }
            newGroupIndex = await returnNewGroupIndex(newGroupIndex, added, true);
            newGroupIndex = await returnNewGroupIndex(newGroupIndex, removed, false);
            console.log(newGroupIndex, 'NEW GROUP INDEX');
            if (Object.keys(newGroupIndex).length === 0) {
               t.delete(userGroupIndexRef);
               t.set(userResult, { roles: { groupAdmin: false } }, { merge: true });
               if (adminUser.data().status.statusId !== 'notInvited') {
                  t.set(userRef, { roles: { groupAdmin: false } }, { merge: true });
               }
            } else {
               t.set(userGroupIndexRef, newGroupIndex);
            }
         });
      } catch (error) {
         console.warn(error, 'Error updating admins memberships');
      }
   });
}

export async function manageMultipleGroupAllocation(
   firebase,
   groups,
   person,
   uid,
   lid,
   cid,
   type,
) {
   let newGroups = [];
   let incr = 1;
   if (type !== 'new') {
      incr = -1;
   }

   await Promise.all(
      groups.map(async (gid) => {
         const groupRef = await firebase.locationGroupDetails(lid, gid);
         const groupIndexRef = await firebase.db.doc(`location/${lid}/indexes/groups`);
         const userRef = await firebase.getPerson(cid, uid);
         let userGid = { gid: arrayUnion(gid) };
         let groupMem = { [uid]: { name: person.name } };
         if (type === 'removed') {
            userGid = { gid: arrayRemove(gid) };
            groupMem = { [uid]: deleteField };
         }

         try {
            await firebase.db.runTransaction(async (t) => {
               const groupDets = await t.get(groupRef);
               if (groupDets.exists) {
                  newGroups.push({ gid, ...groupDets.data() });

                  if (
                     groupDets.data().admins &&
                     Object.keys(groupDets.data().admins).length > 0
                  ) {
                     await manageAdminAllocation(
                        firebase,
                        cid,
                        lid,
                        gid,
                        { [uid]: { uid, type, ...person } },
                        groupDets.data().admins,
                     );
                  }
               }
               t.set(userRef, userGid, { merge: true });
               t.set(
                  groupIndexRef,
                  {
                     [gid]: {
                        count: increment(incr),
                     },
                  },
                  { merge: true },
               );
               t.set(
                  groupRef,
                  {
                     members: { ...groupMem },
                     count: increment(incr),
                  },
                  { merge: true },
               );
            });
         } catch (error) {
            console.warn(error, 'Error managing group allocations');
         }
      }),
   );
}
