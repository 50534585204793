import React from 'react';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   DialogContentText,
   Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const GroupAddVideoHelper = () => {
   return (
      <Accordion elevation={0} sx={{ border: '1px solid #ddd' }}>
         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display={'flex'} gap={2} alignItems={'center'}>
               <OndemandVideoIcon />
               Need help? We have you covered
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <Grid container spacing={3}>
               <Grid item xs={12} sm={6} md={6}>
                  <DialogContentText>
                     Sint Lorem irure minim do esse nostrud. Qui velit cillum dolor
                     tempor. Cillum ipsum quis ut veniam. Incididunt in amet cupidatat
                     aute occaecat culpa eiusmod ullamco do cillum aute laborum.
                  </DialogContentText>
               </Grid>

               <Grid item xs={12} sm={6} md={6}>
                  {/* <iframe
                     width="100%"
                     height="250"
                     src="https://www.youtube.com/embed/n1C4igoCbqk?si=B3KyNcFy0YZZ4YPg"
                     title="YouTube video player"
                     frameborder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     referrerpolicy="strict-origin-when-cross-origin"
                     allowfullscreen
                  ></iframe> */}
               </Grid>
            </Grid>
         </AccordionDetails>
      </Accordion>
   );
};

export default GroupAddVideoHelper;
