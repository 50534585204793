import React, { useState, useEffect, forwardRef } from 'react';
import { Autocomplete, Box, Button, Paper, Typography } from '@mui/material';
import { withFirebase } from '../Firebase';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { TextField } from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
const Alert = forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewVisitorForm = ({ cid, formType, existingVisitor = false, ...props }, ref) => {
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const baseForm = {
      name: '',
      email: '',
      host: {
         uid: authUser.uid,
         name: `${authUser.name.firstName} ${authUser.name.lastName}`,
         email: authUser.email,
         label: `${authUser.name.firstName} ${authUser.name.lastName}`,
      },
      owner: {
         uid: authUser.uid,
         name: `${authUser.name.firstName} ${authUser.name.lastName}`,
         email: authUser.email,
         label: `${authUser.name.firstName} ${authUser.name.lastName}`,
      },
      arrivalTime: null,
      departureTime: null,
   };
   const [form, setForm] = useState(baseForm);
   const [emailError, setEmailError] = useState({ error: false, message: '' });
   const [nameError, setNameError] = useState({ error: false, message: '' });
   const [open, setOpen] = useState(false);
   const [hosts, setHosts] = useState(null);

   useEffect(() => {
      const fetchHosts = async () => {
         if (authUser?.roles?.ADMIN) {
            try {
               const hostList = await props.firebase.db
                  .doc(`company/${cid}/indexes/people`)
                  .get();
               let data = [];
               Object.entries(hostList.data()).forEach(([key, value]) => {
                  data.push({
                     uid: key,
                     label: `${value.name.firstName} ${value.name.lastName}`,
                     ...value,
                  });
               });
               data.sort((a, b) => {
                  const textA = a.name.lastName.toUpperCase();
                  const textB = b.name.lastName.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
               });
               setHosts(data);
            } catch (error) {
               console.error('Failed to fetch host list:', error);
            }
         }
      };

      fetchHosts();
   }, []);

   async function handleSubmit() {
      var emailValid = EmailValidator.validate(form.email); // true
      if (!emailValid) {
         setEmailError({ error: true, message: 'Please enter a valid email address' });
      } else if (form.email in props.dayVisitors) {
         setEmailError({
            error: true,
            message: 'This email address is already registered as a visitor',
         });
      } else {
         setEmailError({ error: false, message: '' });
      }
      if (form.name === '') {
         setNameError({ error: true, message: 'Please enter a name' });
      } else {
         setNameError({ error: false, message: '' });
      }
      if (emailValid && form.name !== '' && !(form.email in props.dayVisitors)) {
         let data = {
            [form.email.toLowerCase()]: {
               name: form.name,
               host: {
                  uid: form.host.uid,
                  name: form.host.label,
                  email: form.host.email,
               },
               owner: {
                  uid: form.owner.uid,
                  name: form.owner.label,
                  email: form.owner.email,
               },
               unplanned: false,
               arrivalTime: form.arrivalTime,
               departureTime: form.departureTime,
            },
         };
         try {
            await props.firebase.addVisitor(
               props.cid,
               props.lid,
               form.host.uid,
               props.date,
               data,
            );
            setForm(baseForm);
            setOpen(true);
            props.setCollapsed(false);
         } catch (error) {
            console.warn(error, 'error');
         }
      }
   }
   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen(false);
   };

   return (
      <Paper sx={{ p: 4, maxWidth: '500px' }}>
         <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
         >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
               Visitor successfully added
            </Alert>
         </Snackbar>
         <Typography variant="h6">Add a new Visitor</Typography>
         <TextField
            required
            error={nameError.error}
            helperText={nameError.message}
            label="Visitor Name"
            name="name"
            value={form.name}
            fullWidth
            onChange={(e) => setForm({ ...form, name: e.target.value })}
         />
         <TextField
            required
            error={emailError.error}
            helperText={emailError.message}
            label="Visitor Email"
            name="email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            fullWidth
         />
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
               label="Expected arrival"
               value={form.arrivalTime}
               ampm={false}
               onChange={(newValue) => {
                  setForm({ ...form, arrivalTime: newValue });
               }}
               renderInput={(params) => (
                  <TextField fullWidth sx={{ mb: 2 }} {...params} />
               )}
            />
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
               label="Expected departure"
               value={form.departureTime}
               ampm={false}
               onChange={(newValue) => {
                  setForm({ ...form, departureTime: newValue });
               }}
               renderInput={(params) => (
                  <TextField fullWidth sx={{ mb: 2 }} {...params} />
               )}
            />
         </LocalizationProvider>
         {authUser?.roles?.ADMIN && hosts !== null ? (
            <Autocomplete
               id="host"
               options={hosts}
               autoSelect
               isOptionEqualToValue={(option, value) => option.uid === value.uid}
               getOptionLabel={(option) => option.label}
               value={form.host}
               onChange={(e, newValue) => {
                  setForm({ ...form, host: newValue });
               }}
               renderInput={(params) => <TextField {...params} label="Host" />}
            />
         ) : (
            <Typography paragraph sx={{ pt: 2, pb: 2 }}>
               Host: {form.host.name}
            </Typography>
         )}
         <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Button variant="contained" onClick={() => handleSubmit()}>
               Save
            </Button>
            <Button
               onClick={() => {
                  setForm(baseForm);
                  props.setCollapsed(false);
                  setNameError({ error: false, message: '' });
                  setEmailError({ error: false, message: '' });
               }}
            >
               Cancel
            </Button>
         </Box>
      </Paper>
   );
};

export default withFirebase(NewVisitorForm);
