import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { getLocation, getUser } from '../firebaseRefs';

const CoCardLocations = ({ lid, cid, setSignedUpDetails }) => {
   const [location, setLocation] = useState({
      name: '',
      adminName: '',
      adminEmail: '',
      adminsNo: 1,
      peopleNo: 0,
   });
   useEffect(() => {
      const getData = async () => {
         let data = {};
         const loc = await getLocation(lid);
         data.name = loc.data().name;
         data.adminsNo = loc.data().locationAdmins.length;
         data.peopleNo = loc.data().people;
         const admin = await getUser(cid, loc.data().locationAdmins[0]);
         data.adminName = `${admin.data().name.firstName} ${admin.data().name.lastName}`;
         data.adminEmail = admin.data().email;
         setLocation(data);
         setSignedUpDetails({ adminName: data.adminName, adminEmail: data.adminEmail });
      };

      getData();
   }, []);
   return (
      <Box sx={{ mt: 1 }}>
         <Divider />
         <Typography>{location.name}</Typography>
         <Typography>People:{location.peopleNo}</Typography>
         <Typography>Admins:{location.adminsNo}</Typography>
      </Box>
   );
};
export default CoCardLocations;
