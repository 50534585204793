import React, { useState, useEffect, useMemo } from 'react';
import {
   Box,
   Button,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from '@mui/material';
import { withFirebase } from '../Firebase';
import { ExportToCsv } from 'export-to-csv';
import { uid } from 'uid';

const RoutineExport = (props) => {
   const [locPeople, setLocPeople] = useState(false);
   const [locRoutes, setLocRoutes] = useState(false);
   const [exportData, setExportData] = useState(false);
   const [exportHeaders, setExportHeaders] = useState(false);
   const [exportDisabled, setExportDisabled] = useState(true);

   useEffect(() => {
      if (props.tab === 2 && props.location) {
         props.firebase
            .locationPeopleIndex(props.location.lid)
            .get()
            .then((result) => {
               setExportData(false);
               setExportDisabled(true);
               setLocRoutes(false);
               if (result.exists) {
                  let ppl = [];
                  Object.entries(result.data()).map(([key, value]) => {
                     ppl.push({ uid: key, ...value });
                  });
                  ppl.sort((a, b) =>
                     a.name.firstName.toLowerCase() > b.name.firstName.toLowerCase()
                        ? 1
                        : -1,
                  );
                  setLocPeople(ppl);
               } else {
                  setLocPeople(false);
               }
            });
      }
   }, [props.tab, props.location]);

   useEffect(() => {
      if (locPeople) {
         const unsubscribe = props.firebase
            .companyPeople(props.location.cid)
            .where('lid', 'array-contains', props.location.lid)
            .onSnapshot((users) => {
               if (!users.empty) {
                  let routine = {};
                  users.forEach((user) => {
                     routine[user.id] = {
                        0: '-',
                        1: '-',
                        2: '-',
                        3: '-',
                        4: '-',
                        5: '-',
                        6: '-',
                     };
                     if (user.data().routine) {
                        routine[user.id] = user.data().routine;
                     }
                  });
                  let file = [];
                  let route = [];
                  let headers = [
                     'Name',
                     'Monday',
                     'Tuesday',
                     'Wednesday',
                     'Thursday',
                     'Friday',
                     'Saturday',
                     'Sunday',
                  ];

                  locPeople.map((person) => {
                     file.push({
                        0: `${person.name.firstName} ${person.name.lastName}`,
                        1: `${routine[person.uid][1]}`,
                        2: `${routine[person.uid][2]}`,
                        3: `${routine[person.uid][3]}`,
                        4: `${routine[person.uid][4]}`,
                        5: `${routine[person.uid][5]}`,
                        6: `${routine[person.uid][6]}`,
                        7: `${routine[person.uid][0]}`,
                     });
                     route.push({
                        name: `${person.name.firstName} ${person.name.lastName}`,
                        uid: person.uid,
                        1: `${routine[person.uid][1]}`,
                        2: `${routine[person.uid][2]}`,
                        3: `${routine[person.uid][3]}`,
                        4: `${routine[person.uid][4]}`,
                        5: `${routine[person.uid][5]}`,
                        6: `${routine[person.uid][6]}`,
                        0: `${routine[person.uid][0]}`,
                     });
                  });
                  setExportHeaders(headers);
                  setExportData(file);
                  setExportDisabled(false);
                  setLocRoutes(route);
               } else {
                  setLocRoutes(false);
               }
            });
         return () => unsubscribe();
      }
   }, [locPeople]);

   const dataToExport = useMemo(() => exportData, [exportData]);
   const dataExportId = useMemo(() => uid(16), []); // create a unique and identifyable ID for each file downloaded

   const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: `ITO-routine-export-${dataExportId}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: exportHeaders,
   };

   const csvExporter = new ExportToCsv(options);

   return (
      <Box>
         <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
            <Button
               variant="contained"
               onClick={() => csvExporter.generateCsv(dataToExport)}
               disabled={exportDisabled}
            >
               Export
            </Button>
         </Box>
         <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
            <Table
               sx={{ minWidth: 650 }}
               size="small"
               aria-label="Export Table"
               stickyHeader
            >
               <TableHead>
                  <TableRow>
                     <TableCell>Name</TableCell>
                     <TableCell>Monday</TableCell>
                     <TableCell>Tuesday</TableCell>
                     <TableCell>Wednesday</TableCell>
                     <TableCell>Thursday</TableCell>
                     <TableCell>Friday</TableCell>
                     <TableCell>Saturday</TableCell>
                     <TableCell>Sunday</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {locRoutes &&
                     locRoutes.map((person) => (
                        <TableRow key={person.uid}>
                           <TableCell>{person.name}</TableCell>
                           <TableCell>{person[1]}</TableCell>
                           <TableCell>{person[2]}</TableCell>
                           <TableCell>{person[3]}</TableCell>
                           <TableCell>{person[4]}</TableCell>
                           <TableCell>{person[5]}</TableCell>
                           <TableCell>{person[6]}</TableCell>
                           <TableCell>{person[0]}</TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Box>
   );
};

export default withFirebase(RoutineExport);
