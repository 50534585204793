import React from 'react';
import SettingsPage from './SettingsPage';
import { AuthProvider, CompanyProvider } from '../../providers';

const Settings = () => {
   return (
      <AuthProvider>
         <CompanyProvider>
            <SettingsPage />
         </CompanyProvider>
      </AuthProvider>
   );
};

export default Settings;
