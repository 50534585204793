import { useContext } from 'react';
import { FirebaseContext } from './context';

const useFirebase = () => {
   const context = useContext(FirebaseContext);

   if (!context) {
      throw new Error('You are trying to use the Firebase hook outside the context tree');
   }
   return context;
};

export default useFirebase;
