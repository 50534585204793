import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import { AuthProvider, CompanyProvider } from '../../providers';
import PeoplePage from './PeoplePage';

const People = (props) => {
   return (
      <React.Fragment>
         <AuthProvider>
            <CompanyProvider>
               <PeoplePage />
            </CompanyProvider>
         </AuthProvider>
      </React.Fragment>
   );
};

const PeopleDirectoryPage = compose(withRouter, withFirebase)(People);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(PeopleDirectoryPage);
