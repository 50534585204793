import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { withFirebase } from '../Firebase';
import { ListItemIcon, useMediaQuery } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InteractionDrawer from '../Interactions';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import BoltIcon from '@mui/icons-material/Bolt';
import { Box, IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
}));

function DateToday(day) {
   var date = new Date();
   date.setDate(date.getDate() + day);
   const d = ('0' + date.getDate()).slice(-2);
   const m = ('0' + date.getMonth()).slice(-2);
   const today = {
      day: d,
      month: m,
      year: date.getFullYear(),
      weekDay: date.getDay(),
      ref: date.getFullYear() + m + d,
   };
   return today;
}

const StyledMenu = withStyles({
   paper: {
      border: '1px solid #d3d4d5',
   },
})((props) => (
   <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
      }}
      {...props}
   />
));

const QuickActions = (props) => {
   const classes = useStyles();
   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

   const today = DateToday(0);
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [visitorsDrawer, setVisitorsDrawer] = useState(false);
   const [showDialog, setShowDialog] = useState(false);
   const [type, setType] = useState(null);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const isMobile = useMediaQuery('(max-width: 900px)');

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   return (
      <Box>
         <InteractionDrawer
            visitorsDrawer={visitorsDrawer}
            setVisitorsDrawer={setVisitorsDrawer}
            type={type}
            setType={setType}
            location={props.location}
            displayFilter={props.displayFilter}
         />

         {props.schedule && isMobile ? (
            <IconButton color="primary" aria-label="Actions" onClick={handleClick}>
               <BoltIcon />
            </IconButton>
         ) : (
            <Button
               aria-describedby={id}
               variant="outlined"
               color="primary"
               size="small"
               startIcon={<BoltIcon />}
               onClick={handleClick}
               fullWidth
            >
               Actions
            </Button>
         )}
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
         >
            <StyledMenu
               id="simple-menu"
               anchorEl={anchorEl}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
            >
               <MenuItem
                  onClick={() => {
                     handleClose();
                     setType('requests');
                     setVisitorsDrawer(true);
                  }}
               >
                  <ListItemIcon>
                     <HowToRegIcon />
                  </ListItemIcon>
                  Request Attendance
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     handleClose();
                     setType('visitors');
                     setVisitorsDrawer(true);
                  }}
               >
                  <ListItemIcon>
                     <PersonAddIcon />
                  </ListItemIcon>
                  Manage Visitors
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     handleClose();
                     setType('meetings');
                     setVisitorsDrawer(true);
                  }}
               >
                  <ListItemIcon>
                     <QuestionAnswerIcon />
                  </ListItemIcon>
                  New Meeting
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     handleClose();
                     setType('register');
                     setVisitorsDrawer(true);
                  }}
               >
                  <ListItemIcon>
                     <AssignmentIcon />
                  </ListItemIcon>
                  Register
               </MenuItem>
            </StyledMenu>
         </Popover>
      </Box>
   );
};

export default withFirebase(QuickActions);
