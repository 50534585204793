import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const OffsiteMiniAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="offsite-mini-am" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M22,0 C24.209139,0 26,1.790861 26,4 L26,12.4168302 C26,12.4168302 0,29 0,29 L0,4 C0,1.790861 1.790861,0 4,0 L22,0 Z M22,1 L4,1 C2.34314575,1 1,2.34314575 1,4 L1,27.175 L1.01139873,27.1688266 C1.49351233,26.8613277 2.18707194,26.4189655 3.26828258,25.7293539 L3.52475431,25.5657726 C7.58725431,22.9746524 7.58725431,22.9746524 12.4622543,19.865308 C17.3372543,16.7559637 17.3372543,16.7559637 21.3997543,14.1648434 C22.3041442,13.5880107 22.734806,13.3133284 23.1150431,13.0708079 L23.3199885,12.9400907 C23.5933661,12.7657266 23.8807811,12.5824093 24.3450668,12.2862812 C24.6916488,12.0652263 24.7956234,11.9989098 24.9400326,11.9068036 L25,11.868 L25,4 C25,2.40231912 23.75108,1.09633912 22.1762728,1.00509269 L22,1 Z M14.6666667,16 L14.6666667,18 L2.75,18 L2.75,16 L14.6666667,16 Z M8.70833333,3 C11.3977083,3 13.5833333,5.05705882 13.5833333,7.58823529 C13.5833333,11.0294118 8.70833333,16 8.70833333,16 C8.70833333,16 3.83333333,11.0294118 3.83333333,7.58823529 C3.83333333,5.05705882 6.01895833,3 8.70833333,3 Z M8.70833333,6 C7.81458333,6 7.08333333,6.675 7.08333333,7.5 C7.08333333,8.325 7.81458333,9 8.70833333,9 C9.61020833,9 10.3333333,8.325 10.3333333,7.5 C10.3333333,6.675 9.60208333,6 8.70833333,6 Z"
                  id="Rectangle"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default OffsiteMiniAm;
