import firebaseApp from 'firebase/app';
import { DateToday } from './functions';

export function UserLog(firebase, authUser, loc) {
   const today = DateToday();
   const pathname = window.location.pathname;
   const href = window.location.href;
   const search = window.location.search;
   const batch = firebase.db.batch();

   const serverTimestamp = firebaseApp.firestore.FieldValue.serverTimestamp();
   const increment = firebaseApp.firestore.FieldValue.increment(1);
   const uRef = firebase.db.doc(`usageLogs/users`);
   batch.set(
      uRef,
      {
         [authUser.uid]: firebaseApp.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
   );
   const userRef = firebase.db.doc(`usageLogs/users/user/${authUser.uid}`);
   batch.set(
      userRef,
      {
         uid: authUser.uid,
         lastActionTime: serverTimestamp,
         pathname,
         href,
         search,
         totalPageViews: increment,
      },
      { merge: true },
   );
   const userRefDate = firebase.db.doc(
      `usageLogs/users/user/${authUser.uid}/dates/${today.ref}`,
   );

   batch.set(
      userRefDate,
      {
         pageViews: increment,
         [new Date().toISOString().split('T')[1]]: {
            time: serverTimestamp,
            pathname,
            search,
            href,
            // device: {
            //    platform: window.navigator.userAgentData.platform,
            //    ...window.navigator.userAgentData.brands,
            //    mobile: window.navigator.userAgentData.mobile,
            // },
         },
      },
      { merge: true },
   );
   const cosRef = firebase.db.doc(`usageLogs/companies`);
   batch.set(
      cosRef,
      {
         [authUser.cid]: firebaseApp.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
   );
   const coRef = firebase.db.doc(`usageLogs/companies/company/${authUser.cid}`);
   batch.set(
      coRef,
      {
         uid: authUser.uid,
         lastActionTime: serverTimestamp,
         pathname,
         href,
         search,
         totalPageViews: increment,
      },
      { merge: true },
   );
   const coRefDate = firebase.db.doc(
      `usageLogs/companies/company/${authUser.cid}/dates/${today.ref}`,
   );
   batch.set(
      coRefDate,
      {
         totalPageViews: increment,

         [authUser.uid]: {
            [new Date().toISOString().split('T')[1]]: href,
            pageViews: increment,
         },
      },
      { merge: true },
   );
   try {
      batch.commit();
   } catch (err) {
      console.error('Error saving user logs', err);
   }
}
