import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Capacity from './location/capacity';
import Button from '@mui/material/Button';
import Details from './location/details';
import IconButton from '@material-ui/core/IconButton';
import SpacesPanel from './spaces';
import SchedulesPanel from './location/schedule';
import VisitorsPanel from '../Visitors/Settings/accordionBody';
import ReportingPanel from './location/reporting';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { withFirebase } from '../Firebase';
import asyncComponent from '../../constants/AsyncComponent';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { gridSelectionStateSelector } from '@material-ui/data-grid';

const LocationQRCode = asyncComponent(() => import('./location/locationQRCode'));

// import LocationQRCode from './locationQRCode';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(4),
      flexGrow: 1,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      flexDirection: 'column',
      justifyContent: 'center',
   },

   title: {
      backgroundColor: theme.palette.tableHead,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      color: theme.palette.primary.main,
   },
   typeIcon: {
      marginRight: theme.spacing(2),
   },
}));

const EachLocation = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [expanded, setExpanded] = React.useState(false);
   const [locationEditing, setLocationEditing] = useState(false);
   const [locationName, setLocationName] = useState('');
   const [location, setLocation] = useState(false);
   const [dialogOpen, setDialogOpen] = useState(false);
   const [dialogType, setDialogType] = useState(false);
   useEffect(() => {
      const loadLocDetails = async () => {
         if (props.locationKey !== null) {
            props.firebase.location(props.locationKey).onSnapshot((result) => {
               setLocation(result.data());
            });
         }
         return;
      };
      loadLocDetails();
   }, [props.locationKey]);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };
   function editLocation(location) {
      setLocationName(location);
      setLocationEditing(true);
   }
   function noSave() {
      setLocationName('');
      setLocationEditing(false);
   }
   async function saveLocation(lid) {
      async function save() {
         await props.firebase.updateLocationName(user.cid, lid, locationName);
      }
      save()
         .then(function () {
            setLocationEditing(false);
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }
   async function deleteLocation(lid) {
      if (location.people > 0) {
         setDialogType('people');
      } else {
         let spaces = await props.firebase.spaces(lid).get();
         if (!spaces.empty) {
            setDialogType('spaces');
         } else {
            setDialogType(false);
         }
      }
      setDialogOpen(true);
   }

   async function archiveLocation(lid) {
      if (location.people > 0) {
         setDialogType('archiveError');
      } else {
         setDialogType('archive');
      }
      setDialogOpen(true);
   }

   const sections = {
      scheduling: {
         name: 'Scheduling',
         component: (
            <SchedulesPanel
               expanded={expanded}
               locationName={props.locationName}
               lid={props.locationKey}
            />
         ),
      },
      scanIn: {
         name: 'Touchless Scan In',
         component: (
            <LocationQRCode
               locationKey={props.locationKey}
               expanded={expanded}
               locationName={props.locationName}
            />
         ),
      },
      spaces: {
         name: 'Spaces',
         component: (
            <SpacesPanel
               expanded={expanded}
               cid={props.cid}
               locationKey={props.locationKey}
            />
         ),
      },

      // actions: {
      //   name: 'Actions',
      //   component: <ActionsPanel expanded={expanded} location={location} locationKey={props.locationKey} />,
      // },
      visitors: {
         name: 'Visitors',
         component: (
            <VisitorsPanel
               expanded={expanded}
               cid={props.cid}
               location={location}
               locationKey={props.locationKey}
            />
         ),
      },
   };
   const handleClose = () => {
      setDialogOpen(false);
   };
   const handleRemove = async () => {
      try {
         await props.firebase.locationDelete(props.locationKey, location, props.company);
         setDialogOpen(false);
      } catch (error) {
         console.log(error, 'error');
      }
   };
   const toggleArchive = async () => {
      let archive = true;
      if (location.archived) {
         archive = false;
      }
      try {
         await props.firebase.archiveLocation(
            props.company,
            props.locationKey,
            location,
            archive,
         );
         setDialogOpen(false);
      } catch (error) {
         console.log(error, 'error');
      }
   };

   return (
      <React.Fragment key={props.locationKey}>
         <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               Delete {props.locationName}?
            </DialogTitle>
            <DialogContent>
               {dialogType === 'people' ? (
                  <DialogContentText>
                     Before you can delete {props.locationName} you must move/remove all
                     of it's users.
                  </DialogContentText>
               ) : dialogType === 'spaces' ? (
                  <DialogContentText>
                     Please delete all spaces belonging to {props.locationName} before you
                     can delete the location.
                  </DialogContentText>
               ) : dialogType === 'archiveError' ? (
                  <DialogContentText>
                     Please remove all people from {props.locationName} before you can
                     archive it.
                  </DialogContentText>
               ) : dialogType === 'archive' ? (
                  <DialogContentText>
                     Are you sure you want to Archive {props.locationName}?
                  </DialogContentText>
               ) : (
                  <DialogContentText>
                     Are you sure you want to delete this {props.locationName}?
                  </DialogContentText>
               )}
            </DialogContent>
            {dialogType ? (
               <DialogActions>
                  <Button onClick={handleClose} color="primary">
                     close
                  </Button>
                  {dialogType === 'archive' && (
                     <Button
                        onClick={() => toggleArchive()}
                        color="error"
                        variant="contained"
                     >
                        Archive
                     </Button>
                  )}
               </DialogActions>
            ) : (
               <DialogActions>
                  <Button onClick={handleClose} color="primary">
                     Cancel
                  </Button>
                  <Button onClick={handleRemove} color="error" variant="contained">
                     Delete
                  </Button>
               </DialogActions>
            )}
         </Dialog>
         <Grid container item xs={12} className={classes.title}>
            <Grid item xs={10}>
               {!locationEditing ? (
                  <div
                     style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                     }}
                  >
                     {location.virtual ? (
                        <GroupWorkIcon className={classes.typeIcon} />
                     ) : (
                        <LocationCityIcon className={classes.typeIcon} />
                     )}
                     <Typography component="h3" variant="h5">
                        {props.locationName}
                     </Typography>
                     <IconButton onClick={() => editLocation(props.locationName)}>
                        <EditIcon />
                     </IconButton>
                  </div>
               ) : (
                  <React.Fragment>
                     <TextField
                        id="locationName"
                        label="Name"
                        defaultValue={locationName}
                        variant="outlined"
                        size="small"
                        onInput={(e) => setLocationName(e.target.value)}
                     />
                     <IconButton
                        style={{ color: green[500] }}
                        onClick={() => saveLocation(props.locationKey)}
                     >
                        <DoneIcon />
                     </IconButton>
                     <IconButton onClick={() => noSave()}>
                        <CancelIcon />
                     </IconButton>
                  </React.Fragment>
               )}
            </Grid>

            <Grid item xs={2}>
               {location.archived ? (
                  <Button
                     variant="outlined"
                     color="primary"
                     onClick={() => toggleArchive()}
                  >
                     Restore
                  </Button>
               ) : (
                  Object.keys(props.locations).length > 1 && (
                     <Button color="error" onClick={() => archiveLocation()}>
                        Archive
                     </Button>
                  )
               )}
               {/* <Button color="error" onClick={()=>deleteLocation(props.locationKey)}>Delete</Button> */}
            </Grid>
         </Grid>
         <Grid item xs={12}>
            {location && !location.archived && (
               <React.Fragment>
                  <Accordion
                     expanded={expanded === 'details'}
                     onChange={handleChange('details')}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="details-content"
                        id="details-header"
                     >
                        <Typography component="h4" variant="h5">
                           Details
                        </Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Details expanded={expanded} locationKey={props.locationKey} />
                     </AccordionDetails>
                  </Accordion>
                  {location.virtual !== true && (
                     <React.Fragment>
                        <Accordion
                           expanded={expanded === 'capacity'}
                           onChange={handleChange('capacity')}
                        >
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="capacity-content"
                              id="capacity-header"
                           >
                              <Typography component="h4" variant="h5">
                                 Capacity
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Capacity
                                 expanded={expanded}
                                 locationKey={props.locationKey}
                              />
                           </AccordionDetails>
                        </Accordion>

                        {Object.entries(sections).map(([key, value]) => (
                           <React.Fragment key={key}>
                              <Accordion
                                 expanded={expanded === key}
                                 onChange={handleChange(key)}
                              >
                                 <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                 >
                                    <Typography component="h4" variant="h5">
                                       {value.name}
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails>{value.component}</AccordionDetails>
                              </Accordion>
                           </React.Fragment>
                        ))}
                     </React.Fragment>
                  )}
               </React.Fragment>
            )}
         </Grid>
      </React.Fragment>
   );
};
export default withFirebase(EachLocation);
