import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { convertAmenityToIcon } from '../functions';
import Seats from './seats';

const useStyles = makeStyles((theme) => ({
   root: {
      maxWidth: 345,
      width: 250,
      margin: 8,
      display: 'inline-block',
      verticalAlign: 'top',
      whiteSpace: 'normal',
   },
   cardHeader: {
      backgroundColor: theme.palette.cardHeader.main,
   },
}));

const SpaceCardContent = (props) => {
   const classes = useStyles();
   const [bookingForm, setBookingForm] = useState(props.bookingForm);

   useEffect(() => {
      setBookingForm(props.bookingForm);
   }, [props.bookingForm]);

   return (
      <React.Fragment>
         {!props.dense && (
            <CardContent className={classes.cardHeader}>
               {props.space.description !== '' && (
                  <Typography variant="body2" color="textSecondary" component="p">
                     {props.space.description}
                  </Typography>
               )}

               {props.space.amenities.length !== 0 && (
                  <Container>
                     {props.space.amenities.map((amenity) => {
                        return convertAmenityToIcon(amenity);
                     })}
                  </Container>
               )}
               {!props.spaceSchedule && (
                  <Seats time={bookingForm.type} space={props.space} />
               )}
               {props.spaceSchedule && (
                  <React.Fragment>
                     <Typography variant="caption" display="block" color="textSecondary">
                        Capacity: {props.space.seats} seats max
                     </Typography>
                  </React.Fragment>
               )}
            </CardContent>
         )}
      </React.Fragment>
   );
};
export default SpaceCardContent;
