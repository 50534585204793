export function processScheduleStatuses(schedule, users, locUsers, lid) {
   let data = [];
   Object.keys(locUsers).forEach(async (key) => {
      let person = { ...defaultData };
      person.uid = key;
      let value = schedule[key];
      if (users[key]) {
         const user = users[key];
         person.name = `${user.name.firstName} ${user.name.lastName}`;
         person.primaryLocation = user?.lid || null;
      }
      if (value !== undefined) {
         person.statusAllDay = value?.status || null;
         if (value.status === 'split') {
            person.statusAM = value?.splitDay?.AM || null;
            person.statusPM = value?.splitDay?.PM || null;
         }
         person.routine = value?.routine || null;
         person.routineOverridden =
            value.overridden !== undefined ? value.overridden : null;
         if (value.offsiteLocation?.AM || value.offsiteLocation?.PM) {
            if (value.offsiteLocation?.AM) {
               if (value.offsiteLocation.AM.locationName) {
                  person.offsiteLocationAM = value.offsiteLocation.AM.locationName;
               } else {
                  person.offsiteLocationAM = value.offsiteLocation.AM;
               }
            }
            if (value.offsiteLocation?.PM) {
               if (value.offsiteLocation.PM.locationName) {
                  person.offsiteLocationPM = value.offsiteLocation.PM.locationName;
               } else {
                  person.offsiteLocationPM = value.offsiteLocation.PM;
               }
            }
         } else if (value.offsiteLocation?.locationName) {
            person.offsiteLocation = value.offsiteLocation.locationName;
         } else if (value.offsiteLocation) {
            person.offsiteLocation = value.offsiteLocation;
         }
      }
      data.push(person);
   });
   data.sort((a, b) => a.uid.localeCompare(b.uid));
   return data;
}

const defaultData = {
   uid: null,
   name: null,
   primaryLocation: null,
   statusAllDay: null,
   statusAM: null,
   statusPM: null,
   routine: null,
   routineOverridden: null,
   offsiteLocation: null,
   offsiteLocationAM: null,
   offsiteLocationPM: null,
};
