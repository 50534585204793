import React from 'react';
import Chip from '@mui/material/Chip';

const HourlyCapacity = (props) => {
   switch (props.cap) {
      case 2:
         return (
            <Chip
               style={{ backgroundColor: '#9ECF99' }}
               size="small"
               label="Getting busier"
            />
         );
      case 3:
         return <Chip style={{ backgroundColor: '#F7E943' }} size="small" label="Busy" />;
      case 4:
         return (
            <Chip style={{ backgroundColor: '#F7E943' }} size="small" label="Very busy" />
         );
      case 5:
         return (
            <Chip
               sx={{ backgroundColor: '#DB3D28', color: 'white' }}
               size="small"
               label="Fully booked"
            />
         );
      default:
         return <Chip style={{ backgroundColor: '#9ECF99' }} size="small" label="Free" />;
   }
};

export default HourlyCapacity;
