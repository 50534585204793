import React, { useState } from 'react';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   List,
   Typography,
} from '@mui/material';
import GroupListingItem from './GroupListingItem';
import GroupDetailsModal from './GroupDetailsModal';
import GroupsUpsellModal from './GroupsUpsellModal';
import { useFirebase } from '../../components/Firebase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useToggle } from '../../hooks';
import useFirebaseSnapshot from '../../hooks/useFirebaseSnapshot';
import { useAuth, useCompany } from '../../providers';
import IsEnabled, { isFeatureEnabledBool } from '../../components/IsEnabled';
import { isEmptyObject } from '../../utils/objectUtils';
import { defaultOption } from './utils/models';
import { sortFunction } from './utils/groupLisingUtils';
import GlobalIcon from '@mui/icons-material/Public';
import DebugUI from '../../components/DebugUI';

const GroupListing = ({ selectedLocationId, selectedGroupId, handleSelectGroup }) => {
   const firebase = useFirebase();
   const { authUser } = useAuth();
   const { company } = useCompany();
   const [editGroupId, setEditGroupId] = useState(undefined);
   const [newGroupId, setNewGroupId] = useState(undefined);
   const [showGroupAd, setShowGroupAd] = useState(false);
   const [toggleA, updateToggleA] = useToggle(true);
   const [toggleB, updateToggleB] = useToggle(true);

   const { loading, data: groupsList } = useFirebaseSnapshot(
      firebase.locationGroupsIndex,
      {
         args: [selectedLocationId],
         sortFunction,
         dependencies: [selectedLocationId],
      },
   );

   function handleCloseModal() {
      setEditGroupId(undefined);
      setNewGroupId(undefined);
      setShowGroupAd(false);
   }

   function handleEditGroup(e) {
      e.stopPropagation();
      setEditGroupId(e.currentTarget.id);
   }

   function handleShowUpsell() {
      setShowGroupAd(true);
   }

   async function handleOpenNewGroup() {
      const gid = await firebase.locationGroups().doc();
      setNewGroupId(gid.id);
   }

   if (loading) return null;

   const groupsEnabled = isFeatureEnabledBool('groups', company.features);
   const emptyResponse = isEmptyObject(groupsList);

   return (
      <>
         <Box mt={2}>
            {!emptyResponse && (
               <Box>
                  {/* <DebugUI /> */}
                  <IsEnabled company={company} hasFeature={'groups'}>
                     <Accordion expanded={toggleA} onChange={updateToggleA}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                           Groups
                        </AccordionSummary>
                        <AccordionDetails>
                           <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <List component="div" disablePadding>
                                 <GroupListingItem
                                    key={'all'}
                                    groupDetails={defaultOption}
                                    groupView={selectedGroupId}
                                    primaryClick={handleSelectGroup}
                                    secondaryClick={handleEditGroup}
                                 />

                                 {!!groupsList.localGroups.length &&
                                    groupsList.localGroups.map((group, index) => (
                                       <GroupListingItem
                                          key={group.gid}
                                          groupDetails={group}
                                          groupView={selectedGroupId}
                                          primaryClick={handleSelectGroup}
                                          secondaryClick={handleEditGroup}
                                          lastItem={
                                             index === groupsList.localGroups.length - 1
                                          }
                                       />
                                    ))}
                              </List>
                           </Box>
                        </AccordionDetails>
                     </Accordion>
                  </IsEnabled>

                  <IsEnabled company={company} hasFeature={'groups'}>
                     <Accordion expanded={toggleB} onChange={updateToggleB}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                           <GlobalIcon color="inherit" sx={{ mr: 1 }} /> Global Groups
                        </AccordionSummary>
                        <AccordionDetails>
                           <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                              {!!groupsList.globalGroups.length ? (
                                 <List component="div" disablePadding>
                                    {groupsList.globalGroups.map((group, index) => (
                                       <GroupListingItem
                                          key={group.gid}
                                          groupDetails={group}
                                          groupView={selectedGroupId}
                                          primaryClick={handleSelectGroup}
                                          secondaryClick={handleEditGroup}
                                          lastItem={
                                             index === groupsList.globalGroups.length - 1
                                          }
                                       />
                                    ))}
                                 </List>
                              ) : (
                                 <Typography
                                    variant="body2"
                                    color="GrayText"
                                    gutterBottom
                                 >
                                    You have 0 global groups
                                 </Typography>
                              )}
                           </Box>
                        </AccordionDetails>
                     </Accordion>
                  </IsEnabled>
               </Box>
            )}

            <Box sx={{ mt: 2 }}>
               <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  onClick={groupsEnabled ? handleOpenNewGroup : handleShowUpsell}
               >
                  <AddIcon />
                  Add group
               </Button>
            </Box>
         </Box>

         {showGroupAd && (
            <GroupsUpsellModal
               onClose={handleCloseModal}
               open={showGroupAd}
               company={{ ...company, cid: authUser.cid }}
            />
         )}

         {newGroupId && (
            <GroupDetailsModal
               onClose={handleCloseModal}
               newGroup={true}
               gid={newGroupId}
               lid={selectedLocationId}
               title="Add Group"
            />
         )}

         {editGroupId && (
            <GroupDetailsModal
               onClose={handleCloseModal}
               newGroup={false}
               gid={editGroupId}
               lid={selectedLocationId}
               title="Edit Group"
            />
         )}
      </>
   );
};

export default GroupListing;
