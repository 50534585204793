import React, { useEffect, useState } from 'react';
import {
   Button,
   Container,
   Grid,
   TextField,
   Typography,
   Select,
   FormControl,
   InputLabel,
   MenuItem,
   CircularProgress,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';

const NewUserForm = (props) => {
   const [form, setForm] = useState(false);
   const [locations, setLocations] = useState(false);
   const [pickedLocation, setPickedLocation] = useState({ 0: 'Select' });
   const [loading, setLoading] = useState(false);
   const [buttonDisabled, setButtonDisabled] = useState(true);
   const [visitors, setVisitors] = useState(false);

   useEffect(() => {
      const setUpForm = async () => {
         if (props.signInType === 'newUser') {
            setForm(props.user);
            await props.firebase
               .company(props.user.cid)
               .get()
               .then(async (company) => {
                  if (company.exists) {
                     setLocations(company.data().locations);
                     if (company.data().features?.visitors) {
                        setVisitors(true);
                     }
                  }
               });
         }
      };
      setUpForm();
   }, [props.signInType]);

   const handleChange = async (event) => {
      var fields = { firstName: form.name.firstName, lastName: form.name.lastName };
      fields[event.target.name] = event.target.value;
      setForm({ ...form, name: fields });
   };

   const handleLocation = (event) => {
      setPickedLocation(event.target.value);
      setForm({ ...form, lid: [event.target.value] });
      console.log(event.target.value);
      if (event.target.value !== '0') {
         setButtonDisabled(false);
      } else {
         setButtonDisabled(true);
      }
   };
   const saveForm = async (event) => {
      let data = { lid: form.lid, name: form.name };
      setForm(false);
      setLoading('loading');
      await props.firebase
         .saveSelfSignUpMSPerson(props.user.cid, props.user, data, visitors)
         .then(async () => {
            var authUser = form;
            authUser.emailVerified = true;
            var verifyEmail = firebase.functions().httpsCallable('setEmailToVerified');
            await verifyEmail(props.user.uid)
               .then(async () => {
                  var updatedUser = props.user;
                  updatedUser.lid = form.lid;
                  updatedUser.emailVerified = true;
                  localStorage.setItem('authUser', JSON.stringify(updatedUser));
                  if (props.msTeams) {
                     props.history.push(ROUTES.MSTEAMSAUTH);
                  } else {
                     setLoading('done');
                  }
                  return;
               })
               .catch((error) => {
                  console.log(error, 'error - saveForm');
               });
         });
   };

   return (
      <Container component="main" maxWidth="xs">
         {form && (
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Typography variant="h4" component="h2">
                     Account Confirmation
                  </Typography>
                  <Typography variant="body1">
                     Please confirm your name and your primary work location.
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name="firstName"
                     value={form.name.firstName}
                     label="First name"
                     onChange={handleChange}
                     variant="outlined"
                     required
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name="lastName"
                     value={form.name.lastName}
                     label="Last name"
                     onChange={handleChange}
                     variant="outlined"
                     required
                     fullWidth
                  />
               </Grid>
               {locations && (
                  <Grid item xs={12}>
                     <FormControl variant="outlined" fullWidth>
                        <InputLabel id="location-picker">Location</InputLabel>
                        <Select
                           labelId="location-picker"
                           id="locationPicker"
                           value={pickedLocation}
                           onChange={handleLocation}
                        >
                           <MenuItem key={0} value="0">
                              <em>None</em>
                           </MenuItem>
                           {Object.entries(locations).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                 {value}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </Grid>
               )}
               <Grid item xs={12}>
                  <Button
                     variant="contained"
                     disabled={buttonDisabled}
                     color="primary"
                     onClick={(e) => {
                        saveForm(e);
                     }}
                  >
                     {' '}
                     Submit{' '}
                  </Button>
               </Grid>
            </Grid>
         )}
         {loading && (
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  {loading === 'loading' && (
                     <React.Fragment>
                        <Typography variant="h5" component="h2" paragraph>
                           Loading...
                        </Typography>
                        <CircularProgress />
                     </React.Fragment>
                  )}
                  {loading === 'done' && (
                     <React.Fragment>
                        <Typography variant="h5" component="h2" paragraph>
                           All done
                        </Typography>
                        <Button
                           variant="contained"
                           color="primary"
                           href={ROUTES.DASHBOARD}
                        >
                           Go to your dashboard
                        </Button>
                     </React.Fragment>
                  )}
               </Grid>
            </Grid>
         )}
      </Container>
   );
};

const NewUser = compose(withRouter, withFirebase)(NewUserForm);
export default withFirebase(NewUser);
