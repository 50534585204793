import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';
import Overview from './overview';
import SpacesDay from './spacesDay';
import { Button, Typography } from '@mui/material';
import DayBookings from './dayBookings';
import FeatureDescription from './featureDescription';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import SchedulerIcon from '../../../../assets/icons/Scheduler';
import AssignmentIcon from '@mui/icons-material/Assignment';
const DayView = (props) => {
   const queryString = window.location.search;
   const history = useHistory();
   const authUser = {
      ...JSON.parse(localStorage.getItem('authUser')),
      lid: JSON.parse(localStorage.getItem('authUser')).lid[0],
   };
   const [tab, setTab] = useState(() => {
      const urlParams = new URLSearchParams(queryString);
      let tab = urlParams.get('tab');
      if (tab !== null) {
         return Number(tab);
      } else {
         return 1;
      }
   });

   const handleChange = (event, newValue) => {
      setTab(newValue);
   };

   return (
      <Box sx={{ width: '100%' }}>
         {props.location ? (
            <Box>
               <Box sx={{ display: 'flex' }}>
                  <Tabs
                     value={tab}
                     onChange={handleChange}
                     textColor="primary"
                     indicatorColor="primary"
                     aria-label="Day view tabs"
                  >
                     <Tab
                        value={1}
                        label={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                              <SchemaOutlinedIcon sx={{ transform: 'rotate(-90deg)' }} />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                 Space Plan
                              </Typography>
                           </div>
                        }
                     />
                     <Tab
                        value={2}
                        label={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                              <SchedulerIcon color="inherit" />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                 Scheduler
                              </Typography>
                           </div>
                        }
                     />
                     <Tab
                        value={3}
                        label={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                              <AssignmentIcon />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                 Day report
                              </Typography>
                           </div>
                        }
                     />
                  </Tabs>
               </Box>
               <Box sx={{ backgroundColor: 'rgb(250,250,250)', borderRadius: 1 }}>
                  {tab === 2 && props.location.spacesEnabled ? (
                     <SpacesDay
                        displayFilter={props.displayFilter}
                        day={props.day}
                        location={props.location}
                        tab={tab}
                        kiosk={false}
                     />
                  ) : tab === 3 && props.location.reporting ? (
                     <Overview
                        displayFilter={props.displayFilter}
                        day={props.day}
                        location={props.location}
                        tab={tab}
                     />
                  ) : tab === 1 && props.location.spacesEnabled ? (
                     <DayBookings
                        displayFilter={props.displayFilter}
                        day={props.day}
                        tab={tab}
                        compressed={true}
                        user={authUser}
                     />
                  ) : tab === 3 && !props.location.reporting ? (
                     <FeatureDescription type="reporting" />
                  ) : (
                     <FeatureDescription type="spaces" />
                  )}
               </Box>
            </Box>
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
               }}
            >
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};
export default DayView;
