import React from 'react';
import { styled } from '@mui/material/styles';

import ITOBlocks from './ITOBlocks';
import ITOnoSpaces from './ITOnoSpaces';
import OffsiteBlock from './offsiteBlock';
import WfhBlock from './wfhBlock';
import AwayBlock from './awayBlock';
import BlankBlock from './blankBlock';
import { Box } from '@mui/material';

const SplitBlocksAccordionBody = (props) => {
   return (
      <Box>
         {props.status.splitDay[props.dayTime] == 'ito' && props.spacesEnabled ? (
            <ITOBlocks
               data={props.data}
               user={props.data.user}
               date={props.data.date}
               status={props.status}
               dayTime={props.dayTime}
               displayFilter={props.displayFilter}
            />
         ) : props.status.splitDay[props.dayTime] == 'ito' ? (
            <ITOnoSpaces />
         ) : props.status.splitDay[props.dayTime] == 'offsite' ? (
            <OffsiteBlock
               data={props.data}
               dayTime={props.dayTime}
               status={props.status}
               displayFilter={props.displayFilter}
               spacesEnabled={props.spacesEnabled}
            />
         ) : props.status.splitDay[props.dayTime] == 'wfh' ? (
            <WfhBlock />
         ) : props.status.splitDay[props.dayTime] == 'away' ? (
            <AwayBlock />
         ) : (
            <BlankBlock />
         )}
      </Box>
   );
};

export default SplitBlocksAccordionBody;
