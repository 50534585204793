import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import CSVReader from 'react-csv-reader';
import * as EmailValidator from 'email-validator';
import './styles.css';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(1),
   },
   button: {
      borderRadius: 5,
      borderColor: '#342E76',
      backgroundColor: '#342E76',
      color: '#fff',
      width: '105px',
      padding: '2px',
      cursor: 'pointer',
      height: '38px',
   },
   buttonText: {
      margin: '5px',
      position: 'absolute',
   },
   buttonIcon: {
      margin: '5px',
   },
   buttonDisabled: {
      borderRadius: 5,
      borderColor: '#342E76',
      backgroundColor: '#fff',
      color: '#342E76',
      width: '105px',
      padding: '2px',
      cursor: 'pointer',
      height: '38px',
   },
   buttonTextDisabled: {
      margin: '5px',
      position: 'absolute',
   },
   buttonIconDisabled: {
      margin: '5px',
   },
   warning: {
      color: '#f44336',
   },
}));

function chunk(arr, chunkSize) {
   var R = [];
   for (var i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
   return R;
}

function validateFile(props, fileInfo, data, setErrorMessage) {
   const user = JSON.parse(localStorage.getItem('authUser'));
   if (fileInfo.type === 'text/csv') {
      if (
         data[0].hasOwnProperty('firstname') &&
         data[0].hasOwnProperty('lastname') &&
         data[0].hasOwnProperty('email')
      ) {
         var emails = [];
         var invalidCount = 0;
         Object.entries(data).map(([key, value]) => {
            data[key].valid = true;
            var emailValid = EmailValidator.validate(value.email); // true
            if (emailValid === false) {
               data[key].valid = false;
               invalidCount = invalidCount + 1;
            }
            data[key].exists = false;
            emails.push(value.email);
         });
         //The below doesn't actually check for duplicates. It's broken
         // const checkEmails = async () => {
         //   var count = props.duplicates;
         //   emails = chunk(emails, 10);
         //   console.log(emails);
         //   for (var i = 0; i < emails.length; i++){
         //     const emailExists = await props.firebase.emailExists(user.cid, emails[i]).get();
         //     console.log(emailExists);
         //     if (emailExists.empty){
         //       props.setData(data);
         //       props.setFileData(fileInfo);
         //       props.setValidFile(true);
         //       props.setUploadComplete(true);
         //     } else if (!emailExists.empty){

         //       emailExists.forEach(doc => {
         //         let key = data.find( x => x.email === doc.data().email);
         //         key.exists = true;
         //         count = count +1;
         //       });
         //     }
         //   }
         props.setData(data);
         props.setEmailError(invalidCount);
         // props.setDuplicates(count);
         props.setFileData(fileInfo);
         props.setValidFile(true);
         props.setUploadComplete(true);
         // }
         // checkEmails();
      } else {
         props.setValidFile(false);
         props.setUploadComplete(false);
         setErrorMessage(
            'OOPS: Please check that your headers are firstname, lastname, email.',
         );
      }
   } else {
      props.setValidFile(false);
      props.setUploadComplete(false);
      setErrorMessage(
         'OOPS: Please check that your file is a csv and try to import it again.',
      );
   }
}

const Upload = (props) => {
   const classes = useStyles();
   const [errorMessage, setErrorMessage] = useState('');
   const handleForce = (data, fileInfo) => {
      var clear = {};
      props.setData(clear);
      validateFile(props, fileInfo, data, setErrorMessage);
   };

   const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
   };

   function uploadButton(props) {
      if (props.uploadComplete) {
         return (
            <div className={classes.buttonDisabled}>
               <CloudUploadIcon className={classes.buttonIconDisabled} />
               <Typography className={classes.buttonTextDisabled} variant="button">
                  Upload
               </Typography>
            </div>
         );
      } else {
         return (
            <div className={classes.button}>
               <CloudUploadIcon className={classes.buttonIcon} />
               <Typography className={classes.buttonText} variant="button">
                  Upload
               </Typography>
            </div>
         );
      }
   }

   return (
      <div className={classes.container}>
         <CSVReader
            cssClass="react-csv-input"
            label={uploadButton(props)}
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
         />
         {props.validFile === false ? (
            <Typography className={classes.warning} variant="body1">
               {errorMessage}
            </Typography>
         ) : (
            ''
         )}
         {props.validFile && props.uploadComplete ? (
            <React.Fragment>
               <Typography variant="caption">{'File: ' + props.fileData.name}</Typography>
            </React.Fragment>
         ) : (
            ''
         )}
      </div>
   );
};

export default Upload;
// ReactDOM.render(reader, document.getElementById("root"));
