import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withFirebase } from '../../Firebase';
import CompanyAccordions from './companyAccordions';
import { IsInTrial } from '../../../constants/functions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   spanLink: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
   },
}));

const Company = (props) => {
   const classes = useStyles();
   const [trial, setTrial] = useState(false);
   const [daysRemaining, setDaysRemaining] = useState(0);
   useEffect(() => {
      if (props.company && props.company.subscription.plan === 'premium') {
         let isTrial = IsInTrial(props.company.subscription.planChangeDate);
         if (isTrial) {
            setTrial(true);
            setDaysRemaining(7 - isTrial);
         }
      }
   }, [props.company]);

   return (
      <React.Fragment>
         <Container sx={{ mt: 3 }}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Typography variant="h4" component="h2">
                     {props.company.companyName}
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                     Details
                  </Typography>
                  <Paper sx={{ p: 2 }}>
                     <Grid container item xs={12}>
                        <Grid item xs={12} sm={6}>
                           <Typography
                              variant="body1"
                              sx={{ fontWeight: 'fontWeightBold' }}
                           >
                              Total users:{' '}
                              <span style={{ fontWeight: 'lighter' }}>
                                 {props.company.totalPeople}
                              </span>
                           </Typography>
                           <Typography
                              variant="body1"
                              sx={{ fontWeight: 'fontWeightBold' }}
                           >
                              Location(s):
                           </Typography>
                           {Object.entries(props.company.locations).map(
                              ([key, location]) => (
                                 <Typography key={key} variant="body1" sx={{ ml: 2 }}>
                                    {location}
                                 </Typography>
                              ),
                           )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                           <Typography
                              variant="body1"
                              sx={{ fontWeight: 'fontWeightBold' }}
                           >
                              Subscription:
                              <span
                                 style={{
                                    textTransform: 'capitalize',
                                    fontWeight: 'lighter',
                                 }}
                              >
                                 {' '}
                                 {props.company.subscription.plan}
                              </span>
                              {trial && (
                                 <span style={{ fontWeight: 'lighter' }}>
                                    {' '}
                                    (Trial, {daysRemaining} days remaining)
                                 </span>
                              )}
                           </Typography>
                           {props.company.currentBillTotal && (
                              <React.Fragment>
                                 <Typography sx={{ fontWeight: 'fontWeightBold' }}>
                                    Current usage:{' '}
                                    <span style={{ fontWeight: 'lighter' }}>
                                       £{props.company.currentBillTotal.toFixed(2)}
                                    </span>
                                 </Typography>
                                 <Typography sx={{ fontWeight: 'fontWeightBold' }}>
                                    Next bill date:{' '}
                                    <span style={{ fontWeight: 'lighter' }}>
                                       {props.company.nextBillingDate
                                          .toDate()
                                          .toDateString()}
                                    </span>
                                 </Typography>
                                 <Typography variant="caption">
                                    For a detailed breakdown of usage go to{' '}
                                    <span
                                       className={classes.spanLink}
                                       onClick={() => props.setValue(3)}
                                    >
                                       Subscription
                                    </span>
                                 </Typography>
                              </React.Fragment>
                           )}
                        </Grid>
                     </Grid>
                  </Paper>
               </Grid>
               <CompanyAccordions trial={trial} company={props.company} />
            </Grid>
         </Container>
      </React.Fragment>
   );
};

export default withFirebase(Company);
