import React, { useState, useEffect } from 'react';
import { Badge, useTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChipDialog from './chipDialog';
import MiniChips from './Chips/miniChips';
import { withFirebase } from '../Firebase';
import Chips from './Chips';
import { DateToday } from '../../constants/functions';
import { useAuth } from '../../providers';

const SingleCells = (props) => {
   const theme = useTheme();
   const { authUser } = useAuth();
   const [clickable, setClickable] = useState(props.row.clickable);
   const [offsiteName, setOffsiteName] = useState(null);
   const [bookings, setBookings] = useState(null);
   const [chipModal, setChipModal] = useState({ open: false });
   const [availability, setAvailability] = useState(false);

   const date =
      props.date.year +
      ('0' + props.date.month).slice(-2) +
      ('0' + props.date.day).slice(-2);
   const today = DateToday();

   useEffect(() => {
      if (props.row) {
         var status = props.row.clickable;
         if (props.date.ref < today.ref && !props.editPrevious) {
            status = false;
         }
         setClickable(status);
      }
   }, [props.date, props.editPrevious, props.row]);

   useEffect(() => {
      if (props.row) {
         const loadOffsiteLocationName = async () => {
            if (props.row.offsiteLocation) {
               setOffsiteName(props.row.offsiteLocation[props.dayNo]);
            }
            if (props.row.status[props.dayNo].customTimes) {
               setAvailability(props.row.status[props.dayNo].times);
            } else {
               setAvailability(false);
            }
         };
         loadOffsiteLocationName();
      }
   }, [props.row]);

   useEffect(() => {
      const loadBookings = async () => {
         if (
            props.row &&
            (props.dayNo || props.dayNo === 0) &&
            props.spaces !== null &&
            props.row.space &&
            props.row.space[props.dayNo] !== 'empty' &&
            //Added the below to stop the schedule being broken when booking in another location
            props.row.status[props.dayNo].set !== 'offsite'
         ) {
            let space = [];
            let bookingExists = false;
            Object.entries(props.row.space[props.dayNo]).map(([key, value]) => {
               if (
                  (value.type == 'All day' || value.type == 'AM' || value.type == 'PM') &&
                  props.spaces[value.sid]
               ) {
                  space.push({
                     bid: key,
                     sid: value.sid,
                     name: props.spaces[value.sid].name,
                     seat: value.seat,
                     type: value.type,
                  });
                  bookingExists = true;
               }
            });

            if (bookingExists) {
               setBookings(space);
            } else {
               setBookings(null);
            }
         } else {
            setBookings(null);
         }
      };
      loadBookings();
   }, [props.row, props.row.space, props.spaces, props.dayNo]);

   const user = {
      lid: props.row.lid,
      uid: props.row.id,
      cid: props.row.cid,
   };

   async function statusClick(row, props, value) {
      async function getStatus(value) {
         switch (value) {
            case 'away':
               return '-';
            case 'ito':
               return 'wfh';
            case 'wfh':
               if (props.location.offsiteEnabled) {
                  return 'offsite';
               } else {
                  return 'away';
               }

            case 'offsite':
               return 'away';
            case '-':
               return 'ito';
            default:
               return '-';
         }
      }
      if (row.status[props.dayNo].set === 'split' || props.miniChips) {
         props.setStatusDrawerOpen(true);
         props.setSpaceData(data);
      } else if (
         props.row.offsiteLocation[props.dayNo] !== '' &&
         props.row.offsiteLocation[props.dayNo] !== null
      ) {
         setChipModal({ open: true });
         setClickable(true);
      } else if (props.row.space[props.dayNo] !== 'empty') {
         setChipModal({ open: true });
         setClickable(true);
      } else {
         setClickable(false);
         var oldStatus = value;
         var status = await getStatus(value);
         let routine = false;
         let oldLocation = '';
         if (props.row.status[props.dayNo].lid) {
            oldLocation = props.row.status[props.dayNo].lid;
         } else {
            oldLocation = props.row.lid;
         }
         if (props.row.status[props.dayNo].originalStatus) {
            oldStatus = props.row.status[props.dayNo].originalStatus;
         }
         let locations = {
            old: oldLocation,
            new: props.row.lid,
         };
         if (props.row.status[props.dayNo].routine && props.date.ref > today.ref) {
            routine = {
               status: props.row.status[props.dayNo].routine,
               overridden: true,
               dayNo: props.date.weekDay,
            };
            if (status === props.row.status[props.dayNo].routine) {
               routine.overridden = false;
            }
         }
         try {
            await props.firebase.setStatus(
               user,
               date,
               status,
               oldStatus,
               locations,
               routine,
               authUser,
            );
         } catch (error) {
            console.warn(error);
         }
      }
   }

   var data = {
      user: {
         uid: props.row.id,
         lid: props.row.lid,
         cid: props.row.cid,
      },
      date: props.date,
      space: props.row.space[props.dayNo],
      offsiteLocation: offsiteName,
      status: props.row.status[props.dayNo],
   };

   function closeChipModal() {
      setChipModal(false);
   }
   return (
      <React.Fragment>
         {props.row &&
            props.row.status &&
            props.row.status[props.dayNo] &&
            data !== null && (
               <React.Fragment>
                  <ChipDialog
                     chipModal={chipModal}
                     setChipModal={setChipModal}
                     data={data}
                  />
                  <div
                     onClick={(e) => {
                        if (clickable) {
                           statusClick(
                              props.row,
                              props,
                              props.row.status[props.dayNo].set,
                           );
                        }
                     }}
                  >
                     <Badge
                        sx={{
                           '& .MuiBadge-badge': {
                              right: props.miniChips ? 1 : -2,
                              top: props.miniChips ? 2 : 8,
                              backgroundColor: `${theme.palette.background.paper}`,
                              border: `2px solid ${theme.palette.background.paper}`,
                              padding: props.miniChips ? '1px 0px' : '4px 0px',
                           },
                        }}
                        invisible={!props.row.status[props.dayNo].customTimes}
                        badgeContent={
                           <React.Fragment>
                              <AccessTimeIcon
                                 color="availableTime"
                                 sx={{ fontSize: props.miniChips ? '0.9rem' : '1.25rem' }}
                              />
                           </React.Fragment>
                        }
                     >
                        <MiniChips
                           value={props.row.status[props.dayNo]}
                           data={data}
                           offsiteName={offsiteName}
                           availability={availability}
                           spaces={props.spaces}
                           bookings={bookings}
                           setSpaceData={props.setSpaceData}
                           setStatusDrawerOpen={props.setStatusDrawerOpen}
                           routineEnabled={props.routineEnabled}
                           spacesEnabled={props.spacesEnabled}
                           clickable={clickable}
                           miniChips={props.miniChips}
                        />
                     </Badge>
                  </div>
               </React.Fragment>
            )}
      </React.Fragment>
   );
};

export default withFirebase(SingleCells);
