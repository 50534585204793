import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { withFirebase } from '../../Firebase';
import { DateToday } from '../../../constants/functions';
import firebase from 'firebase/app';

const GlobalActions = (props) => {
   const [numbers, setNumbers] = useState(false);
   const [startDate, setStartDate] = useState(() => {
      var dayRef = DateToday(-14);
      return dayRef.ref;
   });
   const [endDate, setEndDate] = useState(() => {
      var dayRef = DateToday();
      return dayRef.ref;
   });

   async function processButton() {
      if (startDate.length === 8 && endDate.length === 8) {
         props.firebase
            .locationStatusLevels(props.selectedLocation.lid, startDate, endDate)
            .get()
            .then((results) => {
               let statusLevel = {};
               let schedule = {};
               console.log(results);
               if (!results.empty) {
                  results.forEach((result) => {
                     statusLevel[result.id] = result.data();
                     props.firebase
                        .locationScheduleMultiDay(
                           props.selectedLocation.lid,
                           startDate,
                           endDate,
                        )
                        .get()
                        .then((scheds) => {
                           scheds.forEach((sched) => {
                              schedule[sched.id] = {
                                 ito: 0,
                                 wfh: 0,
                                 away: 0,
                                 offsite: 0,
                              };
                              Object.entries(sched.data()).map(([key, value]) => {
                                 console.log(key, value);
                                 schedule[sched.id][value.status] =
                                    schedule[sched.id][value.status] + 1;
                              });
                           });

                           setNumbers({ statusLevel, schedule });
                        });
                  });
               } else {
                  alert('no statuses');
               }
            });
      } else {
         alert('not a date');
      }
      console.log(props);
   }

   return (
      <Container component={Paper} sx={{ mt: 4, mb: 1, ml: 1, mr: 2 }}>
         {props.selectedLocation && (
            <React.Fragment>
               <TextField
                  id="standard-basic"
                  label="Standard"
                  variant="standard"
                  defaultValue={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
               />
               <TextField
                  id="standard-basic"
                  label="Standard"
                  variant="standard"
                  defaultValue={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
               />
               <Button onClick={() => processButton()}>Button</Button>
            </React.Fragment>
         )}
         {console.log(numbers)}
         {numbers && (
            <Grid container>
               {Object.entries(numbers.schedule).map(([day, sched]) => (
                  <React.Fragment>
                     <Grid item xs={12}>
                        <Typography variant="h6">{day}</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Typography variant="subtitle1">Schedule Count</Typography>
                        {Object.entries(sched).map(([key, value]) => {
                           if (key !== '-' && value !== numbers.statusLevel[day][key]) {
                              return (
                                 <Typography color="secondary" key={key}>
                                    {key}: {value}{' '}
                                 </Typography>
                              );
                           } else {
                              return (
                                 <Typography key={key}>
                                    {key}: {value}{' '}
                                 </Typography>
                              );
                           }
                        })}
                     </Grid>
                     <Grid item xs={6}>
                        <Typography variant="subtitle1">Status Level</Typography>
                        {Object.entries(numbers.statusLevel[day]).map(([key, value]) => (
                           <Typography key={key}>
                              {key}: {value}{' '}
                           </Typography>
                        ))}
                     </Grid>
                  </React.Fragment>
               ))}
            </Grid>
         )}
      </Container>
   );
};

export default withFirebase(GlobalActions);
