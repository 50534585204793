import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const ItoMiniBothBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} color="primary" viewBox="0 0 62 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/ITOPMbookednotclickable-Copy-2"
               transform="translate(-0.4778, 0)"
               fill={theme.palette.tableBody.main}
            >
               <path
                  d="M40.6768839,-1.28747276 L41.4090958,-0.782529856 L18.1413078,31.7125272 L17.4090958,31.2075843 L40.6768839,-1.28747276 Z M53.6072122,8.6 L54.9140083,10.0271739 L43.7128988,22.2600932 L38.4857143,16.5513975 L39.7925104,15.1242236 L43.7128988,19.4057453 L53.6072122,8.6 Z M22.6072122,8.6 L23.9140083,10.0271739 L12.7128988,22.2600932 L7.48571429,16.5513975 L8.7925104,15.1242236 L12.7128988,19.4057453 L22.6072122,8.6 Z"
                  id="Oval-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoMiniBothBooked;
