import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MsSymbol from '../../assets/mssymbol.svg';
import Icon from '@material-ui/core/Icon';

import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import {
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
   useMsal,
} from '@azure/msal-react';
import BoxLogo from '../../assets/boxLogo.svg';
import EmailExists from '../Auth/emailExists';
import * as microsoftTeams from '@microsoft/teams-js';

const useStyles = makeStyles((theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   mssubmit: {
      margin: theme.spacing(1, 0, 2),
      borderWidth: 1,
      borderColor: '#8C8C8C',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
   },
   mssignin: {
      margin: theme.spacing(0, 2),
      height: '34px',
   },
}));

const MSTeamsSignInForm = (props) => {
   const classes = useStyles();
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [userState, setUserState] = useState('none');
   const [checked, setChecked] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [setMSCreds, mSCreds] = useState(false);
   microsoftTeams.initialize();
   var msTeams = false;
   if (
      window.location.hostname.includes('ngrok.io') ||
      window.location.hostname.includes('msteams')
   ) {
      msTeams = true;
   }

   async function logIntoMSTeams() {
      microsoftTeams.authentication.authenticate({
         url: window.location.origin + '/auth',
         width: 600,
         height: 535,
         successCallback: (result) => {
            let authorizeEndpoint = window.location.origin;
            window.location.assign(authorizeEndpoint);
         },
         failureCallback: function (reason) {
            alert(reason);
         },
      });
   }
   const Toast = () => {
      return (
         <div>
            <Collapse in={checked} collapsedHeight={0}>
               <Alert severity="error">
                  <strong>Error - </strong>
                  {errorMessage}
               </Alert>
            </Collapse>
         </div>
      );
   };

   async function handleSubmit(event) {
      event.preventDefault();
      props.firebase
         .doSignInWithEmailAndPassword(email, password)
         .then(() => {
            props.history.push(ROUTES.DASHBOARD);
         })
         .catch((error) => {
            setErrorMessage(error.message);
            setChecked(true);
         });
   }
   useEffect(() => {
      const getOffice = () => {};
      getOffice();
   }, []);

   return (
      <React.Fragment>
         {/* <Link href="https://app.intheoffice.io/signup?utm_source=msteams&utm_medium=inapp&utm_campaign=signup" target="_blank" variant="body2">
            Don't have an account? Sign Up Online
        </Link> */}
         {userState === 'none' && (
            <React.Fragment>
               <UnauthenticatedTemplate>
                  <Avatar className={classes.avatar}>
                     <img src={BoxLogo} alt="intheOffice" height={40} width={40} />
                  </Avatar>
                  <Typography variant="h6" align="center" component="h2" gutterBottom>
                     Log in to get started
                  </Typography>
                  <Typography variant="body1" align="center" gutterBottom>
                     intheOffice for Microsoft Teams helps you manage your hybrid office
                     by letting your colleagues know when you plan to be in the office and
                     book spaces to work.
                  </Typography>
                  <Button
                     color="primary"
                     style={{ marginBottom: 8 }}
                     variant="contained"
                     onClick={() => {
                        logIntoMSTeams();
                     }}
                  >
                     Log in with intheOffice
                  </Button>
                  <Typography variant="body1" align="center" gutterBottom>
                     Don't have a company account yet?{' '}
                     <Link target="_blank" href="https://app.intheoffice.io/signup">
                        Sign up.
                     </Link>
                  </Typography>
               </UnauthenticatedTemplate>
               <AuthenticatedTemplate>
                  <Link>Create a new account</Link>
               </AuthenticatedTemplate>
            </React.Fragment>
         )}
         {userState === 'emailExists' && (
            <EmailExists
               mSCreds={mSCreds}
               userState={userState}
               setUserState={setUserState}
            />
         )}
      </React.Fragment>
   );
};

const MSTeamsSignIn = compose(withRouter, withFirebase)(MSTeamsSignInForm);

export default MSTeamsSignIn;
