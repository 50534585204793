import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState, PureComponent } from 'react';

import { useFirebase } from '../../../Firebase';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import GeneralCoInfo from './generalCoInfo';
import LineChart from '../charts/lineChart';
import { convertToDate, convertToDateName } from '../../../../constants/functions';

const TimeProgress = ({ usage }) => {
   const [data, setData] = useState(null);
   const [company, setCompany] = useState(null);
   const firebase = useFirebase();
   const [getParams, setParams] = useSearchParams();

   useEffect(() => {
      if (usage !== null) {
         let processedData = [];
         let totalUsers = [];
         let newUsers = 0;
         Object.entries(usage).forEach(([key, value]) => {
            let dailyUsers = 0;
            let appViews = 0;
            let teamsViews = 0;
            Object.entries(value).forEach(([uid, views]) => {
               if (uid !== 'totalPageViews') {
                  if (!totalUsers.includes(uid)) {
                     totalUsers.push(uid);
                     newUsers++;
                  }
                  dailyUsers++;

                  Object.entries(views).forEach(([time, page]) => {
                     if (time !== 'pageViews' && page.includes('msteams')) {
                        teamsViews++;
                     } else if (time !== 'pageViews' && page.includes('app')) {
                        appViews++;
                     }
                  });
               }
            });
            let average = Number(value.totalPageViews) / Number(dailyUsers);
            processedData.push({
               dateRef: key,
               name: convertToDateName(key),
               totalPageViews: value.totalPageViews,
               dailyUsers,
               appViews,
               teamsViews,
               average,
               newUsers,
            });
         });

         setData(processedData);
      }
   }, [usage]);

   useEffect(() => {
      const params = getParams();
      const cid = params.cid;
      firebase.db
         .doc(`company/${cid}`)
         .get()
         .then((doc) => {
            setCompany(doc.data());
         });
   }, []);

   const labels = [
      {
         dataKey: 'totalPageViews',
         name: 'Total Page Views',
      },
      {
         dataKey: 'dailyUsers',
         name: 'Daily Users',
      },
      {
         dataKey: 'appViews',
         name: 'App Views',
      },
      {
         dataKey: 'teamsViews',
         name: 'Teams Views',
      },
      {
         dataKey: 'average',
         name: 'Average',
      },
      {
         dataKey: 'newUsers',
         name: 'New Users',
      },
   ];
   return (
      <Box>
         {company !== null && (
            <Box>
               <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                     onClick={() => {
                        setParams({});
                        history.go(0);
                     }}
                  >
                     Back
                  </Button>
                  <Typography variant="h5" component="h2">
                     {company.companyName}
                  </Typography>
               </Box>
               <GeneralCoInfo company={company} />
               {data !== null && <LineChart data={data} labels={labels} />}
            </Box>
         )}
      </Box>
   );
};

export default TimeProgress;
