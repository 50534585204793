import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { featureNames, convertToDate } from '../../../constants/functions';
import { withFirebase } from '../../Firebase';

const PastMonths = (props) => {
   const [expanded, setExpanded] = useState(false);
   const [past, setPast] = useState(false);

   useEffect(() => {
      if (props.company) {
         props.firebase
            .companyPastBills(props.company.cid)
            .get()
            .then((results) => {
               if (!results.empty) {
                  let data = {};
                  results.forEach((doc) => {
                     if (doc.id !== 'current') {
                        data[doc.id] = doc.data();
                     }
                  });
                  const reverseDates = Object.keys(data).reverse();
                  let dates = {};
                  reverseDates.forEach((key) => {
                     var date = data[key].invoiceDate.toDate();
                     date =
                        date.getDate() +
                        '/' +
                        (date.getMonth() + 1) +
                        '/' +
                        date.getFullYear();

                     dates[date] = data[key];
                  });
                  setPast(dates);
               }
            });
      }
   }, [props.company]);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   return (
      <div>
         {past &&
            Object.entries(past).map(([key, day]) => (
               <Accordion
                  key={key}
                  expanded={expanded === key}
                  onChange={handleChange(key)}
               >
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1bh-content"
                     id="panel1bh-header"
                  >
                     <Typography
                        sx={{ width: '33%', flexShrink: 0 }}
                        component="h4"
                        variant="h5"
                     >
                        {key}
                     </Typography>
                     <Typography sx={{ color: 'text.secondary' }}>
                        Total £{day.monthTotal.toFixed(2)} + VAT
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Table size="small">
                        <TableHead>
                           <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Enabled features</TableCell>
                              <TableCell>No. of people</TableCell>
                              <TableCell>Cost</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {Object.entries(day).map(([id, value]) => {
                              if (id !== 'monthTotal' && id !== 'invoiceDate') {
                                 return (
                                    <TableRow key={id}>
                                       <TableCell>{convertToDate(id)}</TableCell>
                                       <TableCell>
                                          {Object.entries(featureNames).map(
                                             ([featKey, featValue]) => {
                                                if (value[featKey]) {
                                                   return (
                                                      <Typography
                                                         key={featValue.name}
                                                         variant="caption"
                                                      >
                                                         {' '}
                                                         {featValue.name},{' '}
                                                      </Typography>
                                                   );
                                                }
                                             },
                                          )}
                                       </TableCell>
                                       <TableCell>{value.people}</TableCell>
                                       <TableCell>£{value.dayTotal.toFixed(2)}</TableCell>
                                    </TableRow>
                                 );
                              }
                           })}
                           <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>Current Total</TableCell>
                              <TableCell>£{day.monthTotal.toFixed(2)} + VAT</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </AccordionDetails>
               </Accordion>
            ))}
      </div>
   );
};

export default withFirebase(PastMonths);
