import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
   TableContainer,
   Table,
   TableHead,
   TableBody,
   TableRow,
   TableCell,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import Chips from '../Schedule/Chips';
import { DateToday } from '../../constants/functions';
import { processUnderwrite } from './routineFunctions';

const useStyles = makeStyles((theme) => ({
   buttons: {
      margin: theme.spacing(2),
   },
}));

const week = {
   1: 'Monday',
   2: 'Tuesday',
   3: 'Wednesday',
   4: 'Thursday',
   5: 'Friday',
   6: 'Saturday',
   0: 'Sunday',
};

const blankRoutine = {
   1: {
      set: '-',
   },
   2: {
      set: '-',
   },
   3: {
      set: '-',
   },
   4: {
      set: '-',
   },
   5: {
      set: '-',
   },
   6: {
      set: '-',
   },
   0: {
      set: '-',
   },
};

const Routine = (props) => {
   const classes = useStyles();
   const [status, setStatus] = useState(blankRoutine);
   const [initialLoadStatus, setInitialLoadStatus] = useState(false);
   const [edited, setEdited] = useState(false);
   const [errorMsg, setErrorMsg] = useState(false);
   const today = DateToday();

   useEffect(() => {
      const getStatus = async () => {
         if (props.user !== null && props.user.routine) {
            let dbRoutine = {};
            Object.entries(props.user.routine).map(([key, value]) => {
               dbRoutine[key] = {
                  set: value,
               };
            });
            setStatus(dbRoutine);
            setInitialLoadStatus(dbRoutine);
         } else {
            setInitialLoadStatus(blankRoutine);
         }
      };
      getStatus();
   }, [props.user]);
   async function statusClick(day, oldStatus) {
      function getStatus(value) {
         switch (value) {
            case 'away':
               return '-';
            case 'ito':
               return 'wfh';
            case 'wfh':
               if (props.location.offsiteEnabled) {
                  return 'offsite';
               } else {
                  return 'away';
               }
            case 'offsite':
               return 'away';
            case '-':
               return 'ito';
            default:
               return '-';
         }
      }
      if (props.clickable) {
         var newStatus = getStatus(oldStatus);
         setStatus({ ...status, [day]: { set: newStatus } });
         setEdited(true);
      }
   }

   function revertRoutine() {
      if (!initialLoadStatus) {
         setStatus(blankRoutine);
      } else {
         setStatus(initialLoadStatus);
      }
      setEdited(false);
      setErrorMsg(false);
   }

   async function saveRoutine(deleteRoutine) {
      setEdited(false);
      setErrorMsg(false);
      let newStatus = status;
      if (deleteRoutine) {
         newStatus = blankRoutine;
      }
      try {
         const futureSchedule = await props.firebase
            .personFutureScheduleExists(props.user.cid, props.user.id, today.ref)
            .get();
         if (futureSchedule.empty) {
            await props.firebase.saveCleanRoutine(
               props.user,
               initialLoadStatus,
               newStatus,
            );
         } else if (!futureSchedule.empty) {
            let schedule = {};
            futureSchedule.forEach((doc) => {
               schedule[doc.id] = doc.data();
            });
            let underWriteChanges = await processUnderwrite(
               props.user,
               today,
               schedule,
               newStatus,
            );
            try {
               await props.firebase.underwriteRoutine(underWriteChanges);
            } catch (error) {
               console.warn(error);
            }

            await props.firebase.saveCleanRoutine(
               props.user,
               initialLoadStatus,
               newStatus,
            );
         }
      } catch (error) {
         setEdited(true);
         setErrorMsg(true);
         console.warn(error);
      }
   }
   async function deleteRoutine() {
      await saveRoutine(true);
      setStatus(blankRoutine);
   }

   return (
      <React.Fragment>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     {Object.entries(week).map(([key, value]) => {
                        if (key >= 1) {
                           return (
                              <TableCell align="center" key={key}>
                                 <Typography variant="subtitle1">{value}</Typography>
                              </TableCell>
                           );
                        }
                     })}
                     <TableCell align="center" key={0}>
                        <Typography variant="subtitle1">{week[0]}</Typography>
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  <TableRow>
                     {Object.entries(status).map(([key, value]) => {
                        if (key >= 1) {
                           return (
                              <TableCell key={key} align="center">
                                 <div
                                    onClick={(e) => {
                                       statusClick(key, value.set);
                                    }}
                                 >
                                    <Chips
                                       value={value}
                                       clickable={false}
                                       routine={true}
                                    />
                                 </div>
                              </TableCell>
                           );
                        }
                     })}
                     <TableCell key={0} align="center">
                        <div
                           onClick={(e) => {
                              statusClick(0, status[0].set);
                           }}
                        >
                           <Chips
                              value={status[0]}
                              clickable={props.clickable}
                              routine={true}
                           />
                        </div>
                     </TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </TableContainer>
         {props.clickable && (
            <Button
               disabled={!edited}
               variant="contained"
               color="primary"
               className={classes.buttons}
               onClick={() => saveRoutine()}
            >
               Save
            </Button>
         )}
         {props.profile && (
            <Button
               color="secondary"
               className={classes.buttons}
               onClick={() => deleteRoutine()}
            >
               delete routine
            </Button>
         )}
         {edited && (
            <Button className={classes.buttons} onClick={() => revertRoutine()}>
               revert
            </Button>
         )}
         {errorMsg && (
            <Typography variant="caption" color="error">
               Oops, something went wrong, please try again. If the problem persists,
               please contact your IT admin
            </Typography>
         )}
      </React.Fragment>
   );
};

export default withFirebase(Routine);
