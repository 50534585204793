import React from 'react';
import UserProfileMenu from './UserProfileMenu';
import { AuthUserContext } from '../../Session';
import * as ROUTES from '../../../constants/routes';
import { IconButton, Button, Box } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { supportEmail } from '../../../constants/appConstants';

function formatSubjectLine(user) {
   if (user) {
      return `Support Request from ${user.email}`;
   }
   return `Support Request`;
}

const UserManagementControl = () => (
   <AuthUserContext.Consumer>
      {(authUser) => (
         <Box display="flex" gap={1}>
            <IconButton
               component={'a'}
               href={`mailto:${supportEmail}?subject=${formatSubjectLine(authUser)}`}
               target="_blank"
            >
               <ContactSupportIcon sx={{ color: 'background.paper' }} />
            </IconButton>
            {authUser ? (
               <UserProfileMenu authUser={authUser} />
            ) : (
               <Button type="button" color="inherit" href={ROUTES.SIGN_IN}>
                  Sign in
               </Button>
            )}
         </Box>
      )}
   </AuthUserContext.Consumer>
);

export default UserManagementControl;
