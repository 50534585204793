import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { withFirebase } from '../Firebase';
import { Typography } from '@mui/material';
import { useAuth, useCompany } from '../../providers';
import { CardSection } from '../layout';

const Main = (props) => {
   const { authUser } = useAuth();
   const { company } = useCompany();

   useEffect(() => {
      if (authUser) {
         console.log(authUser);
         const script = document.createElement('script');
         script.src = 'https://js.hsforms.net/forms/shell.js';
         document.body.appendChild(script);
         script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
               // @TS-ignore
               console.log('hbspt', window.hbspt.forms);
               window.hbspt.forms.create({
                  region: 'na1',
                  portalId: '20162949',
                  formId: '0aacb4c2-e762-478a-9732-60f6528f5fd3',
                  target: '#hubspotForm',
                  onFormReady: function (form) {
                     form.querySelector('input[name="firstname"]').value =
                        authUser.name.firstName;
                     form.querySelector('input[name="lastname"]').value =
                        authUser.name.lastName;
                     form.querySelector('input[name="email"]').value = authUser.email;
                     form.querySelector('input[name="platform_uid"]').value =
                        authUser.uid;
                  },
               });
            }
         });
      }
   }, [authUser]);

   return (
      <Box
         sx={{
            height: 600,
            width: '80%',
            mt: 6,
            ml: 'auto',
            mr: 'auto',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
         }}
      >
         <Typography component="h2" variant="h5">
            How can we help?
         </Typography>
         <CardSection sx={{ m: 2 }}>
            <Typography variant="h6">Your details</Typography>
            <Box>
               <Typography
                  paragraph
                  variant="body1"
               >{`${authUser.name.firstName} ${authUser.name.lastName}`}</Typography>
               <Typography variant="body1">{`${authUser.email}`}</Typography>
               {console.log(company)}
               {company && (
                  <Typography variant="body1">{`${company.companyName}`}</Typography>
               )}
            </Box>
         </CardSection>

         <CardSection sx={{ m: 2 }}>
            <div id="hubspotForm"></div>
         </CardSection>
      </Box>
   );
};

export default withFirebase(Main);
