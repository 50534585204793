export function createNewIndex(membersArray) {
   return membersArray.reduce(
      (acc, id) => ({
         ...acc,
         [id]: 1,
      }),
      {},
   );
}

// export function createModifiedIndex(currentIndexObject, newMembersArray, action) {
//    const modifiedIndex = newMembersArray.reduce((acc, uid) => {
//       if (acc[uid]) {
//          if (action === 'add') {
//             return {
//                ...acc,
//                [uid]: acc[uid] + 1,
//             };
//          } else {
//             return {
//                ...acc,
//                [uid]: acc[uid] - 1,
//             };
//          }
//       } else {
//          return {
//             ...acc,
//             [uid]: 1,
//          };
//       }
//    }, currentIndexObject);

//    return modifiedIndex;
// }

function decrementIndex(index, previousMembers) {
   return previousMembers.reduce(
      (acc, memberUid) => {
         if (acc[memberUid]) {
            if (acc[memberUid] > 1) {
               return {
                  ...acc,
                  [memberUid]: acc[memberUid] - 1,
               };
            } else {
               delete acc[memberUid];
               return acc;
            }
         } else {
            return acc;
         }
      },
      index ? index : {},
   );
}

function incrementIndex(index, currentMembers) {
   return currentMembers.reduce((acc, memberUid) => {
      if (acc[memberUid]) {
         return {
            ...acc,
            [memberUid]: acc[memberUid] + 1,
         };
      } else {
         return {
            ...acc,
            [memberUid]: 1,
         };
      }
   }, index);
}

export function createModifiedIndex(
   currentIndex,
   previousMembers,
   currentMembers,
   action,
) {
   const decrementedMembers = decrementIndex(currentIndex, previousMembers);
   const incrementedMembers = incrementIndex(decrementedMembers, currentMembers);

   if (action === 'update') {
      return incrementedMembers;
   }

   return decrementedMembers;
}
