import React, { useState, useEffect } from 'react';
import Drawer from '../Drawer';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import Upload from './upload';
import sampleCSV from '../../assets/intheOffice-sample-people-upload.csv';
import { chunkObject } from '../../constants/functions';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
   },
   // container: {
   //    paddingTop: theme.spacing(4),
   //    paddingBottom: theme.spacing(4),
   //    paddingLeft: theme.spacing(0),
   // },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   card: {
      margin: theme.spacing(2, 0),
      width: '100%',
   },
   cardTitle: {
      margin: theme.spacing(1),
   },
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   importButton: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
   },
   drawerPaper: {
      width: '70%',
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   deleteButton: {
      margin: theme.spacing(3, 0, 2),
      float: 'right',
   },
   checkbox: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         marginLeft: theme.spacing(0),
      },
   },
   container: {
      height: '70vh',
   },
   rowDuplicate: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
   },
   emailError: {
      color: '#f44336',
   },
}));

function sampleFile() {
   return (
      <React.Fragment>
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <HelpIcon />
            <Typography variant="body1">File format</Typography>
         </div>
         <Typography variant="body2">
            Your file must be in the format: firstName, lastName, email <br />
            <Link href={sampleCSV}>Download a sample file</Link> if you are unsure.
         </Typography>
      </React.Fragment>
   );
}

function importRowStatus(user) {
   if (user.valid === false) {
      return (
         <React.Fragment>
            <WarningIcon color="secondary" />
            <Typography color="secondary" variant="caption">
               Email invalid
            </Typography>
         </React.Fragment>
      );
   } else if (user.exists) {
      return (
         <React.Fragment>
            <WarningIcon />
            <Typography variant="caption">Duplicate</Typography>
         </React.Fragment>
      );
   } else {
      return (
         <React.Fragment>
            <CheckCircleOutlineIcon color="primary" />
            <Typography color="primary" variant="caption">
               Ready
            </Typography>
         </React.Fragment>
      );
   }
}

const BulkManageDrawer = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [data, setData] = useState({});
   const [fileData, setFileData] = useState({});
   const [uploadComplete, setUploadComplete] = useState(false);
   const [validFile, setValidFile] = useState(true);
   const [duplicates, setDuplicates] = useState(0);
   const [emailError, setEmailError] = useState(0);
   var id = 0;

   async function importPeople(data) {
      let chunks = await chunkObject(data, 50);
      console.log(chunks, 'Chunks');
      chunks.map(async (chunk) => {
         await props.firebase.bulkImportPeople(
            chunk,
            props.displayFilter.cid,
            props.displayFilter.id,
            props.company.features,
         );
      });

      props.setBulkDrawer(false);
      setFileData({});
      setUploadComplete(false);
      setValidFile(true);
      props.setAlertMessage({ type: 'success', message: 'People import complete.' });
   }

   const ImportConfirm = () => {
      return (
         <React.Fragment>
            <Typography variant="subtitle1" className={classes.cardTitle}>
               Summary:
            </Typography>
            <Typography className={classes.cardTitle} variant="body1">
               {data.length} Lines ready to upload <br />
               {emailError} Errors ignored <br />
               {/* {duplicates} Duplicates ignored  */}
            </Typography>
            <Button
               className={classes.importButton}
               variant="contained"
               color="primary"
               startIcon={<SaveIcon />}
               onClick={() => {
                  importPeople(data, user);
               }}
            >
               Import {data.length - duplicates - emailError} People
            </Button>
         </React.Fragment>
      );
   };

   return (
      <Drawer
         anchor="right"
         open={props.bulkDrawer}
         title="Bulk upload"
         onClose={() => {
            props.setBulkDrawer(false);
            setFileData({});
            setUploadComplete(false);
            setValidFile(true);
            setDuplicates(0);
            setEmailError(0);
         }}
      >
         <Container maxWidth="md">
            <div className={classes.drawer}>
               {/* <Typography component="h1" variant="h5">
                  Bulk upload
               </Typography> */}
               <Card className={classes.card} elevation={0}>
                  <Grid container>
                     <Grid item xs={12} sm={3}>
                        <Typography
                           className={classes.cardTitle}
                           variant="h6"
                           gutterBottom
                        >
                           CSV import
                        </Typography>
                     </Grid>
                     <Grid item xs={12} sm={4}>
                        <Upload
                           firebase={props.firebase}
                           setAlertMessage={props.setAlertMessage}
                           setData={setData}
                           setFileData={setFileData}
                           fileData={fileData}
                           setValidFile={setValidFile}
                           validFile={validFile}
                           setUploadComplete={setUploadComplete}
                           uploadComplete={uploadComplete}
                           duplicates={duplicates}
                           setDuplicates={setDuplicates}
                           setEmailError={setEmailError}
                        />
                     </Grid>
                     <Grid item xs={12} sm={4}>
                        {uploadComplete ? (
                           <ImportConfirm
                              firebase={props.firebase}
                              duplicates={duplicates}
                              setBulkDrawer={props.setBulkDrawer}
                              setFileData={setFileData}
                              setValidFile={setValidFile}
                              setUploadComplete={setUploadComplete}
                              setAlertMessage={props.setAlertMessage}
                           />
                        ) : (
                           sampleFile(props)
                        )}
                     </Grid>
                     <Grid item xs={12}>
                        <Typography variant="caption">
                           Please note that we do not prevent duplicates being imported.
                           However, you will not be able to invite more than one person
                           with the same email address.
                        </Typography>
                     </Grid>
                  </Grid>
               </Card>
               {uploadComplete ? (
                  <Grid container>
                     <Grid item xs={12}>
                        <Typography variant="subtitle1">
                           Details to be imported:
                        </Typography>
                        <TableContainer className={classes.container}>
                           <Table stickyHeader classes={classes.table}>
                              <TableHead>
                                 <TableRow>
                                    <TableCell scope="col">First Name</TableCell>
                                    <TableCell scope="col">Last Name</TableCell>
                                    <TableCell scope="col">Email</TableCell>
                                    <TableCell scope="col">Status</TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {Object.values(data).map((user) => {
                                    id = id + 1;
                                    return (
                                       <TableRow
                                          className={
                                             user.exists ? classes.rowDuplicate : ''
                                          }
                                          key={id}
                                       >
                                          <TableCell>{user.firstname}</TableCell>
                                          <TableCell>{user.lastname}</TableCell>
                                          <TableCell
                                             className={
                                                user.valid ? '' : classes.emailError
                                             }
                                          >
                                             {user.email}
                                          </TableCell>
                                          <TableCell
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                             }}
                                          >
                                             {importRowStatus(user)}
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </Grid>
                  </Grid>
               ) : (
                  ''
               )}
            </div>
         </Container>
      </Drawer>
   );
};

export default withFirebase(BulkManageDrawer);
