import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
   TextField,
   MenuItem,
   Box,
   InputAdornment,
   IconButton,
   Typography,
   Divider,
} from '@mui/material';
import enLocale from 'date-fns/locale/en-GB';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { parse, stringify } from 'query-string';
import { useCompany } from '../../providers';
import { CardSection, PageLayout } from '../layout';
import { Card } from '@material-ui/core';

const Builder = () => {
   const location = useLocation();
   const { company } = useCompany();
   const [options, setOptions] = useState({
      type: 'schedulestatus',
      lid: null,
      date: new Date(),
      cid: company.cid,
   });
   const [locations, setLocations] = useState([]);
   const [url, setUrl] = useState('');

   useEffect(() => {
      if (company.locations) {
         let locs = [];
         Object.entries(company.locations).map(([key, value]) => {
            locs.push({ lid: key, name: value });
         });
         locs.sort((a, b) => a.name.localeCompare(b.name));
         setOptions({ ...options, lid: locs[0].lid });
         setLocations(locs);
      }
   }, [company]);

   useEffect(() => {
      const urlOrigin = window.location.origin;
      setUrl(`${urlOrigin}${location.pathname}?${generateURL(options)}`);
   }, [options, location]);

   function generateURL(query) {
      let string = `type=${query.type}&cid=${query.cid}`;
      Object.entries(query).forEach(([key, value]) => {
         if (
            key !== 'type' &&
            key !== 'cid' &&
            key !== 'date' &&
            value !== null &&
            value !== ''
         ) {
            string += `&${key}=${value}`;
         }
         if (key === 'date' && value !== null) {
            string += `&${key}=${formatDate(value)}`;
         }
      });
      return string;
   }

   function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth()).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
   }

   const handleCopy = () => {
      navigator.clipboard.writeText(url);
   };

   function handleChangeType(newType) {
      switch (newType) {
         case 'allusers':
            setOptions({
               type: 'allusers',
               cid: company.cid,
               date: null,
               lid: null,
            });
            break;
         case 'users':
            setOptions({ ...options, type: 'users', cid: company.cid, date: null });
            break;
         case 'schedulestatus': {
            let setup = { ...options };
            if (setup.lid === '') {
               setup.lid = locations[0].lid;
            }
            setOptions({
               ...options,
               type: 'schedulestatus',
               cid: company.cid,
               date: new Date(),
            });
            break;
         }
      }
   }

   return (
      <PageLayout>
         <Box>
            <CardSection>
               <Typography variant="h4" gutterBottom>
                  Report Query Builder
               </Typography>
               <Box display="flex" gap={2} mb={2}>
                  <TextField
                     select
                     label="Type"
                     value={options.type}
                     onChange={(e) => handleChangeType(e.target.value)}
                     fullWidth
                     margin="normal"
                  >
                     <MenuItem value="schedulestatus">Schedule Statuses</MenuItem>
                     <MenuItem value="users">Location Users</MenuItem>
                     <MenuItem value="allusers">All Users</MenuItem>
                  </TextField>
                  {options.lid !== null && (
                     <TextField
                        select
                        label="Location"
                        value={options.lid}
                        onChange={(e) => setOptions({ ...options, lid: e.target.value })}
                        fullWidth
                        margin="normal"
                     >
                        {locations.length > 0 &&
                           locations.map((loc) => (
                              <MenuItem key={loc.lid} value={loc.lid}>
                                 {loc.name}
                              </MenuItem>
                           ))}
                     </TextField>
                  )}
                  {options.type === 'schedulestatus' && (
                     <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={enLocale}
                     >
                        <DatePicker
                           format="dd/MM/YYYY"
                           label="Date"
                           value={options.date}
                           onChange={(newDate) =>
                              setOptions({ ...options, date: newDate })
                           }
                           renderInput={(params) => (
                              <TextField {...params} fullWidth margin="normal" />
                           )}
                        />
                     </LocalizationProvider>
                  )}
               </Box>
            </CardSection>
            <CardSection sx={{ mt: 2 }}>
               <Typography variant="h6" gutterBottom>
                  Generated URL
               </Typography>
               <Typography variant="body1" gutterBottom>
                  Copy this URL into the browser to get the results of this query.
               </Typography>
               <Box mt={2}>
                  <TextField
                     label="Generated URL"
                     value={url}
                     fullWidth
                     InputProps={{
                        readOnly: true,
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton onClick={handleCopy}>
                                 <ContentCopyIcon />
                              </IconButton>
                           </InputAdornment>
                        ),

                        sx: {
                           backgroundColor: '#f5f5f5',
                           fontFamily: 'monospace',
                           padding: '10px',
                           borderRadius: '4px',
                           '&:input': {
                              cursor: 'pointer',
                           },
                        },
                     }}
                     onClick={handleCopy}
                  />
               </Box>
            </CardSection>
         </Box>
      </PageLayout>
   );
};

export default Builder;
