import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import ItoWrapper from './Ito/itoWrapper';

import OffsiteWrapper from './Offsite/offsiteWrapper';
import Split from './split';
import WfhWrapper from './Wfh/wfhWrapper';
import AwayWrapper from './Away/awayWrapper';

const MiniChips = (props) => {
   const [dayStatus, setDayStatus] = useState(false);
   const styles = {
      borderRadius: 1,
      width: '26px',
      height: '40px',
      '& .MuiChip-label': {
         paddingLeft: 0,
         marginTop: 0.5,
      },
   };
   useEffect(() => {
      if (props.data) {
         let data = {
            ...props.data.status,
            bookings: null,
            offsiteName: null,
            offsiteLocation: null,
         };
         if (
            props.spacesEnabled &&
            props.bookings !== null &&
            props.bookings.length > 0
         ) {
            data.bookings = [];
            props.bookings.map((booking) => {
               let parentName = '';
               function getParent(space) {
                  if (space.parent !== '') {
                     let parent = `${props.spaces[space.parent].name} > `;
                     if (props.spaces[space.parent].parent !== '') {
                        parent = parent + getParent(props.spaces[space.parent]);
                     }

                     return parent;
                  }
               }
               parentName = getParent(props.spaces[booking.sid]);

               data.bookings.push({
                  parentName,
                  ...props.spaces[booking.sid],
                  ...booking,
               });
               data.bookings = [...data.bookings].sort((a, b) => {
                  if (a.type === 'All day') return -1;
                  if (a.type === 'AM' && b.type !== 'All day') return -1;
                  if (a.type === 'PM' && b.type !== 'All day' && b.type !== 'AM')
                     return -1;
                  return 1;
               });
            });
         }
         if (props.data.offsiteLocation !== null) {
            if (props.data.status.set === 'split') {
               data.offsiteLocation = { AM: null, PM: null };
               data.offsiteName = { AM: null, PM: null };
               if (props.data.offsiteLocation.AM) {
                  if (props.data.offsiteLocation.AM.locationId) {
                     data.offsiteLocation.AM = props.data.offsiteLocation.AM.locationId;
                     data.offsiteName.AM = props.data.offsiteLocation.AM.locationName;
                  } else {
                     data.offsiteName.AM = props.data.offsiteLocation.AM;
                  }
               }
               if (props.data.offsiteLocation.PM) {
                  if (props.data.offsiteLocation.PM.locationId) {
                     data.offsiteLocation.PM = props.data.offsiteLocation.PM.locationId;
                     data.offsiteName.PM = props.data.offsiteLocation.PM.locationName;
                  } else {
                     data.offsiteName.PM = props.data.offsiteLocation.PM;
                  }
               }
            } else {
               if (props.data.offsiteLocation.locationId) {
                  data.offsiteLocation = props.data.offsiteLocation.locationId;
                  data.offsiteName = props.data.offsiteLocation.locationName;
               } else {
                  data.offsiteName = props.data.offsiteLocation;
               }
            }
         }

         setDayStatus(data);
      }
   }, [props.data]);
   if (props.value) {
      switch (props.value.set) {
         case 'ito':
            return (
               <ItoWrapper
                  clickable={props.clickable}
                  styles={styles}
                  dayStatus={dayStatus}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            );
         case 'wfh':
            return (
               <WfhWrapper
                  clickable={props.clickable}
                  styles={styles}
                  dayStatus={dayStatus}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            );
         case 'offsite':
            return (
               <OffsiteWrapper
                  clickable={props.clickable}
                  styles={styles}
                  dayStatus={dayStatus}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            );
         case 'away':
            return (
               <AwayWrapper
                  clickable={props.clickable}
                  styles={styles}
                  dayStatus={dayStatus}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            );
         case 'split':
            return (
               <Split
                  clickable={props.clickable}
                  value={props.value}
                  dayStatus={dayStatus}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            );
         default:
            return (
               <React.Fragment>
                  {props.clickable ? (
                     <Chip
                        clickable={props.clickable}
                        size="small"
                        variant="outlined"
                        sx={{ border: '1px solid rgba(0, 0, 0, 0.23)' }}
                        label="-"
                     />
                  ) : (
                     <Chip
                        clickable={props.clickable}
                        size="small"
                        // variant="outlined"

                        label="-"
                     />
                  )}
               </React.Fragment>
            );
      }
   } else {
      return '-';
   }
};

export default MiniChips;
