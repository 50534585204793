import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DateToday } from '../../constants/functions';
import { withFirebase } from '../Firebase';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ExportButton from '../Reporting/exportButton';
import { Box, Tooltip } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { useCompany } from '../../providers';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      width: '60%',
      [theme.breakpoints.down('sm')]: {
         width: '85%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   label: {
      marginRight: theme.spacing(1),
   },
   wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
   },
   buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}));

const RegisterDrawer = (props) => {
   const { company } = useCompany();
   const classes = useStyles();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const date = DateToday();
   const [people, setPeople] = useState(false);
   const [employees, setEmployees] = useState(false);
   const [visitors, setVisitors] = useState(false);
   const [rows, setRows] = useState([
      { name: 'loading', type: '', checkin: '', checkout: '' },
   ]);
   const [exportData, setExportData] = useState(false);

   useEffect(() => {
      const loadPeople = async () => {
         if (props.location && props.visitorsDrawer && props.type === 'register') {
            let ppl = await props.firebase
               .companyPeopleIndex(props.location.cid)
               .get()
               .then((result) => {
                  return result.data();
               });
            console.log(ppl, 'ppl');
            setPeople(ppl);
         }
      };
      loadPeople();
   }, [props.location.id, props.type, props.visitorsDrawer]);

   useEffect(() => {
      if (props.location && props.visitorsDrawer && props.type === 'register') {
         const unsubscribe = props.firebase
            .findHost(props.location.id, date.ref)
            .onSnapshot((result) => {
               let vis = [];
               if (result.exists && result.data().visitorNo !== 0) {
                  Object.entries(result.data()).map(([key, value]) => {
                     if (key !== 'visitorNo') {
                        let person = {
                           uid: key,
                           name: value.name,
                           type: 'Visitor',
                           checkin: '',
                           checkout: '',
                        };
                        if (value.checkin) {
                           person.checkin = value.checkin
                              .toDate()
                              .toLocaleTimeString([], {
                                 hour: '2-digit',
                                 minute: '2-digit',
                              });
                        }
                        if (value.checkout) {
                           person.checkout = value.checkout
                              .toDate()
                              .toLocaleTimeString([], {
                                 hour: '2-digit',
                                 minute: '2-digit',
                              });
                        }
                        vis.push(person);
                     }
                  });
               }
               vis.sort((a, b) => {
                  return a.name.localeCompare(b.name);
               });
               setVisitors(vis);
            });
         return () => unsubscribe();
      }
   }, [props.location.id, props.type, props.visitorsDrawer]);

   useEffect(() => {
      if (props.location && props.visitorsDrawer && props.type === 'register' && people) {
         const unsubscribe = props.firebase
            .locationSchedule(props.location.id, date.ref)
            .onSnapshot((results) => {
               let emp = [];
               if (results.exists) {
                  Object.entries(results.data()).map(([key, value]) => {
                     if (
                        value.status === 'ito' ||
                        (value.status === 'split' &&
                           (value.splitDay.AM === 'ito' ||
                              value.splitDay.PM === 'ito')) ||
                        value.checkedIn
                     ) {
                        let person = {
                           uid: key,
                           lid: people[key].lid,
                           name: `${people[key].name.firstName} ${people[key].name.lastName}`,
                           type: 'Employee',
                           checkin: '',
                           checkout: '',
                        };
                        if (value.checkedIn) {
                           person.checkin = value.checkedInTime
                              .toDate()
                              .toLocaleTimeString([], {
                                 hour: '2-digit',
                                 minute: '2-digit',
                              });
                        }
                        if (value.checkedOut) {
                           person.checkout = value.checkedOutTime
                              .toDate()
                              .toLocaleTimeString([], {
                                 hour: '2-digit',
                                 minute: '2-digit',
                              });
                        }
                        emp.push(person);
                     }
                  });
               }
               emp.sort((a, b) => {
                  return a.name.localeCompare(b.name);
               });
               setEmployees(emp);
            });
         return () => unsubscribe();
      }
   }, [props.location.id, props.type, props.visitorsDrawer, people]);

   useEffect(() => {
      if ((visitors, employees)) {
         setRows([...employees, ...visitors]);
         loadExportFile([...employees, ...visitors]);
      }
   }, [visitors, employees]);

   async function loadExportFile(data) {
      let file = [];
      data.map((value, key) => {
         let person = {
            0: value.name,
            1: value.type,
         };
         if (value.checkin) {
            person[2] = 'true';
         } else {
            person[2] = '';
         }
         if (value.checkout) {
            person[3] = 'true';
         } else {
            person[3] = '';
         }
         file.push(person);
      });
      setExportData(file);
   }

   return (
      <div className={classes.root}>
         <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     flexWrap: 'wrap',
                     mt: 4,
                  }}
               >
                  <Typography sx={{ pr: 2 }} align="center" component="h1" variant="h5">
                     Live Office Register
                  </Typography>
                  <ExportButton
                     data={exportData}
                     headers={['Name', 'Type', 'Check in', 'Check out']}
                     time={true}
                     icon={true}
                  />
               </Box>
            </Grid>
            <Grid sx={{ minHeight: 90 }} item xs={12}>
               <TableContainer component={Paper}>
                  <Table
                     sx={{ minWidth: 650 }}
                     size="small"
                     aria-label="Export Table"
                     stickyHeader
                  >
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                           <TableCell>Type</TableCell>
                           <TableCell align="center">Check in</TableCell>
                           <TableCell align="center">Check out</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rows.map((person, key) => (
                           <TableRow
                              key={key}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component="th" scope="row">
                                 {props.location.id !== person.lid && (
                                    <Tooltip
                                       title={company.locations[person.lid]}
                                       placement="top"
                                    >
                                       <BusinessIcon
                                          fontSize="small"
                                          sx={{ color: 'grey', mr: 0.5 }}
                                       />
                                    </Tooltip>
                                 )}
                                 {person.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                 {person.type}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                 {person.checkin !== '' ? <TaskAltIcon /> : ''}
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                 {person.checkout !== '' ? <TaskAltIcon /> : ''}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
         </Grid>
      </div>
   );
};

export default withFirebase(RegisterDrawer);
