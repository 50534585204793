import { createMuiTheme } from '@material-ui/core';
import legacyThemeBase from './legacyThemeBase';

const legacyThemeDark = createMuiTheme({
   ...legacyThemeBase('dark'),
   palette: {
      type: 'dark',
      primary: {
         main: '#32BFC7',
      },
      secondary: {
         main: '#DB3D28',
      },
      teal: {
         main: '#32BFC7',
      },
      tableHead: {
         main: '#303030',
      },
      tableBody: {
         main: '#424242',
      },
      cardHeader: {
         main: '#646464',
      },
      cardAction: {
         main: '#303030',
      },
      text: {
         primary: '#fff',
         secondary: '#fff',
         success: '#9ECF99',
         warning: '#F7E943',
      },
      whiteButtonHoverText: {
         primary: '#342E76',
      },
      ito: {
         main: '#32BFC7',
      },
      wfh: {
         main: '#E6E6E6',
      },
      away: {
         main: '#DB3D28',
      },
      offsite: {
         main: '#346E9A',
      },
      capacityBackground: {
         main: '#646464',
      },
      success: {
         main: '#9ECF99',
      },
      warning: {
         main: '#F7E943',
      },
      availableTime: {
         main: '#ff9900',
      },
   },
});

export default legacyThemeDark;
