import React, { createContext, useContext, useEffect, useState } from 'react';
import { roundToNearestHour } from './utils';
const Context = createContext();

const SpacesContextProvider = ({ children, value = {}, ...rest }) => {
   const { detailsOpen, location, bookingForm, firebase } = value;
   const [people, setPeople] = useState([]);
   const [form, setForm] = useState(false);

   useEffect(() => {
      if (bookingForm) {
         setForm({
            title: '',
            host: {
               name: bookingForm.user.name.firstName + bookingForm.user.name.lastName,
               uid: bookingForm.user.uid,
            },
            start: roundToNearestHour(new Date()),
            end: roundToNearestHour(new Date()),
            seats: 1,
            internalParticipants: null,
            externalGuests: null,
            catering: '',
            IT: '',
         });
      }
   }, [bookingForm]);

   useEffect(() => {
      const loadPeople = async () => {
         if (detailsOpen && location && bookingForm) {
            // setForm({...form, owner : {id: user.uid, name: user.name.firstName + " " + user.name.lastName}, cid : user.cid});
            await firebase
               .locationPeople(location.id, bookingForm.user.cid)
               .orderBy('name.lastName')
               .orderBy('name.firstName')
               .get()
               .then((result) => {
                  let officePeople = [];
                  if (!result.empty) {
                     result.forEach((doc) => {
                        var person = {
                           name:
                              doc.data().name.firstName + ' ' + doc.data().name.lastName,
                           email: doc.data().email,
                           id: doc.id,
                           routine: false,
                        };
                        if (location.routine) {
                           person.routine = doc.data().routine;
                        }
                        officePeople.push(person);
                     });
                     setPeople(officePeople);
                  }
               });
         }
      };
      loadPeople();
   }, [detailsOpen, location, bookingForm, firebase]);

   return <Context.Provider value={{ ...value, people }}>{children}</Context.Provider>;
};

export const useSpacesContext = () => {
   const context = useContext(Context);

   if (!context) {
      console.error('use this hook within Spaces Provider');
   }

   return context;
};

export default SpacesContextProvider;
