import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../Firebase';
import { useTheme } from '@material-ui/core/styles';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import getDay from 'date-fns/getDay';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import CapacityGauge from '../Capacity';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
// import 'style.css';

const locales = {
   'en-GB': require('date-fns/locale/en-GB'),
};
const localizer = dateFnsLocalizer({
   format,
   parse,
   startOfWeek,
   getDay,
   locales,
});

const Month = (props) => {
   const theme = useTheme();
   const [month, setMonth] = useState(('0' + getMonth(new Date())).slice(-2));
   const [year, setYear] = useState(getYear(new Date()));
   const [events, setEvents] = useState([{}]);

   useEffect(() => {
      const getMonthCapacity = async () => {
         if (props.location && props.tab === 1) {
            var capNumber = null;
            var capCapacity = 100;

            // const capSettings = location.capacity;
            // const capUnit = location.capacity.unit;
            // if (capSettings[capUnit]){
            //   if(capSettings[capUnit].number) {
            //     capNumber = capSettings[capUnit].number;
            //   } else {
            capNumber = props.location.people;
            //   }
            //   if(capSettings[capUnit].capacity) {
            //     capCapacity = capSettings[capUnit].capacity;
            //   }
            // }
            var maxPercentage = (capCapacity / 100) * capNumber;

            const start = year + month + '01';
            const end = year + month + getDaysInMonth(new Date(year, month));

            var result = await props.firebase
               .locationStatusLevels(props.location.lid, start, end)
               .get();
            let day = [];
            if (!result.empty) {
               result.forEach((doc) => {
                  var ito = doc.data().ito ? doc.data().ito : 0;
                  if (doc.data()?.AM?.ito) {
                     ito = ito + doc.data().AM.ito / 2;
                  }
                  if (doc.data()?.PM?.ito) {
                     ito = ito + doc.data().PM.ito / 2;
                  }
                  var itoPercent = (ito / maxPercentage) * 100;
                  itoPercent = +itoPercent.toFixed(0);

                  var wfh = doc.data().wfh ? doc.data().wfh : 0;
                  if (doc.data()?.AM?.wfh) {
                     wfh = wfh + doc.data().AM.wfh / 2;
                  }
                  if (doc.data()?.PM?.wfh) {
                     wfh = wfh + doc.data().PM.wfh / 2;
                  }
                  var wfhPercent = (wfh / maxPercentage) * 100;
                  wfhPercent = +wfhPercent.toFixed(0);
                  var date = doc.id;
                  date = date.replace(
                     /(\d{4})(\d{2})(\d{2})/g,
                     '$1-' + (Number(month) + 1) + '-$3',
                  );
                  day.push({
                     start: new Date(date),
                     end: new Date(date),
                     allDay: true,
                     resource: {
                        percent: {
                           ito: itoPercent,
                           wfh: wfhPercent,
                        },
                     },
                  });
               });
            }
            setEvents(day);
         }
      };
      getMonthCapacity();
   }, [props.location, month, year]);

   const handleSelect = ({ start, end }) => {
      const title = window.prompt('New Event name');
      // if (title)
      //   this.setState({
      //     events: [
      //       ...this.state.events,
      //       {
      //         start,
      //         end,
      //         title,
      //       },
      //     ],
      //   })
   };
   const onNavigate = (date, view) => {
      setMonth(('0' + getMonth(date)).slice(-2));
      setYear(getYear(date));
   };
   function EventAgenda({ event }) {
      return <CapacityGauge capacity={event.resource.percent} mini multi />;
   }
   let components = {
      month: {
         event: EventAgenda,
      },
   };

   return (
      <Grid container item>
         <Grid item xs={6}></Grid>
         <Grid item xs={6}>
            <div>
               <span
                  style={{
                     color: theme.palette.ito.main,
                     display: 'flex',
                     alignItems: 'center',
                     flexWrap: 'wrap',
                  }}
               >
                  {<BusinessIcon size="small" />} working in the office
               </span>
               <span
                  style={{
                     color: theme.palette.wfh.main,
                     display: 'flex',
                     alignItems: 'center',
                     flexWrap: 'wrap',
                  }}
               >
                  {<HomeIcon size="small" />} working from home
               </span>
            </div>
         </Grid>
         <Grid item xs={12}>
            <Calendar
               selectable={false}
               views={{ month: true }}
               localizer={localizer}
               events={events}
               startAccessor="start"
               endAccessor="end"
               style={{ height: 600 }}
               onSelectSlot={handleSelect}
               // onSelectEvent={event => alert(event.title)}
               onNavigate={onNavigate}
               components={components}
            />
         </Grid>
      </Grid>
   );
};

export default withFirebase(Month);
