import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withFirebase } from '../../Firebase';
import { Box, FormLabel, Radio, RadioGroup } from '@mui/material';

const SchedulePanel = (props) => {
   const [adminEdit, setAdminEdit] = useState('defaultOff');
   const [userEdit, setUserEdit] = useState('defaultOff');

   const handleEditPreviousChange = (event) => {
      setAdminEdit(event.target.value);
      handleChange(event.target.value, 'admin');
   };
   const handleEditPreviousUsersChange = (event) => {
      setUserEdit(event.target.value);
      handleChange(event.target.value, 'user');
   };
   useEffect(() => {
      if (props.expanded === 'scheduling') {
         const unsubscribe = props.firebase.location(props.lid).onSnapshot((result) => {
            if (result.exists) {
               if (result.data().permissions && result.data().permissions.editPrevious) {
                  if (result.data().permissions.editPrevious?.admin) {
                     setAdminEdit(result.data().permissions.editPrevious.admin);
                  }
                  if (result.data().permissions.editPrevious?.user) {
                     setUserEdit(result.data().permissions.editPrevious.user);
                  }
               }
            }
         });
         return () => unsubscribe();
      }
   }, [props.expanded, props.lid]);

   async function handleChange(event, type) {
      try {
         await props.firebase
            .location(props.lid)
            .set({ permissions: { editPrevious: { [type]: event } } }, { merge: true });
      } catch (error) {
         console.log(error, 'Error setting edit day permissions');
      }
   }

   return (
      <Box>
         <Typography variant="h6" component="h2">
            Editing previous days permissions
         </Typography>
         <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box
               sx={{
                  width: { xs: '100%', sm: '100%', md: '40%' },
                  mr: { xs: 0, sm: 2 },
               }}
            >
               <Typography variant="subtitle1">
                  Control how Admins and Users can edit previous days.
               </Typography>
               <Box>
                  <Typography variant="subtitle2">
                     - "Always On" - Edit previous days as soon as the Schedule loads.
                  </Typography>
                  <Typography variant="subtitle2">
                     - "Prime Location On" - Always On for Admins in relation to their
                     primary location, otherwise it defaults to off.
                  </Typography>
                  <Typography variant="subtitle2">
                     - "Default Off" - Edit previous days must be enabled each time.
                  </Typography>
                  <Typography variant="subtitle2">
                     - "Disabled" - No option to edit previous days from the Schedule.
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  width: { xs: '100%', sm: '30%', md: '20%' },
                  mt: { xs: 1, sm: 2, md: 0 },
               }}
            >
               <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Admins</FormLabel>
                  <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={adminEdit}
                     onChange={handleEditPreviousChange}
                  >
                     <FormControlLabel
                        value="alwaysOn"
                        control={<Radio />}
                        label="Always On"
                     />
                     <FormControlLabel
                        value="alwaysOnPrime"
                        control={<Radio />}
                        label="Prime Location On"
                     />
                     <FormControlLabel
                        value="defaultOff"
                        control={<Radio />}
                        label="Default Off"
                     />
                  </RadioGroup>
               </FormControl>
            </Box>
            <Box
               sx={{
                  width: { xs: '100%', sm: '30%', md: '20%' },
                  mt: { xs: 1, sm: 2, md: 0 },
               }}
            >
               <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Users</FormLabel>
                  <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={userEdit}
                     onChange={handleEditPreviousUsersChange}
                  >
                     <FormControlLabel
                        value="alwaysOn"
                        control={<Radio />}
                        label="Always On"
                     />
                     <FormControlLabel
                        value="defaultOff"
                        control={<Radio />}
                        label="Default Off"
                     />
                     <FormControlLabel
                        value="removed"
                        control={<Radio />}
                        label="Disabled"
                     />
                  </RadioGroup>
               </FormControl>
            </Box>
         </Box>
      </Box>
   );
};

export default withFirebase(SchedulePanel);
