import React from 'react';
import {
   ListItem,
   ListItemAvatar,
   Avatar,
   ListItemText,
   Typography,
} from '@mui/material';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../providers';

function stringToColor(string) {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */

   return color;
}

function stringAvatar(name) {
   return {
      sx: {
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   };
}

const VisitorListItem = ({ value, type }) => {
   const authUser = useAuth();
   function timeToString(time) {
      return time.toDate().toLocaleTimeString([], {
         hour: '2-digit',
         minute: '2-digit',
      });
   }
   return (
      <>
         <ListItemAvatar>
            <Avatar {...stringAvatar(value.name)} />
         </ListItemAvatar>
         <ListItemText
            primary={
               <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body1">
                     {value.name}
                  </Typography>

                  {value.owner && value.owner.uid === authUser.uid && (
                     <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                     >
                        {` - ${value.uid}`}
                     </Typography>
                  )}
               </React.Fragment>
            }
            secondary={
               <React.Fragment>
                  {type === 'expected' && (
                     <span style={{ display: 'flex' }}>
                        <Typography component="span" variant="caption" paragraph>
                           Expected times:{' '}
                           {value.arrivalTime ? timeToString(value.arrivalTime) : '" "'}
                           {' - '}
                           {value.departureTime
                              ? timeToString(value.departureTime)
                              : '" "'}
                        </Typography>
                     </span>
                  )}

                  {type === 'checkedIn' && (
                     <span style={{ display: 'flex' }}>
                        <span
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <AssignmentTurnedInIcon fontSize="small" />
                           <Typography component="span" variant="caption">
                              Checked in at: {timeToString(value.checkin)}
                           </Typography>
                        </span>
                        {value.departureTime && (
                           <Typography
                              component="span"
                              variant="caption"
                              sx={{ ml: 2, fontStyle: 'italic' }}
                           >
                              {` Expected checkout: ${timeToString(value.departureTime)}`}
                           </Typography>
                        )}
                     </span>
                  )}
                  {type === 'checkedOut' && (
                     <span style={{ display: 'flex' }}>
                        <span
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <AssignmentTurnedInIcon fontSize="small" />
                           <Typography component="span" variant="caption">
                              Checked in at: {timeToString(value.checkin)}
                           </Typography>
                        </span>
                        <span
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              marginLeft: '8px',
                           }}
                        >
                           <ExitToAppIcon fontSize="small" />
                           <Typography component="span" variant="caption">
                              Checked out at: {timeToString(value.checkout)}
                           </Typography>
                        </span>
                     </span>
                  )}

                  <Typography variant="caption" sx={{ mt: 1 }}>
                     Host: {value.host.name}
                  </Typography>
               </React.Fragment>
            }
         />
      </>
   );
};
export default VisitorListItem;
