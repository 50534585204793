import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const OffsiteMiniPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="offsite-mini-pm" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M26,0 L26,24.862325 C26,27.1474998 24.209139,29 22,29 L4,29 C1.790861,29 0,27.1474998 0,24.862325 L0,17.1041844 L26,0 Z M25,1.5093518 L1.0024487,17.6635581 L1,24.862325 C1,26.5149961 2.24891996,27.8659263 3.82372721,27.9603133 L4,27.9655813 L22,27.9655813 C23.6568542,27.9655813 25,26.5762061 25,24.862325 L25,1.5093518 Z M20.5833333,15.5882353 C20.5833333,13.0570588 18.3977083,11 15.7083333,11 C13.0189583,11 10.8333333,13.0570588 10.8333333,15.5882353 C10.8333333,19.0294118 15.7083333,24 15.7083333,24 C15.7083333,24 20.5833333,19.0294118 20.5833333,15.5882353 Z M14.0833333,15.5 C14.0833333,14.675 14.8145833,14 15.7083333,14 C16.6020833,14 17.3333333,14.675 17.3333333,15.5 C17.3333333,16.325 16.6102083,17 15.7083333,17 C14.8145833,17 14.0833333,16.325 14.0833333,15.5 Z M9.75,24 L9.75,26 L21.6666667,26 L21.6666667,24 L9.75,24 Z"
                  id="Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default OffsiteMiniPm;
