import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { withFirebase } from '../../Firebase';
import { Box, FormHelperText } from '@mui/material';
import { useAuth, useCompany } from '../../../providers';

const CompanySelect = (props) => {
   const { company } = useCompany();
   const { authUser } = useAuth();
   console.log(company, authUser, 'company');

   const [listLocations, setListLocations] = useState(null);
   const [error, setError] = useState({ error: false, text: '' });
   const [excludedLocations, setExcludedLocations] = useState([]);
   const [deleteError, setDeleteError] = useState(false);

   useEffect(() => {
      const loadLocations = async () => {
         const locs = [];
         if (company.publishedLocations) {
            for (const [key, value] of Object.entries(company.publishedLocations)) {
               if (key && key !== props.lid) {
                  locs.push({ lid: key, name: company.locations[key], selected: false });
               }
            }
         }
         setListLocations(locs);
         if (company.excludedLocations && company.excludedLocations[props.lid]) {
            let exclude = [];
            company.excludedLocations[props.lid].map((exclid) => {
               exclude.push(company.locations[exclid]);
            });
            setExcludedLocations(exclude);
         }
      };
      loadLocations();
   }, [company, props.lid]);

   const handleChange = async (event) => {
      const {
         target: { value },
      } = event;

      let updatedExceptions = [];
      value.map((val) => {
         listLocations.map((loc) => {
            if (loc.name === val) {
               updatedExceptions.push(loc.lid);
            }
         });
      });

      try {
         await props.firebase.company(authUser.cid).set(
            {
               excludedLocations: { [props.lid]: updatedExceptions },
            },
            { merge: true },
         );
      } catch (error) {
         setError({ error: true, text: error.message });
      }
      setExcludedLocations(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      );
   };

   return (
      <Box sx={{ p: 3, width: '100%' }}>
         {listLocations !== null && (
            <FormControl fullWidth error={error.error}>
               <InputLabel id="demo-simple-select-label">Location</InputLabel>
               {console.log(excludedLocations, 'excludedLocations')}
               <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  value={excludedLocations}
                  label="Admin Select"
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={handleChange}
                  fullWidth
               >
                  {listLocations.map((location) => (
                     <MenuItem key={location.lid} value={location.name}>
                        <Checkbox
                           checked={excludedLocations.indexOf(location.name) > -1}
                        />
                        <ListItemText primary={location.name} />
                     </MenuItem>
                  ))}
               </Select>
               <FormHelperText>{error.text}</FormHelperText>
            </FormControl>
         )}
      </Box>
   );
};

export default withFirebase(CompanySelect);
