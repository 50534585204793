import React from 'react';
import { DateToday } from '../../constants/functions';
import { withFirebase } from '../Firebase';

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
   }

   static getDerivedStateFromError(error) {
      /*
         here is where we would change something about the UI if there was an error.
         Instead, we are erroring out silently
      */
      // return { hasError: true };
   }

   async logToDb(logObject) {
      const dateRef = DateToday();
      await this.props.firebase.db
         .doc(`logs/${dateRef.ref}`)
         .set({ [new Date().toISOString().split('T')[1]]: logObject }, { merge: true });
   }

   componentDidCatch(error, errorInfo) {
      const [date, time] = new Date().toISOString().split('T');
      const authUser = JSON.parse(localStorage.getItem('authUser'));

      const log = {
         time,
         date,
         user: {
            ...authUser,
         },
         userLocation: authUser.lid[0],
         companyId: authUser.cid,
         device: {
            platform: window.navigator.userAgentData.platform,
            ...window.navigator.userAgentData.brands,
            mobile: window.navigator.userAgentData.mobile,
         },
         incidentMetaData: {
            url: window.document.location.href,
         },
         errorData: JSON.stringify({ error, errorInfo }),
      };

      if (process.env.NODE_ENV === 'production') {
         this.logToDb(log);
         return;
      }
      console.info(log);
   }

   render() {
      return this.props.children;
   }
}

export default withFirebase(ErrorBoundary);
