import React from 'react';
import { Chip, Tooltip, Box } from '@mui/material';
import ToolTipContent from './toolTipContent';
import SplitMiniChips from './splitMiniChips';
import SplitIcons from './splitIcons';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Split = (props) => {
   const [pmBooked, setPmBooked] = React.useState(false);
   React.useEffect(() => {
      let pmBookedStatus = false;

      if (props.dayStatus && props.dayStatus.bookings !== null) {
         for (let booking of props.dayStatus.bookings) {
            if (booking.type === 'PM') {
               pmBookedStatus = true;
               break;
            }
         }
      }
      setPmBooked(pmBookedStatus);
   }, [props.dayStatus]);

   const openDrawer = () => {
      props.setStatusDrawerOpen(true);
      props.setSpaceData(props.data);
   };
   const splitMiniStyles = {
      borderRadius: 0,
      width: '26px',
      height: '40px',
      border: 0,
      '& .MuiChip-label': {
         paddingLeft: 0,
         marginTop: 0.5,
         width: '26px',
         height: '40px',
      },
      '& .MuiSvgIcon-root': {
         width: '26px',
         height: '29px',
      },
   };
   const splitStyles = {
      // borderRadius: 0,
      // width: '26px',
      // height: '40px',
      // border: 0,
      '& .MuiChip-label': {
         paddingLeft: 0,
         paddingRight: 0,
         // marginTop: 0.5,
      },
      '& .MuiSvgIcon-root': {
         // width: '26px',
         // height: '29px',
      },
   };

   return (
      <Tooltip
         title={<ToolTipContent dayStatus={props.dayStatus} />}
         placement="top"
         arrow
      >
         <div>
            {props.miniChips ? (
               <Chip
                  size="small"
                  sx={splitMiniStyles}
                  variant="outlined"
                  clickable={props.clickable}
                  label={
                     <SplitMiniChips value={props.value} dayStatus={props.dayStatus} />
                  }
               />
            ) : (
               <div>
                  {props.clickable ? (
                     <Chip
                        variant={pmBooked ? 'contained' : 'outlined'}
                        color="primary"
                        sx={splitStyles}
                        clickable={props.clickable}
                        label={
                           <SplitIcons
                              value={props.value}
                              dayStatus={props.dayStatus}
                              pmBooked={pmBooked}
                              clickable={true}
                           />
                        }
                        onDelete={() => openDrawer()}
                        deleteIcon={<MoreVertIcon />}
                     />
                  ) : (
                     <Chip
                        variant={pmBooked ? 'contained' : 'outlined'}
                        color="primary"
                        sx={splitStyles}
                        clickable={props.clickable}
                        label={
                           <SplitIcons
                              value={props.value}
                              dayStatus={props.dayStatus}
                              pmBooked={pmBooked}
                              clickable={false}
                           />
                        }
                     />
                  )}
               </div>
            )}
         </div>
      </Tooltip>
   );
};
export default Split;
