import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import { usePage } from '../layout/PageLayout';
import { useLocations } from '../../providers';

const StyledMenu = withStyles({
   paper: {
      border: '1px solid #d3d4d5',
   },
})((props) => (
   <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
      }}
      {...props}
   />
));

const ScheduleMenu = (props) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const [locations, setLocations] = useState(null);
   const pageContext = usePage();
   const { allLocations } = useLocations();
   const authUser = JSON.parse(localStorage.getItem('authUser'));

   const handleClick = (event) => {
      let locs = [];
      props.firebase
         .company(authUser.cid)
         .get()
         .then((result) => {
            Object.entries(result.data().locations).map(([key, value], i) => {
               locs.push({ id: key, title: value, type: 'location', cid: result.id });
            });
            locs.sort(function (a, b) {
               var textA = a.title.toUpperCase();
               var textB = b.title.toUpperCase();
               return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            // locs.unshift({id:result.id, title: result.data().companyName, type: 'company', cid: result.id});
            setLocations(locs);
         });
      setAnchorEl(event.currentTarget);
   };

   const handleClose = (event, item) => {
      if (item.title) {
         props.setDisplayFilter(item);
         if (
            pageContext &&
            pageContext.selectedLocationId &&
            pageContext.handleSelectLocation
         ) {
            pageContext.handleSelectLocation(undefined, item.id);
         }
      }
      setAnchorEl(null);
   };

   return (
      <div style={{ marginLeft: '16px' }}>
         <div
            onClick={handleClick}
            style={{
               display: 'inline-flex',
               VerticalAlign: 'text-bottom',
               BoxSizing: 'inherit',
               textAlign: 'center',
               AlignItems: 'center',
            }}
         >
            {pageContext && pageContext.handleSelectLocation && allLocations ? (
               <Typography variant="h5" component="h1" color="primary" position="fixed">
                  {allLocations[pageContext.selectedLocationId]}
               </Typography>
            ) : (
               <Typography variant="h5" component="h1" color="primary" position="fixed">
                  {props.displayFilter.title}
               </Typography>
            )}
            <ExpandMoreIcon fontSize="large" color="primary" />
         </div>
         <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'left',
            }}
         >
            {locations ? (
               locations.map((item) => (
                  <MenuItem key={item.id} onClick={(event) => handleClose(event, item)}>
                     {item.title}
                  </MenuItem>
               ))
            ) : (
               <MenuItem>Loading...</MenuItem>
            )}
         </StyledMenu>
      </div>
   );
};

export default withFirebase(ScheduleMenu);
