import React from 'react';
import SideBarItem from './SideBarItem';
import { MAIN_UNAUTHENTICATED_ROUTES } from '../../../constants/routes';

const UnAuthenticatedNav = () => {
   const shownRoutes = Object.values(MAIN_UNAUTHENTICATED_ROUTES);
   return shownRoutes.map((navItem, index) => (
      <SideBarItem key={index} navItem={navItem} />
   ));
};

export default UnAuthenticatedNav;
