export async function processUnderwrite(user, today, schedule, routine) {
   let statusObject = {};
   Object.entries(schedule).map(([key, value]) => {
      var year = key.substring(0, 4);
      var month = key.substring(4, 6);
      var day = key.substring(6, 8);
      var date = new Date(year, month, day);
      var dayNo = date.getDay();
      if (routine[dayNo].set !== '-') {
         if (!value.routine) {
            statusObject[key] = {
               uid: user.id,
               dayNo,
               lidOld: value.lid,
               lid: user.lid[0],
               cid: user.cid,
               status: value.status,
               routine: routine[dayNo].set,
               overridden: true,
               type: 'new',
               schedule: value,
            };
            if (value.status === routine[dayNo].set) {
               statusObject[key].overridden = false;
            }
         } else {
            if (value.routine !== routine[dayNo].set) {
               statusObject[key] = {
                  uid: user.id,
                  dayNo,
                  lidOld: value.lid,
                  lid: user.lid[0],
                  cid: user.cid,
                  status: value.status,
                  routine: routine[dayNo].set,
                  oldRoutine: value.routine,
                  overridden: true,
                  oldOverridden: true,
                  type: 'update',
                  schedule: value,
               };
               if (value.status === routine[dayNo].set) {
                  statusObject[key].overridden = false;
               }
               if (value.status === value.routine) {
                  statusObject[key].oldOverridden = false;
               }
            }
         }
      } else if (routine[dayNo].set === '-' && value.routine) {
         statusObject[key] = {
            uid: user.id,
            dayNo,
            lidOld: value.lid,
            lid: user.lid[0],
            cid: user.cid,
            status: value.status,
            oldRoutine: value.routine,
            type: 'remove',
            overridden: true,
         };
         if (value.status === value.routine) {
            statusObject[key].overridden = false;
         }
      }
   });

   return statusObject;
}
