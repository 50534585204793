import React, { useEffect, useState, useMemo } from 'react';
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import { uid } from 'uid';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const ExportButton = (props) => {
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      if (props.headers && props.data) {
         setLoading(false);
      }
   }, [props.headers, props.data]);

   const dataToExport = useMemo(() => props.data, [props.data]);
   const dataExportId = useMemo(() => uid(16), []); // create a unique and identifyable ID for each file downloaded
   async function loadExport() {
      const options = {
         fieldSeparator: ',',
         quoteStrings: '"',
         decimalSeparator: '.',
         showLabels: true,
         showTitle: false,
         filename: `ITO-Export-${dataExportId}`,
         useTextFile: false,
         useBom: true,
         useKeysAsHeaders: false,
         headers: props.headers,
      };
      if (props.time) {
         var date = new Date();
         date = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
         ).slice(-2)}-${('0' + date.getHours()).slice(-2)}${(
            '0' + date.getMinutes()
         ).slice(-2)}`;
         options.filename = `ITO-Export-${date}`;
      }
      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(dataToExport);
   }
   if (props.icon) {
      return (
         <IconButton color="primary" onClick={() => loadExport()} disabled={loading}>
            <DownloadIcon />
         </IconButton>
      );
   } else {
      return (
         <Button variant="contained" onClick={() => loadExport()} disabled={loading}>
            Export
         </Button>
      );
   }
};

export default ExportButton;
