import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SplitBlocksAccordionBody from './splitBlocksAccordionBody';

const Accordion = styled((props) => (
   <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
   border: `1px solid ${theme.palette.divider}`,
   '&:not(:last-child)': {
      borderBottom: 0,
   },
   '&:before': {
      display: 'none',
   },
}));

const AccordionSummary = styled((props) => (
   <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
   />
))(({ theme }) => ({
   minHeight: '24px',
   height: '26px',
   backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
   flexDirection: 'row-reverse',
   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
   },
   '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
   },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   padding: theme.spacing(2),
   borderTop: '1px solid rgba(0, 0, 0, .125)',
   maxHeight: '500px',
   overflow: 'scroll',
}));
const SplitBlocks = (props) => {
   const handleChange = (newValue) => {
      if (newValue === props.dayTime) {
         if (newValue === 'AM') {
            props.setDayTime('PM');
         } else {
            props.setDayTime('AM');
         }
      } else {
         props.setDayTime(newValue);
      }
   };

   //
   // LATEST
   // for some reason the SplitBlocksAccordion component is not rerendering when a prop changes.
   // I feel like this must be due to the accordions rerendering, as it all appears to work properly on a single day.
   // Although, seting a const of dayTime to be AM, still re-renders the accordion.
   //

   return (
      <Box sx={{ mt: 2, width: '100%', typography: 'body1' }}>
         <Accordion expanded={props.dayTime === 'AM'} onChange={() => handleChange('AM')}>
            <AccordionSummary aria-controls="panel1bh-content" id="AM">
               <Typography sx={{ width: '33%', flexShrink: 0 }}>AM</Typography>
               <Typography sx={{ color: 'text.secondary' }}></Typography>
            </AccordionSummary>
            <AccordionDetails>
               <SplitBlocksAccordionBody
                  data={props.data}
                  user={props.data.user}
                  date={props.data.date}
                  status={props.status}
                  dayTime={'AM'}
                  displayFilter={props.displayFilter}
                  spacesEnabled={props.spacesEnabled}
               />
            </AccordionDetails>
         </Accordion>
         <Accordion expanded={props.dayTime === 'PM'} onChange={() => handleChange('PM')}>
            <AccordionSummary aria-controls="panel1bh-content" id="PM">
               <Typography sx={{ width: '33%', flexShrink: 0 }}>PM</Typography>
               <Typography sx={{ color: 'text.secondary' }}></Typography>
            </AccordionSummary>
            <AccordionDetails>
               <SplitBlocksAccordionBody
                  data={props.data}
                  user={props.data.user}
                  date={props.data.date}
                  status={props.status}
                  dayTime={'PM'}
                  displayFilter={props.displayFilter}
                  spacesEnabled={props.spacesEnabled}
               />
            </AccordionDetails>
         </Accordion>
      </Box>
   );
};

export default SplitBlocks;
