import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import { withFirebase } from '../Firebase';
import { ListItemIcon } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import QuickActions from '../QuickActions';

const useStyles = makeStyles((theme) => ({
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
}));

function DateToday(day) {
   var date = new Date();
   date.setDate(date.getDate() + day);
   const d = ('0' + date.getDate()).slice(-2);
   const m = ('0' + date.getMonth()).slice(-2);
   const today = {
      day: d,
      month: m,
      year: date.getFullYear(),
      weekDay: date.getDay(),
      ref: date.getFullYear() + m + d,
   };
   return today;
}

const QuickActionsBlock = ({ location, displayFilter }) => {
   const classes = useStyles();
   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

   const today = DateToday(0);
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   return (
      <Paper className={fixedHeightPaper}>
         <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Quick actions
         </Typography>
         <QuickActions location={location} displayFilter={displayFilter} />
      </Paper>
   );
};

export default withFirebase(QuickActionsBlock);
