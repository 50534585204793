import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const ItoPmBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/ITOPM-booked"
               fill={theme.palette.tableBody.main}
               fillRule="nonzero"
            >
               <g id="done-24px" transform="translate(0, -0)">
                  <polyline
                     id="Path"
                     points="22.7128988 19.4057453 18.7925104 15.1242236 17.4857143 16.5513975 22.7128988 22.2600932 33.9140083 10.0271739 32.6072122 8.6"
                  ></polyline>
               </g>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoPmBooked;
