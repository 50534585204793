import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import RoomIcon from '@mui/icons-material/Room';
import { withFirebase } from '../Firebase';
import { calculateAvailability } from './utils';
import SpaceCardContent from './cardDetails/cardContent';
import BookingForm from './cardDetails/bookingForm';
import DetailsDialog from './detailsDialog';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SpaceMap from './spaceMap';
import SpaceCardHeader from './cardDetails/spaceCardHeader';

const useStyles = makeStyles((theme) => ({
   root: {
      maxWidth: 345,
      width: 250,
      margin: 8,
      display: 'inline-block',
      verticalAlign: 'top',
      whiteSpace: 'normal',
   },
   media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
   },
   expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
         duration: theme.transitions.duration.shortest,
      }),
   },
   cardAction: {
      backgroundColor: theme.palette.cardAction.main,
   },
   cardHeader: {
      backgroundColor: theme.palette.cardHeader.main,
   },
   expandOpen: {
      transform: 'rotate(180deg)',
   },
   avatar: {
      backgroundColor: red[500],
   },
   center: {
      margin: 'auto 0',
      textAlign: 'center',
   },
   right: {
      marginLeft: 'auto',
   },
}));

const SpaceAvailableCard = (props) => {
   const theme = useTheme();
   const classes = useStyles();
   const user = props.user;
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [image, setImage] = useState('');
   const [imageExists, setImageExists] = useState(false);
   const [bookingForm, setBookingForm] = useState(false);
   const [bookingOptions, setBookingOptions] = useState({
      0: 'All day',
      2: 'AM',
      3: 'PM',
      4: 'Hourly',
   });
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [spaceSchedule, setSpaceSchedule] = useState(false);
   const [map, setMap] = useState(false);
   const [detailsData, setDetailsData] = useState(false);

   useEffect(() => {
      if (props.space.timePeriod.hourly) {
         const unsubscribe = props.firebase
            .getSpaceDaySchedule(props.location.id, props.space.sid, props.date.ref)
            .onSnapshot((result) => {
               if (result.exists) {
                  let sched = {};
                  Object.entries(result.data().bookings).map(([key, value]) => {
                     let schedStart = value.start;
                     let schedEnd = value.end;
                     if (value.checkInTime && value.start > value.checkInTime) {
                        schedStart = value.checkInTime;
                     }
                     if (value.checkOutTime && value.end > value.checkOutTime) {
                        schedEnd = value.checkOutTime;
                     }
                     sched[key] = value;
                     sched[key].start = schedStart;
                     sched[key].end = schedEnd;
                  });
                  let capacityAmount = calculateAvailability(result.data(), props.date);
                  setSpaceSchedule({
                     bookingNo: result.data().bookingNo,
                     bookings: sched,
                     capacity: capacityAmount,
                  });
               } else {
                  setSpaceSchedule({ capacity: 'Free' });
               }
            });
         return () => unsubscribe();
      }
   }, [props.space]);
   useEffect(() => {
      if (props.space) {
         let form = { seats: 1, user, type: 0, typeName: 'All day' };
         let options = {
            0: 'All day',
            2: 'AM',
            3: 'PM',
            4: 'Hourly',
         };
         if (props.space.name === 'A1 Group area') {
            console.log('>>>>>>', options, props.space);
         }
         if (props.space.timePeriod.hourly) {
            form.type = 4;
            form.typeName = 'Hourly';
         } else if (props.dayTime == 'AM') {
            form.type = 2;
            form.typeName = 'AM';
         } else if (props.dayTime == 'PM') {
            form.type = 3;
            form.typeName = 'PM';
         } else {
            form.type = 0;
            form.typeName = 'All day';
            if (props.space.seatsAvailable === 0) {
               if (props.space.seatsAvailableAM !== 0) {
                  form.type = 2;
                  form.typeName = 'AM';
                  delete options[4];
                  delete options[3];
                  delete options[0];
               } else if (props.space.seatsAvailablePM !== 0) {
                  form.type = 3;
                  form.typeName = 'PM';
                  delete options[4];
                  delete options[2];
                  delete options[0];
               }
            }
         }

         if (!props.space.timePeriod.allDay) {
            delete options[0];
            delete options[2];
            delete options[3];
         }
         if (!props.space.timePeriod.hourly) {
            delete options[4];
         }
         if (props.dayTime == 'AM') {
            delete options[4];
            delete options[3];
            delete options[0];
         }
         if (props.dayTime == 'PM') {
            delete options[4];
            delete options[2];
            delete options[0];
         }
         if (props.space.name === 'A1 Group area') {
            console.log('>>>>>>', options);
         }
         setBookingOptions(options);
         setBookingForm(form);
      }
   }, [props.space, props.dayTime]);

   useEffect(() => {
      let isMounted = true;

      if (props.space.imagePath) {
         var imageRef = props.firebase.showImage(props.space.imagePath);
         imageRef
            .getDownloadURL()
            .then(function (url) {
               if (isMounted) {
                  setImage(url);
                  setImageExists(true);
               }
            })
            .catch(function (error) {
               console.warn(error, 'error');
            });
      }

      return () => {
         isMounted = false;
      };
   }, []);

   async function showMap() {
      setMap(!map);
   }

   return (
      <React.Fragment>
         {bookingForm && (
            <React.Fragment>
               <DetailsDialog
                  setDetailsOpen={setDetailsOpen}
                  detailsOpen={detailsOpen}
                  date={props.date}
                  space={props.space}
                  location={props.location}
                  bookingForm={bookingForm}
                  setBookingForm={setBookingForm}
                  spaceSchedule={spaceSchedule}
                  setDetailsData={setDetailsData}
                  detailsData={detailsData}
               />
               {props.space.mapPath && (
                  <SpaceMap
                     spaceKey={props.space.sid}
                     locationKey={props.location.lid}
                     map={map}
                     setMap={setMap}
                  />
               )}
               <Card
                  className={classes.root}
                  style={{
                     borderLeft: props.space.backgroundColor ? `4px solid` : '0px solid',
                     borderLeftColor: props.space.backgroundColor
                        ? props.space.backgroundColor
                        : '#C8C8C8',
                  }}
               >
                  <CardHeader
                     className={classes.cardHeader}
                     action={
                        props.space.mapPath ? (
                           <IconButton onClick={() => showMap()}>
                              <RoomIcon />
                           </IconButton>
                        ) : (
                           <React.Fragment></React.Fragment>
                        )
                     }
                     title={<SpaceCardHeader space={props.space} user={props.user} />}
                  />

                  {imageExists && !props.dense && (
                     <CardMedia
                        className={classes.media}
                        image={image}
                        title={props.space.name}
                     />
                  )}

                  <SpaceCardContent
                     user={props.user}
                     space={props.space}
                     date={props.date}
                     dense={props.dense}
                     spaceSchedule={spaceSchedule}
                     dayTime={props.dayTime}
                     bookingForm={bookingForm}
                  />
                  <BookingForm
                     user={props.user}
                     space={props.space}
                     date={props.date}
                     dense={props.dense}
                     spaceSchedule={spaceSchedule}
                     bookingForm={bookingForm}
                     setBookingForm={setBookingForm}
                     bookingOptions={bookingOptions}
                     status={props.status}
                     location={props.location}
                     setDetailsOpen={setDetailsOpen}
                     setDetailsData={setDetailsData}
                     dayTime={props.dayTime}
                  />
               </Card>
            </React.Fragment>
         )}
      </React.Fragment>
   );
};

export default withFirebase(SpaceAvailableCard);
