import React from 'react';
import FeatureUpsellContent from '../../components/FeatureUpsellContent';
import Dialog from '../../components/Dialog';

const GroupsUpsellModal = ({ company, onClose, open }) => (
   <Dialog
      open={open}
      onClose={onClose}
      title="Groups feature"
      maxWidth="sm"
      fullWidth
      loading={company === undefined}
   >
      <FeatureUpsellContent featureName="groups" company={company} callBack={onClose} />
   </Dialog>
);

export default GroupsUpsellModal;
