import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import CompaniesOverview from './companiesOverview';
import LocationOverview from './locationOverview';
import CompanyOverview from './companyOverview';
import GlobalActions from './globalActions';
import { Button } from '@material-ui/core';
import firebase from 'firebase/app';
import { DateToday } from '../../../constants/functions';
import { Commit } from '@mui/icons-material';
import DayLogs from './dayLogs';

const GlobalReporting = (props) => {
   const [companies, setCompanies] = useState(false);
   const [locations, setLocations] = useState(false);
   const [selectedLocation, setSelectedLocation] = useState(false);
   const [selectedCompany, setSelectedCompany] = useState(false);

   useEffect(() => {
      const loadLocations = async () => {
         if (!selectedLocation) {
            await props.firebase
               .allLocations()
               .get()
               .then((result) => {
                  let data = {};
                  result.forEach((doc) => {
                     data[doc.id] = { lid: doc.id, ...doc.data() };
                  });
                  setLocations(data);
                  return;
               });
         }
      };
      loadLocations();
   }, []);

   useEffect(() => {
      const loadCompanies = async () => {
         if (locations) {
            await props.firebase
               .allCos()
               .get()
               .then((result) => {
                  let data = [];

                  result.forEach((doc) => {
                     let locCount = 0;
                     let people = 0;
                     let locData = {};
                     Object.keys(doc.data().locations).map((key) => {
                        people = people + locations[key].people;
                        locCount = locCount + 1;
                        locData[key] = locations[key];
                     });
                     var subscription = doc.data().subscription;
                     if (subscription.demo) {
                        subscription.plan = 'demo';
                     }
                     var notDowngraded = false;
                     if (doc.data().features) {
                        Object.values(doc.data().features).map((feature) => {
                           if (feature) {
                              notDowngraded = true;
                           }
                        });
                     }
                     if (!notDowngraded && subscription.plan === 'premium') {
                        subscription.plan = 'downgraded';
                     }
                     data.push({
                        cid: doc.id,
                        ...doc.data(),
                        people,
                        locCount,
                        locData,
                        subscription,
                     });
                  });
                  setCompanies(data);
                  return;
               });
         }
      };
      loadCompanies();
   }, [locations]);

   async function actionClick() {
      let companies = await props.firebase.db
         .collection(`company`)
         .get()
         .then((results) => {
            let cos = [];

            results.forEach(async (result) => {
               let lids = {};
               cos.push(result.id);
               if (result.data().features) {
                  if (
                     result.data().features.visitors ||
                     result.data().features.spaceBookings
                  ) {
                     console.log(
                        result.data().companyName,
                        result.data().features,
                        result.data().totalPeople,
                     );
                     lids = result.data().locations;
                     let ppldata = {};
                     let people = await props.firebase.db
                        .collection(`company/${result.id}/people`)
                        .get();
                     people.forEach((person) => {
                        ppldata[person.data().lid[0]] = {
                           ...ppldata[person.data().lid[0]],
                           [person.data().email]: `${person.data().name.firstName} ${
                              person.data().name.lastName
                           }`,
                        };
                     });
                     console.log(ppldata, 'ppldata');
                     Object.entries(ppldata).map(async ([key, value]) => {
                        // await props.firebase.db.doc(`location/${key}/visitors/internalDetails`).set(value, {merge:true})
                     });
                  }
               }
            });
            // remove the below line to complete the migration for all companies
            // cos = ['OqrXXfBEATBoT6EQOj3K']
            return cos;
         });
      // companies.map(cid=>{
      //   props.firebase.db.doc(`company/${cid}/indexes/people`).get().then(result => {
      //     // let people = {bTnCc8g1gfuMeIYVCDeo: {email: "JOn+2@intheoffice.io", name: {firstName: 'Adriana', lastName: 'Ocampo'}}}
      //     let people = result.data()

      //     Object.entries(people).map(async([key, value])=>{
      //       const batch = props.firebase.db.batch();
      //       let email = value.email.toLowerCase();
      //       if (email !== value.email){
      //         console.log(value.email, cid)
      //       }
      //       // console.log(email);
      //         // let user = await props.firebase.db.doc(`users/${key}`).get();
      //         // // console.log(user)
      //         // if(user.exists){
      //         //   batch.set(props.firebase.db.doc(`users/${key}`), {email}, {merge:true});
      //         //   // console.log(user.data())
      //         // }
      //         // batch.set(props.firebase.db.doc(`company/${cid}/people/${key}`), {email}, {merge:true})
      //         // batch.set(props.firebase.db.doc(`company/${cid}/indexes/people`), {[key]:{email}}, {merge:true})
      //       // batch.commit();

      //     });

      //   })
      //   console.log(cid, 'complete')
      // })

      //check all locatinos have a cid
      // await props.firebase.db.collection(`company`).get().then(results => {
      //    results.forEach(result =>{
      //      Object.entries(result.data().locations).map(([key, value])=>{
      //       props.firebase.db.doc(`location/${key}`).get().then(loc => {
      //         if(!loc.data().cid){
      //           console.log(value, '----NO----')
      //         }
      //         console.log(value, loc.data())
      //         console.log('location', key)
      //         console.log('company', result.id)
      //       });

      //      })
      //    })

      // })
   }
   function haveSameKeys(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
         return false;
      }
      for (const key of keys1) {
         if (!keys2.includes(key)) {
            return false;
         }
      }
      return true;
   }
   async function action2Click() {
      await props.firebase.db
         .collection(`location`)
         .where('groups', '==', true)
         .get()
         .then((results) => {
            results.forEach((result) => {
               // console.log(result.id, result.data())
               //remove below to run for everyone
               // if(result.id == 'OVU2Ay4b51qKhQK0ToZB'
               // || result.id == 'jil3ZBR5zNzUYXINhZRg'
               // ){
               // locs[result.id] = {}
               props.firebase.db
                  .collection(`location/${result.id}/groups`)
                  .get()
                  .then((response) => {
                     response.forEach((group) => {
                        props.firebase.db
                           .collection(`company/${result.data().cid}/people`)
                           .where('gid', 'array-contains', group.id)
                           .get()
                           .then((members) => {
                              let data = {
                                 // name : group.data().name,
                                 // lid : result.id,
                                 count: 0,
                                 // admins:{},
                                 members: {},
                                 // hidden: false,
                                 // locationPublic: false,
                                 // companyPublic: false,
                                 // global:false,
                                 // type: 'group'
                              };
                              let index = {
                                 // name : group.data().name,
                                 // lid : result.id,
                                 count: 0,
                                 // admins:{},
                                 // hidden: false,
                                 // locationPublic: false,
                                 // companyPublic: false,
                                 // global:false,
                                 // type: 'group'
                              };
                              let count = 0;
                              let ppl = {};
                              members.forEach((member) => {
                                 count = count + 1;
                                 ppl[member.id] = {
                                    name: member.data().name,
                                 };
                              });

                              data.count = count;
                              index.count = count;
                              if (
                                 group.data().count !== count ||
                                 !haveSameKeys(ppl, group.data().members)
                              ) {
                                 console.log(
                                    result.data().name,
                                    group.data(),
                                    group.data().members,
                                    ppl,
                                    count,
                                    'COUNT',
                                 );
                                 const batch = props.firebase.db.batch();
                                 console.log(group.id, index, 'FIRST DATA');
                                 batch.set(
                                    props.firebase.db.doc(
                                       `location/${result.id}/indexes/groups`,
                                    ),
                                    { [group.id]: index },
                                    { merge: true },
                                 );
                                 data.members = ppl;
                                 batch.set(
                                    props.firebase.db.doc(
                                       `location/${result.id}/groups/${group.id}`,
                                    ),
                                    data,
                                    { merge: true },
                                 );
                                 console.log(group.id, data, 'SECOND DATA');
                                 // batch.commit();
                              }
                           });
                     });
                  });

               // }
            });
            return;
         });
   }

   async function action3Click() {
      let now = DateToday().ref;
      console.log(now);
      let locations = await props.firebase.db
         .collection(`location`)
         // .where('name', 'in', ["Test ITO hotfix"])
         // ['Collegiate', 'Fairtrade Foundation', 'Demo intheOffice', 'Endress+Hauser Ltd' ])
         .get()
         .then((results) => {
            let locs = [];
            results.forEach(async (result) => {
               let routine = await props.firebase.db
                  .collection(`location/${result.id}/statusLevels`)
                  .where('overridden', '!=', false)
                  .get()
                  .then((routines) => {
                     routines.forEach(async (route) => {
                        // if(route.id <= now){
                        if (route.data().overridden) {
                           if (route.data().overridden['[object Object]']) {
                              console.log(
                                 result.id,
                                 result.data().name,
                                 route.id,
                                 route.data(),
                                 'ROUTES',
                              );
                              let schedule = await props.firebase.db
                                 .doc(`location/${result.id}/schedule/${route.id}`)
                                 .get();
                              let data = {
                                 count: 0,
                                 same: 0,
                              };
                              let statuses = {
                                 ito: 0,
                                 wfh: 0,
                                 away: 0,
                                 offsite: 0,
                                 AM: {
                                    ito: 0,
                                    wfh: 0,
                                    away: 0,
                                    offsite: 0,
                                 },
                                 PM: {
                                    ito: 0,
                                    wfh: 0,
                                    away: 0,
                                    offsite: 0,
                                 },
                                 overridden: {
                                    ito: 0,
                                    wfh: 0,
                                    away: 0,
                                    offsite: 0,
                                 },
                              };
                              const batch = props.firebase.db.batch();
                              let sl = props.firebase.db.doc(
                                 `location/${result.id}/statusLevels/${route.id}`,
                              );
                              Object.entries(schedule.data()).map(([key, value]) => {
                                 let ls = props.firebase.db.doc(
                                    `location/${result.id}/schedule/${route.id}`,
                                 );
                                 let ps = props.firebase.db.doc(
                                    `company/${
                                       result.data().cid
                                    }/people/${key}/schedule/${route.id}`,
                                 );

                                 if (value.routine && value.routine.set) {
                                    console.log(
                                       'QUERY',
                                       result.id,
                                       result.data().cid,
                                       route.id,
                                       key,
                                       value,
                                    );
                                    if (value.routine.set == value.status) {
                                       batch.set(
                                          ps,
                                          {
                                             routine: value.routine.set,
                                             overridden: false,
                                          },
                                          { merge: true },
                                       );
                                       batch.set(
                                          ls,
                                          {
                                             [key]: {
                                                routine: value.routine.set,
                                                overridden: false,
                                             },
                                          },
                                          { merge: true },
                                       );
                                       data.same = data.same + 1;
                                    } else {
                                       if (value.status !== 'split') {
                                          statuses[value.status] =
                                             statuses[value.status] + 1;
                                       } else {
                                          statuses.AM[value.splitDay.AM] =
                                             statuses.AM[value.splitDay.AM] + 1;
                                          statuses.PM[value.splitDay.PM] =
                                             statuses.AM[value.splitDay.PM] + 1;
                                       }
                                       batch.set(
                                          ps,
                                          {
                                             routine: value.routine.set,
                                             overridden: true,
                                          },
                                          { merge: true },
                                       );
                                       batch.set(
                                          ls,
                                          {
                                             [key]: {
                                                routine: value.routine.set,
                                                overridden: true,
                                             },
                                          },
                                          { merge: true },
                                       );
                                       data.count = data.count + 1;
                                       statuses.overridden[value.routine.set] =
                                          statuses.overridden[value.routine.set] + 1;
                                       // console.log(key,value, route.id)
                                    }
                                    // console.log(key, value)
                                 } else if (value.routine) {
                                    console.log(
                                       'QUERY2',
                                       result.id,
                                       result.data().cid,
                                       route.id,
                                       key,
                                       value,
                                    );
                                    if (value.routine !== value.status) {
                                       if (value.status !== 'split') {
                                          statuses[value.status] =
                                             statuses[value.status] + 1;
                                       } else {
                                          statuses.AM[value.splitDay.AM] =
                                             statuses.AM[value.splitDay.AM] + 1;
                                          statuses.PM[value.splitDay.PM] =
                                             statuses.AM[value.splitDay.PM] + 1;
                                       }
                                       statuses.overridden[value.routine] =
                                          statuses.overridden[value.routine] + 1;
                                       batch.set(
                                          ps,
                                          { routine: value.routine, overridden: true },
                                          { merge: true },
                                       );
                                       batch.set(
                                          ls,
                                          {
                                             [key]: {
                                                routine: value.routine,
                                                overridden: true,
                                             },
                                          },
                                          { merge: true },
                                       );
                                    } else {
                                       batch.set(
                                          ps,
                                          { routine: value.routine, overridden: false },
                                          { merge: true },
                                       );
                                       batch.set(
                                          ls,
                                          {
                                             [key]: {
                                                routine: value.routine,
                                                overridden: false,
                                             },
                                          },
                                          { merge: true },
                                       );
                                    }
                                 } else {
                                    if (value.status !== 'split') {
                                       statuses[value.status] =
                                          statuses[value.status] + 1;
                                    } else {
                                       statuses.AM[value.splitDay.AM] =
                                          statuses.AM[value.splitDay.AM] + 1;
                                       statuses.PM[value.splitDay.PM] =
                                          statuses.AM[value.splitDay.PM] + 1;
                                    }
                                 }
                              });
                              batch.set(sl, statuses);
                              // batch.commit();
                              console.log(statuses, route.id, 'STATUSES');
                           }
                        }
                        // }
                     });
                  });
               locs.push({ id: result.id, ...result.data() });
            });
            return locs;
         });

      console.log(locations);
   }

   async function action4Click() {
      // const cid = "N8nQMp36CMNEGxJueftB"
      const cid = '2fp8D8shclw1dEUAxYjt';
      let locations = await props.firebase.db
         .collection(`company/${cid}/people`)
         // .where('name', 'in', ["Test ITO hotfix"])
         // ['Collegiate', 'Fairtrade Foundation', 'Demo intheOffice', 'Endress+Hauser Ltd' ])
         .get()
         .then((results) => {
            let count = 1;
            results.forEach((result) => {
               if (result.data().lid.includes('sAfZVGwC9KyLbValg4Jv')) {
                  console.log(result.data(), result.id);
                  let user = props.firebase.db
                     .doc(`users/${result.id}`)
                     .get()
                     .then((data) => {
                        console.log(data, result.id);
                     });
               }

               if (result.data().cid.length == 1) {
                  const batch = props.firebase.db.batch();
                  console.log(result.id, result.data());
                  const coref = props.firebase.db.doc(
                     `company/${cid}/people/${result.id}`,
                  );
                  const userref = props.firebase.db.doc(`users/${result.id}`);
                  batch.set(coref, { cid }, { merge: true });
                  batch.set(userref, { cid }, { merge: true });
                  // batch.commit()
                  count = count + 1;
               }
            });
            console.log(count, 'COUNT');
         });
   }
   async function action5Click() {
      await props.firebase.db
         .collection(`location`)
         .where('multisite', '==', true)
         // .where('cid', '==', "OqrXXfBEATBoT6EQOj3K")
         .get()
         .then((results) => {
            results.forEach(async (result) => {
               if (result.data().cid !== 'OqrXXfBEATBoT6EQOj3K') {
                  await migrateOffsite(result.id);
               }
            });
         });
   }
   async function migrateOffsite(lid) {
      await props.firebase.db
         .collection(`location/${lid}/schedule`)
         .get()
         .then((results) => {
            const batch = props.firebase.db.batch();
            results.forEach((result) => {
               if (Object.keys(result.data()).length > 0) {
                  let adjustments = {};
                  Object.entries(result.data()).map(([key, value]) => {
                     if (
                        value.status === 'offsite' &&
                        value.offsiteLocation?.locationId
                     ) {
                        if (!adjustments[value.offsiteLocation.locationId]) {
                           adjustments[value.offsiteLocation.locationId] = {
                              offsiteIto: 0,
                           };
                        }
                        adjustments[value.offsiteLocation.locationId].offsiteIto =
                           adjustments[value.offsiteLocation.locationId].offsiteIto + 1;
                     }
                     if (value.status === 'split') {
                        Object.entries(value.splitDay).map(([key, status]) => {
                           if (
                              status === 'offsite' &&
                              value.offsiteLocation &&
                              value.offsiteLocation[key]?.locationId
                           ) {
                              if (!adjustments[value.offsiteLocation[key].locationId]) {
                                 adjustments[value.offsiteLocation[key].locationId] = {
                                    offsiteIto: 0,
                                 };
                              }
                              if (
                                 !adjustments[value.offsiteLocation[key].locationId][key]
                              ) {
                                 adjustments[value.offsiteLocation[key].locationId][key] =
                                    { offsiteIto: 0 };
                              }
                              adjustments[value.offsiteLocation[key].locationId][
                                 key
                              ].offsiteIto =
                                 adjustments[value.offsiteLocation[key].locationId][key]
                                    .offsiteIto + 1;
                           }
                        });
                     }
                  });
                  // console.log(adjustments, result.id)
                  if (Object.keys(adjustments).length !== 0) {
                     Object.entries(adjustments).map(([lid, entry]) => {
                        const statusLevel = props.firebase.db.doc(
                           `location/${lid}/statusLevels/${result.id}`,
                        );
                        let status = {};
                        // if(result.id === "20230319"){
                        if (entry.offsiteIto && entry.offsiteIto !== 0) {
                           status['offsiteIto'] = firebase.firestore.FieldValue.increment(
                              entry.offsiteIto,
                           );

                           console.log(lid, entry.offsiteIto, entry, result.id);
                        }
                        if (entry.AM && entry.AM.offsiteIto !== 0) {
                           status['AM'] = {
                              offsiteIto: firebase.firestore.FieldValue.increment(
                                 entry.AM.offsiteIto,
                              ),
                           };
                        }
                        if (entry.PM && entry.PM.offsiteIto !== 0) {
                           status['PM'] = {
                              offsiteIto: firebase.firestore.FieldValue.increment(
                                 entry.PM.offsiteIto,
                              ),
                           };
                        }
                        // }

                        batch.set(statusLevel, status, { merge: true });
                     });
                  }
               }
            });
            // try{
            //    batch.commit()
            // } catch (error) {
            //    console.log(error, '<++ERROR')
            // }
         });
   }

   return (
      <React.Fragment>
         {!selectedLocation && !selectedCompany && (
            <div style={{ marginTop: 32 }}>
               <Button onClick={() => actionClick()}>Action</Button>
               <Button onClick={() => action2Click()}>Group Index</Button>
               <Button onClick={() => action3Click()}>Routine Check</Button>
               <Button onClick={() => action4Click()}>fix CID issue</Button>
               <Button onClick={() => action5Click()}>Offsite Migration</Button>
               <DayLogs />
            </div>
         )}
         <GlobalActions
            selectedCompany={selectedCompany}
            selectedLocation={selectedLocation}
         />
         {!selectedLocation && !selectedCompany ? (
            <CompaniesOverview
               locations={locations}
               companies={companies}
               selectedLocation={selectedLocation}
               setSelectedLocation={setSelectedLocation}
               setSelectedCompany={setSelectedCompany}
            />
         ) : !selectedCompany ? (
            <LocationOverview
               selectedLocation={selectedLocation}
               setSelectedLocation={setSelectedLocation}
            />
         ) : (
            <CompanyOverview
               selectedCompany={selectedCompany}
               setSelectedCompany={setSelectedCompany}
            />
         )}
      </React.Fragment>
   );
};

const GlobalReportingPage = compose(withRouter, withFirebase)(GlobalReporting);

const condition = (authUser) => authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(GlobalReportingPage);
