import { Box, Typography } from '@mui/material';
import React from 'react';
import bookingSquare from '../../../../assets/bookingSquare.svg';
import reportingSquare from '../../../../assets/reportingSquare.svg';

const FeatureDescription = (props) => {
   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            flexDirection: 'column',
         }}
      >
         {props.type === 'spaces' && (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
               }}
            >
               <Box sx={{ width: '150px', height: '150px' }}>
                  <img width="150px" height="150px" src={bookingSquare} />
               </Box>
               <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                  Oops, it looks you don't have Spaces enabled.{' '}
               </Typography>
               <Typography variant="body1">
                  Your Admin must enable Spaces from the Settings page for you to book a
                  space.
               </Typography>
            </Box>
         )}
         {props.type === 'reporting' && (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
               }}
            >
               <Box sx={{ width: '150px', height: '150px' }}>
                  <img width="150px" height="150px" src={reportingSquare} />
               </Box>
               <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                  Oops, it looks you don't have Reporting enabled.{' '}
               </Typography>
               <Typography variant="body1">
                  Your Admin must enable Reporting from the Settings page for you to view
                  more insights on where people are working each day.
               </Typography>
            </Box>
         )}
      </Box>
   );
};

export default FeatureDescription;
