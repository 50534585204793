import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { withFirebase } from '../../Firebase';

const HostAutocompleteToggle = ({ lid, location, ...props }) => {
   async function saveHostAutocomplete(value) {
      const batch = props.firebase.db.batch();
      const tlocRef = props.firebase.db.doc(`location/${lid}`);
      batch.set(tlocRef, { visitorHostAutocomplete: value }, { merge: true });
      const visColRef = props.firebase.db.collection(`visitors`);
      batch.set(visColRef.doc(lid), { visitorHostAutocomplete: value }, { merge: true });

      try {
         batch.commit();
      } catch (error) {
         console.warn(error, 'ERROR saving host autocomplete');
      }
   }

   return (
      <FormControl>
         <FormControlLabel
            label="Enable Host Autocomplete"
            control={
               <Switch
                  checked={location.visitorHostAutocomplete || false}
                  onChange={(e) => {
                     saveHostAutocomplete(e.target.checked);
                  }}
               />
            }
         />
         <FormHelperText>
            By default, the "Find your host" field hides employee names and emails for
            unregistered visitors. Enable this option to allow visitors to select their
            host from a list of employee names and email addresses. Note: Visitors must
            enter at least three characters.
         </FormHelperText>
      </FormControl>
   );
};

export default withFirebase(HostAutocompleteToggle);
