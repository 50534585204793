import { useFirebase } from '../../../components/Firebase';
import { objectToArrayWithKeyIds } from '../../../utils/objectToArray';
import * as R from 'ramda';
import useFirebaseRequest from '../../../hooks/useFirebaseRequest';
import { useCompany } from '../../../providers';
import { useEffect } from 'react';

const useGetAllCompanyPeople = (lid) => {
   const firebase = useFirebase();
   const { company } = useCompany();
   const locationPeople = useFirebaseRequest(firebase.locationPeopleIndex, {
      args: [lid],
      sortFunction: (data) =>
         Object.entries(data).reduce(
            (acc, [key, value]) => ({
               ...acc,
               [key]: {
                  ...value,
                  externalLocation: false,
               },
            }),
            {},
         ),
   });
   const allPeople = useFirebaseRequest(firebase.companyPeopleIndex, {
      args: [company.cid],
      sortFunction: (data) =>
         Object.entries(data).reduce(
            (acc, [key, value]) => ({
               ...acc,
               [key]: {
                  ...value,
                  externalLocation: true,
               },
            }),
            {},
         ),
   });

   function sortedData() {
      if (allPeople.data && locationPeople.data) {
         const data = {
            ...allPeople.data,
            ...locationPeople.data,
         };
         return {
            raw: data,
            sorted: R.sortBy(R.path(['name', 'firstName']))(
               objectToArrayWithKeyIds(data, 'id'),
            ),
         };
      }

      return {
         raw: null,
         sorted: [],
      };
   }

   useEffect(() => {}, [allPeople.data, locationPeople.data]);

   return {
      loading: locationPeople.loading || allPeople.loading,
      data: sortedData(),
   };
};

export default useGetAllCompanyPeople;
