import React from 'react';
import Main from './main';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import { AuthProvider, CompanyProvider } from '../../providers';

const Reporting = (props) => {
   return (
      <AuthProvider>
         <CompanyProvider>
            <Main {...props} />
         </CompanyProvider>
      </AuthProvider>
   );
};

const Page = compose(withRouter, withFirebase)(Reporting);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withEmailVerification, withAuthorization(condition))(Page);
