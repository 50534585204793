import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const Footer = () => {
   return (
      <Box
         sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: theme.spacing(6),
            borderTop: '1px solid',
            borderColor: 'divider',
         })}
      >
         <Typography variant="body2" color="textSecondary">
            {'Copyright © '}
            <Link
               color="inherit"
               href="mailto:hi@inthoffice.io?subject=In The Office message about CloudCoding Limited"
            >
               {`intheOffice Limited`}
            </Link>
            {` ${new Date().getFullYear()}.`}
         </Typography>
      </Box>
   );
};

export default Footer;
