import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import requestImage from '../../../assets/requestAttendanceSquare.svg';
import offsiteImage from '../../../assets/offSite.svg';
import routineImage from '../../../assets/routine.svg';
import splitdaysImage from '../../../assets/splitDay.svg';
import multiweekIcon from '../../../assets/multiweekIcon.svg';
import msTeamsIcon from '../../../assets/msTeamsIcon.svg';
import ssoIcon from '../../../assets/ssoicon.svg';
import bookingSquare from '../../../assets/bookingSquare.svg';
import groupsSquare from '../../../assets/groupsSquare.svg';
import locationsSquare from '../../../assets/locationsSquare.svg';
import meetingsSquare from '../../../assets/meetingsSquare.svg';
import availabilityImage from '../../../assets/dailyAvailability.svg';
import reportingSquare from '../../../assets/reportingSquare.svg';
import ScheduleSquare from '../../../assets/ScheduleSquare.svg';
import visitorsSquare from '../../../assets/visitorsSquare.svg';
import capacitySquare from '../../../assets/capacitySquare.svg';
import touchlessCheckin from '../../../assets/touchlessCheckin.svg';
import unlimitedUsersIcon from '../../../assets/unlimitedUsersIcon.svg';
import RegisterIcon from '../../../assets/register.svg';
import FeatureAccordionBody from './featureAccordionBody';
import { withFirebase } from '../../Firebase';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
   featureName: {
      fontWeight: 'lighter',
      color: theme.palette.text.secondary,
   },
   featureNameEnabled: {
      color: theme.palette.primary.main,
   },
}));

const featuresData = {
   basic: {
      name: 'Basic Attendance Scheduling',
      enabled: true,
      summary: 'Free tools to help you manage office attendance with hybrid working',
      layout: 'single',
      description: {
         header: `Attendance scheduling replaces your spreadsheet to give you:`,
         features: [
            {
               title: `Unlimited free users`,
               description:
                  'No matter the size of your office, you can all use attendance scheduling for free.',
               image: (
                  <Avatar
                     alt="unlimited free users"
                     variant="rounded"
                     src={unlimitedUsersIcon}
                  />
               ),
            },
            {
               title: `Basic Statuses`,
               description: `Let your coleagues know where you're working by setting your daily status as one of: working in the office (ITO), working from home (WFH), or not working (away).`,
               image: (
                  <Avatar alt="Basic Statuses" variant="rounded" src={ScheduleSquare} />
               ),
            },
            {
               title: `Daily office capacity/occupancy level tracking`,
               description:
                  'Set maximum occupancy levels based on the number of people or desks when you want to maintain social distancing or have more people than desks available.',
               image: (
                  <Avatar alt="Capacity levels" variant="rounded" src={capacitySquare} />
               ),
            },
            {
               title: `Live Health & Safety Register`,
               description:
                  'See exactly who is onsite in the event of an emergency. Seeing who has arrived and who has already left.',
               image: <Avatar alt="Live Register" variant="rounded" src={RegisterIcon} />,
            },
            {
               title: `Microsoft single sign-on and speeding onboarding`,
               description:
                  'Use your Microsoft 365 login credentials to create an account and automate employee onboarding.',
               image: (
                  <Avatar alt="unlimited free users" variant="rounded" src={ssoIcon} />
               ),
            },
            {
               title: `Microsoft Teams app`,
               description:
                  'Embed intheOffice in your daily workflow by adding it as a Teams tab.',
               image: (
                  <Avatar
                     alt="unlimited free users"
                     variant="rounded"
                     src={msTeamsIcon}
                  />
               ),
            },
            {
               title: `Touchless check-in`,
               description:
                  'Generate a location specific QR code so you can see who is coming and going from your office.',
               image: (
                  <Avatar
                     alt="touchless check-in"
                     variant="rounded"
                     src={touchlessCheckin}
                  />
               ),
            },
         ],
      },
      panel: 'basic',
      switchDisabled: false,
   },
   advancedScheduling: {
      name: 'Advanced Scheduling',
      enabled: false,
      summary: 'Enhanced tools to make attendance scheduling easier',
      layout: 'single',
      description: {
         header: `Advanced scheduling makes it easier to plan where you are working each week, and keep people informed when you are working offsite.`,
         features: [
            {
               title: 'Daily Availability',
               description: `Set time periods to indicate when you are Available or Unavailable regardless of where you are working, e.g. if you are picking up your children, going to the gym, or have to catch the 4pm train home`,
               image: (
                  <Avatar
                     alt="Daily Availability"
                     variant="rounded"
                     src={availabilityImage}
                  />
               ),
            },
            {
               title: 'Offsite Status',
               description: `Add an "offsite" status the schedule for when people are working from another location, e.g. if they are working at a client's office, at a co-working space, or on a work trip.`,
               image: <Avatar alt="Offsite" variant="rounded" src={offsiteImage} />,
            },
            {
               title: 'Request Attendance',
               description: `Plan for a face to face meeting or collaboration day by inviting one or more of your colleages into the office. Invites can be as simple as a request to change your status, or you can automatically update peoples' status.`,
               image: (
                  <Avatar alt="Request Attendance" variant="rounded" src={requestImage} />
               ),
            },
            {
               title: 'Weekly Routines',
               description: `Create a recurring default routine to save any fixed working patterns e.g. if you always work from home on a tuesday. Routines can be overriden by changing your status on your Schedule`,
               image: (
                  <Avatar alt="Weekly Routines" variant="rounded" src={routineImage} />
               ),
            },
            {
               title: 'Split Days',
               description: `Divide your daily status into AM and PM to set different statuses for the morning and afternoon.`,
               image: <Avatar alt="Split Days" variant="rounded" src={splitdaysImage} />,
            },
            {
               title: 'Multiweek view',
               description: `View your teams statuses across multiple weeks so you can plan where you're going to work further in advance.`,
               image: (
                  <Avatar alt="Multiweek View" variant="rounded" src={multiweekIcon} />
               ),
            },
         ],
      },
      panel: 'advancedScheduling',
      switchDisabled: false,
   },
   groups: {
      name: 'Group Management',
      enabled: false,
      layout: 'single',
      summary: 'Clearer visibility of the people you work with every day.',
      description: {
         header:
            'Rather than seeing an overview of everyone in the company, create groups of people that refularly work together. People can belong to one group or many.',
         image: <Avatar alt="Group management" variant="rounded" src={groupsSquare} />,
      },
      panel: 'groups',
      switchDisabled: false,
   },
   multisite: {
      name: 'Multiple Locations',
      enabled: false,
      summary: 'Create additional locations to reflect each of your real-world offices.',
      description: {
         header: `Locations have their own Groups, Reporting, Spaces and capacity levels. Each person has one primary location.`,
         image: (
            <Avatar alt="Multiple Locations" variant="rounded" src={locationsSquare} />
         ),
      },
      panel: 'multisite',
      switchDisabled: false,
   },
   spaceBookings: {
      name: 'Space Bookings',
      enabled: false,
      summary:
         'A booking system for anything physical, from desks and rooms to parking spaces and lockers',
      description: {
         header: `Spaces are office resources that can be booked, e.g. a desk, room, or parking space. Spaces can have any number of seats which can be reserved individually. Multiple spaces can be grouped together in an Area as a parent space, e.g. a desk can exist within a quiet working area, on the first floor.`,
         features: [
            {
               title: `Spaces can be configured on the Location tab`,
               image: (
                  <Avatar alt="Space bookings" variant="rounded" src={bookingSquare} />
               ),
            },
         ],
      },
      panel: 'spaceBookings',
      switchDisabled: false,
   },
   reporting: {
      name: 'Reporting',
      enabled: false,
      layout: 'single',
      summary: 'Learn more about your office utilisation.',
      description: {
         header: `Track and report on where your employees are working to see how they are ustilising your office space. Ensure hybrid working policies are being followed with instantly generated graphs and charts.`,
         image: <Avatar alt="Reporting" variant="rounded" src={reportingSquare} />,
      },
      panel: 'reporting',
      switchDisabled: false,
   },
   visitors: {
      name: 'Visitor Management',
      enabled: false,
      layout: 'single',
      summary: 'Track and manage your office visitors',
      description: {
         header:
            'Let people register their visitors so you know exactly who is in your office each day. Get visitors to check in with their smartphones or check in with our kiosk screen to alert their hosts that they have arrived.',
         image: (
            <Avatar alt="Visitor Management" variant="rounded" src={visitorsSquare} />
         ),
      },
      panel: 'visitors',
      switchDisabled: false,
   },
   // leave: {
   //    name: 'Annual Leave Planner',
   //    enabled: false,
   //    layout: 'single',
   //    summary: 'COMING SOON - Manage, request and approve leave and holiday requests',
   //    description: {
   //       header: `Easy holiday request and approval processes which automatically show up on the Schedule. No more multiple systems to manage and display leave.`,
   //    },
   //    panel: 'leave',
   //    switchDisabled: true,
   // },
};

const CompanyAccordions = (props) => {
   const classes = useStyles();
   const [expanded, setExpanded] = useState(false);
   const [accord, setAccord] = useState(false);
   const [enabled, setEnabled] = useState(false);

   useEffect(() => {
      if (props.company.features) {
         let features = props.company.features;
         features['basic'] = true;
         setEnabled(features);
      }
   }, [props.company]);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   return (
      <React.Fragment>
         <Grid item xs={12}>
            <Typography variant="h5" component="h3">
               Features
            </Typography>
            {props.company.subscription.plan === 'essential' ? (
               <Typography paragraph variant="caption">
                  You are on the free Essential plan, giving you access to Basic
                  Attendance and unlimited users. Enabling a premium feature will start
                  your 7 day free trial of the premium plan. After your trial has
                  finished, your daily usage will be calculated based on the total user
                  number and the premium features you have enabled.
               </Typography>
            ) : (
               <React.Fragment>
                  {props.trial ? (
                     <Typography paragraph variant="caption">
                        During your free premium trial, you can enable as many premium
                        features as you want for free. Once your 7 day trial period has
                        ended we will track the total user number and features that are
                        enabled each day to work out a daily cost. This breakdown will be
                        visible on the Subscription tab.
                     </Typography>
                  ) : (
                     <Typography paragraph variant="caption">
                        Any features you enable or disable will affect your daily usage.
                        We work out daily usage based on the total user number and the
                        premium features that are enabled. You can see a detailed
                        breakdown of your usage costs on the Subscription tab.
                     </Typography>
                  )}
               </React.Fragment>
            )}

            <div>
               {Object.entries(featuresData).map(([key, feature]) => {
                  return (
                     <Accordion
                        key={key}
                        expanded={expanded === feature.panel}
                        onChange={handleChange(feature.panel)}
                     >
                        <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1bh-content"
                           id="panel1bh-header"
                        >
                           <Typography
                              className={clsx(classes.featureName, {
                                 [classes.featureNameEnabled]: enabled[key],
                              })}
                              variant="h6"
                              sx={{
                                 width: '35%',
                                 flexShrink: 0,
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                              }}
                           >
                              {feature.name}
                           </Typography>
                           <Typography
                              variant="caption"
                              sx={{
                                 color: 'text.secondary',
                                 height: '4rem',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                              }}
                           >
                              {feature.summary}
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           {enabled[key] ? (
                              <FeatureAccordionBody
                                 switchDisabled={feature.switchDisabled}
                                 panel={feature.panel}
                                 company={props.company}
                                 data={feature.description}
                                 enabled={enabled[key]}
                              />
                           ) : (
                              <FeatureAccordionBody
                                 switchDisabled={feature.switchDisabled}
                                 panel={feature.panel}
                                 company={props.company}
                                 data={feature.description}
                                 enabled={false}
                              />
                           )}
                        </AccordionDetails>
                     </Accordion>
                  );
               })}
            </div>
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(CompanyAccordions);
