import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';
import Routine from './routine';
import FeatureSwitch from '../Billing/featureSwitch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   paper: {
      padding: theme.spacing(2),
   },
}));

const Preferences = (props) => {
   const classes = useStyles();
   const [location, setLocation] = useState(false);
   const [enabled, setEnabled] = useState(false);

   useEffect(() => {
      if (props.user !== null) {
         const loadLocation = props.firebase
            .location(props.user.lid[0])
            .onSnapshot((result) => {
               if (result.data().routine) {
                  setLocation(result.data());
                  setEnabled(true);
               } else {
                  setEnabled('disabled');
               }
            });
         return () => loadLocation();
      }
   }, [props.user]);

   switch (enabled) {
      case 'disabled':
         return <FeatureSwitch feature="routine" wide />;
      case true:
         return (
            <Paper className={classes.paper}>
               <Typography variant="h5" component="h2" paragraph>
                  My routine
               </Typography>
               <Typography variant="body2">
                  Create a routine to set a starting point for where you are working each
                  week. You can always change your status from the Schedule and override
                  any routine that has been set.
               </Typography>
               <Routine user={props.user} location={location} clickable={true} />
            </Paper>
         );
      default:
         return (
            <Paper className={classes.paper}>
               <Typography variant="h5" component="h2" paragraph>
                  My routine
               </Typography>
               <Typography variant="body2">Loading...</Typography>
            </Paper>
         );
   }
};

export default withFirebase(Preferences);
