import React, { useEffect, useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { withFirebase } from '../../Firebase';
import { useAuth } from '../../../providers';

const BookButton = (props) => {
   const { authUser } = useAuth();
   const [disabled, setDisabled] = useState(false);

   useEffect(() => {
      setDisabled(false);
      if (props.bookingForm.type || props.bookingForm.type === 0) {
         if (props.bookingForm.type === 3 && props.space.seatsAvailablePM === 0) {
            setDisabled(true);
         } else if (props.bookingForm.type === 2 && props.space.seatsAvailableAM === 0) {
            setDisabled(true);
         } else if (props.bookingForm.type === 0 && props.space.seatsAvailable < 1) {
            setDisabled(true);
         }
         if (
            props.dense &&
            props.bookingForm.type === 0 &&
            props.space.seatsAvailable === 0
         ) {
            setDisabled(true);
         }
      }
   }, [props.bookingForm, props.space]);

   async function bookSpace(e) {
      var date = props.date.ref;
      var sid = props.space.sid;

      try {
         await props.firebase.bookSpaceForUser(
            props.location.id,
            date,
            sid,
            props.bookingForm,
            props.status,
            authUser,
            true,
         );
      } catch (error) {
         console.warn(error, 'error');
      }
   }
   return (
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
         <Button
            variant="contained"
            color="primary"
            onClick={(e) => bookSpace(e)}
            disabled={disabled}
            style={{ height: '40px' }}
         >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <Box>Book</Box>
               {props.bookingForm.type === 2 && ' - AM'}
               {props.bookingForm.type === 3 && ' - PM'}
            </Box>
         </Button>
         <Box sx={{ pl: 4 }}>
            <Typography variant="caption" color="textSecondary">
               {props.bookingForm.seats} seat <br />
               {props.bookingForm.typeName}
            </Typography>
         </Box>
      </Box>
   );
};
export default withFirebase(BookButton);
