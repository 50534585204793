import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';
import Modal from '@material-ui/core/Modal';

import Button from '@material-ui/core/Button';
import ScrollableTabsButtonForce from './childScroll';
import { withFirebase } from '../Firebase';
import { Box, Paper, Typography } from '@material-ui/core';
import ErrorImage from '../../assets/MicrosoftTeams-image.png';

const errorMessage = {
   message: 'Sorry, we could not get the image you requested. Please try again later.',
};

function rand() {
   return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
   const left = 50 + rand();

   return {
      top: 15,
      left: '50%',
      transform: `translate(-${left}%, 0)`,
   };
}

const useStyles = makeStyles((theme) => ({
   paper: {
      position: 'absolute',
      width: '40vw',
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
   },
   paperImage: {
      margin: theme.spacing(2),
      maxWidth: '90%',
   },
   image: {
      height: 'auto',
      width: 'auto',
      maxHeight: '100%',
      maxWidth: '100%,',
   },
   breadcrumb: {
      paddingRight: theme.spacing(1),
   },
   parentButton: {
      '&:hover': {
         backgroundColor: '#eeeeee',
      },
   },
}));

const SpaceMap = (props) => {
   const classes = useStyles();
   const [modalStyle] = React.useState(getModalStyle);
   const [mapUrl, setMapUrl] = useState();
   const [mapError, setMapError] = useState(null);
   const [spaceId, setSpaceId] = useState('');
   const [space, setSpace] = useState({});
   const [parent, setParent] = useState({ name: '' });
   const [children, setChildren] = useState(null);

   useEffect(() => {
      if (props.map && props.spaceKey !== '') {
         setSpaceId(props.spaceKey);
      }
   }, [props.spaceKey, props.map]);

   useEffect(() => {
      const getSpace = async () => {
         if (spaceId !== '') {
            await props.firebase
               .getSpace(props.locationKey, spaceId)
               .get()
               .then((result) => {
                  if (result.exists) {
                     console.log(result);
                     let data = result.data();
                     data.id = spaceId;
                     setSpace(data);
                  }
               });
         }
      };
      getSpace();
   }, [spaceId, props.firebase, props.locationKey]);

   useEffect(() => {
      if (props.map) {
         if (space.mapPath) {
            const fullPath = props.firebase.showImage(space.mapPath);
            fullPath
               .getDownloadURL()
               .then(function (url) {
                  console.log(url, 'url');
                  setMapUrl(url);
               })
               .catch(function (error) {
                  console.warn('error getting firebase image', error);
                  setMapUrl(ErrorImage);
                  setMapError(errorMessage);
                  // Uh-oh, an error occurred!
               });
         }
      }
   }, [props.map, space, props.firebase]);

   useEffect(() => {
      if (props.map && space.parent) {
         props.firebase
            .getSpace(props.locationKey, space.parent)
            .get()
            .then((response) => {
               setParent(response.data());
            });
      } else if (props.map && space.parent === '') {
         setParent({ name: '' });
      }
   }, [props.map, space, props.firebase, props.locationKey]);

   useEffect(() => {
      if (props.map && spaceId) {
         props.firebase
            .getChildSpaces(props.locationKey, spaceId)
            .get()
            .then((response) => {
               if (!response.empty) {
                  let data = {};
                  response.forEach((doc) => {
                     data[doc.id] = { ...doc.data() };
                  });
                  setChildren(data);
               } else {
                  setChildren(null);
               }
            });
      }
   }, [props.map, spaceId, props.firebase, props.locationKey]);

   async function goToParent() {
      console.log(props);
      console.log(props.locationKey, space.parent);
      setSpaceId(space.parent);
      // await props.firebase.getSpace(props.locationKey, space.parent).get().then((parent)=>{
      //   console.log(parent.data());
      //   if (parent.data().mapPath){
      //   const fullPath = props.firebase.showImage(parent.data().mapPath);
      //     fullPath.getDownloadURL().then(function(url) {
      //       setMapUrl(url);
      //     }).catch(function(error) {
      //       alert(error);
      //     });;
      //   }
      // });
   }

   return (
      <React.Fragment>
         <Modal
            open={props.map}
            onClose={() => props.setMap(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
         >
            <Grid container className={classes.paper} style={modalStyle}>
               <Grid item xs={12}>
                  <Paper
                     className={classes.paperImage}
                     elevation={3}
                     square
                     height="auto"
                     width="auto"
                  >
                     <Typography paragraph align="center" variant="h5">
                        {space.name}
                     </Typography>
                     {mapUrl && (
                        <>
                           <Image
                              className={classes.image}
                              src={mapUrl}
                              style={{ width: '100%', height: 'auto' }}
                           />
                           {mapError ? (
                              <Box p={2}>
                                 <Typography align="center" variant="h5">
                                    {mapError.message}
                                 </Typography>
                              </Box>
                           ) : null}
                        </>
                     )}
                  </Paper>
               </Grid>
               <Grid item xs={12}>
                  {parent.name !== '' ? (
                     <Button
                        classes={{ root: classes.parentButton }}
                        color="primary"
                        // startIcon={<ZoomOutIcon />}
                        fullWidth
                        onClick={() => goToParent()}
                     >
                        {parent.name}
                     </Button>
                  ) : (
                     ''
                  )}
               </Grid>
               <Grid item xs={12}>
                  <Paper square variant="outlined">
                     <Button color="primary" fullWidth disabled>
                        {space.name}
                     </Button>
                  </Paper>
               </Grid>
               {children !== null ? (
                  <React.Fragment>
                     <Grid item xs={12}>
                        <ScrollableTabsButtonForce
                           setSpaceId={setSpaceId}
                           children={children}
                        />
                     </Grid>
                  </React.Fragment>
               ) : (
                  ''
               )}
            </Grid>
            {/* { children !== null ? 
      <ScrollableTabsButtonForce />
    : ""} */}
         </Modal>
      </React.Fragment>
   );
};

export default withFirebase(SpaceMap);
