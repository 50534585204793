import { exportToCsv } from './exportToCsv';
import { locationPeopleIndexRef } from '../firebaseRefs';

export async function locationUsers(query) {
   const filename = `ITO-export-${query.type}-${query.lid}`;
   try {
      const users = await locationPeopleIndexRef(query.lid).get();
      if (users.exists) {
         const data = [];
         Object.entries(users.data()).forEach(([key, value]) => {
            let person = { ...defaultData };
            person.uid = key;
            person.firstName = value?.name?.firstName || null;
            person.lastName = value?.name?.lastName || null;
            data.push(person);
         });
         exportToCsv(filename, data);
         return { code: 200, status: 'success', data, error: null };
      } else {
         return { code: 204, status: 'empty', data: null, error: null };
      }
   } catch (error) {
      console.warn('Error -', error);
      return { code: 400, status: 'error', error };
   }
}

const defaultData = {
   uid: null,
   firstName: null,
   lastName: null,
};
