import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

const TabSet = ({
   value = 0,
   onChange = () => console.warn('tab change'),
   indicatorColor = 'primary',
   tabNames = [],
   ...rest
}) => (
   <Tabs value={value} onChange={onChange} indicatorColor={indicatorColor} {...rest}>
      {tabNames.map((tabName, index) => (
         <Tab key={tabName} id={`tab-${index}`} label={tabName} />
      ))}
   </Tabs>
);

export const TabSetContent = ({ tabValue, tabNames = [], contentArray = [] }) => (
   <section id={tabNames[tabValue]} data-component="TabSetContent">
      {contentArray[tabValue]}
   </section>
);

let defaultOptions = {
   urlParamAlias: 'tab',
   defaultTabValue: 0,
};

export const useTabSet = (instanceoptions = defaultOptions) => {
   const options = {
      ...defaultOptions,
      ...instanceoptions,
   };

   const { search } = useLocation();
   const [tabValue, setTabValue] = React.useState(() => {
      if (search) {
         const urlParams = new URLSearchParams(search);
         const tabNumber = urlParams.get(options.urlParamAlias);
         return Number(tabNumber);
      } else {
         return options.defaultTabValue;
      }
   });

   const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
   };

   return [tabValue, setTabValue, handleTabChange];
};

export default TabSet;
