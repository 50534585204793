import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import {
   TableContainer,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Box,
} from '@mui/material';
import { CardSection } from '../layout';
import ScheduleMenu from './menu';
import PersonRow from './personRow';
import { usePage } from '../layout/PageLayout';

// Custom function to compare two objects
function areObjectsEqual(objA, objB) {
   if (objA === objB) {
      return true;
   }

   if (
      typeof objA !== 'object' ||
      typeof objB !== 'object' ||
      objA === null ||
      objB === null
   ) {
      return false;
   }

   const keysA = Object.keys(objA);
   const keysB = Object.keys(objB);

   if (keysA.length !== keysB.length) {
      return false;
   }

   for (const key of keysA) {
      if (!objB.hasOwnProperty(key) || !areObjectsEqual(objA[key], objB[key])) {
         return false;
      }
   }

   return true;
}

const PeopleTableComponent = (props) => {
   const [query, setQuery] = useState(null);
   const [peopleList, setPeopleList] = useState(false);
   const { selectedLocationId, selectedGroupId } = usePage();

   useEffect(() => {
      let newQuery = props.firebase.companyPeople(props.displayFilter.cid);
      if (selectedGroupId !== 'all') {
         newQuery = newQuery.where('gid', 'array-contains', selectedGroupId);
      } else if (props.displayFilter.type === 'location') {
         newQuery = newQuery.where('lid', 'array-contains', selectedLocationId);
      }
      newQuery = newQuery.orderBy('name.firstName');
      setQuery(newQuery);
   }, [props.displayFilter, props.groups, selectedGroupId, selectedLocationId]);

   useEffect(() => {
      if (query) {
         const unsubscribe = query.onSnapshot((people) => {
            let newPeopleList = {};
            people.forEach((doc) => {
               newPeopleList[doc.id] = {
                  ...doc.data(),
                  email: doc.data().email,
                  name: doc.data().name,
                  status: doc.data().status,
                  uid: doc.id,
                  cid: doc.data().cid,
                  gid: doc.data().gid,
                  roles: doc.data().roles,
               };
               if (doc.data().gid && doc.data().gid !== null) {
                  let merge = {};
                  doc.data().gid.map((id) => {
                     merge[id] = props.groups[id];
                  });
                  newPeopleList[doc.id].groups = merge;
               }
            });

            setPeopleList(newPeopleList);
         });
         return () => {
            unsubscribe();
         };
      }
   }, [query]);

   useEffect(() => {
      if (peopleList) {
         if (!areObjectsEqual(peopleList, props.companyPeople)) {
            props.setCompanyPeople(peopleList);
         }
      }
   }, [peopleList]);

   return (
      <CardSection>
         <Box mb={2}>
            <ScheduleMenu
               displayFilter={props.displayFilter}
               setDisplayFilter={props.setDisplayFilter}
               setGroups={props.setGroups}
            />
         </Box>
         <TableContainer sx={{ height: '70vh' }}>
            <Table stickyHeader size="small">
               <TableHead>
                  <TableRow>
                     <TableCell>Name</TableCell>
                     <TableCell>Email</TableCell>
                     <TableCell>Group</TableCell>
                     <TableCell>Actions</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {props.companyPeople && (
                     <PersonRow
                        displayFilter={props.displayFilter}
                        setAlertMessage={props.setAlertMessage}
                        setChecked={props.setChecked}
                        setPeopleDrawer={props.setPeopleDrawer}
                        setDrawerData={props.setDrawerData}
                        companyPeople={props.companyPeople}
                     />
                  )}
               </TableBody>
            </Table>
         </TableContainer>
      </CardSection>
   );
};

const PeopleTable = compose(withRouter, withFirebase)(PeopleTableComponent);

export default PeopleTable;
