import React from 'react';
import { Avatar, AvatarGroup, Tooltip, Box } from '@mui/material';
import { roleByAliasMap } from './additionalRolesListData';

const AdditionRolesIconDisplay = ({ roles, ...boxProps }) => {
   const profileRoles = roles.map((roleName) => roleByAliasMap()[roleName]);

   return (
      <Box {...boxProps}>
         <AvatarGroup className="icon-group">
            {profileRoles.map((role, index) => {
               const Component = role.icon;

               return (
                  <Tooltip title={role.label} key={index} placement="top">
                     <Avatar sx={{ width: 28, height: 28, background: 'white' }}>
                        <Component sx={{ color: 'grey', fontSize: [15] }} />
                     </Avatar>
                  </Tooltip>
               );
            })}
         </AvatarGroup>
      </Box>
   );
};

export default AdditionRolesIconDisplay;
