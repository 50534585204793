import React from 'react';
import Typography from '@material-ui/core/Typography';

const Locations = ({ locations, companyName }) => {
   const locsList = Object.entries(locations);

   return (
      <>
         <Typography variant="h5" component="h2" paragraph>
            Details
         </Typography>
         <Typography variant="subtitle1">Company:</Typography>
         <Typography paragraph>{companyName}</Typography>
         <Typography variant="subtitle1">Locations:</Typography>
         {locsList.map(([key, value]) => (
            <Typography key={key} data-location-id={key} variant="body1">
               {value}
            </Typography>
         ))}
      </>
   );
};

export default Locations;
