import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Scheduler = (props) => (
   <SvgIcon {...props} viewBox="0 0 24 24">
      <g
         id="ITO-Design-System"
         stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd"
      >
         <g id="SchedulerIcon" fill="currentColor" fillRule="nonzero">
            <path
               d="M14.6666667,2.66666667 L14.6666667,3.85185185 L21.3333333,3.85185185 L21.3333333,21.6296296 L24,21.6296296 L24,24 L18.6666667,24 L18.6666667,6.22222222 L14.6666667,6.22222222 L14.6666667,24 L0,24 L0,21.6296296 L2.66666667,21.6296296 L2.66666667,9.76137614 C5.3133318,10.6390464 7.35888505,10.3201135 8.80332642,8.80457735 C10.2477678,7.28904123 10.6090725,5.243071 9.88724055,2.66666667 L14.6666667,2.66666667 Z M12,12.1481481 L9.33333333,12.1481481 L9.33333333,14.5185185 L12,14.5185185 L12,12.1481481 Z M4.995,0 C7.76,0 10,2.24 10,5 C10,7.76 7.76,10 4.995,10 C2.235,10 0,7.76 0,5 C0,2.24 2.235,0 4.995,0 Z M5,1 C2.79,1 1,2.79 1,5 C1,7.21 2.79,9 5,9 C7.21,9 9,7.21 9,5 C9,2.79 7.21,1 5,1 Z M5.25,2.33333333 L5.25,5.20218579 L7.5,6.66120219 L7.125,7.33333333 L4.5,5.61202186 L4.5,2.33333333 L5.25,2.33333333 Z"
               id="Shape"
            ></path>
         </g>
      </g>
   </SvgIcon>
);

export default Scheduler;
