import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withFirebase } from '../../Firebase';
import { ListItemIcon } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import InteractionDrawer from '../../Interactions';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Switch from '@material-ui/core/Switch';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
}));

function DateToday(day) {
   var date = new Date();
   date.setDate(date.getDate() + day);
   const d = ('0' + date.getDate()).slice(-2);
   const m = ('0' + date.getMonth()).slice(-2);
   const today = {
      day: d,
      month: m,
      year: date.getFullYear(),
      weekDay: date.getDay(),
      ref: date.getFullYear() + m + d,
   };
   return today;
}

const StyledMenu = withStyles({
   paper: {
      border: '1px solid #d3d4d5',
   },
})((props) => (
   <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
      }}
      {...props}
   />
));

const ScheduleOptions = (props) => {
   const classes = useStyles();
   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

   const today = DateToday(0);
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [visitorsDrawer, setVisitorsDrawer] = useState(false);
   const [type, setType] = useState(null);
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      if (props.permissions && authUser) {
         props.setDisableEditPrevious(false);
         props.setEditPrevious(false);

         let type = 'user';
         if (authUser?.roles?.ADMIN === 'ADMIN') {
            type = 'admin';
         }
         if (props.permissions?.editPrevious?.[type] === 'removed') {
            props.setDisableEditPrevious(true);
         }
         if (props.permissions?.editPrevious?.[type] === 'alwaysOn') {
            props.setEditPrevious(true);
         }
         if (
            props.permissions?.editPrevious?.[type] === 'alwaysOnPrime' &&
            props.lid === authUser.lid[0]
         ) {
            props.setEditPrevious(true);
         }
         // if(props.location)
      }
   }, [props.permissions]);

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   function handleToggle() {
      props.setEditPrevious(!props.editPrevious);
   }
   return (
      <React.Fragment>
         {type && (
            <InteractionDrawer
               visitorsDrawer={visitorsDrawer}
               setVisitorsDrawer={setVisitorsDrawer}
               type={type}
               setType={setType}
               location={props.location}
            />
         )}
         <IconButton
            aria-describedby={id}
            variant="outlined"
            color="primary"
            onClick={handleClick}
         >
            <MoreHorizIcon />
         </IconButton>
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
         >
            <StyledMenu
               id="simple-menu"
               anchorEl={anchorEl}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
            >
               <ListItem>
                  <Switch
                     disabled={props.disableEditPrevious}
                     checked={props.editPrevious}
                     onChange={() => handleToggle()}
                  />
                  Edit Previous Days
               </ListItem>
            </StyledMenu>
         </Popover>
      </React.Fragment>
   );
};

export default withFirebase(ScheduleOptions);
