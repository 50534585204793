import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const WfhAmpmBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/WFHAM-Copy"
               fill={theme.palette.tableBody.main}
               fillRule="nonzero"
            >
               <path
                  d="M41,0 C41,0 19.4938713,31 19.4938713,31 L15.5,31 C6.48554758,31 0,24.0604136 0,15.5 C0,6.93958638 6.93958638,0 15.5,0 Z M14,6 L4,15 L7,15 L7,23 L13,23 L13,17 L15,17 L15,23 L21,23 L21,15 L24,15 L14,6 Z M14,8.69 L19,13.19 L19,21 L17,21 L17,15 L11,15 L11,21 L9,21 L9,13.19 L14,8.69 Z"
                  id="Path-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default WfhAmpmBooked;
