import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import { List, Paper } from '@mui/material';
import BookedSpacesListItem from './bookedSpacesListItem';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(0),
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      borderRadius: 16,
   },
   drawerPaper: {
      width: '70%',
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   booked: {
      backgroundColor: '#E8E9EB',
   },
   spaceItem: {
      padding: theme.spacing(0),
      margin: 'auto',
      width: '100%',
   },
   spaceIcon: {
      maxWidth: '35px',
      maxHeight: '35px',
      marginRight: 1,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   title: {
      marginTop: theme.spacing(4),
   },
   breadcrumb: {
      margin: theme.spacing(3, 0),
   },
   buttons: {
      textAlign: 'center',
   },
   link: {
      margin: theme.spacing(0, 2),
   },
   focusVisible: {},
}));

const DayUserBookings = (props) => {
   const [userBookings, setUserBookings] = useState(false);
   useEffect(() => {
      if (props.date && props.user != null) {
         const getUserDayBookings = props.firebase
            .getUserSpaceBookingsOnDate(props.user.cid, props.user.uid, props.date.ref)
            .onSnapshot((doc) => {
               let books = [];
               let allDay = [];
               let am = [];
               let pm = [];
               let hourly = [];
               if (doc.exists && doc.data().bookingNo >= 1) {
                  const promises = Object.entries(doc.data().bookings).map(
                     async ([key, value]) => {
                        const space = await props.firebase
                           .getSpace(value.lid, value.sid)
                           .get();
                        let loadedSpace = {
                           ...space.data(),
                           typeName: space.data().type,
                        };
                        if (loadedSpace.parent) {
                           const parent = await props.firebase
                              .getSpace(value.lid, loadedSpace.parent)
                              .get();
                           loadedSpace.parentDetail = {
                              typeName: parent.data().type,
                              ...parent.data(),
                           };
                        }
                        if (value?.type === 'All day') {
                           allDay.push({ bid: key, ...loadedSpace, ...value });
                        } else if (value?.type === 'AM') {
                           am.push({ bid: key, ...loadedSpace, ...value });
                        } else if (value?.type === 'PM') {
                           pm.push({ bid: key, ...loadedSpace, ...value });
                        } else {
                           if (props.location && props.location.id === value.lid) {
                              hourly.push({
                                 bid: key,
                                 ...loadedSpace,
                                 ...value,
                                 type: false,
                              });
                           }
                        }
                     },
                  );

                  Promise.all(promises).then(() => {
                     if (props.dayTime === 'AM') {
                        books.push(...am);
                     } else if (props.dayTime === 'PM') {
                        books.push(...pm);
                     } else {
                        books.push(...allDay);
                        books.push(...pm);
                        books.push(...am);
                     }
                     books.push(
                        ...hourly.sort((a, b) => a.start.toDate() - b.start.toDate()),
                     );
                     setUserBookings(books);
                  });
               } else {
                  setUserBookings(false);
               }
            });
         return () => getUserDayBookings();
      }
   }, [props.date, props.user.uid, props.user.cid, props.location]);

   return (
      <Grid container item xs={12}>
         <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
               Bookings
            </Typography>
         </Grid>
         {userBookings ? (
            <Paper sx={{ width: '100%', maxHeight: '150px', overflow: 'scroll' }}>
               <List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {userBookings.map((booking) => (
                     <React.Fragment key={booking.bid}>
                        <BookedSpacesListItem
                           booking={booking}
                           user={props.user}
                           date={props.date}
                        />
                        {/* <Divider /> */}
                     </React.Fragment>
                  ))}
               </List>
            </Paper>
         ) : (
            <Grid>
               <Typography variant="caption" style={{ marginLeft: '22px' }} gutterBottom>
                  No spaces booked.
               </Typography>
            </Grid>
         )}
      </Grid>
   );
};

export default withFirebase(DayUserBookings);
