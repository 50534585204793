import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PeopleIcon from '@material-ui/icons/People';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
   strikethrough: {
      textDecoration: 'line-through',
      color: '#bdbdbd',
   },
   icon: {
      marginRight: theme.spacing(2),
   },
   link: {
      marginLeft: theme.spacing(2),
   },
   close: {
      width: '30px',
   },
}));
const capacityWording =
   'CAPACITY - Set the way you want us to calculate your office capacity and the maximum allowed.';
const peopleWording = 'PEOPLE - add the other people who work in your office.';
const statusWording =
   "STATUS - Set people's intheOffice status by clicking on their daily status icon on the Schedule.";

var msTeams = false;
if (
   window.location.hostname.includes('ngrok.io') ||
   window.location.hostname.includes('msteams')
) {
   msTeams = true;
}

function Capacity(props) {
   const classes = useStyles();
   if (props.companySetUp.capacity === true) {
      return (
         <ListItem>
            <CheckCircleIcon className={classes.icon} color="primary" />
            <Typography variant="body1" className={classes.strikethrough}>
               {capacityWording}
            </Typography>
         </ListItem>
      );
   } else {
      return (
         <ListItem>
            <SettingsIcon className={classes.icon} />
            <Typography variant="body1">{capacityWording}</Typography>
            {!msTeams ? (
               <Link className={classes.link} href={ROUTES.SETTINGS}>
                  Go to Settings
               </Link>
            ) : (
               <Link
                  className={classes.link}
                  href="https://app.intheoffice.io/settings?utm_source=msteams&utm_medium=inapp&utm_campaign=settings"
                  target="_blank"
               >
                  Go to Settings Online
               </Link>
            )}
         </ListItem>
      );
   }
}
function People(props) {
   const classes = useStyles();
   if (props.companySetUp.people === true) {
      return (
         <ListItem>
            <CheckCircleIcon className={classes.icon} color="primary" />
            <Typography variant="body1" className={classes.strikethrough}>
               {peopleWording}
            </Typography>
         </ListItem>
      );
   } else {
      return (
         <ListItem>
            <PeopleIcon className={classes.icon} />
            <Typography variant="body1">{peopleWording}</Typography>
            {!msTeams ? (
               <Link className={classes.link} href={ROUTES.PEOPLE}>
                  Go to People
               </Link>
            ) : (
               <Link
                  className={classes.link}
                  href="https://app.intheoffice.io/people?utm_source=msteams&utm_medium=inapp&utm_campaign=people"
                  target="_blank"
               >
                  Go to People
               </Link>
            )}
         </ListItem>
      );
   }
}
function Status(props) {
   const classes = useStyles();
   if (props.companySetUp.status === true) {
      return (
         <ListItem>
            <CheckCircleIcon className={classes.icon} color="primary" />
            <Typography variant="body1" className={classes.strikethrough}>
               {statusWording}
            </Typography>
         </ListItem>
      );
   } else {
      return (
         <ListItem>
            <DateRangeIcon className={classes.icon} />
            <Typography variant="body1">{statusWording}</Typography>
            <Link className={classes.link} href={ROUTES.SCHEDULE}>
               Go to Schedule
            </Link>
         </ListItem>
      );
   }
}

function dismiss(event, props) {
   event.preventDefault();
   const user = JSON.parse(localStorage.getItem('authUser'));
   props.firebase.locationSetUp(user.lid[0], { 'setup.done': true });
   props.setShowInstructions(false);
}

function Instructions(props) {
   const classes = useStyles();
   return (
      <React.Fragment>
         <Grid item xs={12}>
            <Paper className={props.fixedHeightPaper}>
               <IconButton
                  className={classes.close}
                  edge="end"
                  size="small"
                  onClick={(event) => dismiss(event, props)}
                  aria-label="close"
               >
                  <CloseIcon />
               </IconButton>
               <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  Quick Start Guide
               </Typography>
               <Typography variant="body1">
                  Welcome to intheOffice. Complete the following three steps in order to
                  start managing your office capacity as quickly as possible.
               </Typography>
               <List>
                  <Capacity companySetUp={props.companySetUp} />
                  <People companySetUp={props.companySetUp} />
                  <Status companySetUp={props.companySetUp} />
               </List>

               <Link
                  href=""
                  className={classes.link}
                  onClick={(event) => dismiss(event, props)}
               >
                  Hide
               </Link>
            </Paper>
         </Grid>
      </React.Fragment>
   );
}

export default withFirebase(Instructions);
