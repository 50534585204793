import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Chip, Popover, MenuItem } from '@material-ui/core/';
import { withFirebase } from '../Firebase';
import SpaceCards from './spaceCards';
import { allocateBookings } from '../Spaces/functions';
import { DateToday } from '../../constants/functions';
import { spaceTypes } from '../../constants/data';
import { Box } from '@mui/material';
import { naturalCompare } from './utils';

const useStyles = makeStyles((theme) => ({
   paper: {
      overflow: 'auto',
      whiteSpace: 'nowrap',
   },
}));

const AvailableSpaceCards = (props) => {
   const classes = useStyles();
   const user = props.user;
   const [filters, setFilters] = useState({
      type: { name: 'All', query: '' },
      time: { name: 'All day', query: '' },
      where: 'Where',
      amenities: 'Amenities',
   });
   const [everySpace, setEverySpace] = useState(false);
   const [spaces, setSpaces] = useState(null);
   const today = DateToday();

   useEffect(() => {
      if (props.date && user != null) {
         const loadAllSpaces = async () => {
            let filteredQuery = props.firebase
               .spaces(props.location.id)
               .orderBy('name')
               .where('archive', '==', false)
               .where('bookable', '==', true);
            if (filters.type.query !== '') {
               filteredQuery = filteredQuery.where('type', '==', filters.type.query);
            }
            if (filters.time.query !== '') {
               filteredQuery = filteredQuery.where(
                  'timePeriod.' + filters.time.query,
                  '==',
                  true,
               );
            }
            await filteredQuery.get().then((results) => {
               let allSpaces = {};
               results.forEach((doc) => {
                  allSpaces[doc.id] = {
                     ...doc.data(),
                     sid: doc.id,
                     seatsAvailable: doc.data().seats,
                     seatsAvailableAM: doc.data().seats,
                     seatsAvailablePM: doc.data().seats,
                     available: true,
                  };
                  if (user.favouriteSpaces.includes(doc.id)) {
                     allSpaces[doc.id].favourite = true;
                  } else {
                     allSpaces[doc.id].favourite = false;
                  }
               });
               setEverySpace(allSpaces);
            });
         };
         loadAllSpaces();
      }
   }, [user, props.date, filters]);

   useEffect(() => {
      if (everySpace) {
         const removeBooked = props.firebase
            .spaceBookings(props.location.id, props.date.ref)
            .onSnapshot(async (booked) => {
               let availableSpaces = { ...everySpace };
               if (booked.exists) {
                  availableSpaces = await allocateBookings(
                     everySpace,
                     availableSpaces,
                     booked.data(),
                  );
               }
               let sorted = await sortSpaces(availableSpaces, '');
               setSpaces({ ...availableSpaces });
            });
         return () => removeBooked();
      }
   }, [everySpace]);

   async function sortSpaces(data, parent) {
      let sp = [];
      Object.entries(data)
         .sort(([keyA, valueA], [keyB, valueB]) => {
            // Sort by "order" key (if present)
            if (valueA.order && valueB.order) {
               return valueA.order - valueB.order;
            }
            // Sort using the custom natural compare function for alphabetical and numerical sorting
            return naturalCompare(valueA.name, valueB.name);
         })
         .forEach(([key, value]) => {
            if (value.parent === parent || !value.parent) {
               let individualSpace = { ...value, sid: key };

               sp.push(individualSpace);
            }
         });
      return sp;
   }

   const [anchorEl1, setAnchorEl1] = useState(null);
   const [anchorEl2, setAnchorEl2] = useState(null);

   const handleClose = () => {
      setAnchorEl1(null);
      setAnchorEl2(null);
   };

   const open = Boolean(anchorEl1);
   const open2 = Boolean(anchorEl2);
   const id = open ? 'simple-popover' : undefined;
   const id2 = open2 ? 'simple-popover' : undefined;
   const toggleType = (event) => {
      setAnchorEl1(event.currentTarget);
   };
   const toggleTime = (event) => {
      setAnchorEl2(event.currentTarget);
   };

   const addTypeFilter = (value) => {
      if (value === 'type') {
         setFilters({ ...filters, type: { name: 'All', query: '' } });
      } else {
         setFilters({
            ...filters,
            type: { name: spaceTypes[value], query: value },
         });
      }
      setAnchorEl1(null);
   };
   const addTimeFilter = (value) => {
      switch (value) {
         case 'allDay':
            setFilters({ ...filters, time: { name: 'All Day', query: value } });
            break;
         case 'ampm':
            setFilters({ ...filters, time: { name: 'AM/PM', query: value } });
            break;
         case 'hourly':
            setFilters({ ...filters, time: { name: 'Hourly', query: value } });
            break;
         default:
            setFilters({ ...filters, time: { name: 'All day', query: '' } });
      }
      setAnchorEl2(null);
   };

   return (
      <Box sx={{ width: '100%' }}>
         <Box>
            <Typography variant="subtitle1" gutterBottom>
               Available
            </Typography>
         </Box>
         <Box
            sx={{
               display: 'flex',
               direction: 'row',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Box sx={{ maxWidth: '100%' }}>
               <Typography variant="body2">Filter: </Typography>
               <Box>
                  <Chip
                     className={classes.filters}
                     variant="outlined"
                     aria-describedby={id}
                     onClick={toggleType}
                     label={`Type: ${filters.type.name}`}
                  />
                  <Popover
                     id={id}
                     open={open}
                     anchorEl={anchorEl1}
                     onClose={handleClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                     }}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                     }}
                  >
                     <MenuItem aria-label="None" onClick={() => addTypeFilter('type')}>
                        All
                     </MenuItem>
                     {Object.entries(spaceTypes).map(([key, type]) => (
                        <MenuItem key={key} onClick={() => addTypeFilter(key)}>
                           {type}
                        </MenuItem>
                     ))}
                  </Popover>

                  <Chip
                     variant="outlined"
                     onClick={toggleTime}
                     label={`Time: ${filters.time.name}`}
                  />
                  <Popover
                     id={id}
                     open={open2}
                     anchorEl={anchorEl2}
                     onClose={handleClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                     }}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                     }}
                  >
                     <MenuItem onClick={() => addTimeFilter('')}>All</MenuItem>
                     <MenuItem onClick={() => addTimeFilter('allDay')}>All day</MenuItem>
                     {/* <MenuItem onClick={()=>addTimeFilter('ampm')}>AM/PM</MenuItem> */}
                     <MenuItem onClick={() => addTimeFilter('hourly')}>Hourly</MenuItem>
                  </Popover>
               </Box>

               {spaces == null || Object.keys(spaces).length == 0 ? (
                  <Typography variant="caption">No Spaces Available</Typography>
               ) : (
                  <Paper elevation={0} className={classes.paper}>
                     {Object.values(spaces).map((space) => (
                        <React.Fragment key={space.sid}>
                           {space.available && (
                              <SpaceCards
                                 user={user}
                                 date={props.date}
                                 dense={false}
                                 // spaceData={props.spaceData}
                                 status={props.status}
                                 location={props.location}
                                 space={space}
                                 dayTime={props.dayTime}
                              />
                           )}
                        </React.Fragment>
                     ))}
                  </Paper>
               )}
            </Box>
         </Box>
      </Box>
   );
};

export default withFirebase(AvailableSpaceCards);
