import firebase from 'firebase/app';
export const createBatch = () => firebase.firestore().batch();
export const increment = (i = 1) => firebase.firestore.FieldValue.increment(i);

const userRef = (uid) => firebase.firestore().doc(`users/${uid}`);
const coRef = (cid) => firebase.firestore().doc(`company/${cid}`);
const coIndexRef = (cid) => firebase.firestore().doc(`company/${cid}/indexes/people`);
const coPeopleRef = (cid, uid) =>
   firebase.firestore().doc(`company/${cid}/people/${uid}`);
const locRef = (lid) => firebase.firestore().doc(`location/${lid}`);
const locIndexRef = (lid) => firebase.firestore().doc(`location/${lid}/indexes/people`);
const domainRef = (cid) => firebase.firestore().doc(`domains/${cid}`);

export async function signUpUser(
   firstName,
   lastName,
   domain,
   email,
   password,
   roles,
   companyName,
   allowExtraEmails,
) {
   const batch = createBatch();
   var emailDomain = email.split('@');
   const authUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUser) => {
         var newUser = firebase.auth().currentUser;
         newUser.updateProfile({
            displayName: firstName + ' ' + lastName,
         });
         return newUser;
      });

   const userId = authUser.uid;

   var newCompanyKey = firebase.firestore().collection(`company`).doc();
   var newLocationKey = firebase.firestore().collection(`location`).doc();

   batch.set(
      userRef(userId),
      {
         cid: `${newCompanyKey.id}`,
         lid: [`${newLocationKey.id}`],
         locationAdmin: [`${newLocationKey.id}`],
         gid: [],
         name: {
            firstName,
            lastName,
         },
         email,
         roles,
         marketing: {
            optIn: allowExtraEmails,
         },
      },
      { merge: true },
   );
   batch.set(
      coRef(newCompanyKey.id),
      {
         companyName,
         profile: {
            creationDate: new Date(),
            termsAgreed: new Date(),
         },
         locations: {
            [`${newLocationKey.id}`]: companyName,
         },
         subscription: {
            plan: 'essential',
            planStartDate: new Date(),
         },
         domains: {
            used: [emailDomain[1].toLowerCase()],
            allowed: [],
         },
         totalPeople: 1,
         archived: false,
         features: {
            scheduling: true,
         },
      },
      { merge: true },
   );

   batch.set(
      coPeopleRef(newCompanyKey.id, userId),
      {
         name: {
            firstName,
            lastName,
         },
         email,
         roles,
         locationAdmin: [`${newLocationKey.id}`],
         lid: [`${newLocationKey.id}`],
         gid: [],
         cid: `${newCompanyKey.id}`,
         status: {
            statusId: 'user1',
         },
      },
      { merge: true },
   );
   batch.set(
      locRef(newLocationKey.id),
      {
         name: companyName,
         profile: {
            creationDate: new Date(),
         },
         rules: {
            totalMaxCapacity: 100,
         },
         capacity: {
            unit: 'people',
         },
         people: 1,
         cid: `${newCompanyKey.id}`,
         locationAdmins: [`${userId}`],
         permissions: {
            editPrevious: {
               admin: 'alwaysOn',
               user: 'defaultOff',
            },
         },
         setup: {
            capacity: false,
            people: false,
            status: false,
            done: false,
         },
      },
      { merge: true },
   );
   batch.set(
      domainRef(newCompanyKey.id),
      {
         active: true,
         cid: `${newCompanyKey.id}`,
         usedDomains: [emailDomain[1].toLowerCase()],
         allowedDomains: [],
      },
      { merge: true },
   );
   batch.set(
      coIndexRef(newCompanyKey.id),
      {
         [userId]: {
            email,
            name: {
               firstName: `${firstName}`,
               lastName: `${lastName}`,
            },
            lid: newLocationKey.id,
         },
      },
      { merge: true },
   );
   batch.set(
      locIndexRef(newLocationKey.id),
      {
         [userId]: {
            name: {
               firstName: `${firstName}`,
               lastName: `${lastName}`,
            },
         },
      },
      { merge: true },
   );

   try {
      await batch.commit();
      return { message: 'success' };
   } catch (error) {
      console.error('Error setting up company', error);
      try {
         await firebase.auth().currentUser.delete();
      } catch (error) {
         console.error('Error deleting user', error);
      }
      return { message: 'error', error };
   }
}
