import React from 'react';
import { Chip, Grid } from '@mui/material';

const ChipSet = ({ listItems, onDelete = null, ...rest }) => {
   if (listItems.length > 0) {
      return (
         <Grid container spacing={1}>
            {listItems.map((item, index) => (
               <Grid item key={index}>
                  <Chip
                     id={item.alias}
                     className={item.alias}
                     label={item.label}
                     onDelete={onDelete ? () => onDelete(item) : null}
                     {...rest}
                  />
               </Grid>
            ))}
         </Grid>
      );
   }

   return null;
};

export default ChipSet;
