import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// interface ReadOnlyURLSearchParams extends URLSearchParams {
//   append: never;
//   set: never;
//   delete: never;
//   sort: never;
// }

export function useSearchParams() {
   const { pathname, search } = useLocation();
   const history = useHistory();

   function getParams() {
      const params = new URLSearchParams(search);

      let paramObj = {};
      for (var value of params.keys()) {
         paramObj[value] = params.get(value);
      }
      return paramObj;
   }

   function setParams(object) {
      const newParams = new URLSearchParams(object).toString();
      history.push(`${pathname}?${newParams}`);
   }

   return [getParams, setParams];
}
