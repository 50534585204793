import React, { useState, useEffect } from 'react';
import {
   Avatar,
   Typography,
   Box,
   Button,
   Card,
   CardMedia,
   CardContent,
   CardActions,
   Modal,
} from '@mui/material';
import 'date-fns';
import { Calendar, Views } from 'react-big-calendar';
import { components, localizer } from '../../../Spaces/utils/CalendarComponents';
import defaultImage from '../../../../assets/defaultMeeting.svg';
import { withFirebase } from '../../../Firebase';
import { set } from 'date-fns';
import './customCalendarStyles.css';

const HourlyCards = (props) => {
   const [imageURL, setImageURL] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [events, setEvents] = useState(false);
   useEffect(() => {
      const getSpaces = async () => {
         if (props.space) {
            if (props.space.imagePath) {
               await props.firebase
                  .showImage(props.space.imagePath)
                  .getDownloadURL()
                  .then(function (url) {
                     setImageURL(url);
                  })
                  .catch(function (error) {
                     console.warn(error, 'error - retrieving image url');
                  });
            }
         }
      };
      return getSpaces();
   }, [props.space]);

   useEffect(() => {
      const getEvents = async () => {
         if (props.events) {
            setEvents(props.events);
         }
      };
      getEvents();
      return () => {
         setEvents(false);
      };
   }, [props.events, props.day]);

   function newBooking(space) {
      let lid = props.displayFilter.id;
      if (props.displayFilter.type === 'group') {
         lid = props.displayFilter.lid;
      }
      let data = {
         date: props.day,
         location: {
            id: lid,
         },
         space,
         spaceSchedule: { ...props.spaceSchedule },
         cid: props.displayFilter.cid,
         existing: false,
         booking: null,
         edit: true,
      };
      props.setDetailsData(data);
      props.setDetailsOpen(true);
   }

   const onSelectEvent = (event) => {
      let lid = props.displayFilter.id;
      if (props.displayFilter.type === 'group') {
         lid = props.displayFilter.lid;
      }
      let data = {
         date: props.day,
         location: {
            id: lid,
         },
         space: props.space,
         spaceSchedule: { ...props.spaceSchedule },
         cid: props.displayFilter.cid,
         existing: true,
         booking: { ...event, lid, sid: props.space.sid },
         edit: false,
      };
      props.setDetailsData(data);
      props.setDetailsOpen(true);
   };

   function scrollCurrentTime() {
      const time = new Date();
      const newTime = new Date(
         props.day.year,
         props.day.month,
         props.day.day,
         time.getHours(),
         0,
         0,
         0,
      );
      if (
         time.getFullYear() == newTime.getFullYear() &&
         time.getMonth() == newTime.getMonth() &&
         time.getDay() == newTime.getDay()
      ) {
         return newTime;
      } else {
         return new Date(props.day.year, props.day.month, props.day.day, 9, 0, 0, 0);
      }
   }

   return (
      <Card sx={{ minWidth: 300, maxWidth: 300, m: 2 }}>
         {!imageURL ? (
            <CardMedia
               sx={{ height: 140 }}
               component="img"
               src={defaultImage}
               title={props.space.name}
            />
         ) : (
            <CardMedia
               sx={{ height: 140 }}
               component="img"
               src={imageURL}
               title={props.space.name}
            />
         )}
         <CardContent>
            <Typography variant="h5" component="div" sx={{ fontSize: 'large' }}>
               {props.space.name}
            </Typography>
            <Typography gutterBottom variant="caption" component="span">
               Type: {props.space.type} | Seats:{props.space.seats}
            </Typography>
            <Typography variant="caption" component="p">
               Description: {props.space.description}
            </Typography>
            <Button
               onClick={() => newBooking(props.space)}
               variant="outlined"
               size="small"
               fullWidth
               sx={{ mb: 2 }}
            >
               Create a booking
            </Button>
            {events && (
               <Calendar
                  selectable
                  views={{ day: true }}
                  defaultDate={props.day.fullDate}
                  defaultView={Views.DAY}
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 300 }}
                  // onSelecting={onSelecting}
                  components={components}
                  toolbar={false}
                  dayLayoutAlgorithm="no-overlap"
                  onSelectEvent={onSelectEvent}
                  scrollToTime={scrollCurrentTime()}
                  step={30}
                  eventPropGetter={(event) => {
                     const backgroundColor = event.saved ? '#342E76' : '#32BFC7';
                     // const color = event.saved ? 'white': '#342E76';
                     return { style: { backgroundColor } };
                  }}
                  // min={minTime}
                  // max={maxTime}
               />
            )}
         </CardContent>
         <CardActions>
            <Button
               onClick={() => newBooking(props.space)}
               variant="outlined"
               size="small"
               fullWidth
            >
               Create a booking
            </Button>
         </CardActions>
      </Card>
   );
};

export default withFirebase(HourlyCards);
