import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AvailabilityList from '../availabilityList';
import BookingLocation from './bookingLocation';
import { Box, Divider } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
   <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
      width: 220,
      boxShadow: theme.shadows[1],
      fontSize: 'large',
   },
}));

var i = 0;

const ItoChip = (props) => {
   let [allDayBookings, setAllDayBookings] = useState([]);
   let [amBookings, setAmBookings] = useState([]);
   let [pmBookings, setPmBookings] = useState([]);

   useEffect(() => {
      if (props.bookings !== null && props.bookings !== undefined) {
         let all = [];
         let am = [];
         let pm = [];
         props.bookings.map((book) => {
            if (book.type === 'All day') {
               all.push(book);
            }
            if (book.type === 'AM') {
               am.push(book);
            }
            if (book.type === 'PM') {
               pm.push(book);
            }
         });
         setAllDayBookings(all);
         setAmBookings(am);
         setPmBookings(pm);
      }
   }, [props.bookings]);

   function handleMore(value) {
      props.setSpaceData(props.data);
      props.setStatusDrawerOpen(true);
   }

   if (props.spacesEnabled === true) {
      if (props.spaces && props.data.space !== 'empty' && props.bookings !== null) {
         return (
            <HtmlTooltip
               title={
                  <React.Fragment>
                     <Grid container>
                        {props.availability && (
                           <Grid item xs={12}>
                              <List dense={true}>
                                 {props.availability.map((time) => {
                                    i++;
                                    return <AvailabilityList key={i} time={time} />;
                                 })}
                              </List>
                           </Grid>
                        )}
                        {allDayBookings.length !== 0 && (
                           <Grid container item spacing={0}>
                              {allDayBookings.map((booking) => (
                                 <BookingLocation
                                    key={booking.bid}
                                    booking={booking}
                                    spaces={props.spaces}
                                 />
                              ))}
                           </Grid>
                        )}
                        {allDayBookings.length !== 0 && amBookings.length !== 0 && (
                           <Divider sx={{ width: '100%', bgcolor: '#fff' }} />
                        )}
                        {amBookings.length !== 0 && (
                           <Grid
                              container
                              item
                              spacing={0}
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="stretch"
                           >
                              <Grid item xs={2}>
                                 <Typography>AM:</Typography>
                              </Grid>
                              <Grid container item xs={10}>
                                 {amBookings.map((booking) => (
                                    <BookingLocation
                                       key={booking.bid}
                                       booking={booking}
                                       spaces={props.spaces}
                                    />
                                 ))}
                              </Grid>
                           </Grid>
                        )}
                        {((allDayBookings.length !== 0 && pmBookings.length !== 0) ||
                           (amBookings.length !== 0 && pmBookings.length !== 0)) && (
                           <Divider sx={{ width: '100%', bgcolor: '#fff' }} />
                        )}
                        {pmBookings.length !== 0 && (
                           <Grid container item spacing={0} alignItems="stretch">
                              <Grid item xs={2}>
                                 <Typography>PM:</Typography>
                              </Grid>
                              <Grid container item xs={10}>
                                 {pmBookings.map((booking) => (
                                    <BookingLocation
                                       key={booking.bid}
                                       booking={booking}
                                       spaces={props.spaces}
                                    />
                                 ))}
                              </Grid>
                           </Grid>
                        )}
                     </Grid>
                  </React.Fragment>
               }
            >
               <Chip
                  size="medium"
                  clickable={props.clickable}
                  icon={<DoneIcon />}
                  label=" "
                  color="primary"
                  deleteIcon={<MoreVertIcon />}
                  onDelete={() => handleMore(props.value.set)}
               />
            </HtmlTooltip>
         );
      } else if (props.routineEnabled && props.availability) {
         return (
            <HtmlTooltip
               title={
                  <React.Fragment>
                     <Grid container>
                        {props.availability && (
                           <Grid item xs={12}>
                              <List dense={true}>
                                 {props.availability.map((time) => {
                                    i++;
                                    return <AvailabilityList key={i} time={time} />;
                                 })}
                              </List>
                           </Grid>
                        )}
                     </Grid>
                  </React.Fragment>
               }
            >
               <Chip
                  size="medium"
                  clickable={props.clickable}
                  icon={<DoneIcon />}
                  label=" "
                  color="primary"
                  variant="outlined"
                  deleteIcon={<MoreVertIcon />}
                  onDelete={() => handleMore(props.value.set)}
               />
            </HtmlTooltip>
         );
      } else {
         return (
            <Chip
               size="medium"
               clickable={props.clickable}
               icon={<DoneIcon />}
               label=" "
               color="primary"
               variant="outlined"
               deleteIcon={<MoreVertIcon />}
               onDelete={() => handleMore(props.value.set)}
            />
         );
      }
   } else if (props.bookings && props.bookings !== null && props.spaces !== null) {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                     {props.bookings.map((booking) => {
                        if (props.spaces[booking.sid]) {
                           return (
                              <Grid container item spacing={0} key={booking.bid}>
                                 {props.spaces[booking.sid].parent && (
                                    <Grid item xs={12}>
                                       <Typography variant="caption">
                                          {props.spaces[props.spaces[booking.sid].parent]
                                             .parent &&
                                             props.spaces[
                                                props.spaces[
                                                   props.spaces[booking.sid].parent
                                                ].parent
                                             ].name + ' > '}
                                          {
                                             props.spaces[
                                                props.spaces[booking.sid].parent
                                             ].name
                                          }
                                          {' > '}
                                       </Typography>
                                    </Grid>
                                 )}
                                 <Grid item xs={10}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                          justifyItems: 'center',
                                          mr: 1,
                                       }}
                                    >
                                       {props.spaces[booking.sid].backgroundColor && (
                                          <Box
                                             sx={{
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor:
                                                   props.spaces[booking.sid]
                                                      .backgroundColor,
                                                mr: 1,
                                             }}
                                          ></Box>
                                       )}
                                       <Box>{props.spaces[booking.sid].name}</Box>
                                    </Box>
                                 </Grid>
                                 {/* <Grid item xs={2}>
                    <IconButton aria-label="More insights" color="inherit" size="small" >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid> */}
                              </Grid>
                           );
                        }
                     })}
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               size="medium"
               clickable={props.clickable}
               label={<DoneIcon style={{ marginTop: 5 }} />}
               color="primary"
            />
         </HtmlTooltip>
      );
   } else if (props.routineEnabled && props.availability && props.clickable) {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               size="medium"
               clickable={props.clickable}
               icon={<DoneIcon />}
               label=" "
               color="primary"
               // variant="outlined"
               deleteIcon={<MoreVertIcon />}
               onDelete={() => handleMore(props.value.set)}
            />
         </HtmlTooltip>
      );
   } else if (props.routineEnabled && props.availability) {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               size="medium"
               clickable={props.clickable}
               icon={<DoneIcon />}
               label=" "
               color="primary"
               // variant="outlined"
            />
         </HtmlTooltip>
      );
   } else if (props.routineEnabled && props.clickable) {
      return (
         <Chip
            size="medium"
            clickable={props.clickable}
            icon={<DoneIcon />}
            label=" "
            color="primary"
            // variant="outlined"
            deleteIcon={<MoreVertIcon />}
            onDelete={() => handleMore(props.value.set)}
         />
      );
   } else {
      return (
         <Chip
            size="medium"
            clickable={props.clickable}
            label={<DoneIcon style={{ marginTop: 5 }} />}
            color="primary"
         />
      );
   }
};

export default ItoChip;
