import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Button, Dialog, Paper, TextField, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@mui/material/Collapse';
import ITOBlocks from './ITOBlocks';
import BusinessIcon from '@mui/icons-material/Business';
import ChipDialog from '../chipDialog';
import { set } from 'date-fns';
import { useAuth } from '../../../providers';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      maxWidth: '100%',
   },
}));

const OffsiteBlock = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const { authUser } = useAuth();
   const [form, setForm] = useState({ input: '', inputDisabled: false });
   const [locations, setLocations] = useState(false);
   const [location, setLocation] = useState(false);
   const [loading, setLoading] = useState(false);
   const [showBookings, setShowBookings] = useState(false);
   const [selectedLoc, setSelectedLoc] = useState(null);
   const [saved, setSaved] = useState(false);
   const [saving, setSaving] = useState(false);
   const [wrongLoc, setWrongLoc] = useState(false);
   const [spaceModal, setSpaceModal] = useState(false);
   useEffect(() => {
      const prePopulateName = async () => {
         setSaved(false);
         let data = { input: '', inputDisabled: false };
         setSelectedLoc(null);
         if (location) {
            if (props.status.lid && props.status.lid !== props.data.user.lid) {
               setWrongLoc(true);
            }
            if (props.status.offsiteLocation) {
               if (!props.dayTime) {
                  if (props.status.offsiteLocation.locationId) {
                     data.inputDisabled = true;
                     data.locationId = props.status.offsiteLocation.locationId;
                     data.locationName = props.status.offsiteLocation.locationName;
                     setSelectedLoc({
                        lid: props.status.offsiteLocation.locationId,
                        label: location[props.status.offsiteLocation.locationId],
                     });
                     setShowBookings(true);
                  } else {
                     data.input = props.status.offsiteLocation;
                  }
               } else {
                  if (
                     Object.keys(props.status.offsiteLocation).length !== 0 &&
                     props.status.offsiteLocation[props.dayTime]?.locationId
                  ) {
                     data.inputDisabled = true;
                     data.locationId =
                        props.status.offsiteLocation[props.dayTime].locationId;
                     data.locationName =
                        props.status.offsiteLocation[props.dayTime].locationName;
                     setSelectedLoc({
                        lid: props.status.offsiteLocation[props.dayTime].locationId,
                        label: location[
                           props.status.offsiteLocation[props.dayTime].locationId
                        ],
                     });
                     setShowBookings(true);
                  } else if (
                     props.status.offsiteLocation[props.dayTime] &&
                     props.status.offsiteLocation[props.dayTime] !== ''
                  ) {
                     data.input = props.status.offsiteLocation[props.dayTime];
                     data.inputDisabled = false;
                     setShowBookings(false);
                  } else {
                     data.input = '';
                     data.inputDisabled = false;
                     setShowBookings(false);
                  }
               }
            } else {
               data.input = '';
               data.inputDisabled = false;
               setShowBookings(false);
            }
            if (wrongLoc) {
               data.inputDisabled = true;
               setShowBookings(false);
            }
            setForm(data);
         }
      };
      prePopulateName();
   }, [props.status, props.dayTime, props.status.offsiteLocation, location]);

   useEffect(() => {
      const loadLocations = async () => {
         if (props.data?.user?.cid) {
            await props.firebase
               .company(props.data.user.cid)
               .get()
               .then((result) => {
                  if (result.exists && result.data()?.features?.multisite) {
                     let locList = [];
                     let locs = {};
                     Object.entries(result.data().locations).map(([key, value]) => {
                        let lid = props.displayFilter.id;
                        if (props.displayFilter.type === 'group') {
                           lid = props.displayFilter.lid;
                        }
                        if (key !== lid) {
                           locList.push({ lid: key, label: value });
                           locs[key] = value;
                        }
                     });
                     setLocation(locs);
                     setLocations(locList);
                  }
                  return;
               })
               .catch((error) => {
                  console.warn(error, 'Error - loading locations');
               });
         }
      };
      loadLocations();
   }, [props.data.user]);

   const handleLocation = async (value, obj) => {
      let routine = false;
      if (props.status.routine && props.status.routine === 'offsite') {
         routine = true;
      }
      // if(props.displayFilter.id !== props.status.lid){
      //   form.lid = props.displayFilter.id;
      // }
      if (obj !== null) {
         let data = {
            input: '',
            inputDisabled: true,
            locationId: obj.lid,
            locationName: obj.label,
         };
         setForm(data);
         try {
            await props.firebase.offSiteLocation(
               props.data,
               props.status,
               data,
               routine,
               props.dayTime,
            );
         } catch (error) {
            console.warn(error, 'Problem setting location');
         }
      } else {
         let data = {
            input: '',
            inputDisabled: false,
         };
         setForm(data);
         try {
            await props.firebase.offSiteLocation(
               props.data,
               props.status,
               data,
               routine,
               props.dayTime,
            );
            setShowBookings(false);
         } catch (error) {
            console.warn(error, 'Problem setting location');
         }
      }
   };

   function handleClose() {
      setForm({ input: '', inputDisabled: false });
      setLocations(false);
      setSelectedLoc(null);
   }
   function handleSubmit() {
      setSaving(true);
      let routine = false;
      if (props.status.routine && props.status.routine === 'offsite') {
         routine = true;
      }
      let lid = props.displayFilter.id;
      if (props.displayFilter.type === 'group') {
         lid = props.displayFilter.lid;
      }
      if (lid !== props.status.lid) {
         form.lid = lid;
      }
      props.firebase
         .offSiteLocation(props.data, props.status, form, routine, props.dayTime)
         .then(() => {
            setTimeout(() => {
               setSaving(false);
               setSaved(true);
            }, 1000);
         })
         .catch((error) => {
            console.warn(error, 'error');
         });
   }

   async function handleBookingRemoval() {
      let type = false;
      let data = {
         ...props.data,
         space: {},
      };
      var i = 0;
      Object.entries(props.status.bookings).map(([key, value]) => {
         if (props.dayTime) {
            if (value.type === props.dayTime || !value.type) {
               data.space[key] = value;
               data.space.bookingNo = i + 1;
               i++;
            }
         } else {
            data.space[key] = value;
            data.space.bookingNo = i + 1;
            i++;
         }
      });
      try {
         console.log('offsiteBlock');
         await props.firebase.cancelBookedSpaceForUser(data, authUser, type);
         setSpaceModal(false);
      } catch (error) {
         console.warn(error, 'Error cancelling booking from offsiteBlock');
      }
      let newData = {
         input: '',
         inputDisabled: false,
      };
      setForm(newData);
      let routine = false;
      if (props.status.routine && props.status.routine === 'offsite') {
         routine = true;
      }
      try {
         await props.firebase.offSiteLocation(
            props.data,
            props.status,
            newData,
            routine,
            props.dayTime,
         );
         setShowBookings(false);
      } catch (error) {
         console.warn(error, 'Problem setting location');
      }
   }

   const itoBlockDisplayFilter = React.useMemo(() => {
      return {
         id: form.locationId,
         type: 'location',
         cid: props.displayFilter.cid,
      };
   }, [form.locationId, props.displayFilter.cid]);

   return (
      <div className={classes.root}>
         <Dialog
            open={spaceModal}
            onClose={() => setSpaceModal(false)}
            aria-labelledby="remove-space-bookings-dialog"
            fullWidth
            maxWidth="sm"
         >
            <Typography variant="h6" sx={{ p: 2 }}>
               Remove bookings?
            </Typography>
            <Typography variant="body1" sx={{ p: 2 }}>
               You have reserved one or more spaces at this location. Click on Remove to
               cancel these bookings and reset your offsite location.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
               <Button onClick={() => setSpaceModal(false)}>Cancel</Button>
               <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleBookingRemoval()}
               >
                  Remove
               </Button>
            </Box>
         </Dialog>
         <Grid container item xs={12}>
            <Collapse
               in={props.spacesEnabled ? !showBookings : true}
               sx={{ width: '100%' }}
            >
               <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontSize: 'large' }}>
                     Where are you working?
                  </Typography>
               </Grid>

               <Grid item xs={12}>
                  <Typography sx={{ fontSize: 'medium' }}>
                     Type in the name of your offsite location
                  </Typography>
                  <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                     <InputLabel htmlFor="type-location">Type Location</InputLabel>
                     <OutlinedInput
                        id="type-location"
                        value={form.input}
                        name="location"
                        disabled={form.inputDisabled}
                        type="text"
                        onChange={(e) => {
                           setForm({ ...form, input: e.target.value });
                           setSaved(false);
                        }}
                        endAdornment={
                           <InputAdornment position="end">
                              <Divider
                                 sx={{ height: 28, m: 0.5 }}
                                 orientation="vertical"
                              />
                              {saved ? (
                                 <CheckCircleIcon
                                    color="success"
                                    fontSize="large"
                                    sx={{ ml: 0.5 }}
                                 />
                              ) : saving ? (
                                 <CircularProgress color="success" fontSize="small" />
                              ) : (
                                 <IconButton
                                    aria-label="Save offsite location"
                                    disabled={
                                       form.input === props.status.offsiteLocation ||
                                       wrongLoc
                                    }
                                    onClick={() => handleSubmit()}
                                    edge="end"
                                    color="primary"
                                    sx={{ ml: 1.5 }}
                                 >
                                    <SaveIcon />
                                 </IconButton>
                              )}
                           </InputAdornment>
                        }
                        label="Type Location"
                     />
                  </FormControl>
               </Grid>

               {locations && (
                  <Grid item container xs={12}>
                     <Grid item xs={12}>
                        <Typography sx={{ fontSize: 'medium' }}>
                           Or select another location within your company
                        </Typography>

                        <Autocomplete
                           disablePortal
                           disabled={wrongLoc}
                           id="location-box"
                           options={locations}
                           onChange={(value, obj) => {
                              handleLocation(value, obj);
                              setSaved(false);
                           }}
                           value={selectedLoc}
                           renderInput={(params) => (
                              <TextField
                                 sx={{
                                    '& fieldset': {
                                       border: '1px solid rgba(0, 0, 0, 0.38)',
                                    },
                                 }}
                                 {...params}
                                 variant="outlined"
                                 fullWidth
                                 margin="normal"
                                 label="Select Location"
                              />
                           )}
                        />
                        {wrongLoc && (
                           <React.Fragment>
                              <Typography color="secondary" type="caption" align="center">
                                 Your primary location has changed since this day.
                              </Typography>
                              <Typography color="secondary" type="caption" align="center">
                                 To edit these details, you must reset your status by
                                 clicking on the status chip at the top of this drawer.
                              </Typography>
                           </React.Fragment>
                        )}
                     </Grid>
                  </Grid>
               )}
            </Collapse>
            {form.inputDisabled && props.spacesEnabled && (
               <Collapse in={showBookings} sx={{ maxWidth: '100%', minWidth: '100%' }}>
                  <Grid item xs={12}>
                     <Box sx={{ m: 2 }}>
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              alignContent: 'center',
                              mt: 2,
                           }}
                        >
                           <Typography variant="body2" sx={{ mr: 1 }}>
                              Working from:{' '}
                           </Typography>

                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 alignContent: 'center',
                                 justifyContent: 'space-evenly',
                              }}
                           >
                              <Typography
                                 variant="h6"
                                 sx={{
                                    fontSize: 'large',
                                    display: 'flex',
                                    alignItems: 'start',
                                    alignContent: 'center',
                                 }}
                              >
                                 <BusinessIcon sx={{ mr: 1 }} /> {form.locationName}
                              </Typography>
                              <Button
                                 size="small"
                                 sx={{ ml: 3 }}
                                 onClick={() => {
                                    if (
                                       !props.status.bookingNo ||
                                       props.status.bookingNo === 0
                                    ) {
                                       handleLocation('', null);
                                       setSaved(false);
                                    } else {
                                       setSpaceModal(true);
                                    }
                                 }}
                              >
                                 remove
                              </Button>
                           </Box>
                        </Box>
                        <Paper sx={{ p: 2, mt: 1 }}>
                           <ITOBlocks
                              // data={props.data}
                              user={props.data.user}
                              date={props.data.date}
                              dayTime={props.dayTime}
                              status={props.status}
                              location={location}
                              displayFilter={itoBlockDisplayFilter}
                           />
                        </Paper>
                     </Box>
                  </Grid>
               </Collapse>
            )}
         </Grid>
      </div>
   );
};

export default withFirebase(OffsiteBlock);
