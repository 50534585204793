import React from 'react';
import { CardSection } from '../../../layout';
import { Typography } from '@mui/material';

const GeneralCoInfo = ({ company }) => {
   return (
      <CardSection>
         <Typography>Total People: {company.totalPeople}</Typography>
      </CardSection>
   );
};

export default GeneralCoInfo;
