import React from 'react';
import { AppBar as MuiAppBar, Toolbar, useTheme } from '@mui/material';

const AppBar = ({ children }) => {
   const theme = useTheme();

   return (
      <MuiAppBar
         position="fixed"
         elevation={0}
         style={{ zIndex: theme.zIndex.drawer + 1 }}
      >
         <Toolbar
            sx={{
               backgroundImage: 'linear-gradient(16deg, #32bfc7, #349fb8)',
            }}
         >
            {children}
         </Toolbar>
      </MuiAppBar>
   );
};

export default AppBar;
