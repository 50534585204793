import React, { useState, useEffect } from 'react';
import { Box, Icon } from '@mui/material';
import ITOAM from '../../../assets/status/ITOAM.svg';
import ITOAMBooked from '../../../assets/status/ITOAM-booked.svg';
import ITOPMBooked from '../../../assets/status/ITOPM-booked.svg';
import ITOAMBoth from '../../../assets/status/ITOAM-bothbooked.svg';
import ITOPM from '../../../assets/status/ITOPM.svg';
import WFHAM from '../../../assets/status/WFHAM.svg';
import WFHPM from '../../../assets/status/WFHPM.svg';
import OFFSITEAM from '../../../assets/status/OFFSITEAM.svg';
import OFFSITEPM from '../../../assets/status/OFFSITEPM.svg';
import AWAYAM from '../../../assets/status/AWAYAM.svg';
import AWAYPM from '../../../assets/status/AWAYPM.svg';
import DASH from '../../../assets/status/DASH.svg';
import DASHAM from '../../../assets/status/DASHAM.svg';
import ItoAm from '../../../assets/chips/AM/ItoAm';
import ItoAmBooked from '../../../assets/chips/AM/ItoAmBooked';
import ItoAmNotBooked from '../../../assets/chips/AM/ItoAm';
import ItoAmpmBooked from '../../../assets/chips/AM/ItoAmpmBooked';
import WfhAm from '../../../assets/chips/AM/WfhAm';
import OffsiteAm from '../../../assets/chips/AM/OffsiteAm';
import AwayAm from '../../../assets/chips/AM/AwayAm';
import BlankAm from '../../../assets/chips/AM/BlankAm';
import ItoPm from '../../../assets/chips/PM/ItoPm';
import ItoPmBooked from '../../../assets/chips/PM/ItoPmBooked';
import ItoPmNotBooked from '../../../assets/chips/PM/ItoPm';
import AwayPm from '../../../assets/chips/PM/AwayPm';
import WfhPm from '../../../assets/chips/PM/WfhPm';
import OffsitePm from '../../../assets/chips/PM/OffsitePm';
import BlankPm from '../../../assets/chips/PM/BlankPm';
import WfhAmpmBooked from '../../../assets/chips/AM/WfhAmpmBooked';
import OffsiteAmpmBooked from '../../../assets/chips/AM/OffsiteAmpmBooked';
import AwayAmpmBooked from '../../../assets/chips/AM/AwayAmpmBooked';
import BlankAmpmBooked from '../../../assets/chips/AM/BlankAmpmBooked';
import ItoPmBookedNotClickable from '../../../assets/chips/PM/ItoPmBookednotClickable';
import ItoBothBooked from '../../../assets/chips/ItoBothBooked';

const SplitIcons = (props) => {
   const [bothBooked, setBothBooked] = useState(false);
   const [amBooked, setAmBooked] = useState(false);
   const [pmBooked, setPmBooked] = useState(false);
   const splitAllBooked = {
      width: '62px',
      height: '31px',
   };
   const splitAmStyles = {
      width: '40px',
      height: '31px',
   };
   const splitAmStylespmBooked = {
      width: '40px',
      height: '31px',
      position: 'relative',
      left: '1px',
   };
   const splitPmStyles = {
      position: 'relative',
      left: '-26px',
      mr: '-21px',
      width: '40px',
      height: '31px',
      '& .MuiSvgIcon-root': {
         width: '40px',
         height: '31px',
      },
   };
   useEffect(() => {
      if (props.dayStatus && props.dayStatus.bookings !== null) {
         let amBooking = false;
         let pmBooking = false;
         props.dayStatus.bookings.forEach((booking) => {
            if (booking.type === 'AM') {
               amBooking = true;
            }
            if (booking.type === 'PM') {
               pmBooking = true;
            }
         });
         if (props.value.splitValues.AM === 'ito' && amBooking) {
            setAmBooked(true);
         } else {
            setAmBooked(false);
         }
         if (props.value.splitValues.PM === 'ito' && pmBooking) {
            setPmBooked(true);
         } else {
            setPmBooked(false);
         }
         if (
            amBooking &&
            pmBooking &&
            props.value.splitValues.AM === 'ito' &&
            props.value.splitValues.PM === 'ito'
         ) {
            setBothBooked(true);
         } else {
            setBothBooked(false);
         }
      } else {
         setBothBooked(false);
         setAmBooked(false);
         setPmBooked(false);
      }
   }, [props.value, props.dayStatus]);

   return (
      <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
         {bothBooked ? (
            <ItoBothBooked sx={splitAllBooked} className="custom-svg-icon" />
         ) : (
            <>
               {props.value.splitValues.AM === 'ito' ? (
                  <>
                     {props.pmBooked && !amBooked ? (
                        <ItoAmpmBooked sx={splitAmStylespmBooked} />
                     ) : amBooked ? (
                        <ItoAmBooked sx={splitAmStyles} />
                     ) : (
                        <ItoAmNotBooked sx={splitAmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.AM === 'away' ? (
                  <>
                     {props.pmBooked ? (
                        <AwayAmpmBooked sx={splitAmStylespmBooked} />
                     ) : (
                        <AwayAm sx={splitAmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.AM === 'wfh' ? (
                  <>
                     {props.pmBooked ? (
                        <WfhAmpmBooked sx={splitAmStylespmBooked} />
                     ) : (
                        <WfhAm sx={splitAmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.AM === 'offsite' ? (
                  <>
                     {props.pmBooked ? (
                        <OffsiteAmpmBooked sx={splitAmStylespmBooked} />
                     ) : (
                        <OffsiteAm sx={splitAmStyles} />
                     )}
                  </>
               ) : (
                  <>
                     {props.pmBooked ? (
                        <BlankAmpmBooked sx={splitAmStylespmBooked} />
                     ) : (
                        <BlankAm sx={splitAmStyles} />
                     )}
                  </>
               )}

               {props.value.splitValues.PM === 'ito' ? (
                  <>
                     {pmBooked ? (
                        <>
                           {props.clickable ? (
                              <ItoPmBooked sx={{ ...splitPmStyles, left: '-24px' }} />
                           ) : (
                              <ItoPmBookedNotClickable
                                 sx={{ ...splitPmStyles, left: '-20px' }}
                              />
                           )}
                        </>
                     ) : (
                        <ItoPmNotBooked sx={splitPmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.PM === 'away' ? (
                  <AwayPm sx={splitPmStyles} />
               ) : props.value.splitValues.PM === 'wfh' ? (
                  <WfhPm sx={splitPmStyles} />
               ) : props.value.splitValues.PM === 'offsite' ? (
                  <OffsitePm sx={splitPmStyles} />
               ) : (
                  <BlankPm sx={splitPmStyles} />
               )}
            </>
         )}
      </Box>
   );
};
// const commonStyles = {
//    width: '40px',
//    height: '31px',
// };
// return (
//    <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//       {props.value.splitValues.AM === 'ito' ? (
//          <React.Fragment>
//             {props.booking ? (
//                <ItoAmBooked sx={{ ...commonStyles }} />
//             ) : (
//                <ItoAm sx={{ ...commonStyles }} />
//             )}
//          </React.Fragment>
//       ) : props.value.splitValues.AM === 'away' ? (
//          <AwayAm sx={{ ...commonStyles }} />
//       ) : props.value.splitValues.AM === 'wfh' ? (
//          <WfhAm sx={{ ...commonStyles }} />
//       ) : props.value.splitValues.AM === 'offsite' ? (
//          <OffsiteAm sx={{ ...commonStyles }} />
//       ) : (
//          <BlankAm sx={{ ...commonStyles }} />
//       )}
//       {props.value.splitValues.PM === 'ito' ? (
//          <React.Fragment>
//             {console.log(props, 'ITO CHIP')}
//             {props.booking ? (
//                <ItoPmBooked
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-26px',
//                      mr: '-21px',
//                   }}
//                />
//             ) : (
//                <ItoPm
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-26px',
//                      mr: '-21px',
//                   }}
//                />
//             )}
//          </React.Fragment>
//       ) : props.value.splitValues.PM === 'away' ? (
//          <AwayPm
//             sx={{
//                ...commonStyles,
//                position: 'relative',
//                left: '-26px',
//                mr: '-21px',
//             }}
//          />
//       ) : props.value.splitValues.PM === 'wfh' ? (
//          <WfhPm
//             sx={{
//                ...commonStyles,
//                position: 'relative',
//                left: '-26px',
//                mr: '-21px',
//             }}
//          />
//       ) : props.value.splitValues.PM === 'offsite' ? (
//          <OffsitePm
//             sx={{
//                ...commonStyles,
//                position: 'relative',
//                left: '-26px',
//                mr: '-21px',
//             }}
//          />
//       ) : (
//          <BlankPm
//             sx={{
//                ...commonStyles,
//                position: 'relative',
//                left: '-26px',
//                mr: '-21px',
//             }}
//          />
//       )}
//    </Box>
// );

// if (props.splitValues) {
//    switch (props.splitValues) {
//       case 'itoam-pm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                {/* <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAM} />
//                </Icon> */}
//                <ItoAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={DASH} />
//                </Icon>
//             </Box>
//          );
//       case 'itoamwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <ItoAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoamoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <ItoAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoamawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <ItoAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoamitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <ItoAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambooking-pm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBooked} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={DASH} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambookingwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBooked} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambookingawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBooked} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambookingoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBooked} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambookingitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBooked} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case 'itoamitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAM} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case 'itoambookingitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={ITOAMBoth} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case 'wfham-pm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={DASH} />
//                </Icon>
//             </Box>
//          );
//       case 'wfhamoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case 'wfhamitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case 'wfhamitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case 'wfhamawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       case 'wfhamwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <WfhAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteam-pm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={DASH} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteamwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteamitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteamitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteamawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       case 'offsiteamoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <OffsiteAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case 'awayam-pm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={DASH} />
//                </Icon>
//             </Box>
//          );
//       case 'awayamwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case 'awayamitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case 'awayamitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case 'awayamoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case 'awayamawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <AwayAm sx={{ ...commonStyles }} />
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       case '-amwfhpm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-15px',
//                      mr: '-21px',
//                      top: 2,
//                   }}
//                >
//                   <img src={WFHPM} />
//                </Icon>
//             </Box>
//          );
//       case '-amitopm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-15px',
//                      mr: '-21px',
//                      top: 2,
//                   }}
//                >
//                   <img src={ITOPM} />
//                </Icon>
//             </Box>
//          );
//       case '-amitopmbooking':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles }}>
//                   <img src={DASHAM} />
//                </Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-21px',
//                      mr: '-21px',
//                   }}
//                >
//                   <img src={ITOPMBooked} />
//                </Icon>
//             </Box>
//          );
//       case '-amoffsitepm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-15px',
//                      mr: '-21px',
//                      top: 2,
//                   }}
//                >
//                   <img src={OFFSITEPM} />
//                </Icon>
//             </Box>
//          );
//       case '-amawaypm':
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '60px', display: 'flex' }}>
//                <Icon sx={{ width: '30px', height: '35px' }}>-</Icon>
//                <Icon
//                   sx={{
//                      ...commonStyles,
//                      position: 'relative',
//                      left: '-15px',
//                      mr: '-21px',
//                      top: 2,
//                   }}
//                >
//                   <img src={AWAYPM} />
//                </Icon>
//             </Box>
//          );
//       default:
//          return (
//             <Box sx={{ minHeight: '31px', minWidth: '25px', display: 'flex' }}>
//                <Icon sx={{ ...commonStyles, position: 'relative' }}>-</Icon>
//             </Box>
//          );
//    }
// }
// };
export default SplitIcons;
