import React, { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import PublicIcon from '@mui/icons-material/Public';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import IconButton from '@mui/material/IconButton';

import {
   Modal,
   Box,
   FormGroup,
   FormControlLabel,
   Switch,
   TextField,
   Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditGroupModal from './editGroupModal';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';
import AddGroupModal from './addGroupModal';

const GroupList = (props) => {
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [open, setOpen] = useState(false);
   const [loadedGroups, setLoadedGroups] = useState(false);

   const [openModal, setOpenModal] = useState(false);
   const [groupsEnabled, setGroupsEnabled] = useState(false);
   const [editGroup, setEditGroup] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState(null);
   const [newGroup, setNewGroup] = useState(false);

   useEffect(() => {
      if (props.displayFilter.id) {
         const unsubscribe = props.firebase
            .location(props.displayFilter.id)
            .onSnapshot((result) => {
               if (result.exists) {
                  if (result.data().groups) {
                     setGroupsEnabled(result.data().groups);
                  }
               }
            });
         return () => unsubscribe();
      }
   }, [props.displayFilter]);
   useEffect(() => {
      if (open) {
         const unsubscribe = props.firebase
            .locationGroupsIndex(props.displayFilter.id)
            .onSnapshot((result) => {
               if (result.exists) {
                  let grps = [];
                  Object.entries(result.data()).map(([key, value]) => {
                     let data = { gid: key, ...value };
                     delete data.admins;
                     grps.push(data);
                  });
                  grps.sort((a, b) =>
                     a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                  );

                  setLoadedGroups(grps);
               } else {
                  setLoadedGroups(false);
               }
            });
         return () => unsubscribe();
      } else {
         setLoadedGroups(false);
      }
   }, [open, props.displayFilter]);

   const handleClick = () => {
      setOpen(!open);
   };
   const handleListItemClick = (event, index) => {
      if (index === 'all') {
         setSelectedIndex('');
         props.setGroupFilter('');
      } else {
         setSelectedIndex(index);
         props.setGroupFilter(index);
      }
   };
   const handleModalOpen = () => {
      setEditGroup(true);
      setNewGroup(true);
   };

   function handleEdit(event, group, gid) {
      event.stopPropagation();
      setSelectedGroup({ ...group, gid });
      setEditGroup(true);
   }

   const ListGroups = (props) => {
      const theme = useTheme();
      if (props.displayFilter.type === 'company') {
         return <ListItem>Switch to a location to add groups</ListItem>;
      } else if (props.groups === null) {
         return <ListItem>Loading</ListItem>;
      } else {
         return (
            <React.Fragment>
               <Modal
                  open={editGroup}
                  // onClose={handleClose}
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
               >
                  <Box>
                     <EditGroupModal
                        lid={props.displayFilter.id}
                        cid={props.displayFilter.cid}
                        setNewGroup={setNewGroup}
                        newGroup={newGroup}
                        editGroup={editGroup}
                        setEditGroup={setEditGroup}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        setGroupFilter={props.setGroupFilter}
                        groupFilter={props.groupFilter}
                        groupsEnabled={groupsEnabled}
                     />
                  </Box>
               </Modal>

               <ListItem
                  key={'all'}
                  onClick={(event) => handleListItemClick(event, 'all')}
                  sx={{ paddingLeft: 4 }}
               >
                  <ListItemText primary="All" />
               </ListItem>
               {groupsEnabled &&
                  loadedGroups &&
                  loadedGroups.map((groupValue) => (
                     <ListItem
                        // button
                        key={groupValue.gid}
                        // selected={selectedIndex === groupValue.gid}
                        sx={{
                           paddingLeft: 4,
                           backgroundColor:
                              groupValue.gid === selectedIndex
                                 ? theme.palette.tableBody.main
                                 : 'transparent',
                        }}
                        onClick={(event) => handleListItemClick(event, groupValue.gid)}
                        secondaryAction={
                           <IconButton
                              aria-label="edit"
                              onClick={(e) => handleEdit(e, groupValue, groupValue.gid)}
                           >
                              <EditOutlinedIcon />
                           </IconButton>
                        }
                     >
                        <ListItemText
                           sx={{
                              color:
                                 groupValue.gid === selectedIndex
                                    ? theme.palette.primary.main
                                    : theme.palette.text.main,
                              fontWeight: 'bold',
                           }}
                           primary={groupValue.name}
                           secondary={
                              <React.Fragment>
                                 {groupValue.global && (
                                    <PublicIcon
                                       fontSize="small"
                                       sx={{
                                          color:
                                             groupValue.gid === selectedIndex
                                                ? theme.palette.primary.main
                                                : theme.palette.text.main,
                                       }}
                                    />
                                 )}
                                 <PeopleAltIcon
                                    fontSize="small"
                                    sx={{
                                       color:
                                          groupValue.gid === selectedIndex
                                             ? theme.palette.primary.main
                                             : theme.palette.text.main,
                                    }}
                                 />
                                 <Typography
                                    variant="caption"
                                    sx={{
                                       color:
                                          groupValue.gid === selectedIndex
                                             ? theme.palette.primary.main
                                             : theme.palette.text.main,
                                    }}
                                 >
                                    {groupValue.count}
                                 </Typography>
                              </React.Fragment>
                           }
                        />
                     </ListItem>
                  ))}
               {props.displayFilter.type !== 'company' && (
                  <ListItem
                     // button
                     // selected={selectedIndex === 2}
                     onClick={handleModalOpen}
                  >
                     <AddIcon />
                     <ListItemText secondary="Add Group" />
                  </ListItem>
               )}
            </React.Fragment>
         );
      }
   };
   const addGroupButton = (props) => {
      return (
         <ListItem
            // button selected={selectedIndex === 2}
            onClick={handleModalOpen}
         >
            <AddIcon />
            <ListItemText secondary="Add Group" />
         </ListItem>
      );
   };

   return (
      <React.Fragment>
         {/* <AddGroupModal groupsEnabled={groupsEnabled} openModal={openModal} setOpenModal={setOpenModal} displayFilter={props.displayFilter} setAlertMessage={props.setAlertMessage} /> */}
         <ListItem
            // button
            onClick={handleClick}
         >
            <ListItemText primary="Groups" />
            {open ? <ExpandLess /> : <ExpandMore />}
         </ListItem>
         <Divider />
         <Collapse in={open} timeout="auto" unmountOnExit>
            <List
               component="div"
               disablePadding
               sx={{ maxHeight: '63vh', overflow: 'scroll' }}
            >
               <ListGroups
                  groups={props.groups}
                  displayFilter={props.displayFilter}
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  setGroupFilter={props.setGroupFilter}
                  groupFilter={props.groupFilter}
               />

               {/* {props.displayFilter.type !== 'company' &&  addGroupButton(props)}         */}
            </List>
         </Collapse>
      </React.Fragment>
   );
};

export default withFirebase(GroupList);
