import React, { useEffect, useState } from 'react';
import { PageLayout } from '../../layout';
import { Box, Button, Modal } from '@mui/material';
import RegisteredVisitors from './RegisteredVisitors';
import ScheduleMenu from '../../People/menu';
import { useFirebase } from '../../Firebase';
import { useAuth } from '../../../providers';
import DateSelector from '../../layout/DateSelector';
import { DateToday } from '../../../constants/functions';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NewVisitorForm from '../../Interactions/newVisitorForm';
import EditVisitor from './editVisitor';

const Main = () => {
   const firebase = useFirebase();
   const { authUser } = useAuth();
   const today = DateToday();
   const [location, setLocation] = useState(null);
   const [displayFilter, setDisplayFilter] = useState(null);
   const [loading, setLoading] = useState(true);
   const [date, setDate] = useState(today);
   const [collapsed, setCollapsed] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [visitor, setVisitor] = useState(null);

   async function getPrimaryLocation() {
      try {
         const result = await firebase.location(authUser.lid[0]).get();
         if (result.exists) {
            setDisplayFilter({ title: result.data().name, id: result.id });
         }
      } catch (err) {
         console.error('getPrimaryLocation', err);
      }
   }

   async function getDisplayFilter() {
      if (!displayFilter) return;
      try {
         const result = await firebase.location(displayFilter.id).get();
         setLocation({ ...result.data(), lid: result.id, cid: authUser.cid });
         setLoading(false);
      } catch (err) {
         console.error('getDisplayFilter', err);
         setLoading(false);
      }
   }

   function handleClose() {
      setCollapsed(false);
   }

   useEffect(getPrimaryLocation, []);
   useEffect(getDisplayFilter, [displayFilter]);

   if (loading) {
      return null;
   }
   function handleEditModal(data) {
      setEditModal(true);
      setVisitor(data);
   }

   function closeEditModal() {
      setEditModal(false);
      setVisitor(null);
   }

   return (
      <PageLayout>
         <Modal
            open={collapsed}
            onClose={handleClose}
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
            disableEnforceFocus
            aria-labelledby="modal-modal-title"
         >
            <Box>
               <NewVisitorForm
                  cid={authUser.cid}
                  lid={displayFilter.id}
                  date={date.ref}
                  dayVisitors={[]}
                  setCollapsed={setCollapsed}
               />
            </Box>
         </Modal>
         <Modal
            open={editModal}
            onClose={() => closeEditModal}
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
            disableEnforceFocus
            aria-labelledby="modal-modal-titled"
         >
            <Box>
               <EditVisitor
                  date={date}
                  cid={authUser.cid}
                  lid={displayFilter.id}
                  visitor={visitor}
                  handleEditModal={handleEditModal}
                  closeEditModal={closeEditModal}
               />
            </Box>
         </Modal>
         <Box
            sx={{
               w: '100%',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
            }}
         >
            <Box sx={{ w: '33%' }}>
               <ScheduleMenu
                  displayFilter={displayFilter}
                  setDisplayFilter={setDisplayFilter}
               />
            </Box>
            <Box sx={{ w: '33%' }}>
               <DateSelector date={date} setDate={setDate} />
            </Box>
            <Box sx={{ w: '33%' }}>
               <Button fullWidth variant="outlined" onClick={() => setCollapsed(true)}>
                  <PersonAddIcon sx={{ mr: 1 }} />
                  Add Visitor
               </Button>
            </Box>
         </Box>
         <RegisteredVisitors
            displayFilter={displayFilter}
            date={date}
            handleEditModal={handleEditModal}
         />
      </PageLayout>
   );
};

export default Main;
