import React, { useEffect, useState } from 'react';
import {
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   Radio,
   RadioGroup,
   TextField,
   Typography,
} from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { HexColorPicker } from 'react-colorful';
const predefinedColors = [
   { color: '#FFFFFF' },
   { color: '#98C5F1' },
   { color: '#9ECF99' },
   { color: '#F7B843' },
   { color: '#9C2313' },
   { color: '#FFC0CB' },
   { color: '#800080' },
];

const ColorPicker = (props) => {
   console.log(props.backgroundColor, 'PROPS');
   const [selectedColor, setSelectedColor] = useState(predefinedColors[0].color);
   const [isCustomSelected, setIsCustomSelected] = useState(false);
   const [customColor, setCustomColor] = useState('#000000');
   const theme = useTheme();

   useEffect(() => {
      if (props.backgroundColor) {
         //see if the color is in the predefined colors
         const predefinedColor = predefinedColors.find(
            (colorOption) => colorOption.color === props.backgroundColor,
         );
         if (predefinedColor) {
            setSelectedColor(predefinedColor.color);
            setIsCustomSelected(false);
         } else {
            setSelectedColor('custom');
            setIsCustomSelected(true);
            setCustomColor(props.backgroundColor);
         }
      }
   }, [props.backgroundColor]);

   const handleColorChange = (event) => {
      const { value } = event.target;
      if (value === 'custom') {
         setIsCustomSelected(true);
         setSelectedColor('custom');
         props.setFormValues({ ...props.formValues, backgroundColor: customColor });
      } else {
         setIsCustomSelected(false);
         setSelectedColor(value);
         props.setFormValues({ ...props.formValues, backgroundColor: value });
      }
   };

   const handleCustomColorChange = (value) => {
      setCustomColor(value);
      props.setFormValues({ ...props.formValues, backgroundColor: value });
   };

   return (
      <Box>
         <FormControl component="fieldset">
            <Box mb={2}>
               <FormLabel component="legend">Choose a color:</FormLabel>
            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
               <Box
                  sx={{
                     width: 100,
                     height: 50,
                     backgroundColor: isCustomSelected ? customColor : selectedColor,
                     border: '2px solid #ccc',
                  }}
               />
               <RadioGroup
                  value={selectedColor}
                  onChange={handleColorChange}
                  sx={{ flexDirection: 'row', mb: 2 }}
               >
                  {predefinedColors.map((colorOption) => (
                     <FormControlLabel
                        key={colorOption.color}
                        value={colorOption.color}
                        control={
                           <Radio
                              sx={{
                                 color: colorOption.color,
                                 '&.Mui-checked': {
                                    color: colorOption.color,
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    borderRadius: 0,
                                 },
                                 '&.MuiRadio-colorPrimary': {
                                    color: colorOption.color,
                                 },
                                 '&..MuiRadio-colorSecondary': {
                                    color: colorOption.color,
                                 },
                                 '& .MuiSvgIcon-root': {
                                    border: `2px solid #eee`,
                                    backgroundColor: colorOption.color,
                                    fontSize: 28,
                                 },
                              }}
                           />
                        }
                        label=""
                        labelPlacement="bottom"
                     />
                  ))}
                  <FormControlLabel
                     value="custom"
                     control={
                        <Radio
                           sx={{
                              color: customColor,
                              '&.Mui-checked': {
                                 color: customColor,
                                 border: `2px solid ${theme.palette.primary.main}`,
                                 borderRadius: 0,
                              },
                              '&.MuiRadio-colorPrimary': {
                                 color: customColor,
                              },
                              '&..MuiRadio-colorSecondary': {
                                 color: customColor,
                              },
                              '& .MuiSvgIcon-root': {
                                 border: `2px solid #eee`,
                                 color: customColor,
                                 backgroundColor: customColor,
                                 fontSize: 28,
                              },
                           }}
                        />
                     }
                     label={<Typography variant="caption">Custom</Typography>}
                     labelPlacement="bottom"
                  />
               </RadioGroup>
            </Box>
            {isCustomSelected && (
               <Box>
                  <Box display="flex" alignItems="center" mb={2}>
                     <HexColorPicker
                        color={customColor}
                        onChange={handleCustomColorChange}
                     />
                     <TextField
                        label="Custom Color"
                        variant="outlined"
                        value={customColor}
                        onChange={(e) => handleCustomColorChange(e.target)}
                        sx={{ ml: 2 }}
                     />
                  </Box>
               </Box>
            )}
            <Box>
               <Typography variant="caption">
                  Adding a colour can help to identify different parts of your office, or
                  group similar Spaces together.
               </Typography>
            </Box>
         </FormControl>
      </Box>
   );
};

export default ColorPicker;
