import { createTheme } from '@mui/material';

const legacyThemeBase = (themeType) => {
   return createTheme({
      components: {
         MuiTableCell: {
            styleOverrides: {
               root: {
                  borderBottomColor: 'rgba(224, 224, 224, 1)',
               },
            },
         },
         MuiTableFooter: {
            styleOverrides: {
               root: {
                  position: 'sticky',
                  zIndex: 999,
                  bottom: 0,
                  backgroundColor: themeType === 'dark' ? '#424242' : '#fff',
               },
            },
         },
      },
   });
};

export default legacyThemeBase;
