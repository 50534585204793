import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import DrawerForm from './drawerForm';
import VisitorsDrawer from './visitorsDrawer';
import RegisterDrawer from './registerDrawer';
import FeatureSwitch from '../Billing/featureSwitch';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import MeetingDrawer from './meetingDrawer';
import { AuthProvider, CompanyProvider } from '../../providers';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
         width: '85%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   title: {
      marginTop: theme.spacing(4),
   },
   label: {
      marginRight: theme.spacing(1),
   },
   wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
   },
   buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}));

const data = {
   date: new Date(),
   owner: {},
   lead: {},
   externalParticipants: {},
   internalParticipants: {},
   space: {},
   locationName: 'Loading...',
   locationId: '',
   type: '',
   comments: '',
   cid: '',
   baseCapacity: 0,
   forceStatusChange: false,
};

const InteractionDrawer = (props) => {
   const classes = useStyles();
   const [form, setForm] = useState(data);
   const [saving, setSaving] = useState(false);

   useEffect(() => {
      const loadInitialForm = () => {
         if (props.location.actions && props.location.actions[props.type]) {
            setForm({
               ...form,
               type: props.type,
               locationName: props.location.name,
               locationId: props.location.id,
            });
         }
      };
      loadInitialForm();
   }, [props.type, props.location.actions]);

   function handleClose() {
      props.setVisitorsDrawer(false);
      setForm(data);
      setSaving(false);
      props.setType(null);
   }

   return (
      <AuthProvider>
         <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="right"
            open={props.visitorsDrawer}
            onClose={() => handleClose()}
            disableEnforceFocus
         >
            {props.type && (
               <Container maxWidth="md">
                  {props.location.actions && props.location.actions[props.type] ? (
                     <DrawerForm
                        type={props.type}
                        form={form}
                        setForm={setForm}
                        saving={saving}
                        setSaving={setSaving}
                        visitorsDrawer={props.visitorsDrawer}
                        location={props.location}
                        processClose={handleClose}
                     />
                  ) : props.type === 'visitors' && props.location.visitorsEnabled ? (
                     <VisitorsDrawer
                        type={props.type}
                        form={form}
                        setForm={setForm}
                        saving={saving}
                        setSaving={setSaving}
                        visitorsDrawer={props.visitorsDrawer}
                        location={props.location}
                        processClose={handleClose}
                     />
                  ) : props.type === 'meetings' && props.location.spacesEnabled ? (
                     <MeetingDrawer
                        location={props.location}
                        displayFilter={props.displayFilter}
                     />
                  ) : props.type === 'register' ? (
                     <CompanyProvider>
                        <RegisterDrawer
                           type={props.type}
                           visitorsDrawer={props.visitorsDrawer}
                           location={props.location}
                           processClose={handleClose}
                        />
                     </CompanyProvider>
                  ) : (
                     <FeatureSwitch feature={props.type} />
                  )}
               </Container>
            )}
         </Drawer>
      </AuthProvider>
   );
};

export default withFirebase(InteractionDrawer);
