import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../Firebase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { featureNames, convertToDate } from '../../../constants/functions';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(4),
      flexGrow: 1,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      flexDirection: 'column',
      justifyContent: 'center',
   },

   title: {
      backgroundColor: theme.palette.tableHead,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
   },
   typeIcon: {
      marginRight: theme.spacing(2),
   },
}));

const SubscriptionSwitches = (props) => {
   let feats = {
      employeeScheduling: {
         title: 'Basic Attendance Scheduling',
         cost: 0,
         enabled: true,
      },
      groups: {
         title: 'Groups',
         cost: 0.2,
         enabled: false,
      },
      requests: {
         title: 'Invite Colleagues',
         cost: 0.4,
         enabled: false,
      },
      reporting: {
         title: 'Reporting',
         cost: 1,
         enabled: false,
      },
      spaces: {
         title: 'Spaces',
         cost: 1.5,
         enabled: false,
      },
   };

   const classes = useStyles();
   const [expanded, setExpanded] = useState(false);
   const [location, setLocation] = useState(false);
   const [features, setFeatures] = useState(false);
   const [sumTotal, setSumTotal] = useState(0);
   const [currentUsage, setCurrentUsage] = useState(false);
   const [notPremium, setNotPremium] = useState(false);

   useEffect(() => {
      const loadLocation = async () => {
         if (props.company.locations) {
            let num = 0;
            Object.keys(props.company.locations).map((key) => {
               props.firebase
                  .location(key)
                  .get()
                  .then((result) => {
                     if (result.exists) {
                        setLocation(result.data());
                        let loc = result.data();

                        if (loc.groups) {
                           feats.groups.enabled = true;
                           num = feats.groups.cost * loc.people + num;
                        }
                        if (loc.actions && loc.actions.requests) {
                           feats.requests.enabled = true;
                           num = feats.requests.cost * loc.people + num;
                        }
                        if (loc.reporting) {
                           feats.reporting.enabled = true;
                           num = feats.reporting.cost * loc.people + num;
                        }
                        if (loc.spacesEnabled) {
                           feats.spaces.enabled = true;
                           num = feats.spaces.cost * loc.people + num;
                        }
                        setFeatures(feats);
                        setSumTotal(num);
                     }
                  });
            });
         }
      };
      loadLocation();
   }, []);

   useEffect(() => {
      const loadCurrentBill = async () => {
         if (props.company && props.company.subscription.plan === 'premium') {
            let result = await props.firebase.companyCurrentBill(props.company.cid).get();
            if (result.exists) {
               setCurrentUsage(result.data());
            } else {
               setNotPremium(true);
            }
            return;
         } else if (props.company && props.company.subscription.plan !== 'premium') {
            setNotPremium(true);
         }
      };
      loadCurrentBill();
   }, [props.company]);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   return (
      <Grid container>
         <Grid item xs={12} className={classes.title}>
            <Typography component="h3" variant="h5">
               Billing
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <React.Fragment>
               <Accordion
                  expanded={expanded === 'current'}
                  onChange={handleChange('current')}
               >
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="current-month-header"
                     id="current-month-header"
                  >
                     <Typography component="h4" variant="h5">
                        Current Month
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {currentUsage ? (
                        <React.Fragment>
                           <Grid container spacing={2}>
                              <Grid item xs={12}>
                                 <Table size="small">
                                    <TableHead>
                                       <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Enabled features</TableCell>
                                          <TableCell>No. of people</TableCell>
                                          <TableCell>Cost</TableCell>
                                       </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {Object.entries(currentUsage).map(
                                          ([key, value]) => {
                                             if (key !== 'monthTotal') {
                                                return (
                                                   <TableRow key={key}>
                                                      <TableCell>
                                                         {convertToDate(key)}
                                                      </TableCell>
                                                      <TableCell>
                                                         {Object.entries(
                                                            featureNames,
                                                         ).map(([featKey, featValue]) => {
                                                            if (value[featKey]) {
                                                               return (
                                                                  <Typography variant="caption">
                                                                     {' '}
                                                                     {
                                                                        featValue.name
                                                                     },{' '}
                                                                  </Typography>
                                                               );
                                                            }
                                                         })}
                                                      </TableCell>
                                                      <TableCell>
                                                         {value.people}
                                                      </TableCell>
                                                      <TableCell>
                                                         £{value.dayTotal.toFixed(2)}
                                                      </TableCell>
                                                   </TableRow>
                                                );
                                             }
                                          },
                                       )}
                                       <TableRow>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell>Current Total</TableCell>
                                          <TableCell>
                                             £{currentUsage.monthTotal.toFixed(2)} + VAT
                                          </TableCell>
                                       </TableRow>
                                    </TableBody>
                                 </Table>
                              </Grid>
                              <Grid item xs={12}>
                                 <Typography variant="caption">
                                    Note: Daily costs are worked out as: No of people x
                                    (monthly cost of enabled features / days in the month){' '}
                                 </Typography>
                              </Grid>
                           </Grid>
                        </React.Fragment>
                     ) : (
                        <Grid container item xs={12}>
                           {notPremium ? (
                              <Typography variant="body1">
                                 You are not on a Premium subscription, or are still
                                 within a free trial period and do not have any
                                 outstanding chargable usage.
                              </Typography>
                           ) : (
                              <Typography variant="body1">Loading...</Typography>
                           )}
                        </Grid>
                     )}
                  </AccordionDetails>
               </Accordion>
            </React.Fragment>
         </Grid>
      </Grid>
   );
};

export default withFirebase(SubscriptionSwitches);
