import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import { Typography, Box, Button, IconButton, useMediaQuery } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Image from 'material-ui-image';
import RoomIcon from '@mui/icons-material/Room';
import AvatarGroup from './avatarGroup';
import { spaceTypeImage } from '../../../Spaces/functions';
import DisplaySpaces from './displaySpaces';

const ParentSpace = (props) => {
   const space = props.space;
   // const availableSeats = props.availableSeats;
   // const bookings = props.bookings;
   const [expanded, setExpanded] = useState(false);
   const [isEffectRun, setIsEffectRun] = useState(false);

   const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
   const style = {
      minWidth: '30px',
      maxWidth: '30px',
      maxHeight: '30px',
      mr: 1,
   };
   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   async function showMap(sid, lid) {
      props.setSpaceMap({ sid, lid });
      props.setMap(true);
   }

   useEffect(() => {
      if (isEffectRun) {
         return;
      }
      if (space) {
         if (space.bookings && space.bookings.length > 0) {
            setExpanded('panel1');
         }
         setIsEffectRun(true);
      }
   }, [space, isEffectRun]);
   const Accordion = styled((props) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
   ))(({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      borderLeft: space.backgroundColor ? `4px solid` : `1px solid`,
      borderLeftColor: space.backgroundColor
         ? space.backgroundColor
         : theme.palette.divider,
      '&:before': {
         display: 'none',
      },
   }));

   const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
         expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
         {...props}
      />
   ))(({ theme }) => ({
      backgroundColor: theme.palette.tableBody.main,

      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
         transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
         marginLeft: theme.spacing(1),
         marginTop: theme.spacing(0),
         marginBottom: theme.spacing(0),
         minHeight: '40px',
      },
   }));

   const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      backgroundColor: theme.palette.tableHead.main,
      marginBottom: theme.spacing(4),
   }));

   return (
      <div>
         <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
               <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <Box
                     sx={{
                        width: '33%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                     }}
                  >
                     <Box sx={style}>
                        <Image src={spaceTypeImage(space.type)} />
                     </Box>
                     <Box>
                        <Typography variant="body1" sx={{ fontSize: '14px' }}>
                           {space.name}
                        </Typography>
                     </Box>
                  </Box>
                  <Box sx={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
                     {space.bookings && space.bookings.length !== 0 && (
                        <AvatarGroup items={space.bookings} />
                     )}
                  </Box>
                  <Box sx={{ width: '33%', display: 'flex', justifyContent: 'flex-end' }}>
                     {space.mapPath && (
                        <React.Fragment>
                           {isSmDown ? (
                              <IconButton
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    showMap(space.sid, space.lid);
                                 }}
                                 size="small"
                                 color="primary"
                              >
                                 <RoomIcon fontSize="small" />
                              </IconButton>
                           ) : (
                              <Button
                                 startIcon={<RoomIcon />}
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    showMap(space.sid, space.lid);
                                 }}
                                 size="small"
                              >
                                 Map
                              </Button>
                           )}
                        </React.Fragment>
                     )}
                  </Box>
               </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, ml: 5 }}>
               {space.hasChildren && (
                  <DisplaySpaces
                     compressed={props.compressed}
                     spaces={space.children}
                     setSpaceMap={props.setSpaceMap}
                     setMap={props.setMap}
                     userData={props.userData}
                     day={props.day}
                  />
               )}
            </AccordionDetails>
         </Accordion>
      </div>
   );
};
export default ParentSpace;
