import { createContext, useContext, useEffect, useState } from 'react';
import { useFirebase } from '../components/Firebase';
import { useAuth } from './AuthProvider';
import LocationsProvider from './LocationsProvider';
import useFirebaseSnapshot from '../hooks/useFirebaseSnapshot';
import { featureSet } from '../constants/features';

const contextName = 'Company';
const CompanyContext = createContext(undefined);

const CompanyProvider = ({ children }) => {
   const { authUser } = useAuth();
   const firebase = useFirebase();

   const {
      loading,
      data: company,
      error,
   } = useFirebaseSnapshot(firebase.company, {
      args: [authUser.cid],
      sortFunction: (response) => {
         if (response.features) {
            return {
               ...response,
               features: featureSet.reduce(
                  (acc, feature) => ({
                     ...acc,
                     [feature]: response.features[feature] || false,
                  }),
                  {},
               ),
               cid: authUser.cid,
            };
         }

         return {
            ...response,
            features: featureSet.reduce(
               (acc, feature) => ({
                  ...acc,
                  [feature]: false,
               }),
               {},
            ),
            cid: authUser.cid,
         };
      },
   });

   useEffect(() => {}, [authUser]);

   if (!authUser || !company) {
      return null;
   }

   return (
      <CompanyContext.Provider
         value={{
            company,
            companyError: error,
            companyLoading: loading,
         }}
      >
         <LocationsProvider data={company}>{children}</LocationsProvider>
      </CompanyContext.Provider>
   );
};

export const useCompany = () => {
   const context = useContext(CompanyContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default CompanyProvider;
