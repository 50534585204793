import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { withFirebase } from '../Firebase';
import Routine from '../Profile/routine';

const RoutineModal = (props) => {
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
   };
   return (
      <React.Fragment>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
         >
            <Box sx={style}>
               <Typography variant="h6" component="h2">
                  Routine
               </Typography>
               <Routine
                  user={props.userData}
                  location={props.userData.lid[0]}
                  clickable={false}
                  profile={true}
               />
            </Box>
         </Modal>
         <Grid container>
            <Grid item xs={6}>
               <Button onClick={handleOpen}>View Routine</Button>
            </Grid>
            {props.saveError && (
               <Typography color="secondary" variant="caption">
                  You must view and remove their routine before you can change their
                  location.
               </Typography>
            )}
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(RoutineModal);
