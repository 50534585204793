import React from 'react';
import { Tooltip } from '@mui/material';
import ToolTipContent from '../toolTipContent';
import Wfh from './wfh';

const WfhWrapper = (props) => {
   if (props.dayStatus && props.dayStatus.times) {
      return (
         <Tooltip
            title={<ToolTipContent dayStatus={props.dayStatus} />}
            placement="top"
            arrow
         >
            <div>
               <Wfh
                  clickable={props.clickable}
                  styles={props.styles}
                  miniChips={props.miniChips}
                  data={props.data}
                  setSpaceData={props.setSpaceData}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
               />
            </div>
         </Tooltip>
      );
   } else {
      return (
         <Wfh
            clickable={props.clickable}
            styles={props.styles}
            miniChips={props.miniChips}
            data={props.data}
            setSpaceData={props.setSpaceData}
            setStatusDrawerOpen={props.setStatusDrawerOpen}
         />
      );
   }
};
export default WfhWrapper;
