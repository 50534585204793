import React from 'react';
import { PageLayout, TabSet, TabSetContent, useTabSet } from '../layout';
import { Paper } from '@mui/material';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import Subscription from './subscription';
import Locations from './locations';
import Company from './company/';
import Users from './users';
import { useCompany, useLocations } from '../../providers';

const tabNames = ['Company', 'Locations', 'Users', 'Subscription'];

const SettingsPage = (props) => {
   const { company } = useCompany();
   const { allLocations, archivedLocations, getObjectAsArray } = useLocations();
   const [tabValue, setTabValue, handleTabChange] = useTabSet();

   const locations = getObjectAsArray(allLocations);
   const archivedLocationsArray = getObjectAsArray(archivedLocations);

   return (
      <PageLayout>
         <Paper elevation={0}>
            <TabSet value={tabValue} onChange={handleTabChange} tabNames={tabNames} />

            {company && (
               <TabSetContent
                  tabValue={tabValue}
                  tabNames={tabNames}
                  contentArray={[
                     <Company
                        company={company}
                        locations={locations}
                        setValue={setTabValue}
                     />,
                     <Locations
                        company={company}
                        locations={locations}
                        archivedLocations={archivedLocationsArray}
                     />,
                     <Users tab={tabValue} />,
                     <Subscription tab={tabValue} />,
                  ]}
               />
            )}
         </Paper>
      </PageLayout>
   );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
   withFirebase,
)(SettingsPage);
