import React, { createContext, useContext } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

const contextName = 'Notifications';
const NotificationsContext = createContext(undefined);

const NotificationProvider = ({ children }) => {
   const { enqueueSnackbar } = useSnackbar();

   const handleClickVariant = (variant) => () => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar('This is a success message!', { variant });
   };

   return (
      <NotificationsContext.Provider
         value={{
            handleClickVariant,
         }}
      >
         <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
      </NotificationsContext.Provider>
   );
};

export const useNotification = () => {
   const context = useContext(NotificationsContext);
   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }
   return context;
};

export default NotificationProvider;
