import React from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

const ContactNumber = ({ form, setForm, setStatus, clearForm }) => {
   const [contactNumber, setContactNumber] = React.useState('');

   async function saveContactNumber() {
      setForm({ ...form, contactNumber });
      setStatus('step2');
   }

   return (
      <Box>
         <Typography variant="h5">Contact Number</Typography>
         <Typography variant="body1">
            Please add your contact number so your host can reach you if needed.
         </Typography>
         <TextField
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            label="Contact Number"
            variant="outlined"
            margin="normal"
            fullWidth
         />
         <Box>
            <Button
               onClick={() => saveContactNumber()}
               variant="contained"
               fullWidth
               sx={{ mb: 2 }}
               color="primary"
            >
               {contactNumber === '' ? 'Skip' : 'Next'}
            </Button>
            <Button
               onClick={() => clearForm()}
               variant="outlined"
               fullWidth
               color="primary"
            >
               Start again
            </Button>
         </Box>
      </Box>
   );
};

export default ContactNumber;
