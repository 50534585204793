import React from 'react';
import { compose } from 'recompose';
import CompanyProvider from '../../providers/CompanyProvider';
import Main from './main';

import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import AuthProvider from '../../providers/AuthProvider';

const ProfilePage = () => (
   <AuthUserContext.Consumer>
      {(authUser) => (
         <AuthProvider>
            <CompanyProvider>
               <Main />
            </CompanyProvider>
         </AuthProvider>
      )}
   </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition))(ProfilePage);
