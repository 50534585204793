import firebase from 'firebase/app';
import 'firebase/firestore';

export const migrateLids = async () => {
   const logs = await firebase.firestore().doc(`usageLogs/users`).get();
   //  console.log('migrating LIDs');
   // let companyId = '2fp8D8shclw1dEUAxYjt';
   //MAG
   // let companyId = '8bbrKdctEOwzNRq8JrpF';
   //station 10
   let companyId = 'LWLn7IIWKVPiw99ZF8Et';
   const companiesSnapshot = await firebase
      .firestore()
      .collection('company')
      // .where(firebase.firestore.FieldPath.documentId(), '==', companyId)
      .get();
   const companies = await Promise.all(
      companiesSnapshot.docs.map(async (doc) => {
         const cid = doc.id;
         const companyName = doc.data().companyName;
         const companyPeopleNo = doc.data().totalPeople;
         const locationsSnapshot = await firebase
            .firestore()
            .collection('location')
            .where('cid', '==', cid)
            .get();
         //  console.log(locationsSnapshot, 'locationsSnapshot');
         let locPpl = {};

         await Promise.all(
            locationsSnapshot.docs.map(async (locDoc) => {
               const lid = locDoc.id;
               const peopleSnapshot = await firebase
                  .firestore()
                  .doc(`location/${lid}/indexes/people`)
                  .get()
                  .then((result) => {
                     if (result.exists) {
                        Object.entries(result.data()).map(([key, value]) => {
                           locPpl[key] = { lid, ...value };
                        });
                     }
                  });
            }),
         );
         const coPpl = await firebase
            .firestore()
            .doc(`company/${cid}/indexes/people`)
            .get()
            .then((result) => {
               if (result.exists) {
                  return result.data();
               }
            });
         let toSave = { ...coPpl };
         Object.entries(locPpl).map(([key, value]) => {
            if (coPpl[key]) {
               toSave[key].lid = value.lid;
            } else {
               console.log('missing', key, value);
            }
         });
         console.log(toSave, 'TO SAVE');
         // try {
         //    await firebase
         //       .firestore()
         //       .doc(`company/${cid}/indexes/people`)
         //       .update(toSave);
         // } catch (error) {
         //    console.log(error, cid, companyName);
         // }
         if (Object.keys(coPpl).length !== Object.keys(locPpl).length) {
            console.log(cid, companyName, {
               coPpl: Object.keys(coPpl).length,
               locPpl: Object.keys(locPpl).length,
               total: companyPeopleNo,
            });
            let missing = {};
            Object.entries(coPpl).map(async ([key, value]) => {
               if (!locPpl[key]) {
                  const user = await firebase
                     .firestore()
                     .doc(`users/${key}`)
                     .get()
                     .then((users) => {
                        if (users.exists) {
                           return users.data();
                        }
                     });
                  missing[key] = { lastUsed: null, ...value, ...user };
                  if (logs.data()[key]) {
                     missing[key].lastUsed = logs.data()[key].toDate();
                  }
               }
            });

            console.log(missing, Object.keys(missing).length);
         }
      }),
   );
};
