import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const AwayAmpmBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="status/AwayAMpmBooked" fillRule="nonzero">
               <path
                  d="M15.5,31 C20.071838,31 19.3384387,31 19.3384387,31 C19.3384387,31 19.4938713,31 19.4938713,31 C19.4938713,31 41,0 41,0 C41,0 19.7413077,0 15.5,0 C6.93958638,0 0,6.93958638 0,15.5 C0,24.0604136 6.48554758,31 15.5,31 Z"
                  id="Oval"
                  fill={theme.palette.tableBody.main}
               ></path>
               <path
                  d="M15,5 C20.52,5 25,9.48 25,15 C25,20.52 20.52,25 15,25 C9.48,25 5,20.52 5,15 C5,9.48 9.48,5 15,5 Z M20,14 L10,14 L10,16 L20,16 L20,14 Z"
                  id="Path-2"
                  fill={theme.palette.secondary.main}
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default AwayAmpmBooked;
