import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailExists from './emailExists';
import DomainExists from './domainExists';
import NewCompany from './newCompany';
import * as ROUTES from '../../constants/routes';
import { useMsal } from '@azure/msal-react';
import { PageLayout } from '../layout';
import * as msal from '@azure/msal-browser';

const msalConfig = {
   auth: {
      clientId: '0a89f7d8-b648-4a31-b009-df4f252fb650',
      redirectUri: 'https://msteams.intheoffice.io/blank.html',
      //redirectUri: 'https://app.intheoffice.io/blank.html',
   },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '70vh',
      maxWidth: '100%',
      marginTop: theme.spacing(5),
   },
}));

const Auth = (props) => {
   const classes = useStyles();
   const { instance } = useMsal();
   const [userState, setUserState] = useState('none');
   const [mSCreds, setMSCreds] = useState(false);
   const [cid, setCid] = useState({});
   const [emailDomain, setEmailDomain] = useState('');

   var msTeams = false;
   if (
      window.location.hostname.includes('ngrok.io') ||
      window.location.hostname.includes('msteams')
   ) {
      msTeams = true;
   }

   async function checkDomain(email) {
      var domain = email.split('@');
      setEmailDomain(domain[1].toLowerCase());
      var company = await props.firebase.checkValidEmail(domain[1].toLowerCase()).get();
      if (!company.empty) {
         let cid = {};
         company.forEach((doc) => {
            cid = { ...doc.data() };
         });
         return cid;
      } else {
         return false;
      }
   }

   useEffect(() => {
      async function initApp() {
         var result = await props.firebase.getMicrosoftCreds();
         setMSCreds(result);
         if (!result.credential) {
            await props.firebase.doSignInWithMicrosoft();
         } else if (result.code === 'auth/account-exists-with-different-credential') {
            setUserState('emailExists');
         } else if (result.additionalUserInfo.isNewUser) {
            var domainResult = await checkDomain(result.user.email);
            setCid(domainResult);
            if (!domainResult) {
               var deleteUser = firebase.functions().httpsCallable('deleteUser');
               console.log(result);
               await deleteUser(result.user.uid).then(() => {
                  setUserState('new');
               });
            } else {
               setUserState('domainExists');
            }
         } else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const page = urlParams.get('redirect');
            if (page === 'settings') {
               props.history.push(ROUTES.SETTINGSUSERS);
            } else if (page === 'scanin') {
               props.history.push(
                  ROUTES.SCAN_IN +
                     '?loc=' +
                     urlParams.get('loc') +
                     '&locName=' +
                     urlParams.get('locName') +
                     '&loggedIn=true',
               );
            } else {
               if (msTeams) {
                  props.history.push(ROUTES.MSTEAMSAUTH);
               } else {
                  props.history.push(ROUTES.DASHBOARD);
               }
            }
         }
         console.log(userState);
      }
      initApp();
   }, []);

   return (
      <PageLayout>
         <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            className={classes.root}
         >
            {userState === 'none' && (
               <Grid item xs={12} align="center">
                  <Typography variant="h5" component="h2">
                     Loading
                  </Typography>
                  <CircularProgress />
               </Grid>
            )}
            {userState === 'loggedIn' && (
               <Grid item xs={12} align="center">
                  <Button
                     onClick={() => {
                        props.firebase.doSignOut();
                        props.history.push(ROUTES.SIGN_IN);
                     }}
                  >
                     Logout
                  </Button>
               </Grid>
            )}
            {userState === 'emailExists' && (
               <Grid item xs={12} align="center">
                  <EmailExists
                     mSCreds={mSCreds}
                     userState={userState}
                     setUserState={setUserState}
                     msTeams={msTeams}
                  />
               </Grid>
            )}
            {userState === 'domainExists' && (
               <DomainExists
                  emailDomain={emailDomain}
                  mSCreds={mSCreds}
                  userState={userState}
                  cid={cid}
                  msTeams={msTeams}
               />
            )}
            {userState === 'new' && (
               <Grid item xs={12}>
                  <NewCompany
                     emailDomain={emailDomain}
                     mSCreds={mSCreds}
                     userState={userState}
                     msTeams={msTeams}
                  />
               </Grid>
            )}
         </Grid>
      </PageLayout>
   );
};

export default withFirebase(Auth);
