import React from 'react';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   paper: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(8),
   },
}));

const Loading = () => {
   const classes = useStyles();
   return (
      <React.Fragment>
         <div className={classes.paper}>
            <Container>
               <CircularProgress />
            </Container>
         </div>
      </React.Fragment>
   );
};

export default Loading;
