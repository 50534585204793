export function objectToArrayWithKeyIds(obj, keyname = 'id', otherProps = {}) {
   return Object.entries(obj).reduce(
      (acc, [key, value]) => [
         ...acc,
         {
            [keyname]: key,
            ...value,
            ...otherProps,
         },
      ],
      [],
   );
}
