import { createContext, useContext } from 'react';
import {
   Dialog as MuiDialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Box,
   IconButton,
   useTheme,
} from '@mui/material';
import Loading from '../Loading';
import CloseIcon from '@mui/icons-material/Close';

const contextName = 'Dialog';
const DialogContext = createContext();

const Dialog = ({
   open = false,
   onClose = () => console.info('close dialog'),
   closeIcon = true,
   title = null,
   disableBackdropClick = false,
   contextData = {},
   loading = false,
   children,
   actions = null,
   sx,
   ...rest
}) => {
   const theme = useTheme();

   function handleClose(e, reason) {
      /*
          this is to understand whether the click was genuine "Close" or 
          whether the user accidentally clicked the background.
          https://mui.com/material-ui/api/dialog/
        */
      console.info(e.currentTarget, reason);
      if (reason === 'backdropClick' && disableBackdropClick) {
         return;
      }
      onClose();
   }

   const TitleComponent = () => {
      if (typeof title === 'string') {
         return (
            <>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}
               >
                  <DialogTitle>{title}</DialogTitle>
                  {closeIcon && (
                     <Box mr={3}>
                        <IconButton size="small" onClick={handleClose}>
                           <CloseIcon />
                        </IconButton>
                     </Box>
                  )}
               </Box>
               {/* <Divider /> */}
            </>
         );
      }
      return title;
   };

   return (
      <DialogContext.Provider value={{ ...contextData, open, handleClose }}>
         <MuiDialog
            open={open}
            onClose={handleClose}
            {...rest}
            sx={{
               '& .MuiDialogContent-root': {
                  minHeight: 200,
                  p: [2, 3],
               },
               '& .MuiDialog-container > .MuiPaper-root': {
                  height: '100%',
               },
               '& .MuiPaper-root': {
                  maxWidth: (theme) =>
                     rest.maxWidth
                        ? theme.breakpoints.values[`${rest.maxWidth}`]
                        : 'none',
               },
               ...sx,
            }}
         >
            {title && <TitleComponent />}
            <DialogContent sx={{ overflowY: 'auto', py: 2 }} dividers>
               <Box>
                  {loading ? (
                     <Loading
                        sx={{
                           flexDirection: 'column',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                        }}
                     />
                  ) : (
                     children
                  )}
               </Box>
            </DialogContent>
            {actions && (
               <DialogActions sx={{ mx: [1, 2], my: [1, 1] }}>{actions}</DialogActions>
            )}
         </MuiDialog>
      </DialogContext.Provider>
   );
};

export const useDialogContext = () => {
   const context = useContext(DialogContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default Dialog;
