import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withFirebase } from '../Firebase';
import {
   DateToday,
   DateTodayDBReady,
   getWeekDatesArray,
   FullWeekDateRange,
   splitDate,
} from '../../constants/functions';
import DateRangePicker from './dateRangePicker';
import OverallNumbers from './overallNumbers';
import FavouriteDays from './favouriteDays';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

class Constants {
   static get DAYS() {
      return {
         0: {
            name: 'Monday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         1: {
            name: 'Tuesday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         2: {
            name: 'Wednesday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         3: {
            name: 'Thursday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         4: {
            name: 'Friday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         5: {
            name: 'Saturday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         6: {
            name: 'Sunday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
      };
   }
}

const RepPeople = (props) => {
   let startTod = DateTodayDBReady(-(4 * 7));
   let startWeek = getWeekDatesArray(startTod);
   let start = {
      year: startWeek[0].slice(0, 4),
      month: startWeek[0].slice(4, 6),
      day: startWeek[0].slice(6, 8),
   };
   let tod = DateTodayDBReady(-7);
   let week = getWeekDatesArray(tod);
   let end = {
      year: week[6].slice(0, 4),
      month: week[6].slice(4, 6),
      day: week[6].slice(6, 8),
   };
   let dayRange = FullWeekDateRange(start, end, true);

   const [dates, setDates] = useState(dayRange);
   const [totalDays, setTotalDays] = useState(dayRange.length);
   const [locPeople, setLocPeople] = useState(false);
   const [loadedData, setLoadedData] = useState(false);
   const [favDays, setFavDays] = useState(false);
   const [range, setRange] = React.useState(4);
   const [hideWeekends, setHideWeekends] = useState(true);
   const [fullDisplay, setFullDisplay] = useState('');
   const [customDates, setCustomDates] = useState(false);
   const [errorMessage, setErrorMessage] = useState(false);

   const handleChange = (event) => {
      setRange(event.target.value);
      if (!event.target.value) {
         setCustomDates(true);
      } else {
         setCustomDates(false);
         let newTod = DateTodayDBReady(-(event.target.value * 7));
         let newWeek = getWeekDatesArray(newTod);
         let newStart = {
            year: newWeek[0].slice(0, 4),
            month: newWeek[0].slice(4, 6),
            day: newWeek[0].slice(6, 8),
         };
         let newDayRange = FullWeekDateRange(newStart, end, hideWeekends);
         setDates(newDayRange);
         // if (hideWeekends) {
         //    setTotalDays(newDayRange.length - event.target.value * 2);
         // } else {
         setTotalDays(newDayRange.length);
      }

      // }
   };
   const handleSwitch = (event) => {
      setHideWeekends(event.target.checked);
      // if (event.target.checked) {
      //    setTotalDays(dates.length - range * 2);
      // } else {

      let endDate = Number(dates[dates.length - 1]);
      if (!event.target.checked) {
         endDate = endDate + 2;
      }
      let newDayRange = FullWeekDateRange(
         splitDate(dates[0]),
         splitDate(endDate.toString()),
         event.target.checked,
      );
      setDates(newDayRange);
      setTotalDays(newDayRange.length);
      // }
   };

   useEffect(() => {
      const loadLocationPeople = async () => {
         if (props.tab === 0 && props.location) {
            let allPeople = await props.firebase
               .locationPeople(props.location.lid, props.location.cid)
               .orderBy('name.firstName')
               .get()
               .then((result) => {
                  let peopleData = {};
                  result.forEach((person) => {
                     peopleData[person.id] = {
                        ...person.data(),
                        uid: person.id,
                        name:
                           person.data().name.firstName +
                           ' ' +
                           person.data().name.lastName,
                        ito: 0,
                        wfh: 0,
                        offsite: 0,
                        away: 0,
                     };
                  });
                  return peopleData;
               });
            setLocPeople(allPeople);
         }
      };
      loadLocationPeople();
   }, [props.value, props.location]);

   useEffect(() => {
      const loadData = async () => {
         if (locPeople) {
            let people = {};
            let archivedPeople = {};
            let busyDays = Constants.DAYS;
            Object.entries(locPeople).map(([key, value]) => {
               people[key] = {
                  name: value.name,
                  ito: 0,
                  wfh: 0,
                  offsite: 0,
                  away: 0,
               };
            });
            if (dates.length !== 0) {
               setErrorMessage(false);
               if (dates.length < 8) {
                  await props.firebase
                     .locationScheduleDayStreak(props.location.lid, dates)
                     .get()
                     .then((results) => {
                        if (!results.empty) {
                           results.forEach((result) => {
                              Object.entries(people).map(([key, value]) => {
                                 if (result.data()[key]) {
                                    if (result.data()[key].status === 'split') {
                                       people[key][result.data()[key].splitDay.AM] =
                                          people[key][result.data()[key].splitDay.AM] +
                                          0.5;
                                       people[key][result.data()[key].splitDay.PM] =
                                          people[key][result.data()[key].splitDay.PM] +
                                          0.5;
                                    } else {
                                       people[key][result.data()[key].status] =
                                          people[key][result.data()[key].status] + 1;
                                    }
                                 }
                              });
                              var i = 0;
                              dates.map((date) => {
                                 if (date === result.id) {
                                    Object.values(result.data()).map((person) => {
                                       if (person.status === 'split') {
                                          busyDays[i][person.splitDay.AM] =
                                             busyDays[i][person.splitDay.AM] + 0.5;
                                          busyDays[i][person.splitDay.PM] =
                                             busyDays[i][person.splitDay.PM] + 0.5;
                                       } else {
                                          busyDays[i][person.status] =
                                             busyDays[i][person.status] + 1;
                                       }
                                    });
                                 }
                                 i++;
                              });
                           });
                        }
                        let data = [];
                        Object.values(people).map((person) => {
                           data.push(person);
                        });
                        setLoadedData(data);
                        let dayData = [];
                        Object.values(busyDays).map((day) => {
                           dayData.push(day);
                        });
                        setFavDays(dayData);
                     });
               } else {
                  let data = [];
                  people = await loadWeeksSchedule(people, dates, busyDays);
                  Object.values(people).map((person) => {
                     data.push(person);
                  });
                  setLoadedData(data);
               }
            } else {
               setErrorMessage(true);
            }
         }
      };
      loadData();
   }, [locPeople, props.location, dates]);

   async function loadWeeksSchedule(people, dates, busyDays) {
      let count = dates.length;
      let start = 0;
      let end = 7;
      let interval = 7;
      if (hideWeekends) {
         end = 5;
         interval = 5;
      }
      let run = true;
      let dayData = [];
      while (run) {
         let amendedDates = dates.slice(start, end);
         await props.firebase
            .locationScheduleDayStreak(props.location.lid, amendedDates)
            .get()
            .then((results) => {
               if (!results.empty) {
                  results.forEach((result) => {
                     Object.entries(people).map(([key, value]) => {
                        if (result.data()[key]) {
                           if (result.data()[key].status === 'split') {
                              people[key][result.data()[key].splitDay.AM] =
                                 people[key][result.data()[key].splitDay.AM] + 0.5;
                              people[key][result.data()[key].splitDay.PM] =
                                 people[key][result.data()[key].splitDay.PM] + 0.5;
                           } else {
                              people[key][result.data()[key].status] =
                                 people[key][result.data()[key].status] + 1;
                           }
                        }
                     });
                     var i = 0;
                     amendedDates.map((eachDate) => {
                        if (eachDate === result.id) {
                           Object.values(result.data()).map((person) => {
                              if (person.status === 'split') {
                                 busyDays[i][person.splitDay.AM] =
                                    busyDays[i][person.splitDay.AM] + 0.5;
                                 busyDays[i][person.splitDay.PM] =
                                    busyDays[i][person.splitDay.PM] + 0.5;
                              } else {
                                 busyDays[i][person.status] =
                                    busyDays[i][person.status] + 1;
                              }
                           });
                        }
                        i++;
                     });
                  });
               }
            });

         start = end;
         end = end + interval;
         if (end > count) {
            end = count;
         }
         if (start === count) {
            run = false;
         }
      }
      if (!run) {
         Object.values(busyDays).map((day) => {
            dayData.push(day);
         });
         setFavDays(dayData);
         return people;
      }
   }

   return (
      <React.Fragment>
         {!loadedData && <Typography variant="body1">Loading...</Typography>}
         <Grid container spacing={1}>
            <Grid container item xs={12}>
               <Grid item xs={8} md={6}>
                  {customDates ? (
                     <DateRangePicker
                        dates={dates}
                        setDates={setDates}
                        setTotalDays={setTotalDays}
                        hideWeekends={hideWeekends}
                     />
                  ) : (
                     <React.Fragment>
                        <FormControl fullWidth>
                           <InputLabel id="date_range_selection">Date Range</InputLabel>
                           <Select
                              labelId="date_range_selection"
                              id="demo-simple-select"
                              value={range}
                              defaultValue={4}
                              label="Past date range"
                              onChange={handleChange}
                           >
                              <MenuItem value={1}>Last week</MenuItem>
                              <MenuItem value={2}>Past 2 weeks</MenuItem>
                              <MenuItem value={3}>Past 3 weeks</MenuItem>
                              <MenuItem value={4}>Past 4 weeks</MenuItem>
                              <MenuItem value={5}>Past 5 weeks</MenuItem>
                              <MenuItem value={6}>Past 6 weeks</MenuItem>
                              {/* <MenuItem value={7}>Past 7 weeks</MenuItem>
                              <MenuItem value={8}>Past 2 months</MenuItem> */}
                              {/* <MenuItem value={12}>Past 3 months</MenuItem> */}
                              <MenuItem value={false}>Custom Dates</MenuItem>
                           </Select>
                        </FormControl>
                     </React.Fragment>
                  )}
                  {errorMessage && (
                     <Typography variant="caption" color="error">
                        There are no valid dates to display
                     </Typography>
                  )}
               </Grid>

               <Grid item xs={4} md={2} lg={1}>
                  <Typography sx={{ m: '16px 0 0 16px' }} variant="body1">
                     ({totalDays} days)
                  </Typography>
               </Grid>
               <Grid item xs={12} md={4}>
                  <FormGroup>
                     <FormControlLabel
                        control={
                           <Switch
                              checked={hideWeekends}
                              onChange={handleSwitch}
                              color="primary"
                              inputProps={{ 'aria-label': 'hide weekends' }}
                           />
                        }
                        label="Hide weekends"
                        sx={{ mt: 1, ml: 1 }}
                     />
                  </FormGroup>
               </Grid>
            </Grid>
            {loadedData && fullDisplay !== 'favourite' && (
               <Grid item xs={12} sm={fullDisplay === 'overall' ? 12 : 6}>
                  <OverallNumbers
                     location={props.location}
                     max={totalDays}
                     locPeople={locPeople}
                     loadedData={loadedData}
                     fullDisplay={fullDisplay}
                     setFullDisplay={setFullDisplay}
                  />
               </Grid>
            )}
            {favDays && fullDisplay !== 'overall' && (
               <Grid item xs={12} sm={6}>
                  <FavouriteDays
                     location={props.location}
                     max={totalDays}
                     favDays={favDays}
                  />
               </Grid>
            )}
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(RepPeople);
