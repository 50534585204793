import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../Firebase';
import {
   Box,
   FormControl,
   FormGroup,
   FormControlLabel,
   Switch,
   Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const AlertToggle = ({
   expanded,
   visitorsEnabled,
   location,
   locationKey,
   cid,
   ...props
}) => {
   const [visitorAlertsEnabled, setVisitorAlertsEnabled] = useState(() => {
      if (location?.visitorsAlertEnabled) {
         return true;
      } else {
         return false;
      }
   });
   const [disabled, setDisabled] = useState(false);

   useEffect(() => {
      const toggleVisitors = async () => {
         if (expanded === 'visitors') {
            await props.firebase.locationVisitorsToggle(locationKey, visitorsEnabled);
         }
         return;
      };
      toggleVisitors();
   }, [expanded, visitorsEnabled]);

   async function toggleVisitorsAlert(status) {
      setDisabled(true);
      if (status) {
         setVisitorAlertsEnabled(false);
         setDisabled(false);
      } else {
         var people = await props.firebase
            .locationPeople(locationKey, cid)
            .get()
            .then((results) => {
               let items = {};
               results.forEach((result) => {
                  items[result.data().email.toLowerCase()] =
                     result.data().name.firstName + ' ' + result.data().name.lastName;
               });
               return items;
            });
         try {
            await props.firebase.locationVisitorAlertToggleOn(locationKey, true, people);
            setVisitorAlertsEnabled(true);
            setDisabled(false);
         } catch (error) {
            console.log(error, 'error');
         }
      }
   }

   return (
      <Box>
         <Box>
            <Typography variant="h6" component="h2">
               Visitor Alerts
            </Typography>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '80%' }}>
               <Typography variant="body1">
                  You can enable Visitor Alerts so that Hosts recieve an email
                  notification when their visitors turn up.
               </Typography>
            </Box>
            <Box sx={{ width: '20%' }}>
               <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                     <FormControlLabel
                        value="top"
                        control={
                           <Switch
                              color="primary"
                              disabled={disabled}
                              checked={visitorAlertsEnabled}
                              onChange={() => {
                                 toggleVisitorsAlert(visitorAlertsEnabled);
                              }}
                           />
                        }
                        label="Enable Alerts"
                        labelPlacement="bottom"
                     />
                     {disabled && <CircularProgress />}
                  </FormGroup>
               </FormControl>
            </Box>
         </Box>
      </Box>
   );
};

export default withFirebase(AlertToggle);
