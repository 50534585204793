import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const BlankMiniAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="blank-mini-am" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M22,0 C24.209139,0 26,1.790861 26,4 L26,12.4168302 C26,12.4168302 0,29 0,29 L0,4 C0,1.790861 1.790861,0 4,0 L22,0 Z M22,1 L4,1 C2.34314575,1 1,2.34314575 1,4 L1,27.175 L1.01139873,27.1688266 C1.49351233,26.8613277 2.18707194,26.4189655 3.26828258,25.7293539 L3.52475431,25.5657726 C7.58725431,22.9746524 7.58725431,22.9746524 12.4622543,19.865308 C17.3372543,16.7559637 17.3372543,16.7559637 21.3997543,14.1648434 C22.3041442,13.5880107 22.734806,13.3133284 23.1150431,13.0708079 L23.3199885,12.9400907 C23.5933661,12.7657266 23.8807811,12.5824093 24.3450668,12.2862812 C24.6916488,12.0652263 24.7956234,11.9989098 24.9400326,11.9068036 L25,11.868 L25,4 C25,2.40231912 23.75108,1.09633912 22.1762728,1.00509269 L22,1 Z M14.75,9.5 L14.75,11.5 L6.75,11.5 L6.75,9.5 L14.75,9.5 Z"
                  id="Rectangle"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default BlankMiniAm;
