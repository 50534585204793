export function loadVisitors(result) {
   let vis = {
      expected: [],
      checkedIn: [],
      checkedOut: [],
   };
   if (result.visitorNo !== 0) {
      Object.entries(result).map(([key, value]) => {
         if (key !== 'visitorNo') {
            if (value.checkout) {
               vis.checkedOut.push({ uid: key, ...value });
            } else if (value.checkin) {
               vis.checkedIn.push({ uid: key, ...value });
            } else {
               vis.expected.push({ uid: key, ...value });
            }
         }
      });
   }

   vis.expected.sort((a, b) => {
      return a.name.localeCompare(b.name);
   });
   vis.checkedIn.sort((a, b) => {
      return a.name.localeCompare(b.name);
   });
   vis.checkedOut.sort((a, b) => {
      return a.name.localeCompare(b.name);
   });
   return vis;
}
