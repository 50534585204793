import React from 'react';
import { List } from '@mui/material';
import AvailabilityList from '../../availabilityList';

const AvailableTimes = (props) => {
   return (
      <List dense={true}>
         {props.dayStatus &&
            props.dayStatus.times.map((time, key) => {
               return <AvailabilityList key={key} time={time} />;
            })}
      </List>
   );
};

export default AvailableTimes;
