import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const ItoMiniAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} color="primary" viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="ito-mini-am" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M22,0 C24.209139,0 26,1.7883901 26,3.9944811 L26,12.4168302 L0,29 L0,3.9944811 C0,1.7883901 1.790861,0 4,0 L22,0 Z M16.7272727,3.4 L7.09090909,13.6835821 L3.27272727,9.60895522 L2,10.9671642 L7.09090909,16.4 L18,4.75820896 L16.7272727,3.4 Z"
                  id="Path-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoMiniAm;
