import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { dateFnsLocalizer } from 'react-big-calendar';
import { stringAvatar } from '../../../constants/functions';
export function EventAgenda({ event }) {
   if (event.saved) {
      return (
         <Tooltip title={`${event.owner.name} - ${event.title}`}>
            <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
               <Avatar {...stringAvatar(`${event.owner.name}`)} />
               <Typography variant="caption" sx={{ ml: 1 }}>
                  {event.title}
               </Typography>
            </span>
         </Tooltip>
      );
   } else {
      return (
         <span>
            <em>{event.title}</em>
         </span>
      );
   }
}

export const components = {
   day: {
      event: EventAgenda, // with the agenda view use a different component to render events
   },
   month: {
      event: EventAgenda,
   },
};

const locales = {
   'en-GB': require('date-fns/locale/en-GB'),
};

export const localizer = dateFnsLocalizer({
   format,
   parse,
   startOfWeek,
   getDay,
   locales,
});
