import React, { useState } from 'react';

import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import DoneIcon from '@material-ui/icons/Done';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PageLayout } from '../layout';
import { Paper, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   error: {
      color: 'red',
   },
   success: {
      color: 'green',
   },
}));

const PasswordForgetForm = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const [email, setEmail] = useState('');
   const [emailError, setEmailError] = useState(false);
   const [buttonMessage, setButtonMessage] = useState('reset my password');
   const [buttonDisabled, setButtonDisabled] = useState(false);
   const [error, setError] = useState('');
   const [successMessage, setSuccessMessage] = useState('');

   const Done = () => {
      return (
         <React.Fragment>
            <DoneIcon className={classes.success} />
            <Typography className={classes.success} variant="body1">
               Your password reset email is on it's way. Make sure you check your email,
               including your junk mail and click on the link to login.
            </Typography>
         </React.Fragment>
      );
   };

   function handleSubmit(event) {
      event.preventDefault();
      props.firebase
         .doPasswordReset(email)
         .then(() => {
            setEmail('');
            setEmailError(false);
            setError('');
            setButtonMessage('check your email');
            setButtonDisabled(true);
            setSuccessMessage(Done);
         })
         .catch((error) => {
            setError(error);
            setEmailError(true);
         });
   }

   return (
      <PageLayout sx={{ display: 'flex', justifyContent: 'center' }}>
         <Paper
            sx={{
               mt: theme.spacing(8),
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               p: theme.spacing(4),
               minWidth: 300,
               maxWidth: 450,
            }}
         >
            <div className={classes.paper}>
               <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                  Forgotten your password?
               </Typography>
               <Typography variant="body1">
                  Enter your email address below to recieve a reset password link.
               </Typography>
               <form className={classes.form} onSubmit={handleSubmit} noValidate>
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email Address"
                     name="email"
                     autoComplete="email"
                     value={email}
                     onInput={(e) => setEmail(e.target.value)}
                     autoFocus
                     error={emailError}
                     disabled={buttonDisabled}
                  />
                  <FormHelperText className={classes.error}>
                     {error.message}
                  </FormHelperText>
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     disabled={buttonDisabled}
                  >
                     {buttonMessage}
                  </Button>
               </form>
               {successMessage}
            </div>
         </Paper>
      </PageLayout>
   );
};

const PasswordForgetPage = compose(withRouter, withFirebase)(PasswordForgetForm);

export default PasswordForgetPage;
