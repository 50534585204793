import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../../Firebase';
import { CardSection } from '../../../layout';
import {
   Box,
   Divider,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Typography,
} from '@mui/material';
import Loading from '../../../Loading';
import CompanyDetails from './companyDetails';
import { sort } from 'ramda';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const CompaniesUsage = ({ companies }) => {
   const firebase = useFirebase();
   const [getParams, setParams] = useSearchParams();
   const history = useHistory();
   const [usage, setUsage] = useState(null);
   const [totalCount, setTotalCount] = useState(0);
   useEffect(() => {
      if (companies !== null) {
         firebase.db
            .doc(`usageLogs/companies`)
            .get()
            .then((doc) => {
               let data = [];
               let count = 0;
               Object.entries(doc.data()).forEach(([key, value]) => {
                  let subs = 'essential';

                  if (companies[key] && companies[key].subscription.plan === 'premium') {
                     let downgraded = true;
                     Object.values(companies[key].features).map((value) => {
                        if (value) {
                           downgraded = false;
                        }
                     });

                     if (downgraded) {
                        subs = 'downgraded';
                     } else {
                        subs = 'premium';
                     }
                  }
                  data.push({ cid: key, time: value, subs, ...companies[key] });
                  count++;
               });
               data = sort((a, b) => b.time - a.time, data);
               setTotalCount(count);
               setUsage(data);
            });
      } else {
         setUsage(null);
      }
   }, [companies]);

   const clickCompany = (cid) => {
      setParams({ cid });
      history.go(0);
   };

   return (
      <CardSection>
         {usage !== null ? (
            <List>
               <ListItem>{totalCount}</ListItem>
               {usage.map((company) => (
                  <React.Fragment key={company.cid}>
                     <ListItem>
                        <ListItemButton onClick={() => clickCompany(company.cid)}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 width: '100%',
                                 justifyContent: 'space-between',
                              }}
                           >
                              <Box sx={{ width: '50%' }}>
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       justifyItems: 'center',
                                       alignItems: 'center',
                                    }}
                                 >
                                    {company.subs === 'premium' && (
                                       <WorkspacePremiumIcon color="warning" />
                                    )}

                                    <Typography>{company.companyName}</Typography>
                                 </Box>
                                 <Typography variant="caption">
                                    {company.time.toDate().toString()}
                                 </Typography>
                              </Box>
                              <Box sx={{ width: '50%' }}>
                                 <CompanyDetails company={company} />
                              </Box>
                           </Box>
                        </ListItemButton>
                     </ListItem>
                     <Divider />
                  </React.Fragment>
               ))}
            </List>
         ) : (
            <Loading />
         )}
      </CardSection>
   );
};

export default CompaniesUsage;
