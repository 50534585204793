import { useState } from 'react';
import useFirebaseRequest from '../../../hooks/useFirebaseRequest';
import { useSnackbar } from 'notistack';
import { useFirebase } from '../../../components/Firebase';
import { useCompany } from '../../../providers';
import { getAddedRemovedMembers } from '../utils/convertMembers';

const useSaveGroup = (
   lid,
   gid,
   options = {
      successMessage: `Success`,
      errorMessage: `Fail`,
      callback: undefined,
   },
) => {
   if (!gid) {
      throw new Error(
         'You must pass a gid as an arg into this hook. e.g: const { onSave } = useSaveGroup(gid)',
      );
   }

   const { enqueueSnackbar } = useSnackbar();
   const firebase = useFirebase();
   const { refetch } = useFirebaseRequest(firebase.locationGroupDetails, {
      args: [lid, gid],
   });
   const { company } = useCompany();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   async function onSave(data) {
      setLoading(true);
      setError(null);

      let oldGroupDetails = await refetch();
      if (!oldGroupDetails) {
         oldGroupDetails = { ...data, members: {}, admins: {} };
      }

      const members = getAddedRemovedMembers(oldGroupDetails.members, data.members);
      const admins = getAddedRemovedMembers(oldGroupDetails.admins, data.admins);

      try {
         await firebase.updateGroupMembers(company.cid, gid, admins, members);
         await firebase.saveGroup(lid, gid, data, company.locations);

         enqueueSnackbar(`${options.successMessage}`, {
            variant: 'success',
            autoHideDuration: 3000,
         });

         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error);
         console.error(error, 'error - saving group');

         enqueueSnackbar(`${options.errorMessage}`, {
            variant: 'error',
            autoHideDuration: 3000,
         });
      }
      if (options.callback) {
         options.callback();
      }
   }

   return { loading, onSave, error };
};

export default useSaveGroup;
