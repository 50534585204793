import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const OffsiteAmpmBooked = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/offsiteAMpmBooked"
               fill={theme.palette.tableBody.main}
               fillRule="nonzero"
            >
               <path
                  d="M41,0 C41,0 19.4938713,31 19.4938713,31 L15.5,31 C6.48554758,31 0,24.0604136 0,15.5 C0,6.93958638 6.93958638,0 15.5,0 Z M21,22 L7,22 L7,24 L21,24 L21,22 Z M14,4 C10.69,4 8,6.69 8,10 C8,14.5 14,21 14,21 C14,21 20,14.5 20,10 C20,6.69 17.31,4 14,4 Z M14,8 C15.1,8 16,8.9 16,10 C16,11.1 15.11,12 14,12 C12.9,12 12,11.1 12,10 C12,8.9 12.9,8 14,8 Z"
                  id="Oval-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default OffsiteAmpmBooked;
