import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const WfhAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/WFHAM"
               transform="translate(-0.4778, 0)"
               fill={theme.palette.primary.main}
               fillRule="nonzero"
            >
               <path
                  d="M14,6 L24,15 L21,15 L21,23 L15,23 L15,17 L13,17 L13,23 L7,23 L7,15 L4,15 L14,6 Z M14,8.69 L9,13.19 L9,21 L11,21 L11,15 L17,15 L17,21 L19,21 L19,13.19 L14,8.69 Z"
                  id="Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default WfhAm;
