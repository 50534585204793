import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Tooltip, useMediaQuery } from '@mui/material';
import { stringAvatar } from '../../../../constants/functions';

const BackgroundLetterAvatars = (props) => {
   const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
   const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
   const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

   let maxAvatars;
   if (isXs) {
      maxAvatars = 3;
   } else if (isSm) {
      maxAvatars = 5;
   } else if (isMd) {
      maxAvatars = 6;
   } else {
      maxAvatars = 8;
   }

   let usedOwners = [];
   return (
      <AvatarGroup
         max={maxAvatars}
         sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
            '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14 },
         }}
      >
         {props.items.map((booking) => {
            if (booking.owner && !usedOwners.includes(booking.ownerId)) {
               usedOwners.push(booking.ownerId);
               return (
                  <Tooltip
                     key={booking.bid}
                     placement="top"
                     title={`${booking.owner.name.firstName} ${booking.owner.name.lastName}`}
                  >
                     <Avatar
                        {...stringAvatar(
                           `${booking.owner.name.firstName} ${booking.owner.name.lastName}`,
                        )}
                     />
                  </Tooltip>
               );
            }
         })}
      </AvatarGroup>
   );
};
export default BackgroundLetterAvatars;
