import React from 'react';
import {
   ListItem,
   ListItemButton,
   ListItemAvatar,
   ListItemText,
   Avatar,
   IconButton,
   Typography,
   Tooltip,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const getToolTipMessage = (count) => {
   if (count === undefined) {
      return 'All members';
   }
   return count > 1 || count === 0 ? `${count} members` : `${count} member`;
};

const GroupListingItem = ({
   groupDetails,
   groupView,
   primaryClick,
   secondaryClick,
   lastItem,
}) => {
   return (
      <ListItem
         disablePadding
         sx={{ p: 0 }}
         secondaryAction={
            !groupDetails.default && (
               <IconButton id={groupDetails.gid} onClick={secondaryClick}>
                  <EditOutlinedIcon />
               </IconButton>
            )
         }
      >
         <ListItemButton
            divider={!lastItem}
            key={groupDetails.gid}
            id={groupDetails.gid}
            selected={groupView === groupDetails.gid}
            onClick={primaryClick}
         >
            <ListItemAvatar sx={{ minWidth: 40 }}>
               <Tooltip
                  placement="right"
                  title={`${getToolTipMessage(groupDetails.count)}`}
               >
                  <Avatar sx={{ width: 26, height: 26, fontSize: '1em' }}>
                     {groupDetails.count > 99 ? '99+' : groupDetails.count}
                  </Avatar>
               </Tooltip>
            </ListItemAvatar>
            <ListItemText
               primary={groupDetails.name}
               secondary={
                  <Typography variant="caption" color={'GrayText'}>
                     {groupDetails.global ? 'Global' : ''}
                  </Typography>
               }
            />
         </ListItemButton>
      </ListItem>
   );
};

export default GroupListingItem;
