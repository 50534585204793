import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../Firebase';
import { Box, Button, Typography } from '@mui/material';
import Loading from '../../Loading';
import ProcessRefresh from './overview/processRefresh';
import LineChartComponent from './charts/lineChart';
import { set } from 'date-fns';
import { convertToDateName } from '../../../constants/functions';

const OverviewUsage = ({ companies, ...props }) => {
   const [processing, setProcessing] = useState(false);
   const [data, setData] = useState(null);

   useEffect(() => {
      const load = async () => {
         if (companies !== null) {
            const response = await props.firebase.db
               .collection(`usageLogs/companies/overview`)
               .get()
               .then((results) => {
                  let processedData = [];
                  results.forEach((doc) => {
                     Object.entries(doc.data()).forEach(([key, value]) => {
                        if (key === 'overallData') {
                           processedData.push({
                              dateRef: doc.id,
                              name: convertToDateName(doc.id),
                              ...value,
                           });
                        }
                     });
                  });
                  return processedData;
               });
            setData(response);
         }
      };
      return load();
   }, [processing]);

   const labels = [
      {
         dataKey: 'totalPageViews',
         name: 'Total Page Views',
      },
      {
         dataKey: 'dailyUsers',
         name: 'Daily Users',
      },
      {
         dataKey: 'appViews',
         name: 'App Views',
      },
      {
         dataKey: 'teamsViews',
         name: 'Teams Views',
      },
      {
         dataKey: 'average',
         name: 'Ave views per person',
      },
      {
         dataKey: 'companyNo',
         name: 'Active companies',
      },
   ];
   return (
      <Box>
         <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5" component="h2">
               All usage
            </Typography>
            <ProcessRefresh setProcessing={setProcessing} processing={processing} />
         </Box>
         {processing ? (
            <Loading />
         ) : (
            <Box>
               {data !== null && labels !== null && (
                  <LineChartComponent data={data} labels={labels} />
               )}
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(OverviewUsage);
