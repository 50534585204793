import React from 'react';
import { Box, Grid, Autocomplete, TextField, Button } from '@mui/material';

const AdditionalRolesForm = ({ value, roleList = [], onSubmit, onChange }) => {
   return (
      <form
         onSubmit={onSubmit}
         id="additional-roles"
         className="form"
         data-component="AdditionalRolesForm"
      >
         <Box
            sx={{
               width: '100%',
            }}
         >
            <Grid
               container
               justifyContent={'space-between'}
               alignItems={'center'}
               spacing={2}
            >
               <Grid item xs>
                  <Autocomplete
                     value={value}
                     disablePortal
                     id="combo-box-demo"
                     onChange={onChange}
                     options={roleList}
                     sx={{ width: '100%' }}
                     renderInput={(params) => (
                        <TextField
                           fullWidth
                           {...params}
                           variant="outlined"
                           margin="normal"
                           label="Role name"
                           helperText="Additional roles will be visible to your team."
                        />
                     )}
                  />
               </Grid>
               <Grid item xs="auto">
                  <Button
                     type="submit"
                     variant="contained"
                     disabled={!value}
                     onClick={onSubmit}
                  >
                     Save
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </form>
   );
};

export default AdditionalRolesForm;
