import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import ITOBlocks from './ITOBlocks';
import ITOnoSpaces from './ITOnoSpaces';
import TimingBox from './TimingBox';
import { Typography } from '@mui/material';
import OffsiteBlock from './offsiteBlock';
import SplitBlocks from './splitBlocks';
import WfhBlock from './wfhBlock';
import AwayBlock from './awayBlock';
import BlankBlock from './blankBlock';
import { AuthProvider } from '../../../providers';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
}));

const StatusDrawer = (props) => {
   const classes = useStyles();
   const [title, setTitle] = useState('');
   const [status, setStatus] = useState(false);
   const [savedTimes, setSavedTimes] = useState(false);
   const [location, setLocation] = useState(false);
   const [dayTime, setDayTime] = useState('AM');
   const [changedPrimaryLoc, setChangedPrimaryLoc] = useState(false);

   useEffect(() => {
      const drawerDate = async () => {
         if (props.displayFilter) {
            setTitle(
               props.data.date.dayName +
                  ', ' +
                  props.data.date.day +
                  ' ' +
                  props.data.date.monthName +
                  ' ' +
                  props.data.date.year,
            );
            let lid = props.displayFilter.id;
            if (props.displayFilter.type === 'group') {
               lid = props.displayFilter.lid;
            }
            const loc = await props.firebase.location(lid).get();
            if (loc.exists) {
               setLocation(loc.data());
            }
         }
      };
      drawerDate();
   }, [props.displayFilter]);

   useEffect(() => {
      if (location && props.data.status) {
         const unsubscribe = props.firebase
            .userScheduleDay(
               props.data.user.cid,
               props.data.user.uid,
               props.data.date.ref,
            )
            .onSnapshot((result) => {
               if (result.exists) {
                  if (result.data().customTimes && location?.routine) {
                     setSavedTimes(result.data().times);
                  } else {
                     setSavedTimes(false);
                  }
                  let wrongLoc = false;
                  if (result.data().lid !== props.data.user.lid) {
                     wrongLoc = true;
                     setChangedPrimaryLoc(true);
                  }
                  let statusData = {
                     set: result.data().status,
                  };
                  Object.entries(result.data()).map(([key, value]) => {
                     if (key !== 'status' && value !== '') {
                        statusData[key] = value;
                     }
                  });
                  if (wrongLoc) {
                     statusData.wrongLoc = true;
                     if (result.data().status === 'ito') {
                        statusData.set = 'offsite';
                        statusData.lid = result.data().lid;
                        statusData.originalStatus = 'ito';
                        statusData.offsiteLocation = {
                           locationId: result.data().lid,
                           locationName: props.data.offsiteLocation.locationName,
                        };
                     }
                     if (result.data().status === 'split') {
                        Object.entries(statusData.splitDay).map(([key, value]) => {
                           if (value === 'ito') {
                              statusData.splitDay[key] = 'offsite';
                              if (statusData.offsiteLocation) {
                                 statusData.offsiteLocation[key] = {
                                    locationId: result.data().lid,
                                    // locationName
                                 };
                              } else {
                                 statusData.offsiteLocation = {
                                    [key]: {
                                       locationId: result.data().lid,
                                       // locationName
                                    },
                                 };
                              }
                           }
                        });
                     }
                  }
                  setStatus(statusData);
               } else {
                  setStatus(props.data.status);
               }
            });
         return () => {
            unsubscribe();
            props.setSpaceData({});
            setStatus(false);
            setLocation(false);
            setDayTime('AM');
         };
      }
   }, [location, props.data.status]);

   return (
      <AuthProvider>
         <Container maxWidth="md">
            <div className={classes.root}>
               <Grid container spacing={0}>
                  {status ? (
                     <React.Fragment>
                        <Grid item xs={12}>
                           <TimingBox
                              data={props.data}
                              status={status}
                              setDayTime={setDayTime}
                              dayTime={dayTime}
                              location={location}
                              title={title}
                              savedTimes={savedTimes}
                              displayFilter={props.displayFilter}
                           />
                        </Grid>
                        <Grid item container xs={12}>
                           {status.set == 'ito' && props.spacesEnabled ? (
                              <ITOBlocks
                                 data={props.data}
                                 user={props.data.user}
                                 date={props.data.date}
                                 dayTime={false}
                                 status={status}
                                 location={location}
                                 displayFilter={props.displayFilter}
                              />
                           ) : status.set == 'ito' ? (
                              <ITOnoSpaces />
                           ) : status.set == 'offsite' ? (
                              <OffsiteBlock
                                 data={props.data}
                                 dayTime={false}
                                 status={status}
                                 displayFilter={props.displayFilter}
                                 spacesEnabled={props.spacesEnabled}
                              />
                           ) : status.set == 'split' ? (
                              <SplitBlocks
                                 setDayTime={setDayTime}
                                 dayTime={dayTime}
                                 spacesEnabled={props.spacesEnabled}
                                 data={props.data}
                                 status={status}
                                 displayFilter={props.displayFilter}
                              />
                           ) : status.set == 'wfh' ? (
                              <WfhBlock />
                           ) : status.set == 'away' ? (
                              <AwayBlock />
                           ) : (
                              <BlankBlock />
                           )}
                        </Grid>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <Typography>Loading...</Typography>
                     </React.Fragment>
                  )}
               </Grid>
            </div>
         </Container>
      </AuthProvider>
   );
};

export default withFirebase(StatusDrawer);
