import { useCompany } from '../../providers';
import { featureSet } from '../../constants/features';

export const validateFeature = (feature) => {
   if (!featureSet.includes(feature)) {
      throw new Error(`${feature} is not recongnised as a valid feature`);
   }
   return feature;
};

export const isFeatureEnabled = (feature, companyFeatures) => {
   const validFeature = validateFeature(feature);
   return companyFeatures[validFeature];
};

export const isFeatureEnabledBool = (validFeature, companyFeatures) => {
   return Boolean(isFeatureEnabled(validFeature, companyFeatures));
};

export const areFeaturesEnabled = (features, companyFeatures) => {
   const validFeatures = features.map((feature) => validateFeature(feature));
   return validFeatures.every((feature) => companyFeatures[feature]);
};

const IsEnabled = ({ company, hasFeature = null, hasFeatures = null, children }) => {
   if (!company) return null;

   if (hasFeature && hasFeatures) {
      throw new Error('You need to choose hasFeature OR hasFeatures. Not both');
   }

   let isSupported;

   if (hasFeature) {
      isSupported = isFeatureEnabled(hasFeature, company.features);
   }

   if (hasFeatures && hasFeatures.length) {
      isSupported = areFeaturesEnabled(hasFeatures, company.features);
   }

   return isSupported ? children : null;
};

export default IsEnabled;
