import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withFirebase } from '../Firebase';
import {
   DateTodayDBReady,
   getWeekDatesArray,
   FullWeekDateRange,
   convertToDate,
} from '../../constants/functions';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ExportToCsv } from 'export-to-csv';
import faker from '@faker-js/faker';
import { uid } from 'uid';

class Constants {
   static get DAYS() {
      return {
         0: {
            name: 'Monday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         1: {
            name: 'Tuesday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         2: {
            name: 'Wednesday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         3: {
            name: 'Thursday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         4: {
            name: 'Friday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         5: {
            name: 'Saturday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
         6: {
            name: 'Sunday',
            ito: 0,
            offsite: 0,
            wfh: 0,
            away: 0,
            '-': 0,
         },
      };
   }
}

const ScheduleExport = (props) => {
   let startTod = DateTodayDBReady(-(4 * 7));
   let startWeek = getWeekDatesArray(startTod);
   let start = {
      year: startWeek[0].slice(0, 4),
      month: startWeek[0].slice(4, 6),
      day: startWeek[0].slice(6, 8),
   };
   let tod = DateTodayDBReady(-7);
   let week = getWeekDatesArray(tod);
   let end = {
      year: week[6].slice(0, 4),
      month: week[6].slice(4, 6),
      day: week[6].slice(6, 8),
   };
   let dayRange = FullWeekDateRange(start, end);
   const [dates, setDates] = useState(dayRange);
   const [totalDays, setTotalDays] = useState(dayRange.length - 8);
   const [locPeople, setLocPeople] = useState(false);
   const [locGroups, setLocGroups] = useState(false);
   const [loadedData, setLoadedData] = useState(false);
   const [exportData, setExportData] = useState(false);
   const [exportHeaders, setExportHeaders] = useState(false);
   const [exportDisabled, setExportDisabled] = useState(true);
   const [range, setRange] = React.useState(4);
   const [hideWeekends, setHideWeekends] = useState(true);

   const handleChange = (event) => {
      setExportDisabled(true);
      setLoadedData(false);
      setRange(event.target.value);
      let newTod = DateTodayDBReady(-(event.target.value * 7));
      let newWeek = getWeekDatesArray(newTod);
      let newStart = {
         year: newWeek[0].slice(0, 4),
         month: newWeek[0].slice(4, 6),
         day: newWeek[0].slice(6, 8),
      };
      let newDayRange = FullWeekDateRange(newStart, end);
      setDates(newDayRange);
      if (hideWeekends) {
         setTotalDays(newDayRange.length - event.target.value * 2);
      } else {
         setTotalDays(newDayRange.length);
      }
   };
   const handleSwitch = (event) => {
      setHideWeekends(event.target.checked);
      if (event.target.checked) {
         setTotalDays(dates.length - range * 2);
      } else {
         setTotalDays(dates.length);
      }
   };

   useEffect(() => {
      const loadLocationPeople = async () => {
         if (props.tab === 2 && props.location) {
            setLocPeople(false);
            let allPeople = await props.firebase
               .locationPeople(props.location.lid, props.location.cid)
               .orderBy('name.firstName')
               .get()
               .then((result) => {
                  let peopleData = {};
                  if (!result.empty) {
                     result.forEach((person) => {
                        peopleData[person.id] = {
                           ...person.data(),
                           uid: person.id,
                           name:
                              person.data().name.firstName +
                              ' ' +
                              person.data().name.lastName,
                        };
                     });
                  } else {
                     peopleData = false;
                  }
                  return peopleData;
               });
            setLocPeople(allPeople);
         }
      };
      loadLocationPeople();
   }, [props.tab, props.location]);

   useEffect(() => {
      const loadLocationGroups = async () => {
         if (props.tab === 2 && props.location) {
            setLocGroups(false);
            props.firebase
               .locationGroups(props.location.lid)
               .get()
               .then((results) => {
                  let data = {};
                  if (!results.empty) {
                     results.forEach((result) => {
                        data[result.id] = result.data();
                     });
                  } else {
                     data = true;
                  }
                  setLocGroups(data);
               });
         }
      };
      loadLocationGroups();
   }, [props.tab, props.location]);

   useEffect(() => {
      const loadData = async () => {
         if (locPeople && locGroups) {
            let people = {};
            let busyDays = Constants.DAYS;
            Object.entries(locPeople).map(([key, value]) => {
               let groupNames = [];
               value.gid.forEach((gid) => {
                  groupNames.push(locGroups[gid].name);
               });
               people[key] = {
                  name: value.name,
                  groups: groupNames,
                  status: {},
               };
            });
            if (dates.length < 8) {
               await props.firebase
                  .locationScheduleDayStreak(props.location.lid, dates)
                  .get()
                  .then((results) => {
                     if (!results.empty) {
                        results.forEach((result) => {
                           Object.entries(people).map(([key, value]) => {
                              if (result.data()[key]) {
                                 people[key].status[result.id] = result.data()[key];
                              } else {
                                 people[key].status[result.id] = { status: '-' };
                              }
                           });
                        });
                     }
                     let data = [];
                     Object.values(people).map((person) => {
                        data.push(person);
                     });
                     setLoadedData(data);
                     loadExportFile(data);
                  });
            } else {
               let data = [];
               people = await loadWeeksSchedule(people, dates, busyDays);
               Object.values(people).map((person) => {
                  data.push(person);
               });
               setLoadedData(data);
               loadExportFile(data);
            }
         }
      };
      loadData();
   }, [locPeople, locGroups, props.location, dates]);

   async function loadExportFile(data) {
      let file = [];
      let headers = ['name', 'groups'];
      Object.keys(data[0].status).map((day) => headers.push(convertToDate(day)));
      Object.entries(data).map(([key, value]) => {
         let person = {
            0: value.name,
            1: JSON.stringify(value.groups),
         };
         Object.entries(value.status).map(([key, value]) => {
            if (value.status == 'split') {
               person[key] = `${value.splitDay.AM} / ${value.splitDay.PM}`;
            } else {
               person[key] = value.status;
            }
         });
         file.push(person);
      });
      setExportHeaders(headers);
      setExportData(file);
      setExportDisabled(false);
   }

   async function loadWeeksSchedule(people, dates) {
      let count = dates.length;
      let start = 0;
      let end = 7;
      let run = true;
      while (run) {
         let amendedDates = dates.slice(start, end);
         await props.firebase
            .locationScheduleDayStreak(props.location.lid, amendedDates)
            .get()
            .then((results) => {
               if (!results.empty) {
                  results.forEach((result) => {
                     Object.entries(people).map(([key, value]) => {
                        if (result.data()[key]) {
                           people[key].status[result.id] = result.data()[key];
                        } else {
                           people[key].status[result.id] = { status: '-' };
                        }
                     });
                  });
               }
            });

         start = end;
         end = end + 7;
         if (end > count) {
            end = count;
         }
         if (start === count) {
            run = false;
         }
      }
      if (!run) {
         return people;
      }
   }

   const dataToExport = useMemo(() => exportData, [exportData]);
   const dataExportId = useMemo(() => uid(16), []); // create a unique and identifyable ID for each file downloaded

   const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: `ITO-status-export-${dataExportId}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: exportHeaders,
   };

   const csvExporter = new ExportToCsv(options);

   if (props.location.people > 0) {
      return (
         <Box py={4}>
            <Grid container spacing={1}>
               <Grid container item xs={12}>
                  <Grid item xs={6} sm={3}>
                     <FormControl fullWidth>
                        <InputLabel id="date_range_selection">Date Range</InputLabel>
                        <Select
                           labelId="date_range_selection"
                           id="demo-simple-select"
                           value={range}
                           defaultValue={4}
                           label="Past date range"
                           onChange={handleChange}
                        >
                           <MenuItem value={1}>Last week</MenuItem>
                           <MenuItem value={2}>Past 2 weeks</MenuItem>
                           <MenuItem value={3}>Past 3 weeks</MenuItem>
                           <MenuItem value={4}>Past 4 weeks</MenuItem>
                           <MenuItem value={5}>Past 5 weeks</MenuItem>
                           <MenuItem value={6}>Past 6 weeks</MenuItem>
                           <MenuItem value={7}>Past 7 weeks</MenuItem>
                           <MenuItem value={8}>Past 2 months</MenuItem>
                           <MenuItem value={12}>Past 3 months</MenuItem>
                           {/* <MenuItem value={24}>Past 6 months</MenuItem> */}
                        </Select>
                     </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2} lg={1}>
                     <Typography sx={{ m: '16px 0 0 16px' }} variant="body1">
                        ({totalDays} days)
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <FormGroup>
                        <FormControlLabel
                           control={
                              <Switch
                                 checked={hideWeekends}
                                 onChange={handleSwitch}
                                 color="primary"
                                 inputProps={{ 'aria-label': 'hide weekends' }}
                              />
                           }
                           label="Hide weekends"
                           sx={{ mt: 1, ml: 1 }}
                        />
                     </FormGroup>
                  </Grid>
                  <Grid item>
                     <Button
                        variant="contained"
                        onClick={() => csvExporter.generateCsv(dataToExport)}
                        disabled={exportDisabled}
                     >
                        Export
                     </Button>
                  </Grid>
               </Grid>
               <Grid container item xs={12}>
                  <Typography variant="h5" gutterBottom>
                     Export Preview
                  </Typography>
               </Grid>
               {!loadedData && <Typography variant="body1">Loading...</Typography>}
               {loadedData && (
                  <TableContainer component={Paper}>
                     <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="Export Table"
                        stickyHeader
                     >
                        <TableHead>
                           <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Groups</TableCell>
                              {Object.keys(loadedData[0].status).map((key) => (
                                 <TableCell key={key} align="right">
                                    {convertToDate(key)}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {Object.entries(loadedData).map(([key, person]) => (
                              <TableRow
                                 key={key}
                                 sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                 }}
                              >
                                 <TableCell component="th" scope="row">
                                    {person.name}
                                 </TableCell>
                                 <TableCell align="right" sx={{ maxWidth: 100 }}>
                                    {person.groups.map((group) => (
                                       <Chip key={group} label={group} size="small" />
                                    ))}
                                 </TableCell>
                                 {Object.entries(person.status).map(([day, details]) => (
                                    <TableCell key={day} align="right">
                                       {details.status == 'split'
                                          ? `${details.splitDay.AM} / ${details.splitDay.PM}`
                                          : details.status}
                                    </TableCell>
                                 ))}
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               )}
            </Grid>
         </Box>
      );
   } else {
      return (
         <Box py={4}>
            <Typography>Add users to this location to create reports</Typography>
         </Box>
      );
   }
};

export default withFirebase(ScheduleExport);
