import { locationUsers } from './locationUsers';
import { scheduleStatus } from './scheduleStatus';
import { allUsers } from './allUsers';

export const fetchData = async (query) => {
   switch (query.type) {
      case 'schedulestatus':
         try {
            const response = await scheduleStatus(query);
            return response;
         } catch (error) {
            return error;
         }

      case 'users':
         try {
            const response = await locationUsers(query);
            return response;
         } catch (error) {
            return error;
         }
      case 'allusers':
         try {
            const response = await allUsers(query);
            return response;
         } catch (error) {
            return error;
         }
      default:
         return { code: 400, status: 'error', error: 'Invalid query type' };
   }
};
