import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withFirebase } from '../Firebase';
import InternalPeople from '../Interactions/internalPeople';
import LoadChip from '../Interactions/loadChips';
import { TimePicker } from '@material-ui/pickers';
import {
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   TextField,
   Tooltip,
} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { useSpacesContext } from './spaces.context';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import { useAuth } from '../../providers';

const MeetingForm = (props) => {
   const { authUser } = useAuth();
   const [expanded, setExpanded] = useState('panel1');
   const [peopleVal, setPeopleVal] = useState([]);
   const [attendance, setAttendance] = useState(false);
   const [routine, setRoutine] = useState(false);
   const [pplIndex, setPplIndex] = useState(false);
   const [loading, setLoading] = useState(false);
   const [ownerLoading, setOwnerLoading] = useState(false);
   const { people, space, date, location, defaultDate, form } = useSpacesContext();

   useEffect(() => {
      const getSchedule = () => {
         if (location && date) {
            let allLocAtten = {};
            const schedule = props.firebase
               .locationDayScheduleDate(location.id, date.ref)
               .get();

            if (schedule.exists) {
               allLocAtten = schedule.data();
            }
            setAttendance(allLocAtten);
         }
      };
      getSchedule();
   }, [location, date]);

   useEffect(() => {
      const loadRoutine = async () => {
         if (location.routine) {
            await props.firebase.getRoutineStreak(location.id).onSnapshot((result) => {
               if (result.exists) {
                  setRoutine(result.data());
               }
            });
         }
      };
      loadRoutine();
   }, [location]);

   useEffect(() => {
      const loadPeople = async () => {
         if (attendance) {
            let dayNo = defaultDate.getDay();
            var participants = {};
            Object.entries(peopleVal).map(([key, value]) => {
               let found = false;
               Object.entries(attendance).map(([lid, dayStatuses]) => {
                  if (dayStatuses !== null && value.id in dayStatuses) {
                     found = true;
                     participants[value.id] = {
                        name: value.name,
                        email: value.email,
                     };
                     if (lid !== location.id && dayStatuses[value.id].status == 'ito') {
                        participants[value.id].status = 'offsite';
                     } else {
                        participants[value.id].status = dayStatuses[value.id].status;
                     }
                     if (routine && value.routine) {
                        participants[value.id].routine = value.routine[dayNo];
                     }
                  }
               });
               if (!found) {
                  participants[value.id] = {
                     name: value.name,
                     status: 'none',
                     email: value.email,
                  };
                  if (routine && value.routine) {
                     participants[value.id].routine = value.routine[dayNo];
                  }
               }
            });
            props.setForm({ ...form, internalParticipants: participants });
         }
      };
      loadPeople();
   }, [peopleVal, attendance]);

   useEffect(() => {
      if (location) {
         const unsub = props.firebase.allHosts(location.id).onSnapshot((result) => {
            if (result.exists) {
               let ppl = [
                  {
                     email: authUser.email,
                     label: `${authUser.name.firstName} ${authUser.name.lastName}`,
                  },
               ];
               Object.entries(result.data()).map(([key, value]) => {
                  if (key !== authUser.email) {
                     ppl.push({ email: key, label: value });
                  }
               });
               setPplIndex(ppl);
            }
         });
         return () => unsub();
      }
   }, [location]);

   function handleFormChange(event) {
      const { id, value } = event.target;
      props.setForm({ ...form, [id]: value });
      if (props.existing) {
         Object.assign(props.spaceBookings[props.spaceBookings.length - 1], {
            title: event.target.value,
         });
         props.setEvents(props.spaceBookings);
      } else {
         props.setEvents(() => [...props.spaceBookings, { ...form, title: value }]);
      }
   }

   const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   async function handleHostSelect(event, value) {
      if (value !== null) {
         setLoading('loading');
         props.setHostError({ error: false, message: '' });
         setTimeout(async function () {
            let hostPerson = await props.firebase
               .getPersonByEmail(form.cid, value.email)
               .get();
            if (!hostPerson.empty) {
               let host = {};
               let owner = form.owner;

               hostPerson.forEach((person) => {
                  host = { name: value.label, uid: person.id };
                  if (form.owner.name == '') {
                     owner = { name: value.label, uid: person.id };
                  }
               });
               props.setForm({ ...form, host, owner });
               setLoading('done');
            } else {
               setLoading(false);
               props.setHostError({
                  error: true,
                  message: 'Oops, something went wrong, please try again.',
               });
            }
         }, 1000);
      } else {
         setLoading(false);
         props.setForm({
            ...form,
            host: { name: '', uid: '' },
            owner: { name: '', uid: '' },
         });
      }
   }
   async function handleOwnerSelect(event, value) {
      if (value !== null) {
         setOwnerLoading('loading');
         props.setOwnerError({ error: false, message: '' });
         setTimeout(async function () {
            let ownerPerson = await props.firebase
               .getPersonByEmail(form.cid, value.email)
               .get();
            if (!ownerPerson.empty) {
               let owner = {};
               ownerPerson.forEach((person) => {
                  owner = { name: value.label, uid: person.id };
               });
               props.setForm({ ...form, owner });
               setOwnerLoading('done');
            } else {
               setOwnerLoading(false);
               props.setOwnerError({
                  error: true,
                  message: 'Oops, something went wrong, please try again.',
               });
            }
         }, 1000);
      } else {
         props.setForm({ ...form, owner: { name: '', uid: '' } });
      }
   }

   return (
      <React.Fragment>
         <Accordion
            expanded={expanded === 'panel1'}
            sx={{ minWidth: 100 }}
            onChange={handleAccordionChange('panel1')}
         >
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1bh-content"
               id="panel1bh-header"
            >
               <Typography sx={{ width: '33%', flexShrink: 0 }}>Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <List dense>
                  <ListItem>
                     <Tooltip placement="top" title="Host">
                        <FormatQuoteOutlinedIcon sx={{ mr: 2 }} />
                     </Tooltip>
                     <TextField
                        margin="dense"
                        fullWidth
                        size="small"
                        label="Title"
                        type="text"
                        id="title"
                        value={form.title}
                        onChange={handleFormChange}
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              border: '1px solid #999999',
                           },
                           mb: 2,
                        }}
                     />
                  </ListItem>
                  <ListItem>
                     <Tooltip placement="top" title="Start/End time">
                        <AccessTimeOutlinedIcon sx={{ mr: 2 }} />
                     </Tooltip>

                     <TimePicker
                        sx={{ width: '75%' }}
                        label="Start Time"
                        mask="__:__"
                        ampm={false}
                        value={form.start}
                        minutesStep={1}
                        onChange={(date) => {
                           if (date > form.end) {
                              props.setForm({ ...form, start: date, end: date });
                              if (props.existing) {
                                 Object.assign(
                                    props.spaceBookings[props.spaceBookings.length - 1],
                                    {
                                       start: date,
                                    },
                                 );
                                 props.setEvents(props.spaceBookings);
                              } else {
                                 props.setEvents(() => [
                                    ...props.spaceBookings,
                                    { ...form, start: date, end: date },
                                 ]);
                              }
                           } else {
                              props.setForm({ ...form, start: date });
                              if (props.existing) {
                                 Object.assign(
                                    props.spaceBookings[props.spaceBookings.length - 1],
                                    {
                                       start: date,
                                    },
                                 );
                                 props.setEvents(props.spaceBookings);
                              } else {
                                 props.setEvents(() => [
                                    ...props.spaceBookings,
                                    { ...form, start: date },
                                 ]);
                              }
                           }
                        }}
                     />
                     <TimePicker
                        label="End Time"
                        mask="__:__"
                        ampm={false}
                        value={form.end}
                        minutesStep={1}
                        onChange={(date) => {
                           props.setForm({ ...form, end: date });
                           if (props.existing) {
                              Object.assign(
                                 props.spaceBookings[props.spaceBookings.length - 1],
                                 {
                                    end: date,
                                 },
                              );
                              props.setEvents(props.spaceBookings);
                           } else {
                              props.setEvents(() => [
                                 ...props.spaceBookings,
                                 { ...form, end: date },
                              ]);
                           }
                        }}
                     />
                  </ListItem>

                  {props.kiosk && pplIndex && (
                     <ListItem>
                        {props.existing ? (
                           <>
                              <ListItemIcon>
                                 <Tooltip placement="top" title="Host">
                                    <PortraitOutlinedIcon sx={{ mr: 2 }} />
                                 </Tooltip>
                              </ListItemIcon>
                              <ListItemText primary={props.form.host.name} />
                           </>
                        ) : (
                           <>
                              <Tooltip placement="top" title="Host">
                                 <PortraitOutlinedIcon sx={{ mr: 2 }} />
                              </Tooltip>

                              <Autocomplete
                                 disablePortal
                                 id="host"
                                 options={pplIndex}
                                 size="small"
                                 sx={{ width: '80%', mr: 1 }}
                                 onChange={(e, v) => handleHostSelect(e, v)}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       required
                                       error={props.hostError.error}
                                       helperText={props.hostError.message}
                                       margin="dense"
                                       label="Host"
                                       sx={{
                                          '& .MuiOutlinedInput-root': {
                                             border: '1px solid #999999',
                                          },
                                          mb: 2,
                                       }}
                                    />
                                 )}
                              />
                              {loading === 'loading' && <CircularProgress />}
                              {loading === 'done' && <CheckCircleIcon color="success" />}
                              {loading === 'error' && <CancelIcon color="error" />}

                              {/* <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%'}}>
                <Autocomplete
                  disablePortal
                  id="owner"
                  options={pplIndex}
                  size="small"
                  sx={{ width: '80%', mr:1 }}
                  onChange={(e, v)=>handleOwnerSelect(e, v)}
                  value={form.owner.name}
                  renderInput={(params) => <TextField {...params}  required error={props.ownerError.error} helperText={props.ownerError.message} margin="dense" label="Owner" />}
                />
                {ownerLoading === "loading" &&
                  <CircularProgress />
                }
                {ownerLoading === 'done' &&
                <CheckCircleIcon color="success" />
                }
                {ownerLoading === 'error' &&
                <CancelIcon color="error" />
                }
              </Box> */}
                           </>
                        )}
                     </ListItem>
                  )}
                  {!props.kiosk && pplIndex && (
                     <React.Fragment>
                        {/* <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%'}}>
                <Autocomplete
                  disablePortal
                  id="host"
                  options={pplIndex}
                  size="small"
                  fullWidth
                  sx={{ width: '80%', mr:1 }}
                  onChange={(e, v)=>handleHostSelect(e, v)}
                  value={form.host.name}
                  renderInput={(params) => <TextField {...params}  required error={props.hostError.error} helperText={props.hostError.message} margin="dense" label="Host" />}
                />
                {loading === "loading" &&
                  <CircularProgress />
                }
                {loading === 'done' &&
                <CheckCircleIcon color="success" />
                }
                {loading === 'error' &&
                <CancelIcon color="error" />
                }
              </Box> */}
                        {/* <TextField
                margin="dense"
                fullWidth
                size="small"
                label="Owner"
                value={form.owner.name}
                onChange={handleFormChange}
                disabled
              /> */}
                     </React.Fragment>
                  )}
                  <ListItem>
                     <Tooltip placement="top" title="Seats">
                        <EventSeatOutlinedIcon sx={{ mr: 2 }} />
                     </Tooltip>
                     <TextField
                        margin="dense"
                        size="small"
                        fullWidth
                        type="number"
                        id="seats"
                        InputProps={{
                           inputProps: { min: 1, max: space.seats },
                        }}
                        value={form.seats}
                        onChange={handleFormChange}
                        label="Seats"
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              border: '1px solid #999999',
                           },
                           mb: 2,
                        }}
                     />
                  </ListItem>
               </List>
            </AccordionDetails>
         </Accordion>

         {/* <Accordion
        expanded={expanded === "panel2"}
        sx={{ minWidth: 100 }}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Invite People
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InternalPeople
            attendance={attendance}
            form={form}
            setForm={props.setForm}
            people={people}
            peopleVal={peopleVal}
            setPeopleVal={setPeopleVal}
          />
          <Typography variant="caption">Invitees:</Typography>
          {form.internalParticipants &&
            Object.entries(form.internalParticipants).map(([key, value], i) => (
              <LoadChip
                key={key}
                item={i}
                id={key}
                value={value}
                form={form}
                setForm={props.setForm}
                location={location}
                setPeopleVal={setPeopleVal}
                peopleVal={peopleVal}
              />
            ))}
          <TextField
            margin="dense"
            fullWidth
            size="small"
            label="External guests"
          />
        </AccordionDetails>
      </Accordion> */}
         {/* <Accordion
        expanded={expanded === "panel3"}
        sx={{ minWidth: 100 }}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Extra Requests
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            margin="dense"
            multiline
            rows="4"
            fullWidth
            size="small"
            id="catering"
            value={form.catering}
            onChange={handleFormChange}
            label="Catering"
            placeholder="Enter your requests, e.g. coffee for 6, still and sparkling water, etc."
          />
          <TextField
            margin="dense"
            multiline
            rows="4"
            fullWidth
            size="small"
            label="IT/AV"
            id="it"
            value={form.it}
            onChange={handleFormChange}
            placeholder="Enter any technology requests e.g. projector"
          />
        </AccordionDetails>
      </Accordion> */}
      </React.Fragment>
   );
};

export default withFirebase(MeetingForm);
