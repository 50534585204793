import React, { useEffect, useState } from 'react';

import {
   Box,
   CircularProgress,
   Divider,
   Typography,
   Switch,
   FormControl,
   FormControlLabel,
   FormHelperText,
} from '@mui/material';
import { withFirebase } from '../../Firebase';
import CheckingInPanel from './checkingInPanel';
import AlertToggle from './alertToggle';
import HostTypeSelector from './hostTypeSelector';
import LandlordVisitor from './landlordVisitor';
import TenantVisitor from './tenantVisitor';
import TenantImageUpload from './tenantImageUpload';
import HostAutocompleteToggle from './hostAutocompleteToggle';

const VisitorsPanel = (props) => {
   const [visitorsEnabled, setVisitorsEnabled] = useState(false);
   const [role, setRole] = useState('Loading');

   useEffect(() => {
      if (props.location.visitorsEnabled) {
         setVisitorsEnabled(true);
         if (props.location.visitorHostType) {
            setRole(props.location.visitorHostType);
         } else {
            setRole('Default');
         }
      }
   }, [props.location]);

   return (
      <Box>
         {!visitorsEnabled ? (
            <Box>
               <Typography variant="body1">
                  Visitors is a premium feature that must be enabled at the company level.
                  Please go to the Company tab to enable this feature.
               </Typography>
            </Box>
         ) : (
            <Box>
               <Box>
                  <HostTypeSelector
                     role={role}
                     setRole={setRole}
                     cid={props.location.cid}
                     lid={props.locationKey}
                     location={props.location}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
               </Box>
               {role === 'Default' ? (
                  <Box>
                     <CheckingInPanel
                        lid={props.locationKey}
                        locationName={props.location.name}
                        expanded={props.expanded}
                        visitorsEnabled={visitorsEnabled}
                     />
                     <Divider sx={{ mt: 1, mb: 1 }} />
                     <AlertToggle
                        expanded={props.expanded}
                        visitorsEnabled={visitorsEnabled}
                        location={props.location}
                        locationKey={props.locationKey}
                        cid={props.location.cid}
                     />
                  </Box>
               ) : role === 'Landlord' ? (
                  <Box>
                     <LandlordVisitor
                        lid={props.locationKey}
                        cid={props.location.cid}
                        location={props.location}
                     />
                  </Box>
               ) : role === 'Tenant' ? (
                  <Box>
                     <TenantVisitor
                        lid={props.locationKey}
                        cid={props.location.cid}
                        location={props.location}
                     />
                  </Box>
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress />
                  </Box>
               )}
               <Box>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography variant="h6">Host Autocomplete Settings</Typography>

                  <HostAutocompleteToggle
                     lid={props.locationKey}
                     location={props.location}
                  />
               </Box>
               <Box>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography variant="h6">Upload your logo</Typography>
                  <TenantImageUpload lid={props.locationKey} location={props.location} />
               </Box>
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(VisitorsPanel);
