import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import logo from '../../assets/logo.svg';
import queryString from 'query-string';
import SpaceKiosk from './spaceKiosk';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthProvider } from '../../providers';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   menu: {
      color: theme.palette.text.primary,
   },
   menuButton: {
      marginRight: theme.spacing(2),
   },
   title: {
      flexGrow: 1,
   },
   logo: {
      height: '45px',
   },
   appBar: {
      backgroundImage: 'linear-gradient(16deg, #32bfc7, #349fb8)',
      zIndex: theme.zIndex.drawer + 1,
   },
}));

const Kiosks = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();
   let url = queryString.parse(location.search);
   const [space, setSpace] = useState(false);
   const [coLocs, setCoLocs] = useState(false);
   const [locSpaces, setLocSpaces] = useState(false);
   const [loc, setLoc] = useState(false);
   const [error, setError] = useState(false);
   const authUser = JSON.parse(localStorage.getItem('authUser'));

   function refreshPage() {
      window.location.reload(false);
   }
   useEffect(() => {
      var startDate = new Date();
      var endDate = new Date();
      endDate.setHours(24, 0, 0, 0);
      var milSecs = endDate - startDate;
      const interval = setInterval(() => {
         refreshPage();
      }, milSecs);
      return () => {
         clearInterval(interval);
      };
   }, []);

   function handleLidUpdate(lid) {
      setCoLocs(false);
      history.push({
         pathname: '/kiosks',
         search: `?lid=${lid}`,
      });
   }

   function handleSidUpdate(sid) {
      setLocSpaces(false);
      history.push({
         pathname: '/kiosks',
         search: `${location.search}&sid=${sid}`,
      });
   }

   useEffect(() => {
      if (url.sid) {
         setSpace(url.sid);
         const loadLoc = props.firebase.location(url.lid).onSnapshot((result) => {
            setLoc({ ...result.data(), id: result.id });
         });
         return () => loadLoc();
      } else if (url.lid) {
         const loadSpaces = async () => {
            let results = await props.firebase.spaces(url.lid).get();
            if (!results.empty) {
               let spaceEntries = [];
               results.forEach((result) => {
                  spaceEntries.push({ id: result.id, ...result.data() });
               });
               setLocSpaces(spaceEntries);
            } else {
               setError(true);
            }
         };
         loadSpaces();
      } else {
         const loadCompany = async () => {
            let company = await props.firebase.company(authUser.cid).get();
            console.log(company.data());
            if (company.exists) {
               setCoLocs(company.data().locations);
            } else {
               setError(true);
            }
         };
         loadCompany();
      }
   }, [url.lid, url.sid]);

   return (
      <React.Fragment>
         <AuthProvider>
            <AppBar position="fixed" className={classes.appBar}>
               <Toolbar>
                  <Box className={classes.title}>
                     <img src={logo} alt="InTheOffice Logo" className={classes.logo} />
                  </Box>
               </Toolbar>
            </AppBar>
            {!error ? (
               <Grid sx={{ mt: 4, p: 2, backgroundColor: '#fff' }} spacing={4} container>
                  {space && loc && (
                     <React.Fragment>
                        <Grid container item xs={12} spacing={2}>
                           <SpaceKiosk
                              lid={url.lid}
                              location={loc}
                              sid={space}
                              cid={authUser.cid}
                           />
                        </Grid>
                     </React.Fragment>
                  )}
                  {coLocs && (
                     <React.Fragment>
                        <Grid item xs={12}>
                           <Typography variant="h4" component="h1">
                              Select the appropriate location:
                           </Typography>
                        </Grid>
                        {Object.entries(coLocs).map(([key, value]) => (
                           <Grid key={key} item xs={3}>
                              <Card sx={{ minWidth: 275 }}>
                                 <CardContent>
                                    <Typography sx={{ fontSize: 16 }} gutterBottom>
                                       {value}
                                    </Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Button
                                       size="small"
                                       onClick={() => handleLidUpdate(key)}
                                    >
                                       Select
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                        ))}
                     </React.Fragment>
                  )}
                  {locSpaces && (
                     <React.Fragment>
                        <Grid item xs={12}>
                           <Typography variant="h4" component="h1">
                              Select the appropriate location:
                           </Typography>
                        </Grid>
                        {locSpaces.map((locSpace) => (
                           <Grid key={locSpace.id} item xs={3}>
                              <Card sx={{ minWidth: 275 }}>
                                 <CardContent>
                                    <Typography sx={{ fontSize: 16 }} gutterBottom>
                                       {locSpace.name}
                                    </Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Button
                                       size="small"
                                       onClick={() => handleSidUpdate(locSpace.id)}
                                    >
                                       Select
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                        ))}
                     </React.Fragment>
                  )}
               </Grid>
            ) : (
               <Grid sx={{ mt: 8, p: 2 }} spacing={4} container>
                  <Grid item xs={12}>
                     <Typography variant="h4" component="h1">
                        Whoops, something went wrong. Try to log in again.
                     </Typography>
                  </Grid>
               </Grid>
            )}
         </AuthProvider>
      </React.Fragment>
   );
};

const KiosksPage = compose(withRouter, withFirebase)(Kiosks);

export default KiosksPage;
