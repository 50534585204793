import React, { useState } from 'react';
import { Grid, Paper, Button, Typography, Box } from '@mui/material';
import Image from 'material-ui-image';
import { DropzoneDialog } from 'material-ui-dropzone';
import { withFirebase } from '../../Firebase';

const AdminSpaces = (props) => {
   const [spaces, setSpaces] = useState(false);
   const [spacesMessage, setSpacesMessage] = useState(false);
   const [hideSpace, setHideSpace] = useState(false);
   const [mapUrl, setMapUrl] = useState(false);
   const [mapUpload, setMapUpload] = useState(false);
   const [locDets, setLocDets] = useState(false);
   async function loadSpaces() {
      console.log(props);
      if (!props.selectedLocation.spacesEnabled) {
         setSpacesMessage('Spaces not enabled');
      } else {
         setSpacesMessage('Loading');
         props.firebase
            .spaces(props.selectedLocation.lid)
            .get()
            .then((results) => {
               if (!results.empty) {
                  let data = {};
                  results.forEach((result) => {
                     console.log(result.id, 'ID');
                     data[result.id] = result.data();
                  });
                  let organisedData = [];
                  let isChild = {};
                  console.log(data);
                  Object.entries(data).map(([key, value]) => {
                     if (value.parent && value.parent !== '') {
                        console.log(data, value.parent);
                        data[value.parent].hasChildren = true;
                        data[value.parent].child = {
                           ...data[value.parent].child,
                           [key]: value,
                        };
                        isChild[key] = true;
                     }
                  });
                  console.log(data);
                  setHideSpace(isChild);
                  setSpaces(data);
                  setSpacesMessage(false);
               } else {
                  setSpacesMessage('No spaces found');
               }
            });
      }
   }
   async function loadMap(key, value) {
      const fullPath = props.firebase.showImage(value.mapPath);
      fullPath
         .getDownloadURL()
         .then(function (url) {
            console.log(url, 'url');
            setMapUrl(url);
         })
         .catch(function (error) {
            alert(error);
            // Uh-oh, an error occurred!
         });
   }
   async function saveUpload(files, type) {
      let path = '';
      console.log(files, locDets);
      Object.entries(files).map(([key, value], i) => {
         path =
            'locations/' +
            props.selectedLocation.lid +
            '/spaceImages/' +
            locDets.sid +
            '/';
         props.firebase.uploadImage(value, path).then(() => {
            props.firebase.getSpace(props.selectedLocation.lid, locDets.sid).set(
               {
                  mapPath: path + value.name,
               },
               { merge: true },
            );
            console.log(path);
            setLocDets(false);
            setMapUpload(false);
         });
      });
   }
   async function deleteSpace(key, value) {
      console.log(key, value);
      try {
         await props.firebase.getSpace(props.selectedLocation.lid, key).delete();
      } catch (error) {
         console.log(error, 'ERROR');
      }
   }
   async function deleteMap(path, id) {
      var desertRef = props.firebase.storedImageRef(path);
      await desertRef
         .delete()
         .then(() => {
            props.firebase.deleteSpaceMap(props.selectedLocation.lid, id);
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }

   const DisplayChildren = (props) => {
      if (props.space.hasChildren) {
         return (
            <React.Fragment>
               {Object.entries(props.space.child).map(([key, value]) => (
                  <Box key={key}>
                     <Box sx={{ m: 1, ml: 8 }} component={Paper}>
                        <Typography>
                           {value.name} - {key}
                        </Typography>
                        {value.mapPath && (
                           <Button onClick={() => loadMap(key, value)}>Map</Button>
                        )}
                        <Button
                           onClick={() => {
                              setMapUpload(true);
                              setLocDets({ sid: key, ...value });
                           }}
                        >
                           Upload Map
                        </Button>
                        <Box sx={{ m: 1, ml: 8 }}>
                           <Button onClick={() => deleteSpace(key, value)}>Delete</Button>
                           <Button onClick={() => deleteMap(value.mapPath, key)}>
                              Delete MAP
                           </Button>
                        </Box>
                     </Box>
                     <DisplayChildren space={value} />
                  </Box>
               ))}
            </React.Fragment>
         );
      } else {
         return null;
      }
   };

   return (
      <Paper sx={{ m: 3, p: 3, width: '100%' }}>
         <DropzoneDialog
            open={mapUpload}
            onSave={(files) => {
               saveUpload(files, 'map');
            }}
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            showPreviews={true}
            maxFileSize={5000000}
            filesLimit={1}
            onClose={() => {
               setMapUpload(false);
               setLocDets(false);
            }}
         />
         <Button onClick={() => loadSpaces()}>LoadSpaces</Button>
         {/* <Button onClick={() => changeWeek(-1)}>Back</Button>
          <Button onClick={() => changeWeek(1)}>Forward</Button>
          <Button onClick={() => fixCapCalcs()}>FixCapCalcs</Button> */}
         <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {spaces && hideSpace && (
               <Box>
                  {Object.entries(spaces).map(([key, value]) => {
                     if (!hideSpace[key]) {
                        return (
                           <Box key={key}>
                              <Box component={Paper}>
                                 {!value.timePeriod.hourly && !value.timePeriod.allDay && (
                                    <>
                                       <Typography>
                                          Bookable times need to be updated
                                       </Typography>
                                    </>
                                 )}
                                 <Typography>
                                    {value.name} - {key}
                                 </Typography>

                                 {value.mapPath && (
                                    <Button onClick={() => loadMap(key, value)}>
                                       Map
                                    </Button>
                                 )}
                                 <Button
                                    onClick={() => {
                                       setMapUpload(true);
                                       setLocDets({ sid: key, ...value });
                                    }}
                                 >
                                    Upload Map
                                 </Button>
                                 <Box sx={{ m: 1, ml: 8 }}>
                                    <Button onClick={() => deleteSpace(key, value)}>
                                       Delete
                                    </Button>
                                    <Button onClick={() => deleteMap(value.mapPath, key)}>
                                       Delete MAP
                                    </Button>
                                 </Box>
                              </Box>
                              <DisplayChildren space={value} />
                           </Box>
                        );
                     }
                  })}
               </Box>
            )}
            {mapUrl && (
               <Box
                  sx={{
                     height: 'auto',
                     width: '50%',
                  }}
               >
                  <Image
                     src={mapUrl}
                     sx={{
                        height: 'auto',
                        width: 'auto',
                        maxHeight: '100%',
                        maxWidth: '100%,',
                     }}
                  />
               </Box>
            )}
         </Box>
         {spacesMessage && <Typography>{spacesMessage}</Typography>}
      </Paper>
   );
};

export default withFirebase(AdminSpaces);
