import { useState } from 'react';
import { useFirebase } from '../../../components/Firebase';
import useFirebaseRequest from '../../../hooks/useFirebaseRequest';
import { useCompany } from '../../../providers';
import { useSnackbar } from 'notistack';
import { getAddedRemovedMembers } from '../utils/convertMembers';

const useDeleteGroup = (
   lid,
   gid,
   options = {
      successMessage: `Success`,
      errorMessage: `Fail`,
      callback: undefined,
   },
) => {
   const firebase = useFirebase();
   const { enqueueSnackbar } = useSnackbar();
   const { company } = useCompany();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const { refetch } = useFirebaseRequest(firebase.locationGroupDetails, {
      args: [lid, gid],
   });

   async function onDelete(data) {
      setLoading(true);
      setError(null);

      let oldGroupDetails = await refetch();
      if (!oldGroupDetails) {
         oldGroupDetails = { ...data, members: {}, admins: {} };
      }

      const members = getAddedRemovedMembers(oldGroupDetails.members, {});
      const admins = getAddedRemovedMembers(oldGroupDetails.admins, {});

      try {
         await firebase.updateGroupMembers(company.cid, gid, admins, members);
         await firebase.deleteGroup(company.cid, lid, gid, data);

         enqueueSnackbar(`${options.successMessage}`, {
            variant: 'success',
            autoHideDuration: 3000,
         });

         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error);
         console.warn(error, 'error - deleting group');

         enqueueSnackbar(`${options.errorMessage}`, {
            variant: 'error',
            autoHideDuration: 3000,
         });
      }
      if (options.callback) {
         options.callback();
      }
   }
   return { loading, onDelete, error };
};

export default useDeleteGroup;
