import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip, Typography } from '@mui/material';
import { withFirebase } from '../../../Firebase';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LocationFilter = (props) => {
   const selectedLocs = props.selectedLocs;
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [optionsKey, setOptionsKey] = React.useState(0);
   async function saveOtherLocations(event, value) {
      props.setSelectedLocs(value);
      try {
         await props.firebase.user(authUser.uid).update({ lastViewDayReport: value });
         let auth = { ...authUser, lastViewDayReport: value };
         localStorage.setItem('authUser', JSON.stringify(auth));
      } catch (error) {
         console.warn(error, 'error setting lawViewDayReport');
      }
   }
   React.useEffect(() => {
      const locationFilter = () => {
         if (
            authUser.lastViewDayReport &&
            authUser.lastViewDayReport.length !== 0 &&
            props.companyLocs
         ) {
            setOptionsKey(optionsKey + 1);
            let lid = props.displayFilter.id;
            if (props.displayFilter.type == 'group') {
               lid = props.displayFilter.lid;
            }

            let locsToLoad = [];
            authUser.lastViewDayReport.map((loc) => {
               if (
                  props.companyLocs.some((obj) => obj.lid === loc.lid && obj.lid !== lid)
               ) {
                  locsToLoad.push(loc);
               }
            });
            props.setSelectedLocs(locsToLoad);
         }
      };
      const cleanup = () => {
         props.setSelectedLocs([]);
      };
      locationFilter();
      return cleanup;
   }, [props.companyLocs, props.displayFilter, props.setSelectedLocs]);

   return (
      <Autocomplete
         key={optionsKey}
         multiple
         id="additional locations checkboxes"
         options={props.companyLocs}
         disableCloseOnSelect
         getOptionLabel={(option) => option.name}
         isOptionEqualToValue={(option, value) => option.lid === value.lid}
         renderTags={(value, getTagProps) => (
            <Typography variant="caption">Locations Selected: {value.length}</Typography>
         )}
         renderOption={(props, option, { selected }) => {
            const isChecked = selectedLocs
               ? selectedLocs.some((obj) => obj.lid === option.lid)
               : false;
            return (
               <li {...props}>
                  <Checkbox
                     icon={icon}
                     checkedIcon={checkedIcon}
                     style={{ marginRight: 8 }}
                     checked={isChecked}
                  />
                  {option.name}
               </li>
            );
         }}
         value={selectedLocs}
         onChange={(event, value) => {
            saveOtherLocations(event, value);
         }}
         style={{ width: 235 }}
         renderInput={(params) => (
            <TextField
               {...params}
               sx={{
                  '& fieldset': {
                     border: '1px solid grey',
                  },
               }}
               size="small"
               label="Add Other Locations"
            />
         )}
      />
   );
};

export default withFirebase(LocationFilter);
