import React, { useEffect, useState } from 'react';
import {
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   useTheme,
   Box,
   IconButton,
} from '@mui/material';
import { alpha } from '@mui/system/colorManipulator';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAppBar } from '../AppLayout/AppLayout';
import { useHistory, useLocation } from 'react-router-dom';
import SideBarMoreMenuItems from './SideBarMoreMenuItems';

function animatedObject(theme, animatedProperty) {
   return theme.transitions.create(animatedProperty, {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
   });
}

const SideBarMoreMenu = ({ navItems }) => {
   const history = useHistory();
   const location = useLocation();
   const theme = useTheme();
   const { menuOpen } = useAppBar();
   const [open, setOpen] = useState(false);
   const [disableHover, setDisableHover] = useState(false);

   useEffect(() => {
      if (menuOpen) {
         setOpen(false);
         setDisableHover(true);
      } else {
         setDisableHover(false);
      }
   }, [menuOpen]);

   const handleClick = () => {
      setOpen(!open);
      setDisableHover(false);
   };

   const CustomTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
   ))(({ theme }) => ({}));

   return (
      <CustomTooltip
         title={!open ? 'More' : <SideBarMoreMenuItems navItems={navItems} />}
         placement="right"
         sx={{
            ...(open && {
               '& .MuiTooltip-tooltip': {
                  backgroundColor: theme.palette.common.white,
                  color: 'rgba(0, 0, 0, 0.87)',
                  boxShadow: theme.shadows[1], // Allow the tooltip to grow based on its content
                  maxWidth: '330px',
                  width: 'auto',
                  height: 'auto',
                  maxHeight: 'none', // Allow the tooltip to grow in height
                  flexWrap: 'wrap',
                  padding: theme.spacing(1),
                  whiteSpace: 'normal',
               },
            }),
         }}
         arrow={!open}
         disableHoverListener={disableHover}
         onClose={() => setOpen(false)}
      >
         <ListItem sx={{ display: 'block', p: 0 }}>
            <ListItemButton
               onClick={handleClick}
               variant="contained"
               sx={{
                  minHeight: 48,
                  justifyContent: 'start',
                  px: [1.5, 2],
                  py: 2,
               }}
            >
               <ListItemIcon
                  sx={{
                     color: 'text',
                     minWidth: theme.spacing(4),
                     mr: menuOpen ? 2 : 0,
                     justifyContent: 'center',
                     transition: animatedObject(theme, 'margin-right'),
                  }}
               >
                  <MoreHorizIcon />
               </ListItemIcon>
               <ListItemText
                  primary="More"
                  sx={{
                     opacity: menuOpen ? '1' : '0',
                     transition: animatedObject(theme, 'opacity'),
                     m: 0,
                     width: 0,
                  }}
               />
            </ListItemButton>
         </ListItem>
      </CustomTooltip>
   );
};

export default SideBarMoreMenu;
