import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const ItoPmBookedNotClickable = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="done-24px" transform="translate(0, -0)">
               <path
                  d="M15.5,31 C20.071838,31 19.3384387,31 19.3384387,31 C19.3384387,31 19.4938713,31 19.4938713,31 C19.4938713,31 41,0 41,0 C41,0 19.7413077,0 15.5,0 C6.93958638,0 0,6.93958638 0,15.5 C0,24.0604136 6.48554758,31 15.5,31 Z"
                  id="Oval"
                  fill={theme.palette.primary.main}
                  transform="translate(20.5, 15.5) rotate(-180) translate(-20.5, -15.5)"
               ></path>
               <polyline
                  id="Path"
                  fill={theme.palette.tableBody.main}
                  fillRule="nonzero"
                  points="22.7128988 19.4057453 18.7925104 15.1242236 17.4857143 16.5513975 22.7128988 22.2600932 33.9140083 10.0271739 32.6072122 8.6"
               ></polyline>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoPmBookedNotClickable;
