import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const OffsitePm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 35 34">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/OffsitePM"
               transform="translate(1.1961, 1.1961)"
               fill={theme.palette.primary.main}
               fillRule="nonzero"
            >
               <path
                  d="M22.3756972,-1.19609541 L23.1960954,-0.624302753 L22.9101991,-0.214103669 L-0.0898009166,32.7858963 L-0.375697247,33.1960954 L-1.19609541,32.6243028 L-0.910199083,32.2141037 L22.0898009,-0.785896331 L22.3756972,-1.19609541 Z M33,23 L33,25 L19,25 L19,23 L33,23 Z M26,5 C29.31,5 32,7.69 32,11 C32,15.5 26,22 26,22 C26,22 20,15.5 20,11 C20,7.69 22.69,5 26,5 Z M26,9 C24.9,9 24,9.9 24,11 C24,12.1 24.9,13 26,13 C27.11,13 28,12.1 28,11 C28,9.9 27.1,9 26,9 Z"
                  id="Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default OffsitePm;
