import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { spaceTypeImage } from '../Spaces/functions';
import { Avatar } from '@material-ui/core';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-force-tabpanel-${index}`}
//       aria-labelledby={`scrollable-force-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children} oki</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

function a11yProps(index) {
   return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
   };
}

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      width: '100%',
   },
   header: {
      boxShadow: '0px 0px',
      backgroundColor: '#fff',
   },
   tabs: {},
   tab: {
      width: 75,
      minWidth: 75,
      fontSize: 'small',
      backgroundColor: '#fff',
      color: '#342E76',
      '&:hover': {
         backgroundColor: '#eeeeee',
      },
   },
}));

const ScrollableTabsButtonForce = (props) => {
   console.log(props, 'child props');
   const classes = useStyles();
   const [items, setItems] = useState({});

   useEffect(() => {
      if (props.children) {
         setItems(props.children);
      }
   }, [props.children]);

   const handleChange = (event, newValue) => {
      console.log(event);
      console.log(newValue, 'newValue');
      props.setSpaceId(newValue);
   };

   return (
      <div className={classes.root}>
         <AppBar position="static" color="default" className={classes.header}>
            <Tabs
               value={false}
               onChange={handleChange}
               variant="scrollable"
               scrollButtons="on"
               textColor="primary"
               aria-label="scrollable force tabs example"
               classes={{ root: classes.tabs }}
            >
               {Object.entries(items).map(([key, value], i) => (
                  <Tab
                     classes={{ root: classes.tab }}
                     label={value.name}
                     icon={<Avatar alt={value.type} src={spaceTypeImage(value.type)} />}
                     value={key}
                     key={key}
                  />
               ))}
            </Tabs>
         </AppBar>
      </div>
   );
};

export default ScrollableTabsButtonForce;
