import { createContext, useContext, useEffect, useState } from 'react';

const contextName = 'Locations';
const LocationsContext = createContext(undefined);

const LocationsProvider = ({ children, data }) => {
   const [locationsLoading, setLocationsLoading] = useState(true);
   const [allLocations, setAllLocations] = useState(null);
   const [archivedLocations, setArchivedLocations] = useState(null);

   function getObjectAsArray(object) {
      if (!object) {
         return;
      }
      // TODO: remove title later on after migration of material/core to mui/material
      // this is needed to make sure reporting works with providers
      return Object.entries(object).reduce(
         (acc, [key, value]) => [...acc, { id: key, name: value, title: value }],
         [],
      );
   }

   function locationsInit() {
      setAllLocations(data.locations);
      setArchivedLocations(data.locationsArchived);
      setLocationsLoading(false);
   }

   useEffect(() => {
      if (data) {
         locationsInit();
      }
   }, [data]);

   return (
      <LocationsContext.Provider
         value={{
            locationsLoading,
            allLocations,
            archivedLocations,
            primaryLocation: allLocations ? getObjectAsArray(allLocations)[0] : null,
            getObjectAsArray,
         }}
      >
         {children}
      </LocationsContext.Provider>
   );
};

export const useLocations = () => {
   const context = useContext(LocationsContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default LocationsProvider;
