import React, { useEffect, useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { withFirebase } from '../../Firebase';

function UnstyledButtonCustom({ title, icon, onClick, ...props }) {
   const [iconUrl, setIconUrl] = useState(null);
   useEffect(() => {
      if (icon) {
         const fullPath = props.firebase.showImage(icon);
         fullPath.getDownloadURL().then((url) => {
            setIconUrl(url);
         });
      } else {
         setIconUrl(null);
      }
   }, [icon]);

   return (
      <Button
         variant="outlined"
         sx={{
            width: 300,
            height: 200,
            backgroundColor: '#fff',
            mt: 4,
            borderRadius: 2,
            '&:hover': { backgroundColor: '#f0f0f0' },
         }}
         onClick={onClick}
      >
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignContent: 'space-between',
            }}
         >
            {iconUrl !== null && (
               <Box>
                  <Box sx={{ mt: 1 }}>
                     <img
                        style={{
                           maxWidth: '100%',
                           maxHeight: '100px',
                           width: 'auto',
                           height: 'auto',
                        }}
                        src={iconUrl}
                     />
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
               </Box>
            )}
            <Box sx={{ fontSize: 'large' }}>{title}</Box>
         </Box>
      </Button>
   );
}

export default withFirebase(UnstyledButtonCustom);
