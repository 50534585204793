import React from 'react';
import {
   CssBaseline,
   ThemeProvider as MuiThemeProvider,
   useMediaQuery,
} from '@mui/material';
import themeLight from '../../themes/themeLight';
import themeDark from '../../themes/themeDark';

const ThemeProvider = ({ children }) => {
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

   const legacyTheme = React.useMemo(
      () => (prefersDarkMode ? themeDark : themeLight),
      [prefersDarkMode],
   );

   return (
      <MuiThemeProvider theme={legacyTheme}>
         <CssBaseline />
         {children}
      </MuiThemeProvider>
   );
};

export default ThemeProvider;
