import React, { useState } from 'react';
import {
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   Typography,
   useTheme,
} from '@mui/material';
import { withFirebase } from '../../Firebase';
import firebase from 'firebase/app';
import { DateToday } from '../../../constants/functions';
import ConfirmationImage from '../Settings/confirmationImage';
import { Interweave } from 'interweave';

const HostFound = ({
   host,
   setStatus,
   setLoading,
   unannouncedVisitor,
   form,
   lid,
   locationName,
   customisations,
   clearForm,
   ...props
}) => {
   const theme = useTheme();
   const [confirmed, setConfirmed] = useState(() => {
      if (customisations !== null && customisations.confirmationStatements?.length > 0) {
         return false;
      } else {
         return true;
      }
   });

   async function alertHost() {
      setLoading(true);
      var email = firebase.functions().httpsCallable('sendgridVisitors-sendgridVisitors');
      try {
         await props.firebase.visitorCheckin(
            form,
            lid,
            DateToday().ref,
            'checkin',
            unannouncedVisitor,
            host,
         );
         let data = {
            checkedIn: false,
            host: true,
            visitorName: form.name,
            visitorEmail: form.email,
            hostName: host.name,
            hostEmail: host.email,
            locationName,
            carReg: form.carReg || false,
            contactNumber: form.contactNumber || false,
         };
         await email(data);
         data.host = false;
         await email(data);
      } catch (error) {
         console.wanr(error, 'error');
      }
      setLoading(false);
      setStatus('step3');
   }

   return (
      <Box sx={{ width: '100%' }}>
         {!confirmed && customisations !== null && (
            <Box>
               <Typography variant="h5" component="h2" align="center">
                  {customisations.confirmationPageTitle !== ''
                     ? customisations.confirmationPageTitle
                     : `Please confirm you have read and understood the policies below.`}
               </Typography>
               {customisations !== null &&
                  customisations.confirmationStatements.length > 0 && (
                     <Box
                        sx={{
                           m: 1,
                           maxHeight: '40vh',
                           overflow: 'scroll',
                           backgroundColor: '#fff',
                           border: '1px solid #ccc',
                           p: 1,
                        }}
                     >
                        {customisations.confirmationStatements.map((statement, index) => (
                           <Box key={index}>
                              {statement.type === 'image' ? (
                                 <ConfirmationImage path={statement.path} />
                              ) : (
                                 <Interweave content={statement.content} />
                              )}
                           </Box>
                        ))}
                     </Box>
                  )}
               <FormControl>
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={confirmed}
                           onChange={() => setConfirmed(!confirmed)}
                        />
                     }
                     label={
                        customisations.confirmationStatementWording !== ''
                           ? customisations.confirmationStatementWording
                           : `I confirm I have read and understood these policies.`
                     }
                  />
               </FormControl>
            </Box>
         )}
         {confirmed && (
            <Box>
               <Typography variant="h5" component="h2" align="center">
                  Your host
               </Typography>
               <Typography variant="h6" align="center" component="h3">
                  is {host.name}
               </Typography>
               <Typography variant="body1" component="p">
                  Please click on submit to alert your host(s).
               </Typography>

               <Button
                  onClick={() => {
                     alertHost();
                  }}
                  disabled={!confirmed}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
               >
                  Submit
               </Button>
               <Button
                  onClick={() => {
                     clearForm();
                  }}
                  fullWidth
                  variant="outlined"
                  color="primary"
               >
                  start again
               </Button>
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(HostFound);
