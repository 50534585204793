import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Box } from '@mui/material';

const BookingLocation = (props) => {
   return (
      <React.Fragment>
         {props.spaces[props.booking.sid].parent && (
            <Grid item xs={12}>
               <Typography display="inline" variant="caption">
                  {props.spaces[props.spaces[props.booking.sid].parent].parent &&
                     props.spaces[
                        props.spaces[props.spaces[props.booking.sid].parent].parent
                     ].name + ' > '}
                  {props.spaces[props.spaces[props.booking.sid].parent].name}
                  {' > '}
               </Typography>
            </Grid>
         )}
         <Grid item xs={10}>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  justifyItems: 'center',
                  mr: 1,
               }}
            >
               {props.spaces[props.booking.sid].backgroundColor && (
                  <Box
                     sx={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: props.spaces[props.booking.sid].backgroundColor,
                        mr: 1,
                     }}
                  ></Box>
               )}
               <Box>{props.spaces[props.booking.sid].name}</Box>
            </Box>
         </Grid>
      </React.Fragment>
   );
};

export default BookingLocation;
