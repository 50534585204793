import React from 'react';
import Chip from '@material-ui/core/Chip';

const BlankChip = (props) => {
   if (props.clickable) {
      return (
         <Chip
            clickable={props.clickable}
            size="medium"
            variant="outlined"
            label={props.value.set}
         />
      );
   } else {
      return (
         <Chip
            clickable={props.clickable}
            size="medium"
            // variant="outlined"
            label={props.value.set}
         />
      );
   }
};

export default BlankChip;
