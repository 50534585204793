import React, { useState } from 'react';
import { withFirebase } from '../../../Firebase';
import firebase from 'firebase/app';

import { Box, Button } from '@mui/material';

import { DateToday } from '../../../../constants/functions';

const ProcessRefresh = ({ setProcessing, processing, ...props }) => {
   async function refreshCompanyOverviews() {
      setProcessing(true);
      const activeCompanies = await props.firebase.db.doc(`usageLogs/companies`).get();
      const companyIds = Object.keys(activeCompanies.data());
      const startDate = await props.firebase.db
         .collection(`usageLogs/companies/overview`)
         .orderBy(firebase.firestore.FieldPath.documentId(), 'desc')
         .limit(1)
         .get()
         .then((results) => {
            return results.docs[0].id;
         });

      const endDate = DateToday().ref;
      const batch = props.firebase.db.batch();
      const overallData = await processCoData(companyIds, startDate, endDate);
      Object.entries(overallData).forEach(([date, data]) => {
         batch.set(props.firebase.db.doc(`usageLogs/companies/overview/${date}`), data, {
            merge: true,
         });
      });
      try {
         await batch.commit();
      } catch (error) {
         console.error('Error updating company overview:', error);
      }
      setProcessing(false);
   }

   async function processCoData(companyIds, startDate, endDate) {
      const companyData = {};

      for (const cid of companyIds) {
         try {
            const results = await props.firebase.db
               .collection(`usageLogs/companies/company/${cid}/dates`)
               .where(firebase.firestore.FieldPath.documentId(), '>=', startDate)
               .where(firebase.firestore.FieldPath.documentId(), '<=', endDate)
               .get();

            results.forEach((doc) => {
               let dailyUsers = 0;
               let appViews = 0;
               let teamsViews = 0;

               Object.entries(doc.data()).forEach(([uid, views]) => {
                  if (uid !== 'totalPageViews') {
                     dailyUsers++;
                     Object.entries(views).forEach(([time, page]) => {
                        if (time !== 'pageViews') {
                           if (page.includes('msteams')) {
                              teamsViews++;
                           } else if (page.includes('app')) {
                              appViews++;
                           }
                        }
                     });
                  }
               });

               if (!companyData[doc.id]) {
                  companyData[doc.id] = {
                     overallData: {
                        totalPageViews: 0,
                        dailyUsers: 0,
                        appViews: 0,
                        teamsViews: 0,
                        companyNo: 0,
                     },
                  };
               }

               companyData[doc.id][cid] = {
                  totalPageViews: doc.data().totalPageViews,
                  dailyUsers,
                  appViews,
                  teamsViews,
                  average: doc.data().totalPageViews / dailyUsers,
               };

               companyData[doc.id].overallData.totalPageViews +=
                  doc.data().totalPageViews;
               companyData[doc.id].overallData.dailyUsers += dailyUsers;
               companyData[doc.id].overallData.appViews += appViews;
               companyData[doc.id].overallData.teamsViews += teamsViews;
               companyData[doc.id].overallData.average =
                  companyData[doc.id].overallData.totalPageViews /
                  companyData[doc.id].overallData.dailyUsers;
               companyData[doc.id].overallData.companyNo++;
            });
         } catch (error) {
            console.error(`Error fetching data for company ${cid}:`, error);
         }
      }

      return companyData;
   }

   return (
      <Box>
         <Button disabled={processing} onClick={() => refreshCompanyOverviews()}>
            Refresh
         </Button>
      </Box>
   );
};

export default withFirebase(ProcessRefresh);
