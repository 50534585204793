import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewUser from './newUser';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '70vh',
      maxWidth: '100%',
      marginTop: theme.spacing(5),
   },
}));

var msTeams = false;
if (
   window.location.hostname.includes('ngrok.io') ||
   window.location.hostname.includes('msteams')
) {
   msTeams = true;
}

const DomainExistsForm = (props) => {
   const classes = useStyles();
   const [signInType, setSignInType] = useState('');
   const [personDetails, setPersonDetails] = useState(false);

   async function loadPersonIntoCompany(user, result, pendingCred) {
      let newUser = {};
      let newUserRecord = {};
      result.forEach((doc) => {
         var newPassword =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
         newUser = {
            ...doc.data(),
            uid: doc.id,
            email: doc.data().email,
            emailVerified: true,
            displayName: doc.data().name.firstName + ' ' + doc.data().name.lastName,
            password: newPassword,
            marketing: { optIn: false },
            roles: { USER: 'STAFF' },
            status: { statusId: 'active' },
         };
         if (doc.data().roles) {
            newUser.roles = {
               USER: 'STAFF',
               ...doc.data().roles,
            };
         }
         newUserRecord = { ...doc.data(), uid: doc.id };
      });
      var deleteNewAccount = firebase.functions().httpsCallable('deleteUser');
      var mergeAccounts = firebase.functions().httpsCallable('SSOMergeUser');
      var convertAccount = firebase.functions().httpsCallable('linkNonVerifiedUser');
      await deleteNewAccount(user.uid)
         .then(async () => {
            await mergeAccounts(newUser).then(async () => {
               await props.firebase
                  .doSignInWithEmailAndPassword(newUser.email, newUser.password)
                  .then(async function (result) {
                     return result.user.linkWithCredential(pendingCred);
                  })
                  .then(async function () {
                     var status = { statusId: 'active' };
                     await props.firebase
                        .getPerson(newUserRecord.cid, newUserRecord.uid)
                        .set({ status }, { merge: true });
                     return;
                  })
                  .then(() => {
                     console.log(msTeams, 'MS Teams');
                     if (msTeams) {
                        props.history.push(ROUTES.MSTEAMSAUTH);
                     } else {
                        props.history.push(ROUTES.DASHBOARD);
                     }
                  });
            });
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }

   async function saveNewPersonIntoCompany(mSCreds, cid) {
      let displayName = mSCreds.user.displayName.split(' ');
      let firstName = '';
      let lastName = '';
      if (displayName.length === 1) {
         firstName = displayName[0];
      } else if (displayName.length === 2) {
         firstName = displayName[0];
         lastName = displayName[1];
      } else if (displayName.length > 2) {
         firstName = displayName[0];
         for (var i = 1; i < displayName.length; i++) {
            if (i !== displayName.length - 1) {
               lastName += displayName[i] + ' ';
            } else {
               lastName += displayName[i];
            }
         }
      }
      let user = {
         uid: mSCreds.user.uid,
         email: mSCreds.user.email,
         name: { firstName, lastName },
         cid: cid.cid,
         favouriteSpaces: [],
         gid: [],
         lid: [],
         marketing: { optIn: false },
         roles: { USER: 'STAFF' },
         status: { statusId: 'active' },
      };
      var newUserAccount = firebase.functions().httpsCallable('newCompanyAndUserDetails');
      await newUserAccount(user).then(async () => {
         return;
      });
      return user;
   }

   useEffect(() => {
      const checkEmailandDomainRules = async () => {
         if (props.userState === 'domainExists') {
            if (props.cid) {
               var allowed = props.cid.allowedDomains.includes(props.emailDomain);
               if (allowed) {
                  await props.firebase
                     .emailExists(props.cid.cid, props.mSCreds.user.email)
                     .get()
                     .then(async (result) => {
                        if (!result.empty) {
                           setSignInType('noProviders');
                           loadPersonIntoCompany(
                              props.mSCreds.user,
                              result,
                              props.mSCreds.credential,
                           );
                        } else {
                           setSignInType('noProviders');
                           await saveNewPersonIntoCompany(props.mSCreds, props.cid).then(
                              (user) => {
                                 setPersonDetails(user);
                                 setSignInType('newUser');
                              },
                           );
                        }
                     })
                     .catch((error) => {
                        console.log("can't search if email exists ", error);
                     });
               } else {
                  var deleteNewAccount = firebase.functions().httpsCallable('deleteUser');
                  await deleteNewAccount(props.mSCreds.user.uid)
                     .then(async () => {
                        setSignInType('notAllowed');
                        return;
                     })
                     .catch((error) => {
                        console.log(error, 'error');
                     });
               }
            }
         }
      };
      checkEmailandDomainRules();
   }, [props.emailDomain, props.cid, props.mSCreds, props.userState]);

   return (
      <Grid container item xs={12} align="center">
         <Grid item xs={12} align="center">
            {signInType === 'noProviders' && (
               <React.Fragment>
                  <Typography variant="h5" component="h2" paragraph align="center">
                     Almost there...
                  </Typography>
                  <CircularProgress />
                  <Typography variant="body1" paragraph align="center">
                     We've found your company details and we're finishing your
                     configuration.
                  </Typography>
               </React.Fragment>
            )}
            {signInType === 'notAllowed' && (
               <Container component="main" maxWidth="xs">
                  <Typography variant="h5" component="h2" paragraph align="center">
                     Oops, your company does not have single sign-on enabled.
                  </Typography>
                  <Typography variant="body1" paragraph align="center">
                     Please speak to your IT administrator to add you as a user or to
                     enable single sign on for your domain.
                  </Typography>
               </Container>
            )}
            {signInType === 'newUser' && (
               <NewUser
                  mSCreds={props.mSCreds}
                  user={personDetails}
                  signInType={signInType}
                  msTeams={props.msTeams}
               />
            )}
         </Grid>
      </Grid>
   );
};

const DomainExists = compose(withRouter, withFirebase)(DomainExistsForm);
export default withFirebase(DomainExists);
