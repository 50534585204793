import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChairPMBookedHovered = (props) => (
   <SvgIcon {...props} viewBox="0 0 24 24">
      <g
         id="ITO-Design-System"
         stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd"
      >
         <g id="Chair-pm-booked-hover">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <path
               d="M17,8 L17,13 L10.8861983,13 L17,8 Z M19,10 L22,10 L22,13 L19,13 Z M4,19.2043575 C7.11280314,16.4014525 8.66920471,15 8.66920471,15 C8.66920471,15 12.4461365,15 20,15 L20,21 C18,21 17,21 17,21 C17,21 17,18 17,18 C17,18 7.01853068,18 7.01291969,18 C7.00430656,18 7.00430656,19 7.01291969,21 L4,21 L4,19.2043575 Z"
               id="Combined-Shape"
               fill="#C8C8C8"
            ></path>
            <path
               d="M2,10 L5,10 L5,13 L2,13 Z M4,15 L8.66920471,15 L4,19.2043575 L4,15 Z M9,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,8 L17,8 C12.9241322,11.3333333 10.8861983,13 10.8861983,13 C10.8861983,13 9.59079887,13 7,13 L7,5 C7,3.8954305 7.8954305,3 9,3 Z"
               id="Combined-Shape"
               fill="#476B43"
            ></path>
         </g>
      </g>
   </SvgIcon>
);

export default ChairPMBookedHovered;
