import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { withFirebase } from '../Firebase';
import { Box, IconButton, Tooltip, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const EachBooking = (props) => {
   const [owner, setOwner] = useState('');

   useEffect(() => {
      const getOwnerName = async () => {
         if (props.data && props.cid) {
            const person = await props.firebase
               .companyPerson(props.cid, props.data.ownerId)
               .get();
            let name = '';
            if (person.exists) {
               name = person.data().name.firstName + ' ' + person.data().name.lastName;
            } else {
               name = 'Unknown';
            }
            setOwner(name);
         }
      };
      getOwnerName();
   }, [props.data, props.cid]);

   async function deteleBooking() {
      let data = {
         user: {
            cid: props.cid,
            uid: props.data.ownerId,
         },
         space: {
            [props.bid]: {
               sid: props.spaceKey,
               lid: props.lid,
            },
         },
         date: {
            ref: props.dateRef,
         },
      };
      try {
         console.log('bookings');
         await props.firebase.cancelBookedSpaceForUser(data);
      } catch (error) {
         console.log(error, 'error');
      }
   }

   return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 2 }}>
         <Box
            sx={{
               width: '80%',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-evenly',
            }}
         >
            <Box sx={{ width: '33%' }}>
               <Typography variant="body1" align="left">
                  {owner}
               </Typography>
            </Box>
            <Box sx={{ width: '33%' }}>
               <Typography variant="body1" align="center">
                  {props.data.type}
               </Typography>
            </Box>
            <Box sx={{ width: '33%' }}>
               <Typography variant="body1" align="right">
                  seats:{props.data.seats}
               </Typography>
            </Box>
         </Box>
         <Box sx={{ width: '20%', justifyContent: 'right', display: 'flex' }}>
            <Tooltip title="Remove" placement="top">
               <span>
                  <IconButton
                     disabled={props.editDisabled}
                     onClick={() => deteleBooking()}
                  >
                     <DeleteIcon
                        fontSize="small"
                        color={props.editDisabled ? 'disabled' : 'secondary'}
                     />
                  </IconButton>
               </span>
            </Tooltip>
         </Box>
      </Box>
   );
};

const Bookings = (props) => {
   const [editDisabled, setEditDisabled] = useState(true);

   return (
      <Paper>
         <Box sx={{ m: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
               <Box>
                  <Typography variant="h6">Upcoming Bookings</Typography>
               </Box>
               <Box>
                  <Button onClick={() => setEditDisabled(!editDisabled)}>
                     {editDisabled ? 'Enable' : 'Disabled'} Edit
                  </Button>
               </Box>
            </Box>
            {props.futureBookings ? (
               Object.entries(props.futureBookings).map(([key, value], i) => {
                  return (
                     <Box
                        key={key}
                        sx={{
                           display: 'flex',
                           width: '100%',
                           flexDirection: 'column',
                           m: 2,
                        }}
                     >
                        <Box>
                           <Typography variant="body1">
                              {key.slice(6, 8)}/{Number(key.slice(4, 6)) + 1}/
                              {key.slice(0, 4)}
                           </Typography>
                        </Box>
                        <Box>
                           {Object.entries(value.bookings).map(([bid, booking], i) => {
                              return (
                                 <EachBooking
                                    editDisabled={editDisabled}
                                    dateRef={key}
                                    key={bid}
                                    bid={bid}
                                    data={booking}
                                    lid={props.locationKey}
                                    cid={props.cid}
                                    firebase={props.firebase}
                                    spaceKey={props.spaceKey}
                                 />
                              );
                           })}
                        </Box>
                     </Box>
                  );
               })
            ) : (
               <Box>
                  <Typography variant="body1">None</Typography>
               </Box>
            )}
         </Box>
      </Paper>
   );
};

export default withFirebase(Bookings);
