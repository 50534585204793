import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AwayAm = (props) => (
   <SvgIcon {...props} viewBox="0 0 41 31">
      <g
         id="ITO-Design-System"
         stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd"
      >
         <g
            id="status/AWAYAM"
            transform="translate(-0.2369, 0)"
            fill="#DB3D28"
            fillRule="nonzero"
         >
            <path
               d="M15,5 C9.48,5 5,9.48 5,15 C5,20.52 9.48,25 15,25 C20.52,25 25,20.52 25,15 C25,9.48 20.52,5 15,5 Z M20,14 L20,16 L10,16 L10,14 L20,14 Z"
               id="Shape"
            ></path>
         </g>
      </g>
   </SvgIcon>
);

export default AwayAm;
