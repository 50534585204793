import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeatureSwitch from '../Billing/featureSwitch';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import OfficeRequests from './requests';
import Meetings from './meetings';
import firebase from 'firebase/app';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
         width: '85%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   title: {
      marginTop: theme.spacing(4),
   },
   label: {
      marginRight: theme.spacing(1),
   },
   wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
   },
   buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}));

const DrawerForm = (props) => {
   console.log(props);
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [interaction, setInteraction] = useState('');
   const [people, setPeople] = useState([]);

   useEffect(() => {
      const resetState = () => {
         if (!props.visitorsDrawer) {
            setInteraction('');
            setPeople([]);
         }
      };
      resetState();
   }, [props.visitorsDrawer]);

   useEffect(() => {
      switch (props.type) {
         case 'meetings':
            setInteraction('Meeting details');
            break;
         case 'requests':
            setInteraction('Invite Colleagues to the Office');
            break;
         default:
            setInteraction('Loading...');
      }
   }, [props.type]);

   useEffect(() => {
      const loadPeople = async () => {
         if (props.form.type !== '') {
            props.setForm({
               ...props.form,
               owner: {
                  id: user.uid,
                  name: user.name.firstName + ' ' + user.name.lastName,
               },
               cid: user.cid,
            });
            await props.firebase
               .locationPeople(props.form.locationId, user.cid)
               .orderBy('name.lastName')
               .orderBy('name.firstName')
               .get()
               .then((result) => {
                  let officePeople = [];
                  if (!result.empty) {
                     result.forEach((doc) => {
                        var person = {
                           name:
                              doc.data().name.firstName + ' ' + doc.data().name.lastName,
                           email: doc.data().email,
                           id: doc.id,
                           routine: false,
                        };
                        if (props.location.routine) {
                           person.routine = doc.data().routine;
                        }
                        officePeople.push(person);
                     });
                     setPeople(officePeople);
                  }
               });
         }
      };
      loadPeople();
   }, [props.form.type]);

   async function handleSubmit() {
      props.setSaving(true);
      var d = ('0' + props.form.date.getDate()).slice(-2);
      var m = ('0' + props.form.date.getMonth()).slice(-2);
      var date = d + '/' + m + '/' + props.form.date.getFullYear();
      if (props.form.type === 'requests') {
         try {
            if (props.form.forceStatusChange) {
               await props.firebase.setStatusBulk(props.form);
            }
            var email = firebase.functions().httpsCallable('emails-customEmail');
            Object.entries(props.form.internalParticipants).map(([key, value]) => {
               let data = {
                  name: value.name,
                  email: value.email,
                  status: value.status,
                  date: date,
                  owner: props.form.owner.name,
                  comments: props.form.comments,
               };
               if (value.status === 'none' && value.routine) {
                  data.status = value.routine;
               }
               if (props.form.forceStatusChange) {
                  if (data.status === 'none' || data.status === '-') {
                     data.type = 'statusChanged';
                     email(data)
                        .then(() => {
                           console.log(data, 'email sent');
                        })
                        .catch((error) => {
                           console.log(error, 'email sending error');
                        });
                  }
                  if (data.status === 'wfh') {
                     data.type = 'statusNotChanged';
                     email(data)
                        .then(() => {
                           console.log(data, 'email sent');
                        })
                        .catch((error) => {
                           console.log(error, 'email sending error');
                        });
                  }
               } else {
                  data.type = 'noChange';
                  email(data)
                     .then(() => {
                        console.log(data, 'email sent');
                     })
                     .catch((error) => {
                        console.log(error, 'email sending error');
                     });
               }
            });
            props.processClose();
         } catch (error) {
            console.log(error, 'error');
         }
      }
   }

   return (
      <div className={classes.root}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <Typography
                  className={classes.title}
                  align="center"
                  component="h1"
                  variant="h5"
               >
                  {interaction}
               </Typography>
            </Grid>
            {props.form.type === 'requests' && (
               <OfficeRequests
                  people={people}
                  form={props.form}
                  setForm={props.setForm}
                  location={props.location}
                  visitorsDrawer={props.visitorsDrawer}
               />
            )}
            {props.form.type === 'meetings' && (
               <Meetings
                  people={people}
                  form={props.form}
                  setForm={props.setForm}
                  location={props.location}
                  visitorsDrawer={props.visitorsDrawer}
               />
            )}
            <Grid
               item
               container
               direction="row"
               justifyContent="center"
               alignItems="center"
               xs={12}
               spacing={2}
            >
               <Grid item>
                  <div className={classes.wrapper}>
                     <Button
                        variant="contained"
                        disabled={props.saving}
                        color="primary"
                        onClick={() => handleSubmit()}
                     >
                        {props.type === 'requests' ? 'Invite' : 'Save'}
                     </Button>
                     {props.saving && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                     )}
                  </div>
               </Grid>
               <Grid item>
                  <Button variant="outlined" color="primary" onClick={props.processClose}>
                     Discard
                  </Button>
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
};

export default withFirebase(DrawerForm);
