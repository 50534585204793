import React, { useState } from 'react';
import { compose } from 'recompose';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import MsSymbol from '../../assets/mssymbol.svg';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   mssubmit: {
      margin: theme.spacing(1, 0, 2),
      borderWidth: 1,
      borderColor: '#8C8C8C',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
   },
   mssignin: {
      margin: theme.spacing(0, 2),
      height: '34px',
   },
}));

const NotLoggedInForm = (props) => {
   const classes = useStyles();
   const location = queryString.parse(window.location.search);
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [checked, setChecked] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const Toast = () => {
      return (
         <div>
            <Collapse in={checked} collapsedHeight={0}>
               <Alert severity="error">
                  <strong>Error - </strong>
                  {errorMessage}
               </Alert>
            </Collapse>
         </div>
      );
   };

   async function handleSubmit(event) {
      event.preventDefault();

      await props.firebase
         .doSignInWithEmailAndPassword(email, password)
         .then(() => {
            // props.history.push(ROUTES.SCAN_IN);
            setTimeout(function () {
               window.location.reload(true);
            }, 1000);
         })
         .catch((error) => {
            setErrorMessage(error.message);
            setChecked(true);
         });
   }

   return (
      <Container component="main" maxWidth="xs">
         <div className={classes.paper}>
            <Toast />
            <Avatar className={classes.avatar}>
               <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
               Check into {location.locName}
            </Typography>
            <Typography variant="body1">
               Please enter your login details in order to check into {location.locName}
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
               <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                  autoFocus
               />
               <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onInput={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
               />
               <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
               >
                  Submit
               </Button>
               <Button
                  fullWidth
                  href={
                     ROUTES.AUTH +
                     '?redirect=scanin&loc=' +
                     location.loc +
                     '&locName=' +
                     location.locName
                  }
                  variant="outlined"
                  className={classes.mssubmit}
                  style={{
                     maxHeight: '41px',
                     minHeight: '41px',
                     borderWidth: 1,
                     borderColor: '#8C8C8C',
                  }}
               >
                  <div
                     style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                     }}
                  >
                     <Icon className={classes.mssignin}>
                        <img src={MsSymbol} />
                     </Icon>
                     <Typography>Sign in with Microsoft</Typography>
                  </div>
               </Button>
            </form>
         </div>
      </Container>
   );
};

const NotLoggedIn = compose(withRouter, withFirebase)(NotLoggedInForm);
export default withFirebase(NotLoggedIn);
