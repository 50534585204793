import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { withFirebase } from '../Firebase';
import 'date-fns';
import { Calendar, Views } from 'react-big-calendar';
import MeetingForm from './meetingForm';
import { myEventsList, roundToNearestHour } from './utils';
import Item from './Item';
import { components, localizer } from './utils/CalendarComponents';
import SpacesContextProvider from './spaces.context';
import { DateToday } from '../../constants/functions';
import ReadOnlyMeetingForm from './detailsDialog/readOnlyMeetingForm.jsx';
import { useAuth } from '../../providers/AuthProvider';
import ActionButtons from './detailsDialog/actionButtons.jsx';

const DetailsDialog = (props) => {
   const theme = useTheme();
   const { authUser } = useAuth();
   const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
   const [events, setEvents] = useState(false);
   const [defaultDate, setDefaultDate] = useState(false);
   const [kiosk, setKiosk] = useState(false);
   const [hostError, setHostError] = useState({ error: false, message: '' });
   const [ownerError, setOwnerError] = useState({ error: false, message: '' });
   const [form, setForm] = useState(false);
   const [spaceBookings, setSpaceBookings] = useState(false);
   const [error, setError] = useState({ error: false, message: '' });
   useEffect(() => {
      if (props.detailsOpen && props.spaceSchedule) {
         let entries = [];
         if (props.spaceSchedule.bookings) {
            Object.entries(props.spaceSchedule.bookings).map(([key, value]) => {
               if (props.existing && key !== props.booking.id) {
                  let start = new Date(value.start.seconds * 1000);
                  let end = new Date(value.end.seconds * 1000);
                  if (value.checkInTime && value.start > value.checkInTime) {
                     start = new Date(value.checkInTime.seconds * 1000);
                  }
                  if (value.checkOutTime && value.end > value.checkOutTime) {
                     end = new Date(value.checkOutTime.seconds * 1000);
                  }
                  entries.push({
                     ...value,
                     id: key,
                     start,
                     end,
                     saved: true,
                  });
               } else if (!props.existing) {
                  let start = new Date(value.start.seconds * 1000);
                  let end = new Date(value.end.seconds * 1000);
                  if (value.checkInTime && value.start > value.checkInTime) {
                     start = new Date(value.checkInTime.seconds * 1000);
                  }
                  if (value.checkOutTime && value.end > value.checkOutTime) {
                     end = new Date(value.checkOutTime.seconds * 1000);
                  }
                  entries.push({
                     ...value,
                     id: key,
                     start,
                     end,
                     saved: true,
                  });
               }
            });
            if (props.existing) {
               entries.push({
                  id: props.booking.id,
                  title: props.booking.title,
                  start: props.booking.start,
                  end: props.booking.end,
               });
            }
         }
         setSpaceBookings(entries);
         setEvents(entries);
      }
   }, [props.detailsOpen, props.spaceSchedule]);

   const handleClose = () => {
      props.setDetailsOpen(false);
      setDefaultDate(false);
      setEvents(false);
      setSpaceBookings(false);
      setForm(false);
      setError({ error: false, message: '' });
   };

   const clickRef = useRef(null);

   useEffect(() => {
      /**
       * What Is This?
       * This is to prevent a memory leak, in the off chance that you
       * teardown your interface prior to the timed method being called.
       */
      return () => {
         window.clearTimeout(clickRef?.current);
      };
   }, []);

   const onSelecting = useCallback(
      (range) => {
         /**
          * Here we are waiting 250 milliseconds (use what you want) prior to firing
          * our method. Why? Because both 'click' and 'doubleClick'
          * would fire, in the event of a 'doubleClick'. By doing
          * this, the 'click' handler is overridden by the 'doubleClick'
          * action.
          */
         window.clearTimeout(clickRef?.current);
         //load the times into the form
         clickRef.current = window.setTimeout(() => {
            setError({ error: false, message: '' });
            setForm({ ...form, start: range.start, end: range.end });
            if (props.existing) {
               Object.assign(spaceBookings[spaceBookings.length - 1], {
                  start: range.start,
                  end: range.end,
               });
               setEvents(spaceBookings);
            } else {
               setEvents((prev) => [
                  ...spaceBookings,
                  { start: range.start, end: range.end, title: form.title },
               ]);
            }
         }, 250);
      },
      [form],
   );

   useEffect(() => {
      if (props.detailsOpen) {
         if (DateToday().ref === props.date.ref) {
            setDefaultDate(new Date());
         } else {
            let day = new Date(
               props.date.year,
               props.date.month,
               props.date.day,
               9,
               0,
               0,
               0,
            );
            setDefaultDate(day);
         }
      }
   }, [props.detailsOpen]);

   const submitBooking = async () => {
      if (form.host.uid !== '') {
         try {
            await props.firebase.bookMeetingForUser(
               props.location.id,
               props.date.ref,
               props.space.sid,
               form,
               authUser,
            );
            handleClose();
         } catch (error) {
            console.warn(error, 'error');
         }
      } else {
         setHostError({ error: true, message: 'You must select a Host' });
      }
   };

   useEffect(() => {
      if (props.bookingForm && props.space && defaultDate) {
         if (props.existing) {
            let booking = props.booking;
            setForm({
               id: booking.id,
               title: booking.title,
               host: booking.host,
               owner: booking.owner,
               start: booking.start,
               end: booking.end,
               seats: booking.seats,
               internalParticipants: booking.internalParticipants,
               externalGuests: booking.externalGuests,
               catering: booking.catering,
               cid: booking.cid,
            });
         } else {
            setForm({
               id: null,
               title: '',
               host: {
                  name: `${props.bookingForm.user.name.firstName} ${props.bookingForm.user.name.lastName}`,
                  uid: props.bookingForm.user.uid,
               },
               owner: {
                  name: `${props.bookingForm.user.name.firstName} ${props.bookingForm.user.name.lastName}`,
                  uid: props.bookingForm.user.uid,
               },
               start: roundToNearestHour(defaultDate),
               end: roundToNearestHour(new Date(defaultDate.getTime() + 30 * 60 * 1000)),
               seats: props.space.seats,
               internalParticipants: null,
               externalGuests: null,
               catering: '',
               cid: props.bookingForm.user.cid,
            });
         }
      }
   }, [props.bookingForm, props.space, defaultDate]);

   useEffect(() => {
      if (props.kiosk && props.detailsOpen && defaultDate) {
         if (props.existing) {
            let booking = props.booking;
            setForm({
               id: booking.id,
               title: booking.title,
               host: booking.host,
               owner: booking.owner,
               start: booking.start,
               end: booking.end,
               seats: booking.seats,
               internalParticipants: booking.internalParticipants,
               externalGuests: booking.externalGuests,
               catering: booking.catering,
               cid: booking.cid,
            });
         } else {
            setForm({
               id: null,
               title: '',
               host: {
                  name: '',
                  uid: '',
               },
               owner: {
                  name: '',
                  uid: '',
               },
               start: roundToNearestHour(defaultDate),
               end: roundToNearestHour(new Date(defaultDate.getTime() + 30 * 60 * 1000)),
               seats: props.space.seats,
               internalParticipants: null,
               externalGuests: null,
               catering: '',
               cid: props.cid,
            });
         }
         setKiosk(true);
      }
   }, [props.kiosk, props.detailsOpen, props.existing, props.booking, defaultDate]);

   useEffect(() => {
      let overlap = false;
      if (props.spaceSchedule?.bookingNo > 0) {
         Object.entries(props.spaceSchedule.bookings).map(([key, value]) => {
            if (form.id !== key) {
               if (
                  form.start < value.end.toDate() &&
                  form.start >= value.start.toDate()
               ) {
                  overlap = true;
               }
               if (form.end <= value.end.toDate() && form.end > value.start.toDate()) {
                  overlap = true;
               }
               if (value.start.toDate() > form.start && value.end.toDate() < form.end) {
                  overlap = true;
               }
            }
         });
      }
      if (overlap) {
         setError({ error: true, message: 'There is already a booking at this time' });
      }
      if (form.start > form.end) {
         setError({
            error: true,
            message: 'The start time cannot be before the end time',
         });
      }
      if (form.end < new Date()) {
         setError({ error: true, message: 'You cannot create a booking in the past.' });
      }
      if (form.start < form.end && form.end > new Date() && !overlap) {
         setError({ error: false, message: '' });
      }
   }, [form]);

   async function cancelSpace() {
      let data = {
         user: {
            cid: authUser.cid,
            uid: form.owner.uid,
         },

         space: {
            [props.booking.id]: {
               timePeriod: { hourly: true },
               ...props.booking,
            },
         },
         date: props.date,
      };
      try {
         await props.firebase.cancelBookedSpaceForUser(data, authUser);
         handleClose();
      } catch (error) {
         console.warn(error, 'error');
      }
   }

   function scrollCurrentTime() {
      const time = new Date();
      const newTime = new Date(
         props.date.year,
         props.date.month,
         props.date.day,
         time.getHours(),
         0,
         0,
         0,
      );
      if (
         time.getFullYear() == newTime.getFullYear() &&
         time.getMonth() == newTime.getMonth() &&
         time.getDay() == newTime.getDay()
      ) {
         return newTime;
      } else {
         return new Date(props.date.year, props.date.month, props.date.day, 9, 0, 0, 0);
      }
   }
   return (
      <React.Fragment>
         <SpacesContextProvider value={{ ...props, defaultDate, form }}>
            {defaultDate && events && props.date && (
               <Dialog
                  fullWidth
                  open={props.detailsOpen}
                  // open={true}
                  onClose={() => handleClose()}
                  disableEnforceFocus
                  maxWidth="md"
                  sx={{ maxWidth: 'md', margin: 'auto' }}
               >
                  <Box>
                     <DialogTitle
                        alignItems="center"
                        sx={{ fontSize: isXsDown ? 'medium' : 'large' }}
                     >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                           <Box>
                              {props.space.name} -{' '}
                              {props.date.dayName +
                                 ', ' +
                                 props.date.day +
                                 ' ' +
                                 props.date.monthName +
                                 ' ' +
                                 props.date.year}{' '}
                              - {props.space.seatsAvailable}/{props.space.seats} seats
                              available
                           </Box>
                           <Box>
                              <IconButton onClick={handleClose}>
                                 <CloseOutlinedIcon />
                              </IconButton>
                           </Box>
                        </Box>
                     </DialogTitle>
                     <DialogContent>
                        <Grid container spacing={2}>
                           <Grid item xs={12} sm={6}>
                              <Calendar
                                 selectable={props.detailsData.edit}
                                 tooltipAccessor={null}
                                 resizable={true}
                                 views={{ month: true, work_week: true, day: true }}
                                 defaultDate={defaultDate}
                                 defaultView={Views.DAY}
                                 localizer={localizer}
                                 events={events}
                                 startAccessor="start"
                                 endAccessor="end"
                                 style={{
                                    height: isXsDown ? '40vh' : 500,
                                 }}
                                 onSelecting={onSelecting}
                                 components={components}
                                 toolbar={false}
                                 dayLayoutAlgorithm="no-overlap"
                                 scrollToTime={scrollCurrentTime()}
                                 step={15}
                                 eventPropGetter={(event) => {
                                    const backgroundColor = event.saved
                                       ? '#342E76'
                                       : props.detailsData.edit
                                       ? '#32BFC7'
                                       : '#3C33A0';
                                    const cursor = props.detailsData.edit
                                       ? 'pointer'
                                       : 'default';
                                    const fontSize = event.saved
                                       ? 'normal'
                                       : props.detailsData.edit
                                       ? 'normal'
                                       : 'large';
                                    return {
                                       style: { backgroundColor, cursor },
                                    };
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              {form && props.detailsData.edit ? (
                                 <Box sx={{ width: '100%' }}>
                                    <MeetingForm
                                       setForm={setForm}
                                       form={form}
                                       setEvents={setEvents}
                                       spaceBookings={spaceBookings}
                                       error={error}
                                       setError={setError}
                                       kiosk={kiosk}
                                       hostError={hostError}
                                       setHostError={setHostError}
                                       ownerError={ownerError}
                                       setOwnerError={setOwnerError}
                                       existing={props.existing}
                                    />
                                 </Box>
                              ) : (
                                 <Box sx={{ width: '100%' }}>
                                    <ReadOnlyMeetingForm form={form} />
                                 </Box>
                              )}
                           </Grid>
                        </Grid>
                     </DialogContent>
                     <DialogActions sx={{ flexDirection: 'column' }}>
                        <ActionButtons
                           detailsData={props.detailsData}
                           error={error}
                           existing={props.existing}
                           submitBooking={submitBooking}
                           cancelSpace={cancelSpace}
                           form={form}
                           setDetailsData={props.setDetailsData}
                        />
                     </DialogActions>
                  </Box>
               </Dialog>
            )}
         </SpacesContextProvider>
      </React.Fragment>
   );
};

export default withFirebase(DetailsDialog);
