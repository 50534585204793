import React from 'react';

import { TableCell, useTheme } from '@mui/material';
import SingleCells from '../../singleCells';

const MultiWeekSingleCell = (props) => {
   const theme = useTheme();
   //need the if statement to apply the conditional border formatting.
   if (props.date && props.dayNo !== undefined) {
      return (
         <TableCell
            align="center"
            sx={{
               pl: 0,
               pr: 0,
               borderLeft:
                  props.date.weekDay === 1 && props.dayNo !== 0
                     ? `1px solid rgba(224, 224, 224, 1)`
                     : '0px',
            }}
         >
            <SingleCells
               spaces={props.spaces}
               setStatusDrawerOpen={props.setStatusDrawerOpen}
               setSpaceData={props.setSpaceData}
               spacesEnabled={props.spacesEnabled}
               routineEnabled={props.routineEnabled}
               location={props.location}
               date={props.date}
               editPrevious={props.editPrevious}
               row={props.row}
               dayNo={props.dayNo}
               space={props.row.space}
               miniChips={props.view === 'multiweek' ? true : false}
            />
         </TableCell>
      );
   } else {
      return null;
   }
};

export default MultiWeekSingleCell;
