import React, { useState } from 'react';
import { Typography, TextField, Button, Autocomplete } from '@mui/material';

const HostAutocomplete = ({ name, setName, nameError, setNameError, nameList }) => {
   const options = Object.entries(nameList).map(([email, name]) => ({
      email,
      name,
   }));

   const [inputValue, setInputValue] = useState('');

   return (
      <Autocomplete
         options={options}
         getOptionLabel={(option) => `${option.name} (${option.email})`}
         filterOptions={(options, { inputValue }) => {
            if (inputValue.length < 3) {
               return [];
            }
            return options.filter(
               (option) =>
                  option.email.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.name.toLowerCase().includes(inputValue.toLowerCase()),
            );
         }}
         renderInput={(params) => (
            <TextField
               {...params}
               variant="outlined"
               margin="normal"
               required
               fullWidth
               id="name"
               label="Name / Email"
               name="name"
               value={name}
               onInput={(e) => {
                  setName(e.target.value);
                  setInputValue(e.target.value);
               }}
               error={nameError.status}
               helperText={nameError.text}
            />
         )}
         onChange={(event, newValue) => {
            setName(newValue ? newValue.email : '');
         }}
         noOptionsText={
            inputValue.length < 3
               ? `Start typing to find your host (${
                    3 - inputValue.length
                 } more characters)`
               : 'No options'
         }
         fullWidth
      />
   );
};

export default HostAutocomplete;
