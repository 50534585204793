import React, { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FeatureSwitch from '../Billing/featureSwitch';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditGroupModal from './editGroupModal';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';
import {
   Box,
   FormGroup,
   FormControlLabel,
   Switch,
   TextField,
   Typography,
} from '@mui/material';
// import AddGroup from './addGroup';

const useStyles = makeStyles((theme) => ({
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   paper: {
      backgroundColor: theme.palette.background.paper,

      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
}));

const AddGroupModal = (props) => {
   const classes = useStyles();
   const groupReset = {
      admins: {},
      companyPublic: false,
      count: 0,
      global: false,
      hidden: false,
      lid: props.displayFilter.id,
      locationPublic: false,
      name: '',
      type: 'group',
   };
   const [newGroup, setNewGroup] = useState(groupReset);
   const [checked, setChecked] = useState(false);

   const handleChange = () => {
      console.log('clicked');
      setChecked(!checked);
   };

   function CreateNewGroup(event) {
      event.preventDefault();
      async function addGroup() {
         var gid = await props.firebase.locationGroups().doc();
         // await props.firebase.locationGroups(props.displayFilter.id).doc().set({name : newGroupName });
         await props.firebase.saveGroup(props.displayFilter.id, gid.id, newGroup);
      }
      addGroup().then(function () {
         setChecked(true);
         props.setAlertMessage({
            type: 'success',
            message: newGroup.name + ' has been created.',
         });
      });
      setNewGroup(groupReset);
      props.setOpenModal(false);
   }
   const handleModalClose = () => {
      props.setOpenModal(false);
      setNewGroup(groupReset);
   };

   return (
      <Modal
         open={props.openModal}
         onClose={handleModalClose}
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         className={classes.modal}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
      >
         <Fade in={props.openModal}>
            <div className={classes.paper}>
               {props.groupsEnabled ? (
                  <Box
                     sx={{
                        display: 'flex',
                        p: 2,
                        flexDirection: 'column',
                        justifyContent: 'center',
                     }}
                  >
                     <Typography variant="h5" component="h2">
                        Add a group
                     </Typography>
                     <TextField
                        id="group-name"
                        label="Group name"
                        value={newGroup.name}
                        onInput={(e) =>
                           setNewGroup({ ...newGroup, name: e.target.value })
                        }
                        fullWidth
                     />
                     <FormGroup>
                        <FormControlLabel
                           sx={{ mt: 2, mb: 2 }}
                           disabled
                           control={
                              <Switch checked={checked} onChange={() => handleChange()} />
                           }
                           label="Global"
                        />
                     </FormGroup>
                     <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button
                           color="primary"
                           variant="contained"
                           aria-label="save"
                           edge="end"
                           className={classes.margin}
                           onClick={CreateNewGroup}
                        >
                           Save
                        </Button>
                        <Button
                           aria-label="close"
                           className={classes.margin}
                           onClick={handleModalClose}
                        >
                           Discard
                        </Button>
                     </Box>
                  </Box>
               ) : (
                  <FeatureSwitch feature="groups" />
               )}
            </div>
         </Fade>
      </Modal>
   );
};

export default withFirebase(AddGroupModal);
