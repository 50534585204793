import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../../Firebase';

import {
   Box,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material';
import { getMultipleWeeks } from '../../../../constants/functions';
import { useTheme } from '@mui/material/styles';
import MultiWeekRow from './multiWeekRow';
import TableHeadCells from './tableHeadCells';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import IconButton from '@mui/material/IconButton';
import ScheduleOptions from '../scheduleOptions';
import CapCells from './capCells';
import { sortArrayOfPeopleObjectsByFirstName } from '../../../../utils/sortingUtils';

const MultiWeek = (props) => {
   const [weekDates, setWeekDates] = useState(false);
   const theme = useTheme();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [capToggle, setCapToggle] = useState(false);
   const [spaces, setSpaces] = useState(null);
   const [multiLocations, setMultiLocations] = useState(false);
   const [editPrevious, setEditPrevious] = useState(false);
   const [disableEditPrevious, setDisableEditPrevious] = useState(false);
   const [routineIndex, setRoutineIndex] = useState(false);
   const [lastPerson, setLastPerson] = useState(null);
   const [firstPerson, setFirstPerson] = useState(null);
   const [people, setPeople] = useState(false);
   const [page, setPage] = useState(1);
   const [company, setCompany] = useState(false);
   const [peopleChunk, setPeopleChunk] = useState(false);
   const [chunk, setChunk] = useState(0);
   const [needsUpgrade, setNeedsUpgrade] = useState(false);
   const [coLocations, setCoLocations] = useState(null);
   const [archivedLocations, setArchivedLocations] = useState(null);
   useEffect(() => {
      if (props.day && props.view) {
         const getWeeks = async () => {
            const weeks = await getMultipleWeeks(props.day.fullDate, props.view);
            setWeekDates(weeks);
         };
         getWeeks();
         return () => {
            // Reset weekDates to false when the component unmounts or when the dependencies change
            setWeekDates(false);
         };
      }
   }, [props.day, props.view]);
   useEffect(() => {
      if (
         props.view === 'multiweek' &&
         props.location &&
         !props.location.offsiteEnabled
      ) {
         setNeedsUpgrade(true);
      } else {
         setNeedsUpgrade(false);
      }
   }, [props.view, props.location]);

   useEffect(() => {
      if (props.location.routine) {
         const unsubscribe = props.firebase
            .routineIndex(props.displayFilter.cid)
            .onSnapshot((result) => {
               if (result.exists) {
                  setRoutineIndex(result.data());
               }
            });
         return () => unsubscribe();
      }
   }, [props.displayFilter, props.location]);

   useEffect(() => {
      if (props.displayFilter && props.location) {
         let query = props.firebase.companyPeople(props.displayFilter.cid);
         if (props.displayFilter.type === 'group') {
            query = props.firebase.locationGroupDetails(
               props.displayFilter.lid,
               props.displayFilter.id,
            );
         } else if (props.displayFilter.type === 'location') {
            query = props.firebase.locationPeopleIndex(props.displayFilter.id);
         }

         const unsubscribe = query.onSnapshot((result) => {
            let peopleList = [];
            let needsChunking = false;
            if (result.exists) {
               let data = {};
               if (props.displayFilter.type === 'group') {
                  data = result.data().members;
               } else {
                  data = result.data();
               }
               Object.entries(data).map(([key, value]) => {
                  if (key !== authUser.uid) {
                     var person = { uid: key, ...value, cid: authUser.cid };
                     if (routineIndex && routineIndex[key]) {
                        person.routine = routineIndex[key].routine;
                     }
                     peopleList.push(person);
                  }
               });
               peopleList = sortArrayOfPeopleObjectsByFirstName(peopleList);
               // if peopleList.length is > 25 then chunk the array into 25 person chunks
               if (peopleList.length > 25) {
                  needsChunking = true;
                  let chunkedPeople = [];
                  let i = 0;
                  let j = 0;
                  while (i < peopleList.length) {
                     chunkedPeople[j] = peopleList.slice(i, i + 25);
                     i += 25;
                     j++;
                  }
                  peopleList = chunkedPeople;
               }

               if (data[authUser.uid]) {
                  console.log(data, 'LOGGED IN USER');
                  var person = {
                     uid: authUser.uid,
                     ...data[authUser.uid],
                     cid: authUser.cid,
                  };
                  if (routineIndex && routineIndex[authUser.uid]) {
                     person.routine = routineIndex[authUser.uid].routine;
                  }
                  if (needsChunking) {
                     peopleList[chunk].unshift(person);
                  } else {
                     peopleList.unshift(person);
                  }
               }
            }
            if (needsChunking) {
               setPeopleChunk(true);
            } else {
               setPeopleChunk(false);
            }
            setPeople(peopleList);
         });
         return () => {
            unsubscribe();
            setPeople(false);
         };
      }
   }, [props.displayFilter, props.location, routineIndex]);

   useEffect(() => {
      const loadSpaces = async () => {
         if (props.spacesEnabled) {
            let lid = props.displayFilter.id;
            if (props.displayFilter.type == 'group') {
               lid = props.displayFilter.lid;
            }
            await props.firebase
               .spaces(lid)
               .get()
               .then((result) => {
                  if (!result.empty) {
                     let documents = {};
                     result.forEach((doc) => {
                        documents[doc.id] = doc.data();
                     });
                     setSpaces(documents);
                  } else {
                     setSpaces(null);
                  }
               })
               .catch((error) => {
                  console.warn(error, 'Error loading spaces');
               });
         }
      };
      loadSpaces();
   }, [props.spacesEnabled, props.displayFilter]);

   useEffect(() => {
      const loadCompany = async () => {
         props.firebase
            .company(authUser.cid)
            .get()
            .then((result) => {
               setCompany({ cid: result.id, ...result.data() });
               setCoLocations(result.data().locations);
               if (result.data().locationsArchived) {
                  setArchivedLocations(result.data().locationsArchived);
               }
               if (result.data().multisite) {
                  setMultiLocations(result.data().features.multisite);
               }
            });
      };
      loadCompany();
   }, []);
   useEffect(() => {
      if (props.location) {
         let type = 'user';
         if (authUser?.roles?.ADMIN === 'ADMIN') {
            type = 'admin';
         }
         if (props.location.permissions?.editPrevious?.[type] === 'removed') {
            setDisableEditPrevious(true);
         }
         if (props.location.permissions?.editPrevious?.[type] === 'alwaysOn') {
            setEditPrevious(true);
         }
         if (
            props.location.permissions?.editPrevious?.[type] === 'alwaysOnPrime' &&
            props.location.id === authUser.lid[0]
         ) {
            setEditPrevious(true);
         }
      }
   }, [props.location]);

   return (
      <Box>
         {people ? (
            <TableContainer sx={{ height: '70vh' }}>
               <Table stickyHeader size="small">
                  <TableHead>
                     <TableRow>
                        <TableCell sx={{ position: 'sticky', zIndex: 999, left: 0 }}>
                           {/* <PersonSearch /> */}
                           <ScheduleOptions
                              permissions={props.location.permissions}
                              lid={props.location.id}
                              editPrevious={editPrevious}
                              setEditPrevious={setEditPrevious}
                              disableEditPrevious={disableEditPrevious}
                              setDisableEditPrevious={setDisableEditPrevious}
                           />
                        </TableCell>
                        {weekDates &&
                           weekDates.map((weekDay, key) => {
                              if (!props.hideWeekends) {
                                 return (
                                    <TableCell
                                       key={weekDay.day}
                                       align="center"
                                       sx={{
                                          pl: 1.25,
                                          pr: 1.25,
                                          borderLeft:
                                             weekDay.weekDay === 1 && key !== 0
                                                ? `1px solid rgba(224, 224, 224, 1)`
                                                : '0px',
                                       }}
                                    >
                                       <TableHeadCells
                                          weekDay={weekDay}
                                          view={props.view}
                                          setDay={props.setDay}
                                          setView={props.setView}
                                       />
                                    </TableCell>
                                 );
                              } else if (weekDay.weekDay !== 6 && weekDay.weekDay !== 0) {
                                 return (
                                    <TableCell
                                       key={weekDay.day}
                                       align="center"
                                       sx={{
                                          pl: 1.25,
                                          pr: 1.25,
                                          borderLeft:
                                             weekDay.weekDay === 1 && weekDay.day !== 0
                                                ? `1px solid rgba(224, 224, 224, 1)`
                                                : '0px',
                                       }}
                                    >
                                       <TableHeadCells
                                          weekDay={weekDay}
                                          view={props.view}
                                          setDay={props.setDay}
                                          setView={props.setView}
                                       />
                                    </TableCell>
                                 );
                              }
                           })}
                     </TableRow>
                     <TableRow key="2" sx={{ position: 'sticky', zIndex: 998, top: 61 }}>
                        <TableCell sx={{ position: 'sticky', zIndex: 999, left: 0 }}>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                              }}
                           >
                              <Typography
                                 variant="caption"
                                 sx={{
                                    position: 'sticky',
                                    // backgroundColor: '#fff',
                                    zIndex: 999,
                                 }}
                              >
                                 {capToggle ? 'People ITO' : 'Capacity'}
                              </Typography>
                              <IconButton
                                 aria-label="Toggle Value"
                                 onClick={() => {
                                    setCapToggle(!capToggle);
                                 }}
                                 sx={{ marginLeft: '16px' }}
                                 size="small"
                              >
                                 <SwapHorizIcon titleAccess="Toggle Capacity or People" />
                              </IconButton>
                           </div>
                        </TableCell>
                        <CapCells
                           capToggle={capToggle}
                           location={props.location}
                           weekDates={weekDates}
                           view={props.view}
                           hideWeekends={props.hideWeekends}
                           mini={props.view === 'week' ? true : false}
                           micro={props.view === 'multiweek' ? true : false}
                        />
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {people && peopleChunk && chunk > 0 && (
                        <TableRow>
                           <TableCell colSpan={weekDates.length + 1}>
                              <Typography
                                 variant="caption"
                                 sx={{ textAlign: 'center', cursor: 'pointer' }}
                                 onClick={() => {
                                    setChunk(chunk - 1);
                                 }}
                              >
                                 Load Previous
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {people && peopleChunk && (
                        <>
                           {people[chunk].map((person) => (
                              <TableRow key={person.uid}>
                                 <MultiWeekRow
                                    spaces={spaces}
                                    routineEnabled={props.routineEnabled}
                                    spacesEnabled={props.spacesEnabled}
                                    setSpaceData={props.setSpaceData}
                                    setStatusDrawerOpen={props.setStatusDrawerOpen}
                                    weekDates={weekDates}
                                    person={person}
                                    location={props.location}
                                    editPrevious={editPrevious}
                                    coLocations={coLocations}
                                    archivedLocations={archivedLocations}
                                    multiLocations={multiLocations}
                                    hideWeekends={props.hideWeekends}
                                    view={props.view}
                                    company={company}
                                 />
                              </TableRow>
                           ))}
                        </>
                     )}
                     {people && !peopleChunk && (
                        <>
                           {people.map((person) => (
                              <TableRow key={person.uid}>
                                 <MultiWeekRow
                                    spaces={spaces}
                                    routineEnabled={props.routineEnabled}
                                    spacesEnabled={props.spacesEnabled}
                                    setSpaceData={props.setSpaceData}
                                    setStatusDrawerOpen={props.setStatusDrawerOpen}
                                    weekDates={weekDates}
                                    person={person}
                                    location={props.location}
                                    editPrevious={editPrevious}
                                    coLocations={coLocations}
                                    archivedLocations={archivedLocations}
                                    multiLocations={multiLocations}
                                    hideWeekends={props.hideWeekends}
                                    view={props.view}
                                    company={company}
                                 />
                              </TableRow>
                           ))}
                        </>
                     )}
                     {people && peopleChunk && chunk < people.length - 1 && (
                        <TableRow>
                           <TableCell colSpan={weekDates.length + 1}>
                              <Typography
                                 variant="caption"
                                 sx={{ textAlign: 'center', cursor: 'pointer' }}
                                 onClick={() => {
                                    setChunk(chunk + 1);
                                 }}
                              >
                                 Load More
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         ) : (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
               <Typography variant="caption">Loading</Typography>
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(MultiWeek);
