import React, { useEffect, useState } from 'react';
import Seat from './compressedSeat';

const ShowSeats = (props) => {
   const [bookings, setBookings] = useState(false);
   function loadSplitDay(loadedSeats, booking, index) {
      let jointIndex = index;
      Object.entries(loadedSeats).some(([key, value]) => {
         if (value[booking.type] === null) {
            jointIndex = key;
            return true;
         }
      });
      if (!loadedSeats[jointIndex]) {
         loadedSeats[jointIndex] = {
            AM: null,
            PM: null,
            multi: true,
         };
      }
      loadedSeats[jointIndex][booking.type] = booking;
      return loadedSeats;
   }
   useEffect(() => {
      if (props.space) {
         let totalSeats = props.space.seats;
         let index = 0;
         let loadedSeats = {};
         if (props.space.bookings) {
            props.space.bookings.map((booking) => {
               if (booking.type === 'All day') {
                  if (booking.seat > 1) {
                     let seatNo = 1;
                     while (seatNo < booking.seat) {
                        loadedSeats[index] = booking;
                        index++;
                        seatNo++;
                     }
                     loadedSeats[index] = booking;
                  } else {
                     loadedSeats[index] = booking;
                  }
               } else {
                  if (booking.seat > 1) {
                     let seatNo = 1;
                     while (seatNo < booking.seat) {
                        loadedSeats = loadSplitDay(loadedSeats, booking, index);
                        index++;
                        seatNo++;
                     }
                     loadedSeats = loadSplitDay(loadedSeats, booking, index);
                  } else {
                     loadedSeats = loadSplitDay(loadedSeats, booking, index);
                  }
               }

               index++;
            });
         }
         while (Object.keys(loadedSeats).length < totalSeats) {
            loadedSeats[index] = null;
            index++;
         }
         setBookings(loadedSeats);
      }
      return () => {
         setBookings({});
      };
   }, [props.space.bookings]);
   return (
      <React.Fragment>
         {bookings &&
            Object.entries(bookings).map(([index, booking]) => {
               if (booking !== null) {
                  if (booking.multi) {
                     return (
                        <Seat
                           key={index}
                           space={props.space}
                           booked={true}
                           booking={booking}
                           multi={true}
                           day={props.day}
                           userData={props.userData}
                           setSpaceMap={props.setSpaceMap}
                           setMap={props.setMap}
                        />
                     );
                  } else if (booking.owner) {
                     return (
                        <Seat
                           key={index}
                           space={props.space}
                           booked={true}
                           booking={booking}
                           multi={false}
                           day={props.day}
                           userData={props.userData}
                           setSpaceMap={props.setSpaceMap}
                           setMap={props.setMap}
                        />
                     );
                  } else {
                     return (
                        <Seat
                           key={index}
                           space={props.space}
                           booked={true}
                           booking={false}
                           multi={false}
                           day={props.day}
                           userData={props.userData}
                           setSpaceMap={props.setSpaceMap}
                           setMap={props.setMap}
                        />
                     );
                  }
               } else {
                  return (
                     <Seat
                        key={index}
                        space={props.space}
                        booked={false}
                        booking={false}
                        multi={false}
                        day={props.day}
                        userData={props.userData}
                        setSpaceMap={props.setSpaceMap}
                        setMap={props.setMap}
                     />
                  );
               }
               // }
            })}
      </React.Fragment>
   );
};

export default ShowSeats;
