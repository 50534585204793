import { useState, useEffect } from 'react';

const useFirebaseRequest = (
   func = () => {},
   options = { args: [], sortFunction: undefined, dependencies: undefined },
) => {
   const { args, sortFunction, dependencies } = options;
   const [data, setData] = useState(undefined);
   const [error, setError] = useState(undefined);

   if (!Array.isArray(args)) {
      throw new Error(`typeof args is ${typeof args}, expected an array`);
   }

   if (typeof sortFunction !== 'undefined' && typeof sortFunction !== 'function') {
      throw new Error(
         `typeof sortFunction is ${typeof sortFunction}, expected a function`,
      );
   }

   function dataHandler(response) {
      if (sortFunction) {
         const sortedResponse = sortFunction(response);

         setData(sortedResponse);
         return sortedResponse;
      } else {
         setData(response);
         return response;
      }
   }

   async function getData() {
      setError(undefined);
      try {
         const response = await func(...args)
            .get()
            .then((result) =>
               result.exists ? dataHandler(result.data()) : dataHandler(null),
            );
         return response;
      } catch (err) {
         console.error(err);
         setError(err);
      }
   }

   useEffect(getData, dependencies || []);

   return {
      loading: data === undefined && error === undefined,
      data,
      error,
      refetch: getData,
   };
};

export default useFirebaseRequest;
