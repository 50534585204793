import React, { useEffect, useState, withStyles } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
   formControl: {
      width: 200,
   },
   blueTable: {
      backgroundColor: '#342E76',
   },
   paper: {
      width: '100%',
      padding: theme.spacing(2),
   },
   input: {
      marginLeft: theme.spacing(1),
      flex: 1,
   },
   iconButton: {
      padding: 10,
   },
}));

const ReportingPanel = (props) => {
   const classes = useStyles();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [repStatus, setRepStatus] = useState(false);

   useEffect(() => {
      const loadReporting = async () => {
         if (props.expanded === 'reporting') {
            console.log(props.location, 'reporting');
            if (props.location.reporting) {
               setRepStatus(true);
            }
         }
         return;
      };
      loadReporting();
   }, [props.expanded]);

   async function handleChange(event) {
      setRepStatus(event.target.checked);
      await props.firebase
         .location(authUser.lid[0])
         .set({ reporting: event.target.checked }, { merge: true });
   }

   return (
      <React.Fragment>
         <Grid container spacing={3}>
            <Grid container item xs={12}>
               <Grid item xs={12} sm={8}>
                  <Typography variant="body1">
                     View reports detailing how your employees and teams are using
                     intheOffice.
                  </Typography>
                  {!repStatus && (
                     <Typography variant="caption">
                        Reporting is a premium feature. Enable reporting starts your 7 day
                        free trial, after which the fee of £1 per user per month will be
                        added to your subscription fee.
                     </Typography>
                  )}
               </Grid>
               <Grid item xs={12} sm={2}>
                  <FormControl component="fieldset">
                     <FormGroup aria-label="position" row>
                        <FormControlLabel
                           value="top"
                           control={
                              <Switch
                                 color="primary"
                                 checked={repStatus}
                                 onChange={(event) => {
                                    handleChange(event);
                                 }}
                              />
                           }
                           label="Enable"
                           labelPlacement="top"
                        />
                     </FormGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(ReportingPanel);
