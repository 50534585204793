import React, { useState } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../providers/AuthProvider';
import * as EmailValidator from 'email-validator';
import firebase from 'firebase/app';

const VisitorNotifications = () => {
   const { authUser } = useAuth();
   const [ccField, setCcField] = useState(() => {
      if (authUser.ccField) {
         let fields = authUser.ccField.split(';').map((item) => item.trim());
         return fields;
      } else {
         return null;
      }
   });
   const [addField, setAddField] = useState(false);
   const [newEmail, setNewEmail] = useState('');
   const [error, setError] = useState(false);

   async function saveCCField() {
      let valid = EmailValidator.validate(newEmail);
      if (!valid) {
         setError({ error: true, message: 'Invalid email address' });
      } else {
         setError({ error: false, message: '' });
         const batch = firebase.firestore().batch();
         const userRef = firebase.firestore().doc(`users/${authUser.uid}`);
         const userCoRef = firebase
            .firestore()
            .doc(`company/${authUser.cid}/people/${authUser.uid}`);
         let data = '';
         if (ccField !== null) {
            ccField.map((item) => {
               data += item + ';';
            });
         }
         data += newEmail;
         batch.set(userRef, { ccField: data }, { merge: true });
         batch.set(userCoRef, { ccField: data }, { merge: true });
         try {
            await batch.commit();
            if (ccField === null) {
               setCcField([newEmail]);
            } else {
               setCcField([...ccField, newEmail]);
            }
            setNewEmail('');
            setAddField(false);
         } catch (error) {
            setError({
               error: true,
               message: 'Something went wrong, please try again later',
            });
            console.warn(error, 'error - saving group');
         }
      }
   }

   async function deleteCCField(key) {
      const batch = firebase.firestore().batch();
      const userRef = firebase.firestore().doc(`users/${authUser.uid}`);
      const userCoRef = firebase
         .firestore()
         .doc(`company/${authUser.cid}/people/${authUser.uid}`);
      let field = ccField.filter((_, index) => index !== key);
      if (field.length > 0) {
         let data = '';
         field.map((item) => {
            data += item + ';';
         });
         batch.set(userRef, { ccField: data }, { merge: true });
         batch.set(userCoRef, { ccField: data }, { merge: true });
         setCcField(field);
      } else {
         batch.set(
            userRef,
            { ccField: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
         batch.set(
            userCoRef,
            { ccField: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
         setCcField(null);
      }

      try {
         await batch.commit();
      } catch (error) {
         console.warn(error, 'error - saving group');
      }
   }

   return (
      <Box>
         <Typography variant="h6">Visitors Notifications</Typography>
         <Typography variant="body2" paragraph>
            {`By default, you will receive an email notification to ${authUser.email} when a visitor checks in.`}
         </Typography>
         <Typography variant="body2">
            Add additional email recipients to receive your notification below.
         </Typography>

         {ccField !== null &&
            ccField.map((item, key) => (
               <Box
                  sx={{
                     width: '100%',
                     display: 'flex',
                     flexDirection: 'row',
                     justfyContent: 'center',
                     mt: 1,
                     mb: 1,
                  }}
                  key={key}
               >
                  <Typography variant="body2" sx={{ width: '80%' }}>
                     {item}
                  </Typography>
                  <IconButton size="small" onClick={() => deleteCCField(key)}>
                     <DeleteIcon />
                  </IconButton>
               </Box>
            ))}
         {addField ? (
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justfyContent: 'center',
                  mt: 2,
                  mb: 2,
               }}
            >
               <TextField
                  onChange={(e) => setNewEmail(e.target.value)}
                  value={newEmail}
                  label="CC Email"
                  sx={{ width: '75%', mr: 2 }}
                  size="small"
                  helperText={error.message}
                  error={error.error}
               />
               <Button
                  size="small"
                  variant="contained"
                  sx={{ width: '20%' }}
                  onClick={() => saveCCField()}
               >
                  Save
               </Button>
            </Box>
         ) : (
            <Button variant="outlined" size="small" onClick={() => setAddField(true)}>
               Add CC address
            </Button>
         )}
      </Box>
   );
};
export default VisitorNotifications;
