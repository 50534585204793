import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const BlankMiniPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 26 29">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="blank-mini-pm" fill={theme.palette.primary.main} fillRule="nonzero">
               <path
                  d="M26,0 L26,24.862325 C26,27.1474998 24.209139,29 22,29 L4,29 C1.790861,29 0,27.1474998 0,24.862325 L0,17.1041844 L26,0 Z M25,1.5093518 L1.0024487,17.6635581 L1,24.862325 C1,26.5149961 2.24891996,27.8659263 3.82372721,27.9603133 L4,27.9655813 L22,27.9655813 C23.6568542,27.9655813 25,26.5762061 25,24.862325 L25,1.5093518 Z M20.75,17.5 L20.75,19.5 L12.75,19.5 L12.75,17.5 L20.75,17.5 Z"
                  id="Path-2"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default BlankMiniPm;
