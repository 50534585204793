import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const ItoPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 35 34">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="status/ITOPM" transform="translate(1.1961, 1.1961)">
               <line
                  x1="22.5"
                  y1="-0.5"
                  x2="-0.5"
                  y2="32.5"
                  id="Line-6"
                  stroke={theme.palette.primary.main}
                  strokeLinecap="square"
               ></line>
               <polygon
                  id="Path"
                  fill={theme.palette.primary.main}
                  fillRule="nonzero"
                  points="22.7128988 20.4057453 18.7925104 16.1242236 17.4857143 17.5513975 22.7128988 23.2600932 33.9140083 11.0271739 32.6072122 9.6"
               ></polygon>
            </g>
         </g>
      </SvgIcon>
   );
};

export default ItoPm;
