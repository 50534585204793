import React from 'react';
import { Chip } from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Wfh = (props) => {
   const openDrawer = () => {
      props.setStatusDrawerOpen(true);
      props.setSpaceData(props.data);
   };

   if (props.miniChips) {
      return (
         <Chip
            size="small"
            sx={props.styles}
            variant="outlined"
            clickable={props.clickable}
            label={<HomeOutlined color="primary" />}
            color="primary"
         />
      );
   } else {
      if (props.clickable) {
         return (
            <Chip
               size="medium"
               sx={{
                  '& .MuiChip-label': {
                     pl: 1,
                  },
               }}
               clickable={props.clickable}
               label={<HomeOutlined sx={{ mt: 0.5 }} />}
               color="primary"
               deleteIcon={<MoreVertIcon />}
               variant="outlined"
               onDelete={() => openDrawer()}
            />
         );
      } else {
         return (
            <Chip
               size="medium"
               clickable={props.clickable}
               label={<HomeOutlined sx={{ mt: 0.5 }} />}
               color="primary"
               variant="outlined"
            />
         );
      }
   }
};
export default Wfh;
