import { createContext, useContext, useState } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';

const contextName = 'Page';
const PageContext = createContext(undefined);

const PageLayout = ({ data = {}, storedData, children, sx }) => {
   const [cachedData, setCachedData] = useState(storedData);
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

   function updateData(newData) {
      setCachedData({ ...cachedData, ...newData });
   }

   return (
      <Container
         maxWidth={false}
         sx={{ height: '100%', overflowY: 'auto' }}
         className="outer"
      >
         <Container className="inner" maxWidth="lg" sx={{ px: 0, py: 3, ...sx }}>
            <PageContext.Provider
               value={{
                  cachedData,
                  ...data,
                  updateData,
                  isMobile,
               }}
            >
               {children}
            </PageContext.Provider>
         </Container>
      </Container>
   );
};

export const usePage = () => {
   const context = useContext(PageContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default PageLayout;
