import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import SpaceMap from '../../../Spaces/spaceMap';
import DisplaySpaces from './displaySpaces';
import { withFirebase } from '../../../Firebase';

const DayBookings = (props) => {
   const theme = useTheme();
   const [loading, setLoading] = useState(true);
   const [type, setType] = useState('all');
   const [allSpaces, setAllSpaces] = useState(false);
   const [spaces, setSpaces] = useState(false);
   const [people, setPeople] = useState(false);
   const [map, setMap] = useState(false);
   const [spaceMap, setSpaceMap] = useState(false);
   const [authStatus, setAuthStatus] = useState(false);
   const [userData, setUserData] = useState(false);
   const [bking, setBking] = useState(false);

   useEffect(() => {
      if (props.tab === 1 && props.displayFilter) {
         let query = props.firebase.companyPeople(props.displayFilter.cid);
         if (props.displayFilter.type === 'group') {
            query = props.firebase.locationGroupDetails(
               props.displayFilter.lid,
               props.displayFilter.id,
            );
         } else if (props.displayFilter.type === 'location') {
            query = props.firebase.locationPeopleIndex(props.displayFilter.id);
         }
         const unsubscribe = query.onSnapshot((result) => {
            let peopleList = [];
            peopleList.push({
               uid: props.user.uid,
               cid: props.user.cid,
               name: props.user.name,
            });
            if (result.exists) {
               let data = {};
               if (props.displayFilter.type === 'group') {
                  data = result.data().members;
               } else {
                  data = result.data();
               }
               Object.entries(data).map(([key, value]) => {
                  var person = { uid: key, ...value, cid: props.user.cid };
                  peopleList.push({ uid: key, ...person });
               });
            }
            peopleList.sort((a, b) =>
               a.name.firstName.toLowerCase() > b.name.firstName.toLowerCase() ? 1 : -1,
            );
            setPeople(peopleList);
         });
         return () => unsubscribe();
      }
   }, [props.tab, props.displayFilter]);
   useEffect(() => {
      if ((props.tab === 1, props.displayFilter && props.day && props.user)) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .userScheduleDay(props.displayFilter.cid, props.user.uid, props.day.ref)
            .onSnapshot((result) => {
               var usrData = {
                  user: {
                     uid: props.user.uid,
                     lid: props.user.lid,
                     cid: props.displayFilter.cid,
                  },
                  date: props.day,
                  space: 'empty',
                  offsiteLocation: null,
                  status: { set: '-' },
               };
               if (props.user.routine && props.user.routine[props.day.weekDay] !== '-') {
                  usrData.status.routine = props.user.routine[props.day.weekDay];
               }
               var status = { set: '-' };
               if (result.exists) {
                  usrData.status = { ...result.data(), set: result.data().status };
                  status = { ...result.data(), set: result.data().status };
               }
               setAuthStatus(status);
               setUserData(usrData);
            });
         return () => unsubscribe();
      }
   }, [props.displayFilter, props.day]);
   function deepEqual(obj1, obj2) {
      if (obj1 === obj2) {
         return true;
      }

      if (
         typeof obj1 !== 'object' ||
         obj1 === null ||
         typeof obj2 !== 'object' ||
         obj2 === null
      ) {
         return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
         return false;
      }

      for (let key of keys1) {
         if (!obj2.hasOwnProperty(key)) {
            return false;
         }

         if (!deepEqual(obj1[key], obj2[key])) {
            return false;
         }
      }

      return true;
   }

   useEffect(() => {
      if (props.tab === 1 && props.displayFilter && people) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .locationDayScheduleDate(lid, props.day.ref)
            .onSnapshot((result) => {
               let seats = {};
               let bk = {};
               if (result.exists) {
                  Object.entries(result.data()).map(([uid, uidBookings]) => {
                     if (uidBookings.bookingNo && uidBookings.bookingNo > 0) {
                        Object.entries(uidBookings.bookings).map(([bid, details]) => {
                           if (!bk[details.space]) {
                              bk[details.space] = [];
                           }
                           bk[details.space].push({
                              bid,
                              ...details,
                              lid,
                              sid: details.space,
                              owner: findObjectByKey(people, 'uid', uid),
                           });
                        });
                     }
                  });
               }
               // Compare current bking state with the new bk value
               if (!deepEqual(bk, bking)) {
                  setBking(bk);
               }
            });

         return () => {
            unsubscribe();
            setBking(false);
         };
      }
   }, [props.displayFilter, props.day, people]);

   useEffect(() => {
      if (props.tab === 1 && props.displayFilter) {
         let lid = props.displayFilter.id;
         if (props.displayFilter.type === 'group') {
            lid = props.displayFilter.lid;
         }
         const unsubscribe = props.firebase
            .spaces(lid)
            .where('archive', '!=', true)
            .onSnapshot((results) => {
               if (results.empty) {
                  setAllSpaces(false);
                  setLoading(false);
               } else {
                  let data = {};
                  results.forEach((result) => {
                     data[result.id] = {
                        ...result.data(),
                        lid,
                     };
                  });
                  let isChild = {};

                  Object.entries(data).map(([key, value]) => {
                     if (value.parent && value.parent !== '' && data[value.parent]) {
                        data[value.parent].hasChildren = true;
                        data[value.parent].child = {
                           ...data[value.parent].child,
                           [key]: value,
                        };
                        isChild[key] = true;
                        if (!data[value.parent].totalSeats) {
                           data[value.parent].totalSeats = 0;
                        }
                        if (!data[value.parent].childrenNo) {
                           data[value.parent].childrenNo = 0;
                        }
                        data[value.parent].totalSeats =
                           data[value.parent].totalSeats + value.seats;
                        data[value.parent].childrenNo = data[value.parent].childrenNo + 1;
                     }
                  });
                  const spaceData = sortSpaces(data, '');
                  setAllSpaces(spaceData);
                  setLoading(false);
               }
            });
         return () => {
            unsubscribe();
            setAllSpaces(false);
            setLoading(true);
         };
      }
   }, [props.tab, props.displayFilter]);

   function sortSpaces(data, parent) {
      const naturalCompare = (a, b) => {
         const chunkRegex = /(\d+)|(\D+)/g;
         const ax = a.match(chunkRegex);
         const bx = b.match(chunkRegex);

         while (ax && bx && ax.length > 0 && bx.length > 0) {
            const chunkA = ax.shift();
            const chunkB = bx.shift();

            if (chunkA !== chunkB) {
               const numA = parseInt(chunkA, 10);
               const numB = parseInt(chunkB, 10);

               if (!isNaN(numA) && !isNaN(numB)) {
                  return numA - numB;
               }
               return chunkA.localeCompare(chunkB);
            }
         }

         return a.localeCompare(b);
      };

      let sp = [];
      Object.entries(data)
         .sort(([keyA, valueA], [keyB, valueB]) => {
            // Sort by "order" key (if present)
            if (valueA.order && valueB.order) {
               return valueA.order - valueB.order;
            }
            // Sort using the custom natural compare function for alphabetical and numerical sorting
            return naturalCompare(valueA.name, valueB.name);
         })
         .forEach(([key, value]) => {
            if (value.parent === parent || !value.parent) {
               let individualSpace = { ...value, sid: key, lastChild: false };
               if (value.hasChildren) {
                  individualSpace.children = sortSpaces(value.child, key);
               } else {
                  individualSpace.lastChild = true;
               }
               sp.push(individualSpace);
            }
         });
      return sp;
   }
   useEffect(() => {
      if (props.tab === 1 && props.displayFilter && people && bking && allSpaces) {
         const allocatedSpaces = allocateBookingsToSpaces(bking, allSpaces);
         const spaceData = convertObjectToArray(allocatedSpaces);
         setSpaces(spaceData);
      }
   }, [props.tab, props.displayFilter, people, bking, allSpaces]);
   function allocateBookingsToSpaces(bking, spacesArray) {
      const traverseSpaces = (spaceObj) => {
         let updatedSpace = { ...spaceObj };

         if (spaceObj.sid in bking) {
            if (!updatedSpace.bookings) {
               updatedSpace.bookings = [];
            }
            updatedSpace.bookings.push(...bking[spaceObj.sid]);
         }

         if (spaceObj.children) {
            updatedSpace.children = Object.values(spaceObj.children).map((child) => {
               const updatedChild = traverseSpaces(child);
               if (updatedChild.bookings && updatedChild.bookings.length > 0) {
                  if (!updatedSpace.bookings) {
                     updatedSpace.bookings = [];
                  }
                  updatedSpace.bookings.push(...updatedChild.bookings);
               }
               return updatedChild;
            });
         }

         if (updatedSpace.bookings) {
            updatedSpace.bookings.sort((a, b) => {
               const ownerA = a.owner;
               const ownerB = b.owner;

               if (!ownerA && !ownerB) {
                  return 0;
               } else if (!ownerA) {
                  return 1;
               } else if (!ownerB) {
                  return -1;
               } else {
                  const nameA = ownerA.name.firstName.toLowerCase();
                  const nameB = ownerB.name.firstName.toLowerCase();
                  return nameA.localeCompare(nameB);
               }
            });
         }

         return updatedSpace;
      };

      let updatedSpaces = {};

      Object.values(spacesArray).forEach((spaceObj) => {
         const updatedSpace = traverseSpaces(spaceObj);
         updatedSpaces[spaceObj.sid] = updatedSpace;
      });

      return updatedSpaces;
   }

   function convertObjectToArray(obj) {
      return Object.values(obj);
   }
   function findObjectByKey(array, key, value) {
      const foundObject = array.find((obj) => obj[key] === value);
      return foundObject || false;
   }

   return (
      <Box
         sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         {loading ? (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
               }}
            >
               <CircularProgress />
            </Box>
         ) : (
            <Box sx={{ width: '100%', overflow: 'scroll' }}>
               {spaces.length === 0 ? (
                  <Typography>There are no Spaces</Typography>
               ) : (
                  <Box>
                     {spaces ? (
                        <Box>
                           {map && (
                              <SpaceMap
                                 spaceKey={spaceMap.sid}
                                 locationKey={spaceMap.lid}
                                 map={map}
                                 setMap={setMap}
                                 setSpaceMap={setSpaceMap}
                                 spaceMap={spaceMap}
                              />
                           )}
                           {/* {props.compressed ? (
                              <CompressedDisplaySpaces
                                 spaces={spaces}
                                 availableSeats={availableSeats}
                                 bookings={bookings}
                                 setSpaceMap={setSpaceMap}
                                 setMap={setMap}
                                 authStatus={authStatus}
                                 compressed
                              />
                           ) : ( */}
                           <DisplaySpaces
                              spaces={spaces}
                              setSpaceMap={setSpaceMap}
                              setMap={setMap}
                              userData={userData}
                              compressed={props.compressed}
                              day={props.day}
                           />
                           {/* )} */}
                        </Box>
                     ) : (
                        <Box sx={{ p: 2 }}>
                           <Typography>There are no spaces to display</Typography>
                        </Box>
                     )}
                  </Box>
               )}
            </Box>
         )}
      </Box>
   );
};

export default withFirebase(DayBookings);
