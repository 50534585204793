import React, { useEffect, useRef, useState } from 'react';
import {
   Box,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
   Checkbox,
} from '@mui/material';
import { withFirebase } from '../../Firebase';
import { useAuth } from '../../../providers';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CircularProgress from '@mui/material/CircularProgress';
import { set } from 'date-fns';
import { processAdminChange } from './functions';

const Admins = (props) => {
   const { authUser } = useAuth();
   const [isProcessing, setIsProcessing] = useState(false);
   const [admins, setAdmins] = useState(false);
   const [groupMembers, setGroupMembers] = useState(false);
   const listRef = useRef(null);
   const loadingSpinnerRef = useRef(null);
   useEffect(() => {
      if (props.gid && props.lid) {
         const unsubscribe = props.firebase
            .locationGroupDetails(props.lid, props.gid)
            .onSnapshot((result) => {
               let members = [];
               if (result.exists) {
                  setAdmins({ ...result.data().admins });
                  if (result.data().members) {
                     Object.entries(result.data().members).map(([uid, value]) => {
                        members.push({
                           uid,
                           displayName: `${value.name.firstName} ${value.name.lastName}`,
                           name: value.name,
                           saving: false,
                        });
                     });
                  }
               }
               members.sort(function (a, b) {
                  var textA = a.displayName.toUpperCase();
                  var textB = b.displayName.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
               });
               setGroupMembers(members);
            });
         return () => {
            unsubscribe();
            setGroupMembers(false);
         };
      }
   }, [props.gid, props.lid]);

   useEffect(() => {
      if (listRef.current && loadingSpinnerRef.current) {
         const listRect = listRef.current.getBoundingClientRect();
         const loadingSpinnerRect = loadingSpinnerRef.current.getBoundingClientRect();

         // Calculate the top position of the loading spinner relative to the visible part of the list
         const visibleTop = Math.max(0, loadingSpinnerRect.top - listRect.top);

         // Apply the calculated top position to the loading spinner
         loadingSpinnerRef.current.style.top = `${visibleTop}px`;
      }
   }, [isProcessing]);

   async function toggleMember(person) {
      setIsProcessing(true);
      setGroupMembers((prev) =>
         prev.map((p) => {
            if (p.uid === person.uid) {
               return { ...p, saving: true };
            }
            return p;
         }),
      );
      let newAdmins = admins;
      let added = true;

      if (admins[person.uid] !== undefined) {
         delete newAdmins[person.uid];
         added = false;
      } else {
         newAdmins[person.uid] = { name: person.name };
      }
      await processAdminChange(
         props.firebase,
         person.uid,
         props.gid,
         props.lid,
         props.cid,
         newAdmins,
         added,
      );
      setTimeout(() => {
         setGroupMembers((prev) =>
            prev.map((p) => {
               if (p.uid === person.uid) {
                  return { ...p, saving: false };
               }
               return p;
            }),
         );
         setIsProcessing(false);
      }, 2000);
   }

   return (
      <Box>
         <Typography variant="body2">
            Admins can only be added from saved group members. Any changes here are
            processed instantly.
         </Typography>
         <Box position="relative">
            <List dense={true}>
               {groupMembers && groupMembers.length !== 0 && groupMembers.length < 480 ? (
                  <React.Fragment>
                     {groupMembers.map((person) => (
                        <ListItem key={person.uid} disablePadding>
                           <ListItemIcon>
                              <Checkbox
                                 edge="start"
                                 onChange={() => toggleMember(person)}
                                 checked={admins[person.uid] !== undefined}
                                 disabled={isProcessing}
                                 tabIndex={-1}
                                 //  disableRipple
                                 inputProps={{ 'aria-labelledby': person.displayName }}
                                 size="small"
                                 sx={{ p: 0 }}
                              />
                           </ListItemIcon>
                           <ListItemText
                              id={person.uid}
                              primary={
                                 person.saving ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                       {person.displayName} - Saving
                                       <CircularProgress sx={{ ml: 2 }} size={20} />
                                    </Box>
                                 ) : (
                                    person.displayName
                                 )
                              }
                           />
                        </ListItem>
                     ))}
                  </React.Fragment>
               ) : (
                  <ListItem>
                     <ListItemText>No saved members</ListItemText>
                  </ListItem>
               )}
            </List>
         </Box>
      </Box>
   );
};

export default withFirebase(Admins);
