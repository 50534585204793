import React, { useState, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import DayView from './Views/dayView';
import { withFirebase } from '../Firebase';
import StatusDrawer from './DrawerComponents/statusDrawer';
import MeetingBookingDrawer from './meetingDrawer';
import { DateToday, getWeekDates, getDay, isValidDate } from '../../constants/functions';

import Typography from '@mui/material/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import { Paper } from '@material-ui/core';
import QuickActions from '../QuickActions';
import DisplayFilter from '../DisplayFilter';
import { Box, Stack } from '@mui/material';
import ViewMenu from './Views/viewMenu';
import MultiWeek from './Views/multiWeek';
import AuthProvider from '../../providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
   seeMore: {
      marginTop: theme.spacing(3),
   },
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
   },
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         paddingRight: 0,
         paddingLeft: 0,
      },
   },
   wrapper: {
      padding: theme.spacing(0),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   tableContainer: {
      height: '70vh',
      backgroundColor: '#fff',
   },
   dates: {
      fontSize: 'large',
   },
   capacityRow: {
      position: 'sticky',
      backgroundColor: '#fff',
   },
   topLeftCell: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 999,
      BackgroundColor: '#fafafa',
   },
   capacityRowCell: {
      borderBottom: '2px solid rgba(224, 224, 224, 1)',
      position: 'sticky',
      top: 65,
      left: 0,
      zIndex: 999,
      backgroundColor: '#fafafa',
   },
   tableCell: {
      maxWidth: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
   },
   myRow: {
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      borderColor: '#342E76',
   },
   statusCells: {
      width: '200px',
   },
   drawerPaper: {
      [theme.breakpoints.down('md')]: {
         width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
         width: '90%',
      },
      [theme.breakpoints.down('xs')]: {
         width: '90%',
      },
      [theme.breakpoints.up('md')]: {
         width: '65%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
}));

const Schedule = (props) => {
   const classes = useStyles();
   const queryString = window.location.search;
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [user, setUser] = useState(false);
   const [location, setLocation] = useState(false);
   const [displayFilter, setDisplayFilter] = useState(false);
   const [groupFilter, setGroupFilter] = useState(false);
   const [spacesEnabled, setSpacesEnabled] = useState(false);
   const [routineEnabled, setRoutineEnabled] = useState(false);
   const [statusDrawerOpen, setStatusDrawerOpen] = useState(false);
   const [spaceData, setSpaceData] = useState({});
   const [view, setView] = useState(false);
   const today = DateToday();
   const [scrollAmount, setScrollAmount] = useState(7);
   const [weekDates, setWeekDates] = useState(getWeekDates(today));
   const [meetingDrawer, setMeetingDrawer] = useState(false);
   const [people, setPeople] = useState(false);
   const [day, setDay] = useState(false);
   const [hideWeekends, setHideWeekends] = useState(false);

   var dates = [];
   for (var i = 0; i < 7; i++) {
      dates[i] = weekDates[i].ref;
   }

   useEffect(() => {
      const loadUser = async () => {
         if (authUser) {
            await props.firebase
               .user(authUser.uid)
               .get()
               .then((me) => {
                  setUser({ ...me.data(), uid: me.id });
               });
         }
      };
      loadUser();
   }, []);
   useEffect(() => {
      if (!day && view) {
         const urlParams = new URLSearchParams(queryString);
         let date = urlParams.get('date');
         if (date !== null) {
            let dateRef = new Date(date);
            if (isValidDate(dateRef)) {
               setDay(getDay(dateRef));
            } else {
               setDay(today);
            }
         } else {
            setDay(today);
         }
      }
   }, [view]);

   useEffect(() => {
      if (view) {
         if (view === 'day') {
            setScrollAmount(1);
         } else {
            setScrollAmount(7);
         }
      }
   }, [view]);

   useEffect(() => {
      //Something stange is happening where the location is being passed to the display filter from this useEffect, rather than just being loaded in the display filter component.
      // It's being loaded here and then displayed in the display filter whihc is why nothing is currently showing on the filter.
      setPeople(false);
      if (displayFilter) {
         let lid = displayFilter.id;
         if (displayFilter.type === 'group') {
            lid = displayFilter.lid;
         }
         const locationInfo = props.firebase.location(lid).onSnapshot(
            (result) => {
               setLocation({ ...result.data(), id: result.id });
               setSpacesEnabled(result.data().spacesEnabled);
               if (result.data().routine) {
                  setRoutineEnabled(result.data().routine);
               }
            },
            (error) => {
               console.warn(error, 'Error loading display filter location');
            },
         );
         return () => locationInfo();
      }
   }, [displayFilter]);
   function scrollDay(direction) {
      var date = new Date(day.fullDate);
      date.setDate(date.getDate() + direction);
      setDay(getDay(date));
   }
   function handleClose() {
      setStatusDrawerOpen(false);
   }

   return (
      <React.Fragment>
         <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="right"
            open={statusDrawerOpen}
            onClose={() => handleClose()}
            disableEnforceFocus
         >
            <StatusDrawer
               data={spaceData}
               displayFilter={displayFilter}
               setSpaceData={setSpaceData}
               spacesEnabled={spacesEnabled}
            />
         </Drawer>
         <MeetingBookingDrawer
            meetingDrawer={meetingDrawer}
            setMeetingDrawer={setMeetingDrawer}
            displayFilter={displayFilter}
         />
         <Container maxWidth="lg" className={classes.container}>
            <Paper className={classes.paper} elevation={0}>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                  }}
               >
                  <Box sx={{ paddingTop: '8px' }}>
                     <DisplayFilter
                        user={user}
                        displayFilter={displayFilter}
                        setDisplayFilter={setDisplayFilter}
                        location={location}
                        groupFilter={groupFilter}
                        setGroupFilter={setGroupFilter}
                     />
                  </Box>
                  {view && (
                     <Box>
                        <IconButton
                           onClick={() => {
                              scrollDay(-scrollAmount);
                           }}
                        >
                           <ArrowBackIosOutlinedIcon
                              sx={{ fontSize: { xs: 'medium', sm: 'large' } }}
                           />
                        </IconButton>
                        <IconButton
                           onClick={() => {
                              scrollDay(scrollAmount);
                           }}
                        >
                           <ArrowForwardIosOutlinedIcon
                              sx={{ fontSize: { xs: 'medium', sm: 'large' } }}
                           />
                        </IconButton>
                        <Typography
                           variant="h6"
                           sx={{
                              fontSize: { xs: 'medium', md: 'large' },
                              display: { xs: 'inline' },
                           }}
                           noWrap
                        >{`${day.day} ${day.shortMonthName}`}</Typography>
                     </Box>
                  )}
                  <Box>
                     <AuthProvider>
                        <ViewMenu
                           view={view}
                           setView={setView}
                           hideWeekends={hideWeekends}
                           setHideWeekends={setHideWeekends}
                           location={location}
                        />
                     </AuthProvider>
                  </Box>
                  <QuickActions
                     schedule={true}
                     location={location}
                     displayFilter={displayFilter}
                  />
               </Box>
               {view === 'day' ? (
                  <DayView
                     displayFilter={displayFilter}
                     day={day}
                     location={location}
                     setView={setView}
                  />
               ) : (
                  <MultiWeek
                     day={day}
                     hideWeekends={hideWeekends}
                     displayFilter={displayFilter}
                     setDisplayFilter={setDisplayFilter}
                     location={location}
                     routineEnabled={routineEnabled}
                     spacesEnabled={spacesEnabled}
                     statusDrawerOpen={statusDrawerOpen}
                     setStatusDrawerOpen={setStatusDrawerOpen}
                     spaceData={spaceData}
                     setSpaceData={setSpaceData}
                     setDay={setDay}
                     setView={setView}
                     view={view}
                  />
               )}
            </Paper>
         </Container>
      </React.Fragment>
   );
};
export default withFirebase(Schedule);
