import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { spaceTypeImage, addAmenityIcon } from './functions';
import SpaceMap from './spaceMap';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Image from 'material-ui-image';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import RoomIcon from '@material-ui/icons/Room';
import { withFirebase } from '../Firebase';
import { DropzoneDialog } from 'material-ui-dropzone';
import { spaceTypes } from '../../constants/data';

const useStyles = makeStyles((theme) => ({
   fieldTitle: {
      fontWeight: 500,
   },
   imagePaper: {
      width: '100%',
      height: '100%',
      maxWidth: '200px',
      maxHeight: '200px',
      margin: 'auto',
   },
}));

const SpaceDetails = (props) => {
   const classes = useStyles();
   const space = props.space;
   const lid = props.locationKey;
   const sid = props.spaceKey;
   const [amenities, setAmenities] = useState([]);
   const [parent, setParent] = useState(props.space.parent);
   const titleIcon = spaceTypeImage(space.type);
   const [imageUpload, setImageUpload] = useState(false);
   const [imageExists, setImageExists] = useState(false);
   const [spaceImages, setSpaceImages] = useState('');
   const [mapUpload, setMapUpload] = useState(false);
   const [map, setMap] = useState(false);

   useEffect(() => {
      if (props.space.amenities !== undefined) {
         setAmenities(props.space.amenities);
      }
   }, [props.space.amenities]);

   useEffect(() => {
      async function getParent(pid) {
         if (props.space.parent !== undefined && props.space.parent !== '') {
            const parentResult = await props.firebase
               .getSpace(props.locationKey, pid)
               .get();
            if (parentResult.exists) {
               setParent(parentResult.data().name);
            } else {
               const unknown = '[Area removed]';
               setParent(unknown);
            }
         }
      }
      getParent(props.space.parent);
   }, [props.space.parent]);

   useEffect(() => {
      if (space.imagePath) {
         const fullPath = props.firebase.showImage(space.imagePath);
         fullPath
            .getDownloadURL()
            .then(function (url) {
               setSpaceImages(url);
               setImageExists(true);
            })
            .catch(function (error) {
               alert(error);
               // Uh-oh, an error occurred!
            });
      }
   }, [props.space.imagePath]);

   function uploadOpen() {
      setImageUpload(true);
   }

   function uploadClose() {
      setImageUpload(false);
   }
   function mapUploadOpen() {
      setMapUpload(true);
   }

   function mapUploadClose() {
      setMapUpload(false);
   }
   async function saveUpload(files, type) {
      let path = '';
      Object.entries(files).map(([key, value], i) => {
         path = 'locations/' + props.locationKey + '/spaceImages/' + props.spaceKey + '/';
         props.firebase.uploadImage(value, path).then(() => {
            if (type === 'image') {
               props.firebase.getSpace(lid, sid).set(
                  {
                     imagePath: path + value.name,
                  },
                  { merge: true },
               );
               setImageUpload(false);
            } else if (type === 'map') {
               props.firebase.getSpace(lid, sid).set(
                  {
                     mapPath: path + value.name,
                  },
                  { merge: true },
               );
               setMapUpload(false);
            }
         });
      });
   }
   async function deleteImage() {
      var desertRef = props.firebase.storedImageRef(space.imagePath);
      // Delete the file
      await desertRef
         .delete()
         .then(() => {
            props.firebase.deleteSpaceImage(lid, sid);
            // File deleted successfully
            setImageExists(false);
         })
         .catch((error) => {
            // Uh-oh, an error occurred!
         });
   }
   async function deleteMap() {
      var desertRef = props.firebase.storedImageRef(space.mapPath);
      // Delete the file
      await desertRef
         .delete()
         .then(() => {
            props.firebase.deleteSpaceMap(lid, sid);
            // File deleted successfully
            setImageExists(false);
         })
         .catch((error) => {
            // Uh-oh, an error occurred!
         });
   }

   const Amenities = () => {
      if (amenities.length !== 0) {
         return (
            <React.Fragment>
               {amenities.map((feature) => {
                  return addAmenityIcon(feature);
               })}
            </React.Fragment>
         );
      } else {
         return (
            <Grid item xs={12}>
               <Typography variant="body1">No amenities have been added.</Typography>
            </Grid>
         );
      }
   };

   return (
      <React.Fragment>
         <SpaceMap
            spaceKey={props.space.id}
            locationKey={props.locationKey}
            map={map}
            setMap={setMap}
         />

         <Grid container spacing={1}>
            <Grid
               container
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               item
            >
               <Grid item xs={2} align="center">
                  {titleIcon !== undefined ? (
                     <Image style={{ height: 100, paddingTop: 0 }} src={titleIcon} />
                  ) : (
                     ''
                  )}
               </Grid>
               <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  xs={8}
                  item
                  spacing={0}
               >
                  <Grid item xs={12}>
                     <Typography paragraph component="h1" variant="subtitle1">
                        {space.name}
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography variant="body2">{space.description}</Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid
               container
               alignItems="center"
               justifyContent="center"
               spacing={2}
               item
               xs={12}
            >
               <Grid item xs={12} sm={6}>
                  {imageExists ? (
                     <React.Fragment>
                        <Grid container item direction="row">
                           <Grid item xs={12}>
                              <Paper className={classes.imagePaper}>
                                 <Image src={spaceImages} />
                              </Paper>
                           </Grid>
                           <Grid item xs={12}>
                              <Button fullWidth onClick={deleteImage} color="secondary">
                                 Delete Image
                              </Button>
                           </Grid>
                        </Grid>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <Grid item xs={12}>
                           <Button fullWidth onClick={uploadOpen}>
                              Add images
                           </Button>
                           <DropzoneDialog
                              open={imageUpload}
                              onSave={(files) => {
                                 saveUpload(files, 'image');
                              }}
                              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                              showPreviews={true}
                              maxFileSize={5000000}
                              filesLimit={1}
                              onClose={uploadClose}
                           />
                        </Grid>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item xs={12} sm={6}>
                  {space.mapPath ? (
                     <Grid container item xs={12}>
                        <Grid item xs={12}>
                           <Button
                              fullWidth
                              size="large"
                              startIcon={<RoomIcon />}
                              onClick={() => setMap(true)}
                           >
                              map
                           </Button>
                        </Grid>
                        <Grid item xs={12}>
                           <Button fullWidth color="secondary" onClick={deleteMap}>
                              Delete map
                           </Button>
                        </Grid>
                     </Grid>
                  ) : (
                     <React.Fragment>
                        <Button
                           fullWidth
                           startIcon={<RoomIcon />}
                           onClick={mapUploadOpen}
                        >
                           Add map
                        </Button>
                        <DropzoneDialog
                           open={mapUpload}
                           onSave={(files) => {
                              saveUpload(files, 'map');
                           }}
                           acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                           showPreviews={true}
                           maxFileSize={5000000}
                           filesLimit={1}
                           onClose={mapUploadClose}
                        />
                     </React.Fragment>
                  )}
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Typography variant="body1" className={classes.fieldTitle}>
                  Details
               </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
               <Grid item xs={12} sm={2}>
                  <Typography variant="body1">
                     {space.seats} {space.seats !== 1 ? ' seats' : ' seat'}
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={7}>
                  <Typography variant="body1">
                     {spaceTypes[space.type]} {parent ? ' within ' + parent : ''}
                  </Typography>
               </Grid>
            </Grid>
            {props.childSpaces && (
               <Grid
                  container
                  spacing={1}
                  justifyContent="space-around"
                  alignItems="flex-start"
               >
                  <Grid item xs={12}>
                     <Typography
                        className={classes.fieldTitle}
                        variant="subtitle1"
                        paragraph
                     >
                        Child Spaces
                     </Typography>
                     <TableContainer>
                        <Table stickyHeader size="small">
                           <TableHead>
                              <TableRow key="1">
                                 <TableCell>Name</TableCell>
                                 <TableCell>Type</TableCell>
                                 <TableCell>Seats</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {Object.entries(props.childSpaces).map(([key, value]) => (
                                 <TableRow key={key}>
                                    <TableCell>{value.name}</TableCell>
                                    <TableCell>{value.type}</TableCell>
                                    <TableCell>{value.seats}</TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Grid>
               </Grid>
            )}
            <Grid
               container
               spacing={1}
               justifyContent="space-around"
               alignItems="flex-start"
            >
               <Grid item xs={12}>
                  <Typography className={classes.fieldTitle} variant="subtitle1">
                     Amenities
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Amenities />
               </Grid>
            </Grid>
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(SpaceDetails);
