import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@material-ui/core/Switch';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import DatePick from './datePicker';
import InternalPeople from './internalPeople';
import CapacityGauge from '../Capacity';
import LoadChip from './loadChips';
import { CalculateCapacity } from '../Capacity/functions';

const useStyles = makeStyles((theme) => ({
   label: {
      marginRight: theme.spacing(1),
   },
}));

const Meetings = (props) => {
   const classes = useStyles();
   const [peopleVal, setPeopleVal] = useState([]);
   const [attendance, setAttendance] = useState(false);
   const [capacity, setCapacity] = useState(0);
   const [capLevel, setCapLevel] = useState(null);
   const [routine, setRoutine] = useState(false);
   const [expanded, setExpanded] = useState(false);

   useEffect(() => {
      const resetState = () => {
         if (!props.visitorsDrawer) {
            setPeopleVal([]);
            setAttendance(false);
            setCapacity(0);
            setCapLevel(null);
         }
      };
      resetState();
   }, [props.visitorsDrawer]);

   useEffect(() => {
      const loadRoutine = async () => {
         if (props.location.routine) {
            await props.firebase
               .getRoutineStreak(props.form.locationId)
               .onSnapshot((result) => {
                  if (result.exists) {
                     setRoutine(result.data());
                  }
               });
         }
      };
      loadRoutine();
   }, [props.location]);

   useEffect(() => {
      const loadCapacity = async () => {
         if (props.form.type === 'requests' && props.visitorsDrawer && props.form.cid) {
            var d = ('0' + props.form.date.getDate()).slice(-2);
            var m = ('0' + props.form.date.getMonth()).slice(-2);
            var queryDate = props.form.date.getFullYear() + m + d;
            let dayNo = props.form.date.getDay();
            props.firebase
               .locationStatusLevels(props.form.locationId, queryDate, queryDate)
               .onSnapshot(async (result) => {
                  var statusLevels = 0;
                  if (routine && routine[dayNo].ito) {
                     statusLevels = statusLevels + routine[dayNo].ito;
                  }
                  if (!result.empty) {
                     result.forEach((doc) => {
                        if (doc.data().overridden && doc.data().overridden.ito) {
                           statusLevels = statusLevels - doc.data().overridden.ito;
                        }
                        if (doc.data().ito) {
                           statusLevels = statusLevels + doc.data().ito;
                        }
                     });
                  }
                  let allLocAtten = {};
                  const company = await props.firebase.company(props.form.cid).get();
                  let coLocs = [];
                  Object.keys(company.data().locations).map((lid) => {
                     coLocs.push(lid);
                  });
                  if (
                     company.data().locationsArchived &&
                     Object.keys(company.data().locationsArchived).length !== 0
                  ) {
                     Object.keys(company.data().locationsArchived).map((lid) => {
                        coLocs.push(lid);
                     });
                  }
                  await Promise.all(
                     coLocs.map(async (lid) => {
                        const schedule = await props.firebase
                           .locationDayScheduleDate(lid, queryDate)
                           .get();
                        if (schedule.exists) {
                           allLocAtten[lid] = schedule.data();
                        } else {
                           allLocAtten[lid] = null;
                        }
                     }),
                  );
                  setAttendance(allLocAtten);
                  setCapLevel(statusLevels);
               });
         }
      };
      loadCapacity();
   }, [props.form.type, props.form.date, routine]);

   useEffect(() => {
      const loadPeople = async () => {
         if (props.form.type === 'requests' && attendance) {
            let dayNo = props.form.date.getDay();
            var participants = {};
            Object.entries(peopleVal).map(([key, value]) => {
               let found = false;
               Object.entries(attendance).map(([lid, dayStatuses]) => {
                  if (dayStatuses !== null && value.id in dayStatuses) {
                     found = true;
                     participants[value.id] = {
                        name: value.name,
                        email: value.email,
                     };
                     if (
                        lid !== props.location.id &&
                        dayStatuses[value.id].status == 'ito'
                     ) {
                        participants[value.id].status = 'offsite';
                     } else {
                        participants[value.id].status = dayStatuses[value.id].status;
                     }
                     if (routine && value.routine) {
                        participants[value.id].routine = value.routine[dayNo];
                     }
                  }
               });
               if (!found) {
                  participants[value.id] = {
                     name: value.name,
                     status: 'none',
                     email: value.email,
                  };
                  if (routine && value.routine) {
                     participants[value.id].routine = value.routine[dayNo];
                  }
               }
            });
            props.setForm({ ...props.form, internalParticipants: participants });
         }
      };
      loadPeople();
   }, [peopleVal, attendance, props.form.date]);

   useEffect(() => {
      const loadCapacityLevel = async () => {
         if (capLevel !== null) {
            var statusLevels = capLevel;
            if (Object.keys(props.form.internalParticipants).length !== 0) {
               Object.entries(props.form.internalParticipants).map(([key, value]) => {
                  if (
                     value.status !== 'away' &&
                     value.status !== 'ito' &&
                     value.status !== 'offsite'
                  ) {
                     statusLevels = statusLevels + 1;
                  }
               });
            }
            let cap = await CalculateCapacity(props.location, statusLevels);
            setCapacity(cap);
         }
      };
      loadCapacityLevel();
   }, [capLevel, props.form.date, props.form.internalParticipants]);

   const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   function handleChange() {
      let status = !props.form.forceStatusChange;
      props.setForm({ ...props.form, forceStatusChange: status });
   }

   return (
      <React.Fragment>
         <Grid container item>
            <Grid item xs={12} sm={8} md={6}>
               <Typography variant="caption" className={classes.label}>
                  Location:{' '}
               </Typography>
               <Typography variant="body1">{props.form.locationName}</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
               <DatePick value={props.form} setDate={props.setForm} pickerType="date" />
            </Grid>
            <Accordion
               expanded={expanded === 'panel1'}
               sx={{ minWidth: 100 }}
               onChange={handleAccordionChange('panel1')}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
               >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>Attendees</Typography>
                  <Typography sx={{ color: 'text.secondary' }}>5</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Grid item xs={12}>
                     <Typography variant="caption" className={classes.label}>
                        Capacity:{' '}
                     </Typography>
                     <CapacityGauge capacity={capacity} />
                     <InternalPeople
                        attendance={attendance}
                        form={props.form}
                        setForm={props.setForm}
                        people={props.people}
                        peopleVal={peopleVal}
                        setPeopleVal={setPeopleVal}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Typography variant="caption" className={classes.label}>
                        Invitees:
                     </Typography>
                     {props.form.internalParticipants &&
                        Object.entries(props.form.internalParticipants).map(
                           ([key, value], i) => (
                              <LoadChip
                                 key={key}
                                 item={i}
                                 id={key}
                                 value={value}
                                 form={props.form}
                                 setForm={props.setForm}
                                 location={props.location}
                                 setPeopleVal={setPeopleVal}
                                 peopleVal={peopleVal}
                              />
                           ),
                        )}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Accordion
               expanded={expanded === 'panel2'}
               sx={{ minWidth: 100 }}
               onChange={handleAccordionChange('panel2')}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
               >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                     Space Bookings
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>Board room</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <TextField
                     id="request-comments"
                     variant="outlined"
                     label="Comments"
                     multiline
                     fullWidth
                     rows={4}
                     value={props.form.comments}
                     onChange={(e) =>
                        props.setForm({ ...props.form, comments: e.target.value })
                     }
                  />
               </AccordionDetails>
            </Accordion>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="caption" className={classes.label}>
               Request by:{' '}
            </Typography>
            <Typography variant="body1">{props.form.owner.name}</Typography>
         </Grid>
         <Grid item xs={12}>
            <FormControlLabel
               control={
                  <Switch
                     checked={props.form.forceStatusChange}
                     onChange={handleChange}
                     name="checkedB"
                     color="primary"
                  />
               }
               label="Automatically update invitee status to be in the office."
            />
            {props.form.forceStatusChange && (
               <Typography paragraph variant="caption">
                  {props.location.offsiteEnabled
                     ? `Automatically update the status of anyone who does not have their status set to 'Work from home', 'Offsite', or 'Away'.`
                     : `Automatically update the status of anyone who does not have their status set to 'Work from home' or 'Away'.`}
               </Typography>
            )}
            <Typography paragraph variant="caption">
               We will notify all invitees who have not set their status to "Away".
            </Typography>
         </Grid>
      </React.Fragment>
   );
};

export default withFirebase(Meetings);
