import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { withFirebase } from '../Firebase';
import * as microsoftTeams from '@microsoft/teams-js';

const MsTeamsAuthPage = (props) => {
   useEffect(() => {
      // microsoftTeams.initialize();
      microsoftTeams.authentication.notifySuccess({
         // accessToken: queryParams.access_token
      });
   }, []);
   return (
      <Grid container>
         <Grid item>
            <CircularProgress />
         </Grid>
      </Grid>
   );
};

export default withFirebase(MsTeamsAuthPage);
