import React, { useState, useEffect, forwardRef } from 'react';
import { Autocomplete, Box, Button, Paper, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Typography from '@mui/material/Typography';
import EmailValidator from 'email-validator';
import { withFirebase } from '../../Firebase';
import { useAuth } from '../../../providers';

const EditVisitor = forwardRef(
   ({ cid, lid, date, visitor, closeEditModal, ...props }, ref) => {
      const { authUser } = useAuth();
      const [emailError, setEmailError] = useState({ error: false, message: '' });
      const [nameError, setNameError] = useState({ error: false, message: '' });
      const [saveDisabled, setSaveDisabled] = useState(false);

      const [form, setForm] = React.useState(() => {
         let data = {
            name: visitor.name,
            email: visitor.uid,
            arrivalTime: null,
            departureTime: null,
            host: visitor.host,
            owner: visitor.owner,
         };
         if (visitor.arrivalTime !== null) {
            data.arrivalTime = new Date(
               visitor.arrivalTime.seconds * 1000 +
                  visitor.arrivalTime.nanoseconds / 1000000,
            );
         }
         if (visitor.departureTime !== null) {
            data.departureTime = new Date(
               visitor.departureTime.seconds * 1000 +
                  visitor.departureTime.nanoseconds / 1000000,
            );
         }
         return data;
      });

      const [hosts, setHosts] = useState(null);

      useEffect(() => {
         const fetchHosts = async () => {
            if (authUser?.roles?.ADMIN) {
               try {
                  const hostList = await props.firebase.db
                     .doc(`company/${cid}/indexes/people`)
                     .get();
                  let data = [];
                  Object.entries(hostList.data()).forEach(([key, value]) => {
                     data.push({
                        uid: key,
                        name: `${value.name.firstName} ${value.name.lastName}`,
                        email: value.email,
                        nameObject: { ...value.name },
                     });
                  });
                  data.sort((a, b) => {
                     const textA = a.nameObject.lastName.toUpperCase();
                     const textB = b.nameObject.lastName.toUpperCase();
                     return textA < textB ? -1 : textA > textB ? 1 : 0;
                  });
                  setHosts(data);
               } catch (error) {
                  console.error('Failed to fetch host list:', error);
               }
            }
         };

         fetchHosts();
      }, []);

      async function saveEdit() {
         setSaveDisabled(true);
         var emailValid = EmailValidator.validate(form.email); // true
         if (!emailValid) {
            setEmailError({ error: true, message: 'Please enter a valid email address' });
         } else {
            setEmailError({ error: false, message: '' });
         }
         if (form.name === '') {
            setNameError({ error: true, message: 'Please enter a name' });
         } else {
            setNameError({ error: false, message: '' });
         }
         if (emailValid && form.name !== '') {
            let data = {
               [form.email.toLowerCase()]: {
                  name: form.name,
                  host: form.host,
                  owner: form.owner,
                  unplanned: false,
                  arrivalTime: form.arrivalTime,
                  departureTime: form.departureTime,
               },
            };
            let emailChanged = false;
            if (form.email !== visitor.uid) {
               emailChanged = true;
            }
            if (emailChanged) {
               try {
                  await props.firebase.deleteVisitor(
                     cid,
                     lid,
                     visitor.host.uid,
                     date.ref,
                     visitor.uid,
                  );
                  await props.firebase.addVisitor(
                     cid,
                     lid,
                     form.host.uid,
                     date.ref,
                     data,
                  );
                  setSaveDisabled(false);
                  closeEditModal();
               } catch (error) {
                  console.warn(error, 'deleting then adding visitor');
               }
            } else {
               try {
                  await props.firebase.addVisitor(
                     cid,
                     lid,
                     form.host.uid,
                     date.ref,
                     data,
                  );
                  setSaveDisabled(false);
                  closeEditModal();
               } catch (error) {
                  console.warn('ERROR updating visitor', error);
               }
            }
         }
      }

      return (
         <Paper
            sx={{ p: 4, display: 'flex', flexDirection: 'column', maxWidth: '500px' }}
            ref={ref}
         >
            <Box sx={{ flexDirection: 'row' }}>
               <Typography paragraph variant="h6">
                  Edit Visitor
               </Typography>
               <TextField
                  fullWidth
                  label="Name"
                  value={form.name}
                  sx={{ mb: 1 }}
                  error={nameError.error}
                  helperText={nameError.message}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
               />
               <TextField
                  fullWidth
                  label="Email"
                  value={form.email}
                  sx={{ mb: 1 }}
                  error={emailError.error}
                  helperText={emailError.message}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
               />
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                     label="Expected arrival"
                     value={form.arrivalTime}
                     ampm={false}
                     onChange={(newValue) => {
                        setForm({ ...form, arrivalTime: newValue });
                     }}
                     renderInput={(params) => (
                        <TextField fullWidth sx={{ mb: 2 }} {...params} />
                     )}
                  />
               </LocalizationProvider>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                     label="Expected departure"
                     value={form.departureTime}
                     ampm={false}
                     onChange={(newValue) => {
                        setForm({ ...form, departureTime: newValue });
                     }}
                     renderInput={(params) => (
                        <TextField fullWidth sx={{ mb: 2 }} {...params} />
                     )}
                  />
               </LocalizationProvider>
               {authUser?.roles?.ADMIN && hosts !== null ? (
                  <Autocomplete
                     id="host"
                     options={hosts}
                     autoSelect
                     isOptionEqualToValue={(option, value) => option.uid === value.uid}
                     getOptionLabel={(option) => option.name}
                     value={form.host}
                     onChange={(e, newValue) => {
                        setForm({ ...form, host: newValue });
                     }}
                     renderInput={(params) => <TextField {...params} label="Host" />}
                  />
               ) : (
                  <Typography sx={{ pt: 2, pb: 2 }}>Host: {form.host.name}</Typography>
               )}
            </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
               <Button
                  variant="contained"
                  color="primary"
                  onClick={() => saveEdit()}
                  disabled={saveDisabled}
               >
                  Save
               </Button>
               <Button onClick={() => closeEditModal()}>Close</Button>
            </Box>
         </Paper>
      );
   },
);

export default withFirebase(EditVisitor);
