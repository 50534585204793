import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import { withAuthorization, withEmailVerification } from '../Session';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PeopleTable from './peopleTable';

import LeftPanel from './leftPanel';

import PeoplePageDrawer from './peoplepagedrawer';
import BulkManageDrawer from './bulkManageDrawer';
import BulkActionsDrawer from './bulkActionsDrawer';
import SyncMicrosoftDrawer from './syncMicrosoftUsers';
import { AuthenticatedTemplate } from '@azure/msal-react';
import * as ROLES from '../../constants/roles';
import Drawer from '../Drawer';
import { PageLayout } from '../layout';
import GroupListing from '../../modules/GroupListing';
import { useSearchParams } from '../../hooks/useSearchParams';
import { isFeatureEnabledBool } from '../IsEnabled';
import { useCompany } from '../../providers';

function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const loadingPeople = {
   0: {
      name: { firstName: 'loading', lastName: '' },
      email: '',
      uid: '',
      lid: [''],
      groups: { 0: { name: '' } },
      status: { statusId: 'yes' },
   },
};
const PeopleDirectory = (props) => {
   const user = JSON.parse(localStorage.getItem('authUser'));
   const { company } = useCompany();
   const [getParams, setParams] = useSearchParams();

   const userPrimaryLocation = user.lid[0];

   const [selectedLocationId, setSelectedLocationId] = useState(() => {
      const params = getParams();
      return params.lid ? params.lid : userPrimaryLocation;
   });
   const [selectedGroupId, setSelectedGroupId] = useState(() => {
      const params = getParams();

      if (isFeatureEnabledBool('groups', company.features)) {
         return params.gid ? params.gid : 'all';
      }

      return 'all';
   });

   useEffect(() => {
      const params = getParams();
      if (R.isEmpty(params) || !isFeatureEnabledBool('groups', company.features)) {
         setParams({
            cid: company.cid,
            lid: userPrimaryLocation,
            gid: 'all',
         });
      }
   }, []);

   function handleSelectLocation(e, selection) {
      if (e) {
         setSelectedLocationId(e.target.value);
         setSelectedGroupId('all');
         setParams({ ...getParams(), lid: e.target.value, gid: 'all' });
         return;
      }
      setSelectedLocationId(selection);
      setSelectedGroupId('all');
      setParams({ ...getParams(), lid: selection, gid: 'all' });
   }

   function handleSelectGroup(e, selection) {
      if (e) {
         setSelectedGroupId(e.currentTarget.id);
         setParams({ ...getParams(), gid: e.currentTarget.id });
         return;
      }
      setParams({ ...getParams(), gid: selection });
      setSelectedGroupId(selection);
   }

   const [peopleDrawer, setPeopleDrawer] = useState(false);
   const [bulkDrawer, setBulkDrawer] = useState(false);
   const [bulkDeleteDrawer, setBulkDeleteDrawer] = useState(false);
   const [syncMicrosoftDrawer, setSyncMicrosoftDrawer] = useState(false);
   const [displayFilter, setDisplayFilter] = useState({ id: false, name: 'Loading...' });
   const [groupFilter, setGroupFilter] = useState('');
   const [groups, setGroups] = useState(false);
   const [companyPeople, setCompanyPeople] = useState(false);
   const [drawerData, setDrawerData] = useState(false);
   const [timeToUpgrade, setTimeToUpgrade] = useState(false);
   const [companyPlan, setCompanyPlan] = useState('');
   const [checked, setChecked] = useState(false);
   const [locations, setLocations] = useState([]);
   const [alertMessage, setAlertMessage] = useState({ type: 'success', message: '' });
   const [importType, setImportType] = useState('');
   const [co, setCo] = useState(false);

   useEffect(() => {
      const loadCompany = async () => {
         await props.firebase
            .company(user.cid)
            .get()
            .then((result) => {
               setCo(result.data());
               let loadedLocations = [];
               Object.entries(result.data().locations).map(([key, value]) => {
                  loadedLocations.push({ id: key, name: value });
               });
               setLocations(loadedLocations);
               let locs = {
                  id: user.lid[0],
                  title: result.data().locations[user.lid[0]],
                  type: 'location',
                  cid: result.id,
               };
               setDisplayFilter(locs);
            });
      };
      loadCompany();
   }, []);

   useEffect(() => {
      if (displayFilter.id) {
         const loadGroups = props.firebase
            .locationGroupsIndex(displayFilter.id)
            .onSnapshot((result) => {
               let grps = {};
               if (result.exists) {
                  Object.entries(result.data()).map(([key, value]) => {
                     grps[key] = { id: key, name: value.name, admins: value.admins };
                  });
               }
               setGroups(grps);
            });
         return () => {
            loadGroups();
            setGroups(false);
            setGroupFilter('');
         };
      }
   }, [displayFilter]);

   const snackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setChecked(false);
   };
   return (
      <PageLayout
         data={{
            selectedGroupId,
            selectedLocationId,
            handleSelectLocation,
            handleSelectGroup,
         }}
      >
         <PeoplePageDrawer
            setAlertMessage={setAlertMessage}
            company={co}
            locations={locations}
            setChecked={setChecked}
            timeToUpgrade={timeToUpgrade}
            setPeopleDrawer={setPeopleDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            groups={groups}
            peopleDrawer={peopleDrawer}
            displayFilter={displayFilter}
         />
         <BulkManageDrawer
            setAlertMessage={setAlertMessage}
            company={co}
            setChecked={setChecked}
            timeToUpgrade={timeToUpgrade}
            setBulkDrawer={setBulkDrawer}
            drawerData={drawerData}
            firebase={props.firebase}
            bulkDrawer={bulkDrawer}
            displayFilter={displayFilter}
         />
         <AuthenticatedTemplate>
            <SyncMicrosoftDrawer
               setAlertMessage={setAlertMessage}
               company={co}
               setChecked={setChecked}
               timeToUpgrade={timeToUpgrade}
               setSyncMicrosoftDrawer={setSyncMicrosoftDrawer}
               drawerData={drawerData}
               syncMicrosoftDrawer={syncMicrosoftDrawer}
               displayFilter={displayFilter}
               importType={importType}
               setImportType={setImportType}
            />
         </AuthenticatedTemplate>

         <Drawer
            sx={{
               width: '60vw',
               minWidth: '750px',

               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
            title="Bulk Actions"
            anchor="right"
            open={bulkDeleteDrawer}
            onClose={() => setBulkDeleteDrawer(false)}
         >
            <BulkActionsDrawer
               companyPeople={companyPeople}
               company={co}
               setChecked={setChecked}
               setAlertMessage={setAlertMessage}
               bulkDeleteDrawer={bulkDeleteDrawer}
               setBulkDeleteDrawer={setBulkDeleteDrawer}
               displayFilter={displayFilter}
            />
         </Drawer>
         <Snackbar open={checked} autoHideDuration={6000} onClose={snackbarClose}>
            <Alert onClose={snackbarClose} severity={alertMessage.type}>
               {alertMessage.message}
            </Alert>
         </Snackbar>

         <Container>
            <Grid container spacing={3}>
               <Grid item xs={12} sm={12} md={3}>
                  <LeftPanel
                     setPeopleDrawer={setPeopleDrawer}
                     setDrawerData={setDrawerData}
                     setBulkDrawer={setBulkDrawer}
                     setSyncMicrosoftDrawer={setSyncMicrosoftDrawer}
                     setBulkDeleteDrawer={setBulkDeleteDrawer}
                     companyPlan={companyPlan}
                     groups={groups}
                     groupFilter={groupFilter}
                     setGroupFilter={setGroupFilter}
                     setChecked={setChecked}
                     setAlertMessage={setAlertMessage}
                     displayFilter={displayFilter}
                     setImportType={setImportType}
                  />
                  <GroupListing
                     selectedLocationId={selectedLocationId}
                     selectedGroupId={selectedGroupId}
                     handleSelectGroup={handleSelectGroup}
                  />
               </Grid>
               <Grid item xs={12} sm={12} md={9}>
                  <PeopleTable
                     displayFilter={displayFilter}
                     setDisplayFilter={setDisplayFilter}
                     groupFilter={groupFilter}
                     setChecked={setChecked}
                     setGroups={setGroups}
                     setAlertMessage={setAlertMessage}
                     setPeopleDrawer={setPeopleDrawer}
                     setDrawerData={setDrawerData}
                     companyPeople={companyPeople}
                     setCompanyPeople={setCompanyPeople}
                     groups={groups}
                  />
               </Grid>
            </Grid>
         </Container>
      </PageLayout>
   );
};

const PeopleDirectoryPage = compose(withRouter, withFirebase)(PeopleDirectory);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(PeopleDirectoryPage);
