import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      '& > * + *': {
         marginTop: theme.spacing(3),
      },
   },
}));

const InternalPeople = (props) => {
   const classes = useStyles();

   const handleSpaceFeatures = (event, values) => {
      props.setPeopleVal(values);
   };

   return (
      <div className={classes.root}>
         <Autocomplete
            multiple
            id="tags-outlined"
            options={props.people}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={handleSpaceFeatures}
            value={props.peopleVal}
            renderTags={() => {}}
            renderInput={(params) => (
               <TextField
                  {...params}
                  variant="outlined"
                  label="Add People"
                  placeholder="Type their name"
               />
            )}
         />
      </div>
   );
};

export default InternalPeople;
