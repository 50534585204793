import React, { useState, useEffect } from 'react';
import { Grid, Divider } from '@mui/material';
import ScheduleMenu from '../People/menu';
import Month from './month';
import People from './people';
import Export from './export';
import { TabSet, TabSetContent, useTabSet } from '../layout';
import { PageLayout } from '../layout';
import FeatureSwitch from '../Billing/featureSwitch';

const Main = (props) => {
   const [tabValue, _, handleTabChange] = useTabSet();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [location, setLocation] = useState(null);
   const [displayFilter, setDisplayFilter] = useState(null);
   const [loading, setLoading] = useState(true);

   const tabNames = ['People', 'Location', 'Export'];

   async function getPrimaryLocation() {
      try {
         const result = await props.firebase.location(authUser.lid[0]).get();
         if (result.exists) {
            setDisplayFilter({ title: result.data().name, id: result.id });
         }
      } catch (err) {
         console.error('getPrimaryLocation', err);
      }
   }

   async function getDisplayFilter() {
      if (!displayFilter) return;
      try {
         const result = await props.firebase.location(displayFilter.id).get();
         setLocation({ ...result.data(), lid: result.id, cid: authUser.cid });
         setLoading(false);
      } catch (err) {
         console.error('getDisplayFilter', err);
         setLoading(false);
      }
   }

   useEffect(getPrimaryLocation, []);
   useEffect(getDisplayFilter, [displayFilter]);

   if (loading) {
      return null;
   }

   if (location && !location.reporting) {
      return (
         <PageLayout>
            <FeatureSwitch feature="reporting" />
         </PageLayout>
      );
   }

   return (
      <PageLayout>
         <Grid container>
            <Grid item xs={12} sm={4} sx={{ pt: 1 }}>
               <ScheduleMenu
                  displayFilter={displayFilter}
                  setDisplayFilter={setDisplayFilter}
               />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ pb: 3 }}>
               <TabSet value={tabValue} onChange={handleTabChange} tabNames={tabNames} />
               <Divider />
            </Grid>
            <Grid item xs={12}>
               <TabSetContent
                  tabValue={tabValue}
                  tabNames={tabNames}
                  contentArray={[
                     <People tab={tabValue} location={location} />,
                     <Month tab={tabValue} location={location} />,
                     <Export tab={tabValue} location={location} />,
                  ]}
               />
            </Grid>
         </Grid>
      </PageLayout>
   );
};

export default Main;
