import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core/';
import { withFirebase } from '../Firebase';
import SpaceCards from './spaceCards';
import { allocateBookings } from './functions';
import { Box } from '@mui/material';
import { naturalCompare } from './utils';
const useStyles = makeStyles((theme) => ({
   paper: {
      overflow: 'auto',
      whiteSpace: 'nowrap',
   },
}));

const FavouriteSpaceCards = (props) => {
   const classes = useStyles();
   const [everySpace, setEverySpace] = useState(false);
   const [spaces, setSpaces] = useState(null);
   let lid = props.displayFilter.id;
   if (props.displayFilter.type === 'group') {
      lid = props.displayFilter.lid;
   }
   useEffect(() => {
      if (props.user != null) {
         if (props.user.favouriteSpaces.length > 0) {
            const setFavouriteSpaces = props.firebase
               .getfavouriteSpaces(lid, props.user.favouriteSpaces)
               .onSnapshot(
                  (result) => {
                     let data = {};

                     if (!result.empty) {
                        result.forEach((doc) => {
                           if (!doc.data().archive && doc.data().bookable) {
                              data[doc.id] = {
                                 ...doc.data(),
                                 sid: doc.id,
                                 available: true,
                                 seatsAvailable: doc.data().seats,
                                 favourite: true,
                              };
                           }
                        });
                        if (Object.keys(data).length === 0) {
                           data = false;
                        }
                        setEverySpace(data);
                     }
                  },
                  (error) => {
                     console.log(error);
                  },
               );
            return () => setFavouriteSpaces();
         } else {
            setEverySpace(false);
         }
      }
   }, [props.user]);

   useEffect(() => {
      if (everySpace) {
         const removeBooked = props.firebase
            .spaceBookings(lid, props.date.ref)
            .onSnapshot((booked) => {
               let availableSpaces = { ...everySpace };
               if (booked.exists) {
                  availableSpaces = allocateBookings(
                     everySpace,
                     availableSpaces,
                     booked.data(),
                  );
               }
               // convert availableSpaces to an array
               availableSpaces = Object.values(availableSpaces);
               availableSpaces.sort((a, b) => {
                  if (a.order && b.order) {
                     return a.order - b.order;
                  } else {
                     return naturalCompare(a.name, b.name);
                  }
               });
               console.log(availableSpaces, 'availableSpaces');
               setSpaces(availableSpaces);
            });
         return () => removeBooked();
      } else {
         setSpaces(null);
      }
   }, [everySpace]);

   return (
      <Box sx={{ width: '100%' }}>
         <Box>
            <Typography variant="subtitle1" gutterBottom>
               Favourites
            </Typography>
         </Box>
         <Box>
            {spaces == null ? (
               <Typography variant="caption">No favourites set</Typography>
            ) : (
               <Paper elevation={0} className={classes.paper}>
                  {spaces.map((space) => (
                     <React.Fragment key={space.sid}>
                        <SpaceCards
                           dense={true}
                           user={props.user}
                           status={props.status}
                           location={{ id: lid, lid }}
                           date={props.date}
                           space={space}
                           dayTime={props.dayTime}
                        />
                     </React.Fragment>
                  ))}
               </Paper>
            )}
         </Box>
      </Box>
   );
};

export default withFirebase(FavouriteSpaceCards);
