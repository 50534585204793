import React, { useState } from 'react';
import { isFeatureEnabled, validateFeature } from '../IsEnabled';
import { features } from '../Billing/featureSwitch';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { useFirebase } from '../Firebase';
import { useSnackbar } from 'notistack';

const FeatureUpsellContent = ({ featureName, company, callBack = undefined }) => {
   const firebase = useFirebase();
   const { enqueueSnackbar } = useSnackbar();
   const feature = validateFeature(featureName);
   const [loading, setLoading] = useState(false);

   async function setupVisitors(visitorInternalDetails) {
      try {
         let results = await firebase.companyPeople(company.cid).get();
         if (!results.empty) {
            results.forEach((result) => {
               visitorInternalDetails[result.data().lid[0]][
                  result.data().email.toLowerCase()
               ] = `${result.data().name.firstName} ${result.data().name.lastName}`;
            });
         }
      } catch (err) {
         console.error(err, 'error at setupVisitors()');
      }
   }

   async function featureToggle(visitorInternalDetails) {
      try {
         await firebase.featureSwitch(company, feature, visitorInternalDetails);
         enqueueSnackbar(`${feature} has been enabled`, {
            variant: 'success',
            autoHideDuration: 3000,
         });
         if (callBack) {
            callBack();
         }
      } catch (err) {
         console.error(err, 'error at featureToggle()');
         enqueueSnackbar(`${feature} was not enabled. Please try again later`, {
            variant: 'error',
            autoHideDuration: 3000,
         });
      }
   }

   function handleToggleFeature() {
      setLoading(true);

      let visitorInternalDetails = {};
      Object.keys(company.locations).map((lid) => {
         visitorInternalDetails[lid] = {};
      });

      if (feature === 'visitors') {
         visitorInternalDetails = setupVisitors(visitorInternalDetails);
      }

      featureToggle(visitorInternalDetails);
      setLoading(false);
   }

   return (
      <Box textAlign="center">
         <Typography variant="h4" sx={{ mb: 3 }}>
            {features[feature].title}
         </Typography>

         <Typography>{features[feature].intro}</Typography>

         <Box sx={{ my: 4, textAlign: 'center' }}>
            <img
               src={features[feature].icon}
               alt={features[feature].iconAlt}
               style={{ width: '60%' }}
            />
         </Box>

         {isFeatureEnabled('groups', company.features) ? null : (
            <Box sx={{ my: 4, textAlign: 'center' }}>
               <Button
                  variant="outlined"
                  onClick={handleToggleFeature}
                  disabled={loading}
               >
                  {loading ? <CircularProgress /> : features[feature].button}
               </Button>
            </Box>
         )}

         <Typography sx={{ mb: 2 }}>{features[feature].terms}</Typography>
         <Typography variant="h6">
            {`${features[feature].released ? 'Available now' : 'Coming soon...'}`}
         </Typography>
      </Box>
   );
};

export default FeatureUpsellContent;
