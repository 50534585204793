import React, { useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import multiweekUpgrade from '../../../assets/multiweekUpgrade.png';
import {
   Avatar,
   Button,
   Dialog,
   DialogContent,
   DialogTitle,
   FormControlLabel,
} from '@mui/material';
import { useAuth } from '../../../providers';
import { useHistory } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { useTheme } from '@mui/material/styles';
import multiweekIcon from '../../../assets/multiweekIcon.svg';
import * as ROUTES from '../../../constants/routes';

const ViewDropdown = (props) => {
   // const authUser = JSON.parse(localStorage.getItem('authUser'));
   const theme = useTheme();
   const queryString = window.location.search;
   const history = useHistory();
   const { authUser } = useAuth();
   const [open, setOpen] = React.useState(false);

   function handleClose() {
      setOpen(false);
   }
   function getView() {
      const urlParams = new URLSearchParams(queryString);
      const view = urlParams.get('view');
      if (view !== null) {
         return view;
      } else {
         return null;
      }
   }
   useEffect(() => {
      // if () {
      const fromUrl = getView();

      if (fromUrl === null) {
         if (authUser.pagePreferences && authUser.pagePreferences.scheduleView) {
            if (authUser.pagePreferences.scheduleView.view) {
               props.setView(authUser.pagePreferences.scheduleView.view);
            } else {
               props.setView('week');
            }
            if (authUser.pagePreferences.scheduleView.hideWeekends) {
               props.setHideWeekends(authUser.pagePreferences.scheduleView.hideWeekends);
            }
         } else {
            props.setView('week');
            props.setHideWeekends(false);
         }
      } else if (fromUrl !== null) {
         props.setView(fromUrl);
         if (
            authUser.pagePreferences &&
            authUser.pagePreferences.scheduleView &&
            authUser.pagePreferences.scheduleView.hideWeekends
         ) {
            props.setHideWeekends(authUser.pagePreferences.scheduleView.hideWeekends);
         } else {
            props.setHideWeekends(false);
         }
      }
      // }
   }, [authUser.pagePreferences]);
   const handleWeekendToggle = async () => {
      props.setHideWeekends(!props.hideWeekends);
      try {
         await props.firebase.db.doc(`users/${authUser.uid}`).set(
            {
               pagePreferences: {
                  scheduleView: { hideWeekends: !props.hideWeekends, view: props.view },
               },
            },
            { merge: true },
         );
      } catch (error) {
         console.error('Error setting page preference', error);
      }
   };
   const handleViewSelection = async (e) => {
      if (e.target.value === 'multiweek' && !props.location.offsiteEnabled) {
         setOpen(true);
         return;
      } else {
         try {
            await props.firebase.db.doc(`users/${authUser.uid}`).set(
               {
                  pagePreferences: {
                     scheduleView: {
                        hideWeekends: props.hideWeekends,
                        view: e.target.value,
                     },
                  },
               },
               { merge: true },
            );
            history.push({
               pathname: '/schedule',
            });
            props.setView(e.target.value);
         } catch (error) {
            console.error('Error setting page preference', error);
         }
      }
   };
   return (
      <>
         <Dialog onClose={handleClose} open={open}>
            <DialogContent>
               <Box
                  sx={{
                     display: 'flex',
                     alignContent: 'center',
                     justifyContent: 'center',
                     alignItems: 'center',
                     // backgroundColor: theme.palette.capacityBackground.main,
                     flexDirection: 'column',
                  }}
               >
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                     <Avatar alt="Multiweek View" variant="rounded" src={multiweekIcon} />
                  </Box>
                  <Box
                     sx={{
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                     }}
                  >
                     <Typography variant="h6" component="h2" align="center" paragraph>
                        Multiweek View
                     </Typography>
                     <Typography variant="body1" component="p" paragraph align="center">
                        View your teams statuses across multiple weeks so you can plan
                        where you're going to work further in advance.
                     </Typography>
                     <Typography variant="body1" component="p" paragraph align="center">
                        Multiweek view is part of the Advanced Scheduling feature set.{' '}
                        {authUser?.roles?.ADMIN ? `You` : `Your admin`} can enable
                        Advanced Scheduling from the Settings page.
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        height: '50vh',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <img
                        src={multiweekUpgrade}
                        alt="multiweek upgrade image"
                        height="auto"
                        width="100%"
                     />
                  </Box>
                  {authUser?.roles?.ADMIN && (
                     <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Button
                           href={ROUTES.SETTINGS}
                           variant="contained"
                           color="primary"
                        >
                           Go to Settings
                        </Button>
                     </Box>
                  )}
               </Box>
            </DialogContent>
         </Dialog>
         <FormControl fullWidth>
            {props.view && (
               <Select
                  id="view-select"
                  value={props.view}
                  onChange={(e) => handleViewSelection(e)}
                  variant="standard"
                  displayEmpty
                  sx={{ fontSize: '0.8rem' }}
               >
                  <MenuItem value="day">
                     <Box display="flex" alignItems="center">
                        <TodayOutlinedIcon
                           fontSize="small"
                           color="disabled"
                           style={{ marginRight: 8 }}
                        />
                        <Typography variant="caption">Day</Typography>
                     </Box>
                  </MenuItem>
                  <MenuItem value="week">
                     <Box display="flex" alignItems="center">
                        <CalendarViewWeekIcon
                           fontSize="small"
                           color="disabled"
                           style={{ marginRight: 8 }}
                        />
                        <Typography variant="caption">Week</Typography>
                     </Box>
                  </MenuItem>
                  <MenuItem value="multiweek">
                     <Box display="flex" alignItems="center">
                        <CalendarMonthOutlinedIcon
                           fontSize="small"
                           color="disabled"
                           style={{ marginRight: 8 }}
                        />
                        <Typography variant="caption">Multiweek</Typography>
                     </Box>
                  </MenuItem>

                  <Box display="flex" alignItems="center">
                     <FormControlLabel
                        sx={{ ml: 1 }}
                        label={
                           <Typography sx={{ pr: 1 }} variant="caption">
                              Hide Sat/Sun
                           </Typography>
                        }
                        control={
                           <Checkbox
                              sx={{ p: 1, '& .MuiSvgIcon-root': { fontSize: 20 } }}
                              checked={props.hideWeekends}
                              onChange={handleWeekendToggle}
                              color="primary"
                           />
                        }
                     />
                  </Box>
               </Select>
            )}
         </FormControl>
      </>
   );
};

export default withFirebase(ViewDropdown);
