import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import ItoMiniAm from '../../../assets/miniChips/AM/ItoMiniAm';
import ItoMiniAmNotBooked from '../../../assets/miniChips/AM/ItoMiniAmNotBooked';
import OffsiteMiniAm from '../../../assets/miniChips/AM/OffsiteMiniAm';
import AwayMiniAm from '../../../assets/miniChips/AM/AwayMiniAm';
import WfhMiniAm from '../../../assets/miniChips/AM/WfhMiniAm';
import BlankMiniAm from '../../../assets/miniChips/AM/BlankMiniAm';

import OffsiteMiniPm from '../../../assets/miniChips/PM/OffsiteMiniPm';
import AwayMiniPm from '../../../assets/miniChips/PM/AwayMiniPm';
import WfhMiniPm from '../../../assets/miniChips/PM/WfhMiniPm';
import ItoMiniPm from '../../../assets/miniChips/PM/ItoMiniPm';
import ItoMiniPmNotBooked from '../../../assets/miniChips/PM/ItoMiniPmNotBooked';
import BlankMiniPm from '../../../assets/miniChips/PM/BlankMiniPm';
import ItoMiniBothBooked from '../../../assets/miniChips/ItoMiniBothBooked';

const SplitMiniChips = (props) => {
   const [bothBooked, setBothBooked] = useState(false);
   const [amBooked, setAmBooked] = useState(false);
   const [pmBooked, setPmBooked] = useState(false);
   const splitAllBooked = {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '40px !important',
   };
   const splitAmStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
   };
   const splitPmStyles = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      '& .MuiSvgIcon-root': {
         width: '26px',
         height: '29px',
      },
   };
   useEffect(() => {
      if (props.dayStatus && props.dayStatus.bookings !== null) {
         let amBooking = false;
         let pmBooking = false;
         props.dayStatus.bookings.forEach((booking) => {
            if (booking.type === 'AM') {
               amBooking = true;
            }
            if (booking.type === 'PM') {
               pmBooking = true;
            }
         });
         if (props.value.splitValues.AM === 'ito' && amBooking) {
            setAmBooked(true);
         } else {
            setAmBooked(false);
         }
         if (props.value.splitValues.PM === 'ito' && pmBooking) {
            setPmBooked(true);
         } else {
            setPmBooked(false);
         }
         if (
            amBooking &&
            pmBooking &&
            props.value.splitValues.AM === 'ito' &&
            props.value.splitValues.PM === 'ito'
         ) {
            setBothBooked(true);
         } else {
            setBothBooked(false);
         }
      } else {
         setBothBooked(false);
         setAmBooked(false);
         setPmBooked(false);
      }
   }, [props.value, props.dayStatus]);

   return (
      <Box sx={{ mt: 0.5, display: 'block', width: '100%', height: '100%' }}>
         {bothBooked ? (
            <ItoMiniBothBooked sx={splitAllBooked} className="custom-svg-icon" />
         ) : (
            <>
               {props.value.splitValues.AM === 'ito' ? (
                  <>
                     {amBooked ? (
                        <ItoMiniAm sx={splitAmStyles} />
                     ) : (
                        <ItoMiniAmNotBooked sx={splitAmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.AM === 'away' ? (
                  <AwayMiniAm sx={splitAmStyles} />
               ) : props.value.splitValues.AM === 'wfh' ? (
                  <WfhMiniAm sx={splitAmStyles} />
               ) : props.value.splitValues.AM === 'offsite' ? (
                  <OffsiteMiniAm sx={splitAmStyles} />
               ) : (
                  <BlankMiniAm sx={splitAmStyles} />
               )}
               {props.value.splitValues.PM === 'ito' ? (
                  <>
                     {pmBooked ? (
                        <ItoMiniPm sx={splitPmStyles} />
                     ) : (
                        <ItoMiniPmNotBooked sx={splitPmStyles} />
                     )}
                  </>
               ) : props.value.splitValues.PM === 'away' ? (
                  <AwayMiniPm sx={splitPmStyles} />
               ) : props.value.splitValues.PM === 'wfh' ? (
                  <WfhMiniPm sx={splitPmStyles} />
               ) : props.value.splitValues.PM === 'offsite' ? (
                  <OffsiteMiniPm sx={splitPmStyles} />
               ) : (
                  <BlankMiniPm sx={splitPmStyles} />
               )}
            </>
         )}
      </Box>
   );
};
export default SplitMiniChips;
