import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../Firebase';
import { Box } from '@mui/material';
import useFirebaseSnapshot from '../../../hooks/useFirebaseSnapshot';
import { DateToday } from '../../../constants/functions';
import VisitorListAccordion from '../../Interactions/visitorListAccordion';
import { useAuth } from '../../../providers';
import { objectToArrayWithKeyIds } from '../../../utils/objectToArray';
import { loadVisitors } from '../functions';

const RegisteredVisitors = ({ displayFilter, date, handleEditModal }) => {
   const { authUser } = useAuth();
   const firebase = useFirebase();

   const { loading, data: dayVisitors } = useFirebaseSnapshot(firebase.findHost, {
      args: [displayFilter.id, date.ref],
      dependencies: [displayFilter.id, date.ref],
   });

   if (loading) return null;
   return (
      <Box>
         {!loading && (
            <VisitorListAccordion
               cid={authUser.cid}
               lid={displayFilter.id}
               date={date.ref}
               dayVisitors={loadVisitors(dayVisitors)}
               handleEditModal={handleEditModal}
            />
         )}
      </Box>
   );
};

export default RegisteredVisitors;
