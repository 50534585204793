import React, { useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import SeatIcon from './seatIcon';
import ToolTipContent from './toolTipContent';

const Seat = (props) => {
   const [open, setOpen] = useState(false);
   const [isHovered, setIsHovered] = useState(false);
   const [openedByTouch, setOpenedByTouch] = useState(false);
   const [touchMoved, setTouchMoved] = useState(false);

   const handleTouchStart = (e) => {
      e.preventDefault();
   };
   const handleTouchMove = () => {
      setTouchMoved(true);
   };
   const handleTouchEnd = (e) => {
      e.preventDefault();
      if (!touchMoved) {
         setOpen(true);
         setOpenedByTouch(true);
         setTimeout(() => {
            setOpen(false);
            setOpenedByTouch(false);
         }, 2000);
      }
      setTouchMoved(false);
   };
   const handleClose = () => {
      if (!openedByTouch) {
         setOpen(false);
         setIsHovered(false);
         setOpenedByTouch(false);
      }
   };

   const handleOpen = () => {
      setOpen(true);
   };

   const handleMouseEnter = () => {
      setIsHovered(true);
      setOpenedByTouch(false);
   };

   const handleMouseLeave = () => {
      setIsHovered(false);
   };

   return (
      <Tooltip
         title={
            <ToolTipContent
               space={props.space}
               booked={props.booked}
               booking={props.booking}
               multi={props.multi}
               day={props.day}
               userData={props.userData}
               setSpaceMap={props.setSpaceMap}
               setMap={props.setMap}
            />
         }
         open={open}
         onClose={handleClose}
         onOpen={handleOpen}
         placement="top"
         arrow
      >
         <div>
            <div style={{ position: 'relative' }}>
               <IconButton
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
               >
                  <React.Fragment>
                     <SeatIcon
                        multi={props.multi}
                        booking={props.booking}
                        booked={props.booked}
                        isHovered={isHovered}
                     />
                  </React.Fragment>
               </IconButton>
            </div>
         </div>
      </Tooltip>
   );
};

export default Seat;
