import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { withFirebase } from '../../Firebase';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import HourlyCapacity from './hourlyCapacity';
import Seats from './seats';
import BookButton from './bookButton';

const useStyles = makeStyles((theme) => ({
   expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
         duration: theme.transitions.duration.shortest,
      }),
   },
   cardAction: {
      backgroundColor: theme.palette.cardAction.main,
   },
   expandOpen: {
      transform: 'rotate(180deg)',
   },
   center: {
      margin: 'auto 0',
      textAlign: 'center',
   },
}));

const BookingForm = (props) => {
   const classes = useStyles();
   const [expanded, setExpanded] = useState(false);
   const [selectError, setSelectError] = useState({ show: false, message: '' });

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };

   function handleChange(e) {
      props.setBookingForm({
         ...props.bookingForm,
         type: e.target.value,
         typeName: props.bookingOptions[e.target.value],
      });
   }
   function changeSeats(e) {
      props.setBookingForm({ ...props.bookingForm, seats: e.target.value });
   }

   return (
      <React.Fragment>
         {props.bookingForm && (
            <React.Fragment>
               <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent className={classes.cardAction}>
                     {props.dense && props.bookingForm.type !== undefined && (
                        <React.Fragment>
                           {' '}
                           <Seats time={props.bookingForm.type} space={props.space} />
                        </React.Fragment>
                     )}
                     <FormControl fullWidth>
                        <InputLabel id="timeType">Time</InputLabel>
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={props.bookingForm.type}
                           defaultValue={props.bookingForm.type}
                           label="Time"
                           onChange={(e) => handleChange(e)}
                        >
                           {Object.entries(props.bookingOptions).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                 {value}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <TextField
                        id="outlined-name"
                        error={selectError.show}
                        helperText={selectError.message}
                        type="number"
                        //  native
                        InputProps={{
                           inputProps: { min: 1, max: props.space.seatsAvailable },
                        }}
                        label="Seats"
                        value={props.bookingForm.seats}
                        onChange={(e) => changeSeats(e)}
                     />
                  </CardContent>
               </Collapse>
               <CardActions className={classes.cardAction} disableSpacing>
                  {props.date && props.bookingForm?.type !== 4 && (
                     <BookButton
                        date={props.date}
                        dense={props.dense}
                        status={props.status}
                        space={props.space}
                        bookingForm={props.bookingForm}
                        setSelectError={setSelectError}
                        location={props.location}
                     />
                  )}
                  {props.bookingForm.type === 4 && !props.bookingForm.time && (
                     <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                           let data = {
                              date: props.date.ref,
                              location: {
                                 id: props.location.id,
                              },
                              space: props.space,
                              spaceSchedule: { ...props.spaceSchedule },
                              cid: props.user.cid,
                              existing: false,
                              booking: null,
                              edit: true,
                           };
                           props.setDetailsData(data);
                           props.setDetailsOpen(true);
                        }}
                     >
                        More
                     </Button>
                  )}
                  {props.space.timePeriod.hourly && (
                     <Container className={classes.center}>
                        <Typography variant="caption" color="textSecondary">
                           <HourlyCapacity cap={props.spaceSchedule.capacity} />
                        </Typography>
                     </Container>
                  )}
                  {props.bookingForm.type !== 4 && (
                     <IconButton
                        className={clsx(classes.expand, {
                           [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                     >
                        <ExpandMoreIcon />
                     </IconButton>
                  )}
               </CardActions>
            </React.Fragment>
         )}
      </React.Fragment>
   );
};
export default withFirebase(BookingForm);
