import { Box } from '@mui/material';
import React from 'react';
import {
   LineChart,
   Line,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer,
} from 'recharts';

function stringToColor(string) {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   return color;
}

const LineChartComponent = ({ data, labels }) => {
   return (
      <Box sx={{ width: '100%', height: 300 }}>
         <ResponsiveContainer width="100%" height="100%">
            <LineChart width={600} height={300} data={data}>
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
               <YAxis />
               <Tooltip />
               <Legend verticalAlign="top" />
               {labels.map((label) => {
                  return (
                     <Line
                        id={label.dataKey}
                        key={label.dataKey}
                        type="monotone"
                        dataKey={label.dataKey}
                        name={label.name}
                        stroke={stringToColor(label.name)}
                     />
                  );
               })}
            </LineChart>
         </ResponsiveContainer>
      </Box>
   );
};

export default LineChartComponent;
