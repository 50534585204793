import React, { useState } from 'react';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CalloutBox = ({ contents, sx }) => {
   const [copied, setCopied] = useState(false);

   const handleCopy = () => {
      navigator.clipboard.writeText(contents);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
   };

   return (
      <Box
         sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '16px',
            position: 'relative',
            backgroundColor: '#f9f9f9',
            ...sx,
         }}
      >
         <Typography variant="body1">{contents}</Typography>
         <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
            <Button
               onClick={handleCopy}
               variant="contained"
               color="primary"
               startIcon={<ContentCopyIcon />}
               sx={{ position: 'absolute', top: '8px', right: '8px' }}
            >
               Copy
            </Button>
         </Tooltip>
      </Box>
   );
};

export default CalloutBox;
