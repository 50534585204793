import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../../Firebase';
import { getDay } from '../../../../constants/functions';
import { Box, Divider, Typography } from '@mui/material';

import { set } from 'date-fns';

const CompanyDetails = ({ company }) => {
   const [dayDetails, setDayDetails] = useState(null);
   const [peopleCount, setPeopleCount] = useState(0);

   const firebase = useFirebase();

   useEffect(() => {
      if (company) {
         const date = getDay(new Date(company.time.toDate()));

         firebase.db
            .doc(`usageLogs/companies/company/${company.cid}/dates/${date.ref}`)
            .get()
            .then((doc) => {
               if (doc.exists) {
                  let count = 0;
                  Object.keys(doc.data()).forEach((key) => {
                     count++;
                  });
                  setPeopleCount(count);
                  setDayDetails(doc.data());
               }
            });
      }
   }, [company]);

   return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
         <Box sx={{ mr: 1 }}>
            <Typography>
               {peopleCount}/{company.totalPeople}
            </Typography>
            <Typography variant="caption">People</Typography>
         </Box>
         <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
         <Box sx={{ mr: 1 }}>
            <Typography>{dayDetails && dayDetails.totalPageViews}</Typography>
            <Typography variant="caption">Total views</Typography>
         </Box>
         <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
         <Box sx={{ mr: 1, maxWidth: '100px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
               {company.features && (
                  <>
                     {Object.entries(company.features).map(([key, value]) => {
                        if (value) {
                           return (
                              <Typography variant="caption" key={key} sx={{ mr: 1 }}>
                                 {key}
                              </Typography>
                           );
                        }
                     })}
                  </>
               )}
            </Box>
         </Box>
      </Box>
   );
};

export default CompanyDetails;
