import { Box, useTheme } from '@mui/material';
import React, { useState } from 'react';
import DateScroller from '../DateScroller';
import { DateToday } from '../../constants/functions';
import SpacesDay from '../Schedule/Views/dayView/spacesDay';

const MeetingDrawer = (props) => {
   const theme = useTheme();
   const [date, setDate] = useState(DateToday());
   return (
      <Box sx={{ m: theme.spacing(2) }}>
         <DateScroller date={date} setDate={setDate} />
         <SpacesDay
            displayFilter={props.displayFilter}
            day={date}
            location={props.location}
            tab={2}
            kiosk={false}
         />
      </Box>
   );
};

export default MeetingDrawer;
