import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const Main = () => {
   return (
      <Box>
         <h1>Main</h1>
      </Box>
   );
};

export default Main;
