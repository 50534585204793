import * as R from 'ramda';

export const sortObjByProperty = (object, objectPropertyName) => {
   const sortFunction = R.sortBy(R.path(['name']));
   return sortFunction(shapedData);
};

export const sortArrayOfPeopleObjectsByFirstName = (objectArray) => {
   const byFirstName = R.ascend(R.compose(R.prop('firstName'), R.prop('name')));
   return R.sort(byFirstName, objectArray);
};
