export function getAddedRemovedMembers(currentMembers, newMembers) {
   return {
      removed: Object.keys(currentMembers).filter((id) => !newMembers[id]),
      previous: Object.keys(currentMembers),
      current: Object.keys(newMembers),
      added: Object.keys(newMembers).filter((id) => !currentMembers[id]),
   };
}

export const updateGroupObject = (userObject, groupId, isAdmin) => {
   return {
      ...userObject,
      gid: [...new Set([...userObject.gid, groupId])],
      groupAdmins: isAdmin
         ? [...new Set([...userObject.groupAdmins, groupId])]
         : [...new Set(userObject.groupAdmins.filter((gid) => gid !== groupId))],
   };
};

export const removeGroupObject = (userObject, groupId) => {
   return {
      ...userObject,
      gid: userObject.gid.filter((gid) => gid !== groupId),
      groupAdmins: userObject.groupAdmins.filter((gid) => gid !== groupId),
   };
};
