import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import KeyIcon from '@mui/icons-material/Key';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';

export const roleList = [
   {
      roleId: 1,
      label: 'Health and Safety Officer',
      icon: HealthAndSafetyIcon,
      alias: 'health-and-safety-officer',
   },
   {
      roleId: 2,
      label: 'First Aid Responder',
      icon: MedicalServicesIcon,
      alias: 'first-aid-responder',
   },
   {
      roleId: 3,
      label: 'Office Key Holder',
      icon: KeyIcon,
      alias: 'office-key-holder',
   },
   {
      roleId: 4,
      label: 'Fire Marshal/Fire Warden',
      icon: FireExtinguisherIcon,
      alias: 'fire-marshal-fire-warden',
   },
   // 'Evacuation Coordinator',
   // 'Security Officer',
   // 'Hazardous Materials Handler',
   // 'Safety Committee Member',
   // 'Ergonomics Specialist',
   // 'CPR/AED Instructor',
   // 'Health and Wellness Coordinator',
   // 'Environmental Health and Safety (EHS) Officer',
   // 'Incident Commander',
   // 'Training Coordinator',
   // 'Emergency Response Team Member',
   // 'Building Maintenance Supervisor',
   // 'Sustainability Coordinator',
   // 'Data Protection Officer',
   // 'Diversity and Inclusion Advocate',
   // 'Privacy Officer',
];

export const roleByAliasMap = () =>
   roleList.reduce(
      (acc, role) => ({
         ...acc,
         [role.alias]: role,
      }),
      {},
   );
