import * as R from 'ramda';
import { objectToArrayWithKeyIds } from '../../../utils/objectToArray';

const defaultResponse = {
   localGroups: [],
   globalGroups: [],
};

export const sortFunction = (response) => {
   if (!!Object.keys(response).length) {
      const arr = objectToArrayWithKeyIds(response, 'gid');
      return arr.reduce(
         (acc, group) =>
            group && group.global
               ? {
                    globalGroups: R.sortBy(R.path(['name']))([
                       ...acc.globalGroups,
                       group,
                    ]),
                    localGroups: R.sortBy(R.path(['name']))([...acc.localGroups]),
                 }
               : {
                    globalGroups: R.sortBy(R.path(['name']))([...acc.globalGroups]),
                    localGroups: R.sortBy(R.path(['name']))([...acc.localGroups, group]),
                 },
         defaultResponse,
      );
   }
   return defaultResponse;
};
