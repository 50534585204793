import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
   },
   container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(0),
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   leftBar: {
      paddingTop: theme.spacing(4),
   },
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   drawerPaper: {
      width: '60%',
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   deleteButton: {
      margin: theme.spacing(3, 0, 2),
      float: 'right',
   },
   checkbox: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         marginLeft: theme.spacing(0),
      },
   },
}));
const blankForm = { name: '', virtual: false };

const LocationDrawer = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [form, setForm] = useState(blankForm);

   const saveLocation = async (e) => {
      e.preventDefault();
      var mainLocation = await props.firebase.location(props.locations[0].id).get();
      var locAdmins = mainLocation.data().locationAdmins;
      var newLocationKey = await props.firebase.locations().doc();
      user.lid.push(newLocationKey.id);
      if (user.locationAdmin) {
         user.locationAdmin.push(newLocationKey.id);
      } else {
         user.locationAdmin = [newLocationKey.id];
      }
      try {
         props.setLocationDrawer(false);
         await props.firebase.newLocation(
            newLocationKey.id,
            user,
            form,
            props.company,
            locAdmins,
         );
         localStorage.setItem('authUser', JSON.stringify(user));
         setForm(blankForm);
         props.setSnackBar({
            display: true,
            type: 'success',
            message: form.name + ' has been created',
         });
      } catch (error) {
         console.log(error);
         props.setSnackBar({
            display: true,
            type: 'error',
            message: 'Oops, something went wrong. Please try again later',
         });
      }
   };

   const handleChange = (event) => {
      setForm({ ...form, [event.target.name]: event.target.checked });
   };

   return (
      <Drawer
         classes={{ paper: classes.drawerPaper }}
         anchor="right"
         open={props.locationDrawer}
         onClose={() => {
            props.setLocationDrawer(false);
            setForm(blankForm);
         }}
      >
         <Container maxWidth="xs">
            <div className={classes.drawer}>
               <Typography component="h1" variant="h5">
                  Add a new Location
               </Typography>
               <form className={classes.form} noValidate onSubmit={saveLocation}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <TextField
                           name="name"
                           variant="outlined"
                           fullWidth
                           id="name"
                           label="Name"
                           value={form.name}
                           onInput={(e) => setForm({ ...form, name: e.target.value })}
                           autoFocus
                        />
                     </Grid>
                     {/* <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type of location/area</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={form.virtual} onChange={handleChange} name="virtual" />}
                        label="Virtual Area"
                      />
                    </FormGroup>
                    <FormHelperText>Is this a physical location, e.g. a building or room with desks, or is it a virtual location?</FormHelperText>
                    </FormControl>
                  </Grid> */}
                  </Grid>
                  <Button
                     type="submit"
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                  >
                     Save
                  </Button>
                  <Button
                     variant="text"
                     onClick={() => {
                        props.setLocationDrawer(false);
                        setForm(blankForm);
                     }}
                  >
                     Discard
                  </Button>
               </form>
            </div>
         </Container>
      </Drawer>
   );
};

export default withFirebase(LocationDrawer);
