import firebase from 'firebase/app';
import { usePersonLog, useSpaceLog } from '../firebaseRefs';
import { type } from 'ramda';

export const useSetStatusLogging = (batch, user, date, status, authUser, drawer) => {
   let url = window.location.pathname;
   let newLogEntry = {
      type: 'status',
      status,
      actionBy: authUser.uid,
      timestamp: new Date(),
      url,
      query: window.location.search,
      drawer,
   };
   batch.set(
      usePersonLog(user.cid, user.uid, date),
      { status: firebase.firestore.FieldValue.arrayUnion(newLogEntry) },
      { merge: true },
   );
   return;
};

export const useBookSpaceForUser = (
   batch,
   lid,
   sid,
   date,
   uid,
   form,
   authUser,
   drawer = false,
) => {
   let details = form;
   let cid = '';
   if (form.start) {
      cid = form.cid;
   } else {
      cid = form.user.cid;
      delete details.user;
   }

   let personLogEntry = {
      type: 'booking',
      sid,
      lid,
      bookingDetails: details,
      actionBy: authUser.uid,
      timestamp: new Date(),
      urlHost: window.location.hostname,
      urlPath: window.location.pathname,
      urlQuery: window.location.search,
      drawer,
   };
   batch.set(
      usePersonLog(cid, uid, date),
      { booking: firebase.firestore.FieldValue.arrayUnion(personLogEntry) },
      {
         merge: true,
      },
   );
   let spaceLogEntry = {
      type: 'booking',
      uid,
      bookingDetails: details,
      actionBy: authUser.uid,
      timestamp: new Date(),
      url: window.location.pathname,
      urlQuery: window.location.search,
      drawer,
   };
   batch.set(
      useSpaceLog(lid, sid, date),
      { booking: firebase.firestore.FieldValue.arrayUnion(spaceLogEntry) },
      { merge: true },
   );
};

export const useCancelBookingLog = (batch, data, authUser, drawer = false) => {
   const uid = data.user.uid;
   const cid = data.user.cid;
   const date = data.date.ref;
   let update = {
      type: 'bookingCancelled',
      actionBy: authUser.uid,
      timestamp: new Date(),
      urlHost: window.location.hostname,
      urlPath: window.location.pathname,
      urlQuery: window.location.search,
      drawer,
   };
   Object.entries(data.space).map(([key, value]) => {
      if (key !== 'bookingNo') {
         let lid = value.lid;
         let sid = value.sid;
         if (value?.timePeriod?.hourly) {
            update.bookingDetails = {
               seat: value.seats,
               typeName: 'hourly',
               start: value.start,
               end: value.end,
               title: value.title,
            };
         } else {
            update.bookingDetails = {
               seat: value.seat,
               typeName: value.type,
            };
         }
         batch.set(
            usePersonLog(cid, uid, date),
            {
               booking: firebase.firestore.FieldValue.arrayUnion({ sid, lid, ...update }),
            },
            { merge: true },
         );
         batch.set(
            useSpaceLog(lid, sid, date),
            { booking: firebase.firestore.FieldValue.arrayUnion({ uid, ...update }) },
            { merge: true },
         );
      }
   });
};
