import React from 'react';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AvailabilityList from '../availabilityList';

const HtmlTooltip = styled(({ className, ...props }) => (
   <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
      width: 220,
      boxShadow: theme.shadows[1],
      fontSize: 'large',
   },
}));

var i = 0;

const WfhChip = (props) => {
   function handleMore(value) {
      props.setSpaceData(props.data);
      props.setStatusDrawerOpen(true);
   }
   if (props.availability && !props.clickable) {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               clickable={props.clickable}
               size="medium"
               variant="outlined"
               label={<HomeIcon style={{ marginTop: 5 }} />}
               color="primary"
            />
         </HtmlTooltip>
      );
   } else if (props.routineEnabled && props.availability && props.clickable) {
      return (
         <HtmlTooltip
            title={
               <React.Fragment>
                  <Grid container>
                     {props.availability && (
                        <Grid item xs={12}>
                           <List dense={true}>
                              {props.availability.map((time) => {
                                 i++;
                                 return <AvailabilityList key={i} time={time} />;
                              })}
                           </List>
                        </Grid>
                     )}
                  </Grid>
               </React.Fragment>
            }
         >
            <Chip
               clickable={props.clickable}
               size="medium"
               variant="outlined"
               label={<HomeIcon style={{ marginTop: 5 }} />}
               color="primary"
               deleteIcon={<MoreVertIcon />}
               onDelete={() => handleMore(props.value.set)}
            />
         </HtmlTooltip>
      );
   } else if (props.clickable && !props.availability && props.routineEnabled) {
      return (
         <Chip
            clickable={props.clickable}
            size="medium"
            variant="outlined"
            label={<HomeIcon style={{ marginTop: 5 }} />}
            color="primary"
            deleteIcon={<MoreVertIcon />}
            onDelete={() => handleMore(props.value.set)}
         />
      );
   } else {
      return (
         <Chip
            clickable={props.clickable}
            size="medium"
            variant="outlined"
            label={<HomeIcon color="primary" style={{ marginTop: 5 }} />}
            color="primary"
         />
      );
   }
};

export default WfhChip;
