import React from 'react';
import Chip from '@material-ui/core/Chip';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const AwayChip = (props) => {
   return (
      <Chip
         clickable={props.clickable}
         size="medium"
         variant="outlined"
         label={<DoNotDisturbOnIcon style={{ marginTop: 5 }} />}
         color="secondary"
         value={props.value.set}
      />
   );
};

export default AwayChip;
