import { createTheme } from '@mui/material';
import themeBase from './themeBase';

const legacyThemeLight = createTheme({
   ...themeBase('light'),
   palette: {
      background: {
         paper: '#fff',
         default: '#fafafa',
      },
      mode: 'light',
      primary: {
         main: '#342E76',
      },
      secondary: {
         main: '#DB3D28',
      },
      teal: {
         main: '#32BFC7',
      },
      tableHead: {
         main: '#fafafa',
      },
      tableBody: {
         main: '#fff',
      },
      cardHeader: {
         main: '#fff',
      },
      cardAction: {
         main: '#fafafa',
      },
      text: {
         primary: '#000',
         secondary: '#646464',
         success: 'green',
         warning: '#F7C843',
      },
      whiteButtonHoverText: {
         primary: '#fff',
      },
      ito: {
         main: '#342E76',
      },
      wfh: {
         main: '#32BFC7',
      },
      away: {
         main: '#DB3D28',
      },
      offsite: {
         main: '#646464',
      },
      capacityBackground: {
         main: '#E6E6E6',
      },
      success: {
         main: '#9ECF99',
      },
      warning: {
         main: '#F7E943',
      },
      availableTime: {
         main: '#ff9900',
      },
      background: {
         default: '#fafafa',
      },
   },
});

export default legacyThemeLight;
