import React from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

const CarReg = ({ customisations, form, setForm, setStatus, clearForm }) => {
   const [carReg, setCarReg] = React.useState('');

   async function saveReg() {
      setForm({ ...form, carReg });
      if (customisations?.contactNumber && form?.contactNumber == undefined) {
         setStatus('contactNumber');
      } else {
         setStatus('step2');
      }
   }

   return (
      <Box>
         <Typography variant="h5">Car Parking</Typography>
         <Typography variant="body1">
            If you have used our car park, please enter your registration number.
         </Typography>
         <TextField
            value={carReg}
            onChange={(e) => setCarReg(e.target.value)}
            label="Car Registration"
            variant="outlined"
            margin="normal"
            fullWidth
         />
         <Box>
            <Button
               onClick={() => saveReg()}
               variant="contained"
               fullWidth
               sx={{ mb: 2 }}
               color="primary"
            >
               {carReg === '' ? 'Skip' : 'Next'}
            </Button>
            <Button
               onClick={() => clearForm()}
               variant="outlined"
               fullWidth
               color="primary"
            >
               Start again
            </Button>
         </Box>
      </Box>
   );
};

export default CarReg;
