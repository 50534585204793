import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import wfhBackground from '../../../assets/wfh.svg';
import wfhDarkBackground from '../../../assets/wfh-dark.svg';
import { Box } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      justifyContent: 'center',
   },
}));

const WfhBlock = (props) => {
   const classes = useStyles();
   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   return (
      <div className={classes.root}>
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               mt: 6,
            }}
         >
            <img src={prefersDarkMode ? wfhDarkBackground : wfhBackground} alt="WFH" />
         </Box>
      </div>
   );
};

export default WfhBlock;
