export async function CalculateCapacity(location, level) {
   var capNumber = null;
   var capCapacity = 100;

   const capSettings = location.capacity;
   const capUnit = location.capacity.unit;
   if (capSettings[capUnit]) {
      if (capSettings[capUnit].number) {
         capNumber = capSettings[capUnit].number;
      } else {
         capNumber = location.people;
      }
      if (capSettings[capUnit].capacity) {
         capCapacity = capSettings[capUnit].capacity;
      }
   } else {
      capNumber = location.people;
   }

   var maxPercentage = (capCapacity / 100) * capNumber;
   var percent = (level / maxPercentage) * 100;
   percent = +percent.toFixed(0);
   return percent;
}
