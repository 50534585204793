import React from 'react';
import SideBarItem from './SideBarItem';
import SideBarMoreMenu from './SideBarMoreMenu';
import { MAIN_AUTHENTICATED_ADMIN_ROUTES } from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import { useFirebase } from '../../Firebase';
import useFirebaseRequest from '../../../hooks/useFirebaseRequest';

const AdminNav = ({ authUser }) => {
   // const firebase = useFirebase();
   // const { loading, data, error } = useFirebaseRequest(firebase.company, {
   //    args: [authUser.cid],
   // });
   // console.log(data);

   let shownRoutes = [];
   // let disabledRoutes = [];
   // const isUser = Object.values(authUser.roles).includes('STAFF'); // boolean
   // const isAdmin = Object.values(authUser.roles).includes('ADMIN'); // boolean

   // if (isUser) {
   shownRoutes = Object.values(MAIN_AUTHENTICATED_ADMIN_ROUTES).filter((route, index) => {
      return route;
   });
   //       if (route.dynamic && data?.features?.[route.alias]) {
   //          return route;
   //       } else if (route.accessLevel.includes(ROLES.STAFF) && !route.dynamic) {
   //          return route;
   //       } else if (
   //          route.accessLevel.includes(ROLES.STAFF) &&
   //          route.name !== 'Dashboard'
   //       ) {
   //          //temporarily disabled dashboard
   //          disabledRoutes.push(route);
   //       }
   //    });
   // }

   // if (isAdmin) {
   //    shownRoutes = Object.values(MAIN_AUTHENTICATED_ROUTES).filter((route, index) => {
   //       if (route.dynamic && data?.features?.[route.alias]) {
   //          return route;
   //       } else if (route.accessLevel.includes(ROLES.ADMIN) && !route.dynamic) {
   //          return route;
   //       } else if (route.name !== 'Dashboard') {
   //          //temporarily disabled dashboard
   //          disabledRoutes.push(route);
   //       }
   //    });
   // }

   return (
      <>
         {shownRoutes.map((navItem, index) => (
            <SideBarItem key={index} navItem={navItem} />
         ))}
         {/* {disabledRoutes.length > 0 && <SideBarMoreMenu navItems={disabledRoutes} />} */}
      </>
   );
};

export default AdminNav;

// staff = authUser.roles.USER
