import React from 'react';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import dedicatedDesk from '../../assets/dedicated_desk.svg';
import coworkingSpace from '../../assets/coworking_space.svg';
import meetingRoom from '../../assets/meeting_room.svg';
import locker from '../../assets/locker.svg';
import pets from '../../assets/pets.svg';
import parking from '../../assets/parking.svg';
import area from '../../assets/area.svg';
import highDesk from '../../assets/highDesk.svg';
import ergoChair from '../../assets/ergoChair.svg';
import printer from '../../assets/printer.svg';
import projector from '../../assets/projector.svg';
import deskStorage from '../../assets/deskStorage.svg';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LockIcon from '@mui/icons-material/Lock';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LandscapeIcon from '@mui/icons-material/Landscape';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CoPresentIcon from '@mui/icons-material/CoPresent';

export function spaceTypeImage(space) {
   switch (space) {
      case 'office':
         return meetingRoom;
      case 'desk':
         return dedicatedDesk;
      case 'cowork':
         return coworkingSpace;
      case 'room':
         return meetingRoom;
      case 'area':
         return area;
      case 'parking':
         return parking;
      case 'locker':
         return locker;
      case 'pets':
         return pets;

      default:
         return space;
   }
}

export function convertAmenityToIcon(amenity) {
   switch (amenity) {
      case 'Desk storage':
         return (
            <Tooltip key={amenity} title={amenity}>
               <Icon>
                  <img alt={amenity} src={deskStorage} height={25} width={25} />
               </Icon>
            </Tooltip>
         );
      case 'Ergonomic chair':
         return (
            <Tooltip key={amenity} title={amenity}>
               <Icon>
                  <img alt={amenity} src={ergoChair} height={25} width={25} />
               </Icon>
            </Tooltip>
         );
      case 'External monitor':
         return (
            <Tooltip key={amenity} title={amenity}>
               <DesktopMacIcon color="action" />
            </Tooltip>
         );
      case 'Height adjustable desk':
         return (
            <Tooltip key={amenity} title={amenity}>
               <Icon>
                  <img alt={amenity} src={highDesk} height={25} width={25} />
               </Icon>
            </Tooltip>
         );
      case 'Keyboard + mouse':
         return (
            <Tooltip key={amenity} title={amenity}>
               <KeyboardIcon color="action" />
            </Tooltip>
         );
      case 'Lockable door':
         return (
            <Tooltip key={amenity} title={amenity}>
               <LockIcon color="action" />
            </Tooltip>
         );
      case 'Multi screen':
         return (
            <Tooltip key={amenity} title={amenity}>
               <QueuePlayNextIcon color="action" />
            </Tooltip>
         );
      case 'Multiple seat':
         return (
            <Tooltip key={amenity} title={amenity}>
               <EventSeatIcon color="action" />
            </Tooltip>
         );
      case 'Printer':
         return (
            <Tooltip key={amenity} title={amenity}>
               <Icon>
                  <img alt={amenity} src={printer} height={25} width={25} />
               </Icon>
            </Tooltip>
         );
      case 'Projector':
         return (
            <Tooltip key={amenity} title={amenity}>
               <Icon>
                  <img alt={amenity} src={projector} height={25} width={25} />
               </Icon>
            </Tooltip>
         );
      case 'Window view':
         return (
            <Tooltip key={amenity} title={amenity}>
               <LandscapeIcon color="action" />
            </Tooltip>
         );
      case 'Tv monitor':
         return (
            <Tooltip key={amenity} title={amenity}>
               <LiveTvIcon color="action" />
            </Tooltip>
         );
      case 'White boards':
         return (
            <Tooltip key={amenity} title={amenity}>
               <CoPresentIcon color="action" />
            </Tooltip>
         );
      default:
         return amenity;
   }
}

export function addAmenityIcon(amenity) {
   switch (amenity) {
      case 'Desk storage':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={
                  <Icon>
                     <img alt={amenity} src={deskStorage} height={25} width={25} />
                  </Icon>
               }
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Ergonomic chair':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={
                  <Icon>
                     <img alt={amenity} src={ergoChair} height={25} width={25} />
                  </Icon>
               }
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'External monitor':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<DesktopMacIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Height adjustable desk':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={
                  <Icon>
                     <img alt={amenity} src={highDesk} height={25} width={25} />
                  </Icon>
               }
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Keyboard + mouse':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<KeyboardIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Lockable door':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<LockIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Multi screen':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<QueuePlayNextIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Multiple seat':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<EventSeatIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Printer':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={
                  <Icon>
                     <img alt={amenity} src={printer} height={25} width={25} />
                  </Icon>
               }
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Projector':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={
                  <Icon>
                     <img alt={amenity} src={projector} height={25} width={25} />
                  </Icon>
               }
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Window view':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<LandscapeIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'Tv monitor':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<LiveTvIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      case 'White boards':
         return (
            <Chip
               style={{ marginRight: 8 }}
               icon={<CoPresentIcon color="action" />}
               label={amenity}
               color="primary"
               variant="outlined"
               key={amenity}
            />
         );
      default:
         return amenity;
   }
}

export function allocateBookings(everySpace, availableSpaces, booked) {
   const newSpaces = { ...availableSpaces };
   Object.entries(booked).forEach(([key, value]) => {
      if (newSpaces[key]) {
         const space = { ...newSpaces[key] };
         const { seats } = everySpace[key];
         const { bookings, bookingNo } = value;

         space.available = true;
         space.seatsAvailable = seats;

         if (bookingNo && bookingNo !== 0) {
            let bookedSeats = 0;
            let AMSeats = 0;
            let PMSeats = 0;

            Object.entries(bookings).forEach(([bid, booking]) => {
               const { type, seats } = booking;

               if (type === 'AM') {
                  AMSeats += seats;
               }
               if (type === 'PM') {
                  PMSeats += seats;
               }
               if (type === 'All day') {
                  bookedSeats += seats;
               }
            });

            space.seatsAvailableAM = seats - bookedSeats - AMSeats;
            space.seatsAvailablePM = seats - bookedSeats - PMSeats;

            if (AMSeats > PMSeats) {
               bookedSeats += AMSeats;
            } else {
               bookedSeats += PMSeats;
            }

            space.seatsAvailable = seats - bookedSeats;
            if (
               space.seatsAvailable === 0 &&
               space.seatsAvailableAM === 0 &&
               space.seatsAvailablePM === 0
            ) {
               space.available = false;
            }
         }
         newSpaces[key] = space;
      }
   });
   return newSpaces;
}
