import { useState } from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const iconStyles = {
   fontSize: 20,
   ml: 1,
};

const ConfirmDeleteButton = ({
   primaryText,
   secondaryText,
   onClick,
   onAbandon,
   children,
   ...rest
}) => {
   const [toggleConfirm, setToggleConfirm] = useState(false);

   if (toggleConfirm) {
      return (
         <Button onClick={onClick} {...rest} variant="contained">
            Confirm
            <DeleteIcon sx={iconStyles} />
         </Button>
      );
   }

   return (
      <Button onClick={() => setToggleConfirm(true)} {...rest}>
         {children}
         <DeleteIcon sx={iconStyles} />
      </Button>
   );
};

export default ConfirmDeleteButton;
