import firebase from 'firebase/app';
import app from 'firebase/app';
import 'firebase/firestore';

const increment = (i) => firebase.firestore.FieldValue.increment(i);
const arrayValueRemove = (value) => firebase.firestore.FieldValue.arrayRemove(value);
const deleteField = () => firebase.firestore.FieldValue.delete();

export async function saveLandlordKey(
   lcid,
   llid,
   tcid,
   tlid,
   displayName,
   firstTime,
   logoPath = false,
) {
   try {
      await firebase.firestore().runTransaction(async (transaction) => {
         const landLocRef = firebase.firestore().doc(`location/${llid}`);
         const landCoRef = firebase.firestore().doc(`company/${lcid}`);
         const tenantLocRef = firebase.firestore().doc(`location/${tlid}`);
         const tenantCoRef = firebase.firestore().doc(`company/${tcid}`);
         const visColRef = firebase.firestore().collection(`visitors`);

         const landLocDoc = await transaction.get(landLocRef);
         if (!landLocDoc.exists) {
            throw new Error('LandLocDoc to read does not exist!');
         }
         const landCoDoc = await transaction.get(landCoRef);
         if (!landCoDoc.exists) {
            throw new Error('LandCoDoc to read does not exist!');
         }
         const tenantCoDoc = await transaction.get(tenantCoRef);
         let locationsWithLandlord = [];

         if (tenantCoDoc.data().locationsWithLandlord !== undefined) {
            locationsWithLandlord = tenantCoDoc.data().locationsWithLandlord;
         }
         locationsWithLandlord.push(tlid);
         let landlordDetails = {
            landlordKey: `${llid}*${lcid}`,
            lid: llid,
            cid: lcid,
            displayName,
            landlordName: landCoDoc.data().companyName,
         };

         transaction.set(tenantLocRef, { landlordDetails }, { merge: true });
         transaction.set(
            visColRef.doc(llid),
            { tenantDetails: { [tlid]: { displayName } } },
            { merge: true },
         );
         if (firstTime) {
            transaction.set(
               tenantCoRef,
               { locationsWithLandlordNo: increment(1), locationsWithLandlord },
               { merge: true },
            );
            let tenantDetails = {};
            if (landLocDoc.data().tenantDetails) {
               tenantDetails = { ...landLocDoc.data().tenantDetails };
            }
            tenantDetails[tlid] = {
               cid: tcid,
               displayName,
               logoPath,
            };
            transaction.set(
               landLocRef,
               { tenantDetails, tenantNumber: increment(1) },
               { merge: true },
            );
            transaction.set(
               landCoRef,
               { locationsWithTenants: { [llid]: increment(1) } },
               { merge: true },
            );
            transaction.set(visColRef.doc(llid), { tenantDetails }, { merge: true });
         }
      });
   } catch (error) {
      console.error('Transaction failed: ', error);
      throw error;
   }
}

export async function removeLandlordKey(lcid, llid, tcid, tlid) {
   const landLocRef = firebase.firestore().doc(`location/${llid}`);
   const landCoRef = firebase.firestore().doc(`company/${lcid}`);
   const tenantLocRef = firebase.firestore().doc(`location/${tlid}`);
   const tenantCoRef = firebase.firestore().doc(`company/${tcid}`);
   const visColRef = firebase.firestore().collection('visitors');

   const batch = firebase.firestore().batch();
   batch.set(tenantLocRef, { landlordDetails: deleteField() }, { merge: true });

   batch.set(
      tenantCoRef,
      {
         locationsWithLandlordNo: increment(-1),
         locationsWithLandlord: arrayValueRemove(tlid),
      },
      { merge: true },
   );
   batch.set(
      landLocRef,
      { tenantDetails: { [tlid]: deleteField() }, tenantNumber: increment(-1) },
      { merge: true },
   );
   batch.set(
      visColRef.doc(llid),
      { tenantDetails: { [tlid]: deleteField() } },
      { merge: true },
   );
   batch.set(
      landCoRef,
      { locationsWithTenants: { [llid]: increment(-1) } },
      { merge: true },
   );

   try {
      await batch.commit();
      console.wanr('Batch committed successfully.');
   } catch (error) {
      console.warn('ERROR removing landlord details', error);
      throw error;
   }
}

export async function deleteLogo(lid, logoPath, landlordDetails) {
   const batch = firebase.firestore().batch();
   const tlocRef = firebase.firestore().doc(`location/${lid}`);
   batch.set(tlocRef, { logoPath: deleteField() }, { merge: true });
   const visColRef = firebase.firestore().collection('visitors');
   batch.set(visColRef.doc(`${lid}`), { logoPath: deleteField() }, { merge: true });
   if (landlordDetails !== null) {
      const llocRef = firebase.firestore().doc(`location/${landlordDetails.lid}`);
      batch.set(
         llocRef,
         { tenantDetails: { [lid]: { logoPath: false } } },
         { merge: true },
      );
      batch.set(
         visColRef.doc(`${landlordDetails.lid}`),
         { tenantDetails: { [lid]: { logoPath: false } } },
         { merge: true },
      );
   }
   var desertRef = firebase.storage().ref().child(logoPath);
   //  .storedImageRef(logoPath);

   try {
      await batch.commit();
      await desertRef.delete();
   } catch (error) {
      console.wanr(error, 'error');
   }
}

export async function saveCustomisations(customisations, lid) {
   try {
      await firebase.firestore().doc(`visitors/${lid}`).set(
         {
            customisations,
         },
         { merge: true },
      );
      return 'Success';
   } catch (error) {
      return error;
   }
}

export async function getCustomisations(lid) {
   try {
      const customisations = await firebase.firestore().doc(`visitors/${lid}`).get();
      if (customisations.exists && customisations.data().customisations) {
         let data = customisations.data().customisations;
         if (data.welcomeBackgroundImagePath == undefined) {
            data.welcomeBackgroundImagePath = null;
         }
         return data;
      } else {
         return {
            welcomeTitle: '',
            welcomeMessage: '',
            welcomeBackgroundImagePath: null,
            confirmationStatements: null,
            carReg: false,
            confirmationPageTitle: '',
            confirmationStatementWording:
               'I confirm I have read and understood these policies.',
         };
      }
   } catch (error) {
      console.warn(error);
      return null;
   }
}
export async function loadImage(path) {
   const fullPath = await firebase.storage().ref().child(path);
   const url = await fullPath.getDownloadURL();
   return url;
}

export async function uploadConfirmationImage(files, confirmationStatements, lid) {
   let path = `visitors/${lid}`;
   try {
      await firebase
         .storage()
         .ref()

         .child(path + '/' + files[0].name)
         .put(files[0]);
      // await firebase.uploadImage(files[0], path);
      await firebase
         .firestore()
         .doc(`visitors/${lid}`)
         .set({ customisations: { confirmationStatements } }, { merge: true });
   } catch (error) {
      console.warn(error, 'ERROR uploading logo');
   }
}

export async function uploadBackgroundImage(files, lid) {
   let path = `visitors/${lid}/background`;
   try {
      await firebase
         .storage()
         .ref()

         .child(path + '/' + files[0].name)
         .put(files[0]);
      await firebase
         .firestore()
         .doc(`visitors/${lid}`)
         .set(
            {
               customisations: { welcomeBackgroundImagePath: `${path}/${files[0].name}` },
            },
            { merge: true },
         );
   } catch (error) {
      console.warn(error, 'ERROR uploading logo');
   }
}
export async function deleteWelcomeBackgroundImage(lid, path) {
   var desertRef = firebase.storage().ref().child(path);
   try {
      await firebase
         .firestore()
         .doc(`visitors/${lid}`)
         .set({ customisations: { welcomeBackgroundImagePath: null } }, { merge: true });
      await desertRef.delete();
   } catch (error) {
      console.warn(error, 'error');
   }
}
export async function deleteConfirmationImage(statements, lid, path) {
   var desertRef = firebase.storage().ref().child(path);
   try {
      await firebase
         .firestore()
         .doc(`visitors/${lid}`)
         .set(
            { customisations: { confirmationStatements: statements } },
            { merge: true },
         );
      await desertRef.delete();
   } catch (error) {
      console.warn(error, 'error');
   }
}

export async function loadImageFromStorage(path) {
   const fullPath = await firebase.storage().ref().child(path).getDownloadURL();
   return fullPath;
}
