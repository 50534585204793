import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../Session';
import Main from './main';
import { AuthProvider, LocationsProvider } from '../../../providers';

const VisitorDash = (props) => {
   return (
      <AuthProvider>
         <LocationsProvider>
            <Main />
         </LocationsProvider>
      </AuthProvider>
   );
};

const VisitorDashPage = compose(withRouter, withFirebase)(VisitorDash);

const condition = (authUser) => !!authUser;

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(VisitorDashPage);
