import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PageLayout, CardSection } from '../layout';
import {
   Collapse,
   Modal,
   Grid,
   Button,
   Chip,
   Divider,
   Box,
   Typography,
   Paper,
} from '@mui/material';

import AmIcon from '../../assets/status/AM.svg';
import PmIcon from '../../assets/status/PM.svg';
import DoneIcon from '@mui/icons-material/Done';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DateToday } from '../../constants/functions';
import MyWeek from './myWeek';
import DisplayFilter from '../DisplayFilter';
import DayCapacity from '../Capacity/dayCapacity';
import QuickActionsBlock from './quickActions';
import NewUser from '../Auth/newUser';
import Instructions from './instructions';
import { useAuth, useCompany } from '../../providers';
import MyBookings from './BookingsWidget/myBookings';
import { sortArrayOfPeopleObjectsByFirstName } from '../../utils/sortingUtils';

const useStyles = makeStyles((theme) => ({
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   grid: {
      padding: theme.spacing(0, 4, 0, 2),
   },
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:focus': {
         outline: 'none',
      },
   },
   modalPaper: {
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   fixedHeight: {
      minHeight: 240,
   },
   chips: {
      margin: theme.spacing(0.5),
   },
   exceeded: {
      color: '#FF0000',
   },
}));

function NeedAction(time) {
   return (
      <React.Fragment>
         <Typography gutterBottom={true}>
            Looks like no one is in the office {time}.
         </Typography>
         <Button variant="outlined" color="primary" href="/Schedule">
            Go to schedule
         </Button>
      </React.Fragment>
   );
}

function isExceeded(statusCount, capCapacity) {
   if (Number(statusCount) >= Number(capCapacity)) {
      return 'exceeded';
   }
}

const Dashboard = (props) => {
   const classes = useStyles();
   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
   const history = useHistory();
   const today = DateToday(0);
   const tomorrow = DateToday(1);
   const { authUser } = useAuth();
   const { company } = useCompany();
   const [user, setUser] = useState(false);
   const [people, setPeople] = useState(false);
   const [companySetUp, setCompanySetUp] = useState({
      capacity: false,
      people: false,
      status: false,
   });
   const [showInstructions, setShowInstructions] = useState(false);
   const [todaySchedule, setTodaySchedule] = useState(false);
   const [todayPeople, setTodayPeople] = useState(false);
   const [capacity, setCapacity] = useState(0);
   const [todayCapExceeded, setTodayCapExceeded] = useState('');
   const [tomorrowSchedule, setTomorrowSchedule] = useState(false);
   const [tomorrowCapacity, setTomorrowCapacity] = useState(0);
   const [tomorrowCapExceeded, setTomorrowCapExceeded] = useState('');
   const [needAction, setNeedAction] = useState('Loading...');
   const [displayFilter, setDisplayFilter] = useState(false);
   const [groupFilter, setGroupFilter] = useState(false);
   const [location, setLocation] = useState(false);
   const [noLocation, setNoLocation] = useState(false);
   const [routine, setRoutine] = useState(false);
   const [routineEnabled, setRoutineEnabled] = useState(false);
   const [visitorsDrawer, setVisitorsDrawer] = useState(false);
   const [numbers, setNumbers] = useState({
      ito: 0,
      itoam: 0,
      itopm: 0,
      wfh: 0,
      wfham: 0,
      wfhpm: 0,
   });
   const [itoCollapsed, setItoCollapsed] = useState(false);
   const [wfhCollapsed, setWfhCollapsed] = useState(false);

   useEffect(() => {
      if (authUser && authUser.lid.length === 0) {
         setNoLocation(true);
      }
   }, []);

   useEffect(() => {
      const loadUser = async () => {
         if (authUser) {
            await props.firebase
               .user(authUser.uid)
               .get()
               .then((me) => {
                  setUser({ ...me.data(), uid: me.id });
               });
         }
      };
      loadUser();
   }, []);

   useEffect(() => {
      if (displayFilter) {
         let query = props.firebase.companyPeople(displayFilter.cid);
         if (displayFilter.type === 'group') {
            query = props.firebase.locationGroupDetails(
               displayFilter.lid,
               displayFilter.id,
            );
         } else if (displayFilter.type === 'location') {
            query = props.firebase.locationPeopleIndex(displayFilter.id);
         }
         const unsubscribe = query.onSnapshot(
            (result) => {
               let peopleList = [];
               if (result.exists) {
                  let data = {};
                  if (displayFilter.type === 'group') {
                     data = result.data().members;
                  } else {
                     data = result.data();
                  }
                  Object.entries(data).map(([key, value]) => {
                     if (key !== authUser.uid) {
                        var person = { uid: key, ...value, cid: authUser.cid };
                        peopleList.push(person);
                     }
                  });
                  peopleList = sortArrayOfPeopleObjectsByFirstName(peopleList);
                  if (data[authUser.uid]) {
                     var person = {
                        uid: authUser.uid,
                        ...data[authUser.uid],
                        cid: authUser.cid,
                     };
                     peopleList.unshift(person);
                  }
               }
               setPeople(peopleList);
            },
            (error) => {
               console.warn(error, 'Error getting people list');
            },
         );
         return () => unsubscribe();
      }
   }, [user, displayFilter, groupFilter]);

   useEffect(() => {
      const getToday = async () => {
         if (displayFilter) {
            const unsubscribe = props.firebase.location(displayFilter.lid).onSnapshot(
               (result) => {
                  const locationData = result.data();
                  if (authUser?.roles?.ADMIN) {
                     var setup = locationData.setup;
                     if (setup.done === false) {
                        setShowInstructions(true);
                        setCompanySetUp(setup);
                     }
                  }
                  setLocation({ ...result.data(), id: result.id });
                  if (result.data().routine) {
                     setRoutineEnabled(result.data().routine);
                  }
               },
               (error) => {
                  console.warn(error, 'Error getting location');
               },
            );
            return () => unsubscribe();
         }
      };
      getToday();
   }, [displayFilter]);

   useEffect(() => {
      if (people) {
         // I think because this is in a snapshot it is not updating so it is reloading previous snapshots
         const loadTodayPeople = props.firebase
            .locationDayScheduleDate(displayFilter.lid, today.ref)
            .onSnapshot(
               (dayData) => {
                  let nos = { ito: 0, itoam: 0, itopm: 0, wfh: 0, wfham: 0, wfhpm: 0 };
                  let merged = [];
                  if (!dayData.exists) {
                     setNeedAction(NeedAction('today'));
                     setTodayPeople(false);
                     setNumbers({
                        ito: 0,
                        itoam: 0,
                        itopm: 0,
                        wfh: 0,
                        wfham: 0,
                        wfhpm: 0,
                     });
                  } else if (dayData.exists) {
                     const schedule = dayData.data();
                     Object.entries(people).map(([key, value], i) => {
                        merged[i] = {
                           id: value.uid,
                           name: value.name.firstName + ' ' + value.name.lastName,
                           cid: authUser.cid,
                           day: today,
                           month: today.month,
                           year: today.year,
                           status: null,
                        };
                        if (schedule[value.uid]) {
                           if (schedule[value.uid].status === 'ito') {
                              nos.ito = nos.ito + 1;
                           }
                           if (schedule[value.uid].status === 'wfh') {
                              nos.wfh = nos.wfh + 1;
                           }
                           if (schedule[value.uid].status === 'split') {
                              if (schedule[value.uid].splitDay?.AM == 'ito') {
                                 nos.itoam = nos.itoam + 1;
                              }
                              if (schedule[value.uid].splitDay?.PM == 'ito') {
                                 nos.itopm = nos.itopm + 1;
                              }
                              if (schedule[value.uid].splitDay?.AM == 'wfh') {
                                 nos.wfham = nos.wfham + 1;
                              }
                              if (schedule[value.uid].splitDay?.PM == 'wfh') {
                                 nos.wfhpm = nos.wfhpm + 1;
                              }
                           }
                           if (
                              schedule[value.uid].status === 'ito' ||
                              schedule[value.uid].status === 'wfh' ||
                              schedule[value.uid].status === 'split'
                           ) {
                              merged[i].status = schedule[value.uid].status;
                              if (
                                 schedule[value.uid].splitDay?.AM == 'ito' ||
                                 schedule[value.uid].splitDay?.AM == 'wfh'
                              ) {
                                 merged[i].AM = schedule[value.uid].splitDay.AM;
                              }
                              if (
                                 schedule[value.uid].splitDay?.PM == 'ito' ||
                                 schedule[value.uid].splitDay?.PM == 'wfh'
                              ) {
                                 merged[i].PM = schedule[value.uid].splitDay.PM;
                              }
                           }
                        }
                     });
                     setNumbers(nos);
                     setTodayPeople(merged);
                     // setSchedule(true);
                  }
               },
               (error) => {
                  console.warn(error, 'Error - getting the schedule for today');
               },
            );
         return () => loadTodayPeople();
      }
   }, [people]);

   useEffect(() => {
      if (location.routine) {
         const loadRoutine = props.firebase.getRoutineStreak(location.id).onSnapshot(
            (result) => {
               if (result.exists) {
                  setRoutine(result.data());
               } else {
                  setRoutine(false);
               }
            },
            (error) => {
               console.warn(error, 'Error getting routine');
            },
         );
         return () => loadRoutine();
      }
   }, [location]);

   return (
      <PageLayout>
         <Modal
            open={noLocation}
            aria-labelledby="no-location-modal"
            className={classes.modal}
         >
            <Box component={Paper} sx={{ p: 3 }}>
               <NewUser user={authUser} signInType="newUser" />
            </Box>
         </Modal>
         <Grid container spacing={3}>
            {/* Chart */}
            {showInstructions ? (
               <Instructions
                  setShowInstructions={setShowInstructions}
                  companySetUp={companySetUp}
                  fixedHeightPaper={fixedHeightPaper}
               />
            ) : (
               ''
            )}
            <Grid item xs={12} md={10} lg={9}>
               <CardSection className={fixedHeightPaper}>
                  <Grid container>
                     <Grid item xs={12}>
                        <div
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <DisplayFilter
                              user={user}
                              displayFilter={displayFilter}
                              setDisplayFilter={setDisplayFilter}
                              location={location}
                              groupFilter={groupFilter}
                              setGroupFilter={setGroupFilter}
                           />
                           <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                              gutterBottom
                              style={{ marginLeft: '8px' }}
                           >
                              Today
                           </Typography>
                        </div>
                     </Grid>
                     <Grid item className={classes.grid} xs={12} sm={9}>
                        {todayPeople ? (
                           <React.Fragment>
                              <Box marginBottom="16px">
                                 <div
                                    style={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       flexWrap: 'wrap',
                                    }}
                                 >
                                    <Typography
                                       component="h3"
                                       variant="subtitle1"
                                       color="primary"
                                       inline="true"
                                    >
                                       In The Office
                                    </Typography>
                                 </div>
                                 <Collapse in={itoCollapsed} collapsedSize={75}>
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (value.status === 'ito') {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<DoneIcon />}
                                                label={value.name}
                                             />
                                          );
                                       }
                                    })}
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (
                                          value.status === 'split' &&
                                          value?.AM === 'ito'
                                       ) {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<DoneIcon />}
                                                label={`${value.name} - AM`}
                                                deleteIcon={<AmIcon />}
                                             />
                                          );
                                       }
                                    })}
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (
                                          value.status === 'split' &&
                                          value?.PM === 'ito'
                                       ) {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<DoneIcon />}
                                                label={`${value.name} - PM`}
                                             />
                                          );
                                       }
                                    })}
                                 </Collapse>
                              </Box>
                              <Box
                                 component="div"
                                 sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mt: 2,
                                 }}
                              >
                                 <Box
                                    style={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       flexWrap: 'wrap',
                                    }}
                                 >
                                    <Typography variant="caption">ITO Totals:</Typography>
                                 </Box>
                                 <Box
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'space-evenly',
                                       minWidth: '60%',
                                    }}
                                 >
                                    <Box
                                       style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                       }}
                                    >
                                       <DoneIcon fontSize="small" color="primary" />
                                       <Typography variant="caption">
                                          {numbers.ito}
                                       </Typography>
                                    </Box>
                                    {numbers.itoam !== 0 && (
                                       <Box
                                          style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          {' '}
                                          <DoneIcon fontSize="small" color="primary" />
                                          <Typography variant="caption">
                                             AM: {numbers.itoam}
                                          </Typography>
                                       </Box>
                                    )}
                                    {numbers.itopm !== 0 && (
                                       <Box
                                          style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          {' '}
                                          <DoneIcon fontSize="small" color="primary" />
                                          <Typography variant="caption">
                                             PM: {numbers.itopm}
                                          </Typography>
                                       </Box>
                                    )}
                                 </Box>
                                 <Box>
                                    {itoCollapsed ? (
                                       <Button
                                          size="small"
                                          onClick={() => setItoCollapsed(!itoCollapsed)}
                                          endIcon={<ExpandLessIcon />}
                                       >
                                          Less
                                       </Button>
                                    ) : (
                                       <Button
                                          size="small"
                                          onClick={() => setItoCollapsed(!itoCollapsed)}
                                          endIcon={<ExpandMoreIcon />}
                                       >
                                          More
                                       </Button>
                                    )}
                                 </Box>
                              </Box>
                              <Divider />
                              <Box marginTop="16px">
                                 <Typography
                                    component="h3"
                                    variant="subtitle1"
                                    color="primary"
                                    gutterBottom
                                    inline="true"
                                 >
                                    Working From Home
                                 </Typography>
                                 <Collapse in={wfhCollapsed} collapsedSize={75}>
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (value.status === 'wfh') {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<HomeIcon />}
                                                label={value.name}
                                             />
                                          );
                                       }
                                    })}
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (
                                          value.status === 'split' &&
                                          value.AM === 'wfh'
                                       ) {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<HomeIcon />}
                                                label={`${value.name} - AM`}
                                                deleteIcon={<img src={PmIcon} />}
                                             />
                                          );
                                       }
                                    })}
                                    {Object.entries(todayPeople).map(([key, value]) => {
                                       if (
                                          value.status === 'split' &&
                                          value.PM === 'wfh'
                                       ) {
                                          return (
                                             <Chip
                                                size="small"
                                                className={classes.chips}
                                                color="primary"
                                                variant="outlined"
                                                key={value.id}
                                                icon={<HomeIcon />}
                                                label={`${value.name} - PM`}
                                             />
                                          );
                                       }
                                    })}
                                 </Collapse>
                                 <Box
                                    component="div"
                                    sx={{
                                       display: 'flex',
                                       justifyContent: 'space-between',
                                       mt: 2,
                                    }}
                                 >
                                    <Box
                                       style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                       }}
                                    >
                                       <Typography variant="caption">
                                          WFH Totals:
                                       </Typography>
                                    </Box>
                                    <Box
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'space-evenly',
                                          minWidth: '60%',
                                       }}
                                    >
                                       <Box
                                          style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             flexWrap: 'wrap',
                                          }}
                                       >
                                          <HomeIcon fontSize="small" color="primary" />
                                          <Typography variant="caption">
                                             {numbers.wfh}
                                          </Typography>
                                       </Box>
                                       {numbers.wfham !== 0 && (
                                          <Box
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                             }}
                                          >
                                             <HomeIcon fontSize="small" color="primary" />
                                             <Typography variant="caption">
                                                AM: {numbers.wfham}
                                             </Typography>
                                          </Box>
                                       )}
                                       {numbers.wfhpm !== 0 && (
                                          <Box
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                             }}
                                          >
                                             <HomeIcon fontSize="small" color="primary" />
                                             <Typography variant="caption">
                                                PM: {numbers.wfhpm}
                                             </Typography>
                                          </Box>
                                       )}
                                    </Box>
                                    <Box>
                                       {wfhCollapsed ? (
                                          <Button
                                             size="small"
                                             onClick={() =>
                                                setWfhCollapsed(!wfhCollapsed)
                                             }
                                             endIcon={<ExpandLessIcon />}
                                          >
                                             Less
                                          </Button>
                                       ) : (
                                          <Button
                                             size="small"
                                             onClick={() =>
                                                setWfhCollapsed(!wfhCollapsed)
                                             }
                                             endIcon={<ExpandMoreIcon />}
                                          >
                                             More
                                          </Button>
                                       )}
                                    </Box>
                                 </Box>
                              </Box>
                           </React.Fragment>
                        ) : (
                           needAction
                        )}
                     </Grid>
                     <Grid item xs={12} sm={3}>
                        <React.Fragment>
                           <Typography
                              variant="subtitle1"
                              component="h3"
                              color="primary"
                              gutterBottom
                           >
                              {location.name} Capacity
                           </Typography>
                           <DayCapacity
                              location={location}
                              routine={routine}
                              date={today}
                           />
                           {/* <Box sx={{width:'100%', mt:2, display:'flex', justifyContent:'center'}}>
                                 <Button size="small" color="primary" endIcon={<AssessmentOutlinedIcon />} onClick={()=>history.push({pathname: '/schedule',search:`?view=day&date=${today.year}-${String(today.month +1).padStart(2, '0')}-${String(today.day).padStart(2, '0')}`,})} ><Typography variant="caption">View Report</Typography></Button>
                              </Box> */}
                        </React.Fragment>
                     </Grid>
                  </Grid>
               </CardSection>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} sm={6} md={2} lg={3}>
               <QuickActionsBlock location={location} displayFilter={displayFilter} />
               {company.features?.spaceBookings && (
                  <Box sx={{ mt: 2 }}>
                     <MyBookings />
                  </Box>
               )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
               <CardSection className={fixedHeightPaper}>
                  <Typography component="h2" variant="h6" color="primary" gutterBottom>
                     Tomorrow
                  </Typography>
                  <Grid>
                     {/* {tomorrowSchedule */}
                     {/* ?  */}
                     <DayCapacity location={location} routine={routine} date={tomorrow} />
                     {/* <Box sx={{width:'100%', mt:2, display:'flex', justifyContent:'center'}}>
                           <Button size="small" color="primary" endIcon={<AssessmentOutlinedIcon />} onClick={()=>history.push({pathname: '/schedule',search:`?view=day&date=${tomorrow.year}-${String(tomorrow.month +1).padStart(2, '0')}-${String(tomorrow.day).padStart(2, '0')}`,})} ><Typography variant="caption">View Report</Typography></Button>
                        </Box> */}
                  </Grid>
               </CardSection>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
               <CardSection className={fixedHeightPaper}>
                  <MyWeek
                     displayFilter={displayFilter}
                     location={location}
                     routineEnabled={routineEnabled}
                  />
                  <Grid item xs={12} md={2} lg={4}></Grid>
               </CardSection>
            </Grid>
         </Grid>
      </PageLayout>
   );
};

export default withFirebase(Dashboard);
