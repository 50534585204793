import React from 'react';
import {
   IconButton,
   Menu,
   MenuItem,
   List,
   ListItem,
   ListItemText,
   ListItemIcon,
   Typography,
} from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE_MENU_ROUTES } from '../../../constants/routes';

const UserProfileMenu = ({ authUser }) => {
   const shownRoutes = Object.values(USER_PROFILE_MENU_ROUTES);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const history = useHistory();

   const handleClick = (event) => setAnchorEl(event.currentTarget);
   const handleClose = () => setAnchorEl(null);
   const routeTo = (route) => {
      history.push(route);
      handleClose();
   };

   return (
      <div>
         <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
         >
            <AccountCircleRoundedIcon />
         </IconButton>
         <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={2}
            getcontentanchorel={null}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
         >
            <List
               disablePadding
               sx={{
                  minWidth: 220,
                  maxWidth: 280,
               }}
            >
               <ListItem>
                  <ListItemText
                     primary={
                        <>
                           <Typography variant="caption">{`Signed in as`}</Typography>
                           <Typography
                              variant="h6"
                              noWrap
                           >{`${authUser.name.firstName} ${authUser.name.lastName}`}</Typography>
                        </>
                     }
                  />
               </ListItem>
               {shownRoutes.map(({ icon, name, alias, path }) => {
                  const Icon = icon;
                  return (
                     <MenuItem
                        key={alias}
                        onClick={() => routeTo(path)}
                        sx={{ py: 2, width: '100%' }}
                     >
                        <ListItemIcon>
                           <Icon />
                        </ListItemIcon>
                        <ListItemText>{name}</ListItemText>
                     </MenuItem>
                  );
               })}
            </List>
         </Menu>
      </div>
   );
};

export default UserProfileMenu;
