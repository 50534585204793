import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const OffsiteAm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 41 31">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g
               id="status/OFFSITEAM"
               transform="translate(-0.4778, 0)"
               fill={theme.palette.primary.main}
               fillRule="nonzero"
            >
               <path
                  d="M21,22 L21,24 L7,24 L7,22 L21,22 Z M14,4 C17.31,4 20,6.69 20,10 C20,14.5 14,21 14,21 C14,21 8,14.5 8,10 C8,6.69 10.69,4 14,4 Z M14,8 C12.9,8 12,8.9 12,10 C12,11.1 12.9,12 14,12 C15.11,12 16,11.1 16,10 C16,8.9 15.1,8 14,8 Z"
                  id="Shape"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default OffsiteAm;
