import React, { useEffect, useState, withStyles } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import SpacesDrawer from './spacesDrawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import { Select, InputLabel, MenuItem, TableContainer } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { spaceTypes } from '../../constants/data';
import { Box } from '@mui/material';
import { naturalCompare } from '../Spaces/utils';
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: 200,
   },
   blueTable: {
      backgroundColor: '#342E76',
      color: '#fff',
   },
   paper: {
      width: '100%',
      padding: theme.spacing(2),
   },
   // searchPaper: {
   //   padding: '2px 4px',
   //   display: 'flex',
   //   alignItems: 'center',
   //   width: "100%",
   // },
   input: {
      marginLeft: theme.spacing(1),
      flex: 1,
   },
   iconButton: {
      padding: 10,
   },
   rowHover: {
      '&$hover:hover': {
         cursor: 'pointer',
      },
   },
   /* Pseudo-class applied to the root element if `hover={true}`. */
   hover: {
      cursor: 'pointer',
   },
}));

const SpacesPanel = (props) => {
   const classes = useStyles();
   const [spacesEnabled, setSpacesEnabled] = useState(false);
   const [spacesExist, setSpacesExist] = useState(false);
   const [spacesDrawerOpen, setSpacesDrawerOpen] = useState(false);
   const [spaceKey, setSpaceKey] = useState(null);
   const [refresh, setRefresh] = useState(false);
   const [allSpaces, setAllSpaces] = useState({ loading: 'Loading...' });
   const [filter, setFilter] = useState({ archive: 2, type: '', search: '' });
   useEffect(() => {
      if (props.expanded === 'spaces') {
         let query = props.firebase.spaces(props.locationKey);
         if (filter.archive === 2) {
            query = query.where('archive', '==', false);
         }
         if (filter.archive === 3) {
            query = query.where('archive', '==', true);
         }
         if (filter.type !== '') {
            query = query.where('type', '==', filter.type);
         }
         //commenting out for now, as I might be able to get away without having a search bar
         // if(filter.search !== ""){
         //   query = query.where('type', '==', filter.type);
         // }
         props.firebase.location(props.locationKey).onSnapshot(
            (result) => {
               if (result.data().spacesEnabled === true) {
                  setSpacesEnabled(true);
                  query.onSnapshot(
                     (spaceResult) => {
                        if (spaceResult.empty != true) {
                           let data = [];
                           spaceResult.forEach((doc) => {
                              data.push({
                                 ...doc.data(),
                                 sid: doc.id,
                                 type: spaceTypes[doc.data().type],
                              });
                           });

                           data.sort((a, b) => {
                              if (a.order && b.order) {
                                 return a.order - b.order;
                              } else {
                                 return naturalCompare(a.name, b.name);
                              }
                           });

                           setAllSpaces(data);
                           setSpacesExist(true);
                        } else {
                           setSpacesExist(false);
                        }
                     },
                     (error) => {
                        console.log(error);
                     },
                  );
               } else {
                  setSpacesEnabled(false);
               }
            },
            (error) => {
               console.log(error);
            },
         );
         setRefresh(true);
      }
   }, [props.expanded, filter]);

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFilter({ ...filter, [name]: value });
   };
   const handleFormat = (event, value) => {
      setFilter({ ...filter, archive: value });
   };
   const submitSearch = (e) => {
      setFilter({ ...filter, search: e.target.value });
   };
   const ListSpaces = (props) => {
      return (
         <Grid item xs={12}>
            <TableContainer style={{ maxHeight: 400 }}>
               <Table size="small" aria-label="Spaces table" stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell className={classes.blueTable}>Space</TableCell>
                        <TableCell className={classes.blueTable} align="left">
                           Description
                        </TableCell>
                        <TableCell className={classes.blueTable} align="left">
                           Seats
                        </TableCell>
                        <TableCell className={classes.blueTable} align="left">
                           Type
                        </TableCell>
                        <TableCell className={classes.blueTable} align="left">
                           Colour
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {allSpaces.map((value) => (
                        <React.Fragment key={value.sid}>
                           <TableRow
                              className={classes.rowHover}
                              hover
                              onClick={() => {
                                 setSpacesDrawerOpen(true);
                                 setSpaceKey(value.sid);
                              }}
                           >
                              <TableCell align="left">
                                 <Typography variant="body1">
                                    {value.archive ? (
                                       <ArchiveOutlinedIcon
                                          style={{
                                             position: 'relative',
                                             top: 6,
                                             marginRight: 6,
                                          }}
                                          color="action"
                                          size="small"
                                       />
                                    ) : (
                                       ''
                                    )}
                                    {value.name}
                                 </Typography>
                              </TableCell>
                              <TableCell style={{ maxWidth: 300 }} align="left">
                                 <Typography variant="caption">
                                    {value.description}
                                 </Typography>
                              </TableCell>
                              <TableCell align="left">
                                 <Typography variant="body1">
                                    {value.seats ? value.seats : ''}
                                 </Typography>
                              </TableCell>
                              <TableCell align="left">
                                 <Typography variant="body1">{value.type}</Typography>
                              </TableCell>
                              <TableCell align="left">
                                 <Box
                                    sx={{
                                       width: 25,
                                       height: 25,
                                       backgroundColor: value.backgroundColor
                                          ? value.backgroundColor
                                          : '#fff',
                                       border: `2px solid #eee`,
                                    }}
                                 ></Box>
                              </TableCell>
                           </TableRow>
                        </React.Fragment>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>
      );
   };

   function toggleSpaces(lid, initialStatus) {
      var status = false;
      if (initialStatus === false) {
         status = true;
      }
      props.firebase.locationToggleSpaces(lid, status);
   }

   return (
      <React.Fragment>
         <Grid container>
            <Grid container item xs={12}>
               {spacesEnabled && (
                  <React.Fragment>
                     <Grid item xs={12} sm={4} md={2}>
                        <Button
                           variant="outlined"
                           color="primary"
                           onClick={() => {
                              setSpacesDrawerOpen(true);
                           }}
                        >
                           New Space
                        </Button>
                     </Grid>
                  </React.Fragment>
               )}
               <Grid item xs={12} sm={6} md={8}>
                  <Typography variant="caption">
                     Spaces are office resources that can be booked, e.g. a desk, room, or
                     parking space. Spaces can have any number of seats which can be
                     reserved individually. Multiple spaces can be groupeed together in an
                     Area as a parent space, e.g. a desk can exist within a quiet working
                     area, on the first floor.
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={2}>
                  {/* <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch color="primary" checked={spacesEnabled} onChange={()=>{toggleSpaces(props.locationKey, spacesEnabled)}} />
                      }
                      label="Enable"
                      labelPlacement="top"
                    />
                    </FormGroup>
                  </FormControl> */}
               </Grid>
            </Grid>
            <Paper variant="outlined" className={classes.paper}>
               <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12} sm={12} md={2}>
                     <Typography variant="body2">Filters:</Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={5} md={3}>
                  <Paper component="form" className={classes.searchPaper}>
                    <InputBase
                      className={classes.input}
                      placeholder="Search Space Name"
                      onChange={submitSearch}
                      inputProps={{ 
                        'aria-label': 'search space name',
                      }}
                      
                    />
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={5}>
                     <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.formControl}
                     >
                        <InputLabel htmlFor="outlined-type-native-simple">
                           Type
                        </InputLabel>
                        <Select
                           name="type"
                           value={filter.type}
                           onChange={handleInputChange}
                           labelId="Type"
                        >
                           <MenuItem aria-label="None" value="">
                              All
                           </MenuItem>
                           {Object.entries(spaceTypes).map(([key, type]) => (
                              <MenuItem key={key} value={key}>
                                 {type}
                              </MenuItem>
                           ))}
                           {/* <MenuItem value={'desk'}>Dedicated Desk</MenuItem>
                           <MenuItem value={'office'}>Office</MenuItem>
                           <MenuItem value={'cowork'}>Co-working space</MenuItem>
                           <MenuItem value={'room'}>Meeting room</MenuItem>
                           <MenuItem value={'area'}>Area</MenuItem>
                           <MenuItem value={'parking'}>Parking space</MenuItem>
                           <MenuItem value={'dogs'}>Dog space</MenuItem> */}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                     <div>
                        <ToggleButtonGroup
                           value={filter.archive}
                           exclusive
                           onChange={handleFormat}
                           aria-label="archived status"
                           size="small"
                           label="Status"
                        >
                           <ToggleButton value={1} aria-label="all">
                              All
                           </ToggleButton>
                           <ToggleButton value={2} aria-label="active">
                              Active
                           </ToggleButton>
                           <ToggleButton value={3} aria-label="archived">
                              Archived
                           </ToggleButton>
                        </ToggleButtonGroup>
                     </div>
                  </Grid>
               </Grid>
            </Paper>
            <Grid container item xs={12}>
               {spacesExist ? <ListSpaces classes={classes} /> : 'No results'}
            </Grid>
         </Grid>

         <SpacesDrawer
            locationKey={props.locationKey}
            cid={props.cid}
            spaceKey={spaceKey}
            setSpaceKey={setSpaceKey}
            setSpacesDrawerOpen={setSpacesDrawerOpen}
            spacesDrawerOpen={spacesDrawerOpen}
         />
      </React.Fragment>
   );
};

export default withFirebase(SpacesPanel);
