import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
const WfhPm = (props) => {
   const theme = useTheme();
   return (
      <SvgIcon {...props} viewBox="0 0 35 34">
         <g
            id="ITO-Design-System"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
         >
            <g id="status/wFHPM" transform="translate(1.1961, 1.1961)">
               <line
                  x1="22.5"
                  y1="-0.5"
                  x2="-0.5"
                  y2="32.5"
                  id="Line-6"
                  stroke={theme.palette.primary.main}
                  strokeLinecap="square"
               ></line>
               <path
                  d="M26,6 L36,15 L33,15 L33,23 L27,23 L27,17 L25,17 L25,23 L19,23 L19,15 L16,15 L26,6 Z M26,8.69 L21,13.19 L21,21 L23,21 L23,15 L29,15 L29,21 L31,21 L31,13.19 L26,8.69 Z"
                  id="Shape"
                  fill={theme.palette.primary.main}
                  fillRule="nonzero"
               ></path>
            </g>
         </g>
      </SvgIcon>
   );
};

export default WfhPm;
