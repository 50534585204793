import React, { useState, useEffect } from 'react';
import {
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Box,
   Typography,
   IconButton,
   Collapse,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { withFirebase } from '../../Firebase';

const HostTypeSelector = ({ location, cid, lid, role, setRole, firebase }) => {
   const options = ['Default', 'Landlord', 'Tenant'];
   const [showExplanation, setShowExplanation] = useState(false);
   const [disabled, setDisabled] = useState(true);

   useEffect(() => {
      if (
         !location.landlordDetails &&
         (!location.tenantDetails || Object.keys(location.tenantDetails).length === 0)
      ) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
   }, [location.landlordDetails, location.tenantDetails]);

   const handleChange = async (event) => {
      setRole('loading');
      console.log(lid, cid);
      const batch = firebase.db.batch();
      const locationRef = firebase.location(lid);
      batch.set(locationRef, { visitorHostType: event.target.value }, { merge: true });

      console.log(lid, event.target.value);
      try {
         await batch.commit();
         setRole(event.target.value);
      } catch (error) {
         console.warn('Error updating host type', error);
         setRole(location.visitorHostType || 'Default');
      }
   };

   const toggleExplanation = () => {
      setShowExplanation((prev) => !prev);
   };

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: { xs: '100%', sm: '50%' },
               }}
            >
               <Typography variant="h6">Setup Type</Typography>
               <IconButton onClick={toggleExplanation} aria-label="info">
                  <InfoIcon />
               </IconButton>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '50%' }, mt: 2 }}>
               <FormControl fullWidth size="small">
                  <InputLabel id="role-selector-label">Setup Type</InputLabel>
                  <Select
                     labelId="role-selector-label"
                     id="role-selector"
                     value={role}
                     label="Setup Type"
                     onChange={handleChange}
                     disabled={disabled}
                  >
                     {options.map((option) => (
                        <MenuItem key={option} value={option}>
                           {option}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         </Box>
         <Collapse in={showExplanation}>
            <Box sx={{ mt: 2 }}>
               <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Default Setup:</span> This is the
                  standard setup where visitors check in using a kiosk or by scanning a QR
                  code. Their host is notified of their arrival.
               </Typography>
               <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Landlord Setup:</span> Designed for
                  multi-tenant buildings, landlords can manage both their and their
                  tenants' visitors, maintaining safety and security across the property.
               </Typography>
               <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Tenant Setup:</span> Tenants link
                  to their landlord's system by entering the landlord's location ID,
                  streamlining visitor check-ins and host notifications within a
                  landlord-managed complex.
               </Typography>
            </Box>
         </Collapse>
      </Box>
   );
};

export default withFirebase(HostTypeSelector);
