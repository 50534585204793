import { Box, Typography } from '@mui/material';
import React from 'react';
import { LargeIconButton } from '../../layout';

import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Interweave } from 'interweave';

const MultiTenantForm = ({ lids, llid, landlocation, customisations }) => {
   const history = useHistory();
   return (
      <Box sx={{ mt: 7, height: '80vh', position: 'relative', zIndex: 1 }}>
         {customisations !== null && customisations.welcomeTitle !== '' ? (
            <Typography align="center" variant="h4" component="h1" color="primary">
               {customisations.welcomeTitle}
            </Typography>
         ) : (
            <Typography align="center" variant="h4" component="h1" color="primary">
               Which company are you visiting?
            </Typography>
         )}
         {customisations !== null &&
            customisations.welcomeMessage !== '' &&
            customisations.welcomeMessage !== '<p><br></p>' && (
               <Box
                  sx={{
                     mt: 2,
                     p: 2,
                     border: '1px solid #ccc',
                     borderRadius: 2,
                     backgroundColor: '#fff',
                  }}
               >
                  <Interweave content={customisations.welcomeMessage} />
               </Box>
            )}
         <Box
            sx={{
               display: 'flex',
               justifyItems: 'center',
               justifyContent: 'space-evenly',
               alignItems: 'center',
               alignContent: 'space-evenly',
               flexWrap: 'wrap',
               mt: 4,
            }}
         >
            {lids.map((loc) => (
               <LargeIconButton
                  key={loc.lid}
                  title={loc.displayName}
                  icon={loc.logoPath}
                  onClick={() => {
                     //  setLocation({ loc: loc.lid, locName: loc.name });
                     history.push({
                        pathname: `/visitors?llid=${llid}&llocation=${landlocation}&loc=${loc.lid}&locName=${loc.displayName}`,
                     });
                     history.go(0);
                  }}
               />
            ))}
         </Box>
      </Box>
   );
};

export default MultiTenantForm;
