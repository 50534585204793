import React, { useState } from 'react';
import { Box, Tooltip, Typography, Paper, Badge, Avatar } from '@mui/material';
import Image from 'material-ui-image';
import BookingChips from './bookingChips';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import { spaceTypeImage } from '../../../Spaces/functions';
import { stringToColor } from '../../../../constants/functions';
import Seat from './compressedSeat';
import ShowSeats from './showSeats';

const CompressedChildSpace = (props) => {
   return (
      <React.Fragment>
         {/* {props.space.seats <= 1 ? (
            <Box sx={{ pt: 1 }}>
               <ShowSeats day={props.day} space={props.space} userData={props.userData} />
            </Box>
         ) : ( */}
         <Box
            component={Paper}
            sx={{
               display: 'flex',
               flexWrap: 'wrap',
               flexDirection: 'column',
               m: 1,
               borderLeft: props.space.backgroundColor ? `4px solid` : '2px solid',
               borderLeftColor: props.space.backgroundColor
                  ? props.space.backgroundColor
                  : '#C8C8C8',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
               }}
            >
               <Box sx={{ minWidth: '35px', maxWidth: '35px', maxHeight: '35px', mr: 1 }}>
                  <Image src={spaceTypeImage(props.space.type)} />
               </Box>

               <ShowSeats
                  day={props.day}
                  space={props.space}
                  userData={props.userData}
                  setSpaceMap={props.setSpaceMap}
                  setMap={props.setMap}
               />
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
               }}
            >
               <Typography variant="caption">{props.space.name}</Typography>
            </Box>
         </Box>
         {/* )} */}
      </React.Fragment>
   );
};

export default CompressedChildSpace;
