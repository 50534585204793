import { createContext, useContext } from 'react';
import {
   Drawer as MuiDrawer,
   useTheme,
   IconButton,
   Box,
   Toolbar,
   Divider,
   Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const contextName = 'Drawer';
const DrawerContext = createContext();

const Drawer = ({ children, open, onClose, title = null, ...rest }) => {
   const theme = useTheme();

   return (
      <MuiDrawer
         open={open}
         onClose={onClose}
         anchor="right"
         sx={{
            '& .MuiPaper-root': {
               [theme.breakpoints.up('xs')]: {
                  left: 0,
               },
               [theme.breakpoints.up('sm')]: {
                  left: 'auto',
               },
            },
         }}
         {...rest}
      >
         <Toolbar />
         <DrawerContext.Provider value={{ open, onClose }}>
            <Box
               sx={{
                  my: 2,
                  mx: 3,
                  display: 'flex',
                  justifyContent: title ? 'space-between' : 'end',
                  alignItems: 'center',
               }}
            >
               {title ? <Typography variant="h5">{title}</Typography> : null}
               <IconButton
                  onClick={onClose}
                  sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
               >
                  <CloseIcon />
               </IconButton>
            </Box>
            <Divider />
            <Box sx={{ py: [1, 3], px: [2, 6], overflowY: 'auto', height: '100%' }}>
               {children}
            </Box>
         </DrawerContext.Provider>
      </MuiDrawer>
   );
};

export const useDrawer = () => {
   const context = useContext(DrawerContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default Drawer;
