import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = (props) => {
   return (
      <Box id="loading-component" {...props}>
         <CircularProgress />
      </Box>
   );
};

export default Loading;
